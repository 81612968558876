import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { color, transition } from '@gsc/components';
import { breakpoint } from '../../../../style/breakpoint';
import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { PhotoDetailsPanel } from './PhotoDetailsPanel';
import { ProductTagsPanel } from './ProductTagsPanel';
import {
  PhotoDetailsPanels,
  useQueryParams,
} from '../../../../../../state/QueryParams/hooks';
import { waitUntil } from '../../../PhotoGrid/utils';
import {
  ActionTypes,
  PhotosDispatchContext,
  PhotosStateContext,
} from '../../../../../../state/Photos';
import { ComplianceActions } from './Compliance/ComplianceActions';
import { ComplianceSummary } from './Compliance/ComplianceSummary';
import { ComplianceStateContext } from '../../../../../../state/Compliance/context';
import { useComplianceContext } from '../../../../../../state/Compliance/hooks/useComplianceContext';
import { validateComplianceResults } from '../../utils';

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const SidebarWrapper = styled.div`
  background-color: ${color.ui01};
  display: flex;
  flex-direction: column;
  height: 50%;
  overflow: auto;

  ${breakpoint.lg`
    & {
      flex-direction: row;
      height: 100%;
    }
  `}
`;

const DetailsWrapper = styled.article`
  scroll-behavior: smooth;
  transition: ${transition.primary('width')};
  width: 100%;

  ${breakpoint.lg`
    & {
      height: 100vh;
      overflow-y: auto;
      width: 0;
    }

    .open & {
      width: 52.8rem;
    }
  `}
`;

const Root = styled.div`
  position: relative;
`;

const Header = styled.div`
  background: #ffffff;
  z-index: 2;
  width: 100%;
  ${breakpoint.lg`
    & {
      position: fixed;
      width: 51.2rem;
    }
  `}
`;

const HeaderTitle = styled.div`
  width: 100%;
  padding: 1.6rem;
  padding-right: 1.2rem;
  display: flex;
  ${breakpoint.md`
    & {
      padding: 2rem;
    }
  `}
  ${breakpoint.lg`
    & {
      padding: 2.4rem;
    }
  `}
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  ${breakpoint.lg`
    & {
      top: 10.8rem;
    }
  `}
`;

const Title = styled.span`
  color: #262b2f;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; /* 125% */
`;

const StyledTabs = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid #e5e6e6;
  display: inline-flex;
  padding: 0 1.4rem 0 1.5rem;
  align-items: center;
  gap: 1.6rem;
`;

const StyledTab = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  min-width: 6.2rem;
`;

const TabText = styled.div<{ selected?: boolean }>`
  color: ${p => (p.selected ? '#262b2f' : '#929597')};
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem; /* 121.429% */
  white-space: nowrap;
`;

const Bar = styled.div<{ selected?: boolean }>`
  height: 0.2rem;
  border-radius: 0.8rem;
  background: ${p => (p.selected ? '#00d9eb' : 'transparent')};
  width: 100%;
`;

const FlexSpacer = styled.div`
  flex-grow: 1;
`;

const CloseButton = styled.button`
  background-color: #ffffff;
`;
interface PhotoDetailsSidebarProps {
  assetDetail: AssetDetail.AsObject | undefined;
  downloadingAsset: boolean;
  downloadPic: () => void;
  isDesktop: boolean;
  loading?: boolean;
}

const PhotoDetailsSidebar: FC<PhotoDetailsSidebarProps> = ({
  assetDetail,
  loading,
}) => {
  const {
    selectedTagId,
    photoDetailsPanel,
    setPhotoDetailsPanel,
  } = useQueryParams();

  const showDetails = useContext(PhotosStateContext)?.showDetails;
  const isPlanogramImage = useContext(ComplianceStateContext)?.isPlanogramImage;
  const dispatch = useContext(PhotosDispatchContext);
  const contextState = useComplianceContext()?.state;

  const tags = useMemo(
    () =>
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_tags')
        ?.recordsList?.filter(
          tag => !parseInt(getProperty(tag, 'deleted_at'))
        ) ?? [],
    [assetDetail]
  );

  const notEnoughOkMatchesError = useMemo(
    () => validateComplianceResults(contextState?.kpiResult),
    [contextState]
  );

  useEffect(
    function switchOffTagsPanelWhenPhotoHasNoTags() {
      if (
        assetDetail &&
        !tags.length &&
        photoDetailsPanel === 'PRODUCT_TAGS_PANEL'
      ) {
        setPhotoDetailsPanel('PHOTO_DETAILS_PANEL');
      }
    },
    [assetDetail, photoDetailsPanel, setPhotoDetailsPanel, tags.length]
  );

  const scrollArea = useRef<HTMLDivElement | null>(null);
  useEffect(
    function scrollSidebarToSelectedTag() {
      let unmounted = false;

      if (selectedTagId) {
        const getListItem = () =>
          scrollArea.current?.querySelector<HTMLLIElement>(
            `.ir-tag-list-item[data-tag-id='${selectedTagId}']`
          );

        waitUntil(() => unmounted || !!getListItem()).then(() => {
          if (unmounted) return;
          const listItem = getListItem();
          if (!listItem || !scrollArea.current) return;
          const currentScroll = scrollArea.current.scrollTop;
          const panelHeight = scrollArea.current.offsetHeight;
          const itemTop = listItem.offsetTop;
          const scrollDestination = itemTop - 80;
          const isOnScreen =
            currentScroll < itemTop &&
            currentScroll + panelHeight > itemTop + listItem.offsetHeight;
          if (!isOnScreen) scrollArea.current.scrollTo(0, scrollDestination);
        });
      }

      return () => {
        unmounted = true;
      };
    },
    [selectedTagId]
  );

  const renderTabContent = () => {
    if (!isPlanogramImage) {
      switch (photoDetailsPanel) {
        case PhotoDetailsPanels.PRODUCT_TAGS:
          return <ProductTagsPanel assetDetail={assetDetail} />;
        default:
          return (
            <PhotoDetailsPanel assetDetail={assetDetail} loading={loading} />
          );
      }
    }

    switch (photoDetailsPanel) {
      case PhotoDetailsPanels.COMPLIANCE_SUMMARY:
        return <ComplianceSummary matchesError={notEnoughOkMatchesError} />;
      case PhotoDetailsPanels.RECOMMENDED_ACTIONS:
        return <ComplianceActions matchesError={notEnoughOkMatchesError} />;
      case PhotoDetailsPanels.PRODUCT_TAGS:
        return <ProductTagsPanel assetDetail={assetDetail} />;
      default:
        return (
          <PhotoDetailsPanel assetDetail={assetDetail} loading={loading} />
        );
    }
  };

  return (
    <SidebarWrapper
      data-test="photo-details-sidebar"
      className={classnames({ open: showDetails })}
    >
      <DetailsWrapper
        data-test="photo-details-sidebar-wrapper"
        ref={scrollArea}
      >
        {showDetails && (
          <article>
            {loading ? (
              <Root>
                <Header>
                  <HeaderTitle>
                    <Title>Info</Title>
                    <FlexSpacer />
                    <CloseButton
                      data-test="photo-details-close-button"
                      onClick={() => {
                        dispatch &&
                          dispatch({ type: ActionTypes.ToggleShowDetails });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 6L18 18"
                          stroke="#262B2F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18 6L6 18"
                          stroke="#262B2F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </CloseButton>
                  </HeaderTitle>
                </Header>
              </Root>
            ) : (
              <Root>
                <Header>
                  <HeaderTitle>
                    <Title>Info</Title>
                    <FlexSpacer />
                    <CloseButton
                      data-test="photo-details-close-button"
                      onClick={() => {
                        dispatch &&
                          dispatch({ type: ActionTypes.ToggleShowDetails });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 6L18 18"
                          stroke="#262B2F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18 6L6 18"
                          stroke="#262B2F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </CloseButton>
                  </HeaderTitle>
                  <StyledTabs>
                    <StyledTab
                      data-test="photo-details-tab-details"
                      aria-selected={
                        photoDetailsPanel === PhotoDetailsPanels.PHOTO_DETAILS
                      }
                      onClick={() => {
                        setPhotoDetailsPanel(PhotoDetailsPanels.PHOTO_DETAILS);
                      }}
                    >
                      <TabText
                        selected={
                          photoDetailsPanel === PhotoDetailsPanels.PHOTO_DETAILS
                        }
                      >
                        Photo Details
                      </TabText>
                      <Bar
                        selected={
                          photoDetailsPanel === PhotoDetailsPanels.PHOTO_DETAILS
                        }
                      />
                    </StyledTab>
                    {isPlanogramImage && (
                      <>
                        <StyledTab
                          data-test="photo-details-tab-compliance"
                          aria-selected={
                            photoDetailsPanel ===
                            PhotoDetailsPanels.COMPLIANCE_SUMMARY
                          }
                          onClick={() => {
                            setPhotoDetailsPanel(
                              PhotoDetailsPanels.COMPLIANCE_SUMMARY
                            );
                          }}
                        >
                          <TabText
                            selected={
                              photoDetailsPanel ===
                              PhotoDetailsPanels.COMPLIANCE_SUMMARY
                            }
                          >
                            KPI
                          </TabText>
                          <Bar
                            selected={
                              photoDetailsPanel ===
                              PhotoDetailsPanels.COMPLIANCE_SUMMARY
                            }
                          />
                        </StyledTab>
                        <StyledTab
                          data-test="photo-details-tab-actions"
                          aria-selected={
                            photoDetailsPanel ===
                            PhotoDetailsPanels.RECOMMENDED_ACTIONS
                          }
                          onClick={() => {
                            setPhotoDetailsPanel(
                              PhotoDetailsPanels.RECOMMENDED_ACTIONS
                            );
                          }}
                        >
                          <TabText
                            selected={
                              photoDetailsPanel ===
                              PhotoDetailsPanels.RECOMMENDED_ACTIONS
                            }
                          >
                            Actions
                          </TabText>
                          <Bar
                            selected={
                              photoDetailsPanel ===
                              PhotoDetailsPanels.RECOMMENDED_ACTIONS
                            }
                          />
                        </StyledTab>
                      </>
                    )}
                    {tags.length > 0 && (
                      <StyledTab
                        data-test="photo-details-tab-product-tags"
                        aria-selected={
                          photoDetailsPanel === PhotoDetailsPanels.PRODUCT_TAGS
                        }
                        onClick={() => {
                          setPhotoDetailsPanel(PhotoDetailsPanels.PRODUCT_TAGS);
                        }}
                      >
                        <TabText
                          selected={
                            photoDetailsPanel ===
                            PhotoDetailsPanels.PRODUCT_TAGS
                          }
                        >
                          Product Tags
                        </TabText>
                        <Bar
                          selected={
                            photoDetailsPanel ===
                            PhotoDetailsPanels.PRODUCT_TAGS
                          }
                        />
                      </StyledTab>
                    )}
                  </StyledTabs>
                </Header>
                <Content>{renderTabContent()}</Content>
              </Root>
            )}
          </article>
        )}
      </DetailsWrapper>
    </SidebarWrapper>
  );
};

export { PhotoDetailsSidebar };
