import React, { CSSProperties, FC, memo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { color, H4 } from '@gsc/components';
import { Selected, SuccessCheckmark, Unselected } from '@gsc/icons/react';
import { areEqual } from 'react-window';
import { pluralizePhotoCount } from '../utils';
import { BucketedGridCell, ItemData } from '../types';

const bucketRowHeight = 48;

const BucketRow = styled.div`
  background: white;
  width: min(100vw - 3.2rem, 188.8rem);
  height: ${bucketRowHeight / 10}rem;
  padding: 0 1.8rem;
  position: relative;
  z-index: 2;
`;

const BucketH4 = styled(H4)`
  display: inline-flex;
  font-size: 2rem;
  line-height: 2.4rem;
  position: relative;
  top: 0.8rem;
`;

const BucketPhotoCount = styled.small`
  color: #6b717c;
  font-size: 1.6rem;
  line-height: 2rem;
  position: relative;
  top: 0.3rem;
  margin-left: 1.2rem;
`;

const TextLoading = styled.div(
  ({ width, height }: { width?: string; height?: string }) => `
  background-color: ${color.ui10};
  border-radius: 99rem;
  height: ${height ? height : '1.8rem'};
  width: ${width ? width : '18rem'};
  line-height: 3.2rem;
  position: relative;
  top: 0.4rem;
`
);

const TruncatingSpan = styled.span`
  display: inline-flex;
  max-width: calc(100vw - 17.5rem);
  white-space: nowrap;
`;

const TruncatingContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectAllButton = styled.button.attrs({ role: 'switch' })`
  background: transparent;
  border: none;
  color: #dadcde;
  cursor: pointer;
  display: none;
  height: auto;
  padding: 0;
  margin-right: 1.2rem;
  width: 2.4rem;

  &.active {
    color: #1948b8;
  }

  *:hover > &,
  &.visible {
    display: inline-flex;
  }

  & > svg {
    width: 2.4rem;
  }
`;

const StyledCheckmark = styled(SuccessCheckmark)`
  color: white;
  height: 1.2rem;
  margin-left: -2.4rem;
  margin-top: 0.6rem;
`;

interface BucketTitleProps {
  cell: BucketedGridCell;
  data: ItemData;
}

const BucketTitle: FC<BucketTitleProps> = ({ cell, data }) => {
  if (
    typeof cell?.bucket === 'string' ||
    typeof cell?.bucket?.count !== 'number' ||
    typeof cell?.bucket?.value !== 'string'
  )
    return <TextLoading />;

  const { count, value } = cell.bucket;
  const allSelected = data.bucketsAllSelected.includes(value);
  const SelectAllIcon = allSelected ? Selected : Unselected;

  return (
    <>
      <TruncatingSpan title={value}>
        <SelectAllButton
          onClick={() => data.toggleBucketAllSelected(value)}
          className={classNames({
            active: allSelected,
            visible: data.mobileSelectMode,
          })}
        >
          <SelectAllIcon />
          {allSelected && <StyledCheckmark />}
        </SelectAllButton>

        <TruncatingContent>{value || '(No value set)'}</TruncatingContent>
      </TruncatingSpan>

      <BucketPhotoCount>{pluralizePhotoCount(count)}</BucketPhotoCount>
    </>
  );
};

const BucketRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const cell = data.rows[rowIndex]?.[columnIndex];

    return (
      <div style={style}>
        <BucketRow>
          <BucketH4>
            <BucketTitle cell={cell} data={data} />
          </BucketH4>
        </BucketRow>
      </div>
    );
  },
  areEqual
);

BucketRenderer.displayName = 'BucketRenderer';

export { BucketRenderer, bucketRowHeight };
