import React, { FC } from 'react';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { ShelvedProductTagsPanel } from './ShelvedProductTagsPanel';
import { MenuTagsPanel } from './MenuTagsPanel';
import { MenuTagsPanelV2 } from './MenuTagsPanelV2';
import { ShelvedProductGridTagsPanel } from './ShelvedProductGridTagsPanel';

interface ProductTagsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
}

// eslint-disable-next-line complexity
const ProductTagsPanel: FC<ProductTagsPanelProps> = ({ assetDetail }) => {
  const sceneType = assetDetail?.referencesList
    ?.find(({ name }) => name === 'image_recognition_scenes')
    ?.recordsList?.[0]?.propertiesList?.find(({ name }) => name === 'type_code')
    ?.value;

  switch (sceneType) {
    case 'shelf_grid':
    case 'shelf_ar':
    case 'cooler_grid':
    case 'cooler_ar':
    case 'floor_display_grid':
    case 'floor_display_ar':
    case 'back_bar_grid':
    case 'back_bar_ar':
      return <ShelvedProductGridTagsPanel assetDetail={assetDetail} />;

    case 'cold_box':
    case 'cold_vault':
    case 'wine_menu':
      return <ShelvedProductTagsPanel assetDetail={assetDetail} />;

    case 'menu':
    case 'full_menu':
      return <MenuTagsPanel assetDetail={assetDetail} />;

    case 'menu_v2':
      return <MenuTagsPanelV2 assetDetail={assetDetail} />;

    default:
      return null;
  }
};

export { ProductTagsPanel };
