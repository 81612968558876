import React, {
  Context,
  createContext,
  FC,
  FormEvent,
  ReactNode,
  useReducer,
  useState,
} from 'react';
import moment from 'moment';

const useForm = (params: {
  onSubmit: (values: any) => void;
  onValidate: (values: any) => any;
  initialValues: any;
}) => {
  const [values, setValues] = useReducer(
    (state: any, newState: any) => ({
      ...state,
      ...newState,
    }),
    params.initialValues
  );

  const [errors, setErrors] = useState({});

  const handleSubmit = (event?: FormEvent) => {
    if (event) event.preventDefault();

    const errors = params.onValidate(values);
    if (Object.keys(errors).length === 0) {
      setErrors({});
      params.onSubmit(values);
    } else {
      setErrors(errors);
    }
  };

  const handleChange = (event: FormEvent, altId?: string) => {
    if (event !== null) {
      if (moment.isMoment(event)) {
        const formattedDate = moment(event).format('MM/DD/YYYY');
        if (altId === undefined) return;
        setValues({ [altId]: formattedDate });
      } else {
        const target = event.target as HTMLInputElement;
        const { name, value } = target;
        setValues({ [name]: value });
      }
    }
  };

  const ErrorMessages = () => {
    const errorCount = Object.keys(errors).length;
    const hasErrors = errorCount > 0;

    return hasErrors ? errors : null;
  };

  const validate = () => {
    const errors = params.onValidate(values);
    setErrors(errors);
    return errors;
  };

  return {
    validate,
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
    ErrorMessages,
  };
};

interface FormContextValues {
  setValues: (obj: { [key: string]: string }) => void;
  values: any;
  errors: any;
  ErrorMessages: () => Record<string, any> | null;
  handleChange: (event: FormEvent, altId?: string) => void;
  handleSubmit: (event: FormEvent) => void;
  validate: () => any;
}

const initialContext: FormContextValues = {
  setValues: () => null,
  values: {},
  errors: {},
  ErrorMessages: () => null,
  handleChange: () => null,
  handleSubmit: () => null,
  validate: () => null,
};

const FormContext: Context<FormContextValues> = createContext(initialContext);

const FormProvider: FC<{
  children: ReactNode;
  onSubmit: (values: any) => void;
  onValidate: (values: any) => any;
  initialValues: any;
}> = ({ children, ...rest }) => {
  const value = useForm(rest);

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export { FormProvider, FormContext };
