import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { H3, SecondaryButton, TertiaryButton } from '@gsc/components';
import { ChevronDownBold, ChevronUpBold } from '@gsc/icons/react';
import { IsFeatureEnabled, isEmpty } from '../../../../../../shared/utils';
import {
  AssetDetail,
  Record,
  SpaceFeatureFlag,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { Details, DetailsTitle } from './components';
import { useQueryParams } from '../../../../../../state/QueryParams/hooks';
import { useAuth, useMetadata } from '../../../../../../state/Auth';
import { getImageRecognitionTags } from '../../../../shared/utils/asset';
import { CreatePlanogramFromPictureDialog } from './CreatePlanogramFromPictureDialog';
import { getPictureToPlanogramAccess } from '../../../../../../state/Compliance/wapi_planogram_api';

const TagPropertyList = styled.ul`
  padding: 0.8rem 0 0;

  & > li {
    color: #6b717c;
    padding: 0.4rem 0;

    & > .property-value {
      float: right;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
`;

const TagList = styled.ul`
  margin: 0 -3.2rem 3.2rem;
  padding-top: 0.1rem;

  & > li {
    padding: 1.6rem 3.2rem;
    font-size: 1.4rem;
    color: #222b3b;
    cursor: pointer;
    border-bottom: 0.1rem solid #f3f3f4;

    &:hover {
      background: #f3f3f4;
    }

    &:first-child {
      border-top: 0.1rem solid #f3f3f4;
    }

    &.active {
      background: #e6e7e9;
    }

    & > .tag-details-toggle {
      color: #222b3b;
      float: right;
      padding: 1.2rem;
      margin-top: -1rem;
      margin-right: -2rem;

      &:hover,
      &:focus {
        background: transparent !important;
        border-color: transparent;
        box-shadow: none;
        color: #222b3b;
      }

      & > svg {
        margin-right: 0;
        width: 1.2rem;
      }
    }
  }
`;

const PictureToPlanogramButton = styled(SecondaryButton)`
  margin-bottom: 3.2rem;
`;

const PictureToPlanogramSceneTypes = ['shelf_grid', 'cooler_grid'];

interface Shelf {
  index: number;
  tags: Record.AsObject[];
}

interface ShelvedProductGridTagsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
}

type nameGetter = (record: Record.AsObject) => string;

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const repBrandAssignments = 'rep_brand_assignments';
const brand = 'brand';
const brandFamily = 'brand_family';
const supplier = 'supplier';
const tagEdit = 'Tag Edit';
const ignored = 'Ignored';
const name = 'name';
const shelfProduct = 'shelf_product';
const unrecognizable = 'coldbox_unrecognizable_product';
const emptyFacing = 'empty_facing';

const nameGetterFactory = (typeName: string): nameGetter => (
  record: Record.AsObject
) => {
  switch (typeName) {
    case shelfProduct:
      return getProperty(record, name) ?? 'Unknown Product';

    case brandFamily:
      return getProperty(record, brandFamily) ?? 'Unknown Product';

    case emptyFacing:
      return 'Empty Facing';

    case unrecognizable:
      return 'Unknown Product';

    default:
      return 'Unknown Product';
  }
};

const determineName = (
  record: Record.AsObject,
  getName: nameGetter
): string => {
  const rba = JSON.parse(getProperty(record, repBrandAssignments) ?? '{}');

  if (isEmpty(rba)) {
    return getName(record);
  }

  if (rba.ignored == 'true') {
    return tagEdit + ': ' + ignored;
  }

  if (rba.ignored == 'false') {
    return tagEdit + ': ' + rba.brand;
  }

  return 'Unknown Product';
};

const determineValue = (record: Record.AsObject, name: string): string => {
  let value = '';
  const rba = JSON.parse(getProperty(record, repBrandAssignments) ?? '{}');

  if (isEmpty(rba)) {
    return getProperty(record, name);
  }

  switch (name) {
    case brand:
      value = rba.brand;
      break;

    case brandFamily:
      value = rba.brand_family;
      break;

    case supplier:
      value = rba.supplier;
      break;

    default:
      value = getProperty(record, name);
  }

  return value;
};

const ShelvedProductGridTagsPanel: FC<ShelvedProductGridTagsPanelProps> = ({
  assetDetail,
}) => {
  const currentSpace = useCurrentSpaceObj();
  const auth = useAuth();

  const displayFields = useMemo(
    () =>
      currentSpace?.config?.assetDetailConfig?.tablesList
        ?.find(({ tableName }) => tableName === 'image_recognition_tags')
        ?.columnConfigsList?.map(({ columnName }) => columnName) ?? [],
    [currentSpace]
  );

  const tags = useMemo(() => getImageRecognitionTags(assetDetail), [
    assetDetail,
  ]);

  const { selectedTagId, setSelectedTagId } = useQueryParams();

  const shelves = useMemo(
    () =>
      Object.values(
        tags.reduce<{ [key: string]: Shelf }>((result, tag) => {
          const XpositionProperty = getProperty(tag, 'shelf_index_x');
          const YpositionProperty = getProperty(tag, 'shelf_index_y');

          if (XpositionProperty && YpositionProperty) {
            const [shelfIndex] = [YpositionProperty, XpositionProperty].map(
              parseInt
            );

            if (!result[`${shelfIndex}`])
              result[`${shelfIndex}`] = { index: shelfIndex, tags: [] };

            result[`${shelfIndex}`].tags.push(tag);
          }

          return result;
        }, {})
      ).map((shelf, index) => {
        shelf.index = index;
        return {
          ...shelf,
          tags: shelf.tags.sort((a, b) => {
            const positionPropertyA = getProperty(a, 'shelf_index_x');
            const positionPropertyB = getProperty(b, 'shelf_index_x');

            if (!positionPropertyA || !positionPropertyB) return 0;

            const columnIndexA = parseInt(positionPropertyA);
            const columnIndexB = parseInt(positionPropertyB);

            return columnIndexA - columnIndexB;
          }),
        };
      }),
    [tags]
  );

  const [
    canCreatePlanogramFromPicture,
    setCanCreatePlanogramFromPicture,
  ] = useState(false);

  const { photoGridId, companyId } = useMemo(() => {
    let photoGridId: number | undefined;
    let companyId: number | undefined;
    if (tags.length > 0) {
      const tag = tags[0];
      photoGridId = parseInt(getProperty(tag, 'photo_grid_id'));
      if (isNaN(photoGridId)) {
        photoGridId = undefined;
      }
      companyId = parseInt(getProperty(tag, 'company_id'));
      if (isNaN(companyId)) {
        companyId = undefined;
      }
    }
    return { photoGridId, companyId };
  }, [tags]);

  const getMetadata = useMetadata();

  useEffect(() => {
    if (!photoGridId || !companyId) {
      setCanCreatePlanogramFromPicture(false);
      return;
    }

    const isFeatureEnabled = IsFeatureEnabled(
      currentSpace,
      SpaceFeatureFlag.SPACE_FEATURE_FLAG_PICTURE_TO_PLANOGRAM
    );

    if (!isFeatureEnabled) {
      setCanCreatePlanogramFromPicture(false);
      return;
    }

    const sceneType =
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_scenes')
        ?.recordsList?.[0]?.propertiesList?.find(
          ({ name }) => name === 'type_code'
        )?.value ?? '';

    if (!PictureToPlanogramSceneTypes.includes(sceneType)) {
      setCanCreatePlanogramFromPicture(false);
      return;
    }

    getMetadata().then(metadata => {
      getPictureToPlanogramAccess(metadata, companyId)
        .then(result => {
          setCanCreatePlanogramFromPicture(result.data.hasAccess);
        })
        .catch(() => {
          setCanCreatePlanogramFromPicture(false);
        });
    });
  }, [
    currentSpace,
    getMetadata,
    companyId,
    photoGridId,
    assetDetail,
    auth.hasGscAccess,
  ]);

  const [
    showCreatePlanogramFromPictureDialog,
    setShowCreatePlanogramFromPictureDialog,
  ] = useState(false);

  return (
    <Details>
      <DetailsTitle>
        {pluralize('Product', tags.length, true)} Tagged
      </DetailsTitle>

      {canCreatePlanogramFromPicture && (
        <PictureToPlanogramButton
          data-test="picture-to-planogram-button"
          onClick={() => setShowCreatePlanogramFromPictureDialog(true)}
        >
          Convert Image To Space Planning File
        </PictureToPlanogramButton>
      )}

      <ul>
        {shelves.map(shelf => (
          <li key={shelf.index}>
            <H3>Shelf {shelf.index}</H3>
            <TagList>
              {shelf.tags.map((tag, i) => {
                const tagId = getProperty(tag, 'id');
                const active = tagId === selectedTagId;
                const ToggleIcon = active ? ChevronUpBold : ChevronDownBold;
                const typeName = getProperty(tag, 'type_name');
                const nameGetter = nameGetterFactory(typeName);
                return (
                  <li
                    key={i}
                    className={classnames({ active, 'ir-tag-list-item': true })}
                    onClick={() => setSelectedTagId(active ? null : tagId)}
                    data-tag-id={tagId}
                  >
                    {displayFields.length > 0 && (
                      <TertiaryButton className="tag-details-toggle">
                        <ToggleIcon />
                      </TertiaryButton>
                    )}

                    {determineName(tag, nameGetter)}

                    {active && (
                      <TagPropertyList>
                        {displayFields.map(propertyName => {
                          if (propertyName === 'name') return null;

                          const name = propertyName
                            .split('_')
                            .map(w => w[0].toUpperCase() + w.substring(1))
                            .join(' ');

                          const value = determineValue(tag, propertyName);

                          if (!value) return null;
                          return (
                            <li key={propertyName}>
                              {name}:{' '}
                              <span className="property-value">{value}</span>
                            </li>
                          );
                        })}
                      </TagPropertyList>
                    )}
                  </li>
                );
              })}
            </TagList>
          </li>
        ))}
      </ul>

      {showCreatePlanogramFromPictureDialog && (
        <CreatePlanogramFromPictureDialog
          companyId={companyId ?? 0}
          photoGridId={photoGridId ?? 0}
          onCloseCallback={() => setShowCreatePlanogramFromPictureDialog(false)}
        />
      )}
    </Details>
  );
};

export { ShelvedProductGridTagsPanel };
