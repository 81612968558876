import {
  formatParamToString,
  uppercaseFirstLetter,
} from '../../../../shared/utils';
import {
  ColumnConfig,
  TableConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const formatTableName = (tableName: string): string =>
  formatParamToString(uppercaseFirstLetter(tableName));

const formatColumnName = (columnName: string): string =>
  formatParamToString(uppercaseFirstLetter(columnName));

const formatLabelOrColumnName = (column: ColumnConfig.AsObject) =>
  column.label === '' ? formatColumnName(column.columnName) : column.label;

const combineTableAndColumnName = (
  tableName: string,
  columnName: string
): string => `${formatTableName(tableName)}: ${formatColumnName(columnName)}`;

const formatFilterText = (
  table: TableConfig.AsObject,
  column: ColumnConfig.AsObject
): string =>
  column.label === ''
    ? combineTableAndColumnName(table.tableName, column.columnName)
    : formatLabelOrColumnName(column);

export {
  formatTableName,
  formatColumnName,
  formatLabelOrColumnName,
  combineTableAndColumnName,
  formatFilterText,
};
