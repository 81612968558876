import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { usePhotosState } from '../../../../state/Photos';
import {
  AlbumPageLoadStatus,
  useAlbumsContext,
} from '../../../../state/Albums';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { Details } from '../../../Photos/screens/Details/Details';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { ComplianceProvider } from '../../../../state/Compliance/context';
import { useAssetDetailTags } from '../../../../state/Compliance/hooks/useAssetDetailTags';

interface AlbumPhotoDetailProps {
  albumId?: string;
  id: string;
}

const AlbumPhotoDetail: FC<AlbumPhotoDetailProps> = ({ albumId, id }) => {
  const { detailPlaceholder } = usePhotosState();
  const clickedCoordinates = detailPlaceholder?.clickedCoordinates;
  const [{ albumAssetsPages, getAsset }] = useAlbumsContext();
  const [albumAssetDetail, setAlbumAssetDetail] = useState<
    AssetDetail.AsObject
  >();
  const [assetDetailLoading, setAssetDetailLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add('details-active');
    setAssetDetailLoading(true);

    setAlbumAssetDetail(getAsset(id));

    setAssetDetailLoading(false);

    return () => {
      document.body.classList.remove('takeover-active');
    };
  }, [albumAssetDetail, getAsset, id]);

  const currentPage = useMemo(
    () => Object.values(albumAssetsPages).find(({ ids }) => ids.includes(id)),
    [albumAssetsPages, id]
  );

  const { setShowPhoto } = useQueryParams();
  const currentPageNumber = currentPage?.pageNumber ?? -1;

  const { hasPreviousPhoto } = useMemo(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    const hasPreviousPhoto = currentIndex > 0;
    return { hasPreviousPhoto };
  }, [currentPage, id]);

  const goToPreviousPhoto = useCallback(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    let prevPhotoId = currentPage?.ids?.[currentIndex - 1];

    // check for a previous page to grab the last id of...
    if (!prevPhotoId && currentPageNumber > 0) {
      const pageKey = `album:${albumId};page${currentPageNumber - 1}`;
      const previousPage = albumAssetsPages[pageKey];
      prevPhotoId = previousPage?.ids?.slice?.(-1)?.[0];
    }

    if (prevPhotoId) setShowPhoto(prevPhotoId);
  }, [
    albumAssetsPages,
    albumId,
    currentPage,
    currentPageNumber,
    id,
    setShowPhoto,
  ]);

  const goToNextPhoto = useCallback(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    const nextPhotoId = currentPage?.ids?.[currentIndex + 1];
    if (nextPhotoId) return setShowPhoto(nextPhotoId);

    const nextPageNumber = currentPageNumber + 1;
    const pageKey = `album:${albumId};page${nextPageNumber}`;
    const nextPage = albumAssetsPages[pageKey];
    if (nextPage?.status === AlbumPageLoadStatus.LOADING) return;
    const firstId = nextPage?.ids?.[0];
    if (firstId) return setShowPhoto(firstId);
  }, [
    currentPage,
    id,
    setShowPhoto,
    currentPageNumber,
    albumId,
    albumAssetsPages,
  ]);

  const { companyId, photoGridId } = useAssetDetailTags(albumAssetDetail);

  return (
    <ComplianceProvider photoGridId={photoGridId} companyId={companyId}>
      <Details
        assetDetail={albumAssetDetail}
        assetDetailLoading={assetDetailLoading}
        clickedCoordinates={clickedCoordinates}
        goToNextPhoto={goToNextPhoto}
        goToPreviousPhoto={goToPreviousPhoto}
        hasPreviousPhoto={hasPreviousPhoto}
      />
    </ComplianceProvider>
  );
};

export { AlbumPhotoDetail };
