import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ScaleLinear } from 'd3-scale';
import Rheostat from 'rheostat';
import { SliderHandle } from './components';
import { ThemeProvider } from '../../../../shared/utils/theme';

const SliderWrapper = styled.div`
  display: block;
  overflow: visible;
  z-index: 6;
`;

const UnselectedBar = styled.div`
  border-radius: 0.2rem;
  height: 0.4rem;
  margin-top: -0.1rem;
  position: relative;
  z-index: 2;
`;

const SelectedBar = styled.div`
  border-radius: 0.2rem;
  height: 0.4rem;
  margin-bottom: -1.7rem;
  margin-top: -0.4rem;
  position: relative;
  z-index: 3;
`;

interface SliderProps {
  scale: ScaleLinear<number, number>;
  selection: number[];
  onChange?: (selection: number[]) => void;
  selectedColor?: string;
  unselectedColor?: string;
}

const Slider = (props: SliderProps) => {
  const {
    scale,
    selection,
    onChange,
    selectedColor = '#1948B8',
    unselectedColor = '#B5B8BE',
  } = props;

  const [domainMin, domainMax] = useMemo(() => {
    const { 0: min, length, [length - 1]: max } = scale.domain();
    return [min, max];
  }, [scale]);

  const selectionWidth = useMemo(
    () => Math.abs(scale(selection[1]) - scale(selection[0])),
    [scale, selection]
  );

  const selectionOffset = useMemo(
    () => scale(Array.from(selection).sort((a, b) => +a - +b)[0]),
    [scale, selection]
  );

  const onValuesUpdated = useCallback(
    ({ values }: { values: number[] }) => onChange?.(values),
    [onChange]
  );

  return (
    <SliderWrapper>
      <UnselectedBar style={{ background: unselectedColor }} />
      <SelectedBar
        style={{
          background: selectedColor,
          width: selectionWidth,
          left: selectionOffset,
        }}
      />

      <ThemeProvider>
        <Rheostat
          min={domainMin}
          max={domainMax}
          values={selection}
          onValuesUpdated={onValuesUpdated}
          handle={SliderHandle}
          snap
        />
      </ThemeProvider>
    </SliderWrapper>
  );
};

export { Slider };
