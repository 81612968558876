import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Header } from './components/Header';
import { Admin } from './screens/Admin';
import { Exports } from './screens/Exports';
import { NoMatch } from './screens/NoMatch';
import { Photos } from './screens/Photos';
import { AlbumsApp } from './screens/Albums';
import { CompliancePhotos } from './screens/Compliance';
import { AlbumScreen } from './screens/Albums/screens/album/Screen';
import { Settings } from './screens/Settings';
import { useAuth } from './state/Auth';
import { paths } from './paths';
import { useSpacesRedirect } from './components/useSpacesRedirect';
import { usePendo } from './components/usePendo';
import { AlbumsProvider } from './state/Albums';
import { ExportsProvider } from './state/Exports';
import { useCurrentSpaceObj } from './state/Spaces';
import { IsFeatureEnabled } from './shared/utils';
import {
  SpaceFeatureFlag,
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { ThemeProvider } from './provider/ThemeProvider';

const Routes: FC = () => {
  usePendo();
  const shareRouteMatch = useRouteMatch<{ shareToken: string }>(
    paths.share.root()
  );
  const { isApertureAdmin, isSpaceAdmin } = useAuth();
  const { hasSpaces, spaceExists } = useSpacesRedirect();
  const currentSpace = useCurrentSpaceObj();
  const expiredTrial =
    currentSpace?.status === SpaceStatus.SPACE_STATUS_INACTIVE &&
    currentSpace?.tier?.type === SpaceTierType.SPACE_TIER_TYPE_TRIAL;
  const isPlanogramSpace = IsFeatureEnabled(
    currentSpace,
    SpaceFeatureFlag.SPACE_FEATURE_FLAG_PLANOGRAM_COMPLIANCE
  );

  const renderAdminRoutes = () =>
    isApertureAdmin
      ? [
          <Route key={paths.admin.root} path={paths.admin.root}>
            <Admin />
          </Route>,
        ]
      : null;

  const renderSpacesRoutes = () => [
    (isApertureAdmin || isSpaceAdmin) && (
      <Route
        key={paths.spaces.space.settings.root()}
        path={paths.spaces.space.settings.root()}
      >
        {expiredTrial ? (
          <NoMatch
            title="Trial Expired"
            message="Your trial of PhotoWorks has expired. Please contact your CSM to upgrade to a paid plan."
          />
        ) : (
          <Settings />
        )}
      </Route>
    ),
    <Route exact key={paths.exports.root} path={paths.exports.root}>
      <ExportsProvider>
        <Exports />
      </ExportsProvider>
    </Route>,
    <Route
      key={paths.spaces.space.photos.root()}
      path={paths.spaces.space.photos.root()}
    >
      <Photos />
    </Route>,
    <Route
      key={paths.spaces.space.albums.root()}
      path={paths.spaces.space.albums.root()}
    >
      <AlbumsApp />
    </Route>,
    isPlanogramSpace && (
      <Route
        key={paths.spaces.space.compliance.root()}
        path={paths.spaces.space.compliance.root()}
      >
        <CompliancePhotos />
      </Route>
    ),
    <Route
      key={paths.spaces.space.photos.notFound()}
      path={paths.spaces.space.photos.notFound()}
      render={props =>
        props.location.pathname === paths.root ? null : (
          <NoMatch
            title="Photo Not Found"
            message="This photo is no longer available, possibly due to it being outside the retention window. Please contact Support with any questions."
          />
        )
      }
    />,
    <Route
      key={paths.notFound}
      render={props =>
        props.location.pathname === paths.root ? null : <NoMatch />
      }
    />,
  ];

  const sharedRoute = () => {
    return shareRouteMatch ? (
      [
        <Route
          key={paths.share.root()}
          path={paths.share.root()}
          render={props => (
            <AlbumsProvider>
              <AlbumScreen match={props.match} />
            </AlbumsProvider>
          )}
        />,
      ]
    ) : (
      <NoMatch key={paths.notFound} />
    );
  };

  return (
    <ThemeProvider>
      <Header />
      {spaceExists === false ? (
        sharedRoute()
      ) : (
        <main>
          <Switch>
            {hasSpaces ? (
              [renderAdminRoutes(), renderSpacesRoutes(), sharedRoute()]
            ) : (
              <Route>
                <NoMatch />
              </Route>
            )}
          </Switch>
        </main>
      )}
    </ThemeProvider>
  );
};

export { Routes };
