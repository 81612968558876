import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { H3 } from '@gsc/components';
import { max, min } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { NoHistogramDataMessage } from './components';
import { HistogramRangePickerBucket } from './types';
import { Histogram } from './Histogram';
import { Slider } from './Slider';

const HistogramWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-bottom: 3.6rem;
`;

interface HistogramRangePickerProps {
  data: HistogramRangePickerBucket[];
  onChange: (...args: any[]) => any;
  selection?: number[];
  width?: number;
  height?: number;
  padding?: number;
  sliderHeight?: number;
  style?: object;
  selectedColor?: string;
  unselectedColor?: string;
}

const HistogramRangePicker = (props: HistogramRangePickerProps) => {
  const {
    data,
    selection,
    width = 400,
    height = 200,
    padding = 20,
    style = {},
  } = props;

  const histogramWidth = width - padding * 2;
  const histogramHeight = height - padding * 2;

  const sortedData = useMemo(() => data.sort((a, b) => +a.x0 - +b.x0), [data]);
  const extent: number[] = useMemo(
    () => [
      min(sortedData, ({ x0 }: HistogramRangePickerBucket) => +x0) ?? 0,
      max(sortedData, ({ x }: HistogramRangePickerBucket) => +x) ?? 0,
    ],
    [sortedData]
  );
  const scale = useMemo(
    () =>
      scaleLinear()
        .domain(extent)
        .range([0, histogramWidth])
        .clamp(true),
    [extent, histogramWidth]
  );

  const onChangeProp = props.onChange;
  const onChange = useCallback(
    selection => {
      onChangeProp?.(
        selection.map((d: number) =>
          Math.max(extent[0], Math.min(extent[1], +d))
        )
      );
    },
    [extent, onChangeProp]
  );

  const overrides = {
    data: sortedData,
    selection: selection ?? extent,
    scale,
    onChange,
    width: histogramWidth,
    height: histogramHeight,
  };

  if (data.length > 0) {
    return (
      <HistogramWrapper style={style}>
        <Histogram {...props} {...overrides} />
        <Slider {...props} {...overrides} />
      </HistogramWrapper>
    );
  } else {
    return (
      <NoHistogramDataMessage>
        <H3>No data available to filter</H3>
      </NoHistogramDataMessage>
    );
  }
};

export { HistogramRangePicker };
