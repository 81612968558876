import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';
import moment from 'moment';
import {
  bootstrapOverrides,
  border,
  breakpoint,
  color,
  H1,
  Input,
  typography,
} from '@gsc/components';
import { ShareLink } from '@gsc/icons/react';
import { useAlbumsAPI, useAlbumsContext } from '../../../../state/Albums';
import { useAuth } from '../../../../state/Auth';
import { TextLoading } from '../../components';
import { HeaderActionButtons } from './HeaderActionButtons';
import { HeaderSmartFilters } from './HeaderSmartFilters';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Input)`
  & > input {
    ${bootstrapOverrides.textInline}
    ${typography.heading1};
    border-color: ${({ errors }) =>
      errors ? color.negative50 : 'transparent'};
    border-radius: ${border.radius.normal};
    color: ${color.text900};
    cursor: text;
    padding: 0.3rem 0;
    position: relative;
    width: 32rem;

    ${breakpoint.md`
      width: 80rem;
    `}

    &:hover {
      border-color: ${({ errors }) =>
        errors ? color.negative50 : color.form.default.border};
    }

    &:focus {
      ${bootstrapOverrides.textInline}
      background-color: ${color.form.default.background};
      border: ${border.normal(color.form.focus.border)};
      border-radius: ${border.radius.normal};
    }
  }

  & > div {
    display: none;
  }
`;

const BasicTitle = styled(H1)`
  margin-bottom: 0;
  padding: 0.3rem 0;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Details = styled.p`
  color: #6b717c;
  font-size: 1.4rem;

  & > span {
    font-weight: 500;
  }
`;

const Spacer = styled.span`
  background: #dadcde;
  border-radius: 100%;
  display: inline-block;
  height: 0.4rem;
  margin: 0 0.8rem;
  position: relative;
  top: -0.3rem;
  width: 0.4rem;
`;

const ShareFlag = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2.4rem;
`;

const IconWrapper = styled.span`
  border-radius: 50%;
  border: 0.1rem solid ${color.mxDeepOcean};
  display: flex;
  height: 2.1rem;
  justify-content: center;
  margin-right: 0.75rem;
  width: 2.1rem;
`;

const ShareIcon = styled(ShareLink)`
  color: ${color.mxDeepOcean};
  width: 1.2rem;
`;

const ShareText = styled.p`
  color: ${color.mxDeepOcean};
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface AlbumHeaderProps {
  album: AlbumType;
  setResponseNotification: (args: string) => any;
  shareToken: string;
  smartAlbum: boolean;
}

const AlbumHeader: FC<AlbumHeaderProps> = ({
  album,
  setResponseNotification,
  shareToken,
  smartAlbum,
}) => {
  const { status, types } = useAuth();
  const authorized = status === types.AuthStatus.AUTHORIZED;
  const [nameValue, setNameValue] = useState<string>();
  const albumsAPI = useAlbumsAPI();
  const [
    { albumAssetCounts },
    { getAlbum, getSmartAlbum },
  ] = useAlbumsContext();

  const photoCount =
    album && 'assetCount' in album
      ? album.assetCount
      : album && albumAssetCounts[album.id];

  const formattedTimestamp = useMemo(
    () => album && moment(album.createdAtTime).format('MMMM D, YYYY'),
    [album]
  );

  useEffect(() => {
    if (album && nameValue === undefined) {
      setNameValue(album.name);
    }
  }, [album, nameValue]);

  const handleInputChange = (e: FormEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setNameValue(value);
  };

  let updatedName = '';
  const keepExistingName = () => {
    if (album) {
      updatedName = album.name;
      setNameValue(album.name as string);
    }
    return updatedName;
  };

  const editAlbumName = () => {
    updatedName = !nameValue ? keepExistingName() : nameValue;

    albumsAPI()
      .then(({ setAlbumName }) => {
        return album && setAlbumName(album.id, updatedName);
      })
      .then(() => {
        if (smartAlbum) {
          album && getSmartAlbum(album.id);
        } else {
          album && getAlbum(album.id);
        }
      })
      .catch(() =>
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        )
      );
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      const input = document.getElementById('album-name-input');
      input && input.blur();
    }
  };

  const subTitle = smartAlbum ? (
    <HeaderSmartFilters album={album} photoCount={photoCount} />
  ) : (
    <Details data-test="photo-count">
      {pluralize('photo', photoCount, true)}
      <Spacer />
      {formattedTimestamp}
    </Details>
  );

  return album ? (
    <>
      <TitleWrapper>
        {album.name && !shareToken ? (
          <Title
            data-test="album-name-input"
            name="album-name-input"
            onBlur={editAlbumName}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            value={nameValue}
          />
        ) : (
          <BasicTitle>{nameValue}</BasicTitle>
        )}
        {authorized && (
          <HeaderActionButtons
            album={album}
            assetCount={photoCount}
            setResponseNotification={setResponseNotification}
            shareToken={shareToken}
          />
        )}
      </TitleWrapper>
      <SubWrapper>
        {subTitle}
        {album.shareSettings && (
          <ShareFlag>
            <IconWrapper>
              <ShareIcon />
            </IconWrapper>
            <ShareText>Shared</ShareText>
          </ShareFlag>
        )}
      </SubWrapper>
    </>
  ) : (
    <>
      <TextLoading
        style={{
          width: '30%',
          height: '3rem',
          margin: '2rem 0 1.6rem',
        }}
      />
      <TextLoading style={{ width: '16%', height: '1.8rem' }} />
    </>
  );
};

export { AlbumHeader };
