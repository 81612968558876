import { AuthStatus, Auth0User } from './types';

export enum ActionTypes {
  SetToken = 'SetToken',
  SetStatus = 'SetStatus',
  SetUser = 'SetUser',
  SetRoles = 'SetRoles',
}

interface SetToken {
  type: ActionTypes.SetToken;
  payload: {
    token: string;
  };
}

interface SetStatus {
  type: ActionTypes.SetStatus;
  payload: {
    status: AuthStatus;
  };
}

interface SetUser {
  type: ActionTypes.SetUser;
  payload: {
    user: Auth0User;
  };
}

interface SetRoles {
  type: ActionTypes.SetRoles;
  payload: {
    hasGscAccess?: boolean;
    isApertureAdmin?: boolean;
    isSpaceAdmin?: boolean;
  };
}

export type Actions = SetStatus | SetToken | SetUser | SetRoles;
