import {
  GetApertureAdminStatusRequest,
  GetGSCAccessStatusRequest,
  GetSpaceAdminStatusRequest,
} from '@gsc/proto-gen-v2/dist/idl/aperture/authz/v1/authz_api_pb';

import { grpc } from '@improbable-eng/grpc-web';
import { AuthZClient } from '../../services';

const api = (metadata: grpc.Metadata) => {
  const isApertureAdmin = (userId: string): Promise<boolean> => {
    const request = new GetApertureAdminStatusRequest();
    userId && request.setUserId(userId);

    return new Promise((resolve, reject) => {
      AuthZClient.getApertureAdminStatus(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AuthZClient:getApertureAdminStatus')
          );
        }
        return resolve(response.getIsApertureAdmin());
      });
    });
  };

  const isSpaceAdmin = (spaceId: string, userId: string): Promise<boolean> => {
    const request = new GetSpaceAdminStatusRequest();
    request.setSpaceId(spaceId);
    userId && request.setUserId(userId);

    return new Promise((resolve, reject) => {
      AuthZClient.getSpaceAdminStatus(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AuthZClient:getSpaceAdminStatus')
          );
        }

        return resolve(response.getIsSpaceAdmin());
      });
    });
  };

  const hasGscAccess = (userId: string): Promise<boolean> => {
    const request = new GetGSCAccessStatusRequest();
    request.setUserId(userId);

    return new Promise((resolve, reject) => {
      AuthZClient.getGSCAccessStatus(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AuthZClient:getGSCAccessStatus')
          );
        }

        return resolve(response.getHasGscAccess());
      });
    });
  };

  return {
    hasGscAccess,
    isApertureAdmin,
    isSpaceAdmin,
  };
};

export { api };
