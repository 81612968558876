import { createContext, useContext } from 'react';

export type ModalContextValues = {
  close: () => void;
  labelledBy: string;
  describedBy: string;
};

export const ModalContext = createContext<ModalContextValues | null>(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('Must be used in a Modal component');

  return context;
};
