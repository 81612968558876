import React, { useEffect, useState } from 'react';
import { PopupModal, PrimaryButton, SecondaryButton } from '@gsc/components';
import {
  getExportableResultJson,
  GetExportableResultJsonResponse,
} from '../../../../../../../state/Compliance/wapi_planogram_api';
import { AxiosResponse } from 'axios';
import { useMetadata } from '../../../../../../../state/Auth';
import styled from 'styled-components';

type ComplianceJsonModalProps = {
  companyId?: number;
  photoGridId?: number;
  onCloseCallback: () => void;
};

const StyledPopupModal = styled(PopupModal)`
  min-width: 50vw;
  height: 50vh;
`;

const ComplianceJsonModal = ({
  companyId,
  photoGridId,
  onCloseCallback,
}: ComplianceJsonModalProps) => {
  const getMetadata = useMetadata();

  const [content, setContent] = useState<string>('[Loading ...]');
  const [justCopied, setJustCopied] = useState<boolean>(false);

  useEffect(() => {
    if (companyId == null || photoGridId == null) {
      return;
    }
    getMetadata()
      .then(metadata =>
        getExportableResultJson(metadata, companyId, photoGridId)
      )
      .then((response: AxiosResponse<GetExportableResultJsonResponse>) => {
        if (response.status === 200) {
          setContent(JSON.stringify(response.data.result, null, 2));
        } else {
          setContent(
            `[Failed to get JSON: ${response.status} ${response.statusText}]`
          );
        }
      });
  });

  const copyJsonToClipboard = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setJustCopied(true);
        setTimeout(() => {
          setJustCopied(false);
        }, 1000);
      })
      .catch(reason => {
        alert('Failed to copy JSON to clipboard: ' + reason);
      });
  };

  return (
    <StyledPopupModal closeClick={onCloseCallback}>
      <textarea
        data-test="compliance-json-modal-content"
        value={content}
        readOnly
        style={{
          width: '100%',
          height: 'calc(100% - 5rem)',
          resize: 'none',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div style={{ textAlign: 'right' }}>
        <PrimaryButton onClick={copyJsonToClipboard}>
          {justCopied ? 'Copied!' : 'Copy to Clipboard'}
        </PrimaryButton>
        <SecondaryButton onClick={onCloseCallback} status="neutral">
          Close
        </SecondaryButton>
      </div>
    </StyledPopupModal>
  );
};

export { ComplianceJsonModal };
