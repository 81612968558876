import React, { FC, useContext } from 'react';
import { Input } from '@gsc/components';
import { FormContext } from '../../../../../../shared/components/FormContext';
import {
  SectionWrapper,
  SectionHeader,
  SubWrapper,
  SubHeader,
} from './ExportSettingsForm';
import { LayoutPhotosPerSlide } from './LayoutPhotosPerSlide';
import { MetadataLayout } from './MetadataLayout';
import { Format } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';

const FormLayout: FC = () => {
  const { errors, handleChange, values } = useContext(FormContext);
  const exportFormat = parseInt(values.format, 10);
  const type = exportFormat === Format.FORMAT_PPTX ? 'slide' : 'page';

  return (
    <SectionWrapper>
      <SectionHeader>Layout</SectionHeader>
      <SubWrapper>
        <SubHeader>How many photos per {type}?</SubHeader>
        <LayoutPhotosPerSlide />
      </SubWrapper>
      <SubWrapper>
        <Input
          errors={errors.title}
          id="title"
          label="Title"
          name="title"
          onChange={handleChange}
          value={values.title}
        />
        <Input
          id="subtitle"
          label="Subtitle (optional)"
          name="subtitle"
          onChange={handleChange}
          value={values.subtitle}
        />
      </SubWrapper>
      <MetadataLayout />
    </SectionWrapper>
  );
};

export { FormLayout };
