import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { H1, PrimaryButton } from '@gsc/components';
import { useCurrentSpace } from '../../../../state/Spaces';
import { paths } from '../../../../paths';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 4.8rem;
`;

const Text = styled(H1)`
  color: #b5b8be;
  font-weight: 500;
`;

const AddButton = styled(PrimaryButton)`
  max-width: 11.6rem;
`;

interface AddPhotosProps {
  albumId: string | undefined;
  smartAlbum: boolean;
}

const AddPhotos: FC<AddPhotosProps> = ({ albumId, smartAlbum }) => {
  const { id: spaceId } = useCurrentSpace();
  const history = useHistory();

  const text = smartAlbum
    ? `This album is empty. No photos currently match the criteria for this album.`
    : `This album is empty. Use the button below to start adding photos.`;

  const addPhotos = () => {
    history.push(
      `${paths.spaces.space.photos.root(spaceId)}?selectAlbum=${albumId}`
    );
  };

  return (
    <Wrapper>
      <Text>{text}</Text>
      {!smartAlbum && <AddButton onClick={addPhotos}>Add Photos</AddButton>}
    </Wrapper>
  );
};

export { AddPhotos };
