import React, { FC, useState, FormEvent } from 'react';
import styled from 'styled-components';
import {
  breakpoint,
  ConfirmationModal,
  Input,
  PrimaryButton,
  SecondaryButton,
  typography,
} from '@gsc/components';
import { useAlbumsAPI } from '../../../../../state/Albums';
import { useQueryParams } from '../../../../../state/QueryParams/hooks';
import { useCurrentSpace } from '../../../../../state/Spaces';

const SaveModalBody = styled(ConfirmationModal)`
  max-width: 32.7rem;
  min-width: 32.7rem;

  ${breakpoint.md`
    max-width: 45rem;
    min-width: 40.8rem;
  `}
`;

const SubHeader = styled.p`
  ${typography.body};
  color: #6b717c;
  padding-bottom: 2.4rem;
`;

interface SaveModalProps {
  setResponseNotification: (args: string) => any;
}

const SaveModal: FC<SaveModalProps> = ({ setResponseNotification }) => {
  const api = useAlbumsAPI();
  const {
    getFilters,
    searchFilters,
    setShowSavedViewModal,
    setShowSmartAlbumModal,
    showSavedViewModal,
    sortDirection,
    sortField,
  } = useQueryParams();
  const { id } = useCurrentSpace();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e: FormEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    if (value) setError('');
    setInputValue(value);
  };

  const saveCurrentView = () => {
    if (!inputValue) return setError('Enter a name');

    api()
      .then(({ createSavedView }) => {
        const filters = getFilters();
        return createSavedView(
          inputValue,
          id,
          filters,
          sortDirection,
          sortField,
          searchFilters
        );
      })
      .then(response => {
        const savedView = response.toObject().savedView;
        setShowSavedViewModal(false);
        setResponseNotification(
          `${savedView?.name} saved view has been created`
        );
      })
      .catch(() => {
        setShowSavedViewModal(false);
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const saveSmartAlbum = () => {
    if (!inputValue) return setError('Enter a name');

    api()
      .then(({ createSmartAlbum }) => {
        const filters = getFilters();
        return createSmartAlbum(inputValue, id, filters, searchFilters);
      })
      .then(response => {
        const smartAlbum = response.toObject().smartAlbum;
        setShowSmartAlbumModal(false);
        setResponseNotification(
          `${smartAlbum?.name} smart album has been created`
        );
      })
      .catch(() => {
        setShowSmartAlbumModal(false);
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      showSavedViewModal ? saveCurrentView() : saveSmartAlbum();
    }
  };

  const modalTitle = showSavedViewModal
    ? 'Save as new view'
    : 'Save as smart album';

  const modalSubHeader = showSavedViewModal
    ? 'Views let you save your sorting and filtering for easy access.'
    : 'Smart albums automatically collect and display photos that meet the criteria you specified.';

  const inputName = showSavedViewModal ? 'Name' : 'Smart album name';

  const buttonText = showSavedViewModal ? 'Save View' : 'Save Album';

  const closeModal = () => {
    showSavedViewModal
      ? setShowSavedViewModal(false)
      : setShowSmartAlbumModal(false);
  };

  const handleClick = showSavedViewModal ? saveCurrentView : saveSmartAlbum;

  return (
    <SaveModalBody
      buttons={[
        <SecondaryButton
          key="cancel"
          name="cancel"
          onClick={closeModal}
          status="neutral"
        >
          Cancel
        </SecondaryButton>,
        <PrimaryButton
          disabled={!inputValue}
          key="save"
          name="save"
          onClick={handleClick}
          status="default"
        >
          {buttonText}
        </PrimaryButton>,
      ]}
      closeClick={closeModal}
      title={modalTitle}
    >
      <SubHeader>{modalSubHeader}</SubHeader>
      <Input
        aria-label={inputName}
        errors={error}
        label={inputName}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        value={inputValue}
      />
    </SaveModalBody>
  );
};

export { SaveModal };
