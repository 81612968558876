import {
  ListAlbumsResponse,
  ListAlbumAssetsResponse,
  ListSmartAlbumAssetsResponse,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_api_pb';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

export enum ActionTypes {
  SetPageLoading = 'app/album/SetPageLoading',
  PageLoaded = 'app/album/PageLoaded',
  PageLoadFailed = 'app/album/PageLoadFailed',

  SetRecordLoading = 'app/album/SetRecordLoading',
  RecordLoaded = 'app/album/RecordLoaded',
  RecordLoadFailed = 'app/album/RecordLoadFailed',

  SetAssetsPageLoading = 'app/album/SetAssetsPageLoading',
  AssetsPageLoaded = 'app/album/AssetsPageLoaded',
  AssetsPageLoadFailed = 'app/album/AssetsPageLoadFailed',

  SetSmartAssetsPageLoading = 'app/album/SetSmartAssetsPageLoading',
  SmartAssetsPageLoaded = 'app/album/SmartAssetsPageLoaded',
  SmartAssetsPageLoadFailed = 'app/album/AssetsPageLoadFailed',

  AddAlbum = 'app/album/AddAlbum',
  ResetPagination = 'app/album/ResetPagination',
}

interface SetPageLoading {
  type: ActionTypes.SetPageLoading;
  payload: {
    pageNumber: number;
  };
}

interface PageLoaded {
  type: ActionTypes.PageLoaded;
  payload: {
    pageNumber: number;
    response: ListAlbumsResponse;
  };
}

interface PageLoadFailed {
  type: ActionTypes.PageLoadFailed;
  payload: {
    pageNumber: number;
  };
}

interface SetRecordLoading {
  type: ActionTypes.SetRecordLoading;
  payload: { id?: string };
}

interface RecordLoaded {
  type: ActionTypes.RecordLoaded;
  payload: { id?: string; record: Album | SmartAlbum };
}

interface RecordLoadFailed {
  type: ActionTypes.RecordLoadFailed;
  payload: { id?: string };
}

interface SetAssetsPageLoading {
  type: ActionTypes.SetAssetsPageLoading;
  payload: {
    albumId: string;
    pageNumber: number;
  };
}

interface AssetsPageLoaded {
  type: ActionTypes.AssetsPageLoaded;
  payload: {
    albumId: string;
    pageNumber: number;
    response: ListAlbumAssetsResponse;
  };
}

interface AssetsPageLoadFailed {
  type: ActionTypes.AssetsPageLoadFailed;
  payload: {
    albumId: string;
    pageNumber: number;
  };
}

interface SetSmartAssetsPageLoading {
  type: ActionTypes.SetSmartAssetsPageLoading;
  payload: {
    albumId: string;
    pageNumber: number;
  };
}

interface SmartAssetsPageLoaded {
  type: ActionTypes.SmartAssetsPageLoaded;
  payload: {
    albumId: string;
    pageNumber: number;
    response: ListSmartAlbumAssetsResponse;
  };
}

interface SmartAssetsPageLoadFailed {
  type: ActionTypes.SmartAssetsPageLoadFailed;
  payload: {
    albumId: string;
    pageNumber: number;
  };
}

interface AddAlbum {
  type: ActionTypes.AddAlbum;
  payload: {
    album: Album;
  };
}

interface ResetPagination {
  type: ActionTypes.ResetPagination;
}

export type Actions =
  | SetPageLoading
  | PageLoaded
  | PageLoadFailed
  | SetRecordLoading
  | RecordLoaded
  | RecordLoadFailed
  | SetAssetsPageLoading
  | AssetsPageLoaded
  | AssetsPageLoadFailed
  | SetSmartAssetsPageLoading
  | SmartAssetsPageLoaded
  | SmartAssetsPageLoadFailed
  | AddAlbum
  | ResetPagination;
