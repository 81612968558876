import React, { FC, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  border,
  color,
  H3,
  Input,
  PopupModal,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  typography,
} from '@gsc/components';
import { Delete, Unsaved } from '@gsc/icons/react';
import {
  stringifyFilters,
  useQueryParams,
} from '../../../../../state/QueryParams/hooks';
import { useAlbumsAPI } from '../../../../../state/Albums';
import { useCurrentSpaceObj } from '../../../../../state/Spaces';
import { SpaceTierType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { SavedView } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const TriggerButton = styled(SecondaryButton)`
  margin-left: auto;
`;

const StyledPopupModal = styled(PopupModal)`
  height: 100%;
  min-width: auto;
  padding: 0;
  width: 100%;

  & > svg {
    left: 2.4rem;
    top: 2rem;
  }

  &.saved-view-error {
    padding-top: 3.2rem;

    & > svg {
      left: 2.4rem;
      top: 17rem;
    }
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  border-bottom: ${border.normal(color.ui15)};
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  left: 0;
  position: fixed;
  width: 100%;
`;

const ModalHeaderText = styled(H3)`
  font-size: 1.6rem;
  margin-bottom: 0;
  margin-left: calc(50% - 4.5rem);
`;

const EditButton = styled(TertiaryButton)`
  padding-right: 2.4rem;

  &:focus,
  &:not(.show-loader):hover {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
`;

const InnerContainer = styled.div`
  padding: 8rem 2.4rem 2.4rem;
`;

const SubHeader = styled.p`
  ${typography.body};
  color: #6b717c;
  padding-bottom: 2.4rem;
`;

const ListContainer = styled.ul`
  margin-top: 5.6rem;
  max-height: calc(100% - 12.7rem);
  overflow-y: scroll;
`;

const ListItem = styled.li`
  align-items: center;
  background-color: ${color.ui01};
  border-bottom: ${border.normal(color.ui15)};
  color: ${color.ui50};
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  line-height: 2rem;
  white-space: nowrap;
  width: 100%;

  &.list-header {
    font-weight: 500;
    padding: 1.4rem 2.4rem;
  }
`;

const ListItemTextWrapper = styled.div`
  padding: 1.4rem 2.4rem;
  width: calc(100% - 5.3rem);
`;

const ListItemText = styled.p`
  display: inline-block;
`;

const DeleteButton = styled(TertiaryButton)`
  padding-right: 2.4rem;

  &:focus,
  &:not(.show-loader):hover {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
`;

const StyledDeleteIcon = styled(Delete)`
  color: ${color.ui50};
  height: 1.6rem;
`;

const FooterWrapper = styled.div`
  background-color: ${color.ui01};
  border-top: ${border.normal(color.ui15)};
  bottom: 0;
  display: flex;
  left: 0;
  padding: 1.6rem 2.4rem;
  position: fixed;
  width: 100%;

  & > button.button-right {
    margin-left: 1.6rem;
  }
`;

interface MobileSavedTakeOverProps {
  setResponseNotification: (args: string) => any;
}

const MobileSavedTakeOver: FC<MobileSavedTakeOverProps> = ({
  setResponseNotification,
}) => {
  const api = useAlbumsAPI();
  const {
    getFilters,
    searchFilters,
    sortDirection,
    sortField,
  } = useQueryParams();
  const currentSpace = useCurrentSpaceObj();

  const [createNew, setCreateNew] = useState({ type: '' });
  const [inputValue, setInputValue] = useState('');
  const [toggleTakeOver, setToggleTakeOver] = useState(false);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [savedViewItems, setSavedViewItems] = useState<SavedView.AsObject[]>(
    []
  );
  const history = useHistory();

  const fetchViewsList = () => {
    api()
      .then(({ listSavedViews }) => {
        return listSavedViews(currentSpace.id);
      })
      .then(res => {
        const list = res.toObject().savedViewsList;
        setSavedViewItems(list);
        setToggleTakeOver(true);
      })
      .catch(() => {
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const filters = getFilters();

  const closeTakeOver = () => {
    setInputValue('');
    setToggleTakeOver(false);
    setCreateNew({ type: '' });
  };

  const cancelCreateNew = () => {
    setInputValue('');
    setCreateNew({ type: '' });
  };

  const handleInputChange = (e: FormEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    if (value) setError('');
    setInputValue(value);
  };

  const createSavedView = () => {
    if (!inputValue) return setError('Enter a name');

    api()
      .then(({ createSavedView }) => {
        return createSavedView(
          inputValue,
          currentSpace.id,
          filters,
          sortDirection,
          sortField,
          searchFilters
        );
      })
      .then(response => {
        const savedView = response.toObject().savedView;
        closeTakeOver();
        setResponseNotification(
          `${savedView?.name} saved view has been created`
        );
      })
      .catch(() => {
        closeTakeOver();
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const deleteSavedView = (id: string) => {
    api()
      .then(({ deleteSavedView }) => {
        return deleteSavedView(id);
      })
      .then(() => {
        const newList = savedViewItems.filter(view => view.id !== id);
        setSavedViewItems(newList);
      })
      .catch(() => {
        closeTakeOver();
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const createSmartAlbum = () => {
    if (!inputValue) return setError('Enter a name');

    api()
      .then(({ createSmartAlbum }) => {
        return createSmartAlbum(
          inputValue,
          currentSpace.id,
          filters,
          searchFilters
        );
      })
      .then(response => {
        const smartAlbum = response.toObject().smartAlbum;
        closeTakeOver();
        setResponseNotification(
          `${smartAlbum?.name} smart album has been created`
        );
      })
      .catch(() => {
        closeTakeOver();
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      createNew.type === 'view' ? createSavedView() : createSmartAlbum();
    }
  };

  const listItems = savedViewItems
    ?.sort((a, b) => b.createdAtTime - a.createdAtTime)
    .map(item => {
      const spaces = /\s/g;
      const combinedName = item.name.toLowerCase().replace(spaces, '-');

      return (
        <ListItem key={item.id}>
          <ListItemTextWrapper
            onClick={() => {
              history.replace({ search: stringifyFilters(item) });
              closeTakeOver();
            }}
          >
            <ListItemText>{item.name}</ListItemText>
          </ListItemTextWrapper>
          {editMode && (
            <DeleteButton
              data-test={`delete-${combinedName}`}
              isIconOnly
              status="neutral"
              onClick={() => deleteSavedView(item.id)}
            >
              <StyledDeleteIcon />
            </DeleteButton>
          )}
        </ListItem>
      );
    });

  if (currentSpace.tier?.type === SpaceTierType.SPACE_TIER_TYPE_LITE)
    return null;

  const listView = (
    <>
      <ListContainer>
        <ListItem className="list-header">MY VIEWS</ListItem>
        {listItems}
      </ListContainer>
      <FooterWrapper>
        <PrimaryButton
          disabled={filters.length === 0}
          key="save-smart-album"
          isFullWidth
          name="save-smart-album"
          onClick={() => setCreateNew({ type: 'album' })}
          status="default"
        >
          Save as smart album
        </PrimaryButton>
        <PrimaryButton
          className="button-right"
          disabled={filters.length === 0}
          key="saved-view"
          isFullWidth
          name="saved-view"
          onClick={() => setCreateNew({ type: 'view' })}
          status="default"
        >
          Save current view
        </PrimaryButton>
      </FooterWrapper>
    </>
  );

  const subHeaderText =
    createNew.type === 'view'
      ? 'Views let you save your sorting and filtering for easy access.'
      : 'Smart albums automatically collect and display photos that meet the criteria you specified.';

  const inputName = createNew.type === 'view' ? 'Name' : 'Smart album name';

  const buttonText = createNew.type === 'view' ? 'Save View' : 'Save Album';

  const handleClick =
    createNew.type === 'view' ? createSavedView : createSmartAlbum;

  const saveView = (
    <InnerContainer>
      <>
        <SubHeader>{subHeaderText}</SubHeader>
        <Input
          aria-label={inputName}
          errors={error}
          label={inputName}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          value={inputValue}
        />
      </>
      <FooterWrapper>
        <SecondaryButton
          isFullWidth
          key="cancel-saved-view"
          name="cancel-saved-view"
          onClick={() => cancelCreateNew()}
          status="neutral"
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          className="button-right"
          disabled={!inputValue}
          isFullWidth
          key="current-saved-view"
          name="current-saved-view"
          onClick={handleClick}
          status="default"
        >
          {buttonText}
        </PrimaryButton>
      </FooterWrapper>
    </InnerContainer>
  );

  const modalHeaderText = () => {
    switch (createNew.type) {
      case '':
        return 'Saved Views';
      case 'view':
        return 'Save current view';
      case 'album':
        return 'Save current album';
      default:
        return 'Saved Views';
    }
  };

  return (
    <>
      <TriggerButton onClick={() => fetchViewsList()} status="neutral">
        <Unsaved />
        Saved Views
      </TriggerButton>

      {toggleTakeOver && (
        <>
          <StyledPopupModal closeClick={() => closeTakeOver()}>
            <ModalHeader>
              <ModalHeaderText>{modalHeaderText()}</ModalHeaderText>
              <EditButton onClick={() => setEditMode(!editMode)}>
                {editMode ? 'Done' : 'Edit'}
              </EditButton>
            </ModalHeader>

            {createNew.type === '' ? listView : saveView}
          </StyledPopupModal>
        </>
      )}
    </>
  );
};

export { MobileSavedTakeOver };
