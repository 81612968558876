import { useContext } from 'react';
import { ComplianceStateContext } from '../context';

export const useComplianceContext = () => {
  const context = useContext(ComplianceStateContext);

  if (context === undefined) {
    throw new Error(
      'useComplianceContext must be used within a ComplianceProvider'
    );
  }

  return context;
};
