import { Dispatch, useEffect } from 'react';
import { Actions, ActionTypes } from './actions';
import { api } from './api';
import { useMetadata } from '../Auth';
import { useCurrentSpace, useCurrentSpaceObj } from '../Spaces';
import { useQueryParams } from '../QueryParams/hooks';

const useGetPhotos = (dispatch: Dispatch<Actions>) => {
  const getMetadata = useMetadata();
  const { id } = useCurrentSpace();
  const { getFilters, sortField, sortDirection } = useQueryParams();

  useEffect(() => {
    if (!id) return; // we don't allow teenagers in here.

    const filters = getFilters();

    dispatch({
      type: ActionTypes.ClearPhotosResponse,
    });

    dispatch({
      type: ActionTypes.SetPageLoading,
      payload: { pageNumber: 0 },
    });

    getMetadata()
      .then(metadata =>
        api(metadata, id).getPhotoSearch({ filters, sortField, sortDirection })
      )
      .then(data => {
        dispatch({
          type: ActionTypes.GetPhotosResponse,
          payload: {
            photos: data,
            pageNumber: 0,
          },
        });

        dispatch({
          type: ActionTypes.SetTotalHits,
          payload: { totalHits: data.getTotalHits() },
        });
      });
  }, [id, dispatch, getMetadata, getFilters, sortField, sortDirection]);
};

const useClearSelectionsWithSpace = (dispatch: Dispatch<Actions>) => {
  const currentSpaceId = useCurrentSpaceObj()?.id;
  useEffect(() => {
    dispatch({
      type: ActionTypes.SetMobileSelectMode,
      payload: { mobileSelectMode: false },
    });
  }, [currentSpaceId, dispatch]);
};

export { useGetPhotos, useClearSelectionsWithSpace };
