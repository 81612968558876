import React from 'react';
import { baseTheme } from '../main/theme/base-theme';
import { cssReset, makeTheme } from '../main/theme';

export interface ThemeProvider {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProvider) => {
  const theme = makeTheme(baseTheme);

  const variablesTag = document.createElement('style');

  variablesTag.innerHTML = `html {${theme}}`;

  variablesTag.id = 'css-variables';

  document.head.appendChild(variablesTag);

  cssReset();

  return <>{children}</>;
};
