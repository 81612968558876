import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { color, font } from '@gsc/components';
import { Switch } from '@gsc/icons/react';
import { ButtonMenu, DropdownMenuLink } from '../../shared/components';
import { useAuth } from '../../state/Auth';
import { paths } from '../../paths';

const StyledDropdownMenuLink = styled(DropdownMenuLink)`
  border-left: 0;
  padding: 0;
`;

const MenuTitle = styled.h5`
  color: ${color.ui50};
  cursor: default;
  font-weight: ${font.weight.medium};
  margin: 0;
  padding: 0 1.2rem;
  transform: translateX(-1.2rem);
  width: calc(100% + 2.4rem);
`;

const StyledButtonMenu = styled(ButtonMenu)`
  margin-right: 1.6rem;
`;

interface MenuItemsProps {
  children: React.ReactNode;
  dataTest?: string;
  disabled?: boolean;
  onSelect?(): any;
}

const SwitchToNav: FC = () => {
  const history = useHistory();
  const { isApertureAdmin } = useAuth();

  const menuItems = () => {
    const title: MenuItemsProps = {
      children: <MenuTitle>SWITCH TO</MenuTitle>,
      dataTest: 'switch-to-nav-link-menu-title',
      disabled: true,
    };

    const admin: MenuItemsProps = {
      children: (
        <StyledDropdownMenuLink
          data-test="switch-to-nav-link-admin"
          key="admin-switch-to-nav-link"
          to={paths.admin.root}
        >
          Admin
        </StyledDropdownMenuLink>
      ),
      onSelect: () => history.push(paths.admin.root),
    };

    const gospotcheck: MenuItemsProps = {
      children: (
        <StyledDropdownMenuLink
          as="a"
          data-test="gospotcheck-switch-to-nav-link"
          href="https://admin.gospotcheck.com/"
          key="switch-to-nav-link-gospotcheck"
          rel="noopener"
          target="_blank"
        >
          GoSpotCheck
        </StyledDropdownMenuLink>
      ),
      onSelect: () => window.open('https://admin.gospotcheck.com/'),
    };
    return isApertureAdmin ? [title, admin, gospotcheck] : [title, gospotcheck];
  };

  return (
    <StyledButtonMenu
      alignRight
      dataTest="switch-to-nav"
      icon={<Switch />}
      items={menuItems()}
      status="neutral"
      variant="tertiary"
    />
  );
};

export { SwitchToNav };
