import React, { forwardRef } from 'react';

import { TextInput, TextInputProps } from './text-input';

export type InputProps = TextInputProps;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function Component(props, ref) {
    return <TextInput {...props} ref={ref} />;
  }
);
