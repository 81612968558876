import styled from 'styled-components';
import { breakpoint, color, H2, H6 } from '@gsc/components';
import { FixedSizeGrid } from 'react-window';
import { ShareLink } from '@gsc/icons/react';

const AlbumsLayout = styled.div`
  padding: 5.5rem 2.4rem 0;

  ${breakpoint.lg`
    & {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
  `}

  ${breakpoint.xxxl`
    & {
      margin-right: auto;
      margin-left: auto;
      max-width: 192rem;
    }
  `}
`;

const AlbumsPageHeader = styled(H2)`
  font-weight: normal;
  padding-top: 3.5rem;
  margin-bottom: 2.4rem;
`;

const AlbumFixedSizeGrid = styled(FixedSizeGrid)`
  height: 100% !important;
  user-select: none;
  overflow: hidden !important;
  margin: 0 -1.6rem;
  padding-bottom: 3rem;
`;

const AlbumPhotosFixedSizeGrid = styled(FixedSizeGrid)`
  height: 100% !important;
  user-select: none;
  overflow: hidden !important;
  margin: 2.4rem -1.6rem 0;
  padding-bottom: 3rem;
`;

const AlbumGridCell = styled.div.attrs({ role: 'gridcell' })`
  padding: 1.6rem;
  cursor: pointer;
`;

const AlbumTitle = styled(H6)`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 1.6rem;
  color: #222b3b;
`;

const AlbumSubTitle = styled.p`
  color: #222b3b;
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: -0.5rem;
`;

const ShareIconWrapper = styled.span`
  border-radius: 50%;
  border: 0.1rem solid ${color.mxDeepOcean};
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-left: 1.2rem;
  width: 2rem;
`;

const ShareFlag = styled(ShareLink)`
  color: ${color.mxDeepOcean};
  width: 1.2rem;
`;

const TextLoading = styled.div.attrs({ title: 'Loading...' })`
  background-color: ${color.ui10};
  border-radius: 99rem;
  height: 1.6rem;
  width: 90%;
`;

const AlbumHoverOverlay = styled.div`
  position: absolute;
  width: calc(100% - 3.4rem);
  top: 1.8rem;
  left: 1.7rem;
  background: rgba(0, 0, 0, 0.32);
  border-radius: 1.2rem;
  transition: opacity 0.25s linear;
  opacity: 0;

  *:hover > & {
    opacity: 1;
  }
`;

export {
  AlbumsLayout,
  AlbumsPageHeader,
  AlbumFixedSizeGrid,
  AlbumPhotosFixedSizeGrid,
  AlbumGridCell,
  AlbumTitle,
  AlbumSubTitle,
  ShareIconWrapper,
  ShareFlag,
  TextLoading,
  AlbumHoverOverlay,
};
