import { useMemo } from 'react';
import moment from 'moment';

const clone = (obj: any) => JSON.parse(JSON.stringify(obj));

const formatParamToString = (str: string) => {
  return str.split('_').join(' ');
};

const isEmpty = (obj: any) => Object.entries(obj).length === 0;

const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const isMobileDevice = () => {
  if (navigator.userAgent.includes('Android')) {
    return true;
  } else if (navigator.userAgent.includes('Mobile')) {
    return true;
  } else if (
    navigator.platform === 'MacIntel' &&
    navigator.maxTouchPoints > 1
  ) {
    // this check is because iPad userAgent now mimics desktop
    return true;
  } else {
    return false;
  }
};

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const R = [];
  for (let i = 0, len = array.length; i < len; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
}

function ensureIsArray<T>(maybeArray: T[] | T | null | undefined): T[] {
  if (maybeArray == null) {
    return [];
  } else if (Array.isArray(maybeArray)) {
    return maybeArray;
  } else {
    return [maybeArray];
  }
}

function useEnsureIsArray<T>(maybeArray: T[] | T | null | undefined): T[] {
  return useMemo(() => ensureIsArray(maybeArray), [maybeArray]);
}

const isLast7Days = () => moment().subtract(6, 'days');
const isLast30Days = () => moment().subtract(29, 'days');
const isThisMonth = () => moment().date(1);
const isThisYear = () => moment().dayOfYear(1);
const isEndOfLastMonth = () =>
  moment()
    .subtract(1, 'month')
    .endOf('month');
const isStartOfLastMonth = () =>
  moment()
    .subtract(1, 'month')
    .startOf('month');

export {
  clone,
  formatParamToString,
  isMobileDevice,
  isEmpty,
  uppercaseFirstLetter,
  chunkArray,
  ensureIsArray,
  useEnsureIsArray,
  isLast7Days,
  isLast30Days,
  isThisMonth,
  isThisYear,
  isEndOfLastMonth,
  isStartOfLastMonth,
};
