import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { H1, Notification } from '@gsc/components';
import moment from 'moment';
import { useSpacesAPI, useSpacesDispatch } from '../../../state/Spaces';
import { FormProvider } from '../../../shared/components/FormContext';
import { NewSpaceForm } from './components/NewSpaceForm';
import {
  SourceIdType,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

interface ResponseErrorProps {
  code: number;
  id: string;
}

const StyledNotification = styled(Notification)`
  margin-bottom: 1.6rem;
`;

const COMPANY_ID_TEXT = 'Company ID';
const COMPANY_GROUP_ID_TEXT = 'Company Group ID';

const NewSpace: FC = () => {
  const history = useHistory();
  const spacesAPI = useSpacesAPI();
  const { dispatch, actions } = useSpacesDispatch();
  const [responseError, setResponseError] = useState<
    ResponseErrorProps | undefined
  >();
  const [companyText, setCompanyText] = useState(COMPANY_ID_TEXT);

  const validate = (values: any) => {
    const errors: any = {};
    const sourceIdType = parseInt(values.sourceIdType, 10);
    if (
      sourceIdType === SourceIdType.SOURCE_ID_TYPE_COMPANY_ID &&
      !values.sourceId
    ) {
      errors.sourceId = `${COMPANY_ID_TEXT} is required.`;
    } else if (
      sourceIdType === SourceIdType.SOURCE_ID_TYPE_COMPANY_GROUP_ID &&
      !values.sourceId
    ) {
      errors.sourceId = `${COMPANY_GROUP_ID_TEXT} is required.`;
    } else if (
      values.tier.type === SpaceTierType.SPACE_TIER_TYPE_TRIAL &&
      !values.tier.trialEndDate
    ) {
      errors.tier = 'Trial end date is required.';
    }

    return errors;
  };

  return (
    <>
      <Helmet>
        <title>PhotoWorks - Create PhotoWorks Space</title>
        <meta name="description" content="Create PhotoWorks Space" />
      </Helmet>

      {responseError && (
        <StyledNotification
          dataTest="new-space-notification"
          onClick={() => setResponseError(undefined)}
          status="negative"
        >
          {responseError.code === 6
            ? `A PhotoWorks space already exists for ${companyText} ${responseError.id}.`
            : 'Uh oh! Something went wrong on our end.'}
        </StyledNotification>
      )}

      <H1>Create PhotoWorks Space</H1>

      <FormProvider
        onSubmit={(values: any) => {
          if (
            values.sourceIdType ===
            JSON.stringify(SourceIdType.SOURCE_ID_TYPE_COMPANY_ID)
          ) {
            setCompanyText(COMPANY_ID_TEXT);
          } else {
            setCompanyText(COMPANY_GROUP_ID_TEXT);
          }

          spacesAPI()
            .then(({ saveSpace }) => {
              return saveSpace(values);
            })
            .then(space => {
              dispatch({
                type: actions.AddSpace,
                payload: {
                  space,
                },
              });
              history.push('/admin');
            })
            .catch(e => {
              setResponseError({ code: e.code, id: values.sourceId });
            });
        }}
        initialValues={{
          sourceIdType: SourceIdType.SOURCE_ID_TYPE_COMPANY_ID,
          tier: {
            trialEndDate: moment().add(30, 'd'),
            type: SpaceTierType.SPACE_TIER_TYPE_TRIAL,
          },
        }}
        onValidate={validate}
      >
        <NewSpaceForm isInvalid={responseError?.code === 3} />
      </FormProvider>
    </>
  );
};

export { NewSpace };
