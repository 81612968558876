import React from 'react';

import { useModalContext } from '../../modal-context';
import { Container } from './modal-content.styles';

export interface ModalContentProps {
  children?: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export const ModalContent = React.forwardRef<HTMLDivElement, ModalContentProps>(
  ({ children, className, dataTestId = 'modal-content', ...props }, ref) => {
    const context = useModalContext();

    if (!context)
      throw new Error('ModalContent must be used inside a Modal component');

    return (
      <Container
        {...props}
        data-testid={dataTestId}
        ref={ref}
        id={context.describedBy}
        className={className}
      >
        {children}
      </Container>
    );
  }
);

ModalContent.displayName = 'ModalContent';
Object.defineProperty(ModalContent, 'modalComponent', { value: true });
