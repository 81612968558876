import React, { FC } from 'react';
import styled from 'styled-components';
import { color } from '@gsc/components';
import { CdnImageWrapper } from '../Photos/shared/components/CdnImageWrapper';

const PreviewImageBox = styled.div.attrs({ role: 'listbox' })`
  display: flex;
  flex-direction: column;
`;

const PreviewImageRow = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
`;

const PreviewImagePane = styled.div.attrs({ role: 'listitem' })`
  background-color: #dadcde;
  background-position: center;
  background-size: cover;
  height: calc(100% - 0.2rem);
  margin: 0.2rem 0.1rem;
  overflow: hidden;
  position: relative;
  width: calc(100% - 0.2rem);
`;

const SmartLabel = styled.div`
  align-items: center;
  background-color: ${color.mxDeepOcean};
  border-radius: 1.2rem;
  bottom: 0.4rem;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  height: 2rem;
  left: 0.4rem;
  line-height: 1.2rem;
  padding: 0.4rem 0.8rem;
  position: absolute;
  width: 8.6rem;
`;

interface PreviewImagesProps {
  boxSize: number;
  previewUrls?: string[];
  smartAlbum?: boolean;
}

const PreviewImages: FC<PreviewImagesProps> = ({
  boxSize,
  previewUrls = [],
  smartAlbum,
}) => (
  <PreviewImageBox style={{ width: boxSize, height: boxSize }}>
    <PreviewImageRow>
      <PreviewImagePane style={{ borderTopLeftRadius: 12 }}>
        {previewUrls[0] && (
          <CdnImageWrapper
            alt="Preview 1"
            imageUrl={previewUrls[0]}
            style={{
              width: boxSize / 2,
              height: boxSize / 2,
              objectFit: 'cover',
            }}
          />
        )}
      </PreviewImagePane>
      <PreviewImagePane style={{ borderTopRightRadius: 12 }}>
        {previewUrls[1] && (
          <CdnImageWrapper
            alt="Preview 2"
            imageUrl={previewUrls[1]}
            style={{
              width: boxSize / 2,
              height: boxSize / 2,
              objectFit: 'cover',
            }}
          />
        )}
      </PreviewImagePane>
    </PreviewImageRow>
    <PreviewImageRow>
      <PreviewImagePane style={{ borderBottomLeftRadius: 12 }}>
        {previewUrls[2] && (
          <CdnImageWrapper
            alt="Preview 3"
            imageUrl={previewUrls[2]}
            style={{
              width: boxSize / 2,
              height: boxSize / 2,
              objectFit: 'cover',
            }}
          />
        )}
        {smartAlbum && <SmartLabel>SMART ALBUM</SmartLabel>}
      </PreviewImagePane>
      <PreviewImagePane style={{ borderBottomRightRadius: 12 }}>
        {previewUrls[3] && (
          <CdnImageWrapper
            alt="Preview 4"
            imageUrl={previewUrls[3]}
            style={{
              width: boxSize / 2,
              height: boxSize / 2,
              objectFit: 'cover',
            }}
          />
        )}
      </PreviewImagePane>
    </PreviewImageRow>
  </PreviewImageBox>
);

export { PreviewImages };
