import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../../../style/breakpoint';
import { ProductsEntity } from '../../../../../../../state/Compliance/wapi_planogram_api';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import { ComplianceProductBox } from './ComplianceProductBox';
import {
  EmptyFacingsIcon,
  IncorrectItemsIcon,
  IncorrectPositionIcon,
  // MissingShelfTagsIcon,
  NotOnShelfIcon,
  UnrecognizedProduct,
} from '../../../../../icons/Icons';
import { Dropdown, DropdownItem } from '../../../../../components/Dropdown';
import { SummaryAdvancedOptionsMenu } from './SummaryAdvancedOptionsMenu';
import { ComplianceJsonModal } from './ComplianceJsonModal';
import { useComplianceContext } from '../../../../../../../state/Compliance/hooks/useComplianceContext';

interface ComplianceSummaryProps {
  propsToCome?: string;
  matchesError: string | boolean;
}

const Root = styled.div`
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  & span {
    font-family: Helvetica Neue, sans-serif;
  }
`;
const SummaryHeaderContainer = styled.div<{ scrollTop: number }>`
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 1.2rem;
  background: #fff;
  box-shadow: ${p =>
    p.scrollTop > 0 ? '0px 6px 8px 0px rgba(38, 43, 47, 0.12)' : 'none'};
  z-index: 2;
  ${breakpoint.md`
    & {
      padding: 2.0rem;
    }
  `};
  ${breakpoint.lg`
    & {
      position: fixed;
      top: 10.8rem;
      width: 52.8rem;
      padding: 2.4rem;
    }
  `}
`;
const ContentContainer = styled.div`
  ${breakpoint.lg`
  & {
    position: relative;
    top: 9rem;
    height: calc(100vh - 20rem );
    overflow: auto;
  }
`}
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ProductTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.2rem;
  ${breakpoint.md`
    & {
      padding-left: .8rem;
      padding-right: .8rem;
    }
  `};
  ${breakpoint.lg`
    & {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
  `}
`;

const ProductContainer = styled.div`
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`;

const ComplianceTitle = styled.span`
  color: #262b2f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.2rem; /* 122.222% */
`;

const ProductTitle = styled.span`
  color: #262b2f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 122.222% */
`;

const ProductGroupingTitleDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
`;

const ProductGroupingTitle = styled.span`
  color: #262b2f;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
`;

const ProductGroupingProducts = styled.div`
  padding-bottom: 1.2rem;
`;

const ShowByTitle = styled.span`
  color: #727578;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  margin-right: 0.8rem;
`;

const NoProductsMsg = styled.div`
  color: #262b2f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.2rem; /* 122.222% */
  padding-left: 1.2rem;
`;

const CompliancePercentage = styled.span<{ error: boolean }>`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${p => (p.error ? '#d0021b' : '#262b2f')};
`;

const FlexSpacer = styled.div`
  flex-grow: 1;
`;

const Segments = styled.div`
  padding-top: 0.8rem;
  color: var(--colors-charlestonGrey65);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem; /* 121.429% */
`;

const SegmentNumbers = styled.span`
  color: #262b2f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem;
`;

const ResultTimestamp = styled.div`
  color: grey;
  margin-top: 2px;
  font-size: 1.05rem;
`;

const ResultFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  gap: 1.6rem;
  ${breakpoint.md`
    & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 2rem;
      gap: 1.6rem;
    }
  `}
  ${breakpoint.lg`
    & {
      padding: 2.4rem;
      gap: 1.6rem;
    }
  `}
`;

const ResultFilter = styled.div<{ selectable?: boolean; selected?: boolean }>`
  border: ${p => (p.selected ? '.1rem solid #A4C9D6' : '.1rem solid #E5E6E6')};
  border-radius: 0.4rem;
  width: 100%;
  padding: 0.6rem;
  align-items: center;
  display: flex;
  cursor: ${p => (p.selectable ? 'pointer' : undefined)};
  background: ${p => (p.selected ? '#F1F7F9' : 'transparent')};
  &:hover {
    background: #f6f7f7;
  }
  ${breakpoint.lg`
    & {
      padding: 1.2rem;
    }
  `}
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: end;
`;

const FilterCount = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 0.8rem;
`;

const FilterTotal = styled.span`
  color: #262b2f;
  margin-left: 0.4rem;
  font-size: 1.2rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
`;

const FilterTitle = styled.span`
  color: #727578;
  margin-left: 0.4rem;
  font-size: 1.2rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
`;

const ErrorBanner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    padding: 1rem;
    width: 100%;
    color: #d0021b;
    background: #d0021b17;
    border-radius: 8px;
  }

  ${breakpoint.md`
    & {
      padding: 0 1rem;
    }
  `};
  ${breakpoint.lg`
    & {
      position: fixed;
      top: 22rem;
      width: 52.8rem;
      padding: 0 1.4rem;
    }
  `}
`;

type ResultFilterBoxProps = {
  Icon: any;
  count: number;
  text: string;
  mobileText?: string;
  total?: number;
  onClick?: (e: React.MouseEvent) => void;
  selected?: boolean;
  testId?: string;
};

export enum ProductTag {
  NotOnShelf = 'NotOnShelf',
  IncorrectPosition = 'IncorrectPosition',
  MissingShelfTag = 'MissingShelfTag',
  UnplannedItem = 'UnplannedItem',
  EmptyFacings = 'EmptyFacings',
  UnrecognizedProduct = 'UnrecognizedProduct',
}

/// ('h:mm A z, dddd, MMMM D, YYYY')
const dateFormatForTimestamp = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});
const timeFormatForTimestamp = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
});
const FormatDateForTimestamp = (date: Date) =>
  timeFormatForTimestamp.format(date) +
  ', ' +
  dateFormatForTimestamp.format(date);

const ResultFilterBox = ({
  Icon,
  count,
  text,
  mobileText,
  total,
  onClick,
  selected,
  testId,
}: ResultFilterBoxProps) => {
  const innerWidth = window.innerWidth;
  return (
    <ResultFilter
      onClick={onClick}
      selectable={!!onClick}
      selected={selected}
      data-test={testId}
    >
      <Icon />
      <FilterContainer>
        {innerWidth <= 1024 ? (
          <>
            <FilterCount>{count}</FilterCount>
            <FilterTitle>{mobileText ?? text}</FilterTitle>
          </>
        ) : (
          <>
            <FilterCount>{count}</FilterCount>
            {total !== undefined && <FilterTotal>/{total}</FilterTotal>}
            <FilterTitle>{text}</FilterTitle>
          </>
        )}
      </FilterContainer>
    </ResultFilter>
  );
};

enum ShowBy {
  Items = 'Items',
  Segments = 'Segments',
  Brands = 'Brands',
  Suppliers = 'Suppliers',
}

type ProductsProps = {
  showBy?: ShowBy;
  products: ProductsEntity[];
};

const Products: FC<ProductsProps> = ({ showBy, products }: ProductsProps) => {
  const contextState = useContext(ComplianceStateContext)?.state;

  const groupBySuppliers = () => {
    const result = products.reduce((rv, product) => {
      const brandbankItem = contextState?.brandbankMap.get(
        product.brandbank_uuid
      );
      if (brandbankItem) {
        const key = `${brandbankItem.supplier}`;
        const products = rv.get(key) ?? [];
        products.push(product);
        rv.set(key, products);
      }
      return rv;
    }, new Map<string, ProductsEntity[]>());
    return result;
  };

  const groupByBrands = () => {
    const result = products.reduce((rv, product) => {
      const brandbankItem = contextState?.brandbankMap.get(
        product.brandbank_uuid
      );
      if (brandbankItem) {
        const key = `${brandbankItem.brand}`;
        const products = rv.get(key) ?? [];
        products.push(product);
        rv.set(key, products);
      }
      return rv;
    }, new Map<string, ProductsEntity[]>());
    return result;
  };

  const render = () => {
    switch (showBy) {
      case ShowBy.Items:
        if (products.length <= 0)
          return <NoProductsMsg>No product data available</NoProductsMsg>;
        return products
          .slice(0, 100)
          .map((product, index) => (
            <ComplianceProductBox product={product} key={index} />
          ));

      case ShowBy.Segments:
        const segment_rects = contextState?.kpiResult?.segment_rects;
        const planned_rects = contextState?.kpiResult?.planned_rects;

        if (segment_rects === undefined || planned_rects === undefined)
          return <NoProductsMsg>No product data available</NoProductsMsg>;
        return (
          <>
            {segment_rects?.map((el, index) => {
              const uuids = planned_rects
                ?.filter(el => el.segment_index === index)
                .map(el => el.brandbank_uuid);
              return (
                <div key={index}>
                  <ProductGroupingTitleDiv key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle cx="4" cy="4" r="4" fill="#1B7899" />
                    </svg>
                    <ProductGroupingTitle>
                      Segment {index + 1} of {segment_rects.length}
                    </ProductGroupingTitle>
                  </ProductGroupingTitleDiv>
                  <div>
                    {products
                      .filter(
                        el => uuids && uuids?.indexOf(el.brandbank_uuid) >= 0
                      )
                      .slice(0, 100)
                      .map((product, index) => (
                        <ComplianceProductBox product={product} key={index} />
                      ))}
                  </div>
                </div>
              );
            })}
          </>
        );
      case ShowBy.Suppliers:
        const supplierProducts = groupBySuppliers();
        return Array.from(supplierProducts.keys()).map((key: string, index) => {
          return (
            <ProductGroupingProducts key={index}>
              <ProductGroupingTitleDiv>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#1B7899" />
                </svg>
                <ProductGroupingTitle>{key}</ProductGroupingTitle>
              </ProductGroupingTitleDiv>
              {supplierProducts
                .get(key)
                ?.map((product: ProductsEntity, product_index: any) => (
                  <ComplianceProductBox
                    product={product}
                    key={`${index}+${product_index}`}
                  />
                ))}
            </ProductGroupingProducts>
          );
        });

      case ShowBy.Brands:
        const brandProducts = groupByBrands();
        return Array.from(brandProducts.keys()).map((key: string, index) => {
          return (
            <ProductGroupingProducts key={index}>
              <ProductGroupingTitleDiv>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#1B7899" />
                </svg>
                <ProductGroupingTitle>{key}</ProductGroupingTitle>
              </ProductGroupingTitleDiv>
              {brandProducts.get(key)?.map((product, product_index) => (
                <ComplianceProductBox
                  product={product}
                  key={`${index}+${product_index}`}
                />
              ))}
            </ProductGroupingProducts>
          );
        });
    }
    return <NoProductsMsg>No product data available</NoProductsMsg>;
  };
  return <>{render()}</>;
};

interface SummaryHeaderProps {
  setCurrentFilters?: (filters: string[]) => void;
  scrollTop: number;
  percentCompliant: number;
  resultTimestampText?: string;
  selectedSegmentIndex?: number;
  segmentCount: number;
  setShowExportableJsonModal: (show: boolean) => void;
  hideActions: boolean;
}

const SummaryHeader: FC<SummaryHeaderProps> = ({
  setCurrentFilters,
  scrollTop,
  percentCompliant,
  resultTimestampText,
  selectedSegmentIndex,
  segmentCount,
  setShowExportableJsonModal,
  hideActions,
}) => {
  const hasSingleSegment =
    segmentCount <= 1 || selectedSegmentIndex !== undefined;

  const segmentNumber = (() => {
    if (hasSingleSegment)
      return selectedSegmentIndex ? selectedSegmentIndex + 1 : 1;

    return `1 - ${segmentCount}`;
  })();
  return (
    <SummaryHeaderContainer
      onClick={(e: React.MouseEvent) => {
        setCurrentFilters && setCurrentFilters([]);
        e.stopPropagation();
      }}
      scrollTop={scrollTop}
    >
      <FlexDiv>
        <ComplianceTitle>Compliance</ComplianceTitle>
        <FlexSpacer />
        {!hideActions && (
          <CompliancePercentage
            data-test="compliance-result"
            error={percentCompliant < 80}
          >
            {percentCompliant}%
          </CompliancePercentage>
        )}
      </FlexDiv>
      <FlexDiv>
        <Segments data-test="segment-summary">
          {!hideActions && (
            <>
              {hasSingleSegment ? 'for Segment ' : 'for Segments '}
              <SegmentNumbers>{segmentNumber}</SegmentNumbers>
            </>
          )}
          {resultTimestampText && (
            <ResultTimestamp>{resultTimestampText}</ResultTimestamp>
          )}
        </Segments>
        <FlexSpacer />
        {!hideActions && (
          <SummaryAdvancedOptionsMenu
            showJsonModal={() => setShowExportableJsonModal(true)}
          />
        )}
      </FlexDiv>
    </SummaryHeaderContainer>
  );
};

const ComplianceSummary = ({ matchesError }: ComplianceSummaryProps) => {
  const {
    state: contextState,
    currentFilters,
    toggleCurrentFilter,
    setCurrentFilters,
    segmentState,
    selectedProduct,
    setSelectedProduct,
    companyId,
    photoGridId,
  } = useComplianceContext();

  const [showBy, setShowBy] = useState<ShowBy>(ShowBy.Items);
  const [scrollTop, setScrollTop] = useState(0);
  const [showExportableJsonModal, setShowExportableJsonModal] = useState(false);

  const percentCompliant = useMemo(() => {
    if (!contextState?.kpiResult) return 0;
    const pctCompliant = contextState?.kpiResult?.pct_compliant;
    return Math.round(pctCompliant * 10000) / 100;
  }, [contextState]);

  const isSelected = (filter: string) => {
    return currentFilters.indexOf(filter) >= 0;
  };

  const getProductsByFacingStatus = useCallback(
    facingStatus => {
      return (
        contextState?.kpiResult?.planned_rects
          ?.filter(el => el.facing_status === facingStatus)
          .map((rect, index) => {
            const brandbankItem = contextState?.brandbankMap?.get(
              rect.found_brandbank_uuid
            );
            return facingStatus === 'unrecognized_item'
              ? {
                  brandbank_uuid: `UR:${index}:${rect.brandbank_uuid}`,
                  empty_facings: 0,
                  extra_facings: 0,
                  incorrect_position: 0,
                  incorrect_pricing: 0,
                  is_compliant: false,
                  missing_price: 0,
                  name: 'Unrecognized Product',
                  is_not_on_shelf: false,
                  ok: 0,
                  planned_facings: 0,
                }
              : {
                  brandbank_uuid: rect.found_brandbank_uuid,
                  empty_facings: 0,
                  extra_facings: 0,
                  incorrect_position: 0,
                  incorrect_pricing: 0,
                  is_compliant: false,
                  missing_price: 0,
                  name: brandbankItem?.name ?? '',
                  is_not_on_shelf: false,
                  ok: 0,
                  planned_facings: 0,
                };
          }) ?? []
      );
    },
    [contextState]
  );

  const productMatchFilter = useCallback(
    (product: ProductsEntity) => {
      const containsFilter = (filter: ProductTag) => {
        return currentFilters.indexOf(filter) >= 0;
      };

      let result = false;

      if (containsFilter(ProductTag.NotOnShelf)) {
        result = result || product.is_not_on_shelf;
      }
      if (containsFilter(ProductTag.IncorrectPosition)) {
        result =
          result || product.incorrect_position > 0 || product.extra_facings > 0;
      }
      if (containsFilter(ProductTag.MissingShelfTag)) {
        result = result || product.missing_price > 0;
      }
      if (containsFilter(ProductTag.EmptyFacings)) {
        result = result || product.empty_facings > 0;
      }

      return result;
    },
    [currentFilters]
  );

  const filteredProducts = useMemo(() => {
    if (
      contextState?.kpiResult === undefined ||
      contextState?.kpiResult.products === undefined ||
      contextState?.kpiResult.products === null
    )
      return [];

    const products = contextState?.kpiResult.products;

    const unplannedProducts = getProductsByFacingStatus('unplanned_item');
    const unrecognizedProducts = getProductsByFacingStatus('unrecognized_item');

    let result: ProductsEntity[] = [];

    if (currentFilters.length === 0) {
      result = [...products, ...unplannedProducts];
    } else {
      result = result.concat(products.filter(el => productMatchFilter(el)));

      if (currentFilters.indexOf(ProductTag.UnplannedItem) >= 0) {
        result = result.concat(unplannedProducts);
      }
      if (currentFilters.indexOf(ProductTag.UnrecognizedProduct) >= 0) {
        result = result.concat(unrecognizedProducts);
      }

      result = result.filter(
        (thing, i, arr) =>
          arr.findIndex(t => t.brandbank_uuid === thing.brandbank_uuid) === i
      );
    }

    if (
      segmentState?.selectedSegmentIndex !== undefined &&
      segmentState?.selectedSegmentIndex !== null &&
      contextState.kpiResult.planned_rects
    ) {
      const uuids = contextState.kpiResult.planned_rects
        .filter(el => el.segment_index === segmentState.selectedSegmentIndex)
        .map(el => el.brandbank_uuid);

      result = result.filter(el => uuids?.indexOf(el.brandbank_uuid) >= 0);
    }

    //out of compliance items at the top of the list
    result.sort((a, b) =>
      a.is_compliant === b.is_compliant ? 0 : a.is_compliant ? 1 : -1
    );

    return result;
  }, [
    contextState,
    currentFilters,
    getProductsByFacingStatus,
    productMatchFilter,
    segmentState,
  ]);

  const onScroll = (e: React.UIEvent) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const onlyUnique = (value: string, index: number, array: string[]) => {
    return array.indexOf(value) === index;
  };

  const {
    totalProducts,
    totalPositions,
    notOnShelf,
    emptyFacings,
    unplannedProducts,
    unrecognizedProducts,
    incorrectPositions,
  } = useMemo(() => {
    let totalProducts = contextState?.kpiResult?.products?.length;
    let totalPositions = contextState?.kpiResult?.planned_facings;
    let notOnShelf = contextState?.kpiResult?.not_on_shelf;
    let emptyFacings = contextState?.kpiResult?.empty_facings;
    let unplannedProducts = contextState?.kpiResult?.unplanned_product;
    let unrecognizedProducts = contextState?.kpiResult?.unrecognized_items;
    let incorrectPositions =
      (contextState?.kpiResult?.incorrect_position ?? 0) +
      (contextState?.kpiResult?.extra_facings ?? 0);

    const filter = (segmentIndex: number, facingStatus: string) => {
      const plannedRects = contextState?.kpiResult?.planned_rects ?? [];
      return plannedRects.filter(
        el =>
          el.segment_index === segmentIndex && el.facing_status === facingStatus
      );
    };

    if (
      contextState &&
      segmentState?.selectedSegmentIndex !== undefined &&
      segmentState?.selectedSegmentIndex !== null
    ) {
      const segmentPositions =
        contextState.planogram?.details.positions.filter(
          pos => pos.segmentIndex === segmentState.selectedSegmentIndex
        ) ?? [];

      const positionCount = segmentPositions.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.hFacings * currentValue.vFacings,
        0
      );

      totalPositions = positionCount;
      totalProducts = segmentPositions.map(pos => pos.upc).filter(onlyUnique)
        .length;

      notOnShelf = 0;
      incorrectPositions = 0;
      emptyFacings = filter(segmentState.selectedSegmentIndex, 'empty').length;

      unplannedProducts = filter(
        segmentState.selectedSegmentIndex,
        'unplanned_item'
      ).length;

      unrecognizedProducts = filter(
        segmentState.selectedSegmentIndex,
        'unrecognized_item'
      ).length;

      filteredProducts.forEach(product => {
        notOnShelf = notOnShelf ?? 0;
        if (product.is_not_on_shelf) notOnShelf++;
        if (product.incorrect_position > 0 || product.extra_facings > 0)
          incorrectPositions++;
      });
    }

    return {
      totalProducts,
      totalPositions,
      notOnShelf,
      emptyFacings,
      unplannedProducts,
      unrecognizedProducts,
      incorrectPositions,
    };
  }, [contextState, segmentState, filteredProducts]);

  useEffect(() => {
    if (selectedProduct) {
      const product = filteredProducts.find(
        el => el.brandbank_uuid === selectedProduct.brandbankUuid
      );
      if (!product) {
        setSelectedProduct && setSelectedProduct(undefined);
      }
    }
  }, [filteredProducts, selectedProduct, setSelectedProduct]);

  const resultTimestampText = useMemo(() => {
    if (!contextState?.mostRecentPlanogramResult) {
      return;
    }
    const t = new Date(contextState.mostRecentPlanogramResult.createdAtTime);
    const timeString = 'Result as of ' + FormatDateForTimestamp(t);

    return timeString;
  }, [contextState]);

  const selectedSegmentIndex = segmentState?.selectedSegmentIndex;
  return (
    <Root>
      <SummaryHeader
        setCurrentFilters={setCurrentFilters}
        scrollTop={scrollTop}
        percentCompliant={percentCompliant}
        resultTimestampText={resultTimestampText}
        selectedSegmentIndex={selectedSegmentIndex}
        segmentCount={contextState?.kpiResult?.segment_rects?.length ?? 0}
        setShowExportableJsonModal={setShowExportableJsonModal}
        hideActions={!!matchesError}
      />
      {matchesError && (
        <ErrorBanner>
          <div>{matchesError}</div>
        </ErrorBanner>
      )}
      {!matchesError && (
        <ContentContainer onScroll={onScroll}>
          <ResultFilters>
            <ResultFilterBox
              testId="result-filter-not-on-shelf"
              Icon={NotOnShelfIcon}
              count={notOnShelf ?? 0}
              total={totalProducts}
              text={'not on shelf'}
              onClick={(e: React.MouseEvent) => {
                toggleCurrentFilter &&
                  toggleCurrentFilter(ProductTag.NotOnShelf);
                e.stopPropagation();
              }}
              selected={isSelected(ProductTag.NotOnShelf)}
            />
            <ResultFilterBox
              testId="result-filter-incorrect-position"
              Icon={IncorrectPositionIcon}
              count={incorrectPositions}
              total={totalPositions}
              text={'incorrect positions'}
              onClick={e => {
                toggleCurrentFilter &&
                  toggleCurrentFilter(ProductTag.IncorrectPosition);
                e.stopPropagation();
              }}
              selected={isSelected(ProductTag.IncorrectPosition)}
            />
            {/* <ResultFilterBox
            Icon={MissingShelfTagsIcon}
            total={totalProducts}
            count={contextState?.kpiResult?.missing_price ?? 0}
            text={'missing shelf tags'}
            onClick={e => {
              toggleCurrentFilter &&
                toggleCurrentFilter(ProductTag.MissingShelfTag);
              e.stopPropagation();
            }}
            selected={isSelected(ProductTag.MissingShelfTag)}
          /> */}
            <ResultFilterBox
              testId="result-filter-empty-facings"
              Icon={EmptyFacingsIcon}
              count={emptyFacings ?? 0}
              total={totalPositions}
              text={'empty facings'}
              onClick={e => {
                toggleCurrentFilter &&
                  toggleCurrentFilter(ProductTag.EmptyFacings);
                e.stopPropagation();
              }}
              selected={isSelected(ProductTag.EmptyFacings)}
            />
            <ResultFilterBox
              testId="result-filter-unplanned-products"
              Icon={IncorrectItemsIcon}
              count={unplannedProducts ?? 0}
              text={'unplanned products'}
              mobileText={'unplanned'}
              total={totalProducts}
              onClick={e => {
                toggleCurrentFilter &&
                  toggleCurrentFilter(ProductTag.UnplannedItem);
                e.stopPropagation();
              }}
              selected={isSelected(ProductTag.UnplannedItem)}
            />
            <ResultFilterBox
              testId="result-filter-unrecognized-products"
              Icon={UnrecognizedProduct}
              count={unrecognizedProducts ?? 0}
              text={'unrecognized'}
              total={totalPositions}
              onClick={e => {
                toggleCurrentFilter &&
                  toggleCurrentFilter(ProductTag.UnrecognizedProduct);
                e.stopPropagation();
              }}
              selected={isSelected(ProductTag.UnrecognizedProduct)}
            />
          </ResultFilters>
          <ProductContainer>
            <ProductTitleContainer>
              <ProductTitle>Products</ProductTitle>
              <FlexSpacer />
              <ShowByTitle>Show by:</ShowByTitle>
              <Dropdown
                title={showBy}
                options={Object.values(ShowBy).map(el => ({
                  text: el,
                  selected: showBy === el,
                }))}
                onClick={(item: DropdownItem) => {
                  setShowBy(item.text as ShowBy);
                }}
              />
            </ProductTitleContainer>
            <Products showBy={showBy} products={filteredProducts} />
          </ProductContainer>
        </ContentContainer>
      )}
      {showExportableJsonModal && (
        <ComplianceJsonModal
          companyId={companyId}
          photoGridId={photoGridId}
          onCloseCallback={() => setShowExportableJsonModal(false)}
        />
      )}
    </Root>
  );
};

export { ComplianceSummary };
