import styled from 'styled-components';
import { breakpoint } from '@gsc/components';
import { FilterDropdown } from '../FilterDropdown';

const StyledFilterDropdown = styled(FilterDropdown)`
  margin-bottom: 3.2rem;

  ${breakpoint.md`
    & {
      margin: 0.4rem 0.8rem 0.4rem 0;
    }
  `};
`;

export { StyledFilterDropdown };
