import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  box-sizing: border-box;

  border-radius: 4px;
  border: 1px solid transparent;
  background: var(--colors-charlestonGrey4);

  &:hover {
    border-color: var(--colors-deepOcean);
  }

  &:focus-within {
    border-color: var(--colors-deepOcean);
    background: var(--colors-deepOcean6);
  }

  &.success {
    border-color: var(--colors-success);

    &:hover,
    &:focus-within {
      border-color: var(--colors-success);
      outline: 1px solid var(--colors-success);
    }
  }

  &.error {
    border-color: var(--colors-fail);

    &:hover,
    &:focus-within {
      border-color: var(--colors-fail);
      outline: 1px solid var(--colors-fail);
      background: var(--colors-fail10);
    }
  }

  &.warning {
    border-color: var(--colors-warning);

    &:hover,
    &:focus-within {
      border-color: var(--colors-warning);
      outline: 1px solid var(--colors-warning);
    }
  }

  .visibility {
    font-size: 20px;
    margin: auto;
    color: var(--colors-charlestonGrey);
    cursor: pointer;
  }

  &[aria-disabled='true'] {
    outline: none;
    color: var(--colors-charlestonGrey30);
    background: var(--colors-charlestonGrey4);
    border-color: transparent;
    pointer-events: none;

    .visibility {
      color: var(--colors-charlestonGrey30);
      pointer-events: none;
    }
  }

  & .elementWrapper {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 100%;
    border-radius: 4px;
  }
`;

export const StyledInput = styled.input`
  width: 100%;

  padding: 6px 8px;
  margin: 0;

  background: transparent;
  border: none;
  border-radius: 4px;
  outline: none;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.024px;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-input,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-input,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  &:disabled {
    color: var(--colors-charlestonGrey30);
  }
`;
