import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useReducer,
} from 'react';

import { Actions } from './actions';
import { reducer, State } from './reducer';
import { useGetExportsList } from './effects';

interface ExportsProviderProps {
  children: ReactNode;
}

const ExportsStateContext = createContext<State | undefined>(undefined);
const ExportsDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined
);

const initialState: State = {
  exportsList: [],
  loading: true,
};

const ExportsProvider: FC<ExportsProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useGetExportsList(dispatch);

  return (
    <ExportsStateContext.Provider value={state}>
      <ExportsDispatchContext.Provider value={dispatch}>
        {children}
      </ExportsDispatchContext.Provider>
    </ExportsStateContext.Provider>
  );
};

export {
  ExportsProvider,
  ExportsStateContext,
  ExportsDispatchContext,
  initialState,
};
