import React, { CSSProperties, FC, SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import { border, color } from '@gsc/components';
import { ChevronDown, ChevronUp } from '@gsc/icons/react';
import { DropdownMenuLink, MenuLink } from '../../shared/components';
import { isMobileDevice, useWindowDimensions } from '../../shared/utils';
import { useSpacesState, useCurrentSpace } from '../../state/Spaces';
import {
  Space,
  SpaceStatus,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { paths } from '../../paths';

const StyledChevronDown = styled(ChevronDown)`
  width: 1.6rem;
`;

const StyledChevronUp = styled(ChevronUp)`
  width: 1.6rem;
`;

const MobileTriggerButton = styled(MenuLink)`
  cursor: pointer;
  display: flex;
  position: relative;
  border-top: ${border.normal(color.ui15)};

  svg {
    position: absolute;
    right: 1.2rem;
    top: 1.4rem;
  }
`;

const DesktopTriggerButton = styled(MobileTriggerButton)`
  padding: 0 1.2rem;
  transform: translateX(-1.2rem);
  width: calc(100% + 2.4rem);
`;

const DrawerContainer = styled.ul`
  background-color: ${color.ui10};
  border-bottom: ${border.normal(color.ui15)};
  max-height: 0;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: max-height 0.15s ease-in-out;
  overflow-y: auto;
  padding: 0 1.2rem;
  transform: translateX(-1.2rem);
  width: calc(100% + 2.4rem);

  &.openDrawer {
    border-top: ${border.normal(color.ui15)};
    max-height: 20.25rem;
  }
`;

const MobileDropdownMenuLink = styled(DropdownMenuLink)`
  &.current-space-active {
    display: none;
  }
`;

const DesktopDropdownMenuLink = styled(MobileDropdownMenuLink)`
  padding: 0 1.2rem;
  transform: translateX(-1.2rem);
  width: calc(100% + 2.4rem);
`;

interface StyledDropdownMenuLinkProps {
  dataTest: string;
  isDesktop: boolean | undefined;
  onClick(e: any): void;
  style: CSSProperties;
  to: string;
}

const StyledDropdownMenuLink: FC<StyledDropdownMenuLinkProps> = ({
  children,
  dataTest,
  isDesktop,
  onClick,
  style,
  to,
}) =>
  isDesktop ? (
    <DesktopDropdownMenuLink
      data-test={dataTest}
      onClick={onClick}
      style={style}
      to={to}
    >
      {children}
    </DesktopDropdownMenuLink>
  ) : (
    <MobileDropdownMenuLink
      data-test={dataTest}
      onClick={onClick}
      style={style}
      to={to}
    >
      {children}
    </MobileDropdownMenuLink>
  );

interface TriggerButtonProps {
  as: any;
  isDesktop: boolean | undefined;
  toggleDrawer(): void;
  role: string;
}

const TriggerButton: FC<TriggerButtonProps> = ({
  as,
  children,
  isDesktop,
  toggleDrawer,
  role,
}) =>
  isDesktop ? (
    <DesktopTriggerButton as={as} role={role} onClick={toggleDrawer}>
      {children}
    </DesktopTriggerButton>
  ) : (
    <MobileTriggerButton as={as} role={role} onClick={toggleDrawer}>
      {children}
    </MobileTriggerButton>
  );

const SpaceNavDrawer: FC = () => {
  const state = useSpacesState();
  const { isMatch, id } = useCurrentSpace();
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const Arrow = isDrawerOpen ? StyledChevronUp : StyledChevronDown;
  const currentSpace = isMatch ? state.spaces[id] : undefined;
  const largerWindow = useWindowDimensions().width >= 1019;
  const isDesktop = !isMobileDevice() && largerWindow;

  const navigateToSpace = (e: SyntheticEvent, space: Space.AsObject) => {
    e.preventDefault();
    setIsDrawerOpen(false);
    history.push(paths.spaces.space.photos.root(space.id));
  };

  const activeSpaces = state
    .getSpacesAlphabetical()
    .filter(space => space.status === SpaceStatus.SPACE_STATUS_ACTIVE);

  return (
    <>
      <TriggerButton
        as="div"
        isDesktop={isDesktop}
        key="switch-spaces"
        role="toggle"
        toggleDrawer={toggleDrawer}
      >
        Switch spaces <Arrow />
      </TriggerButton>

      <DrawerContainer className={classnames({ openDrawer: isDrawerOpen })}>
        {activeSpaces.map(space => (
          <li key={space.id}>
            <StyledDropdownMenuLink
              dataTest={`${space['name']}-space-nav-item`}
              isDesktop={isDesktop}
              onClick={e => navigateToSpace(e, space)}
              style={{
                display: currentSpace?.id === space.id ? 'none' : 'block',
              }}
              to={paths.spaces.space.photos.root(space.id)}
            >
              {space['name']}
            </StyledDropdownMenuLink>
          </li>
        ))}
      </DrawerContainer>
    </>
  );
};

export { SpaceNavDrawer };
