import { useEffect } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router';
import { paths } from '../paths';
import { useSpacesState } from '../state/Spaces';

const WHITELISTED_PATHS = [
  paths.admin.root,
  paths.exports.root,
  paths.notFound,
];

const useSpacesRedirect = () => {
  const { spaces, defaultSpace } = useSpacesState();
  const match = useRouteMatch<{ spaceId: string }>(paths.spaces.space.root());
  const location = useLocation();
  const history = useHistory();
  const hasSpaces = Object.keys(spaces).length > 0;

  const spaceId = match?.params.spaceId;
  const isWhitelistedPath = WHITELISTED_PATHS.some(path =>
    location.pathname.includes(path)
  );
  let spaceExists: boolean | null = spaceId ? !!spaces[spaceId] : false;

  if (isWhitelistedPath || location.pathname === paths.root) {
    spaceExists = null;
  }

  useEffect(
    function redirectToValidSpace() {
      const shouldReplace = match === null && !isWhitelistedPath;

      if (shouldReplace && spaceExists !== false && defaultSpace.length > 0) {
        history.replace(paths.spaces.space.photos.root(defaultSpace));
      }

      if (match?.isExact) {
        history.replace(
          paths.spaces.space.photos.root(spaceId || defaultSpace)
        );
      }
    },
    [match, defaultSpace, history, isWhitelistedPath, spaceExists, spaceId]
  );

  return { hasSpaces, spaceExists, spaceId };
};

export { useSpacesRedirect };
