import React, { FC, useEffect, useRef, useState } from 'react';
// import classNames from 'classnames';
// import styled from 'styled-components';
import {
  zoom as d3Zoom,
  ZoomBehavior,
  zoomIdentity,
  ZoomTransform,
} from 'd3-zoom';
import { select } from 'd3-selection';
import { zoomToFitImage } from './zoom_functions';

interface ImageWithZoomProps {
  imageUrl?: string;
  isDesktop: boolean;
  onImageLoad?: () => void;
  width: number;
  height: number;
}

const ImageWithZoom: FC<ImageWithZoomProps> = ({
  imageUrl,
  onImageLoad,
  width,
  height,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const zoom = useRef<ZoomBehavior<Element, unknown> | null>(null);
  const [transform, setTransform] = useState<ZoomTransform>(zoomIdentity);
  const [zoomInitialized, setZoomInitialized] = useState(false);

  useEffect(
    function initializeZoom() {
      zoom.current = d3Zoom()
        .scaleExtent([1 / 10, 2])
        .translateExtent([
          [-500, -500],
          [width + 500, height + 500],
        ])
        .on('zoom', e => {
          setTransform(e.transform);
        });

      const { width: viewportWidth = 0, height: viewportHeight = 0 } =
        svgRef.current?.getBoundingClientRect() ?? {};

      const initialTransform = zoomToFitImage({
        imageWidth: width,
        imageHeight: height,
        viewportWidth,
        viewportHeight,
      });

      select(`[id="image-with-zoom-${imageUrl}"]`)
        .call(zoom.current as any)
        .call(zoom.current.transform as any, initialTransform);

      setZoomInitialized(true);
    },
    [height, imageUrl, width]
  );

  return (
    <svg
      ref={svgRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      style={{ position: 'absolute', opacity: zoomInitialized ? 1 : 0 }}
      role="zoom-area"
      id={`image-with-zoom-${imageUrl}`}
    >
      <g transform={isNaN(transform?.k) ? undefined : transform.toString()}>
        <image href={imageUrl} onLoad={onImageLoad} />
      </g>
    </svg>
  );
};

export { ImageWithZoom };
