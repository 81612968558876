import {
  Space,
  SpaceFeatureFlagMap,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

export const IsFeatureEnabled = (
  space: Space.AsObject,
  featureFlag: SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]
) => {
  return !!space?.featureFlagsList?.includes(featureFlag);
};
