import { ExportAPIClient } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_api_pb_service';
import { AuthzAPIClient } from '@gsc/proto-gen-v2/dist/idl/aperture/authz/v1/authz_api_pb_service';
import { AssetDetailAPIClient } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_api_pb_service';
import { SearchAPIClient } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_api_pb_service';
import { AlbumAPIClient } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_api_pb_service';
import { PhotoClient } from './Photo';

const API_URL = process.env.API_URL || '';

const ExportClient = new ExportAPIClient(
  process.env.EXPORT_API_URL || `${API_URL}/export/v1/grpcweb`
);

const AuthZClient = new AuthzAPIClient(
  process.env.AUTHZ_API_URL || `${API_URL}/authz/v1/grpcweb`
);

const AssetDetailClient = new AssetDetailAPIClient(
  process.env.ASSETDETAIL_API_URL || `${API_URL}/assetdetail/v1/grpcweb`
);

const SearchClient = new SearchAPIClient(`${API_URL}/search/v1/grpcweb`);

const AlbumClient = new AlbumAPIClient(
  `${process.env.API_URL || ''}/album/v1/grpcweb`
);

export {
  AuthZClient,
  ExportClient,
  PhotoClient,
  AssetDetailClient,
  SearchClient,
  AlbumClient,
};
