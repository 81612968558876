import React, { FC } from 'react';
import { useSelect } from 'downshift';
import styled from 'styled-components';
import { ChevronDown, ChevronUp, MoreMeatball } from '@gsc/icons/react';
import {
  border,
  color,
  elevation,
  mixin,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  zIndex,
} from '@gsc/components';

import { ButtonMenuItem, SingleButtonMenuItem } from './ButtonMenuItem';

type ButtonMenuPropsStatus =
  | 'default'
  | 'neutral'
  | 'positive'
  | 'warning'
  | 'negative'
  | 'inverted';

type ButtonMenuPropsVariant = 'primary' | 'secondary' | 'tertiary';

interface ButtonMenuProps {
  alignRight?: boolean;
  buttonText?: string;
  className?: string;
  dataTest?: string;
  handleClick?(args: any): void;
  handleSelect?(args: any): void;
  icon?: any;
  id?: string;
  items: Array<SingleButtonMenuItem>;
  status?: ButtonMenuPropsStatus;
  variant?: ButtonMenuPropsVariant;
}

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const ButtonMenuList = styled.ul`
  ${({ alignRight }: ButtonMenuProps) => (alignRight ? 'right: 0;' : 'left: 0')}
  ${elevation['12']}
  background-color: ${color.ui01};
  border: ${border.normal(color.ui15)};
  border-radius: ${border.radius.normal};
  left: auto;
  max-height: none;
  min-width: 16rem;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 0.4rem);
  width: inherit;
  z-index: ${zIndex.echo};
`;

const MeatballsIcon = styled(MoreMeatball)`
  ${mixin.size('2.4rem')}
  vertical-align: middle;
`;

// eslint-disable-next-line complexity
const ButtonMenuComponent: FC<ButtonMenuProps> = ({
  alignRight = false,
  buttonText,
  className,
  dataTest,
  handleClick,
  handleSelect,
  icon,
  id,
  items = [],
  status,
  variant = 'primary',
}) => {
  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items,
    itemToString: item => (item.children.key ? item.children.key : ''),
    onSelectedItemChange: handleSelect,
  });

  let ButtonStyle = PrimaryButton;
  const useIcon = icon ? icon : <MeatballsIcon />;

  if (variant !== 'primary') {
    ButtonStyle = variant === 'secondary' ? SecondaryButton : TertiaryButton;
  }

  const showMenu = !!items.length && isOpen;
  const useChevron = showMenu ? <ChevronUp /> : <ChevronDown />;

  return (
    <span id={id} className={className}>
      <Wrapper>
        <ButtonStyle
          {...getToggleButtonProps({
            onClick: isOpen ? undefined : handleClick,
          })}
          data-test={dataTest}
          isIconOnly={!buttonText}
          isIconRight={buttonText ? true : false}
          status={status}
        >
          {buttonText ? (
            <>
              {buttonText}
              {icon ? icon : useChevron}
            </>
          ) : (
            useIcon
          )}
        </ButtonStyle>
        <ButtonMenuList
          {...getMenuProps()}
          alignRight={alignRight}
          data-test={`${dataTest}-list`}
          hidden={!showMenu}
        >
          {isOpen &&
            items.map((item, index) => (
              <ButtonMenuItem
                key={index}
                {...getItemProps({
                  index,
                  item,
                  disabled: item.disabled,
                })}
                active={highlightedIndex === index}
                dataTest={item.dataTest}
                status={item.status}
              >
                {item.children}
              </ButtonMenuItem>
            ))}
        </ButtonMenuList>
      </Wrapper>
    </span>
  );
};

const ButtonMenu: FC<ButtonMenuProps> = ({
  alignRight,
  buttonText,
  className,
  dataTest,
  handleClick,
  icon,
  id,
  items,
  status,
  variant,
}) => {
  const handleSelect = ({ selectedItem }: any) => {
    const { onSelect } = selectedItem;
    onSelect && onSelect();
  };

  return (
    <ButtonMenuComponent
      alignRight={alignRight}
      buttonText={buttonText}
      className={className}
      dataTest={dataTest}
      handleClick={handleClick}
      handleSelect={handleSelect}
      icon={icon}
      id={id}
      items={items}
      status={status}
      variant={variant}
    />
  );
};

export { ButtonMenu };
