import { Actions, ActionTypes } from './actions';
import { Pagination } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

export interface State {
  assetDetail?: AssetDetail.AsObject;
  assetDetailsById: { [id: string]: AssetDetail.AsObject };
  assetDetailsPages: {
    [key: string]: {
      pageNumber: number;
      ids: string[];
      loading: boolean;
    };
  };
  detailPlaceholder?: {
    clickedCoordinates: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
    placeholder: string;
  };
  loading: boolean;
  mobileSelectMode: boolean;
  pagination?: Pagination.AsObject;
  selectedPhotos: string[];
  totalHits: number;
  showDetails: boolean;
  showPlanogram: boolean;
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.ClearAssetDetailResponse: {
      return {
        ...state,
        assetDetail: undefined,
      };
    }

    case ActionTypes.ClearPhotosResponse: {
      return {
        ...state,
        assetDetailsById: {},
        assetDetailsPages: {},
      };
    }

    case ActionTypes.GetAssetDetailResponse: {
      const { assetDetail } = action.payload.assetDetail.toObject();

      return {
        ...state,
        assetDetail,
      };
    }

    case ActionTypes.SetPageLoading: {
      const { pageNumber } = action.payload;
      const assetDetailsPages = {
        ...state.assetDetailsPages,
        [`page${pageNumber}`]: {
          pageNumber,
          ids: [],
          loading: true,
        },
      };

      return { ...state, assetDetailsPages };
    }

    case ActionTypes.GetPhotosResponse: {
      const { pageNumber, photos } = action.payload;

      const {
        assetDetailsList: newAssetDetails,
        pagination,
      } = photos.toObject();

      const assetDetailsById = newAssetDetails.reduce(
        (result, assetDetail) => ({ ...result, [assetDetail.id]: assetDetail }),
        state.assetDetailsById
      );

      const assetDetailsPages = {
        ...state.assetDetailsPages,
        [`page${pageNumber}`]: {
          pageNumber,
          ids: newAssetDetails.map(assetDetail => assetDetail.id),
          loading: false,
        },
      };

      if (pagination) pagination.size = 30;

      return {
        ...state,
        assetDetailsById,
        assetDetailsPages,
        loading: false,
        pagination,
      };
    }

    case ActionTypes.SetTotalHits: {
      return { ...state, totalHits: action.payload.totalHits };
    }

    case ActionTypes.SetDetailPlaceholder: {
      const { detailPlaceholder } = action.payload;

      return {
        ...state,
        detailPlaceholder,
      };
    }

    case ActionTypes.SetLoadingState: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    case ActionTypes.SetMobileSelectMode: {
      return {
        ...state,
        mobileSelectMode: action.payload.mobileSelectMode,
        selectedPhotos: action.payload.mobileSelectMode
          ? state.selectedPhotos
          : [],
      };
    }

    case ActionTypes.SetSelectedPhotos: {
      return { ...state, selectedPhotos: action.payload.ids };
    }

    case ActionTypes.TogglePhotoSelected: {
      return {
        ...state,
        selectedPhotos:
          state.selectedPhotos.indexOf(action.payload.id) > -1
            ? state.selectedPhotos.filter(id => id !== action.payload.id)
            : [...state.selectedPhotos, action.payload.id],
      };
    }

    case ActionTypes.ToggleShowDetails: {
      return { ...state, showDetails: !state.showDetails };
    }

    case ActionTypes.ToggleShowPlanogram: {
      return { ...state, showPlanogram: !state.showPlanogram };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
