import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment-timezone';
import { color, Reset, typography } from '@gsc/components';
import { Routes } from './Routes';
import { ErrorBoundary } from './shared/components';
import { CenterElement } from './shared/components/CenterElement';
import { Loader } from './shared/components/Loader';
import { useSpacesState } from './state/Spaces';
import { ScrollToTop } from './screens/Photos/components/ScrollToTop';
import { useAuth } from './state/Auth';

const StyledApp = styled.div`
  ${typography.body}
  color: ${color.text900};

  a, button {
    -webkit-tap-highlight-color: transparent;
  }
`;

const DisableBodyScroll = createGlobalStyle`
  body, #root {
    position: relative;
    z-index: var(--zIndices-initial);
  }

  @media (max-width: 64em) {
    body.takeover-active {
      height: 100%;
      overflow: hidden;
    }
  }
`;

const StyledLoader = styled(Loader)`
  font-size: 6rem;
`;

const Root: FC = ({ children }) => {
  const { status: AuthStatus, types } = useAuth();
  const { status, SpacesStatus } = useSpacesState();

  if (
    status === SpacesStatus.COMPLETE ||
    AuthStatus === types.AuthStatus.UNAUTHENTICATED
  )
    return <>{children}</>;

  return (
    <CenterElement>
      <StyledLoader />
    </CenterElement>
  );
};

const App: FC = () => {
  const userTimezone = moment.tz.guess();
  moment.tz.setDefault(userTimezone);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>PhotoWorks</title>
        <meta name="description" content="PhotoWorks" />
      </Helmet>

      <Reset />
      <DisableBodyScroll />

      <StyledApp>
        <ScrollToTop />
        <Routes />
      </StyledApp>
    </ErrorBoundary>
  );
};

export { App, Root };
