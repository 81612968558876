import {
  Space,
  SpaceConfig,
  SpaceFeatureFlagMap,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { SpacesStatus } from './types';

export enum ActionTypes {
  InitializeSpaces = 'app/InitializeSpaces',
  AddSpace = 'app/AddSpace',
  SetStatus = 'app/SetStatus',
  UpdateSpaceConfig = 'app/UpdateSpaceConfig',
  SetDefaultSpace = 'app/SetDefaultSpace',
  UpdateSpaceFeatureFlags = 'app/UpdateSpaceFeatureFlags',
}

interface InitializeSpaces {
  type: ActionTypes.InitializeSpaces;
  payload: {
    spaces: Space[];
  };
}

interface AddSpace {
  type: ActionTypes.AddSpace;
  payload: {
    space: Space;
  };
}

interface SetStatus {
  type: ActionTypes.SetStatus;
  payload: {
    status: SpacesStatus;
  };
}

interface UpdateSpaceConfig {
  type: ActionTypes.UpdateSpaceConfig;
  payload: {
    id: string;
    config: SpaceConfig;
  };
}

interface UpdateSpaceFeatureFlags {
  type: ActionTypes.UpdateSpaceFeatureFlags;
  payload: {
    id: string;
    featureFlagsList: Array<SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]>;
  };
}

interface SetDefaultSpace {
  type: ActionTypes.SetDefaultSpace;
  payload: {
    id: string;
  };
}

export type Actions =
  | InitializeSpaces
  | AddSpace
  | SetStatus
  | UpdateSpaceConfig
  | SetDefaultSpace
  | UpdateSpaceFeatureFlags;
