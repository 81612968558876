import React, { FC, FormEvent, Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  color,
  elevation,
  PrimaryButton,
  Toggle,
  zIndex,
} from '@gsc/components';

import {
  formatParamToString,
  isMobileDevice,
  uppercaseFirstLetter,
} from '../../../../shared/utils';
import { TableHeader, TBody, TH } from '../../../../shared/components';
import { LabelInput } from './LableInput';
import {
  useSpacesAPI,
  useSpacesDispatch,
  useCurrentSpace,
  useSpacesState,
} from '../../../../state/Spaces';
import {
  AssetDetailConfig,
  Table as ProtoTable,
  Column,
  TableConfig,
  ColumnConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import {
  StyledTable,
  StyledTData,
  StyledTHead,
  StyledTRow,
} from '../../shared/components/Tables';

interface ConfigTablesProps {
  assetDetailConfig: AssetDetailConfig.AsObject;
  dataTest?: string;
  tablesList: ProtoTable.AsObject[];
  tableSizeWidth: number;
}

const MobileActionButtons = styled.div`
  ${elevation['12']};
  background-color: ${color.ui01};
  border-top: ${border.normal(color.ui15)};
  bottom: 0;
  display: flex;
  left: 0;
  padding: 1.6rem;
  position: fixed;
  width: 100%;
  z-index: ${zIndex.golf};

  .takeover-active & {
    z-index: -1;
  }
`;

const DesktopActionButtons = styled(MobileActionButtons)`
  margin-left: 24.2rem;
  padding-left: min(50vw, 56rem);
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: auto;
`;

const StyledToggle = styled(Toggle)`
  display: inline-block;
` as typeof Toggle;

interface StyledProps {
  isDesktop: boolean | undefined;
}

const ActionButtons: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopActionButtons>{children}</DesktopActionButtons>
  ) : (
    <MobileActionButtons>{children}</MobileActionButtons>
  );

interface SaveButtonProps {
  dataTest: string;
  isDesktop: boolean | undefined;
  isFullWidth: boolean;
  isLoading: boolean;
  type: string;
}

const SaveButton: FC<SaveButtonProps> = ({
  dataTest,
  isDesktop,
  isFullWidth,
  isLoading,
  type,
  children,
}) =>
  isDesktop ? (
    <StyledPrimaryButton dataTest={dataTest} isLoading={isLoading} type={type}>
      {children}
    </StyledPrimaryButton>
  ) : (
    <PrimaryButton
      dataTest={dataTest}
      isFullWidth={isFullWidth}
      isLoading={isLoading}
      type={type}
    >
      {children}
    </PrimaryButton>
  );

const ConfigTables: FC<ConfigTablesProps> = ({
  assetDetailConfig: initialConfig,
  dataTest,
  tablesList,
  tableSizeWidth,
}) => {
  const spacesAPI = useSpacesAPI();
  const currentSpaceId = useCurrentSpace().id;
  const spacesDispatch = useSpacesDispatch();
  const { spaces } = useSpacesState();
  const currentSpace = spaces[currentSpaceId];
  const currentConfig = currentSpace.config;
  const [isLoading, setIsLoading] = useState(false);
  const [assetDetailObj, setAssetDetailObj] = useState<
    AssetDetailConfig.AsObject
  >(JSON.parse(JSON.stringify(initialConfig)));

  const largerWindow = tableSizeWidth >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  const updateLabel = (
    headerName: string,
    name: string,
    inputValue: string
  ) => {
    const matchedTable = assetDetailObj?.tablesList?.find(
      table => table['tableName'] === headerName
    );
    if (!matchedTable) {
      const combinedAssetArr: TableConfig.AsObject[] = [
        ...assetDetailObj?.tablesList,
        {
          tableName: headerName,
          columnConfigsList: [
            {
              columnName: name,
              type: 0,
              label: inputValue,
              canFilter: true,
            },
          ],
        },
      ];
      setAssetDetailObj({ tablesList: combinedAssetArr });
    } else {
      const matchedRow = matchedTable.columnConfigsList?.find(
        row => row['columnName'] === name
      );
      if (!matchedRow) {
        const combinedColumnConfigs: ColumnConfig.AsObject[] = [
          ...matchedTable.columnConfigsList,
          {
            columnName: name,
            type: 0,
            label: inputValue,
            canFilter: true,
          },
        ];
        matchedTable.columnConfigsList = combinedColumnConfigs;
      } else matchedRow['label'] = inputValue;
      setAssetDetailObj({ tablesList: assetDetailObj.tablesList });
    }
  };

  const toggleFilterable = (headerName: string, name: string) => {
    const matchedTable = assetDetailObj?.tablesList?.find(
      table => table['tableName'] === headerName
    );
    if (!matchedTable) {
      const combinedAssetArr: TableConfig.AsObject[] = [
        ...assetDetailObj?.tablesList,
        {
          tableName: headerName,
          columnConfigsList: [
            {
              columnName: name,
              type: 0,
              label: '',
              canFilter: true,
            },
          ],
        },
      ];
      setAssetDetailObj({ tablesList: combinedAssetArr });
    } else {
      const matchedRow = matchedTable.columnConfigsList?.find(
        row => row['columnName'] === name
      );
      if (!matchedRow) {
        const combinedColumnConfigs: ColumnConfig.AsObject[] = [
          ...matchedTable.columnConfigsList,
          {
            columnName: name,
            type: 0,
            label: '',
            canFilter: true,
          },
        ];
        matchedTable.columnConfigsList = combinedColumnConfigs;
      } else {
        matchedRow['canFilter'] = !matchedRow['canFilter'];
      }
      setAssetDetailObj({ tablesList: assetDetailObj.tablesList });
    }
  };

  const toggleDetailedView = (
    e: FormEvent,
    headerName: string,
    name: string
  ) => {
    e.preventDefault();
    const matchedTable = assetDetailObj?.tablesList?.find(
      table => table['tableName'] === headerName
    );

    if (!matchedTable) {
      const combinedAssetArr: TableConfig.AsObject[] = [
        ...assetDetailObj?.tablesList,
        {
          tableName: headerName,
          columnConfigsList: [
            {
              columnName: name,
              type: 0,
              label: '',
              canFilter: false,
            },
          ],
        },
      ];
      setAssetDetailObj({ tablesList: combinedAssetArr });
    } else {
      const matchedRow = matchedTable.columnConfigsList?.find(
        row => row['columnName'] === name
      );

      if (!matchedRow) {
        const combinedColumnConfigsArr: ColumnConfig.AsObject[] = [
          ...matchedTable.columnConfigsList,
          {
            columnName: name,
            type: 0,
            label: '',
            canFilter: false,
          },
        ];
        const newTable: TableConfig.AsObject = {
          tableName: headerName,
          columnConfigsList: combinedColumnConfigsArr,
        };
        const filteredTableArr = assetDetailObj.tablesList.filter(
          table => table['tableName'] !== headerName
        );
        filteredTableArr.push(newTable);
        setAssetDetailObj({ tablesList: filteredTableArr });
      } else {
        const filteredTableArr = assetDetailObj.tablesList.filter(
          table => table['tableName'] !== headerName
        );
        const filteredRows = matchedTable.columnConfigsList.filter(
          row => row['columnName'] !== name
        );
        const newTable = {
          tableName: headerName,
          columnConfigsList: filteredRows,
        };
        filteredTableArr.push(newTable);
        setAssetDetailObj({ tablesList: filteredTableArr });
      }
    }
  };

  const canFilter = (
    item: TableConfig.AsObject,
    headerName: string,
    name: string
  ) => {
    if (item['tableName'] === headerName) {
      return item.columnConfigsList?.some(config => {
        return config['columnName'] === name && config['canFilter'] === true;
      });
    }
    return false;
  };

  const filterable = (headerName: string, name: string) =>
    assetDetailObj?.tablesList?.some(item => canFilter(item, headerName, name));

  const matchAssetNames = (
    item: TableConfig.AsObject,
    headerName: string,
    name: string
  ) => {
    if (item['tableName'] === headerName) {
      const checked = item.columnConfigsList?.some(
        config => config['columnName'] === name
      );
      return checked ? true : undefined;
    }
    return undefined;
  };

  const detailedValue = (headerName: string, name: string) => {
    const checked = assetDetailObj?.tablesList?.some(item =>
      matchAssetNames(item, headerName, name)
    );
    return checked ? true : undefined;
  };

  const filterLabel = (headerName: string, name: string) => {
    let label;
    const matchedTable = assetDetailObj?.tablesList?.find(
      table => table['tableName'] === headerName
    );
    if (matchedTable) {
      const matchedRow = matchedTable.columnConfigsList?.find(
        row => row['columnName'] === name
      );
      if (matchedRow) {
        label = matchedRow.label;
      } else label = '';
    }
    return label;
  };

  const FieldName = (
    <StyledTRow>
      <TH>Field Name</TH>
    </StyledTRow>
  );

  interface DataTableProps {
    headerName: string;
    columnsList: Column.AsObject[];
  }

  const DataTable = ({ headerName, columnsList }: DataTableProps) => {
    return (
      <Fragment key={headerName}>
        <TableHeader data-test={dataTest && `${dataTest}-${headerName}-header`}>
          {`${uppercaseFirstLetter(
            formatParamToString(headerName)
          )} Information`}
        </TableHeader>
        <StyledTable data-test={dataTest && `${dataTest}-${headerName}-table`}>
          <StyledTHead>
            <StyledTRow>
              <TH>Field Name</TH>
              <TH>Label</TH>
              <TH>Filterable</TH>
              <TH>Detailed View</TH>
            </StyledTRow>
          </StyledTHead>
          <TBody>
            {!largerWindow && FieldName}
            {columnsList.map(column => {
              const { name } = column;
              return (
                <StyledTRow key={name}>
                  <StyledTData>
                    {uppercaseFirstLetter(formatParamToString(name))}
                  </StyledTData>
                  <StyledTData data-title="Label">
                    <LabelInput
                      dataTest={dataTest || ''}
                      headerName={headerName}
                      label={filterLabel(headerName, name) || ''}
                      name={name}
                      updateLabel={updateLabel}
                    />
                  </StyledTData>
                  <StyledTData
                    data-title="Filterable"
                    data-test={`${dataTest}-filter-toggle`}
                  >
                    <StyledToggle
                      onChange={() => toggleFilterable(headerName, name)}
                      checked={filterable(headerName, name)}
                      hasLabel={false}
                      id={`${headerName}-${name}-filterable`}
                      key={name}
                      name={`${headerName}-${name}-filterable-toggle`}
                      value="true"
                    />
                  </StyledTData>
                  <StyledTData data-title="Detailed View">
                    <StyledToggle
                      onChange={(e: FormEvent) =>
                        toggleDetailedView(e, headerName, name)
                      }
                      checked={detailedValue(headerName, name)}
                      hasLabel={false}
                      id={`${headerName}-${name}-detailed-view`}
                      key={name}
                      name={`${headerName}-${name}-detailed-view-toggle`}
                      value="true"
                    />
                  </StyledTData>
                </StyledTRow>
              );
            })}
          </TBody>
        </StyledTable>
      </Fragment>
    );
  };

  const applySettings = (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    spacesAPI().then(({ saveSpaceConfig }) => {
      saveSpaceConfig(currentSpaceId, {
        ...currentConfig,
        assetDetailConfig: assetDetailObj,
      }).then(config => {
        setIsLoading(false);
        spacesDispatch.dispatch({
          type: spacesDispatch.actions.UpdateSpaceConfig,
          payload: { id: currentSpaceId, config },
        });
      });
    });
  };

  return (
    <form onSubmit={applySettings} data-test={`${dataTest}-form`}>
      {tablesList.map(table => {
        const { name: headerName, columnsList } = table;
        return (
          <DataTable
            columnsList={columnsList}
            headerName={headerName}
            key={headerName}
          />
        );
      })}
      <ActionButtons isDesktop={isDesktop}>
        <SaveButton
          dataTest={`${dataTest}-save`}
          isDesktop={isDesktop}
          isFullWidth
          isLoading={isLoading}
          type="submit"
        >
          {isLoading ? 'Saving...' : 'Save Configuration'}
        </SaveButton>
      </ActionButtons>
    </form>
  );
};

export { ConfigTables };
