import React, { FC, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { H1, Notification } from '@gsc/components';
import {
  AdminStatus,
  useSpacesAPI,
  useSpacesDispatch,
  useSpacesState,
} from '../../../state/Spaces';
import { paths } from '../../../paths';
import { NoMatch } from '../../NoMatch';
import { FormProvider } from '../../../shared/components/FormContext';
import { EditSpaceForm } from './components/EditSpaceForm';
import {
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

interface ResponseErrorProps {
  code: number;
  id?: string;
}

const StyledNotification = styled(Notification)`
  margin-bottom: 1.6rem;
`;

const EditHeader = styled(H1)`
  display: inline-block;
`;

const EditSpace: FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ spaceId: string }>('/admin/:spaceId');
  const spacesAPI = useSpacesAPI();
  const spacesDispatch = useSpacesDispatch();
  const { spaces } = useSpacesState();
  const currentSpace = spaces[match?.params.spaceId || ''];
  const [responseError, setResponseError] = useState<
    ResponseErrorProps | undefined
  >();

  const validate = (values: any) => {
    const errors: any = {};

    if (values.status === SpaceStatus.SPACE_STATUS_INVALID) {
      errors.status = 'Status is required.';
    } else if (
      values.tier.type === SpaceTierType.SPACE_TIER_TYPE_TRIAL &&
      !values.tier.trialEndDate
    ) {
      errors.tier = 'Trial end date is required.';
    }
    return errors;
  };

  if (!currentSpace) return <NoMatch />;

  return (
    <>
      <Helmet>
        <title>
          PhotoWorks - Edit {currentSpace.name} Space - {currentSpace.id}
        </title>
        <meta
          name="description"
          content="Edit {currentSpace.name} Space - {currentSpace.id}"
        />
      </Helmet>

      {responseError && (
        <StyledNotification
          dataTest="new-space-notification"
          onClick={() => setResponseError(undefined)}
          status="negative"
        >
          {responseError.code === AdminStatus.INVALID_USER_ID
            ? `Invalid user ID. GSC user ${responseError.id} does not yet exist in this Space.`
            : 'Uh oh! Something went wrong on our end.'}
        </StyledNotification>
      )}

      <EditHeader>
        Edit {currentSpace.name} Space - {currentSpace.id}
      </EditHeader>

      <FormProvider
        onSubmit={(values: any) => {
          spacesAPI()
            .then(
              ({
                setSpaceAdmins,
                setSpaceStatus,
                setSpaceTier,
                setSpaceFeatureFlags,
              }) => {
                setSpaceStatus(currentSpace.id, values.status);
                setSpaceTier(currentSpace.id, values.tier);
                setSpaceAdmins(currentSpace.id, values.adminIdsList);
                setSpaceFeatureFlags(currentSpace.id, values.featureFlagsList);
              }
            )
            .then(() => {
              spacesDispatch.dispatch({
                type: spacesDispatch.actions.UpdateSpaceFeatureFlags,
                payload: {
                  id: currentSpace.id,
                  featureFlagsList: values.featureFlagsList,
                },
              });

              history.push(paths.admin.root);
            })
            .catch(e => {
              setResponseError({ code: e.code });
            });
        }}
        initialValues={{
          adminIdsList: currentSpace.adminIdsList,
          sourceId: currentSpace.sourceId,
          sourceIdType: currentSpace.sourceType,
          status: currentSpace.status,
          tier: currentSpace.tier,
          featureFlagsList: currentSpace.featureFlagsList ?? [],
        }}
        onValidate={validate}
      >
        <EditSpaceForm setResponseError={setResponseError} />
      </FormProvider>
    </>
  );
};

export { EditSpace };
