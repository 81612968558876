import React, { CSSProperties, memo } from 'react';
import { ItemData } from '../../../types';
import { CreateAlbumRenderer } from './CreateAlbumRenderer';
import { AlbumRenderer } from './AlbumRenderer';
import { areEqual } from 'react-window';

const AlbumGridCellFactory = memo(
  (props: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const { columnIndex, rowIndex, data } = props;
    const cell = data.rows[rowIndex]?.[columnIndex];

    if (!cell) return null;
    if (cell.type === 'create') {
      return <CreateAlbumRenderer {...props} />;
    } else if (cell.type === 'album') {
      return <AlbumRenderer {...props} />;
    } else {
      return null;
    }
  },
  areEqual
);

AlbumGridCellFactory.displayName = 'AlbumGridCellFactory';

export { AlbumGridCellFactory };
