import React, { forwardRef } from 'react';
import { Container, Indicator, Radio, RadioInput } from './radio-button.styles';
import { useRadioContext } from '../../context';

type Props = {
  children?: React.ReactNode;
};

const RadioIndicator = ({ children }: Props) => {
  if (children) return <>{children}</>;
  return <Indicator />;
};

export type RadioInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'checked'
> & {
  dataTestId?: string;
  indicator?: React.ReactNode;
  label: React.ReactNode;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioInputProps>(
  function Component(
    { className, value, indicator, dataTestId, disabled, label, ...props },
    ref
  ) {
    const { onChange, groupDataTestId, currentValue, name } = useRadioContext();

    const testId = dataTestId ?? `${groupDataTestId}-value`;
    const isChecked = value === currentValue;
    const tabIndex = disabled ? -1 : 0;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      onChange(e.target.value);
    };

    return (
      <Container
        className={className}
        aria-disabled={disabled}
        tabIndex={tabIndex}
      >
        <Radio isChecked={isChecked} aria-disabled={disabled}>
          {isChecked && <RadioIndicator>{indicator}</RadioIndicator>}
        </Radio>
        {label}
        <RadioInput
          {...props}
          type="radio"
          ref={ref}
          name={name}
          value={value}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          tabIndex={-1}
          data-testid={testId}
        />
      </Container>
    );
  }
);
