import React, { FC, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { breakpoint, Input } from '@gsc/components';

import {
  formatParamToString,
  uppercaseFirstLetter,
} from '../../../../shared/utils';

interface LabelInputProps {
  dataTest: string;
  headerName: string;
  label: string;
  name: string;
  updateLabel(headerName: string, name: string, inputValue: string): any;
}

const StyledInput = styled(Input)`
  flex: 1;

  input + div {
    display: none;
  }

  ${breakpoint.md`
    & {
      max-width: 20rem;
      width: auto;
    }
  `}
`;

const LabelInput: FC<LabelInputProps> = ({
  dataTest,
  headerName,
  label,
  name,
  updateLabel,
}) => {
  const [inputValue, setInputValue] = useState(label);
  const [errors, setErrors] = useState('');

  const handleInputChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLTextAreaElement;
    e.persist();

    if (value) {
      value.length > 32
        ? setErrors('Label is too long.')
        : setInputValue(value);
    } else {
      setInputValue('');
    }
  };

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      updateLabel(headerName, name, inputValue.trim());
    }
  };

  return (
    <StyledInput
      dataTest={`${dataTest}-${name}`}
      errors={errors}
      id={`${headerName}-${name}-label`}
      key={name}
      name={`${headerName}-${name}-label`}
      onBlur={() =>
        inputValue && updateLabel(headerName, name, inputValue.trim())
      }
      onChange={handleInputChange}
      onKeyDown={onEnter}
      placeholder={uppercaseFirstLetter(formatParamToString(name))}
      value={inputValue}
    />
  );
};

export { LabelInput };
