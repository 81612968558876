import React from 'react';

import { Container } from './modal-actions.styles';

export interface ModalActionsProps {
  children?: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export const ModalActions = React.forwardRef<HTMLDivElement, ModalActionsProps>(
  ({ children, className, dataTestId = 'modal-actions', ...props }, ref) => {
    return (
      <Container
        {...props}
        ref={ref}
        className={className}
        data-testid={dataTestId}
      >
        {children}
      </Container>
    );
  }
);

ModalActions.displayName = 'ModalActions';
Object.defineProperty(ModalActions, 'modalComponent', { value: true });
