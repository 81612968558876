import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import {
  ActionTypes,
  usePhotosContext,
} from '../../../../../../../state/Photos';
import {
  PhotoDetailsPanels,
  useQueryParams,
} from '../../../../../../../state/QueryParams/hooks';
import { ComplianceViewerProvider } from '../../../../../../../state/ComplianceViewer/context';
import { ImageWithZoom } from '../ImageWithZoom';
import { RealogramView } from './RealogramView';
import { PlanogramView } from './PlanogramView';
import { convertUnitToPx, validateComplianceResults } from '../../../utils';

const FullContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const SplitContainer = styled.div`
  width: 100%;
  height: 50%;
  border-top: 0.1rem solid #5c6063;
  flex: 1;
  position: relative;
  overflow: visible;
`;

export const StyledButton = styled.button`
  background-color: #262b2faa;
  font-size: 1.4rem;
  color: #ffffff;
  border: 0.1rem solid #5c6063;
  border-radius: 0.4rem;
  display: flex;
  z-index: 3;
  align-items: center;
  gap: 0.6rem;
  height: 3.2rem;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const RealogramTitle = styled.div<{ inApp?: boolean }>`
  position: absolute;
  padding: 2.4rem;
  padding-top: ${p => (p.inApp ? '1.6rem' : '6.4rem')};
  z-index: 2;
`;

const PlanogramTitle = styled.div`
  position: absolute;
  padding: 2.4rem;
  z-index: 2;
`;

const Title = styled.span`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
`;

export const FlexSpacer = styled.div`
  flex-grow: 1;
`;

interface ComplianceViewerProps {
  assetDetail: AssetDetail.AsObject | undefined;
  className?: string;
  downloadPic: () => void;
  isDesktop: boolean;
  loading?: boolean;
  goToNextPhoto: () => void;
  goToPreviousPhoto: () => void;
  hasPreviousPhoto: boolean;
  matchesError?: boolean;
  realogramDimensions: {
    width: number;
    height: number;
  };
}

const ComplianceViewer = ({
  assetDetail,
  realogramDimensions,
  goToNextPhoto,
  goToPreviousPhoto,
}: ComplianceViewerProps) => {
  const contextState = useContext(ComplianceStateContext)?.state;
  const isPlanogramImage = useContext(ComplianceStateContext)?.isPlanogramImage;
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;

  const { setPhotoDetailsPanel, inApp } = useQueryParams();

  const [{ showPlanogram, showDetails }, dispatch] = usePhotosContext();

  const errorState = useMemo(
    () => !!validateComplianceResults(contextState?.kpiResult),
    [contextState]
  );

  const details = contextState?.planogram?.details;
  // Divide by ten as the zoom control starts at a tenth of the total size
  const planogramWidth =
    (details &&
      convertUnitToPx(
        details.planogram.width,
        details.project.metric ? 'cm' : 'in'
      ) / 10) ??
    0;
  const planogramHeight =
    (details &&
      convertUnitToPx(
        details.planogram.height,
        details.project.metric ? 'cm' : 'in'
      ) / 10) ??
    0;

  useEffect(() => {
    if (isPlanogramImage === false) {
      setPhotoDetailsPanel(PhotoDetailsPanels.PHOTO_DETAILS);
    }
  }, [isPlanogramImage, setPhotoDetailsPanel]);

  useEffect(() => {
    if (selectedProduct && !showDetails) {
      dispatch({ type: ActionTypes.ToggleShowDetails });
    }
    // don't want to trigger it to open when closing and product selected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const renderContentView = () => {
    if (showPlanogram)
      return (
        <>
          <SplitContainer>
            <RealogramTitle inApp={inApp}>
              <Title style={{ top: 48 }}>Realogram</Title>
            </RealogramTitle>
            {errorState && (
              <ImageWithZoom
                imageUrl={assetDetail?.assetUrl}
                height={realogramDimensions.height}
                width={realogramDimensions.width}
                isDesktop
              />
            )}
            {!errorState && (
              <RealogramView
                assetDetail={assetDetail}
                goToNextPhoto={goToNextPhoto}
                goToPreviousPhoto={goToPreviousPhoto}
                planogramVisible={showPlanogram}
              />
            )}
          </SplitContainer>
          <SplitContainer>
            <PlanogramTitle>
              <Title>{contextState?.planogram?.summary.name}</Title>
            </PlanogramTitle>
            {!errorState && <PlanogramView />}
            {errorState && (
              <ImageWithZoom
                imageUrl={contextState?.planogram?.summary.previewUrl}
                height={planogramHeight}
                width={planogramWidth}
                isDesktop
              />
            )}
          </SplitContainer>
        </>
      );

    return (
      <FullContainer>
        {!errorState && (
          <RealogramView
            assetDetail={assetDetail}
            goToNextPhoto={goToNextPhoto}
            goToPreviousPhoto={goToPreviousPhoto}
            planogramVisible={showPlanogram}
          />
        )}
        {errorState && (
          <ImageWithZoom
            imageUrl={assetDetail?.assetUrl}
            height={realogramDimensions.height}
            width={realogramDimensions.width}
            isDesktop
          />
        )}
      </FullContainer>
    );
  };

  if (assetDetail === undefined) return null;
  return (
    <ComplianceViewerProvider kpiResult={contextState?.kpiResult}>
      {renderContentView()}
    </ComplianceViewerProvider>
  );
};

export { ComplianceViewer };
