import React, { useCallback, useImperativeHandle, useRef } from 'react';

import classNames from 'classnames';

import { createRipple } from './helpers';
import { Container } from './button.styles';

export type Button = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'error';
  type?: 'button' | 'submit' | 'reset';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'outlined' | 'text';
  iconAfter?: React.ReactNode;
  iconBefore?: React.ReactNode;
  ripple?: boolean;
  name: string;
};

export const Button = React.forwardRef<HTMLButtonElement, Button>(
  (
    {
      color,
      size = 'md',
      ripple = true,
      variant,
      className,
      iconAfter,
      iconBefore,
      children,
      onClick,
      name,
      ...props
    },
    forwardedRef
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const rippleClasses = classNames('ripple', variant);

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (ripple)
          createRipple(buttonRef, rippleClasses, e.clientX, e.clientY);

        return onClick?.(e);
      },
      [onClick, ripple, rippleClasses]
    );

    useImperativeHandle(forwardedRef, () =>
      buttonRef.current ? buttonRef.current : ({} as HTMLButtonElement)
    );

    const classes = classNames(size, variant, color, className);

    const ariaLabel = props?.['aria-label'] ?? name;

    return (
      <Container
        {...props}
        ref={buttonRef}
        name={name}
        className={classes}
        onClick={handleClick}
        aria-label={ariaLabel}
      >
        {iconBefore && <span className="BeforeIcon">{iconBefore}</span>}
        {children}
        {iconAfter && <span className="AfterIcon">{iconAfter}</span>}
      </Container>
    );
  }
);

Button.displayName = 'Button';
