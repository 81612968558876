import { useEffect } from 'react';
import { initPendo, updatePendo } from '../shared/utils';
import { useCurrentSpace, useSpacesState } from '../state/Spaces';
import { useAuth } from '../state/Auth';
import { Space } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const usePendo = () => {
  const { user, isApertureAdmin } = useAuth();
  const { spaces } = useSpacesState();
  const { id } = useCurrentSpace();

  const space: Space.AsObject | undefined = spaces[id || ''];

  if (
    (process.env.ENV === 'staging' || process.env.ENV === 'production') &&
    !!space
  ) {
    initPendo({ user, space, isApertureAdmin });
  }

  useEffect(() => {
    if (
      (process.env.ENV === 'staging' || process.env.ENV === 'production') &&
      !!space
    ) {
      updatePendo({ user, space, isApertureAdmin });
    }
  }, [isApertureAdmin, space, user]);
};

export { usePendo };
