import React, { CSSProperties, FC, Ref } from 'react';
import styled from 'styled-components';
import { color, H3 } from '@gsc/components';
import { Hamburger } from '@gsc/icons/react';

const NoHistogramDataMessage = styled.div`
  height: 14.3rem;
  display: flex;
  align-items: center;

  & > ${H3} {
    color: ${color.ui50};
    margin-bottom: 0;
    margin-left: -0.2rem;
  }
`;

const SliderHandleDiv = styled.div`
  background-color: white;
  border: 0.1rem solid #dadcde;
  border-radius: 100%;
  cursor: ew-resize;
  height: 3rem;
  margin-left: -1.3rem;
  width: 3rem;
  z-index: 3;
`;

const StyledHamburger = styled(Hamburger)`
  color: #b5b8be;
  left: 0.8rem;
  position: relative;
  top: 0.8rem;
  transform: rotate(90deg);
  width: 1.2rem;
`;

interface SliderHandleProps {
  style: CSSProperties;
  handleRef: Ref<HTMLDivElement>;
}

const SliderHandle: FC<SliderHandleProps> = ({
  style,
  handleRef,
  ...passProps
}) => (
  <SliderHandleDiv ref={handleRef} {...passProps} style={style}>
    <StyledHamburger />
  </SliderHandleDiv>
);

export { NoHistogramDataMessage, SliderHandle };
