import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { zoom as d3Zoom, ZoomBehavior } from 'd3-zoom';
import { select } from 'd3-selection';
import React from 'react';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import { PlannedRectsEntity } from 'src/state/Compliance/wapi_planogram_api';
import styled from 'styled-components';
import { ProductTag } from './ComplianceSummary';
import { useComplianceViewerContext } from '../../../../../../../state/ComplianceViewer/hooks';
import { ActionTypes } from '../../../../../../../state/ComplianceViewer/actions';
import { zoomToFitImage } from '../ImageWithTags/zoom_functions';
import { StyledImage } from './RealogramView';

const SegmentRect = styled.rect<{ isHovering: boolean }>`
  cursor: pointer;
  transition: opacity 1s;

  opacity: 0;
  g:hover > & {
    opacity: 1;
  }
  opacity: ${p => (p.isHovering ? '1' : '0')};
`;
const TagPolygon = styled.polygon`
  cursor: pointer;
  fill-opacity: 1;
  &:hover {
    fill-opacity: 0;
  }
`;
const role = 'zoom-area-planogram';

export const PlanogramView = () => {
  const [state, dispatch] = useComplianceViewerContext();
  const svgRef = useRef<SVGSVGElement>(null);
  const zoom = useRef<ZoomBehavior<Element, unknown> | null>(null);
  const [zoomInitialized, setZoomInitialized] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const contextState = useContext(ComplianceStateContext)?.state;
  const segmentState = useContext(ComplianceStateContext)?.segmentState;
  const setSegmentState = useContext(ComplianceStateContext)?.setSegmentState;
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;
  const setSelectedProduct = useContext(ComplianceStateContext)
    ?.setSelectedProduct;
  const currentFilters = useContext(ComplianceStateContext)?.currentFilters;

  const getPointsString = (rect: {
    x_max: number;
    x_min: number;
    y_max: number;
    y_min: number;
  }) => {
    return `${rect.x_min * state.planogram.width} ${rect.y_min *
      state.planogram.height} ${rect.x_max *
      state.planogram.width} ${rect.y_min *
      state.planogram.height} ${rect.x_max *
      state.planogram.width} ${rect.y_max *
      state.planogram.height} ${rect.x_min *
      state.planogram.width} ${rect.y_max * state.planogram.height}`;
  };

  const getFitToScreenTransform = useCallback(() => {
    if (
      state.planogram.width > 0 &&
      state.planogram.height > 0 &&
      !!svgRef.current
    ) {
      const { width: viewportWidth = 0, height: viewportHeight = 0 } =
        svgRef.current?.getBoundingClientRect() ?? {};

      const initialTransform = zoomToFitImage({
        imageWidth: state.planogram.width,
        imageHeight: state.planogram.height,
        viewportWidth,
        viewportHeight,
      });
      return initialTransform;
    }
    return;
  }, [state.planogram]);

  const previewUrl = contextState?.planogram?.summary.previewUrl;

  useEffect(() => {
    if (!zoomInitialized) {
      const imageWidth = state.realogram.plannedRect?.width ?? 0;
      const imageHeight = state.realogram.plannedRect?.height ?? 0;
      if (
        state.planogram.transform &&
        previewUrl &&
        imageWidth > 0 &&
        imageHeight > 0
      ) {
        zoom.current = d3Zoom()
          .scaleExtent([1 / 10, 10])
          .on('zoom', e => {
            if (e.sourceEvent !== null) {
              dispatch({
                type: ActionTypes.SetPlanogramTransform,
                payload: {
                  transform: e.transform,
                },
              });
            }
          });

        const initialTransform = getFitToScreenTransform();

        if (initialTransform) {
          select(`[role="${role}"]`)
            .call(zoom.current as any)
            .call(zoom.current.transform as any, initialTransform);

          setZoomInitialized(true);
        }
      }
    }
  }, [
    dispatch,
    previewUrl,
    state.planogram.transform,
    zoomInitialized,
    getFitToScreenTransform,
    state.realogram.plannedRect,
  ]);

  useEffect(() => {
    if (zoomInitialized) {
      (select(`[role="${role}"]`) as any).call(
        zoom.current?.transform as any,
        state.planogram.transform
      );
    }
  }, [state.planogram.transform, zoomInitialized]);

  const onImageLoad = () => {
    if (imageRef.current) {
      dispatch({
        type: ActionTypes.SetPlanogramDimensions,
        payload: {
          width: imageRef.current.naturalWidth,
          height: imageRef.current.naturalHeight,
        },
      });
    }
  };

  const onlyUnique = (
    value: PlannedRectsEntity,
    index: number,
    array: PlannedRectsEntity[]
  ) => {
    return (
      array.map(el => el.brandbank_uuid).indexOf(value.brandbank_uuid) === index
    );
  };

  const plannedRects = contextState?.kpiResult?.planned_rects;
  const planogram = contextState?.planogram;
  const displayRects = useMemo(() => {
    let result: PlannedRectsEntity[] = [];
    if (!plannedRects || !planogram) return result;
    const totalWidth = planogram.details.planogram.width;
    const totalHeight = planogram.details.planogram.height;

    const notOnShelf = (brandbankUuid: string) => {
      const product = contextState?.kpiResult?.products?.find(
        el => el.brandbank_uuid === brandbankUuid
      );
      return !product || product.is_not_on_shelf;
    };

    if (currentFilters && currentFilters.length > 0) {
      if (currentFilters.indexOf(ProductTag.NotOnShelf) >= 0) {
        result = result.concat(
          plannedRects.filter(el => notOnShelf(el.brandbank_uuid))
        );
      }
      if (currentFilters.indexOf(ProductTag.IncorrectPosition) >= 0) {
        result = result.concat(
          plannedRects.filter(el => el.facing_status === 'wrong_item')
        );
      }
      if (currentFilters.indexOf(ProductTag.EmptyFacings) >= 0) {
        result = result.concat(
          plannedRects.filter(el => el.facing_status === 'empty')
        );
      }
      if (currentFilters.indexOf(ProductTag.UnplannedItem) >= 0) {
        result = result.concat(
          plannedRects.filter(el => el.facing_status === 'unplanned_item')
        );
      }
    } else {
      result = plannedRects;
    }

    result = result
      .filter(
        el =>
          !selectedProduct ||
          selectedProduct.brandbankUuid === el.brandbank_uuid
      )
      .filter(el => !(el.facing_status === 'correct_item'))
      .filter(onlyUnique);

    return result.map(el => {
      const planogramRect = planogram.details?.positions.find(
        pos =>
          pos.segmentIndex === el.segment_index &&
          pos.shelfIndexX === el.shelf_index_x &&
          pos.shelfIndexY === el.shelf_index_y
      );
      let x_min = el.x_min;
      let x_max = el.x_max;
      let y_min = el.y_min;
      let y_max = el.y_max;
      if (planogramRect) {
        x_min = planogramRect.x / totalWidth;
        x_max = (planogramRect.x + planogramRect.width) / totalWidth;
        y_min =
          (totalHeight - planogramRect.y - planogramRect.height) / totalHeight;
        y_max = (totalHeight - planogramRect.y) / totalHeight;
      }
      return { ...el, x_max, x_min, y_max, y_min };
    });
  }, [contextState, currentFilters, plannedRects, planogram, selectedProduct]);

  const getPolygonColors = (rect: PlannedRectsEntity) => {
    let color = '#00d9eb';
    let fill = `transparent`;

    const notOnShelf = () => {
      const product = contextState?.kpiResult?.products?.find(
        el => el.brandbank_uuid === rect.brandbank_uuid
      );
      return !product || product.is_not_on_shelf;
    };

    if (notOnShelf()) {
      color = '#D0021B';
      fill = `${color}22`;
    } else if (
      selectedProduct &&
      selectedProduct.brandbankUuid === rect.brandbank_uuid
    ) {
      color = '#ffffff';
      fill = 'transparent';
    } else {
      switch (rect.facing_status) {
        case 'empty':
          color = '#00d9eb';
          fill = `${color}22`;
          break;
        case 'correct_item':
          color = '#ffffff';
          fill = 'transparent';
          break;
        case 'unrecognized_item':
          color = '#EED202';
          fill = `${color}22`;
          break;
      }
    }
    return { color, fill };
  };

  if (!contextState?.planogram) return null;

  const totalWidth = contextState.planogram.details.planogram.width;
  const segments = contextState.planogram.details.segments;
  return (
    <div
      data-test="compliance-planogram-view"
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <StyledImage
        ref={imageRef}
        src={contextState.planogram.summary.previewUrl}
        style={{ opacity: 0, position: 'absolute' }}
        onLoad={onImageLoad}
      />
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          opacity: zoomInitialized ? 1 : 0,
        }}
        onClick={() => {
          setSegmentState && setSegmentState({});
          setSelectedProduct && setSelectedProduct(undefined);
        }}
        role={role}
      >
        <g
          transform={
            !state.planogram.transform || isNaN(state.planogram.transform?.k)
              ? undefined
              : state.planogram.transform.toString()
          }
        >
          <defs>
            <mask xmlns="http://www.w3.org/2000/svg" id="planogramMask">
              <rect
                width={state.planogram.width}
                height={state.planogram.height}
                x={0}
                y={0}
                fill="white"
              ></rect>
              {displayRects.map((el, index) => {
                return (
                  <rect
                    key={index}
                    x={el.x_min * state.planogram.width}
                    y={el.y_min * state.planogram.height}
                    width={(el.x_max - el.x_min) * state.planogram.width}
                    height={(el.y_max - el.y_min) * state.planogram.height}
                    fill="black"
                  ></rect>
                );
              })}
            </mask>
            <mask xmlns="http://www.w3.org/2000/svg" id="segmentPlanogramMask">
              <rect
                width={state.planogram.width}
                height={state.planogram.height}
                x={0}
                y={0}
              ></rect>
              {segments.map((el, index) => {
                if (
                  segmentState === undefined ||
                  index !== segmentState.selectedSegmentIndex
                )
                  return null;
                const x = (el.offsetX / totalWidth) * state.planogram.width;
                const width = (el.width / totalWidth) * state.planogram.width;
                return (
                  <rect
                    key={index}
                    width={width}
                    height={state.planogram.height}
                    x={x}
                    y={0}
                    fill="black"
                  ></rect>
                );
              })}
            </mask>
          </defs>
          <image href={contextState.planogram.summary.previewUrl} />
          {segmentState?.selectedSegmentIndex !== undefined && (
            <rect
              width={state.planogram.width}
              height={state.planogram.height}
              x={0}
              y={0}
              fill="#262B2FCC"
              mask="url(#segmentPlanogramMask)"
            />
          )}
          <rect
            width={state.planogram.width}
            height={state.planogram.height}
            x={0}
            y={0}
            fill="#262B2F99"
            mask="url(#planogramMask)"
          />

          {segments.map((el, index) => {
            const x = (el.offsetX / totalWidth) * state.planogram.width;
            const width = (el.width / totalWidth) * state.planogram.width;
            const isHovering =
              segmentState !== undefined &&
              segmentState.hoveringSegmentIndex === index;
            return (
              <g key={index}>
                <SegmentRect
                  onMouseEnter={() => {
                    setSegmentState &&
                      setSegmentState({
                        ...segmentState,
                        hoveringInPlanogramSegmentIndex: index,
                      });
                  }}
                  onMouseLeave={() => {
                    setSegmentState &&
                      setSegmentState({
                        ...segmentState,
                        hoveringInPlanogramSegmentIndex: undefined,
                      });
                  }}
                  onClick={(e: React.MouseEvent) => {
                    setSegmentState &&
                      setSegmentState({
                        ...segmentState,
                        selectedSegmentIndex: index,
                      });
                    e.stopPropagation();
                  }}
                  isHovering={isHovering}
                  width={width}
                  height={state.planogram.height}
                  x={x}
                  y={0}
                  strokeWidth={isHovering ? 0 : 2}
                  stroke={'#D4D5D5'}
                  fill={isHovering ? '#D4D5D566' : '#262B2F01'}
                />
              </g>
            );
          })}

          <g>
            {displayRects.map((el, index) => {
              const { color, fill } = getPolygonColors(el);

              return (
                <g key={index}>
                  <TagPolygon
                    points={getPointsString(el)}
                    stroke={color}
                    strokeWidth={
                      1 /
                      (!state.planogram.transform ||
                      isNaN(state.planogram.transform.k)
                        ? 1
                        : state.planogram.transform.k)
                    }
                    fill={fill}
                    strokeLinejoin="round"
                    rx={
                      4 /
                      (!state.planogram.transform ||
                      isNaN(state.planogram.transform.k)
                        ? 1
                        : state.planogram.transform.k)
                    }
                    ry={
                      4 /
                      (!state.planogram.transform ||
                      isNaN(state.planogram.transform.k)
                        ? 1
                        : state.planogram.transform.k)
                    }
                    onClick={(e: React.MouseEvent) => {
                      if (setSelectedProduct) {
                        if (
                          selectedProduct &&
                          selectedProduct.brandbankUuid === el.brandbank_uuid
                        ) {
                          setSelectedProduct(undefined);
                        } else {
                          setSelectedProduct(el.brandbank_uuid);
                        }
                      }
                      e.stopPropagation();
                    }}
                  />
                </g>
              );
            })}
          </g>
        </g>
      </svg>
    </div>
  );
};
