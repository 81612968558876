import React, { ReactNode } from 'react';
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';
const stylesInterface = require('react-with-styles-interface-css').default;
const RheostatDefaultTheme = require('rheostat/lib/themes/DefaultTheme')
  .default;
const ReactDatesDefaultTheme = require('react-dates/lib/theme/DefaultTheme')
  .default;

const stylesTheme = {
  ...RheostatDefaultTheme,
  ...ReactDatesDefaultTheme,
};

const value = { stylesInterface, stylesTheme };

const ThemeProvider = ({ children }: { children: ReactNode }) => (
  <WithStylesContext.Provider value={value}>
    {children}
  </WithStylesContext.Provider>
);

export { ThemeProvider };
