import React, { FC, SyntheticEvent } from 'react';
import { useLocation, Link, NavLink, useRouteMatch } from 'react-router-dom';
import { TertiaryButton } from '@gsc/components';
import { Hamburger } from '@gsc/icons/react';
import { SecondaryNav } from './SecondaryNav';
import { SwitchToNav } from './SwitchToNav';
import {
  IsFeatureEnabled,
  isMobileDevice,
  useWindowDimensions,
} from '../../shared/utils';
import {
  useSpacesState,
  useCurrentSpace,
  useCurrentSpaceObj,
} from '../../state/Spaces';
import {
  DesktopCameraIcon,
  DesktopHeaderText,
  DesktopHeaderWrapper,
  DesktopLogo,
  DesktopNavigationWrapper,
  DesktopNavWrapper,
  FreeLink,
  MobileCameraIcon,
  MobileHeaderText,
  MobileHeaderWrapper,
  MobileLogo,
  MobileNavigationWrapper,
  MobileNavWrapper,
  PendoAnchor,
  PendoIcon,
  SignInButton,
} from './elements';
import { useAuth } from '../../state/Auth';
import { useQueryParams } from '../../state/QueryParams/hooks';
import { paths } from '../../paths';
import {
  SpaceFeatureFlag,
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import moment from 'moment';

interface ConditionalLinkProps {
  isDesktop?: boolean;
  isOnPhotosGrid?: boolean;
  linkDisabled?: boolean;
  to: string;
  testId?: string;
}

interface HeaderWrapperProps {
  dataTest: string;
  isDesktop: boolean | undefined;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({
  children,
  dataTest,
  isDesktop,
}) =>
  isDesktop ? (
    <DesktopHeaderWrapper data-test={dataTest}>{children}</DesktopHeaderWrapper>
  ) : (
    <MobileHeaderWrapper data-test={dataTest}>{children}</MobileHeaderWrapper>
  );

interface NavigationWrapperProps {
  Button: any;
  buttonText: any;
  dataTest: string;
  isDesktop: boolean | undefined;
}

const NavigationWrapper: FC<NavigationWrapperProps> = ({
  Button,
  buttonText,
  children,
  dataTest,
  isDesktop,
}) =>
  isDesktop ? (
    <DesktopNavigationWrapper
      Button={Button}
      buttonText={buttonText}
      dataTest={dataTest}
    >
      {children}
    </DesktopNavigationWrapper>
  ) : (
    <MobileNavigationWrapper
      Button={Button}
      buttonText={buttonText}
      dataTest={dataTest}
    >
      {children}
    </MobileNavigationWrapper>
  );

interface FullLogoLinkProps extends ConditionalLinkProps {
  as: any;
  onClick(e: SyntheticEvent<Element, Event>): void;
  role: string;
}

const FullLogoLink: FC<FullLogoLinkProps> = ({
  as,
  children,
  isDesktop,
  onClick,
  role,
  to,
}) =>
  isDesktop ? (
    <DesktopLogo as={as} onClick={onClick} role={role} to={to}>
      {children}
    </DesktopLogo>
  ) : (
    <MobileLogo as={as} onClick={onClick} role={role} to={to}>
      {children}
    </MobileLogo>
  );

const LogoLink: FC<ConditionalLinkProps> = ({
  children,
  isDesktop,
  isOnPhotosGrid,
  linkDisabled,
  to,
}) =>
  linkDisabled ? (
    isDesktop ? (
      <DesktopLogo>{children}</DesktopLogo>
    ) : (
      <MobileLogo>{children}</MobileLogo>
    )
  ) : (
    <FullLogoLink
      as={Link}
      isDesktop={isDesktop}
      isOnPhotosGrid={isOnPhotosGrid}
      role="logo"
      onClick={(e: SyntheticEvent) => {
        if (isOnPhotosGrid) {
          e.preventDefault();
          window.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }
      }}
      to={to}
    >
      {children}
    </FullLogoLink>
  );

interface DisabledHeaderTextProps {
  isDesktop: boolean | undefined;
}

const DisabledHeaderText: FC<DisabledHeaderTextProps> = ({
  children,
  isDesktop,
}) => {
  return isDesktop ? (
    <DesktopHeaderText className="active">{children}</DesktopHeaderText>
  ) : (
    <MobileHeaderText className="active">{children}</MobileHeaderText>
  );
};

interface LinkHeaderTextProps {
  as: any;
  isDesktop: boolean | undefined;
  onClick(e: React.SyntheticEvent<Element, Event>): void;
  to: string;
  testId?: string;
}

const LinkHeaderText: FC<LinkHeaderTextProps> = ({
  as,
  children,
  isDesktop,
  onClick,
  to,
  testId,
}) => {
  return isDesktop ? (
    <DesktopHeaderText as={as} to={to} onClick={onClick} data-test={testId}>
      {children}
    </DesktopHeaderText>
  ) : (
    <MobileHeaderText as={as} to={to} onClick={onClick} data-test={testId}>
      {children}
    </MobileHeaderText>
  );
};

const HeaderLink: FC<ConditionalLinkProps> = ({
  children,
  isDesktop,
  isOnPhotosGrid,
  linkDisabled,
  to,
  testId,
}) =>
  linkDisabled ? (
    <DisabledHeaderText isDesktop={isDesktop}>{children}</DisabledHeaderText>
  ) : (
    <LinkHeaderText
      as={NavLink}
      isDesktop={isDesktop}
      onClick={(e: SyntheticEvent) => {
        if (isOnPhotosGrid) {
          e.preventDefault();
          window.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }
      }}
      to={to}
      testId={testId}
    >
      {children}
    </LinkHeaderText>
  );

const NavWrapper: FC<DisabledHeaderTextProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopNavWrapper>{children}</DesktopNavWrapper>
  ) : (
    <MobileNavWrapper>{children}</MobileNavWrapper>
  );

interface BasicHeaderProps {
  isDesktop: boolean | undefined;
}

const BasicHeader: FC<BasicHeaderProps> = ({ isDesktop }) => {
  const { setSignIn } = useQueryParams();
  const signIn = () => {
    setSignIn(true);
    window.location.reload(false);
  };

  return (
    <HeaderWrapper dataTest="basic-header" isDesktop={isDesktop}>
      <DesktopLogo>
        <DesktopCameraIcon />
      </DesktopLogo>
      <SignInButton onClick={signIn} status="neutral">
        Sign In
      </SignInButton>
    </HeaderWrapper>
  );
};

// eslint-disable-next-line complexity
const Header: FC = () => {
  const auth = useAuth();
  const state = useSpacesState();
  const currentSpace = useCurrentSpace();
  const currentSpaceObj = useCurrentSpaceObj();
  const location = useLocation();
  const largerWindow = useWindowDimensions().width >= 1019;
  const isDesktop = !isMobileDevice() && largerWindow;
  const shareRouteMatch = useRouteMatch<{ shareToken: string }>(
    paths.share.root()
  );
  const authorized = auth.status === auth.types.AuthStatus.AUTHORIZED;

  const hasSpaces = Object.keys(state.spaces).length > 0;
  const activeSpace =
    currentSpaceObj?.status === SpaceStatus.SPACE_STATUS_ACTIVE;

  const buttonText =
    currentSpace.isMatch && activeSpace
      ? state.spaces[currentSpace.id].name
      : auth.user.email;

  const linksDisabled = !hasSpaces;
  const rootPath = paths.spaces.space.photos.root(currentSpace.id);
  const pathMatch = location.pathname === rootPath;
  const isOnExportPage: boolean = location.search?.includes('export=true');
  const isOnPhotosGrid: boolean = pathMatch && !isOnExportPage;

  const logoHrefParams = pathMatch
    ? location.search?.replace(/export=true&?/, '')
    : '';

  const logoHref = `${rootPath}${logoHrefParams}`;

  const notLiteTier =
    currentSpaceObj?.tier?.type !== SpaceTierType.SPACE_TIER_TYPE_LITE;

  const isTrialTier =
    currentSpaceObj?.tier?.type === SpaceTierType.SPACE_TIER_TYPE_TRIAL;

  const trialEndDate = moment(currentSpaceObj?.tier?.trialEndDate).fromNow();

  const isPlanogramSpace = IsFeatureEnabled(
    currentSpaceObj,
    SpaceFeatureFlag.SPACE_FEATURE_FLAG_PLANOGRAM_COMPLIANCE
  );

  return shareRouteMatch && !authorized ? (
    <BasicHeader isDesktop={isDesktop} />
  ) : (
    <HeaderWrapper dataTest="main-header" isDesktop={isDesktop}>
      <LogoLink
        isDesktop={isDesktop}
        isOnPhotosGrid={isOnPhotosGrid}
        linkDisabled={linksDisabled}
        to={logoHref}
      >
        {isDesktop ? <DesktopCameraIcon /> : <MobileCameraIcon />}
      </LogoLink>

      <NavigationWrapper
        Button={TertiaryButton}
        buttonText={<Hamburger />}
        dataTest="main-navigation"
        isDesktop={isDesktop}
      >
        <HeaderLink
          isDesktop={isDesktop}
          isOnPhotosGrid={isOnPhotosGrid}
          linkDisabled={linksDisabled}
          to={logoHref}
        >
          Photos
        </HeaderLink>

        {notLiteTier && (
          <HeaderLink
            isDesktop={isDesktop}
            linkDisabled={linksDisabled}
            to={paths.spaces.space.albums.root(currentSpace.id)}
          >
            Albums
          </HeaderLink>
        )}

        {notLiteTier && isPlanogramSpace && (
          <HeaderLink
            testId="header-link-planogram-compliance"
            isDesktop={isDesktop}
            linkDisabled={linksDisabled}
            to={paths.spaces.space.compliance.root(currentSpace.id)}
          >
            Planogram Compliance
          </HeaderLink>
        )}

        <NavWrapper isDesktop={isDesktop}>
          {isTrialTier && activeSpace && pathMatch && (
            <FreeLink
              isNewTab
              href="https://www.gospotcheck.com/product/photo-management-reporting"
            >
              Free trial ends {trialEndDate}
            </FreeLink>
          )}
          {isDesktop && (
            <PendoAnchor
              data-test="pendo-anchor-icon"
              isIconOnly
              status="neutral"
            >
              <PendoIcon />
            </PendoAnchor>
          )}
          {isDesktop && auth.hasGscAccess && <SwitchToNav />}
          <SecondaryNav
            buttonText={buttonText}
            email={auth.user.email}
            hasGscAccess={auth.hasGscAccess}
            initials={auth.getUserInitials()}
            isApertureAdmin={auth.isApertureAdmin}
            isDesktop={isDesktop}
            isSpaceAdmin={auth.isSpaceAdmin}
            logout={() => auth.logout({ returnTo: window.location.origin })}
            multiSpace={Object.keys(state.spaces).length > 1}
            name={auth.user.name}
            spaceId={currentSpace.id}
          />
        </NavWrapper>
      </NavigationWrapper>
    </HeaderWrapper>
  );
};

export { Header };
