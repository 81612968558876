import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { border, color } from '@gsc/components';

const menuLinkStyles = `
  border-left: ${border.heavy('transparent')};
  color: ${color.ui50};
  display: block;
  line-height: 4.4rem;
  padding: 0 2.7rem;
  width: 100%;

  &:hover {
    background-color: ${color.ui05};
  }

  &.active,
  &.active:hover {
    background-color: ${color.ui01};
    border-left-color: ${color.mxDeepOcean};
    color: ${color.text900};
    cursor: default;
  }
`;

const MenuLink = styled.a.attrs(({ key }: { key: string }) => ({
  key,
}))`
  ${menuLinkStyles}
`;

const DropdownMenuLink = styled(NavLink)`
  ${menuLinkStyles}
`;

export { DropdownMenuLink, MenuLink, menuLinkStyles };
