import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useReducer,
} from 'react';

import { Actions } from './actions';
import { reducer, State } from './reducer';
import { useClearSelectionsWithSpace, useGetPhotos } from './effects';

interface PhotosProviderProps {
  children: ReactNode;
}

const PhotosStateContext = createContext<State | undefined>(undefined);
const PhotosDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined
);

const initialState: State = {
  assetDetailsById: {},
  assetDetailsPages: {},
  detailPlaceholder: {
    clickedCoordinates: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
    placeholder: '',
  },
  loading: true,
  mobileSelectMode: false,
  pagination: {
    from: 0,
    size: 0,
  },
  selectedPhotos: [],
  totalHits: 0,
  showDetails: true,
  showPlanogram: true,
};

const PhotosProvider: FC<PhotosProviderProps> = props => {
  const getShowDetails = window.localStorage.getItem('showDetails');
  const getShowPlanogram = window.localStorage.getItem('showPlanogram');

  const initState = {
    ...initialState,
    showDetails: getShowDetails === null ? true : getShowDetails === 'true',
    showPlanogram:
      getShowPlanogram === null ? true : getShowPlanogram === 'true',
  };

  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    window.localStorage.setItem(
      'showDetails',
      (!!state.showDetails).toString()
    );
  }, [state.showDetails]);

  useEffect(() => {
    window.localStorage.setItem(
      'showPlanogram',
      (!!state.showPlanogram).toString()
    );
  }, [state.showPlanogram]);

  useGetPhotos(dispatch);
  useClearSelectionsWithSpace(dispatch);

  return (
    <PhotosStateContext.Provider value={state}>
      <PhotosDispatchContext.Provider value={dispatch}>
        {props.children}
      </PhotosDispatchContext.Provider>
    </PhotosStateContext.Provider>
  );
};

export {
  initialState,
  PhotosProvider,
  PhotosStateContext,
  PhotosDispatchContext,
};
