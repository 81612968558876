import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { zoom as d3Zoom, ZoomBehavior, ZoomTransform } from 'd3-zoom';
import { select } from 'd3-selection';
import React from 'react';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import {
  zoomToFitImage,
  zoomToFitImageWidth,
  zoomToPoints,
} from '../ImageWithTags/zoom_functions';
import { ViewControls } from './ViewControls';
import { ProductResultRects } from './ProductResultRects';
import styled from 'styled-components';
import { useComplianceViewerContext } from '../../../../../../../state/ComplianceViewer/hooks';
import { ActionTypes } from '../../../../../../../state/ComplianceViewer/actions';

type RealogramProps = {
  assetDetail: AssetDetail.AsObject | undefined;
  goToNextPhoto: () => void;
  goToPreviousPhoto: () => void;
  planogramVisible: boolean;
};

export const TagPolygon = styled.polygon`
  cursor: pointer;
  fill-opacity: 1;
  &:hover {
    fill-opacity: 0;
  }
`;

export const StyledImage = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
`;

const role = 'zoom-area-realogram';

const transformsEqual = (a: ZoomTransform, b: ZoomTransform) => {
  const tolerance = 0.00001;
  return (
    Math.abs(a.x - b.x) < tolerance &&
    Math.abs(a.y - b.y) < tolerance &&
    Math.abs(a.k - b.k) < tolerance
  );
};

export const RealogramView = ({
  assetDetail,
  goToNextPhoto,
  goToPreviousPhoto,
  planogramVisible,
}: RealogramProps) => {
  const [state, dispatch] = useComplianceViewerContext();

  const svgRef = useRef<SVGSVGElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const zoom = useRef<ZoomBehavior<Element, unknown> | null>(null);
  const [fitToScreenTransform, setFitToScreenTransform] = useState<
    ZoomTransform
  >();
  const [viewport, setViewport] = useState({ width: 0, height: 0 });
  const context = useContext(ComplianceStateContext)?.state;
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;
  const setSelectedProduct = useContext(ComplianceStateContext)
    ?.setSelectedProduct;
  const segmentState = useContext(ComplianceStateContext)?.segmentState;
  const setSegmentState = useContext(ComplianceStateContext)?.setSegmentState;

  const getPointsString = (rect: {
    x_max: number;
    x_min: number;
    y_max: number;
    y_min: number;
  }) => {
    return `${rect.x_min * state.realogram.width} ${rect.y_min *
      state.realogram.height} ${rect.x_max *
      state.realogram.width} ${rect.y_min *
      state.realogram.height} ${rect.x_max *
      state.realogram.width} ${rect.y_max *
      state.realogram.height} ${rect.x_min *
      state.realogram.width} ${rect.y_max * state.realogram.height}`;
  };

  const { selectedSegmentIndex } = useMemo(() => {
    if (!segmentState) return {};
    return segmentState;
  }, [segmentState]);

  const getRect = (brandbankUuid: string) => {
    let result:
      | {
          x_max: number;
          x_min: number;
          y_max: number;
          y_min: number;
        }
      | undefined;

    const planned_rects = context?.kpiResult?.planned_rects;
    if (planned_rects) {
      let x_max = 0;
      let x_min = 2;
      let y_max = 0;
      let y_min = 2;
      planned_rects
        .filter(
          el =>
            el.brandbank_uuid === brandbankUuid ||
            el.found_brandbank_uuid === brandbankUuid
        )
        .forEach(el => {
          x_min = Math.min(x_min, el.x_min);
          y_min = Math.min(y_min, el.y_min);
          x_max = Math.max(x_max, el.x_max);
          y_max = Math.max(y_max, el.y_max);
          result = { x_min, x_max, y_min, y_max };
        });
    }
    return result;
  };

  const getFitToScreenTransform = useCallback(() => {
    if (
      state.realogram.plannedRect &&
      state.realogram.plannedRect.width > 0 &&
      state.realogram.plannedRect.height > 0 &&
      !!svgRef.current
    ) {
      let viewportWidth = viewport.width;
      let viewportHeight = viewport.height;
      if (viewportWidth === 0 || viewportHeight === 0) {
        const boundingRect = svgRef.current?.getBoundingClientRect();
        if (boundingRect) {
          viewportWidth = boundingRect.width;
          viewportHeight = boundingRect.height;
        }
      }

      const initialTransform = zoomToFitImage({
        imageWidth: state.realogram.plannedRect.width,
        imageHeight: state.realogram.plannedRect.height,
        viewportWidth,
        viewportHeight,
      });
      return initialTransform;
    }
    return;
  }, [state.realogram.plannedRect, viewport]);

  useEffect(() => {
    const imageWidth = state.realogram.plannedRect?.width ?? 0;
    const imageHeight = state.realogram.plannedRect?.height ?? 0;
    if (
      !fitToScreenTransform &&
      imageWidth > 0 &&
      imageHeight > 0 &&
      !!svgRef.current
    ) {
      zoom.current = d3Zoom()
        .scaleExtent([1 / 10, 10])
        .on('zoom', e => {
          if (e.sourceEvent !== null) {
            dispatch({
              type: ActionTypes.SetRealogramTransform,
              payload: {
                transform: e.transform,
              },
            });
          }
        });

      const initialTransform = getFitToScreenTransform();

      if (initialTransform) {
        select(`[role="${role}"]`)
          .call(zoom.current as any)
          .call(zoom.current.transform as any, initialTransform);

        setFitToScreenTransform(initialTransform);
        (select(`[role="${role}"]`) as any).call(
          zoom.current?.transform as any,
          initialTransform
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, assetDetail?.assetUrl, state.realogram.plannedRect]);

  useEffect(() => {
    if (fitToScreenTransform) {
      (select(`[role="${role}"]`) as any).call(
        zoom.current?.transform as any,
        state.realogram.transform
      );
    }
  }, [state.realogram.transform, fitToScreenTransform]);

  useEffect(() => {
    const { width: viewportWidth = 0, height: viewportHeight = 0 } =
      svgRef.current?.getBoundingClientRect() ?? {};

    // wait for SVG to load to prevent DOM exception
    if (
      !fitToScreenTransform ||
      state.realogram.width < 0 ||
      state.realogram.height < 0
    ) {
      return;
    }

    const selectedProductRect =
      !!selectedProduct && getRect(selectedProduct.brandbankUuid);
    const zoomTransform = selectedProductRect
      ? zoomToPoints({
          points: getPointsString(selectedProductRect),
          viewportWidth,
          viewportHeight,
        })
      : getFitToScreenTransform();

    if (
      zoomTransform &&
      !transformsEqual(zoomTransform, state.realogram.transform)
    ) {
      dispatch({
        type: ActionTypes.SetRealogramTransform,
        payload: {
          transform: zoomTransform,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitToScreenTransform, state.realogram.plannedRect, selectedProduct]);

  const onImageLoad = () => {
    if (imageRef.current && context?.kpiResult) {
      dispatch({
        type: ActionTypes.SetRealogramDimensions,
        payload: {
          width: imageRef.current.naturalWidth,
          height: imageRef.current.naturalHeight,
          kpiResult: context?.kpiResult,
        },
      });
    }
  };

  const imageUrl = useMemo(() => {
    if (!context?.kpiResult) return '';
    return context?.kpiResult?.generated_image_url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDetail?.assetUrl, context?.kpiResult]);

  const zoomIn = () => {
    !!zoom.current &&
      dispatch({
        type: ActionTypes.SetRealogramTransform,
        payload: {
          transform: state.realogram.transform.scale(1.5),
        },
      });
  };

  const zoomOut = () => {
    !!zoom.current &&
      dispatch({
        type: ActionTypes.SetRealogramTransform,
        payload: {
          transform: state.realogram.transform.scale(0.75),
        },
      });
  };

  const [zoomToSelectionEnabled, setZoomToSelectionEnabled] = useState(false);
  const [zoomSelection, setZoomSelection] = useState<{
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  }>();

  const toggleZoomToSelection = () => {
    setZoomToSelectionEnabled(!zoomToSelectionEnabled);
  };

  useEffect(() => {
    if (zoom.current) {
      if (!zoomToSelectionEnabled) {
        (select(`[role="${role}"]`) as any).call(zoom.current as any);
      } else {
        (select(`[role="${role}"]`) as any).on('.zoom', null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomToSelectionEnabled]);

  const fitToScreen = useCallback(() => {
    // wait for SVG to load to prevent DOM exception
    if (
      !fitToScreenTransform ||
      state.realogram.width < 0 ||
      state.realogram.height < 0
    ) {
      return;
    }
    const zoomTransform = getFitToScreenTransform();
    if (
      zoomTransform &&
      !transformsEqual(zoomTransform, state.realogram.transform)
    ) {
      if (!transformsEqual(zoomTransform, fitToScreenTransform)) {
        setFitToScreenTransform(zoomTransform);
      }
      dispatch({
        type: ActionTypes.SetRealogramTransform,
        payload: {
          transform: zoomTransform,
        },
      });
    }
  }, [
    fitToScreenTransform,
    state.realogram.width,
    state.realogram.height,
    state.realogram.transform,
    getFitToScreenTransform,
    dispatch,
  ]);

  const fitToWidth = useCallback(() => {
    const { width: viewportWidth = 0, height: viewportHeight = 0 } =
      svgRef.current?.getBoundingClientRect() ?? {};

    // wait for SVG to load to prevent DOM exception
    if (
      !fitToScreenTransform ||
      state.realogram.width < 0 ||
      state.realogram.height < 0
    ) {
      return;
    }

    const imageWidth = state.realogram.width;
    const imageHeight = state.realogram.height;

    const zoomTransform = zoomToFitImageWidth({
      imageWidth,
      imageHeight,
      viewportWidth,
      viewportHeight,
    });
    dispatch({
      type: ActionTypes.SetRealogramTransform,
      payload: {
        transform: zoomTransform,
      },
    });
  }, [
    fitToScreenTransform,
    state.realogram.width,
    state.realogram.height,
    dispatch,
  ]);

  const observer = useRef(
    new ResizeObserver(() => {
      setTimeout(() => {
        const { width = 0, height = 0 } =
          svgRef.current?.getBoundingClientRect() ?? {};
        setViewport({ width, height });
      }, 0);
    })
  );

  useEffect(() => {
    if (observerRef.current) {
      observer.current.observe(observerRef.current);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.current.disconnect();
    };
  }, [observerRef, observer, fitToScreenTransform]);

  const onMouseDown = (e: React.MouseEvent) => {
    if (zoomToSelectionEnabled) {
      setZoomSelection({
        x1: e.clientX,
        y1: e.clientY,
        x2: e.clientX + 1,
        y2: e.clientY + 1,
      });
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (zoomToSelectionEnabled && zoomSelection) {
      setZoomSelection(prev =>
        prev ? { ...prev, x2: e.clientX, y2: e.clientY } : undefined
      );
    }
  };

  const onMouseUp = () => {
    const { width: viewportWidth = 0, height: viewportHeight = 0 } =
      svgRef.current?.getBoundingClientRect() ?? {};

    // wait for SVG to load to prevent DOM exception
    if (
      !fitToScreenTransform ||
      state.realogram.width < 0 ||
      state.realogram.height < 0 ||
      !zoomToSelectionEnabled ||
      !zoomSelection ||
      !state.realogram.transform
    ) {
      return;
    }

    const x_min =
      (Math.min(zoomSelection.x1, zoomSelection.x2) -
        state.realogram.transform.x) /
      state.realogram.transform.k;
    const x_max =
      (Math.max(zoomSelection.x1, zoomSelection.x2) -
        state.realogram.transform.x) /
      state.realogram.transform.k;
    const y_min =
      (Math.min(zoomSelection.y1, zoomSelection.y2) -
        state.realogram.transform.y) /
      state.realogram.transform.k;
    const y_max =
      (Math.max(zoomSelection.y1, zoomSelection.y2) -
        state.realogram.transform.y) /
      state.realogram.transform.k;

    const points = `${x_min} ${y_min} ${x_max} ${y_min} ${x_max} ${y_max} ${x_min} ${y_max}`;
    const zoomTransform = zoomToPoints({
      points: points,
      viewportWidth,
      viewportHeight,
    });

    dispatch({
      type: ActionTypes.SetRealogramTransform,
      payload: {
        transform: zoomTransform,
      },
    });
    setZoomSelection(undefined);
  };

  const isZoomed = useMemo(() => {
    return (
      !!fitToScreenTransform &&
      fitToScreenTransform.k < state.realogram.transform.k
    );
  }, [fitToScreenTransform, state.realogram.transform]);

  useEffect(() => {
    if (viewport.width > 0 && viewport.height > 0 && !isZoomed) {
      fitToScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport]);

  const zoomToRect = (
    x_min: number,
    y_min: number,
    x_max: number,
    y_max: number
  ) => {
    const { width: viewportWidth = 0, height: viewportHeight = 0 } =
      svgRef.current?.getBoundingClientRect() ?? {};
    const points = `${x_min} ${y_min} ${x_max} ${y_min} ${x_max} ${y_max} ${x_min} ${y_max}`;
    const zoomTransform = zoomToPoints({
      points: points,
      viewportWidth,
      viewportHeight,
    });
    dispatch({
      type: ActionTypes.SetRealogramTransform,
      payload: {
        transform: zoomTransform,
      },
    });
    setZoomSelection(undefined);
  };

  const segmentRects = context?.kpiResult?.segment_rects ?? [];

  useEffect(() => {
    if (
      segmentRects.length &&
      segmentState?.selectedSegmentIndex !== undefined
    ) {
      const segment = segmentRects[segmentState.selectedSegmentIndex];
      zoomToRect(
        segment.x_min * state.realogram.width,
        segment.y_min * state.realogram.height,
        segment.x_max * state.realogram.width,
        segment.y_max * state.realogram.height
      );
    } else {
      fitToScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentState?.selectedSegmentIndex]);

  if (!context?.kpiResult) return null;

  let previousSegmentDisabled = true;
  let nextSegmentDisabled = true;
  if (selectedSegmentIndex !== undefined) {
    previousSegmentDisabled = selectedSegmentIndex === 0;
  }
  if (selectedSegmentIndex !== undefined) {
    nextSegmentDisabled = selectedSegmentIndex >= segmentRects.length - 1;
  }

  const selectSegment = (index: number) => {
    setSegmentState &&
      setSegmentState({ ...segmentState, selectedSegmentIndex: index });
  };

  return (
    <div
      ref={observerRef}
      data-test="compliance-realogram-view"
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <StyledImage
        ref={imageRef}
        src={imageUrl}
        style={{ opacity: 0 }}
        onLoad={onImageLoad}
      />
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          opacity: fitToScreenTransform ? 1 : 0,
        }}
        role={role}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onClick={() => {
          if (!zoomToSelectionEnabled) {
            setSegmentState && setSegmentState({});
            setSelectedProduct && setSelectedProduct(undefined);
            fitToScreen();
          }
        }}
      >
        <g
          transform={
            isNaN(state.realogram.transform.k)
              ? undefined
              : state.realogram.transform.toString()
          }
        >
          <image href={imageUrl}></image>

          <ProductResultRects
            disableHover={zoomToSelectionEnabled}
            dimensions={state.realogram}
            transform={state.realogram.transform}
            setTransform={realogramTransform => {
              dispatch({
                type: ActionTypes.SetRealogramTransform,
                payload: {
                  transform: realogramTransform,
                },
              });
            }}
            selectSegment={selectSegment}
          />
        </g>
        {zoomSelection && (
          <rect
            width={Math.abs(zoomSelection.x2 - zoomSelection.x1)}
            height={Math.abs(zoomSelection.y2 - zoomSelection.y1)}
            x={Math.min(zoomSelection.x1, zoomSelection.x2)}
            y={Math.min(zoomSelection.y1, zoomSelection.y2)}
            fill="none"
            stroke="white"
            strokeDasharray="4 2"
          ></rect>
        )}
      </svg>
      <ViewControls
        {...{
          zoomIn,
          zoomOut,
          fitToScreen,
          fitToWidth,
          zoomToSelection: zoomToSelectionEnabled,
          toggleZoomToSelection,
          goToNextPhoto,
          goToPreviousPhoto,
          isZoomed,
          selectSegment,
          previousSegmentDisabled,
          nextSegmentDisabled,
          planogramVisible,
        }}
      />
    </div>
  );
};
