import React, { FC, FormEvent, useContext } from 'react';
import { SelectTabRadio, SelectTabRadioGroup } from '@gsc/components';
import classnames from 'classnames';

import {
  isMobileDevice,
  useWindowDimensions,
} from '../../../../../../shared/utils';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { Format } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';

const LayoutPhotosPerSlide: FC = () => {
  const { handleChange, setValues, values } = useContext(FormContext);

  const largerWindow = useWindowDimensions().width >= 1019;
  const isDesktop = !isMobileDevice() && largerWindow;
  const format = parseInt(values.format, 10);

  const handlePhotos = (e: FormEvent) => {
    handleChange(e);
    setValues({ useMetadata: 'false' });
  };

  return format === Format.FORMAT_PDF ? (
    <SelectTabRadioGroup
      className={classnames({ 'large-radio-group': isDesktop })}
    >
      <SelectTabRadio
        checked={values.photosPerPage === '1'}
        id="photosPerPage-1"
        name="photosPerPage"
        onChange={handleChange}
        value="1"
      >
        1
      </SelectTabRadio>
      <SelectTabRadio
        checked={values.photosPerPage === '4'}
        id="photosPerPage-4"
        name="photosPerPage"
        onChange={handleChange}
        value="4"
      >
        4
      </SelectTabRadio>
      <SelectTabRadio
        checked={values.photosPerPage === '6'}
        id="photosPerPage-6"
        name="photosPerPage"
        onChange={handleChange}
        value="6"
      >
        6
      </SelectTabRadio>
      <SelectTabRadio
        checked={values.photosPerPage === '9'}
        id="photosPerPage-9"
        name="photosPerPage"
        onChange={(e: FormEvent) => handlePhotos(e)}
        value="9"
      >
        9
      </SelectTabRadio>
    </SelectTabRadioGroup>
  ) : (
    <SelectTabRadioGroup
      className={classnames({ 'small-radio-group': isDesktop })}
    >
      <SelectTabRadio
        checked={values.photosPerPage === '1'}
        id="photosPerPage-1"
        name="photosPerPage"
        onChange={handleChange}
        value="1"
      >
        1
      </SelectTabRadio>
      <SelectTabRadio
        checked={values.photosPerPage === '2'}
        id="photosPerPage-2"
        name="photosPerPage"
        onChange={handleChange}
        value="2"
      >
        2
      </SelectTabRadio>
      <SelectTabRadio
        checked={values.photosPerPage === '3'}
        id="photosPerPage-3"
        name="photosPerPage"
        onChange={handleChange}
        value="3"
      >
        3
      </SelectTabRadio>
    </SelectTabRadioGroup>
  );
};

export { LayoutPhotosPerSlide };
