import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../../style/breakpoint';
import {
  AssetDetail,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import {
  PhotoDetailsPanels,
  useQueryParams,
} from '../../../../../../state/QueryParams/hooks';
import { PhotoDetailsImage } from './PhotoDetailsImage';
import { ChevronLeftBold } from '@gsc/icons/react';
import { ActionTypes, usePhotosContext } from '../../../../../../state/Photos';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background: #ffffff;
`;

const PhotoViewerContainer = styled.div`
  background-color: #262b2f;
  color: #ffffff;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopBar = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  padding: 1.4rem 2.4rem;
  gap: 0.8rem;
`;

export const StyledButton = styled.button`
  background-color: #262b2faa;
  font-size: 1.4rem;
  color: #ffffff;
  border: 0.1rem solid #5c6063;
  border-radius: 0.4rem;
  display: flex;
  z-index: 3;
  align-items: center;
  gap: 0.6rem;
  height: 3.2rem;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  padding: 0.6rem 0.8rem;
  font-family: Helvetica Neue, sans-serif;
  & span {
    font-family: Helvetica Neue, sans-serif;
  }
`;

const StyledIconButton = styled.button`
  background-color: #262b2faa;
  border: 0.1rem solid #5c6063;
  border-radius: 0.4rem;
  display: flex;
  color: white;
  padding: 0;
  z-index: 3;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #5c6063;
  }
`;

const IconButtonImage = styled.svg<{ selected?: boolean }>`
  width: 3.4rem;
  height: 3.4rem;
  fill: ${p => (p.selected ? '#00D9EB' : 'white')};
`;

const BackButtonImage = styled(ChevronLeftBold)`
  fill: white;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0.2rem;
`;

const BackButtonText = styled.span`
  margin-left: 0.8rem;
  font-family: Helvetica Neue, sans-serif;
`;

export const FlexSpacer = styled.div`
  flex-grow: 1;
`;

// const Spacer = styled.div`
//   padding: 0.8rem;
// `;

const BottomBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.4rem 2.4rem;
  display: none;
  ${breakpoint.lg`
    display: flex;
  `}
`;

interface PhotoViewerProps {
  assetDetail: AssetDetail.AsObject | undefined;
  className?: string;
  downloadPic: () => void;
  isDesktop: boolean;
  loading?: boolean;
  goToNextPhoto: () => void;
  goToPreviousPhoto: () => void;
  hasPreviousPhoto: boolean;
}

const PhotoViewer = ({
  assetDetail,
  downloadPic,
  isDesktop,
  goToNextPhoto,
  goToPreviousPhoto,
  hasPreviousPhoto,
  className,
}: PhotoViewerProps) => {
  const {
    photoDetailsPanel,
    setShowAlbumModal,
    setShowPhoto,
    inApp,
  } = useQueryParams();
  const [{ showDetails, showPlanogram }, dispatch] = usePhotosContext();
  const currentSpaceObj = useCurrentSpaceObj();
  const onLiteTier =
    currentSpaceObj?.tier?.type === SpaceTierType.SPACE_TIER_TYPE_LITE;

  const navText = 'Back';
  const onComplianceTab =
    photoDetailsPanel === PhotoDetailsPanels.COMPLIANCE_SUMMARY ||
    photoDetailsPanel === PhotoDetailsPanels.RECOMMENDED_ACTIONS;
  return (
    <Container>
      <PhotoViewerContainer data-test="photo-viewer" className={className}>
        <TopBar>
          {!inApp && (
            <StyledButton
              data-test="photo-viewer-back"
              onClick={() => setShowPhoto()}
            >
              <BackButtonImage />
              <BackButtonText>{navText}</BackButtonText>
            </StyledButton>
          )}
          <FlexSpacer />
          <StyledIconButton
            data-test="photo-viewer-toggle-details"
            onClick={() => dispatch({ type: ActionTypes.ToggleShowDetails })}
          >
            <IconButtonImage
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34 34"
              selected={showDetails}
            >
              <path
                d="M17 8C15.22 8 13.4799 8.52784 11.9999 9.51677C10.5198 10.5057 9.36628 11.9113 8.68509 13.5558C8.0039 15.2004 7.82567 17.01 8.17294 18.7558C8.5202 20.5016 9.37737 22.1053 10.636 23.364C11.8947 24.6226 13.4984 25.4798 15.2442 25.8271C16.99 26.1743 18.7996 25.9961 20.4442 25.3149C22.0887 24.6337 23.4943 23.4802 24.4832 22.0001C25.4722 20.5201 26 18.78 26 17C26 14.6131 25.0518 12.3239 23.364 10.636C21.6761 8.94821 19.387 8 17 8ZM17.1875 11.75C17.41 11.75 17.6275 11.816 17.8125 11.9396C17.9975 12.0632 18.1417 12.2389 18.2269 12.4445C18.312 12.65 18.3343 12.8762 18.2909 13.0945C18.2475 13.3127 18.1403 13.5132 17.983 13.6705C17.8257 13.8278 17.6252 13.935 17.407 13.9784C17.1888 14.0218 16.9626 13.9995 16.757 13.9144C16.5514 13.8292 16.3757 13.685 16.2521 13.5C16.1285 13.315 16.0625 13.0975 16.0625 12.875C16.0625 12.5766 16.181 12.2905 16.392 12.0795C16.603 11.8685 16.8891 11.75 17.1875 11.75ZM18.875 21.875H15.875C15.6761 21.875 15.4853 21.796 15.3447 21.6553C15.204 21.5147 15.125 21.3239 15.125 21.125C15.125 20.9261 15.204 20.7353 15.3447 20.5947C15.4853 20.454 15.6761 20.375 15.875 20.375H16.4375C16.4872 20.375 16.5349 20.3552 16.5701 20.3201C16.6052 20.2849 16.625 20.2372 16.625 20.1875V16.8125C16.625 16.7628 16.6052 16.7151 16.5701 16.6799C16.5349 16.6448 16.4872 16.625 16.4375 16.625H15.875C15.6761 16.625 15.4853 16.546 15.3447 16.4053C15.204 16.2647 15.125 16.0739 15.125 15.875C15.125 15.6761 15.204 15.4853 15.3447 15.3447C15.4853 15.204 15.6761 15.125 15.875 15.125H16.625C17.0228 15.125 17.4044 15.283 17.6857 15.5643C17.967 15.8456 18.125 16.2272 18.125 16.625V20.1875C18.125 20.2372 18.1448 20.2849 18.1799 20.3201C18.2151 20.3552 18.2628 20.375 18.3125 20.375H18.875C19.0739 20.375 19.2647 20.454 19.4053 20.5947C19.546 20.7353 19.625 20.9261 19.625 21.125C19.625 21.3239 19.546 21.5147 19.4053 21.6553C19.2647 21.796 19.0739 21.875 18.875 21.875Z"
                fill={'inherit'}
              />
            </IconButtonImage>
          </StyledIconButton>

          {onComplianceTab && (
            <StyledIconButton
              data-test="photo-viewer-toggle-planogram"
              onClick={() =>
                dispatch({ type: ActionTypes.ToggleShowPlanogram })
              }
            >
              <IconButtonImage
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill="none"
                selected={showPlanogram && onComplianceTab}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 8C8.89543 8 8 8.89543 8 10V17H11V12C11 11.4477 11.4477 11 12 11H14V17H15V11H17C17.5523 11 18 11.4477 18 12V17H26V10C26 8.89543 25.1046 8 24 8H10ZM26 18H18H11H8V24C8 25.1046 8.89543 26 10 26H11H18H19H22H24C25.1046 26 26 25.1046 26 24V18ZM22 25V20C22 19.4477 21.5523 19 21 19H19V25H22ZM18 25V19H15V25H18ZM14 25V19H12C11.4477 19 11 19.4477 11 20V25H14Z"
                  fill={'inherit'}
                />
              </IconButtonImage>
            </StyledIconButton>
          )}

          <StyledIconButton
            data-test="photo-viewer-download-photo"
            onClick={downloadPic}
          >
            <IconButtonImage
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34 34"
              fill="none"
            >
              <path
                d="M25.91 19.46L23.66 15.3275C23.537 15.1006 23.355 14.9111 23.1332 14.7791C22.9114 14.647 22.6581 14.5774 22.4 14.5775H20.75C20.5511 14.5775 20.3603 14.6565 20.2197 14.7972C20.079 14.9378 20 15.1286 20 15.3275C20 15.5264 20.079 15.7172 20.2197 15.8578C20.3603 15.9985 20.5511 16.0775 20.75 16.0775H22.25C22.2836 16.0782 22.3164 16.0875 22.3453 16.1046C22.3742 16.1217 22.3982 16.1459 22.415 16.175L24.0425 19.175C24.059 19.2035 24.0676 19.2358 24.0676 19.2688C24.0676 19.3017 24.059 19.334 24.0425 19.3625C24.0266 19.3901 24.0036 19.413 23.9759 19.4288C23.9482 19.4446 23.9169 19.4528 23.885 19.4525H21.125C20.8266 19.4525 20.5405 19.571 20.3295 19.782C20.1185 19.993 20 20.2791 20 20.5775C20 20.8759 19.8815 21.162 19.6705 21.373C19.4595 21.584 19.1734 21.7025 18.875 21.7025H15.125C14.976 21.7025 14.8285 21.6729 14.691 21.6155C14.5535 21.558 14.4288 21.4738 14.3242 21.3677C14.2195 21.2616 14.1369 21.1359 14.0813 20.9976C14.0256 20.8594 13.998 20.7115 14 20.5625C14 20.2641 13.8815 19.978 13.6705 19.767C13.4595 19.556 13.1734 19.4375 12.875 19.4375H10.1C10.0684 19.4364 10.0376 19.4276 10.0101 19.4119C9.98271 19.3963 9.9595 19.3741 9.9425 19.3475C9.92604 19.319 9.91738 19.2867 9.91738 19.2537C9.91738 19.2208 9.92604 19.1885 9.9425 19.16L11.51 16.16C11.5255 16.1299 11.5493 16.1047 11.5784 16.0875C11.6076 16.0702 11.6411 16.0616 11.675 16.0625H12.875C13.0739 16.0625 13.2647 15.9835 13.4053 15.8428C13.546 15.7022 13.625 15.5114 13.625 15.3125C13.625 15.1136 13.546 14.9228 13.4053 14.7822C13.2647 14.6415 13.0739 14.5625 12.875 14.5625H11.555C11.2969 14.5624 11.0436 14.632 10.8218 14.7641C10.6 14.8961 10.418 15.0856 10.295 15.3125L8.09 19.46C8.04 19.5715 8.00956 19.6907 8 19.8125V24.3125C8 24.6109 8.11853 24.897 8.3295 25.108C8.54048 25.319 8.82663 25.4375 9.125 25.4375H24.875C25.1734 25.4375 25.4595 25.319 25.6705 25.108C25.8815 24.897 26 24.6109 26 24.3125V19.8125C25.9945 19.6901 25.9639 19.5701 25.91 19.46Z"
                fill="inherit"
              />
              <path
                d="M14.4876 13.5875L16.5501 16.34C16.6037 16.4086 16.6718 16.4644 16.7497 16.5033C16.8275 16.5423 16.9131 16.5633 17.0001 16.565C17.0872 16.5639 17.173 16.543 17.2509 16.5041C17.3288 16.4651 17.3969 16.409 17.4501 16.34L19.5126 13.5875C19.5759 13.5035 19.6143 13.4035 19.6236 13.2988C19.6329 13.194 19.6126 13.0888 19.5651 12.995C19.5187 12.9017 19.4469 12.8235 19.358 12.7691C19.2692 12.7147 19.1668 12.6864 19.0626 12.6875H18.3126C18.2629 12.6875 18.2152 12.6677 18.18 12.6326C18.1449 12.5974 18.1251 12.5497 18.1251 12.5V9.6875C18.1251 9.38913 18.0066 9.10298 17.7956 8.892C17.5846 8.68103 17.2985 8.5625 17.0001 8.5625C16.7017 8.5625 16.4156 8.68103 16.2046 8.892C15.9936 9.10298 15.8751 9.38913 15.8751 9.6875V12.5C15.8751 12.5497 15.8554 12.5974 15.8202 12.6326C15.785 12.6677 15.7373 12.6875 15.6876 12.6875H14.9376C14.8338 12.6881 14.7321 12.7171 14.6436 12.7713C14.555 12.8255 14.4829 12.9028 14.4351 12.995C14.3893 13.0892 14.37 13.1942 14.3792 13.2985C14.3885 13.4029 14.4259 13.5028 14.4876 13.5875Z"
                fill="inherit"
              />
            </IconButtonImage>
          </StyledIconButton>
          {!onLiteTier && currentSpaceObj && (
            <StyledIconButton
              data-test="photo-viewer-add-to-album"
              onClick={() => setShowAlbumModal(true)}
            >
              <IconButtonImage
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.1125 11C23.0278 10.9827 22.9517 10.9367 22.897 10.8697C22.8423 10.8027 22.8125 10.7189 22.8125 10.6325V9.5C22.8125 9.10218 22.6545 8.72064 22.3732 8.43934C22.0919 8.15804 21.7103 8 21.3125 8H11.9375C11.3408 8 10.7685 8.23705 10.3465 8.65901C9.92455 9.08097 9.6875 9.65326 9.6875 10.25V23.75C9.6875 24.3467 9.92455 24.919 10.3465 25.341C10.7685 25.7629 11.3408 26 11.9375 26H22.8125C23.2103 26 23.5919 25.842 23.8732 25.5607C24.1545 25.2794 24.3125 24.8978 24.3125 24.5V12.5C24.3196 12.1491 24.2034 11.8068 23.9841 11.5327C23.7648 11.2586 23.4564 11.0701 23.1125 11ZM21.3125 10.8125C21.3125 10.8622 21.2927 10.9099 21.2576 10.9451C21.2224 10.9802 21.1747 11 21.125 11H11.9375C11.7386 11 11.5478 10.921 11.4072 10.7803C11.2665 10.6397 11.1875 10.4489 11.1875 10.25C11.1875 10.0511 11.2665 9.86032 11.4072 9.71967C11.5478 9.57902 11.7386 9.5 11.9375 9.5H21.125C21.1747 9.5 21.2224 9.51975 21.2576 9.55492C21.2927 9.59008 21.3125 9.63777 21.3125 9.6875V10.8125ZM16 15C16 14.4477 16.4477 14 17 14C17.5523 14 18 14.4477 18 15V17.5H20.5C21.0523 17.5 21.5 17.9477 21.5 18.5C21.5 19.0523 21.0523 19.5 20.5 19.5H18V22C18 22.5523 17.5523 23 17 23C16.4477 23 16 22.5523 16 22V19.5H13.5C12.9477 19.5 12.5 19.0523 12.5 18.5C12.5 17.9477 12.9477 17.5 13.5 17.5H16V15Z"
                  fill="inherit"
                />
              </IconButtonImage>
            </StyledIconButton>
          )}
        </TopBar>
        <PhotoDetailsImage
          assetDetail={assetDetail}
          isDesktop={isDesktop}
          downloadPic={downloadPic}
          goToNextPhoto={goToNextPhoto}
          goToPreviousPhoto={goToPreviousPhoto}
        />
        <BottomBar>
          <StyledButton
            data-test="photo-viewer-previous-photo"
            onClick={goToPreviousPhoto}
            disabled={!hasPreviousPhoto}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 12H4"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                d="M12 20L4 12L12 4"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
            </svg>
            <span>Previous Photo</span>
          </StyledButton>
          <FlexSpacer />
          <StyledButton
            data-test="photo-viewer-next-photo"
            onClick={goToNextPhoto}
          >
            <span>Next Photo</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 12H20"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                d="M12 20L20 12L12 4"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
            </svg>
          </StyledButton>
        </BottomBar>
      </PhotoViewerContainer>
    </Container>
  );
};

export { PhotoViewer };
