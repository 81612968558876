import { grpc } from '@improbable-eng/grpc-web';
import { AlbumClient } from '../../services';
import { AuthStatus } from '../Auth/types';
import { SearchFilter, SortDirection } from '../QueryParams/types';
import {
  currentDate,
  generateDateTypes,
} from '../../screens/Photos/shared/filters/formatDateFilters';
import {
  AddAssetsToAlbumRequest,
  AddAssetsToAlbumResponse,
  CreateAlbumRequest,
  CreateAlbumResponse,
  CreateShareTokenRequest,
  CreateShareTokenResponse,
  DeleteAlbumRequest,
  DeleteAlbumResponse,
  DeleteShareTokenRequest,
  DeleteShareTokenResponse,
  GetAlbumRequest,
  GetAlbumResponse,
  ListAlbumAssetsRequest,
  ListAlbumAssetsResponse,
  ListAlbumsRequest,
  ListAlbumsResponse,
  RemoveAssetsFromAlbumRequest,
  RemoveAssetsFromAlbumResponse,
  SetAlbumNameRequest,
  SetAlbumNameResponse,
  CreateSavedViewRequest,
  CreateSavedViewResponse,
  ListSavedViewsRequest,
  ListSavedViewsResponse,
  DeleteSavedViewRequest,
  DeleteSavedViewResponse,
  CreateSmartAlbumRequest,
  CreateSmartAlbumResponse,
  GetSmartAlbumRequest,
  GetSmartAlbumResponse,
  ListSmartAlbumAssetsResponse,
  ListSmartAlbumAssetsRequest,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_api_pb';
import {
  CountPerAlbumType,
  DateType,
  DateTypeMap,
  Pagination,
  SortCriteria,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import {
  Filter,
  Operator,
  SearchCriteria,
  SortClause,
  SortCriteria as PhotoSortCriteria,
  SortOrder,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import {
  AssetDetailConfig,
  CardConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

export interface AlbumsApi {
  listAlbums: (options: {
    nameMatchValue?: string;
    pagination?: Pagination.AsObject;
  }) => Promise<ListAlbumsResponse>;

  createAlbum: (
    albumName: string,
    selectedPhotos: string[],
    spaceId: string
  ) => Promise<CreateAlbumResponse>;

  addAssetsToAlbum: (
    albumId: string,
    selectedPhotos: string[]
  ) => Promise<AddAssetsToAlbumResponse>;

  getAlbum: (params: {
    albumId?: string;
    shareToken?: string;
  }) => Promise<GetAlbumResponse>;

  getSmartAlbum: (params: {
    albumId?: string;
    shareToken?: string;
  }) => Promise<GetSmartAlbumResponse>;

  listAlbumAssets: (params: {
    id: string;
    from?: number;
    size?: number;
    shareToken?: string;
  }) => Promise<ListAlbumAssetsResponse>;

  listSmartAlbumAssets: (params: {
    id: string;
    from?: number;
    size?: number;
    shareToken?: string;
  }) => Promise<ListSmartAlbumAssetsResponse>;

  setAlbumName: (
    albumId: string,
    albumName: string
  ) => Promise<SetAlbumNameResponse>;

  deleteAlbum: (albumId: string) => Promise<DeleteAlbumResponse>;

  removeAssetsFromAlbum: (
    albumId: string,
    assetIdsList: string[]
  ) => Promise<RemoveAssetsFromAlbumResponse>;

  createShareToken: (
    albumId: string,
    assetDetailConfig: AssetDetailConfig,
    cardConfig: CardConfig
  ) => Promise<CreateShareTokenResponse>;

  deleteShareToken: (shareToken: string) => Promise<DeleteShareTokenResponse>;

  createSavedView: (
    name: string,
    spaceId: string,
    filters: Filter[],
    sortDirection: SortDirection,
    sortField: string,
    searchFilters: SearchFilter[]
  ) => Promise<CreateSavedViewResponse>;

  listSavedViews: (spaceId: string) => Promise<ListSavedViewsResponse>;

  deleteSavedView: (id: string) => Promise<DeleteSavedViewResponse>;

  createSmartAlbum: (
    name: string,
    spaceId: string,
    filters: Filter[],
    searchFilters: SearchFilter[]
  ) => Promise<CreateSmartAlbumResponse>;
}

const api = (
  metadata: grpc.Metadata | null,
  authStatus?: string,
  currentSpaceId?: string
): AlbumsApi => {
  const unAuthenticated = authStatus === AuthStatus.UNAUTHENTICATED;

  if (!metadata && !unAuthenticated) throw new Error('Auth Metadata missing');

  const listAlbums = ({
    nameMatchValue = '',
    pagination = { from: 0, size: 50 },
  }: {
    nameMatchValue?: string;
    pagination?: Pagination.AsObject;
  }) => {
    const request = new ListAlbumsRequest();
    currentSpaceId && request.setSpaceId(currentSpaceId);
    request.setNameMatchValue(nameMatchValue);

    const sortCriteria = new SortCriteria();
    request.setSortCriteria(sortCriteria);

    const countPerAlbumType = new CountPerAlbumType();
    pagination.countPerAlbumType &&
      countPerAlbumType.setSmart(pagination.countPerAlbumType.smart);
    pagination.countPerAlbumType &&
      countPerAlbumType.setStandard(pagination.countPerAlbumType.standard);

    const newPagination = new Pagination();
    newPagination.setFrom(pagination.from);
    newPagination.setSize(pagination.size);
    newPagination.setCountPerAlbumType(countPerAlbumType);
    request.setPagination(newPagination);

    return new Promise<ListAlbumsResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.listAlbums(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(new Error('No response from AlbumClient:listAlbums'));
        });
    });
  };

  const createAlbum = (
    albumName: string,
    selectedPhotos: string[],
    spaceId: string
  ) => {
    const request = new CreateAlbumRequest();
    request.setName(albumName);
    request.setAssetIdsList(selectedPhotos);
    request.setSpaceId(spaceId);

    return new Promise<CreateAlbumResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.createAlbum(request, metadata, (err, response) => {
          if (err) return reject(err);

          if (response) return resolve(response);

          return reject(new Error('No response from AlbumClient:createAlbum'));
        });
    });
  };

  const getAlbum = ({
    albumId,
    shareToken,
  }: {
    albumId?: string;
    shareToken?: string;
  }) => {
    const request = new GetAlbumRequest();
    albumId && request.setAlbumId(albumId);
    shareToken && request.setShareToken(shareToken);

    return new Promise<GetAlbumResponse>((resolve, reject) => {
      metadata
        ? AlbumClient.getAlbum(request, metadata, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(new Error('No response from AlbumClient:getAlbum'));
          })
        : AlbumClient.getAlbum(request, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(new Error('No response from AlbumClient:getAlbum'));
          });
    });
  };

  const getSmartAlbum = ({
    albumId,
    shareToken,
  }: {
    albumId?: string;
    shareToken?: string;
  }) => {
    const request = new GetSmartAlbumRequest();
    albumId && request.setAlbumId(albumId);
    shareToken && request.setShareToken(shareToken);

    return new Promise<GetSmartAlbumResponse>((resolve, reject) => {
      metadata
        ? AlbumClient.getSmartAlbum(request, metadata, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:getSmartAlbum')
            );
          })
        : AlbumClient.getSmartAlbum(request, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:getSmartAlbum')
            );
          });
    });
  };

  const addAssetsToAlbum = (albumId: string, selectedPhotos: string[]) => {
    const request = new AddAssetsToAlbumRequest();
    request.setAlbumId(albumId);
    request.setAssetIdsList(selectedPhotos);

    return new Promise<AddAssetsToAlbumResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.addAssetsToAlbum(request, metadata, (err, response) => {
          if (err) return reject(err);

          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:addAssetsToAlbum')
          );
        });
    });
  };

  const listAlbumAssets = ({
    id,
    from = 0,
    size = 50,
    shareToken,
  }: {
    id: string;
    from?: number;
    size?: number;
    shareToken?: string;
  }) => {
    const request = new ListAlbumAssetsRequest();
    request.setAlbumId(id);
    shareToken && request.setShareToken(shareToken);

    const pagination = new Pagination();
    pagination.setFrom(from);
    pagination.setSize(size);
    request.setPagination(pagination);

    return new Promise<ListAlbumAssetsResponse>((resolve, reject) => {
      metadata
        ? AlbumClient.listAlbumAssets(request, metadata, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:listAlbumAssets')
            );
          })
        : AlbumClient.listAlbumAssets(request, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:listAlbumAssets')
            );
          });
    });
  };

  const listSmartAlbumAssets = ({
    id,
    from = 0,
    size = 50,
    shareToken,
  }: {
    id: string;
    from?: number;
    size?: number;
    shareToken?: string;
  }) => {
    const request = new ListSmartAlbumAssetsRequest();
    request.setAlbumId(id);
    shareToken && request.setShareToken(shareToken);

    const pagination = new Pagination();
    pagination.setFrom(from);
    pagination.setSize(size);
    request.setPagination(pagination);

    return new Promise<ListSmartAlbumAssetsResponse>((resolve, reject) => {
      metadata
        ? AlbumClient.listSmartAlbumAssets(
            request,
            metadata,
            (err, response) => {
              if (err) return reject(err);
              if (response) return resolve(response);
              return reject(
                new Error('No response from AlbumClient:listSmartAlbumAssets')
              );
            }
          )
        : AlbumClient.listSmartAlbumAssets(request, (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:listSmartAlbumAssets')
            );
          });
    });
  };

  const setAlbumName = (albumId: string, albumName: string) => {
    const request = new SetAlbumNameRequest();
    request.setAlbumId(albumId);
    request.setName(albumName);

    return new Promise<SetAlbumNameResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.setAlbumName(request, metadata, (err, response) => {
          if (err) return reject(err);

          if (response) return resolve(response);

          return reject(new Error('No response from AlbumClient:setAlbumName'));
        });
    });
  };

  const deleteAlbum = (albumId: string) => {
    const request = new DeleteAlbumRequest();
    request.setAlbumId(albumId);

    return new Promise<DeleteAlbumResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.deleteAlbum(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(new Error('No response from AlbumClient:deleteAlbum'));
        });
    });
  };

  const removeAssetsFromAlbum = (albumId: string, assetIdsList: string[]) => {
    const request = new RemoveAssetsFromAlbumRequest();
    request.setAlbumId(albumId);
    request.setAssetIdsList(assetIdsList);

    return new Promise<RemoveAssetsFromAlbumResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.removeAssetsFromAlbum(
          request,
          metadata,
          (err, response) => {
            if (err) return reject(err);
            if (response) return resolve(response);
            return reject(
              new Error('No response from AlbumClient:removeAssetsFromAlbum')
            );
          }
        );
    });
  };

  const createShareToken = (
    albumId: string,
    assetDetailConfig: AssetDetailConfig,
    cardConfig: CardConfig
  ) => {
    const request = new CreateShareTokenRequest();
    request.setAlbumId(albumId);
    request.setAssetDetailConfig(assetDetailConfig);
    request.setCardConfig(cardConfig);

    return new Promise<CreateShareTokenResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.createShareToken(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:createShareToken')
          );
        });
    });
  };

  const deleteShareToken = (shareToken: string) => {
    const request = new DeleteShareTokenRequest();
    request.setShareToken(shareToken);

    return new Promise<DeleteShareTokenResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.deleteShareToken(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:deleteShareToken')
          );
        });
    });
  };

  const createSavedView = (
    name: string,
    spaceId: string,
    filters: Filter[],
    sortDirection: SortDirection,
    sortField: string,
    searchFilters: SearchFilter[]
  ) => {
    const request = new CreateSavedViewRequest();
    const searchCriteria = new SearchCriteria();
    searchCriteria.setFiltersList(filters);

    const sortClauseArray: Array<SortClause> = [];
    const [sortReferenceName, sortPropertyName] = sortField.split('.');

    if (sortField !== 'mission_responses.completed_at') {
      const primarySortClause = new SortClause();
      primarySortClause.setReferenceName(sortReferenceName);
      primarySortClause.setPropertyName(sortPropertyName);
      primarySortClause.setSortOrder(
        sortDirection === '+'
          ? SortOrder.SORT_ORDER_ASC
          : SortOrder.SORT_ORDER_DESC
      );
      sortClauseArray.push(primarySortClause);
    }

    const sortCriteria = new PhotoSortCriteria();
    sortCriteria.setSortClausesList(sortClauseArray);

    const dateFilters = searchFilters.filter(f =>
      f.propertyName?.includes('completed_at')
    );

    let dateType!: DateTypeMap[keyof DateTypeMap];
    let endDate!: SearchFilter;

    dateFilters.map(
      date => date.operator === Operator.OPERATOR_LTE && (endDate = date)
    );

    if (dateFilters.length === 0) {
      dateType = DateType.DATE_TYPE_ALL_TIME;
    } else if (dateFilters[0].label) {
      dateType = generateDateTypes(dateFilters[0].label);
    } else if (!dateFilters[0].label && endDate.value !== currentDate) {
      dateType = DateType.DATE_TYPE_CUSTOM_RANGE;
    }

    request.setName(name);
    request.setSpaceId(spaceId);
    request.setSearchCriteria(searchCriteria);
    request.setSortCriteria(sortCriteria);
    request.setDateType(dateType);

    return new Promise<CreateSavedViewResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.createSavedView(request, metadata, (err, response) => {
          if (err) return reject(err);

          if (response) return resolve(response);

          return reject(
            new Error('No response from AlbumClient:createSavedView')
          );
        });
    });
  };

  const listSavedViews = (spaceId: string) => {
    const request = new ListSavedViewsRequest();
    request.setSpaceId(spaceId);

    return new Promise<ListSavedViewsResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.listSavedViews(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:listSavedViews')
          );
        });
    });
  };

  const deleteSavedView = (id: string) => {
    const request = new DeleteSavedViewRequest();
    request.setSavedViewId(id);

    return new Promise<DeleteSavedViewResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.deleteSavedView(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:deleteSavedView')
          );
        });
    });
  };

  const createSmartAlbum = (
    name: string,
    spaceId: string,
    filters: Filter[],
    searchFilters: SearchFilter[]
  ) => {
    const request = new CreateSmartAlbumRequest();
    const searchCriteria = new SearchCriteria();
    searchCriteria.setFiltersList(filters);

    const dateFilters = searchFilters.filter(f =>
      f.propertyName?.includes('completed_at')
    );

    let dateType!: DateTypeMap[keyof DateTypeMap];
    let endDate!: SearchFilter;

    dateFilters.map(
      date => date.operator === Operator.OPERATOR_LTE && (endDate = date)
    );

    if (dateFilters.length === 0) {
      dateType = DateType.DATE_TYPE_ALL_TIME;
    } else if (dateFilters[0].label) {
      dateType = generateDateTypes(dateFilters[0].label);
    } else if (!dateFilters[0].label && endDate.value !== currentDate) {
      dateType = DateType.DATE_TYPE_CUSTOM_RANGE;
    }

    request.setName(name);
    request.setSpaceId(spaceId);
    request.setSearchCriteria(searchCriteria);
    request.setDateType(dateType);

    return new Promise<CreateSmartAlbumResponse>((resolve, reject) => {
      metadata &&
        AlbumClient.createSmartAlbum(request, metadata, (err, response) => {
          if (err) return reject(err);
          if (response) return resolve(response);
          return reject(
            new Error('No response from AlbumClient:createSmartAlbum')
          );
        });
    });
  };

  return {
    listAlbums,
    getAlbum,
    getSmartAlbum,
    listAlbumAssets,
    listSmartAlbumAssets,
    createAlbum,
    addAssetsToAlbum,
    setAlbumName,
    deleteAlbum,
    removeAssetsFromAlbum,
    createShareToken,
    deleteShareToken,
    createSavedView,
    listSavedViews,
    deleteSavedView,
    createSmartAlbum,
  };
};

export { api };
