import React, { FC } from 'react';
import { NavLink, Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '@gsc/components';

import { MenuLink, Sidebar } from '../../shared/components';
import { isMobileDevice, useWindowDimensions } from '../../shared/utils';
import { EditSpace, NewSpace, SpacesTable } from './screens';
import { paths } from '../../paths';

const MobileAdminLayout = styled.div`
  padding: 0 3.2rem;
`;

const DesktopAdminLayout = styled.div`
  display: flex;
`;

const MobileArticle = styled.article`
  flex: 1;
  padding: 13.2rem 0 8rem;
`;

const DesktopArticle = styled(MobileArticle)`
  padding: 8.8rem 6.4rem 3.2rem 26rem;
  width: 100%;

  ${breakpoint.lg`
    padding: 8.8rem 6.4rem 3.2rem 30.6rem;
  `}
`;

interface StyledProps {
  isDesktop: boolean | undefined;
}

const AdminLayout: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopAdminLayout>{children}</DesktopAdminLayout>
  ) : (
    <MobileAdminLayout>{children}</MobileAdminLayout>
  );

const StyledArticle: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopArticle>{children}</DesktopArticle>
  ) : (
    <MobileArticle>{children}</MobileArticle>
  );

const Admin: FC = () => {
  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  return (
    <AdminLayout isDesktop={isDesktop}>
      <Sidebar
        dataTest="admin-sidebar"
        title="Admin"
        links={[
          <MenuLink
            activeClassName="active"
            as={NavLink}
            key="spaces-nav-link"
            to={paths.admin.root}
          >
            Spaces
          </MenuLink>,
        ]}
      />

      <StyledArticle isDesktop={isDesktop}>
        <Switch>
          <Route exact path={paths.admin.root}>
            <SpacesTable />
          </Route>
          <Route exact path={paths.admin.newSpace}>
            <NewSpace />
          </Route>
          <Route exact path={paths.admin.editSpace()}>
            <EditSpace />
          </Route>
          <Redirect to={paths.admin.root} />
        </Switch>
      </StyledArticle>
    </AdminLayout>
  );
};

export { Admin };
