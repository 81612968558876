import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { color } from '@gsc/components';
const Link = styled(RouterLink)`
  color: ${color.mxDeepOcean};
  cursor: pointer;
  padding: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export { Link };
