import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Filter,
  Operator,
  SortOrder,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { SavedView } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import {
  DATE_PROPERTY_NAME,
  DATE_REFERENCE_NAME,
  SearchFilter,
  SortDirection,
} from './types';
import { selectFilters } from './selectors';
import { serialize } from './serializer';
import {
  isLast7Days,
  isLast30Days,
  isThisMonth,
  isThisYear,
  isEndOfLastMonth,
  isStartOfLastMonth,
  useEnsureIsArray,
} from '../../shared/utils';
import qs from 'query-string';
import moment from 'moment';

const serializedOperators: { [key: string]: 0 | 1 | 2 | 3 } = {
  eq: Operator.OPERATOR_EQUALS,
  lte: Operator.OPERATOR_LTE,
  gte: Operator.OPERATOR_GTE,
};

const isDatePropertyFilter = (filter: SearchFilter): boolean =>
  filter.propertyName === DATE_PROPERTY_NAME &&
  filter.referenceName === DATE_REFERENCE_NAME;

// eslint-disable-next-line complexity
const relativeDateValues = (labelCombo: string) => {
  const currentDate = moment()
    .endOf('day')
    .format('x');

  switch (labelCombo) {
    case 'gte@date-Last 7 days':
      return isLast7Days()
        .startOf('day')
        .format('x');

    case 'lte@date-Last 7 days':
      return currentDate;

    case 'gte@date-Last 30 days':
      return isLast30Days()
        .startOf('day')
        .format('x');

    case 'lte@date-Last 30 days':
      return currentDate;

    case 'gte@date-This month':
      return isThisMonth()
        .startOf('day')
        .format('x');

    case 'lte@date-This month':
      return currentDate;

    case 'gte@date-Last month':
      return isStartOfLastMonth()
        .startOf('day')
        .format('x');

    case 'lte@date-Last month':
      return isEndOfLastMonth()
        .endOf('day')
        .format('x');

    case 'gte@date-This year':
      return isThisYear()
        .startOf('day')
        .format('x');

    case 'lte@date-This year':
      return currentDate;

    default:
      return '';
  }
};

const serializeOperator = (operator: 0 | 1 | 2 | 3): string | undefined =>
  Object.keys(serializedOperators).find(
    k => serializedOperators[k] === operator
  );

const deserializeOperator = (input: string): 0 | 1 | 2 | 3 =>
  serializedOperators[input] ?? 0;

const serializeOrDate = (searchFilters: SearchFilter[]): string[] | undefined =>
  searchFilters.map(f => {
    if (isDatePropertyFilter(f) && f.label) {
      return [
        f.referenceName,
        f.propertyName,
        serializeOperator(f.operator),
        f.label,
      ].join('.');
    } else if (isDatePropertyFilter(f) && !f.label) {
      return [
        f.referenceName,
        f.propertyName,
        serializeOperator(f.operator),
        f.value,
      ].join('.');
    } else {
      return [
        f.referenceName,
        f.propertyName,
        serializeOperator(f.operator),
        f.value,
      ].join('.');
    }
  });

const serializeFilters = (
  searchFilters: SearchFilter[]
): string[] | undefined =>
  searchFilters.length > 0 ? serializeOrDate(searchFilters) : undefined;

const deserializeFilters = (filtersString?: string[]): SearchFilter[] =>
  filtersString?.map(
    (filter: string): SearchFilter => {
      let referenceName;
      let propertyName;
      let operatorAsString;
      let label = '';
      let valueArray;

      if (filter.includes('@date-')) {
        [
          referenceName,
          propertyName,
          operatorAsString,
          label,
          ...valueArray
        ] = filter.split('.');
      } else {
        [
          referenceName,
          propertyName,
          operatorAsString,
          ...valueArray
        ] = filter.split('.');
      }
      const operator = deserializeOperator(operatorAsString);
      const value =
        valueArray.length === 0
          ? label && relativeDateValues(operatorAsString + label)
          : valueArray.join('.');

      if (label) {
        return {
          referenceName,
          propertyName,
          operator,
          label,
          value,
        };
      } else {
        return {
          referenceName,
          propertyName,
          operator,
          value,
        };
      }
    }
  ) ?? [];

const coalesce = (filterString: string[] | undefined): Filter[] => {
  const { dateFilters, numericRangeFilters, customFilters } = selectFilters(
    deserializeFilters(filterString)
  );

  const custom = customFilters
    .reduce((acc: Filter.AsObject[], filter) => {
      const { value, ...rest } = filter;
      const existingFilterIndex = acc.findIndex(
        f =>
          f.referenceName === filter.referenceName &&
          f.propertyName === filter.propertyName
      );

      if (existingFilterIndex > -1) {
        acc[existingFilterIndex].valuesList.push(value);
      } else {
        acc.push({ ...rest, valuesList: [value] });
      }

      return acc;
    }, [])
    .map(serialize);

  const numericRange = numericRangeFilters
    .map(({ value, ...rest }) => ({
      ...rest,
      valuesList: [value],
    }))
    .map(serialize);

  const dates = dateFilters
    .map(({ value, ...rest }) => ({
      ...rest,
      valuesList: [value],
    }))
    .map(serialize);

  return [...dates, ...numericRange, ...custom];
};

export const stringifyFilters = (item: SavedView.AsObject) => {
  const filtersString = item.searchCriteria?.filtersList
    .map(filter => {
      return filter.valuesList.map(value => {
        return [
          filter.referenceName,
          filter.propertyName,
          serializeOperator(filter.operator),
          value,
        ].join('.');
      });
    })
    .map(filter => qs.stringify({ f: filter }))
    .join('&');

  const sortFilter = () => {
    const sortObj = item.sortCriteria?.sortClausesList[0];
    if (sortObj) {
      if (sortObj?.sortOrder === SortOrder.SORT_ORDER_ASC) {
        return qs.stringify({
          s: `+${sortObj.referenceName}.${sortObj.propertyName}`,
        });
      } else {
        return qs.stringify({
          s: `${sortObj.referenceName}.${sortObj.propertyName}`,
        });
      }
    } else return null;
  };

  return sortFilter() ? `${filtersString}&${sortFilter()}` : filtersString;
};

const defaultSortDirection: SortDirection = '-';
const defaultSortField = 'mission_responses.completed_at';

export enum PhotoDetailsPanels {
  COMPLIANCE_SUMMARY = 'COMPLIANCE_SUMMARY_PANEL',
  RECOMMENDED_ACTIONS = 'RECOMMENDED_ACTIONS_PANEL',
  PRODUCT_TAGS = 'PRODUCT_TAGS_PANEL',
  PHOTO_DETAILS = 'PHOTO_DETAILS_PANEL',
}
const defaultPhotoDetailsPanel = PhotoDetailsPanels.PHOTO_DETAILS;

interface UseQueryParams {
  currentPhotoId?: string;
  photoDetailsPanel?: string;
  selectedTagId?: string;
  filtersString: string[] | undefined;
  getFilters: () => Filter[];
  searchFilters: SearchFilter[];
  setSearchFilters: (filters: SearchFilter[]) => void;
  selectAlbumId?: string;
  setShowAlbumModal: (show: boolean, replace?: boolean) => void;
  setShowDeleteAlbumModal: (show: boolean, replace?: boolean) => void;
  setShowEditAlbum: (show: boolean, replace?: boolean) => void;
  setShowExport: (show: boolean, replace?: boolean) => void;
  setShowShareAlbumModal: (show: boolean, replace?: boolean) => void;
  setShowPhoto: (id?: string, photoDetailsPanel?: string) => void;
  setShowSelectedAlbum: (id?: string) => void;
  setSignIn: (show: boolean, replace?: boolean) => void;
  setSortDirection: (sortDirection: SortDirection) => void;
  setSortField: (sortField: string) => void;
  setSortFieldAndDirection: (
    sortField: string,
    sortDirection: SortDirection
  ) => void;
  setPhotoDetailsPanel: (name: string) => void;
  setSelectedTagId: (name: string | undefined) => void;
  setShowSavedViewModal: (show: boolean, replace?: boolean) => void;
  setShowSmartAlbumModal: (show: boolean, replace?: boolean) => void;
  setShowAlbumType: (id?: string) => void;
  showSignIn: boolean;
  showAlbumModal: boolean;
  showDeleteAlbumModal: boolean;
  showEditAlbum: boolean;
  showExport: boolean;
  showShareAlbumModal: boolean;
  sortDirection: SortDirection;
  sortField: string;
  showSavedViewModal: boolean;
  showSmartAlbumModal: boolean;
  showAlbumType?: string;
  planogramUuid?: string;
  inApp: boolean;
}

function parseSortParam(sortParam: string): [string, SortDirection] {
  const directionMatcher = /^([-+])/;
  const sortDirection = (sortParam.match(directionMatcher)?.[0] ??
    defaultSortDirection) as SortDirection;
  const sortField = sortParam.replace(directionMatcher, '') || defaultSortField;

  return [sortField, sortDirection];
}

function serializeSortParam(
  sortField: string,
  sortDirection: SortDirection
): string | undefined {
  const serializedSortField = sortField === defaultSortField ? '' : sortField;
  const serializedSortDirection =
    sortDirection === defaultSortDirection ? '' : sortDirection;

  return `${serializedSortDirection}${serializedSortField}` || undefined;
}

const useQueryParams = (): UseQueryParams => {
  const { search } = useLocation();
  const history = useHistory();
  const parsed = useMemo(() => qs.parse(search), [search]);

  // convert the old way.
  if (parsed.filters || parsed.sortField || parsed.sortDirection) {
    const oldStyleFilters = JSON.parse((parsed.filters as string) ?? '[]');
    const newStyleFilters = oldStyleFilters.map((f: any) =>
      [
        f.referenceName,
        f.propertyName,
        serializeOperator(f.operator),
        f.value,
      ].join('.')
    );

    const oldStyleSortField = parsed.sortField as string;
    const oldStyleSortDirection = parsed.sortDirection as string;
    const newStyleSortParam = serializeSortParam(
      oldStyleSortField || defaultSortField,
      oldStyleSortDirection === 'ascending' ? '+' : '-'
    );

    history.replace({
      search: qs.stringify({
        f: newStyleFilters?.length ? newStyleFilters : undefined,
        s: newStyleSortParam,
      }),
    });
  }

  const filtersString = useEnsureIsArray<string>(parsed.f);
  const searchFilters: SearchFilter[] = useMemo(
    () => deserializeFilters(filtersString),
    [filtersString]
  );

  const [sortField, sortDirection] = useMemo(
    () => parseSortParam((parsed.s ?? '') as string),
    [parsed.s]
  );

  const mergeParams = useCallback(
    (newParams: { [param: string]: any }): string =>
      qs.stringify({
        f: filtersString?.length ? filtersString : undefined,
        s: serializeSortParam(sortField, sortDirection),
        albumType: parsed.albumType,
        currentPhoto: parsed.currentPhoto,
        photoDetailsPanel: parsed.photoDetailsPanel,
        selectAlbum: parsed.selectAlbum,
        selectedTagId: parsed.selectedTagId,
        inApp: parsed.inApp,

        ...newParams,
      }),
    [
      filtersString,
      parsed.albumType,
      parsed.currentPhoto,
      parsed.photoDetailsPanel,
      parsed.selectAlbum,
      parsed.selectedTagId,
      parsed.inApp,
      sortDirection,
      sortField,
    ]
  );

  const setSearchFilters = useCallback(
    (searchFilters: SearchFilter[]) => {
      history.push({
        search: mergeParams({
          f: serializeFilters(searchFilters),
        }),
      });
    },
    [history, mergeParams]
  );

  const showExportString = parsed.export as string | undefined;
  const showExport = showExportString === 'true';

  const setShowExport = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ export: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showAlbumString = parsed.album as string | undefined;
  const showAlbumModal = showAlbumString === 'true';

  const setShowAlbumModal = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ album: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showDeleteAlbumString = parsed.deleteAlbum as string | undefined;
  const showDeleteAlbumModal = showDeleteAlbumString === 'true';

  const setShowDeleteAlbumModal = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ deleteAlbum: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const currentPhotoId = parsed.currentPhoto as string | undefined;

  const setShowPhoto = useCallback(
    (id?: string, photoDetailsPanel?: string) => {
      history.push({
        search: mergeParams({
          currentPhoto: id ? id : undefined,
          photoDetailsPanel: photoDetailsPanel,
        }),
      });
    },
    [history, mergeParams]
  );

  const inApp = parsed.inApp === 'true';

  const photoDetailsPanel = (parsed.photoDetailsPanel ??
    defaultPhotoDetailsPanel) as string;

  const setPhotoDetailsPanel = useCallback(
    (name: string) => {
      history.replace({
        search: mergeParams({ photoDetailsPanel: name }),
      });
    },
    [history, mergeParams]
  );

  const selectedTagId = parsed.selectedTagId as string | undefined;

  const setSelectedTagId = useCallback(
    (name: string | undefined) => {
      history.replace({
        search: mergeParams({ selectedTagId: name }),
      });
    },
    [history, mergeParams]
  );

  const getFilters = useCallback(() => coalesce(filtersString), [
    filtersString,
  ]);

  const setSortField = useCallback(
    newField => {
      history.push({
        search: mergeParams({
          s: serializeSortParam(newField, sortDirection),
        }),
      });
    },
    [history, mergeParams, sortDirection]
  );

  const setSortDirection = useCallback(
    newDirection => {
      history.push({
        search: mergeParams({
          s: serializeSortParam(sortField, newDirection),
        }),
      });
    },
    [history, mergeParams, sortField]
  );

  const setSortFieldAndDirection = useCallback(
    (newField, newDirection) => {
      history.push({
        search: mergeParams({
          s: serializeSortParam(newField, newDirection),
        }),
      });
    },
    [history, mergeParams]
  );

  const selectAlbumId = parsed.selectAlbum as string | undefined;

  const setShowSelectedAlbum = useCallback(
    (id?: string) => {
      history.push({
        search: mergeParams({ selectAlbum: id ? id : undefined }),
      });
    },
    [history, mergeParams]
  );

  const showEditAlbumString = parsed.editAlbum as string | undefined;
  const showEditAlbum = showEditAlbumString === 'true';

  const setShowEditAlbum = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ editAlbum: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showShareAlbumString = parsed.shareAlbum as string | undefined;
  const showShareAlbumModal = showShareAlbumString === 'true';

  const setShowShareAlbumModal = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ shareAlbum: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const signInString = parsed.signIn as string | undefined;
  const showSignIn = signInString === 'true';

  const setSignIn = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ signIn: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showSavedViewString = parsed.saveView as string | undefined;
  const showSavedViewModal = showSavedViewString === 'true';

  const setShowSavedViewModal = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ saveView: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showSmartAlbumString = parsed.saveSmartAlbum as string | undefined;
  const showSmartAlbumModal = showSmartAlbumString === 'true';

  const setShowSmartAlbumModal = useCallback(
    (show: boolean, replace = false) => {
      const search = mergeParams({ saveSmartAlbum: show ? show : undefined });

      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, mergeParams]
  );

  const showAlbumType = parsed.albumType as string;

  const setShowAlbumType = useCallback(
    (type?: string) => {
      history.push({
        search: mergeParams({ albumType: type ? type : undefined }),
      });
    },
    [history, mergeParams]
  );

  const planogramUuid = parsed.planogramUuid as string | undefined;

  return {
    selectAlbumId,
    showSignIn,
    currentPhotoId,
    photoDetailsPanel,
    selectedTagId,
    filtersString,
    getFilters,
    searchFilters,
    setSearchFilters,
    setShowAlbumModal,
    setShowDeleteAlbumModal,
    setShowSelectedAlbum,
    setShowEditAlbum,
    setShowExport,
    setShowPhoto,
    setSignIn,
    setSortDirection,
    setSortField,
    setSortFieldAndDirection,
    setShowShareAlbumModal,
    setPhotoDetailsPanel,
    setSelectedTagId,
    setShowSavedViewModal,
    setShowSmartAlbumModal,
    setShowAlbumType,
    showAlbumModal,
    showDeleteAlbumModal,
    showEditAlbum,
    showExport,
    showShareAlbumModal,
    sortDirection,
    sortField,
    showSavedViewModal,
    showSmartAlbumModal,
    showAlbumType,
    planogramUuid,
    inApp,
  };
};

export { useQueryParams };
