import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FixedSizeGrid } from 'react-window';
import { WindowScroller } from 'react-virtualized';
import { getColumnCount } from '../../../Photos/screens/PhotoGrid/utils';
import { chunkArray, useWindowDimensions } from '../../../../shared/utils';
import { AlbumGridCellFactory } from './cells';
import { useAlbumsContext } from '../../../../state/Albums';
import { AlbumFixedSizeGrid } from '../../components';
import { AlbumsSearchInput } from '../../SearchInput';
import { useCurrentSpaceObj } from '../../../../state/Spaces';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

interface AlbumsGridProps {
  setErrorNotification: (args: string) => any;
}

const tileAspectRatio = 1.15;

const AlbumsGrid: FC<AlbumsGridProps> = ({ setErrorNotification }) => {
  const [
    { getAlbumsList, getPage, pageSize, totalCount },
    { loadPage },
  ] = useAlbumsContext();
  const [renderedAlbumsList, setRenderedAlbumsList] = useState<
    (Album.AsObject | SmartAlbum.AsObject)[]
  >([]);

  const gridRef = useRef<FixedSizeGrid>(null);
  const history = useHistory();
  const { id: spaceId } = useCurrentSpaceObj();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const columnCount = getColumnCount(windowWidth);
  const width = Math.min(windowWidth, 1920) - 32;
  const height = windowHeight - 186;
  const columnWidth = Math.floor(width / columnCount);
  const rowHeight = columnWidth * tileAspectRatio;

  const onScroll = useCallback(({ scrollTop }) => {
    gridRef.current?.scrollTo({ scrollTop, scrollLeft: 0 });
  }, []);

  const albumCount = totalCount ?? pageSize;
  const rowCount = Math.ceil((albumCount + 1) / columnCount); // 1 extra for the create button

  const rows = useMemo(() => {
    const albums = getAlbumsList();
    if (
      albums.length !== renderedAlbumsList.length &&
      totalCount !== renderedAlbumsList.length
    ) {
      albums && setRenderedAlbumsList(albums);
    }

    return chunkArray(
      [
        { type: 'create', setErrorNotification: setErrorNotification },
        ...new Array(albumCount).fill(null).map((_n, index) => ({
          index,
          type: 'album',
          album: renderedAlbumsList[index],
          onClick: () =>
            history.push(
              `/spaces/${spaceId}/albums/${renderedAlbumsList[index]?.id}`
            ),
        })),
      ],
      columnCount
    );
  }, [
    albumCount,
    columnCount,
    getAlbumsList,
    history,
    renderedAlbumsList,
    setErrorNotification,
    spaceId,
    totalCount,
  ]);

  const itemData = useMemo(
    () => ({
      rows,
      getPage,
      loadPage,
      pageSize,
    }),
    [rows, getPage, pageSize, loadPage]
  );

  return (
    <>
      <AlbumsSearchInput setRenderedAlbumsList={setRenderedAlbumsList} />
      <WindowScroller onScroll={onScroll}>{() => <div />}</WindowScroller>

      <AlbumFixedSizeGrid
        ref={gridRef}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        columnCount={columnCount}
        height={height}
        rowCount={rowCount}
        width={width}
        itemData={itemData}
        overscanRowCount={3}
      >
        {AlbumGridCellFactory}
      </AlbumFixedSizeGrid>
    </>
  );
};

export { AlbumsGrid };
