import React, { CSSProperties, memo } from 'react';
import { areEqual } from 'react-window';
import { ItemData } from './types';
import { AlbumPageLoadStatus, useAlbumsAPI } from '../../../../state/Albums';
import { queuePageForLoading } from '../../../Photos/screens/PhotoGrid/utils';
import { PhotoCard } from '../../../Photos/shared/components/PhotoCard';

export interface AlbumPhotoCellProps {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: ItemData;
}

const AlbumPhotoCell = memo<AlbumPhotoCellProps>(
  ({ columnIndex, rowIndex, style, data }) => {
    const {
      albumId,
      cardConfig,
      columnCount,
      deleteMode,
      pageSize,
      shareToken,
      smartAlbum,
      getAlbum,
      getPageOfAssets,
      loadPageOfAssets,
      setResponseNotification,
      setShowPhoto,
      loadPageOfSmartAssets,
    } = data;
    const albumsAPI = useAlbumsAPI();
    if (typeof albumId !== 'string') return null;

    const dataIndex = columnCount * rowIndex + columnIndex;
    const pageNumber = Math.floor(dataIndex / pageSize);
    const page = getPageOfAssets(albumId, pageNumber);

    const handleDeleteAsset = (assetId: string) => {
      albumsAPI()
        .then(({ removeAssetsFromAlbum }) => {
          if (!albumId) {
            return;
          } else return removeAssetsFromAlbum(albumId, [assetId]);
        })
        .then(() => {
          loadPageOfAssets(albumId, pageNumber, shareToken);
          getAlbum(albumId);
        })
        .catch(() => {
          setResponseNotification(
            'We were unable to process your request. Please try again. If this error continues, please contact support.'
          );
        });
    };

    if (!page) {
      queuePageForLoading(pageNumber, () => {
        smartAlbum
          ? loadPageOfSmartAssets(albumId, pageNumber, shareToken)
          : loadPageOfAssets(albumId, pageNumber, shareToken);
      });
    } else if (page.status === AlbumPageLoadStatus.LOADED) {
      const photoId = page.ids[dataIndex % pageSize];
      if (!photoId) return null;

      const photo = data.getAsset(photoId);
      if (photo) {
        return (
          <PhotoCard
            deleteMode={deleteMode}
            handleChange={() => {
              handleDeleteAsset(photoId);
            }}
            id={photoId}
            loading={false}
            onClick={() => setShowPhoto(photoId)}
            cardConfig={cardConfig}
            photo={photo}
            selectable={deleteMode}
            style={style}
            url={photo.assetUrl}
          />
        );
      }
    }

    return <PhotoCard id="loading" style={style} loading />;
  },
  areEqual
);

AlbumPhotoCell.displayName = 'AlbumPhotoCell';

export { AlbumPhotoCell };
