import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useReducer,
} from 'react';

import { Actions } from './actions';
import { reducer } from './reducer';
import { useGetSpacesList, useSetDefaultSpaceToRoute } from './effects';
import { State, SpacesStatus } from './types';

interface SpacesProviderProps {
  children: ReactNode;
}

const SpacesStateContext = createContext<State | undefined>(undefined);
const SpacesDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined
);

const initialState: State = {
  spaces: {},
  defaultSpace: '',
  status: SpacesStatus.INVALID,
};

const SpacesProvider: FC<SpacesProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useGetSpacesList(dispatch);
  useSetDefaultSpaceToRoute(state, dispatch);

  return (
    <SpacesStateContext.Provider value={state}>
      <SpacesDispatchContext.Provider value={dispatch}>
        {children}
      </SpacesDispatchContext.Provider>
    </SpacesStateContext.Provider>
  );
};

export { SpacesProvider, SpacesStateContext, SpacesDispatchContext };
