export const anchorPositioningHelper = (
  anchorElement: HTMLElement | null,
  element: HTMLElement | null
) => {
  const DROPDOWN_GAP = 2;
  if (anchorElement && element) {
    const anchorRect = anchorElement.getBoundingClientRect();

    element.style.width = `${anchorElement.clientWidth}px`;
    element.style.top = `${anchorRect.bottom + DROPDOWN_GAP}px`;
    element.style.left = `${anchorRect.left}px`;
  }
};
