import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {
  zoom as d3Zoom,
  ZoomBehavior,
  zoomIdentity,
  ZoomTransform,
} from 'd3-zoom';
import { select } from 'd3-selection';
import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useQueryParams } from '../../../../../../../state/QueryParams/hooks';
import { zoomToFitImage, zoomToPoints } from './zoom_functions';
import { isEmpty } from '../../../../../../../shared/utils';

const TagPolygon = styled.polygon`
  cursor: pointer;
  transition: opacity 0.2s;

  &.otherTagSelected {
    opacity: 0;

    &:hover {
      opacity: 0.5;
    }
  }

  g:hover > & {
    opacity: 0;

    &:hover:not(.otherTagSelected),
    &.selected {
      opacity: 1;
    }
  }
`;

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const tagColorsByType: { [typeName: string]: string } = {
  coldbox_product: '#3D94F0',
  coldbox_unrecognizable_product: '#EFB126',
  menu_product: '#3D94F0',
  menu_generic_alcohol: '#EFB126',
  menu_generic_product: '#848992',
  menu_known_product: '#3D94F0',
  menu_unknown_product: '#EFB126',
  rep_brand_assignment: '#FF00FF',
  tag_edit: '#FF00FF', //also for old rep_brand_assignment
  shelf_product: '#3D94F0',
  brand_family: '#3D94F0',
  wine_product: '#3D94F0',
  wine_unknown_product: '#EFB126',
};

const getColor = (
  rba: Record.AsObject,
  tagEdit: boolean,
  typeName: string
): string => {
  if (!isEmpty(rba)) {
    typeName = 'rep_brand_assignment';
  }

  if (tagEdit) {
    typeName = 'tag_edit';
  }

  return tagColorsByType[typeName] ?? '#848992';
};

const shouldTagBeRendered = (tag: Record.AsObject): boolean =>
  !parseInt(getProperty(tag, 'deleted_at')) &&
  getProperty(tag, 'type_name') !== 'menu_item' &&
  getProperty(tag, 'type_name') !== 'menu_unit';

interface ImageWithTagsProps {
  assetDetail?: AssetDetail.AsObject;
  isDesktop: boolean;
  onImageLoad?: () => void;
}

const ImageWithTags: FC<ImageWithTagsProps> = ({
  assetDetail,
  isDesktop,
  onImageLoad,
}) => {
  const { selectedTagId, setSelectedTagId } = useQueryParams();

  const svgRef = useRef<SVGSVGElement>(null);
  const zoom = useRef<ZoomBehavior<Element, unknown> | null>(null);
  const [transform, setTransform] = useState<ZoomTransform>(zoomIdentity);
  const [zoomInitialized, setZoomInitialized] = useState(false);

  const tagBoundingBoxes = useMemo(
    () =>
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_tags')
        ?.recordsList?.filter(shouldTagBeRendered)
        ?.map(tag => {
          const id = getProperty(tag, 'id');
          const typeName = getProperty(tag, 'type_name') ?? '';
          const box = JSON.parse(getProperty(tag, 'tag_bounding_box') ?? '{}');
          const rba = JSON.parse(
            getProperty(tag, 'rep_brand_assignments') ?? '{}'
          );
          const tagEdit: boolean = getProperty(tag, 'is_edit');

          const color = getColor(rba, tagEdit, typeName);

          return {
            id,
            box,
            color: color,
            points: [
              box.left,
              box.top,
              box.right,
              box.top,
              box.right,
              box.bottom,
              box.left,
              box.bottom,
            ].join(' '),
          };
        }) ?? [],
    [assetDetail]
  );

  const [imageWidth, imageHeight] = useMemo(
    () => [
      parseInt(tagBoundingBoxes[0]?.box?.display_width ?? '0'),
      parseInt(tagBoundingBoxes[0]?.box?.display_height ?? '0'),
    ],
    [tagBoundingBoxes]
  );

  useEffect(
    function initializeZoom() {
      zoom.current = d3Zoom()
        .scaleExtent([1 / 10, 2])
        .translateExtent([
          [-500, -500],
          [imageWidth + 500, imageHeight + 500],
        ])
        .on('zoom', e => {
          setTransform(e.transform);
        });

      const { width: viewportWidth = 0, height: viewportHeight = 0 } =
        svgRef.current?.getBoundingClientRect() ?? {};

      const initialTransform = zoomToFitImage({
        imageWidth,
        imageHeight,
        viewportWidth,
        viewportHeight,
      });

      select('[id="image-with-tags"]')
        .call(zoom.current as any)
        .call(zoom.current.transform as any, initialTransform);

      setZoomInitialized(true);
    },
    [imageHeight, imageWidth, isDesktop]
  );

  const [selectedTagBoundingBox, selectedTagBoundingBoxLoaded] = useMemo(
    () => [
      tagBoundingBoxes.find(({ id }) => id === selectedTagId),
      !!assetDetail,
    ],
    [assetDetail, selectedTagId, tagBoundingBoxes]
  );

  useEffect(
    function adjustZoomWhenSelectedTagChanges() {
      const { width: viewportWidth = 0, height: viewportHeight = 0 } =
        svgRef.current?.getBoundingClientRect() ?? {};

      // wait for SVG to load to prevent DOM exception
      if (!zoomInitialized) {
        return;
      }

      const zoomTransform = selectedTagBoundingBox
        ? zoomToPoints({
            points: selectedTagBoundingBox.points,
            viewportWidth,
            viewportHeight,
            offsetFromTop: isDesktop ? 72 : 0,
          })
        : zoomToFitImage({
            imageWidth,
            imageHeight,
            viewportWidth,
            viewportHeight,
          });

      (select('[id="image-with-tags"]') as any)
        .transition()
        .duration(800)
        .call(zoom.current?.transform as any, zoomTransform);
    },
    [
      zoomInitialized,
      imageHeight,
      imageWidth,
      isDesktop,
      selectedTagBoundingBox,
    ]
  );

  useEffect(
    function deselectTagIdWhenInvalid() {
      if (
        selectedTagId &&
        selectedTagBoundingBoxLoaded &&
        !selectedTagBoundingBox
      ) {
        setSelectedTagId(undefined);
      }
    },
    [
      selectedTagBoundingBox,
      selectedTagBoundingBoxLoaded,
      selectedTagId,
      setSelectedTagId,
    ]
  );

  useEffect(
    function clearSelectedTagOnEscapePressed() {
      const onKey = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setSelectedTagId(undefined);
        }
      };

      document.addEventListener('keydown', onKey);

      return () => {
        document.removeEventListener('keydown', onKey);
      };
    },
    [setSelectedTagId]
  );

  return (
    <svg
      ref={svgRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      style={{ position: 'absolute', opacity: zoomInitialized ? 1 : 0 }}
      role="zoom-area"
      id="image-with-tags"
    >
      <g transform={isNaN(transform?.k) ? undefined : transform.toString()}>
        <image
          href={assetDetail?.assetUrl}
          onLoad={onImageLoad}
          onClick={() => {
            setSelectedTagId(undefined);
          }}
        />

        <g>
          {tagBoundingBoxes.map(({ id, color, points }) => (
            <TagPolygon
              key={`${id}-${points}`}
              points={points}
              stroke={color}
              strokeWidth={3 / (isNaN(transform.k) ? 1 : transform.k)}
              fill={`${color}66`}
              className={classNames({
                selected: id === selectedTagId,
                otherTagSelected: selectedTagId && selectedTagId !== id,
              })}
              onClick={() => {
                setSelectedTagId(id);
              }}
            />
          ))}
        </g>
      </g>
    </svg>
  );
};

export { ImageWithTags };
