import React, { forwardRef } from 'react';
import { BaseSelect } from './select';
import { SelectItemProps, SelectItemValue } from '../select-item';

type SingleSelection = {
  multiple?: boolean;
  value?: SelectItemValue;
};

type SharedProps = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  'defaultValue' | 'onClick' | 'onChange'
> & {
  children?:
    | React.ReactElement<SelectItemProps>
    | React.ReactElement<SelectItemProps>[];
  contentRef?: React.ForwardedRef<HTMLDivElement>;
  className?: string;
  clearIcon?: React.ReactNode;
  contentClass?: string;
  dataTestid?: string;
  defaultOpen?: boolean;
  defaultValue?: SelectItemValue;
  error?: boolean;
  iconBefore?: React.ReactNode;
  name: string;
  open?: boolean;
  options?: SelectItemValue[];
  placeholder?: string;
  ref?: React.ForwardedRef<HTMLDivElement>;
  success?: boolean;
  triggerIcon?: React.ReactNode;
  value?: SelectItemValue;
  warning?: boolean;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => void;
  onClear?: () => void;
  onClose?: () => void;
  onChange?: (value?: SelectItemValue) => void;
};

export type SelectProps = SharedProps & SingleSelection;
// | (SharedProps<T> & MultipleSelection<T>);

const SelectInner = (
  props: SelectProps,
  ref?: React.ForwardedRef<HTMLDivElement>
) => {
  const Component = BaseSelect();

  return <Component {...props} ref={ref} />;
};

export const Select = forwardRef(SelectInner) as typeof SelectInner;
