import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.024px;
  color: var(--colors-charlestonGrey);
  user-select: none;
  cursor: pointer;

  &[data-disabled] {
    color: var(--colors-charlestonGrey50);
    pointer-events: none;
  }

  &:hover,
  &:focus-within {
    background-color: var(--colors-deepOcean8);
  }
`;

export const Indicator = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--colors-deepOcean);
`;
