import React, { FC } from 'react';
import styled from 'styled-components';

type ProductImageProps = {
  width: number;
  height: number;
  url?: string;
};

export const ProductImageContainer = styled.div<{
  width: number;
  height: number;
}>`
  width: ${p => p.width}rem;
  min-width: ${p => p.width}rem;
  height: ${p => p.height}rem;
  background-size: contain;
  background-color: #f2f2f3;
  border-radius: 0.4rem;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductImage: FC<ProductImageProps> = ({
  width,
  height,
  url,
}: ProductImageProps) => {
  return (
    <ProductImageContainer width={width} height={height}>
      {url ? (
        <StyledImage src={url} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_6161_118477)">
            <path
              d="M20.32 6.87109L15.59 11.6111V12.0011C15.59 12.9551 15.2124 13.8703 14.5397 14.5467C13.867 15.2231 12.9539 15.6058 12 15.6111H11.61L8.22998 19.0011H19.52C20.0504 19.0011 20.5591 18.7904 20.9342 18.4153C21.3093 18.0402 21.52 17.5315 21.52 17.0011V8.72109C21.5233 8.32906 21.4113 7.94469 21.198 7.61577C20.9846 7.28686 20.6793 7.0279 20.32 6.87109Z"
              fill="#BEBFC1"
            />
            <path
              d="M23.4 0.600523C23.3311 0.529766 23.2487 0.47353 23.1576 0.435133C23.0666 0.396735 22.9688 0.376953 22.87 0.376953C22.7712 0.376953 22.6734 0.396735 22.5824 0.435133C22.4914 0.47353 22.409 0.529766 22.34 0.600523L16.88 6.06052L15.75 3.79052C15.6662 3.62409 15.5375 3.4844 15.3784 3.38722C15.2194 3.29004 15.0364 3.23923 14.85 3.24052H9.15003C8.96367 3.23923 8.78065 3.29004 8.62162 3.38722C8.4626 3.4844 8.3339 3.62409 8.25003 3.79052L6.80003 6.70052H4.48003C3.9496 6.70052 3.44089 6.91124 3.06582 7.28631C2.69075 7.66138 2.48003 8.17009 2.48003 8.70052V16.9205C2.46922 17.3842 2.6199 17.8373 2.90631 18.2021C3.19271 18.567 3.59702 18.8209 4.05003 18.9205L0.600035 22.3405C0.529277 22.4095 0.473042 22.4919 0.434644 22.5829C0.396247 22.6739 0.376465 22.7717 0.376465 22.8705C0.376465 22.9693 0.396247 23.0671 0.434644 23.1581C0.473042 23.2492 0.529277 23.3316 0.600035 23.4005C0.668205 23.4721 0.750555 23.5286 0.841818 23.5665C0.933081 23.6043 1.03125 23.6228 1.13003 23.6205C1.22858 23.621 1.32622 23.6018 1.41723 23.564C1.50824 23.5262 1.59079 23.4706 1.66003 23.4005L23.4 1.66052C23.4708 1.59158 23.527 1.50916 23.5654 1.41814C23.6038 1.32711 23.6236 1.22932 23.6236 1.13052C23.6236 1.03173 23.6038 0.933937 23.5654 0.842911C23.527 0.751885 23.4708 0.669469 23.4 0.600523ZM8.39003 12.0005C8.39003 11.0431 8.77037 10.1249 9.44738 9.44787C10.1244 8.77086 11.0426 8.39052 12 8.39052C12.7117 8.39562 13.4066 8.60756 14 9.00052L9.00003 14.0005C8.60707 13.4071 8.39513 12.7122 8.39003 12.0005Z"
              fill="#BEBFC1"
            />
          </g>
          <defs>
            <clipPath id="clip0_6161_118477">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </ProductImageContainer>
  );
};

export { ProductImage };
