import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint, color, H3, Notification, zIndex } from '@gsc/components';
import classnames from 'classnames';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';
import { FormProvider } from '../../../../shared/components/FormContext';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { serialize, useCurrentSpaceObj } from '../../../../state/Spaces';
import { AlbumsProvider } from '../../../../state/Albums';
import { useExportsAPI } from '../../../../state/Exports';
import {
  ActionTypes,
  usePhotosDispatch,
  usePhotosState,
} from '../../../../state/Photos';
import { ExportSettingsForm, Preview } from './shared/components';
import {
  Format,
  ImageQuality,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import {
  AssetDetailConfig,
  ColumnConfig,
  ColumnType,
  TableConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useSortColumn } from '../PhotoGrid/utils';
import {
  Album,
  SmartAlbum,
  AlbumType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const ExportsTakeOver = styled.div`
  height: 0;
  visibility: hidden;
  width: 0;

  &.exports-open {
    background-color: ${color.ui01};
    height: calc(100% - 5.6rem);
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 5.6rem;
    visibility: visible;
    width: 100%;
    z-index: ${zIndex.hotel + 1};
  }

  body.takeover-active & {
    visibility: hidden;
  }
`;

const ExportsLayout = styled.div`
  body.exports-error & {
    position: fixed;
    top: 15.8rem;
    width: 100%;

    ${breakpoint.md`
      top: 13.8rem;
    `};

    ${breakpoint.lg`
      top: 11.8rem;
    `};
  }
`;

const StyledNotification = styled(Notification)`
  margin: 0.8rem 2.4rem;
  position: fixed;
  top: 5.7rem;
  width: calc(100% - 4.8rem);
  z-index: ${zIndex.india};
`;

const ExportsHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 2.4rem;

  &:after {
    background-color: ${color.ui15};
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    top: 7.2rem;
    width: 100%;
  }

  ${ExportsLayout}.desktop-layout & {
    padding-left: 6.4rem;
  }
`;

const HeaderText = styled(H3)`
  margin-bottom: 0;
`;

const ExportsBody = styled.div`
  padding: 2.4rem;

  ${ExportsLayout}.desktop-layout & {
    display: flex;
    padding: 0;
  }
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface ExportsSettingsProps {
  album?: AlbumType;
  albumType?: number;
  assetCount?: number;
  handleCancel(): any;
}

type MetadataValuesObj = { [x: string]: any };

const ExportsSettings: FC<ExportsSettingsProps> = ({
  album,
  albumType,
  assetCount,
  handleCancel,
}) => {
  const history = useHistory();
  const api = useExportsAPI();
  const dispatch = usePhotosDispatch();
  const { selectedPhotos } = usePhotosState();
  const { setShowExport, sortField, sortDirection } = useQueryParams();
  const [responseError, setResponseError] = useState<{ message: string }>({
    message: '',
  });
  const currentSpace = useCurrentSpaceObj();
  const tablesList = currentSpace.config?.assetDetailConfig?.tablesList || [];
  const sortAsType = useSortColumn()?.type ?? ColumnType.COLUMN_TYPE_DATETIME;
  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  useEffect(() => {
    if (album) {
      if ('assetCount' in album && album.assetCount === 0) {
        setShowExport(false, true);
      }
    } else if (selectedPhotos.length === 0) {
      setShowExport(false, true);
    }
  }, [album, selectedPhotos.length, setShowExport]);

  const metadataValues = () => {
    const metadataObj: { [key: string]: string } = {};
    tablesList?.map(table => {
      if (table.columnConfigsList.length > 0) {
        table.columnConfigsList.map(columnConfig => {
          const combinedKey = `metadata-${table.tableName}-${columnConfig.columnName}`;
          metadataObj[combinedKey] = 'false';
        });
      }
    });
    return metadataObj;
  };

  const addMetadata = metadataValues();

  const metadataValuesObj = (values: MetadataValuesObj) =>
    Object.keys(values).reduce<MetadataValuesObj>((result, key) => {
      const value = values[key];
      if (key.startsWith('metadata-') && value === 'true') result[key] = value;
      return result;
    }, {});

  const createAssetDetailConfig = (values: any) => {
    if (values.useMetadata === 'true') {
      const newMetaVals = metadataValuesObj(values);
      const copyTablesList = tablesList.reduce((result, table) => {
        const exportedColumns = table.columnConfigsList.filter(
          columnConfig =>
            `metadata-${table.tableName}-${columnConfig.columnName}` in
            newMetaVals
        );
        if (exportedColumns.length) {
          result.push({
            ...table,
            columnConfigsList: exportedColumns,
          });
        }
        return result;
      }, new Array<TableConfig.AsObject>());

      if (values['metadata-mission_responses-completed_at'] === 'true') {
        const createColumnConfig = () => {
          const newColumnConfig = new ColumnConfig();
          const newColumnConfigsList: ColumnConfig[] = [];
          newColumnConfig.setColumnName('completed_at');
          newColumnConfigsList.push(newColumnConfig);
          return newColumnConfigsList;
        };

        const newTableConfig = new TableConfig();
        newTableConfig.setTableName('mission_responses');
        newTableConfig.setColumnConfigsList(createColumnConfig());
        copyTablesList.unshift(newTableConfig.toObject());
      }

      const newAssetDetailConfig = new AssetDetailConfig();
      newAssetDetailConfig.setTablesList(
        copyTablesList.map((tableConfig: TableConfig.AsObject) =>
          serialize(tableConfig).toTableConfig()
        )
      );

      return serialize(newAssetDetailConfig.toObject()).toAssetDetailConfig();
    }
    return;
  };

  const handleExport = (values: any) => {
    const exportParams = {
      ...values,
      assetIdsList: selectedPhotos,
      format: parseInt(values.format),
      imageQuality: parseInt(values.imageQuality),
      photosPerPage: parseInt(values.photosPerPage),
      assetDetailSettings: createAssetDetailConfig(values),
      includeLabels: values.includeLabels === 'true',
      timezone: moment.tz.guess(),
      sortField,
      sortDirection,
      sortAsType,
      albumType,
    };

    api()
      .then(({ createExport }) => createExport(exportParams))
      .then(() => {
        history.push('/exports');
        dispatch({
          type: ActionTypes.SetSelectedPhotos,
          payload: { ids: [] },
        });
      })
      .catch(() => {
        document.body.classList.add('exports-error');
        setResponseError({
          message:
            'We were unable to process your request. Please try again. If this error continues, please contact support.',
        });
      });
  };

  const validate = (values: any) => {
    const errors: any = {};
    const format = parseInt(values.format, 10);

    if (format !== Format.FORMAT_JPEG && values.title === '') {
      errors.title = 'Title is required.';
    }

    return errors;
  };

  const selectedPhotoCount =
    album && assetCount ? assetCount : selectedPhotos.length;

  const exportTitle = album
    ? album.name
    : `${selectedPhotoCount} ${pluralize('Photo', selectedPhotoCount)}`;

  const initialFormValues = {
    albumId: album?.id,
    format: Format.FORMAT_PPTX,
    imageQuality: ImageQuality.IMAGE_QUALITY_MAXIMUM,
    photosPerPage: '2',
    title: exportTitle,
    subtitle: `${moment().format('MMMM D, YYYY')}`,
    useMetadata: 'true',
    includeLabels: 'false',
    ['metadata-mission_responses-completed_at']: 'false',
  };

  const formValues = Object.assign(initialFormValues, addMetadata);

  return (
    <ExportsTakeOver className={classnames({ 'exports-open': true })}>
      {responseError.message && (
        <StyledNotification
          className="export-error"
          dataTest="create-export-notification"
          onClick={() => {
            document.body.classList.remove('exports-error');
            setResponseError({ message: '' });
          }}
          status="negative"
        >
          {responseError.message}
        </StyledNotification>
      )}

      <ExportsLayout className={classnames({ 'desktop-layout': isDesktop })}>
        <ExportsHeader>
          <HeaderText>
            Export {selectedPhotoCount} {pluralize('photo', selectedPhotoCount)}
          </HeaderText>
        </ExportsHeader>

        <ExportsBody>
          <FormProvider
            initialValues={formValues}
            onSubmit={handleExport}
            onValidate={validate}
          >
            <ExportSettingsForm handleCancel={handleCancel} />
            {isDesktop && (
              <AlbumsProvider spaceId={currentSpace.id}>
                <Preview />
              </AlbumsProvider>
            )}
          </FormProvider>
        </ExportsBody>
      </ExportsLayout>
    </ExportsTakeOver>
  );
};

export { ExportsSettings };
