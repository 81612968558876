import {
  AssetDetailConfig,
  ColumnConfig,
  TableConfig,
  AssetDetail,
  Property,
  Reference,
  Record,
  CardConfig,
  CardField,
  SpaceConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

type ValueObject =
  | AssetDetailConfig.AsObject
  | ColumnConfig.AsObject
  | TableConfig.AsObject
  | AssetDetail.AsObject
  | CardField.AsObject
  | CardConfig.AsObject
  | SpaceConfig.AsObject;

const toColumnConfig = (value: ColumnConfig.AsObject): ColumnConfig => {
  const columnConfig = new ColumnConfig();
  columnConfig.setColumnName(value.columnName);
  columnConfig.setType(value.type);
  columnConfig.setLabel(value.label);
  columnConfig.setCanFilter(value.canFilter);

  return columnConfig;
};

const toTableConfig = (value: TableConfig.AsObject): TableConfig => {
  const tableConfig = new TableConfig();
  tableConfig.setTableName(value.tableName);
  const columnConfigList = value.columnConfigsList.map(valueItem =>
    toColumnConfig(valueItem)
  );
  tableConfig.setColumnConfigsList(columnConfigList);

  return tableConfig;
};

const toAssetDetailConfig = (
  value: AssetDetailConfig.AsObject
): AssetDetailConfig => {
  const assetDetailConfig = new AssetDetailConfig();
  const tablesList = value.tablesList.map(listItem => toTableConfig(listItem));
  assetDetailConfig.setTablesList(tablesList);

  return assetDetailConfig;
};

const toProperty = (value: Property.AsObject): Property => {
  const property = new Property();
  property.setName(value.name);
  property.setValue(value.value);
  return property;
};

const toRecord = (value: Record.AsObject): Record => {
  const propertiesList = value.propertiesList.map(toProperty);
  const record = new Record();
  record.setPropertiesList(propertiesList);
  return record;
};

const toReference = (value: Reference.AsObject): Reference => {
  const recordsList = value.recordsList.map(toRecord);
  const reference = new Reference();
  reference.setName(value.name);
  reference.setRecordsList(recordsList);
  return reference;
};

const toAssetDetail = (value: AssetDetail.AsObject): AssetDetail => {
  const propertiesList = value.propertiesList.map(toProperty);
  const referencesList = value.referencesList.map(toReference);

  const assetDetail = new AssetDetail();
  assetDetail.setId(value.id);
  assetDetail.setVersionId(value.versionId);
  assetDetail.setCustomerId(value.customerId);
  assetDetail.setAssetUrl(value.assetUrl);
  assetDetail.setCreatedAtTime(value.createdAtTime);
  assetDetail.setUpdatedAtTime(value.updatedAtTime);
  assetDetail.setPropertiesList(propertiesList);
  assetDetail.setReferencesList(referencesList);

  return assetDetail;
};

const toCardField = (value: CardField.AsObject): CardField => {
  const cardField = new CardField();
  cardField.setTableName(value.tableName);
  cardField.setColumnName(value.columnName);
  cardField.setRole(value.role);

  return cardField;
};

const toCardConfig = (value: CardConfig.AsObject): CardConfig => {
  const fieldsList = value.fieldsList.map(toCardField);

  const cardConfig = new CardConfig();
  cardConfig.setFieldsList(fieldsList);

  return cardConfig;
};

const toSpaceConfig = (value: SpaceConfig.AsObject): SpaceConfig => {
  const spaceConfig = new SpaceConfig();

  if (value.assetDetailConfig) {
    spaceConfig.setAssetDetailConfig(
      toAssetDetailConfig(value.assetDetailConfig)
    );
  }

  if (value.cardConfig) {
    spaceConfig.setCardConfig(toCardConfig(value.cardConfig));
  }

  return spaceConfig;
};

const serialize = (value: ValueObject) => ({
  toColumnConfig: () => toColumnConfig(value as ColumnConfig.AsObject),
  toTableConfig: () => toTableConfig(value as TableConfig.AsObject),
  toAssetDetailConfig: () =>
    toAssetDetailConfig(value as AssetDetailConfig.AsObject),
  toAssetDetail: () => toAssetDetail(value as AssetDetail.AsObject),
  toCardField: () => toCardField(value as CardField.AsObject),
  toCardConfig: () => toCardConfig(value as CardConfig.AsObject),
  toSpaceConfig: () => toSpaceConfig(value as SpaceConfig.AsObject),
});

export { serialize };
