import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import {
  PhotoDetailsPanels,
  useQueryParams,
} from '../../../../../../state/QueryParams/hooks';
import { CdnImageWrapper } from '../../../../shared/components/CdnImageWrapper';
import { ImageWithTags } from './ImageWithTags';
import { ImageWithZoom } from './ImageWithZoom';
import { ComplianceViewer } from './Compliance/ComplianceViewer';

interface ImageContainerProps extends HTMLAttributes<HTMLDivElement> {
  imageLoaded?: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
  background-color: #262b2f;
  color: #ffffff;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface PhotoDetailsImageProps {
  assetDetail: AssetDetail.AsObject | undefined;
  isDesktop: boolean;
  downloadPic: () => void;
  goToNextPhoto: () => void;
  goToPreviousPhoto: () => void;
}

const PhotoDetailsImage: FC<PhotoDetailsImageProps> = ({
  assetDetail,
  isDesktop,
  downloadPic,
  goToNextPhoto,
  goToPreviousPhoto,
}) => {
  const { photoDetailsPanel } = useQueryParams();
  const displayingTags = photoDetailsPanel === 'PRODUCT_TAGS_PANEL';
  const displayingCompliance =
    photoDetailsPanel === PhotoDetailsPanels.COMPLIANCE_SUMMARY ||
    photoDetailsPanel === PhotoDetailsPanels.RECOMMENDED_ACTIONS;

  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  }>();

  const onImageLoad = useCallback((args: any) => {
    if (args) {
      const target = args.target;
      target &&
        setImageDimensions({ width: target.width, height: target.height });
    }
  }, []);

  const formattedDate = useMemo(() => {
    const completedAtTime = assetDetail?.referencesList?.find(
      ({ name }) => name === 'mission_responses'
    )?.recordsList[0].propertiesList[0].value;

    return completedAtTime
      ? moment(parseInt(completedAtTime)).format('MMMM DD, YYYY h:mm A z')
      : '';
  }, [assetDetail]);

  return (
    <ImageContainer>
      {assetDetail?.assetUrl && (
        <CdnImageWrapper
          alt={`Photo taken on ${formattedDate}`}
          imageUrl={assetDetail?.assetUrl}
          onLoad={onImageLoad}
          style={{ opacity: 0, position: 'absolute' }}
        />
      )}

      {imageDimensions && (
        <>
          {!displayingTags && !displayingCompliance && (
            <ImageWithZoom
              imageUrl={assetDetail?.assetUrl}
              isDesktop
              width={imageDimensions.width}
              height={imageDimensions.height}
            />
          )}
          {displayingTags && (
            <ImageWithTags assetDetail={assetDetail} isDesktop />
          )}
          {displayingCompliance && (
            <ComplianceViewer
              assetDetail={assetDetail}
              downloadPic={downloadPic}
              isDesktop={isDesktop}
              goToNextPhoto={goToNextPhoto}
              goToPreviousPhoto={goToPreviousPhoto}
              hasPreviousPhoto={false}
              realogramDimensions={imageDimensions}
            />
          )}
        </>
      )}
    </ImageContainer>
  );
};

export { PhotoDetailsImage };
