import React, { FC } from 'react';
import styled from 'styled-components';
import { H1, H2, font, color } from '@gsc/components';
// For what ever reason, can't get TS to be happy with importing SVG's
// import noPhotosUrl from '../images/no-photos.svg';
const noPhotosUrl = require('../images/no-photos.svg').default as string;

const NoResultsLayout = styled.div`
  padding: 3.2rem;
  text-align: center;
`;

const StyledH1 = styled(H1)`
  color: ${color.ui50};
  font-weight: ${font.weight.medium};
  margin-bottom: 1.6rem;
`;

const StyledH2 = styled(H2)`
  color: ${color.ui50};
  margin-bottom: 6.4rem;
`;

const StyledImg = styled.img`
  max-width: 60rem;
`;

interface NoResultsProps {
  title?: string;
  message?: string;
}

const NoResults: FC<NoResultsProps> = props => {
  const {
    title = 'Oops! Looks like there aren’t any photos matching your search.',
    message = 'Try removing filters to find more photos.',
  } = props;

  return (
    <NoResultsLayout>
      <StyledH1>{title}</StyledH1>
      <StyledH2>{message}</StyledH2>
      <StyledImg src={noPhotosUrl} alt="No photos" />
    </NoResultsLayout>
  );
};

export { NoResults };
