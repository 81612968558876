import { Dispatch, useCallback, useContext } from 'react';
import { AlbumsDispatchContext, AlbumsStateContext } from './context';
import { useAuth, useMetadata } from '../Auth';
import { useCurrentSpace } from '../Spaces';
import { AlbumsApi, api } from './api';
import { useSelectors } from './selectors';
import { Actions, ActionTypes } from './actions';
import { AlbumsActions, State, StateWithSelectors } from './types';

const useAlbumsState = (): StateWithSelectors => {
  const state = useContext(AlbumsStateContext);
  if (!state)
    throw new Error('useAlbumsState must be used within a AlbumsProvider');

  return { ...state, ...useSelectors(state) };
};

const useAlbumsDispatch = (): Dispatch<Actions> => {
  const dispatch = useContext(AlbumsDispatchContext);
  if (!dispatch)
    throw new Error('useAlbumsDispatch must be used within a AlbumsProvider');

  return dispatch;
};

const useAlbumsAPI = () => {
  const getMetadata = useMetadata();
  const { status } = useAuth();
  const { id } = useCurrentSpace();
  return useCallback(() => {
    return getMetadata()
      .then(metadata => {
        return api(metadata, status, id);
      })
      .catch(() => {
        return api(null, status); // if unauthenticated allow API call for shared albums with token
      });
  }, [getMetadata, id, status]);
};

const useLoadPage = (
  state: State | StateWithSelectors,
  dispatch: Dispatch<Actions>,
  api: () => Promise<AlbumsApi>
) =>
  useCallback(
    pageNumber => {
      dispatch({
        type: ActionTypes.SetPageLoading,
        payload: { pageNumber },
      });

      return api()
        .then(({ listAlbums }) =>
          listAlbums({
            pagination: {
              from: pageNumber * state.pageSize,
              size: state.pageSize,
              countPerAlbumType: {
                smart: state.pagination?.countPerAlbumType?.smart ?? 0,
                standard: state.pagination?.countPerAlbumType?.standard ?? 0,
              },
            },
          })
        )
        .then(response => {
          dispatch({
            type: ActionTypes.PageLoaded,
            payload: { pageNumber, response },
          });
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.PageLoadFailed,
            payload: { pageNumber },
          });

          throw e;
        });
    },
    [api, dispatch, state.pageSize, state.pagination]
  );

const useGetAlbum = (
  state: State | StateWithSelectors,
  dispatch: Dispatch<Actions>,
  api: () => Promise<AlbumsApi>
) =>
  useCallback(
    (id?: string, shareToken?: string) => {
      if (id) {
        dispatch({
          type: ActionTypes.SetRecordLoading,
          payload: { id },
        });
      } else {
        dispatch({
          type: ActionTypes.SetRecordLoading,
          payload: { id },
        });
      }

      return api()
        .then(({ getAlbum }) => {
          return getAlbum({ albumId: id, shareToken });
        })
        .then(response => {
          const album = response.getAlbum();
          if (album) {
            dispatch({
              type: ActionTypes.RecordLoaded,
              payload: { id, record: album },
            });
          } else {
            return dispatch({
              type: ActionTypes.RecordLoadFailed,
              payload: { id },
            });
          }
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.RecordLoadFailed,
            payload: { id },
          });

          throw e;
        });
    },
    [api, dispatch]
  );

const useGetSmartAlbum = (
  state: State | StateWithSelectors,
  dispatch: Dispatch<Actions>,
  api: () => Promise<AlbumsApi>
) =>
  useCallback(
    (id?: string, shareToken?: string) => {
      if (id) {
        dispatch({
          type: ActionTypes.SetRecordLoading,
          payload: { id },
        });
      } else {
        dispatch({
          type: ActionTypes.SetRecordLoading,
          payload: { id },
        });
      }

      return api()
        .then(({ getSmartAlbum }) => {
          return getSmartAlbum({ albumId: id, shareToken });
        })
        .then(response => {
          const smartAlbum = response.getSmartAlbum();
          if (smartAlbum) {
            dispatch({
              type: ActionTypes.RecordLoaded,
              payload: { id, record: smartAlbum },
            });
          } else {
            return dispatch({
              type: ActionTypes.RecordLoadFailed,
              payload: { id },
            });
          }
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.RecordLoadFailed,
            payload: { id },
          });

          throw e;
        });
    },
    [api, dispatch]
  );

const useLoadPageOfAssets = (
  state: State | StateWithSelectors,
  dispatch: Dispatch<Actions>,
  api: () => Promise<AlbumsApi>
) =>
  useCallback(
    (albumId: string, pageNumber: number, shareToken?: string) => {
      dispatch({
        type: ActionTypes.SetAssetsPageLoading,
        payload: { albumId, pageNumber },
      });
      return api()
        .then(({ listAlbumAssets }) =>
          listAlbumAssets({
            id: albumId,
            from: pageNumber * state.pageSize,
            size: state.pageSize,
            shareToken: shareToken,
          })
        )
        .then(response => {
          dispatch({
            type: ActionTypes.AssetsPageLoaded,
            payload: {
              albumId,
              pageNumber,
              response,
            },
          });
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.AssetsPageLoadFailed,
            payload: { albumId, pageNumber },
          });

          throw e;
        });
    },
    [api, dispatch, state.pageSize]
  );

const useLoadPageOfSmartAssets = (
  state: State | StateWithSelectors,
  dispatch: Dispatch<Actions>,
  api: () => Promise<AlbumsApi>
) =>
  useCallback(
    (albumId: string, pageNumber: number, shareToken?: string) => {
      dispatch({
        type: ActionTypes.SetSmartAssetsPageLoading,
        payload: { albumId, pageNumber },
      });

      return api()
        .then(({ listSmartAlbumAssets }) =>
          listSmartAlbumAssets({
            id: albumId,
            from: pageNumber * state.pageSize,
            size: state.pageSize,
            shareToken: shareToken,
          })
        )
        .then(response => {
          dispatch({
            type: ActionTypes.SmartAssetsPageLoaded,
            payload: {
              albumId,
              pageNumber,
              response,
            },
          });
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.SmartAssetsPageLoadFailed,
            payload: { albumId, pageNumber },
          });

          throw e;
        });
    },
    [api, dispatch, state.pageSize]
  );

const useAlbumsActions = (): AlbumsActions => {
  const state = useAlbumsState();
  const dispatch = useAlbumsDispatch();
  const api = useAlbumsAPI();

  return {
    dispatch,
    loadPage: useLoadPage(state, dispatch, api),
    getAlbum: useGetAlbum(state, dispatch, api),
    getSmartAlbum: useGetSmartAlbum(state, dispatch, api),
    loadPageOfAssets: useLoadPageOfAssets(state, dispatch, api),
    loadPageOfSmartAssets: useLoadPageOfSmartAssets(state, dispatch, api),
  };
};

const useAlbumsContext = (): [StateWithSelectors, AlbumsActions] => [
  useAlbumsState(),
  useAlbumsActions(),
];

export {
  useAlbumsState,
  useAlbumsDispatch,
  useAlbumsContext,
  useAlbumsAPI,
  useAlbumsActions,
  useLoadPage,
  useGetAlbum,
  useGetSmartAlbum,
  useLoadPageOfAssets,
  useLoadPageOfSmartAssets,
};
