import { grpc } from '@improbable-eng/grpc-web';
import {
  AssetDetailSettings,
  TitlePageSettings,
  SortCriteria,
  SortClause,
  SortOrder,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import {
  CreateExportRequest,
  CreateExportResponse,
  DeleteExportResponse,
  DeleteExportRequest,
  GetDownloadURLRequest,
  GetDownloadURLResponse,
  ListExportsRequest,
  ListExportsResponse,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_api_pb';

import { ExportClient } from '../../services';
import { ColumnType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const api = (metadata: grpc.Metadata | null) => {
  if (!metadata) throw new Error('Auth Metadata missing');

  const createExport = (values: any): Promise<CreateExportResponse> => {
    const assetDetailSettings = new AssetDetailSettings();
    const titlePageSettings = new TitlePageSettings();
    if (values.useMetadata === 'true') {
      assetDetailSettings.setConfig(values.assetDetailSettings);
      assetDetailSettings.setIncludeLabels(values.includeLabels);
      assetDetailSettings.setTimezone(values.timezone);
    }

    titlePageSettings.setTitle(values.title);
    titlePageSettings.setSubtitle(values.subtitle);

    const createExportRequest = new CreateExportRequest();
    createExportRequest.setAssetIdsList(values.assetIdsList);
    createExportRequest.setFormat(values.format);
    createExportRequest.setImageQuality(values.imageQuality);
    createExportRequest.setPhotosPerPage(values.photosPerPage);
    createExportRequest.setTitlePageSettings(titlePageSettings);
    createExportRequest.setAssetDetailSettings(assetDetailSettings);
    createExportRequest.setAlbumId(values.albumId);
    createExportRequest.setAlbumType(values.albumType);

    const {
      sortField = 'mission_responses.completed_at',
      sortDirection = '-',
      sortAsType = ColumnType.COLUMN_TYPE_DATETIME,
    } = values;
    const sortClauseArray: Array<SortClause> = [];
    const primarySortClause = new SortClause();
    const [sortReferenceName, sortPropertyName] = sortField.split('.');
    primarySortClause.setReferenceName(sortReferenceName);
    primarySortClause.setPropertyName(sortPropertyName);
    primarySortClause.setSortAsType(sortAsType);
    primarySortClause.setSortOrder(
      sortDirection === '+'
        ? SortOrder.SORT_ORDER_ASC
        : SortOrder.SORT_ORDER_DESC
    );
    sortClauseArray.push(primarySortClause);
    if (sortField !== 'mission_responses.completed_at') {
      const secondarySortClause = new SortClause();
      secondarySortClause.setReferenceName('mission_responses');
      secondarySortClause.setPropertyName('completed_at');
      secondarySortClause.setSortAsType(ColumnType.COLUMN_TYPE_DATETIME);
      secondarySortClause.setSortOrder(SortOrder.SORT_ORDER_DESC);
      sortClauseArray.push(secondarySortClause);
    }
    const tertiarySortClause = new SortClause();
    tertiarySortClause.setReferenceName('');
    tertiarySortClause.setPropertyName('_id');
    tertiarySortClause.setSortOrder(SortOrder.SORT_ORDER_ASC);
    tertiarySortClause.setSortAsType(ColumnType.COLUMN_TYPE_TEXT);
    sortClauseArray.push(tertiarySortClause);

    const sortCriteria = new SortCriteria();
    sortCriteria.setSortClausesList(sortClauseArray);
    createExportRequest.setSortCriteria(sortCriteria);

    return new Promise((resolve, reject) => {
      ExportClient.createExport(
        createExportRequest,
        metadata,
        (error, response) => {
          if (error) return reject(error);
          if (!response) {
            return reject(new Error('No response from Exports:createExport'));
          }
          return resolve(response);
        }
      );
    });
  };

  const listExports = (): Promise<ListExportsResponse> => {
    const getExportListRequest = new ListExportsRequest();

    return new Promise((resolve, reject) => {
      ExportClient.listExports(
        getExportListRequest,
        metadata,
        (error, response) => {
          if (error) return reject(error);
          if (!response) {
            return reject(new Error('No response from Exports:listExports'));
          }
          return resolve(response);
        }
      );
    });
  };

  const getDownloadURL = (
    exportId: string
  ): Promise<GetDownloadURLResponse> => {
    const getDownloadURLRequest = new GetDownloadURLRequest();
    getDownloadURLRequest.setExportId(exportId);

    return new Promise((resolve, reject) => {
      ExportClient.getDownloadURL(
        getDownloadURLRequest,
        metadata,
        (error, response) => {
          if (error) return reject(error);
          if (!response) {
            return reject(new Error('No response from Exports:getDownloadURL'));
          }
          return resolve(response);
        }
      );
    });
  };

  const deleteExport = (exportId: string): Promise<DeleteExportResponse> => {
    const request = new DeleteExportRequest();
    request.setExportId(exportId);

    return new Promise((resolve, reject) => {
      ExportClient.deleteExport(request, metadata, (error, response) => {
        if (error) return reject(error);
        if (!response) {
          return reject(new Error('No response from Exports:deleteExport'));
        }
        return resolve(response);
      });
    });
  };

  return {
    createExport,
    getDownloadURL,
    listExports,
    deleteExport,
  };
};

export { api };
