import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useReducer,
} from 'react';
import { zoomIdentity } from 'd3-zoom';
import { reducer, State } from './reducer';
import { Actions } from './actions';
import { Results } from '../Compliance/wapi_planogram_api';

const ComplianceViewerStateContext = createContext<State | undefined>(
  undefined
);
const ComplianceViewerDispatchContext = createContext<
  Dispatch<Actions> | undefined
>(undefined);

interface ComplianceViewerProviderProps {
  kpiResult: Results | undefined;
  children: ReactNode;
  testState?: State;
}

const initialState: State = {
  realogram: {
    width: 0,
    height: 0,
    transform: zoomIdentity,
  },
  planogram: {
    width: 0,
    height: 0,
  },
};

const ComplianceViewerProvider: FC<ComplianceViewerProviderProps> = props => {
  const [state, dispatch] = useReducer(
    reducer,
    props.testState ?? initialState
  );
  return (
    <ComplianceViewerStateContext.Provider value={state}>
      <ComplianceViewerDispatchContext.Provider value={dispatch}>
        {props.children}
      </ComplianceViewerDispatchContext.Provider>
    </ComplianceViewerStateContext.Provider>
  );
};

export {
  ComplianceViewerStateContext,
  ComplianceViewerDispatchContext,
  ComplianceViewerProvider,
};
