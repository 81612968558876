import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { color, H5 } from '@gsc/components';
import {
  AssetDetail,
  ColumnConfig,
  Property,
  Reference,
  TableConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { Details, DetailsTitle } from './components';
import moment from 'moment-timezone';

const calendarUrl = require('@gsc/icons/svg/calendar-standard.svg')
  .default as string;
const clipboardUrl = require('@gsc/icons/svg/tasks.svg').default as string;
const personUrl = require('@gsc/icons/svg/user-standard.svg').default as string;
const placeUrl = require('@gsc/icons/svg/place-default.svg').default as string;

interface DetailHighlightProps extends HTMLAttributes<any> {
  icon: string;
}

const DetailHighlight = styled.div(
  ({ icon }: DetailHighlightProps) => `
  background: transparent url(${icon}) no-repeat left center/1.6rem;
  padding-left: 2.8rem;
`
);

const TextLoading = styled.div(
  ({ width, height }: { width?: string; height?: string }) => `
  background-color: ${color.ui10};
  border-radius: 99rem;
  height: ${height ? height : '1.6rem'};
  margin-bottom: 1.2rem;
  width: ${width ? width : '95%'};
  line-height: 3rem;
`
);

const ReferenceSection = styled.ul`
  margin-bottom: 4.8rem;
`;

const ListItem = styled.li`
  margin-top: 1.6rem;
`;

const ReferenceTitle = styled(H5)`
  margin-bottom: 1.6rem;
  text-transform: capitalize;
`;

const PropertyTitle = styled.div`
  color: ${color.ui50};
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
  text-transform: capitalize;
`;

const sortTableConfig = (a: TableConfig.AsObject, b: TableConfig.AsObject) => {
  const nameA = a.tableName.toUpperCase();
  const nameB = b.tableName.toUpperCase();

  if (nameA > nameB) {
    return 1;
  } else if (nameA < nameB) {
    return -1;
  }

  return 0;
};

const sortColumnConfigs = (
  a: ColumnConfig.AsObject,
  b: ColumnConfig.AsObject
) => {
  const nameA = (a.label && a.label.length > 0
    ? a.label
    : a.columnName
  ).toUpperCase();
  const nameB = (b.label && b.label.length > 0
    ? b.label
    : b.columnName
  ).toUpperCase();

  if (nameA > nameB) {
    return 1;
  } else if (nameA < nameB) {
    return -1;
  }

  return 0;
};

const shouldHideColumn = ({
  tableName,
  columnName,
}: {
  tableName: string;
  columnName: string;
}): boolean =>
  (tableName === 'missions' && columnName === 'name') ||
  (tableName === 'places' && columnName === 'name') ||
  (tableName === 'users' && columnName === 'full_name');

const shouldDisplayTableConfig = (
  tableConfig: TableConfig.AsObject
): boolean => {
  const { tableName, columnConfigsList } = tableConfig;

  if (tableName === 'image_recognition_tags') return false;

  const columnCount = columnConfigsList.length;
  if (columnCount === 1) {
    const { columnName } = columnConfigsList[0];

    if (shouldHideColumn({ tableName, columnName })) {
      return false;
    }
  }

  return columnCount > 0;
};

interface PhotoDetailsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
  loading: boolean | undefined;
}

// eslint-disable-next-line complexity
const PhotoDetailsPanel = ({
  assetDetail,
  loading,
}: PhotoDetailsPanelProps) => {
  // Info that we'll always show at the top
  const selectColumn = (column: string) =>
    assetDetail?.referencesList?.find(value => value.name === column);
  const formatTitle = (title: string) => title?.split('_').join(' ');

  const missionName = selectColumn(
    'missions'
  )?.recordsList[0]?.propertiesList.find(
    (value: Property.AsObject) => value.name === 'name'
  );

  const placeName = selectColumn('places')?.recordsList[0]?.propertiesList.find(
    (value: Property.AsObject) => value.name === 'name'
  );

  const username = selectColumn('users')?.recordsList[0]?.propertiesList.find(
    (value: Property.AsObject) => value.name === 'full_name'
  );

  const completedAtTime = assetDetail?.referencesList
    ?.find((item: Reference.AsObject) => item.name === 'mission_responses')
    ?.recordsList?.[0]?.propertiesList?.find(
      (item: Property.AsObject) => item.name === 'completed_at'
    )?.value;

  const currentSpace = useCurrentSpaceObj();
  const detailsList = assetDetail?.referencesList ?? [];
  const tablesList = currentSpace?.config?.assetDetailConfig?.tablesList || [];

  // TODO: Nate fix when types are correct
  const spaceConfigList = tablesList.map(table => table).sort(sortTableConfig);

  const findReferenceIndex = (referenceName: string) =>
    detailsList.findIndex(
      (reference: Reference.AsObject) => reference.name === referenceName
    );

  const findValue = (
    tableName: string,
    columnName: string,
    referenceIndex: number
  ) =>
    detailsList[referenceIndex]?.recordsList[0].propertiesList
      .filter(reference =>
        shouldHideColumn({ tableName, columnName })
          ? null
          : columnName === reference.name
      )
      .map((property: Property.AsObject) => property.value);
  // End: All things for filtering and sorting detail data based on the config

  return (
    <Details>
      <DetailsTitle>
        <span data-test="photo-details-title">Photo Details</span>
      </DetailsTitle>

      <ReferenceSection>
        <ListItem>
          <DetailHighlight data-test="detail-highlight-date" icon={calendarUrl}>
            {loading ? (
              <TextLoading width="70%" height="2rem" />
            ) : (
              assetDetail &&
              completedAtTime &&
              moment(parseInt(completedAtTime)).format('MMMM DD, YYYY h:mm A z')
            )}
          </DetailHighlight>
        </ListItem>

        <ListItem>
          <DetailHighlight
            data-test="detail-highlight-mission-name"
            icon={clipboardUrl}
          >
            {loading ? (
              <TextLoading width="65%" height="2rem" />
            ) : (
              missionName?.value
            )}
          </DetailHighlight>
        </ListItem>

        <ListItem>
          <DetailHighlight
            data-test="detail-highlight-place-name"
            icon={placeUrl}
          >
            {loading ? (
              <TextLoading width="40%" height="2rem" />
            ) : (
              placeName?.value
            )}
          </DetailHighlight>
        </ListItem>

        <ListItem>
          <DetailHighlight
            data-test="detail-highlight-full-name"
            icon={personUrl}
          >
            {loading ? (
              <TextLoading width="50%" height="2rem" />
            ) : (
              username?.value
            )}
          </DetailHighlight>
        </ListItem>
      </ReferenceSection>

      {currentSpace && // unauth'd user viewing a shared album cannot see space details
        spaceConfigList?.map(tableConfig => {
          if (!shouldDisplayTableConfig(tableConfig)) return null;
          return (
            <ReferenceSection key={tableConfig.tableName}>
              <ListItem>
                <ReferenceTitle>
                  {formatTitle(tableConfig.tableName)} Details
                </ReferenceTitle>
              </ListItem>

              {loading ? (
                <>
                  <TextLoading width="30%" />
                  <TextLoading width="65%" />
                  <TextLoading width="20%" />
                  <TextLoading width="50%" />
                </>
              ) : (
                <ul>
                  {tableConfig.columnConfigsList
                    .map(columnConfig => columnConfig)
                    .sort(sortColumnConfigs)
                    .map(property => {
                      if (
                        (tableConfig.tableName === 'missions' &&
                          property['columnName'] === 'name') ||
                        (tableConfig.tableName === 'places' &&
                          property['columnName'] === 'name') ||
                        (tableConfig.tableName === 'users' &&
                          property['columnName'] === 'full_name')
                      ) {
                        return null;
                      } else if (
                        findReferenceIndex(tableConfig.tableName) >= 0
                      ) {
                        return (
                          <ListItem
                            key={`${tableConfig.tableName}_${property['columnName']}`}
                          >
                            <PropertyTitle>
                              {property['label'] === ''
                                ? formatTitle(property['columnName'])
                                : property['label']}
                            </PropertyTitle>
                            {findValue(
                              tableConfig.tableName,
                              property['columnName'],
                              findReferenceIndex(tableConfig.tableName)
                            ).length > 0 ? (
                              <span
                                data-test={`${tableConfig.tableName}_${property['columnName']}_value`}
                              >
                                {
                                  findValue(
                                    tableConfig.tableName,
                                    property['columnName'],
                                    findReferenceIndex(tableConfig.tableName)
                                  )[0]
                                }
                              </span>
                            ) : (
                              <span
                                data-test={`${tableConfig.tableName}_${property['columnName']}_empty`}
                              >
                                &mdash;
                              </span>
                            )}
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                </ul>
              )}
            </ReferenceSection>
          );
        })}
    </Details>
  );
};

export { PhotoDetailsPanel };
