import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface CdnImageProps {
  imageUrl?: string;
  imgSrcOverride?: string;

  fullUrlOptions?: string;
  placeholderOptions?: string;

  width?: number;
  height?: number;

  alt: string;
  className?: string;
  onLoad?: (...args: any[]) => any;
  style?: CSSProperties;
}

export const SetCdnOptions = (url: string, urlOptions: string): string => {
  const [domain, query] = url.split('?');
  const transformedQuery =
    query === undefined ? `?${urlOptions}` : `?${query}&${urlOptions}`;

  return domain + transformedQuery;
};

const imagesLoaded: string[] = [];

export const CdnImage = ({
  alt,
  imageUrl,
  imgSrcOverride,
  fullUrlOptions = 'quality=50',
  placeholderOptions = 'quality=50',
  onLoad,
  ...otherProps
}: CdnImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [fullUrl, setFullUrl] = useState<string | undefined>(undefined);

  const placeholderUrl = useMemo(() => {
    if (imgSrcOverride) return imgSrcOverride;
    if (!imageUrl) return;
    if (imagesLoaded.indexOf(fullUrl ?? '') > -1) return fullUrl;
    return SetCdnOptions(imageUrl, placeholderOptions);
  }, [imageUrl, fullUrl, imgSrcOverride, placeholderOptions]);

  const [image, setImage] = useState(placeholderUrl);
  const [retries, setRetries] = useState(5);

  useEffect(() => {
    let url;
    if (imgSrcOverride) url = imgSrcOverride;
    else if (imageUrl) {
      url = SetCdnOptions(imageUrl, fullUrlOptions);
    }

    if (url) {
      setRetries(5);
      setFullUrl(url);
    }
  }, [imageUrl, imgSrcOverride, fullUrlOptions]);

  useEffect(() => {
    let unmounted = false;
    if (!fullUrl) return;

    const imageObj = new Image();
    imageObj.referrerPolicy = 'no-referrer';

    imageObj.onload = args => {
      imagesLoaded.push(imageObj.src);
      if (unmounted) return;
      setImage(imageObj.src);
      onLoad?.(args);
    };

    imageObj.src = fullUrl;

    return () => {
      imageObj.src = '';
      unmounted = true;
    };
  }, [fullUrl, onLoad, retries]);

  useEffect(
    () => () => {
      if (imgRef?.current?.src) {
        imgRef.current.src = '';
      }
    },
    []
  );

  return (
    <img
      ref={imgRef}
      alt={alt}
      src={image}
      referrerPolicy="no-referrer"
      {...otherProps}
    />
  );
};
