import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  elevation,
  OutsideClick,
  SecondaryButton,
  zIndex,
} from '@gsc/components';
import { isMobileDevice, useWindowDimensions } from '../../../shared/utils';
// For what ever reason, can't get TS to be happy with importing SVG's
// import chevronDownBoldIconUrl from '@gsc/icons/svg/chevron-down-bold.svg';

interface DropdownProps {
  alignRight?: boolean;
  columnName?: string;
  dataTest?: string;
  fetchInitialFilterPropertyValues?(tableName: string, columnName: string): any;
  isActive?: boolean;
  tableName?: string;
  title: string;
  options: DropdownItem[];
  onClick: (item: DropdownItem) => void;
  onOpen?: (...args: any[]) => any;
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const TriggerButton = styled(SecondaryButton).attrs({ status: 'neutral' })<
  DropdownProps
>(
  ({ isActive, isOpen }) => `
    border:0;
    border-bottom: ${
      isActive || isOpen
        ? border.normal(color.mxDeepOcean)
        : border.normal(color.ui15)
    };
    padding:.8em;
    height:2.4rem;
    color: ${color.ui50};

    svg {
      width:2.4rem;
      margin-left:1.6rem;
      margin-right:0;
    }
    ${
      isActive || isOpen
        ? `
      &:focus,
      &:focus:hover,
      &:not(.show-loader):hover {
      }
      `
        : ''
    } /* This style allows a chunk of CSS without generating a false: ''; attribute */
  `
);

const DropdownContainer = styled.div(
  ({ alignRight }: { alignRight: boolean }) => `
  ${elevation['12']}
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.mxDeepOcean)};
  margin-bottom: 1.6rem;
  margin-top: 0.4rem;
  position: absolute;
  top: 100%;
  left: ${alignRight ? 'auto' : 0};
  right: ${alignRight ? 0 : 'auto'};
  width: 100%;
  z-index: ${zIndex.hotel};
  overflow: hidden;

  ${breakpoint.md`
    & {
      min-width: 12rem;
      width: auto;
    }
  `}
`
);

const StyledListItem = styled.li<{ selected?: boolean }>`
  color: ${p => (p.selected ? color.mxDeepOcean : '#262B2F')};
  height: 3.2rem;
  padding: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f1f7f9;
  }
`;

const StyledTitle = styled.span`
  flex-grow: 1;
`;

export type DropdownItem = {
  text: string;
  selected?: boolean;
};

// eslint-disable-next-line complexity
const Dropdown: FC<DropdownProps> = ({
  columnName,
  dataTest,
  fetchInitialFilterPropertyValues,
  isActive,
  tableName,
  title,
  options,
  onOpen,
  onClick,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sanitizedId = title.toLowerCase().replace(/\s/g, '');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) onOpen?.();
    fetchInitialFilterPropertyValues &&
      fetchInitialFilterPropertyValues(tableName || '', columnName || '');
  };

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;
  const formatId = title?.split(' ').join('');
  const buttonRight = document.getElementById(formatId)?.getBoundingClientRect()
    .right;
  const difference = window.innerWidth - (buttonRight ?? 1000);
  const alignRight = isDesktop && difference < 145;

  const selectOption = (item: DropdownItem) => {
    onClick(item);
    setIsOpen(false);
  };
  return (
    <DropdownWrapper data-test={dataTest} id={formatId} {...rest}>
      <OutsideClick onOutsideClick={() => setIsOpen(false)}>
        <TriggerButton
          aria-expanded="false"
          aria-haspopup="true"
          data-test={dataTest && `${dataTest}-button`}
          isActive={isActive}
          isOpen={isOpen}
          onClick={toggleDropdown}
        >
          <StyledTitle>{title}</StyledTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.75732 9.24268L12.4142 14.8995L18.071 9.24268"
              stroke="#BEBFC1"
              fill="none"
            />
          </svg>
        </TriggerButton>
        {isOpen && (
          <DropdownContainer
            data-test={`${sanitizedId}-container`}
            alignRight={alignRight || false}
          >
            <ul>
              {options.map((el, index) => {
                return (
                  <StyledListItem
                    key={index}
                    selected={el.selected}
                    onClick={() => {
                      selectOption(el);
                    }}
                  >
                    <StyledTitle>{el.text}</StyledTitle>
                    {el.selected && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M18.3008 7L10.883 17L6.30078 12.7895"
                            stroke="#1B7899"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                          />
                        </svg>
                      </>
                    )}
                  </StyledListItem>
                );
              })}
            </ul>
          </DropdownContainer>
        )}
      </OutsideClick>
    </DropdownWrapper>
  );
};

export { Dropdown };
