import React, { forwardRef } from 'react';

import { Group, RadioGroupProps } from './radio-group';

export interface RadioGroup extends RadioGroupProps {
  name: string;
  dataTestId?: string;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroup>(
  function Component(props, ref) {
    return <Group {...props} ref={ref} />;
  }
);
