import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--colors-charlestonGrey12);
  font-size: 16px;
  font-weight: 700;
  color: var(--colors-charlestonGrey);

  &.withClose {
    padding-right: 40px;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 8px;
    top: 20px;
    right: 8px;
    cursor: pointer;
    background: transparent;
    border: none;

    &:focus,
    &:focus-within {
      outline: 1px solid #006c4e;
    }
  }
`;
