import React, { FC } from 'react';
import styled from 'styled-components';
import { color, typography } from '@gsc/components';

interface HeaderProps {
  bodyText: string;
  title: string;
}

const HeadingWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

const HeadingTitle = styled.h1`
  ${typography.heading2};
  color: ${color.text900};
  font-weight: normal;
  margin-bottom: 0.8rem;
`;

const HeadingBody = styled.p`
  ${typography.body};
  color: ${color.ui50};
`;

const PageHeader: FC<HeaderProps> = ({ bodyText, title }) => {
  return (
    <HeadingWrapper>
      <HeadingTitle>{title}</HeadingTitle>
      <HeadingBody>{bodyText}</HeadingBody>
    </HeadingWrapper>
  );
};

export { PageHeader };
