import React, { FC } from 'react';
import { PositionDetails } from 'src/state/Compliance/wapi_planogram_api';
import styled from 'styled-components';
import { PlanIcon } from '../../../../../icons/Icons';

type ExpectedPositionProps = {
  planogramPosition: PositionDetails;
};

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PlanogramPositionBox = styled.div`
  margin-left: 3.2rem;
  display: flex;
  flex-wrap: wrap;
`;

const ProductPositionBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
`;

const ProductPosition = styled.div`
  margin-left: 0.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap;
  color: #262b2f;
  line-height: 2.4rem;
`;

const ProductDetailsTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;
  line-height: 2.4rem;
  color: #727578;
`;

const PositionSeparator = styled.div`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;
  color: #727578;
  line-height: 2.4rem;
`;

const ExpectedPosition: FC<ExpectedPositionProps> = ({
  planogramPosition,
}: ExpectedPositionProps) => {
  return (
    <>
      <ProductPositionBox>
        <PlanIcon />
        <ProductPosition>Position on Planogram</ProductPosition>
      </ProductPositionBox>
      <PlanogramPositionBox>
        <FlexBox>
          <ProductDetailsTitle>Segment:</ProductDetailsTitle>
          <ProductPosition>
            {planogramPosition.segmentIndex + 1}
          </ProductPosition>
          <PositionSeparator>/</PositionSeparator>
        </FlexBox>
        <FlexBox>
          <ProductDetailsTitle>Shelf:</ProductDetailsTitle>
          <ProductPosition>{planogramPosition.shelfIndexY + 1}</ProductPosition>
          <PositionSeparator>/</PositionSeparator>
        </FlexBox>
        <FlexBox>
          <ProductDetailsTitle>Position:</ProductDetailsTitle>
          <ProductPosition>{planogramPosition.shelfIndexX + 1}</ProductPosition>
          <PositionSeparator>/</PositionSeparator>
        </FlexBox>
        <FlexBox>
          <ProductDetailsTitle>Facings:</ProductDetailsTitle>
          <ProductPosition>
            {planogramPosition.hFacings * planogramPosition.vFacings}
          </ProductPosition>
          <PositionSeparator>/</PositionSeparator>
        </FlexBox>
        <FlexBox>
          <ProductDetailsTitle>Capacity:</ProductDetailsTitle>
          <ProductPosition>
            {planogramPosition.hFacings *
              planogramPosition.vFacings *
              planogramPosition.dFacings}
          </ProductPosition>
        </FlexBox>
      </PlanogramPositionBox>
    </>
  );
};

export { ExpectedPosition };
