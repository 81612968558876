import React from 'react';
import styled from 'styled-components';

const logoUrl = require('./photoworks-by-gsc.svg').default as string;

const StyledIcon = styled('img')`
  height: 3.2rem;
`;

export const LogoIcon = () => {
  return <StyledIcon src={logoUrl} />;
};
