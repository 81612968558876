import { generateCssString } from './generate-css-string';

export const cssReset = () => {
  const cssResetObject = {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      // fontFamily: 'Helvetica Neue', Figma uses Helvetica Neue but the project uses Roboto
    },
    '*::-webkit-scrollbar': {
      width: '6px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbdbd',
      borderRadius: '8px',
      backgroundClip: 'padding-box',
    },
    html: {
      // fontSize: '16px', Project currently uses 10px or 62.5%
      lineHeight: 1.15,
      // '-webkit-text-size-adjust': '100%',
    },
    body: {
      height: '100vh',
    },
    // '#root': {
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
    // overflow: 'auto',
    // },
    // main: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   height: '100%',
    //   maxWidth: '80rem',
    //   width: '100%',
    //   margin: '0 auto',
    // },
    hr: {
      boxSizing: 'content-box',
      height: 0,
      overflow: 'visible',
    },
    a: {
      textDecoration: 'none',
    },
    'abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'underline dotted',
    },
    'b, strong': {
      fontWeight: 'bolder',
    },
    'button, [type=`button`],[type=`reset`], [type=`submit`]': {
      '-webkit-appearance': 'button',
    },
    '[hidden]': {
      display: 'none',
    },
  };

  const resetTag = document.createElement('style');

  resetTag.innerHTML = generateCssString(cssResetObject);

  resetTag.id = 'css-reset';
  resetTag.title = 'css-reset';

  document.head.appendChild(resetTag);
};
