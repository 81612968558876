import React, { FC } from 'react';
import { Notification } from '@gsc/components';

interface Props {
  componentStack: string;
  error: Error | null;
}

const ErrorBoundaryFallback: FC<Props> = ({ componentStack, error }) => {
  const errorString = error ? error.toString() : '';

  return (
    <Notification status="negative" data-testid="error-boundary-fallback">
      <p>{errorString}</p>
      {componentStack}
    </Notification>
  );
};

export { ErrorBoundaryFallback };
