import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Container } from './overlay.styles';

type Props = {
  onClick?: () => void;
  open?: boolean;
};

export const Overlay = ({ onClick, open }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const shouldClose = isOpen && !open;

  const classes = classNames({
    open: isOpen,
    close: shouldClose,
  });

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';

    let timer: number;
    if (open) {
      setIsOpen(true);
    }
    if (shouldClose) {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, 300);
    }

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = 'unset';
    };
  }, [open, isOpen, shouldClose]);

  return (
    <>
      {isOpen && (
        <Container
          data-testid="overlay"
          onClick={onClick}
          className={classes}
        />
      )}
    </>
  );
};
