import { useContext, useCallback } from 'react';
import { ActionTypes } from './actions';
import { SpacesDispatchContext, SpacesStateContext } from './context';
import * as types from './types';
import { api } from './api';
import { useMetadata } from '../Auth';
import { useRouteMatch } from 'react-router';
import { selectors } from './selectors';
import { Space } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const useSpacesState = () => {
  const state = useContext(SpacesStateContext);

  if (!state) {
    throw new Error('useSpacesState must be used within a SpacesProvider');
  }
  return {
    ...state,
    ...types,
    ...selectors(state),
  };
};

const useSpacesDispatch = () => {
  const dispatch = useContext(SpacesDispatchContext);

  if (!dispatch) {
    throw new Error('useSpacesDispatch must be used within a SpacesProvider');
  }

  return {
    dispatch,
    actions: {
      ...ActionTypes,
    },
  };
};

const useSpacesAPI = () => {
  const getMetadata = useMetadata();

  return useCallback(() => {
    return getMetadata().then(metadata => {
      return api(metadata);
    });
  }, [getMetadata]);
};

const useSpacesContext = () => [useSpacesState(), useSpacesDispatch()];

const useCurrentSpace = (): {
  isMatch: boolean;
  id: string;
} => {
  const { defaultSpace, spaces } = useSpacesState();

  const match = useRouteMatch<{ spaceId: string }>('/spaces/:spaceId');

  const isValidSpace = !!spaces[match?.params.spaceId || ''];
  const isMatch = match?.params.spaceId !== undefined && isValidSpace;
  const id = isValidSpace
    ? match?.params.spaceId || defaultSpace
    : defaultSpace;

  return { isMatch, id };
};

const useCurrentSpaceObj = (): Space.AsObject => {
  const { spaces } = useSpacesState();
  const { id: currentSpaceId } = useCurrentSpace();
  return spaces[currentSpaceId];
};

export {
  useSpacesAPI,
  useSpacesState,
  useSpacesDispatch,
  useSpacesContext,
  useCurrentSpace,
  useCurrentSpaceObj,
};
