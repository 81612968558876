import { produce } from 'immer';
import { State, SpacesStatus } from './types';
import { Actions, ActionTypes } from './actions';
import { selectors } from './selectors';

const reducer = (state: State, action: Actions): State =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SetStatus:
        draft.status = action.payload.status;
        return draft;

      case ActionTypes.InitializeSpaces:
        const { spaces } = action.payload;

        spaces.forEach(space => {
          draft.spaces[space.getId()] = space.toObject();
        });

        draft.status = SpacesStatus.COMPLETE;
        if (spaces.length > 0) {
          // TODO: Remove alphabetical sort once backend sends ordered list
          draft.defaultSpace = selectors(draft).getSpacesAlphabetical()[0].id;
        }
        return draft;

      case ActionTypes.AddSpace:
        const { space } = action.payload;

        draft.spaces[space.getId()] = space.toObject();
        return draft;

      case ActionTypes.UpdateSpaceConfig:
        const { id, config } = action.payload;
        draft.spaces[id].config = config.toObject();
        return draft;

      case ActionTypes.SetDefaultSpace:
        draft.defaultSpace = action.payload.id;
        return draft;

      case ActionTypes.UpdateSpaceFeatureFlags:
        draft.spaces[action.payload.id].featureFlagsList =
          action.payload.featureFlagsList;
        return draft;

      default:
        return draft;
    }
  });

export { reducer };
