import React, { useMemo } from 'react';
import { FC, useContext } from 'react';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import styled from 'styled-components';
import {
  BrandbankProduct,
  PositionDetails,
  ProductDetails,
  ProductsEntity,
} from 'src/state/Compliance/wapi_planogram_api';
import { ProductImage } from './ProductImage';

interface ComplianceActionsProps {
  matchesError: string | boolean;
}

interface ActionBoxProps {
  value: ActionData;
}

const Root = styled.div`
  padding: 1.6rem;
`;

const ActionBoxRoot = styled.div<{ selected: boolean }>`
  padding: 0.8rem;
  display: flex;
  border-bottom: 0.1rem solid #e5e6e6;
  background: ${p => (p.selected ? '#DBE9EF' : undefined)};
  border-radius: ${p => (p.selected ? '0.8rem' : undefined)};
  cursor: pointer;
  min-width: 7.6rem;
`;

const RecommendationText = styled.div`
  color: #262b2f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem; /* 121.429% */
  margin-left: 1.6rem;
`;

const ErrorBanner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;

  & > div {
    margin-top: 8px;
    padding: 1rem;
    width: 100%;
    color: #d0021b;
    background: #d0021b17;
    border-radius: 8px;
  }
`;

enum Action {
  INCORRECT_ITEM,
  NOT_ON_SHELF,
  EMPTY_FACING,
  INCORRECT_POSITION,
  MISSING_PRICE_TAG,
  INCORRECT_PRICING,
  NONE,
}

type ActionData = {
  action: number;
  product: ProductsEntity | undefined;
  planogramProduct: ProductDetails | undefined;
  brandbankItem: BrandbankProduct | undefined;
  planogramPosition: PositionDetails | undefined;
};

const getFacingsCount = (actionData: ActionData) => {
  return actionData.product ? actionData.product.empty_facings : 0;
};

const getSegment = (actionData: ActionData) => {
  return (actionData.planogramPosition?.segmentIndex ?? 0) + 1;
};

const getShelf = (actionData: ActionData) => {
  return (actionData.planogramPosition?.shelfIndexY ?? 0) + 1;
};

const getPosition = (actionData: ActionData) => {
  return (actionData.planogramPosition?.shelfIndexX ?? 0) + 1;
};

const getCapacity = (actionData: ActionData) => {
  return actionData.planogramPosition
    ? actionData.planogramPosition.hFacings *
        actionData.planogramPosition.vFacings *
        actionData.planogramPosition.dFacings
    : 0;
};

const getProductName = (actionData: ActionData) => {
  return actionData.product?.name
    ? actionData.product.name
    : actionData.planogramProduct?.name
    ? actionData.planogramProduct.name
    : actionData.brandbankItem?.name
    ? actionData.brandbankItem.name
    : '';
};

const ActionBox: FC<ActionData> = (props: ActionData) => {
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;
  const setSelectedProduct = useContext(ComplianceStateContext)
    ?.setSelectedProduct;

  const { isSelected } = useMemo(() => {
    return {
      isSelected:
        !!selectedProduct &&
        selectedProduct.brandbankUuid == props.brandbankItem?.brandbankGuid,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  let recommendation = '';
  const facings = getFacingsCount(props);
  const segment = getSegment(props);
  const shelf = getShelf(props);
  const position = getPosition(props);
  const capacity = getCapacity(props);
  const productName = getProductName(props);
  switch (props.action) {
    case Action.EMPTY_FACING:
      recommendation = `Add ${
        facings === 1 ? facings + ' facing' : facings + ' facings'
      } of ${props.brandbankItem?.upc} - ${productName} - ${
        props.brandbankItem?.packSize
      } to Segment ${segment}, Shelf ${shelf}, Position ${position}.`;
      break;
    case Action.NOT_ON_SHELF:
      recommendation = `Restock ${capacity} units of ${props.brandbankItem?.upc} - ${productName} - ${props.brandbankItem?.packSize}`;
      break;
    case Action.INCORRECT_POSITION:
      recommendation = `Move ${props.brandbankItem?.upc} - ${productName} - ${props.brandbankItem?.packSize} to Segment ${segment}, Shelf ${shelf}, Position ${position}`;
      break;
    case Action.INCORRECT_ITEM:
      recommendation = `Remove ${props.brandbankItem?.upc} - ${props.brandbankItem?.name} - ${props.brandbankItem?.packSize} from the shelf`;
      break;
    case Action.MISSING_PRICE_TAG:
      recommendation = `Print & Tag Price for ${props.brandbankItem?.upc} - ${props.brandbankItem?.name} - ${props.brandbankItem?.packSize} on Segment ${segment}, Shelf ${shelf}, Position ${position}`;
      break;
    case Action.INCORRECT_PRICING:
      recommendation = `Check pricing and Retag Price for ${props.brandbankItem?.upc} - ${props.brandbankItem?.name} - ${props.brandbankItem?.packSize} on Segment ${segment}, Shelf ${shelf}, Position ${position}`;
      break;
  }

  return (
    <ActionBoxRoot
      selected={isSelected}
      onClick={() => {
        !!setSelectedProduct &&
          setSelectedProduct(props.brandbankItem?.brandbankGuid);
      }}
    >
      <ProductImage
        width={6}
        height={6}
        url={props.brandbankItem?.imageUrl ?? ''}
      />
      <RecommendationText>{recommendation}</RecommendationText>
    </ActionBoxRoot>
  );
};

const ComplianceActions = ({ matchesError }: ComplianceActionsProps) => {
  const contextState = useContext(ComplianceStateContext)?.state;

  const actions = useMemo(() => {
    const result: ActionData[] = [];

    contextState?.kpiResult?.planned_rects
      ?.filter(el => el.facing_status === 'unplanned_item')
      .forEach(item => {
        const brandbankItem = item.found_brandbank_uuid
          ? contextState?.brandbankMap?.get(item.found_brandbank_uuid)
          : undefined;
        result.push({
          action: Action.INCORRECT_ITEM,
          product: undefined,
          planogramProduct: undefined,
          brandbankItem,
          planogramPosition: undefined,
        });
      });

    contextState?.kpiResult?.products
      ?.filter(item => !item.is_compliant)
      .forEach(product => {
        let action = Action.NONE;

        if (product.is_not_on_shelf) {
          action = Action.NOT_ON_SHELF;
        } else if (product.empty_facings > 0) {
          action = Action.EMPTY_FACING;
        } else if (product.incorrect_position > 0) {
          action = Action.INCORRECT_POSITION;
        } else if (product.missing_price > 0) {
          action = Action.MISSING_PRICE_TAG;
        } else if (product.incorrect_pricing > 0) {
          action = Action.INCORRECT_PRICING;
        }
        const planogramProduct = contextState?.planogramProductMap?.get(
          product.brandbank_uuid
        );
        const planogramPosition = contextState?.planogram?.details.positions.find(
          el => el.upc === planogramProduct?.upc
        );

        const brandbankItem = planogramProduct
          ? contextState?.brandbankMap?.get(product.brandbank_uuid)
          : undefined;

        if (action !== Action.NONE) {
          result.push({
            action,
            product,
            planogramProduct,
            brandbankItem,
            planogramPosition,
          });
        }
      });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps, prettier/prettier
  }, [contextState?.kpiResult?.products, contextState?.planogram, contextState?.brandbankMap, contextState?.planogramProductMap,
  ]);

  const renderAction = (value: ActionData, index: number) => {
    return <ActionBox key={index} {...value} />;
  };

  if (typeof matchesError === 'string')
    return (
      <ErrorBanner>
        <div>
          {matchesError.replace('Planogram Compliance Results', 'Actions')}
        </div>
      </ErrorBanner>
    );

  return <Root>{actions.map(renderAction)}</Root>;
};

export { ComplianceActions };
