import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { border, color, elevation, H5, TertiaryButton } from '@gsc/components';
import { ChevronDownBold } from '@gsc/icons/react';

import { MobileTakeOver } from './';
import {
  isMobileDevice,
  uppercaseFirstLetter,
  useWindowDimensions,
} from '../../shared/utils';

const MobileSidebar = styled(MobileTakeOver)`
  background-color: ${color.ui01};
  left: 0;
  position: fixed;
  top: 5.6rem;
  width: 100%;
  z-index: 10;
`;

const DesktopSidebar = styled(MobileSidebar)`
  border-right: ${border.normal(color.ui15)};
  height: 100%;
  padding: 4rem 0;
  width: 24.2rem;
`;

const MobileButton = styled(TertiaryButton).attrs({ isFullWidth: true })`
  ${elevation['12']};
  border-radius: 0;
  color: ${color.ui50};
  height: 4.4rem;
`;

const DownArrow = styled(ChevronDownBold)`
  color: ${color.ui50};
  vertical-align: middle;
  width: 1.2rem !important; /* have to do this to override the base button style */
  margin-left: 0.4rem;
  margin-right: -1.2rem !important;
  position: relative;
  top: -0.1rem;
`;

const SidebarTitle = styled(H5)`
  color: ${color.ui50};
  margin-bottom: 1.2rem;
  padding-left: 3.2rem;
  text-transform: uppercase;
`;

interface StyledSidebarProps {
  Button: any;
  buttonText: any;
  dataTest: string;
  isDesktop: boolean | undefined;
}

const StyledSidebar: FC<StyledSidebarProps> = ({
  Button,
  buttonText,
  children,
  dataTest,
  isDesktop,
}) =>
  isDesktop ? (
    <DesktopSidebar Button={Button} buttonText={buttonText} dataTest={dataTest}>
      {children}
    </DesktopSidebar>
  ) : (
    <MobileSidebar Button={Button} buttonText={buttonText} dataTest={dataTest}>
      {children}
    </MobileSidebar>
  );

interface SidebarProps {
  children?: React.ReactNode;
  dataTest?: string;
  links?: Array<React.ReactElement>;
  title: string | React.ReactNode;
}

const Sidebar: FC<SidebarProps> = ({
  children,
  dataTest = 'sidebar-default',
  links = [],
  title,
}) => {
  const location = useLocation();
  const pageName = uppercaseFirstLetter(
    location.pathname
      .split('/')
      .slice(-1)[0]
      .split('-')
      .join(' ')
      .toLowerCase()
  );

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  return (
    <>
      <StyledSidebar
        Button={MobileButton}
        buttonText={
          <span>
            {pageName} <DownArrow />
          </span>
        }
        dataTest={dataTest}
        isDesktop={isDesktop}
      >
        <SidebarTitle>{title}</SidebarTitle>

        <nav>
          <ul>
            {links.map(link => (
              <li key={`${link?.key}-list-item`}>{link}</li>
            ))}
          </ul>
        </nav>

        {children}
      </StyledSidebar>
    </>
  );
};

export { Sidebar };
