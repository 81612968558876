import React, {
  CSSProperties,
  FC,
  FormEvent,
  SyntheticEvent,
  useMemo,
} from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import {
  border,
  breakpoint,
  color,
  elevation,
  font,
  H4,
  H5,
  H6,
  Label,
  transition,
  typography,
  zIndex,
} from '@gsc/components';
import { Close, SuccessCheckmark, ZoomIn } from '@gsc/icons/react';
import { CdnImageWrapper } from './CdnImageWrapper';
import {
  useCurrentSpaceObj,
  defaultCardConfig,
} from '../../../../state/Spaces';
import {
  AssetDetail,
  CardConfig,
  CardField,
  Reference,
  Property,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import classnames from 'classnames';
import moment from 'moment';

export interface PhotoCardProps {
  cardConfig?: CardConfig.AsObject;
  children?: any;
  checked?: boolean;
  date?: number;
  deleteMode?: boolean;
  id: string;
  loading?: boolean;
  selectable?: boolean;
  handleChange?(event: FormEvent): any;
  onClick?(e: SyntheticEvent): any;
  onMouseDown?(e: SyntheticEvent): any;
  onMouseEnter?(e: SyntheticEvent): any;
  onMouseUp?(e: SyntheticEvent): any;
  photo?: AssetDetail.AsObject;
  primaryInfo?: string;
  secondaryInfo?: string;
  selectMode?: boolean | string;
  style?: CSSProperties;
  tertiaryInfo?: string;
  url?: string;
  imgSrcOverride?: string;
  suspended?: boolean;
}

const CardOuterWrapper = styled.div`
  height: 40rem; /* placeholder for loading state of image */
  padding: 0 1.2rem 2.4rem;
  -webkit-tap-highlight-color: transparent;

  ${breakpoint.md`
    & {
      height: 50rem;
    }
  `}

  ${breakpoint.lg`
    & {
      height: 52rem;
      padding: 0 1.6rem 3.2rem;
    }
  `}
`;

const CardWrapper = styled.div`
  border: transparent solid 0;
  height: 100%;
  position: relative;
  transition: ${transition.primary('border-width')};

  &.selectable.checked:not(.loading) {
    border: transparent solid 1.6rem;

    &:after {
      background-color: ${color.info10};
      border-radius: 0.4rem;
      content: '';
      display: block;
      height: calc(100% + 3.2rem);
      left: -1.6rem;
      position: absolute;
      top: -1.6rem;
      width: calc(100% + 3.2rem);
      z-index: -1;
    }
  }

  &.delete-mode {
    border-left: transparent solid 0.8rem;
    border-top: transparent solid 1rem;
    border-bottom: none;
    border-right: none;

    &:after {
      border-radius: 0.4rem;
      content: '';
      display: block;
      height: 100%;
      left: -1.6rem;
      position: absolute;
      top: -1.6rem;
      width: 100%;
      z-index: -1;
    }
  }
`;

const PhotoWrapper = styled.div`
  ${elevation['08']};
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  display: block;
  height: 100%;

  ${CardWrapper}.selectable,
  ${CardWrapper}.delete-mode & {
    cursor: pointer;
  }

  ${CardWrapper}.selectable &:after {
    ${elevation['08']};
    border-top-left-radius: ${border.radius.normal};
    border-top-right-radius: ${border.radius.normal};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    padding-bottom: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }

  ${CardWrapper}.checked &:after,
  ${CardWrapper}.select-mode &:after,
  ${CardWrapper}.delete-mode &:after,
  ${CardWrapper}:hover:not(.loading) &:after {
    visibility: visible;
  }
` as AnyStyledComponent;

const Photo = styled.div`
  background-color: ${color.ui10};
  border-top-left-radius: ${border.radius.normal};
  border-top-right-radius: ${border.radius.normal};
  height: calc(100% - 13rem);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${CardWrapper}.delete-mode &:after {
    background-color: transparent;
  }

  img {
    border-top-left-radius: ${border.radius.normal};
    border-top-right-radius: ${border.radius.normal};
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
    user-select: none;
  }
`;

const CheckboxWrapper = styled.div`
  left: 0rem;
  position: absolute;
  top: 0rem;
  vertical-align: middle;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: left 0.25s ease, top 0.25s ease;

  ${CardWrapper}.checked & {
    height: calc(100% + 3.2rem);
    left: -1.6rem;
    top: -1.6rem;
    width: calc(100% + 3.2rem);
  }

  ${CardWrapper}.select-mode & {
    height: calc(100% + 3.2rem);
    width: calc(100% + 3.2rem);
  }

  ${CardWrapper}.delete-mode & {
    height: 100%;
    width: 100%;
  }

  input + label {
    .delete-checkbox {
      background-color: ${color.ui50};
    }
  }

  input:hover + label {
    .delete-checkbox {
      background-color: ${color.ui50};
    }
  }

  input:checked + label {
    &:before {
      width: 0.4rem;
    }

    &:after {
      width: 1rem;
    }

    .checkbox-background {
      background-color: ${color.mxDeepOcean};
      border-color: ${color.mxDeepOcean};
    }

    .delete-checkbox {
      background-color: ${color.ui50};
    }
  }
`;

const Input = styled.input<PhotoCardProps>`
  left: -9999rem;
  position: absolute;
`;

const CheckboxLabel = styled(Label)`
  color: ${color.ui01};
  line-height: 2.4rem;
  padding: 0.8rem 0 0 0.8rem;

  ${CardWrapper}.checked & {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${CardWrapper}.select-mode &,
  ${CardWrapper}.delete-mode & {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const CheckboxCircle = styled.div`
  border-radius: 100%;
  border: ${border.normal(color.ui01)};
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.5);
  display: inline-block;
  height: 2.4rem;
  vertical-align: bottom;
  visibility: hidden;
  width: 2.4rem;
  z-index: ${zIndex.alpha};

  &.delete-checkbox {
    border: none;
    left: -1.2rem;
    position: absolute;
    top: -1rem;
    visibility: visible;
  }

  ${CardWrapper}.select-mode &,
  ${CardWrapper}:hover & {
    visibility: visible;
  }

  ${Input}:checked + label & {
    visibility: visible;
  }
`;

const StyledCheckmark = styled(SuccessCheckmark)`
  height: 1.2rem;
  left: 1.4rem;
  position: absolute;
  top: 1.4rem;
  visibility: hidden;

  ${Input}:checked + label & {
    visibility: visible;
  }
`;

const StyledError = styled(Close)`
  height: 1rem;
  left: 0.7rem;
  position: absolute;
  top: 0.7rem;

  ${Input}:checked + label & {
    visibility: visible;
  }
`;

const ZoomWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  bottom: 14rem;
  visibility: hidden;

  ${CardWrapper}.select-mode:hover & {
    visibility: visible;
  }
`;

const StyledZoom = styled(ZoomIn)`
  color: ${color.ui01};
  filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.5));
  height: 2.4rem;
  width: 2.4rem;
`;

const PhotoDetailsWrapper = styled.div`
  bottom: 0;
  height: 13rem;
  left: 0;
  padding: 1.6rem;
  position: absolute;
  width: 100%;
`;

const ellipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PrimaryInfo = styled(H4)`
  ${ellipsis}
  ${typography.heading3}
  margin-bottom: 0.2rem;
  user-select: none;
`;

const SecondaryInfo = styled(H5)`
  ${ellipsis}
  ${typography.bodyLarge}
  margin-bottom: 0;
  user-select: none;
`;

const TertiaryInfo = styled(H6)`
  ${ellipsis}
  ${typography.bodyLarge}
  user-select: none;
`;

const Timestamp = styled(H6)`
  ${ellipsis}
  ${typography.bodySmall}
  color: ${color.ui50};
  font-weight: ${font.weight.normal};
  margin-bottom: 0;
  user-select: none;
`;

const TextLoading = styled.div(
  ({ last, width }: { last?: boolean; width?: string }) => `
  background-color: ${color.ui10};
  border-radius: 99rem;
  height: ${last ? '1.4rem' : '1.6rem'};
  margin-bottom: ${last ? '0' : '1.2rem'};
  width: ${width ? width : '95%'}
`
);

const getDataFromField = (
  assetDetail: AssetDetail.AsObject,
  cardField: CardField.AsObject
) => {
  if (!cardField) return 'N/A';

  const table = assetDetail.referencesList.find(
    value => value.name === cardField.tableName
  );
  const column = table?.recordsList?.[0]?.propertiesList?.find(
    c => c.name === cardField.columnName
  );

  if (cardField.tableName === 'context_filters') {
    return column?.valueArrayList.join(', ') ?? 'N/A';
  } else {
    return column?.value ?? 'N/A';
  }
};

const getPrimaryInfo = (
  assetDetail: AssetDetail.AsObject,
  cardConfig: CardConfig.AsObject
) => {
  const primaryField = cardConfig.fieldsList.find(({ role }) => role === 1);
  return primaryField && getDataFromField(assetDetail, primaryField);
};

const getSecondaryInfo = (
  assetDetail: AssetDetail.AsObject,
  cardConfig: CardConfig.AsObject
) => {
  const secondaryField = cardConfig.fieldsList.find(({ role }) => role === 2);
  return secondaryField && getDataFromField(assetDetail, secondaryField);
};

const getTertiaryInfo = (
  assetDetail: AssetDetail.AsObject,
  cardConfig: CardConfig.AsObject
) => {
  const tertiaryField = cardConfig.fieldsList.find(({ role }) => role === 3);
  return tertiaryField && getDataFromField(assetDetail, tertiaryField);
};

// eslint-disable-next-line complexity
const PhotoCard: FC<PhotoCardProps> = ({
  cardConfig,
  checked,
  date,
  deleteMode,
  handleChange,
  id,
  imgSrcOverride,
  loading = true,
  onClick,
  photo,
  primaryInfo,
  secondaryInfo,
  selectable = true,
  selectMode,
  suspended,
  tertiaryInfo,
  url,
  ...rest
}) => {
  const getCardConfig =
    cardConfig ?? useCurrentSpaceObj()?.config?.cardConfig ?? defaultCardConfig;

  const formattedDate = useMemo(() => {
    let epoch;

    if (date) {
      epoch = date;
    } else {
      const missionCreatedAt = photo?.referencesList
        ?.find((item: Reference.AsObject) => item.name === 'mission_responses')
        ?.recordsList[0].propertiesList.find(
          (item: Property.AsObject) => item.name === 'completed_at'
        )?.value;
      if (missionCreatedAt) epoch = parseInt(missionCreatedAt);
    }

    if (epoch) {
      return moment(epoch).format('MMMM DD, YYYY h:mm A z');
    } else {
      return 'Date not found.';
    }
  }, [date, photo]);

  return (
    <CardOuterWrapper data-test={`photo-card-${id}`} {...rest}>
      <CardWrapper
        className={classnames({
          checked,
          selectable,
          loading,
          'select-mode': selectMode,
          'delete-mode': deleteMode,
        })}
      >
        <PhotoWrapper onClick={onClick}>
          <Photo className="photo-card-photo">
            {!loading && !suspended && (
              <CdnImageWrapper
                alt={primaryInfo ?? ''}
                className="photo-card-photo-image"
                imageUrl={url}
                imgSrcOverride={imgSrcOverride}
                data-test={`photo-card-${id}-image`}
              />
            )}
          </Photo>
          <PhotoDetailsWrapper>
            {!loading ? (
              <>
                <PrimaryInfo
                  data-test={`photo-card-${id}-primary-info`}
                  title={primaryInfo}
                >
                  {primaryInfo ??
                    (photo && getPrimaryInfo(photo, getCardConfig))}
                </PrimaryInfo>
                <SecondaryInfo
                  data-test={`photo-card-${id}-secondary-info`}
                  title={secondaryInfo}
                >
                  {secondaryInfo ??
                    (photo && getSecondaryInfo(photo, getCardConfig))}
                </SecondaryInfo>
                <TertiaryInfo
                  data-test={`photo-card-${id}-tertiary-info`}
                  title={tertiaryInfo}
                >
                  {tertiaryInfo ??
                    (photo && getTertiaryInfo(photo, getCardConfig))}
                </TertiaryInfo>
                <Timestamp
                  data-test={`photo-card-${id}-timestamp`}
                  title={formattedDate}
                >
                  {formattedDate}
                </Timestamp>
              </>
            ) : (
              <>
                <TextLoading />
                <TextLoading width="65%" />
                <TextLoading width="85%" />
                <TextLoading last width="50%" />
              </>
            )}
          </PhotoDetailsWrapper>
        </PhotoWrapper>

        {!loading && selectable && (
          <CheckboxWrapper>
            <Input
              aria-checked={checked}
              checked={checked}
              data-test={`photo-card-${id}-input`}
              id={id}
              onChange={event =>
                handleChange ? handleChange(event) : () => null
              }
              role="checkbox"
              type="checkbox"
            />
            <CheckboxLabel
              className={classnames({ checked })}
              data-test={`photo-card-${id}-label`}
              id={id}
            >
              <CheckboxCircle
                className={classnames(
                  { 'delete-checkbox': deleteMode },
                  'checkbox-background'
                )}
              >
                {deleteMode ? <StyledError /> : <StyledCheckmark />}
              </CheckboxCircle>
            </CheckboxLabel>
          </CheckboxWrapper>
        )}

        <ZoomWrapper data-test="zoom-icon" onClick={onClick}>
          <StyledZoom />
        </ZoomWrapper>
      </CardWrapper>
    </CardOuterWrapper>
  );
};

export { PhotoCard };
