import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActionTypes,
  usePhotosAPI,
  usePhotosDispatch,
  usePhotosState,
} from '../../../../state/Photos';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { Details } from './Details';
import { ComplianceProvider } from '../../../../state/Compliance/context';
import { paths } from '../../../../paths';
import { useCurrentSpace } from '../../../../state/Spaces';
import { useAssetDetailTags } from '../../../../state/Compliance/hooks/useAssetDetailTags';

let pageLoadTimer: number | undefined;
const queuePageForLoading = (
  pageNumber: number,
  loadFn: (v: number) => void
) => {
  if (typeof pageLoadTimer !== 'undefined') clearTimeout(pageLoadTimer);

  pageLoadTimer = setTimeout(function() {
    pageLoadTimer = undefined;

    loadFn(pageNumber);
  }, 200);
};

interface PhotoDetailsProps {
  id: string;
}

const PhotoDetails: FC<PhotoDetailsProps> = ({ id }) => {
  const history = useHistory();
  const {
    assetDetail,
    assetDetailsPages,
    pagination,
    detailPlaceholder,
  } = usePhotosState();
  const space = useCurrentSpace();
  const clickedCoordinates = detailPlaceholder?.clickedCoordinates;
  const dispatch = usePhotosDispatch();
  const api = usePhotosAPI();
  const [assetDetailLoading, setAssetDetailLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add('details-active');
    setAssetDetailLoading(true);

    api()
      .then(({ getAssetDetail }) => getAssetDetail(id))
      .then(data => {
        dispatch({
          type: ActionTypes.GetAssetDetailResponse,
          payload: {
            assetDetail: data,
          },
        });

        setAssetDetailLoading(false);
      })
      .catch(() => {
        history.push(paths.spaces.space.photos.notFound(space.id));
      });

    return () => {
      document.body.classList.remove('takeover-active');

      dispatch({
        type: ActionTypes.ClearAssetDetailResponse,
      });

      dispatch({
        type: ActionTypes.SetDetailPlaceholder,
        payload: {
          detailPlaceholder: {
            clickedCoordinates: {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
            },
            placeholder: '',
          },
        },
      });
    };
  }, [dispatch, api, history, id, space.id]);

  const currentPage = useMemo(
    () => Object.values(assetDetailsPages).find(({ ids }) => ids.includes(id)),
    [assetDetailsPages, id]
  );

  const { getFilters, setShowPhoto, photoDetailsPanel } = useQueryParams();
  const currentPageNumber = currentPage?.pageNumber ?? -1;

  const { hasPreviousPhoto } = useMemo(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    const hasPreviousPhoto = currentIndex > 0;
    return { hasPreviousPhoto };
  }, [currentPage, id]);

  const goToPreviousPhoto = useCallback(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    let prevPhotoId = currentPage?.ids?.[currentIndex - 1];

    // check for a previous page to grab the last id of...
    if (!prevPhotoId && currentPageNumber > 0) {
      const previousPage = assetDetailsPages[`page${currentPageNumber - 1}`];
      prevPhotoId = previousPage?.ids?.slice?.(-1)?.[0];
    }

    if (prevPhotoId) setShowPhoto(prevPhotoId, photoDetailsPanel);
  }, [
    assetDetailsPages,
    currentPage,
    currentPageNumber,
    id,
    setShowPhoto,
    photoDetailsPanel,
  ]);

  const goToNextPhoto = useCallback(() => {
    const currentIndex = currentPage?.ids?.indexOf(id) ?? -1;
    const nextPhotoId = currentPage?.ids?.[currentIndex + 1];
    if (nextPhotoId) return setShowPhoto(nextPhotoId, photoDetailsPanel);

    const nextPageNumber = currentPageNumber + 1;
    const nextPage = assetDetailsPages[`page${nextPageNumber}`];
    if (nextPage?.loading) return;
    const firstId = nextPage?.ids?.[0];
    if (firstId) return setShowPhoto(firstId);

    queuePageForLoading(nextPageNumber, () => {
      dispatch({
        type: ActionTypes.SetPageLoading,
        payload: { pageNumber: nextPageNumber },
      });

      api()
        .then(({ getPhotoSearch }) =>
          getPhotoSearch({
            filters: getFilters(),
            from: nextPageNumber * (pagination?.size ?? 9),
          })
        )
        .then(data => {
          dispatch({
            type: ActionTypes.GetPhotosResponse,
            payload: { photos: data, pageNumber: nextPageNumber },
          });

          const firstId = data.getAssetDetailsList()[0]?.getId();
          if (firstId) setShowPhoto(firstId);
        });
    });
  }, [
    api,
    assetDetailsPages,
    currentPage,
    currentPageNumber,
    dispatch,
    getFilters,
    id,
    pagination,
    setShowPhoto,
    photoDetailsPanel,
  ]);

  const { companyId, photoGridId } = useAssetDetailTags(assetDetail);

  return (
    <ComplianceProvider photoGridId={photoGridId} companyId={companyId}>
      <Details
        assetDetail={assetDetail}
        assetDetailLoading={assetDetailLoading}
        clickedCoordinates={clickedCoordinates}
        goToNextPhoto={goToNextPhoto}
        goToPreviousPhoto={goToPreviousPhoto}
        hasPreviousPhoto={hasPreviousPhoto}
      />
    </ComplianceProvider>
  );
};

export { PhotoDetails };
