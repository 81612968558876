import 'a11y-dialog';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ModalContext, ModalContextValues } from './modal-context';
import { Container } from './modal.styles';
import { Overlay } from '../overlay';
import { Portal } from '../portal';
import { delayedAction } from '../../hooks';

export type ModalProps = {
  children?: React.ReactElement | React.ReactElement[];
  open: boolean;
  onClose: () => void;
  id: string;
  className?: string;
  dataTestId?: string;
};

type ExtendedHTMLDialogElement = HTMLDialogElement & {
  show?: () => void;
  close?: () => void;
};

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    { children, id, onClose, open, className, dataTestId = 'modal-wrapper' },
    ref
  ) => {
    const modalRef = useRef<ExtendedHTMLDialogElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    const activeElement = useMemo(
      () => document.activeElement as HTMLElement,
      []
    );
    const labelledBy = `modal-${id}-header`;
    const describedBy = `modal-${id}-body`;

    const close = useMemo(() => isOpen && !open, [isOpen, open]);

    const wrapperClasses = classNames({
      open: isOpen,
      close: close,
    });
    const classes = classNames('content', className);

    const openModal = () => {
      setIsOpen(true);
      modalRef.current?.show();
    };

    const closeModal = () => {
      setIsOpen(false);
      modalRef.current?.close();
    };

    useImperativeHandle(
      ref,
      () =>
        ({
          ...contentRef.current,
          openModal,
          closeModal,
        } as any)
    );

    const handleEscapeClose = useCallback(
      (e: KeyboardEvent) => e.key === 'Escape' && onClose(),
      [onClose]
    );

    useEffect(() => {
      contentRef.current?.focus();

      return () => {
        activeElement.focus();
      };
    }, [activeElement]);

    useEffect(() => {
      if (open) {
        openModal();
      }

      let action: number;

      if (close) {
        action = delayedAction(closeModal);
      }

      return () => {
        clearTimeout(action);
      };
    }, [close, open]);

    useEffect(() => {
      document.addEventListener('keydown', handleEscapeClose);

      return () => {
        document.removeEventListener('keydown', handleEscapeClose);
      };
    }, [handleEscapeClose]);

    React.Children.forEach(children, child => {
      if (React.isValidElement(child)) {
        const isModalComponent = ((child.type as unknown) as {
          modalComponent?: boolean;
        })?.modalComponent;

        if (!isModalComponent)
          throw new Error(
            'Modal only accepts children of type ModalActions, ModalContent or ModalHeader'
          );
      }
    });

    const contextValue: ModalContextValues = {
      close: onClose,
      labelledBy,
      describedBy,
    };

    return (
      <>
        {isOpen && (
          <Portal>
            <Container
              ref={modalRef}
              role="dialog"
              aria-hidden={!isOpen}
              aria-modal
              aria-labelledby={labelledBy}
              aria-describedby={describedBy}
              className={wrapperClasses}
              data-testid={dataTestId}
              data-a11y-dialog={id}
              tabIndex={-1}
            >
              <Overlay open onClick={onClose} />
              <ModalContext.Provider value={contextValue}>
                <div ref={contentRef} className={classes}>
                  {children}
                </div>
              </ModalContext.Provider>
            </Container>
          </Portal>
        )}
      </>
    );
  }
);

Modal.displayName = 'Modal';
