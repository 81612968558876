import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import { border, color, TertiaryButton } from '@gsc/components';
import { AddCircle, Delete, Settings, Unsaved } from '@gsc/icons/react';
import { ButtonMenu } from '../../../../../shared/components';
import { useAlbumsAPI } from '../../../../../state/Albums';
import {
  stringifyFilters,
  useQueryParams,
} from '../../../../../state/QueryParams/hooks';
import { useCurrentSpaceObj } from '../../../../../state/Spaces';
import { SpaceTierType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { SavedView } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const StyledButtonMenu = styled(ButtonMenu)`
  margin-right: 0.8rem;

  ul[role='listbox'] {
    max-height: 42.4rem;
    overflow-x: hidden;
    z-index: 2;
  }

  ul[role='listbox'] li:nth-last-child(2) {
    background-color: #fff;
    border-top: ${border.normal(color.ui15)};
    position: sticky;
    bottom: 4rem;
  }

  ul[role='listbox'] li:last-child {
    background-color: #fff;
    position: sticky;
    bottom: 0;
  }
`;

const SpecialItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledAddIcon = styled(AddCircle)`
  color: ${color.mxDeepOcean};
  height: 1.6rem;
  margin-right: 0.8rem;

  ${SpecialItemWrapper}.disabled & {
    color: ${color.ui30};
  }
`;

const StyledSettingsIcon = styled(Settings)`
  color: ${color.mxDeepOcean};
  height: 1.6rem;
  margin-right: 0.8rem;

  ${SpecialItemWrapper}.disabled & {
    color: ${color.ui30};
  }
`;

const ListItemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ListItemTextWrapper = styled.div`
  width: 100%;

  ${ListItemWrapper}:hover & {
    width: calc(100% - 5.3rem);
  }
`;

const ListItemText = styled.p`
  display: inline-block;
`;

const DeleteButton = styled(TertiaryButton)`
  border: none;
  padding-right: 0;
  visibility: hidden;

  ${ListItemWrapper}:hover & {
    visibility: visible;
  }

  &:not(.show-loader):hover {
    background-color: ${color.ui05};
  }
`;

const StyledDeleteIcon = styled(Delete)`
  color: ${color.ui50};
  height: 1.6rem;
`;

const SpecialItemText = styled.p`
  color: ${color.mxDeepOcean};
  font-weight: 500;

  ${SpecialItemWrapper}.disabled & {
    color: ${color.ui30};
  }
`;

interface SavedViewsButtonProps {
  setResponseNotification: (args: string) => any;
}

const SavedViewsButton: FC<SavedViewsButtonProps> = ({
  setResponseNotification,
}) => {
  const history = useHistory();
  const currentSpace = useCurrentSpaceObj();
  const {
    getFilters,
    setShowSavedViewModal,
    setShowSmartAlbumModal,
  } = useQueryParams();
  const api = useAlbumsAPI();
  const [savedViewItems, setSavedViewItems] = useState<SavedView.AsObject[]>(
    []
  );

  const openMenu = () => {
    setSavedViewItems([]);
    api()
      .then(({ listSavedViews }) => {
        return listSavedViews(currentSpace.id);
      })
      .then(res => {
        const list = res.toObject().savedViewsList;
        setSavedViewItems(list);
      })
      .catch(() => {
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const deleteSavedView = (id: string) => {
    api()
      .then(({ deleteSavedView }) => {
        return deleteSavedView(id);
      })
      .then(() => {
        const deletedItem = savedViewItems.filter(item => item.id === id)[0];
        setResponseNotification(
          `${deletedItem?.name} saved view has been deleted`
        );
      })
      .catch(() => {
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const filters = getFilters();
  const noFilters = filters.length === 0;

  const listItems = savedViewItems
    ?.sort((a, b) => b.createdAtTime - a.createdAtTime)
    .map(item => {
      const spaces = /\s/g;
      const combinedName = item.name.toLowerCase().replace(spaces, '-');

      return {
        children: (
          <ListItemWrapper data-test={`${combinedName}-item`}>
            <ListItemTextWrapper
              onClick={() => {
                history.replace({ search: stringifyFilters(item) });
              }}
            >
              <ListItemText>{item.name}</ListItemText>
            </ListItemTextWrapper>
            <DeleteButton
              data-test={`delete-${combinedName}`}
              isIconOnly
              status="neutral"
              onClick={() => deleteSavedView(item.id)}
            >
              <StyledDeleteIcon />
            </DeleteButton>
          </ListItemWrapper>
        ),
      };
    });

  const items = useMemo(
    () => [
      ...listItems,
      {
        onSelect: () => setShowSavedViewModal(true),
        children: (
          <SpecialItemWrapper className={classnames({ disabled: noFilters })}>
            <StyledAddIcon />
            <SpecialItemText>Save as new view</SpecialItemText>
          </SpecialItemWrapper>
        ),
        disabled: noFilters,
      },
      {
        onSelect: () => setShowSmartAlbumModal(true),
        children: (
          <SpecialItemWrapper className={classnames({ disabled: noFilters })}>
            <StyledSettingsIcon />
            <SpecialItemText>Save as smart album</SpecialItemText>
          </SpecialItemWrapper>
        ),
        disabled: noFilters,
      },
    ],
    [listItems, noFilters, setShowSavedViewModal, setShowSmartAlbumModal]
  );

  if (currentSpace.tier?.type === SpaceTierType.SPACE_TIER_TYPE_LITE)
    return null;

  return (
    <StyledButtonMenu
      alignRight
      buttonText="Saved Views"
      handleClick={() => openMenu()}
      icon={<Unsaved />}
      items={items}
      status="neutral"
      variant="secondary"
    />
  );
};

export { SavedViewsButton };
