import styled from 'styled-components';
import { breakpoint } from '@gsc/components';

const MobileArticle = styled.article`
  flex: 1;
  padding: 0 1.6rem 8rem;
`;

const DesktopArticle = styled(MobileArticle)`
  max-width: 70rem;
  padding: 0 6.4rem 3.2rem;
`;

const StyledArticle = styled.article`
  flex: 1;
  padding: 0 1.6rem 8rem;

  ${breakpoint.lg`
    & {
      max-width: 70rem;
      padding: 0 6.4rem 3.2rem;
    }
  `}
`;

export { DesktopArticle, MobileArticle, StyledArticle };
