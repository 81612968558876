import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Operator } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { isInclusivelyAfterDay } from 'react-dates';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import {
  generateLabel,
  toMoment,
  toString,
} from '../../shared/filters/formatDateFilters';
import { DateRange, DateRangePicker } from '../DateRangePicker';
import {
  DATE_PROPERTY_NAME,
  DATE_REFERENCE_NAME,
  SearchFilter,
} from '../../../../state/QueryParams/types';
import { FilterComponentProps } from './types';
import { StyledFilterDropdown } from './components';

const isOutsideRange = (day: moment.Moment): boolean =>
  isInclusivelyAfterDay(day, moment().add(1, 'day'));

const isDatePropertyFilter = (filter: SearchFilter): boolean =>
  filter.propertyName === DATE_PROPERTY_NAME &&
  filter.referenceName === DATE_REFERENCE_NAME;

const DateRangeFilter: FC<FilterComponentProps> = () => {
  const { searchFilters, setSearchFilters } = useQueryParams();

  const startDateFilter = useMemo(
    () =>
      searchFilters.find(
        filter =>
          isDatePropertyFilter(filter) &&
          filter.operator === Operator.OPERATOR_GTE
      ),
    [searchFilters]
  );

  const endDateFilter = useMemo(
    () =>
      searchFilters.find(
        filter =>
          isDatePropertyFilter(filter) &&
          filter.operator === Operator.OPERATOR_LTE
      ),
    [searchFilters]
  );

  const startDate = useMemo(
    () => (startDateFilter ? toMoment(startDateFilter.value) : undefined),
    [startDateFilter]
  );
  const endDate = useMemo(
    () => (endDateFilter ? toMoment(endDateFilter.value) : undefined),
    [endDateFilter]
  );

  const isActive = !!startDateFilter || !!endDateFilter;
  const text = useMemo(
    () =>
      isActive && startDateFilter && endDateFilter
        ? generateLabel([startDateFilter, endDateFilter])
        : 'Dates',
    [endDateFilter, isActive, startDateFilter]
  );

  const handleDateChange = useCallback(
    (event: DateRange, labelName?: string) => {
      const { startDate, endDate } = event;
      const newFilters = searchFilters.filter(f => !isDatePropertyFilter(f));

      if (startDate) {
        const startEpoch = toString(startDate.startOf('day'));
        const endUTC = endDate?.endOf('day') ?? startDate.endOf('day');
        const endEpoch = toString(endUTC);

        if (labelName) {
          newFilters.push(
            {
              referenceName: DATE_REFERENCE_NAME,
              propertyName: DATE_PROPERTY_NAME,
              operator: Operator.OPERATOR_GTE,
              value: startEpoch,
              label: labelName,
            },
            {
              referenceName: DATE_REFERENCE_NAME,
              propertyName: DATE_PROPERTY_NAME,
              operator: Operator.OPERATOR_LTE,
              value: toString(moment().endOf('day')),
              label: labelName,
            }
          );
        } else {
          newFilters.push(
            {
              referenceName: DATE_REFERENCE_NAME,
              propertyName: DATE_PROPERTY_NAME,
              operator: Operator.OPERATOR_GTE,
              value: startEpoch,
            },
            {
              referenceName: DATE_REFERENCE_NAME,
              propertyName: DATE_PROPERTY_NAME,
              operator: Operator.OPERATOR_LTE,
              value: endEpoch,
            }
          );
        }
      }

      setSearchFilters(newFilters);
    },
    [searchFilters, setSearchFilters]
  );

  return (
    <StyledFilterDropdown
      dataTest="filter-trigger-date"
      isActive={isActive}
      text={text}
    >
      <DateRangePicker
        dataTest="date-range-picker"
        enableOutsideDays
        initialEndDate={endDate}
        initialStartDate={startDate}
        isOutsideRange={isOutsideRange}
        onChange={handleDateChange}
      />
    </StyledFilterDropdown>
  );
};

export { DateRangeFilter };
