import React, { FC } from 'react';
import { FilterComponentProps } from './types';
import { getFilterComponentForColumnType } from './registry';

const FilterButton: FC<FilterComponentProps> = props => {
  if (!props.canFilter) return null;
  const FilterForType = getFilterComponentForColumnType(
    props.columnName,
    props.columnType
  );
  return <FilterForType {...props} />;
};

export { FilterButton };
