import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  breakpoint,
  color,
  H4,
  H5,
  PrimaryButton,
  SecondaryButton,
  zIndex,
} from '@gsc/components';
import classnames from 'classnames';

import {
  isMobileDevice,
  useWindowDimensions,
} from '../../../../../../shared/utils';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { Format } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import { FormLayout } from './FormLayout';
import { FormSettings } from './FormSettings';

interface ExportsSettingsFormProps {
  handleCancel(): any;
}

const Form = styled.form`
  flex: 1;

  &.desktop-layout {
    padding: 4.8rem 0 2.4rem 6.4rem;

    ${breakpoint.md`
      padding: 4.8rem 3.2rem 2.4rem 6.4rem;
    `}
  }
`;

const ActionButtonsWrapper = styled.div`
  background-color: ${color.ui01};
  bottom: 0;
  box-shadow: 0 -0.1rem 0 0 ${color.ui15};
  display: flex;
  justify-content: center;
  left: 0;
  padding: 1.6rem 2.4rem;
  position: fixed;
  width: 100%;
  z-index: ${zIndex.india + 1};

  form.desktop-layout & {
    box-shadow: none;
    height: 7.2rem;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 3.3rem;
    top: 0;
    width: 16.7rem;
    z-index: 0;

    body.exports-error & {
      top: 11.8rem;
    }
  }
`;

const ExportButton = styled(PrimaryButton)`
  margin: 0;
`;

const SectionWrapper = styled.div`
  > div:last-child {
    margin-bottom: 6.4rem;
  }

  form.desktop-layout & {
    width: 43.6rem;
  }
`;

const SectionHeader = styled(H4)`
  margin-bottom: 1.6rem;
`;

const SubWrapper = styled.div`
  margin-bottom: 3.2rem;

  > .small-radio-group {
    max-width: 32.8rem;
  }

  > .large-radio-group {
    max-width: 43.6rem;
  }
`;

const SubHeader = styled(H5)`
  color: ${color.ui50};
  display: inline-block;
  line-height: 2rem;
`;

const ExportSettingsForm: FC<ExportsSettingsFormProps> = ({ handleCancel }) => {
  const { handleSubmit, values } = useContext(FormContext);

  const largerWindow = useWindowDimensions().width >= 1019;
  const isDesktop = !isMobileDevice() && largerWindow;

  const format = parseInt(values.format, 10);

  const nonJPEGFormat = format !== Format.FORMAT_JPEG;

  return (
    <Form
      className={classnames({ 'desktop-layout': isDesktop })}
      onSubmit={handleSubmit}
      role="form"
    >
      <ActionButtonsWrapper>
        <SecondaryButton
          isFullWidth
          isInline
          onClick={handleCancel}
          status="neutral"
          title="Cancel Button"
        >
          Cancel
        </SecondaryButton>
        <ExportButton isFullWidth isInline title="Export Button" type="submit">
          Export
        </ExportButton>
      </ActionButtonsWrapper>

      <FormSettings />
      {nonJPEGFormat && <FormLayout />}
    </Form>
  );
};

export {
  ExportSettingsForm,
  SectionWrapper,
  SectionHeader,
  SubWrapper,
  SubHeader,
};
