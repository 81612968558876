import { useCallback } from 'react';
import { AlbumPage, State } from './types';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const useSelectors = (state: State) => ({
  getAlbumsList: useCallback(
    (): (Album.AsObject | SmartAlbum.AsObject)[] =>
      Object.keys(state.albumsPages).reduce<
        (Album.AsObject | SmartAlbum.AsObject)[]
      >((result, pageId) => {
        state.albumsPages[pageId]?.ids?.forEach(id => {
          const album = state.albumsById[id];
          if (
            album &&
            !result.some((item: { id: string }) => item.id === album.id)
          )
            result.push(album);
        });

        return result;
      }, []),
    [state.albumsById, state.albumsPages]
  ),

  getPage: useCallback(
    (pageNumber: number): AlbumPage | undefined =>
      state.albumsPages[`page${pageNumber}`],
    [state.albumsPages]
  ),

  getAsset: useCallback(
    (assetId: string): AssetDetail.AsObject | undefined =>
      state.albumAssetsById[assetId],
    [state.albumAssetsById]
  ),

  getPageOfAssets: useCallback(
    (albumId: string, pageNumber: number) =>
      state.albumAssetsPages[`album:${albumId};page${pageNumber}`],
    [state.albumAssetsPages]
  ),
});

export { useSelectors };
