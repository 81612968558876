import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  breakpoint,
  font,
  PrimaryButton,
  SecondaryButton,
} from '@gsc/components';
import { ShareLink } from '@gsc/icons/react';
import { paths } from '../../../../paths';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { ButtonMenu, MobileTakeOver } from '../../../../shared/components';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';
import { ShareModalBody } from './ShareModalBody';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 2.4rem;
  top: 7.6rem;

  ${breakpoint.md`
      position: relative;
      right: auto;
      top: auto;
  `}
`;

const DoneButton = styled(PrimaryButton)`
  margin-right: 1.6rem;
`;

const StyledButtonMenu = styled(ButtonMenu)`
  margin-right: 1.6rem;
`;

const ShareButton = styled(SecondaryButton)``;

const StyledMobileTakeOver = styled(MobileTakeOver)`
  &.share-album-takeover > button > svg {
    margin-right: 0;
  }
`;

const TakeOverHeader = styled.div`
  font-size: 1.6rem;
  font-weight: ${font.weight.medium};
  margin: auto;
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface HeaderActionButtonsProps {
  album: AlbumType;
  assetCount: number | undefined;
  setResponseNotification: (args: string) => any;
  shareToken: string;
}

const HeaderActionButtons: FC<HeaderActionButtonsProps> = ({
  album,
  assetCount,
  setResponseNotification,
  shareToken,
}) => {
  const {
    setShowExport,
    setShowDeleteAlbumModal,
    setShowEditAlbum,
    setShowShareAlbumModal,
    showEditAlbum,
  } = useQueryParams();
  const history = useHistory();

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;
  const smartAlbum = (album as SmartAlbum.AsObject).searchCriteria
    ? true
    : false;

  useEffect(() => {
    if (assetCount === 0) {
      setShowEditAlbum(false);
    }
  }, [assetCount, setShowEditAlbum]);

  const menuItems = () => {
    const addPhotos = {
      children: <p>Add photos</p>,
      dataTest: 'add-album-action',
      onSelect: () => {
        history.push(
          `${paths.spaces.space.photos.root(album?.spaceId)}?selectAlbum=${
            album?.id
          }`
        );
      },
    };

    const editAlbum = {
      children: <p>Edit album</p>,
      dataTest: 'edit-album-action',
      onSelect: () => setShowEditAlbum(true),
    };

    const exportAlbum = {
      children: <p>Export album</p>,
      dataTest: 'export-album-action',
      onSelect: () => setShowExport(true),
    };

    const deleteAlbum = {
      children: <p>Delete album</p>,
      dataTest: 'delete-album-action',
      onSelect: () => setShowDeleteAlbumModal(true),
    };
    return smartAlbum
      ? [exportAlbum, deleteAlbum]
      : [addPhotos, editAlbum, exportAlbum, deleteAlbum];
  };

  const shareButtonComponent = () =>
    isDesktop ? (
      <ShareButton
        onClick={() => setShowShareAlbumModal(true)}
        status="neutral"
      >
        <ShareLink />
        Share
      </ShareButton>
    ) : (
      <StyledMobileTakeOver
        Button={SecondaryButton}
        buttonText={<ShareLink />}
        className="share-album-takeover"
        dataTest="share-album"
        headerContent={
          <>
            <span />
            <TakeOverHeader>Share album</TakeOverHeader>
          </>
        }
        status="neutral"
      >
        <ShareModalBody
          album={album}
          setResponseNotification={setResponseNotification}
        />
      </StyledMobileTakeOver>
    );

  return (
    <ButtonContainer data-test="header-action-buttons">
      {showEditAlbum && (
        <DoneButton onClick={() => setShowEditAlbum(false)}>Done</DoneButton>
      )}
      {!shareToken && (
        <>
          <StyledButtonMenu
            alignRight
            dataTest="album-actions"
            items={menuItems()}
            status="neutral"
            variant="secondary"
          />
          {shareButtonComponent()}
        </>
      )}
    </ButtonContainer>
  );
};

export { HeaderActionButtons };
