import {
  Filter,
  Pagination,
  SearchCriteria,
  SortClause,
  SortCriteria,
  SortOrder,
  AggregatePagination,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import {
  GetPropertyValuesRequest,
  GetPropertyValuesResponse,
  SearchRequest,
  SearchResponse,
  GetPropertyHistogramResponse,
  GetPropertyHistogramRequest,
  CountDistinctPropertyValuesResponse,
  CountDistinctPropertyValuesRequest,
  GetFacetedFilterValuesResponse,
  GetFacetedFilterValuesRequest,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_api_pb';
import {
  GetEnrichedAssetDetailRequest,
  GetEnrichedAssetDetailResponse,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_api_pb';
import { grpc } from '@improbable-eng/grpc-web';
import { PhotoClient } from '../../services';

const api = (metadata: grpc.Metadata | null, currentSpaceId: string) => {
  if (!metadata) throw new Error('Auth Metadata missing');

  const PhotoSearchService = PhotoClient(metadata);

  const getAssetDetail = (
    id: string
  ): Promise<GetEnrichedAssetDetailResponse> => {
    const enrichedAssetDetailRequest = new GetEnrichedAssetDetailRequest();

    enrichedAssetDetailRequest.setId(id);

    return PhotoSearchService.getAssetDetail(enrichedAssetDetailRequest);
  };

  const getFacetedFilterValues = (params: {
    referenceName: string;
    propertyName: string;
    filters: Filter[];
    sortDirection?: string;
    inputValue?: string;
  }): Promise<GetFacetedFilterValuesResponse.AsObject> => {
    const { referenceName, propertyName, filters, inputValue = '' } = params;

    const request = new GetFacetedFilterValuesRequest();
    const photoSearchCriteria = new SearchCriteria();

    photoSearchCriteria.setFiltersList(filters);

    request.setSpaceId(currentSpaceId);
    request.setReferenceName(referenceName);
    request.setPropertyName(propertyName);
    request.setMatchValue(inputValue || '');

    request.setSearchCriteria(photoSearchCriteria);

    return PhotoSearchService.getFacetedFilterValues(request).then(response =>
      response.toObject()
    );
  };

  const getFilterPropertyValues = (params: {
    referenceName: string;
    propertyName: string;
    filters: Filter[];
    sortDirection?: string;
    inputValue?: string;
    applyAllFilters?: boolean;
    afterKey?: string;
    pageSize?: number;
  }): Promise<GetPropertyValuesResponse.AsObject> => {
    const {
      referenceName,
      propertyName,
      filters,
      sortDirection,
      inputValue = '',
      applyAllFilters = false,
      afterKey,
      pageSize = 50,
    } = params;

    const filterPropertyValuesRequest = new GetPropertyValuesRequest();
    const photoSearchCriteria = new SearchCriteria();

    photoSearchCriteria.setFiltersList(filters);

    filterPropertyValuesRequest.setApplyAllFilters(applyAllFilters);
    filterPropertyValuesRequest.setSpaceId(currentSpaceId);
    filterPropertyValuesRequest.setReferenceName(referenceName);
    filterPropertyValuesRequest.setPropertyName(propertyName);
    filterPropertyValuesRequest.setMatchValue(inputValue || '');

    filterPropertyValuesRequest.setSearchCriteria(photoSearchCriteria);

    if (sortDirection) {
      filterPropertyValuesRequest.setSortOrder(
        sortDirection === '+'
          ? SortOrder.SORT_ORDER_ASC
          : SortOrder.SORT_ORDER_DESC
      );
    }

    const pagination = new AggregatePagination();
    if (afterKey) pagination.setAfterKey(afterKey);
    pagination.setSize(pageSize);
    filterPropertyValuesRequest.setPagination(pagination);

    return PhotoSearchService.getFilterPropertyValues(
      filterPropertyValuesRequest
    ).then(response => response.toObject());
  };

  const getPhotoSearch = (params: {
    filters: Filter[];
    from?: number;
    pageSize?: number;
    sortField?: string;
    sortDirection?: string;
  }): Promise<SearchResponse> => {
    const {
      filters,
      from = 0,
      pageSize = 30,
      sortField = 'mission_responses.completed_at',
      sortDirection = '-',
    } = params;

    const photoSearchCriteria = new SearchCriteria();
    const pagination = new Pagination();
    const photoSearchRequest = new SearchRequest();

    photoSearchRequest.setSpaceId(currentSpaceId);

    //  set default sort descending
    const sortClauseArray: Array<SortClause> = [];
    const [sortReferenceName, sortPropertyName] = sortField.split('.');
    const primarySortClause = new SortClause();
    primarySortClause.setReferenceName(sortReferenceName);
    primarySortClause.setPropertyName(sortPropertyName);
    primarySortClause.setSortOrder(
      sortDirection === '+'
        ? SortOrder.SORT_ORDER_ASC
        : SortOrder.SORT_ORDER_DESC
    );
    sortClauseArray.push(primarySortClause);

    if (sortField !== 'mission_responses.completed_at') {
      const secondarySortClause = new SortClause();
      secondarySortClause.setReferenceName('mission_responses');
      secondarySortClause.setPropertyName('completed_at');
      secondarySortClause.setSortOrder(SortOrder.SORT_ORDER_DESC);
      sortClauseArray.push(secondarySortClause);
    }

    const tertiarySortClause = new SortClause();
    tertiarySortClause.setReferenceName('tasks');
    tertiarySortClause.setPropertyName('position');
    tertiarySortClause.setSortOrder(SortOrder.SORT_ORDER_ASC);
    sortClauseArray.push(tertiarySortClause);

    const sortCriteria = new SortCriteria();
    sortCriteria.setSortClausesList(sortClauseArray);

    //  set default pagination
    pagination.setFrom(from);
    pagination.setSize(pageSize);

    // set all search parameters
    photoSearchCriteria.setFiltersList(filters);
    photoSearchRequest.setSearchCriteria(photoSearchCriteria);
    photoSearchRequest.setSortCriteria(sortCriteria);
    photoSearchRequest.setPagination(pagination);

    return PhotoSearchService.getSearch(photoSearchRequest);
  };

  const getPropertyHistogram = (params: {
    referenceName: string;
    propertyName: string;
    filters: Filter[];
  }): Promise<GetPropertyHistogramResponse> => {
    const { referenceName, propertyName, filters = [] } = params;

    const request = new GetPropertyHistogramRequest();
    const photoSearchCriteria = new SearchCriteria();

    request.setSpaceId(currentSpaceId);
    request.setReferenceName(referenceName);
    request.setPropertyName(propertyName);
    request.setBucketInterval(1);

    photoSearchCriteria.setFiltersList(filters);
    request.setSearchCriteria(photoSearchCriteria);

    return PhotoSearchService.getPropertyHistogram(request);
  };

  const countDistinctPropertyValues = (params: {
    referenceName: string;
    propertyName: string;
    filters: Filter[];
  }): Promise<CountDistinctPropertyValuesResponse> => {
    const { referenceName, propertyName, filters = [] } = params;

    const request = new CountDistinctPropertyValuesRequest();
    request.setSpaceId(currentSpaceId);
    request.setReferenceName(referenceName);
    request.setPropertyName(propertyName);

    const photoSearchCriteria = new SearchCriteria();
    photoSearchCriteria.setFiltersList(filters);
    request.setSearchCriteria(photoSearchCriteria);

    return PhotoSearchService.countDistinctPropertyValues(request);
  };

  return {
    getAssetDetail,
    getFacetedFilterValues,
    getFilterPropertyValues,
    getPhotoSearch,
    getPropertyHistogram,
    countDistinctPropertyValues,
  };
};

export { api };
