import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Input } from '@gsc/components';
import { Operator } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { FilterComponentProps } from './types';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { useDebounce } from '../../../../shared/utils/hooks';
import { StyledFilterDropdown } from './components';
import { HistogramRangePicker } from '../HistogramRangePicker';
import { useHistogram } from '../HistogramRangePicker/state/hooks';

const NumericRangePopup = styled.div`
  width: calc(min(36rem, 100vw - 4.8rem));
  padding: 1.2rem;
`;

const HistogramArea = styled.div`
  min-height: 16rem;
  padding: 1.2rem 1.5rem 0.5rem;
`;

const NumericRangeFields = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldWrapper = styled.div`
  padding: 0 1.2rem;
  height: 7.8rem;
  overflow: hidden;

  label {
    padding-bottom: 0.5rem;
  }
`;

const parseFloatWithFallback = (value: string, fallback: number) => {
  const float = parseFloat(value);
  return isNaN(float) ? fallback : float;
};

// eslint-disable-next-line complexity
const NumericRangeFilter: FC<FilterComponentProps> = ({
  tableName,
  columnName,
  filterText,
}) => {
  const { searchFilters, setSearchFilters } = useQueryParams();

  const minInputRef = useRef(null);
  const maxInputRef = useRef(null);
  const isOpen = !!minInputRef.current || !!maxInputRef.current;

  const isMinFilter = useCallback(
    filter =>
      filter.referenceName === tableName &&
      filter.propertyName === columnName &&
      filter.operator === Operator.OPERATOR_GTE,
    [columnName, tableName]
  );

  const isMaxFilter = useCallback(
    filter =>
      filter.referenceName === tableName &&
      filter.propertyName === columnName &&
      filter.operator === Operator.OPERATOR_LTE,
    [columnName, tableName]
  );

  const [minFilterIndex, maxFilterIndex] = useMemo(
    () => [
      searchFilters.findIndex(isMinFilter),
      searchFilters.findIndex(isMaxFilter),
    ],
    [isMaxFilter, isMinFilter, searchFilters]
  );

  const minFilter = minFilterIndex > -1 ? searchFilters[minFilterIndex] : null;
  const maxFilter = maxFilterIndex > -1 ? searchFilters[maxFilterIndex] : null;

  const minFilterValue = minFilter?.value ?? '';
  const maxFilterValue = maxFilter?.value ?? '';

  const [minFieldValue, setMinFieldValue] = useState(minFilterValue);
  const [maxFieldValue, setMaxFieldValue] = useState(maxFilterValue);

  const debouncedMinValue = useDebounce(minFieldValue, 400);
  const debouncedMaxValue = useDebounce(maxFieldValue, 400);

  const isActive = !!minFilter && !!maxFilter;

  const handleMinFieldChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setMinFieldValue(value ?? '');

    // if we've passed the max, we'll set that too.
    if (value && parseFloat(value) > parseFloat(maxFieldValue))
      setMaxFieldValue(value);
  };

  const handleMaxFieldChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setMaxFieldValue(value ?? '');

    // if we've passed the min, we'll set that too.
    if (value && parseFloat(value) < parseFloat(minFieldValue))
      setMinFieldValue(value);
  };

  const hasMinAndMax =
    debouncedMinValue.length > 0 && debouncedMaxValue.length > 0;

  const applyFilter = useCallback(() => {
    const newFilters = searchFilters.filter(
      f => !isMinFilter(f) && !isMaxFilter(f)
    );

    if (hasMinAndMax) {
      newFilters.push({
        referenceName: tableName,
        propertyName: columnName,
        operator: Operator.OPERATOR_GTE,
        value: debouncedMinValue,
      });

      newFilters.push({
        referenceName: tableName,
        propertyName: columnName,
        operator: Operator.OPERATOR_LTE,
        value: debouncedMaxValue,
      });
    }

    setSearchFilters(newFilters);
  }, [
    columnName,
    debouncedMaxValue,
    debouncedMinValue,
    hasMinAndMax,
    isMaxFilter,
    isMinFilter,
    searchFilters,
    setSearchFilters,
    tableName,
  ]);

  const initialHistogramSelection = useMemo(() => {
    if (!minFilterValue.length || !maxFilterValue.length) return;
    return [parseFloat(minFilterValue), parseFloat(maxFilterValue)];
  }, [maxFilterValue, minFilterValue]);

  const {
    histogramSelection,
    setHistogramSelection,
    fetchHistogram,
    histogramData,
    histogramResponse,
  } = useHistogram({
    tableName,
    columnName,
    initialSelection: initialHistogramSelection,
    onChange: useCallback((minValue, maxValue) => {
      setMinFieldValue(minValue);
      setMaxFieldValue(maxValue);
    }, []),
  });

  const defaultMinValue = histogramResponse?.getMinPropertyValue() ?? 0;
  const defaultMaxValue = histogramResponse?.getMaxPropertyValue() ?? 1;

  // this effect applies the filter when the debounced values change.
  useEffect(() => {
    // if the fields are not rendered, skip the following.
    if (!isOpen) return;
    if (!minFilter && !maxFilter && !hasMinAndMax) return;

    const adjustingMin = !minFilter || minFilter.value !== debouncedMinValue;
    const adjustingMax = !maxFilter || maxFilter.value !== debouncedMaxValue;
    if (!adjustingMin && !adjustingMax) return;

    applyFilter();
    setHistogramSelection([
      parseFloatWithFallback(debouncedMinValue, defaultMinValue),
      parseFloatWithFallback(debouncedMaxValue, defaultMaxValue),
    ]);
  }, [
    applyFilter,
    debouncedMaxValue,
    debouncedMinValue,
    defaultMaxValue,
    defaultMinValue,
    hasMinAndMax,
    histogramResponse,
    isOpen,
    maxFilter,
    minFilter,
    setHistogramSelection,
  ]);

  // this effect clears component state when the filter is externally removed.
  useEffect(() => {
    if (isOpen) return;

    const minFilterEmpty = !minFilter && minFieldValue.length > 0;
    const maxFilterEmpty = !maxFilter && maxFieldValue.length > 0;

    if (minFilterEmpty) setMinFieldValue('');
    if (maxFilterEmpty) setMaxFieldValue('');
    if (minFilterEmpty || maxFilterEmpty) setHistogramSelection(undefined);
  }, [
    isOpen,
    maxFieldValue.length,
    maxFilter,
    minFieldValue.length,
    minFilter,
    setHistogramSelection,
  ]);

  const text =
    filterText +
    (isActive ? ` (${debouncedMinValue} to ${debouncedMaxValue})` : '');

  const noData = histogramData?.length === 0;
  const minPlaceholder = noData ? '' : histogramResponse?.getMinPropertyValue();
  const maxPlaceholder = noData ? '' : histogramResponse?.getMaxPropertyValue();
  const width = Math.min(306, window.innerWidth - 102);

  return (
    <StyledFilterDropdown
      onOpen={fetchHistogram}
      dataTest="filter-trigger-numeric-range"
      isActive={isActive}
      text={text}
    >
      <NumericRangePopup>
        <HistogramArea data-test="numeric-range-histogram">
          {histogramData && (
            <HistogramRangePicker
              width={width}
              height={120}
              padding={0}
              data={histogramData}
              selection={histogramSelection}
              onChange={setHistogramSelection}
            />
          )}
        </HistogramArea>

        <NumericRangeFields>
          <FieldWrapper>
            <Input
              ref={minInputRef}
              id={`${tableName}-${columnName}-min-value`}
              label="Min value"
              type="number"
              value={minFieldValue}
              onChange={handleMinFieldChange}
              disabled={noData}
              placeholder={minPlaceholder}
              autoFocus
            />
          </FieldWrapper>

          <FieldWrapper>
            <Input
              ref={maxInputRef}
              id={`${tableName}-${columnName}-max-value`}
              label="Max value"
              type="number"
              value={maxFieldValue}
              onChange={handleMaxFieldChange}
              disabled={noData}
              placeholder={maxPlaceholder}
            />
          </FieldWrapper>
        </NumericRangeFields>
      </NumericRangePopup>
    </StyledFilterDropdown>
  );
};

export { NumericRangeFilter };
