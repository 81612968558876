import { Results } from '../../../../state/Compliance/wapi_planogram_api';

const MIN_PLANNED_FACINGS = 10;
const MIN_ACCEPTED_OK_RESULTS = 5;

export const validateComplianceResults = (kpiResult?: Results) => {
  if (!kpiResult)
    return 'No Planogram Compliance Results are currently available';

  return (
    // Under the minimum planned facings, any number of ok results is acceptable
    ((kpiResult.planned_facings < MIN_PLANNED_FACINGS && kpiResult.ok === 0) ||
      kpiResult.ok < MIN_ACCEPTED_OK_RESULTS) &&
    'Planogram Compliance Results cannot be displayed. There are not enough tagged products correlated to the plan in the image to return results.'
  );
};

const CM_TO_PX_RATIO = 37.795275591;
const IN_TO_PX_RATIO = 96;

export const convertUnitToPx = (value: number, unit: 'cm' | 'in') => {
  if (unit === 'cm') return value * CM_TO_PX_RATIO;

  return value * IN_TO_PX_RATIO;
};
