import React, { forwardRef } from 'react';
import { RadioContext, RadioContextValues } from '../../context';
import { Container } from './radio-group.styles';

export type RadioGroupProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange' | 'value' | 'name' | 'defaultValue'
> & {
  children: React.ReactNode;
  dataTestId?: string;
  defaultValue?: string;
  value?: string;
  name: string;
  onChange: (value: string) => void;
};

export const Group = forwardRef<HTMLDivElement, RadioGroupProps>(
  function Component(
    { children, dataTestId, value, defaultValue, name, onChange, ...rest },
    ref
  ) {
    const contextValue: RadioContextValues = {
      currentValue: value ?? defaultValue,
      groupDataTestId: dataTestId,
      name,
      onChange,
    };

    return (
      <RadioContext.Provider value={contextValue}>
        <Container {...rest} ref={ref} data-testid={dataTestId}>
          {children}
        </Container>
      </RadioContext.Provider>
    );
  }
);
