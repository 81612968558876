import React, { FC, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, breakpoint } from '@gsc/components';
import { TableHeader, TBody, TH } from '../../../../shared/components';
import {
  StyledTable,
  StyledTData,
  StyledTHead,
  StyledTRow,
} from '../../shared/components/Tables';
import { PropertiesDropdown } from './PropertiesDropdown';
import {
  useCurrentSpaceObj,
  useSpacesAPI,
  useSpacesDispatch,
} from '../../../../state/Spaces';
import { StyledArticle } from '../../shared/components/Article';
import { combineTableAndColumnName } from '../../../Photos/shared/filters/formatFilterText';
import { PhotoCard } from '../../../Photos/shared/components/PhotoCard';
import { CardConfig } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { defaultCardConfig } from '../../../../state/Spaces/types';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';

interface FieldsTableProps {
  dataTest?: string;
}

const photosUrl = require('@gsc/icons/svg/photos.svg').default as string;

const StyledMobileForm = styled.form`
  display: flex;
  flex-direction: column;

  ${breakpoint.md`
    padding-left: 3.2rem;
  `}

  ${breakpoint.lg`
    flex-direction: row;
  `}
`;

const StyledDesktopForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: 6.4rem;

  ${breakpoint.lg`
    flex-direction: row;
  `}
`;

interface StyledProps {
  dataTest: string;
  isDesktop: boolean | undefined;
  onSubmit(event: FormEvent<Element>): void;
}

const StyledForm: FC<StyledProps> = ({
  isDesktop,
  children,
  onSubmit,
  dataTest,
}) =>
  isDesktop ? (
    <StyledDesktopForm onSubmit={onSubmit} data-test={dataTest}>
      {children}
    </StyledDesktopForm>
  ) : (
    <StyledMobileForm onSubmit={onSubmit} data-test={dataTest}>
      {children}
    </StyledMobileForm>
  );

const TableColumn = styled(StyledArticle)`
  width: 100%;
  flex-grow: 1;
  padding: 0 3.2rem 3.2rem;

  ${breakpoint.md`
    padding-left: 0;
  `}

  ${breakpoint.lg`
    padding-right: 1.6rem;
  `}
`;

const PhotoCardColumn = styled(StyledArticle)`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;

  ${breakpoint.md`
    padding-left: 0;

    & > h3 {
      padding-left: 0 !important; // override base article styles
    }
  `}

  ${breakpoint.lg`
    & > h3 {
      padding-left: 1.6rem !important; // override base article styles
    }
  `}
`;

const VeryStyledTRow = styled(StyledTRow)`
  display: table-row;
`;

const VeryStyledTHead = styled(StyledTHead)`
  display: table-header-group;
  visibility: visible;
`;

const VeryStyledTData = styled(StyledTData)`
  width: auto;
  display: table-cell;

  &:first-child {
    width: 1% !important;

    ${breakpoint.sm`
      width: 5% !important;
    `}
  }
`;

const StyledTH = styled(TH)`
  &:first-child {
    width: 1% !important;

    ${breakpoint.sm`
      width: 5% !important;
    `}
  }

  &:not(:first-child) {
    padding: 1rem 0 1rem 4.8rem;
  }

  ${breakpoint.lg`
    &:not(:first-child) {
      padding-left: 0;
    }
  `}
`;

const StyledTableHeader = styled(TableHeader)`
  margin-bottom: 1.2rem;

  ${breakpoint.lg`
    margin-bottom: 2.4rem;
  `}
`;

const StyledPhotoCard = styled(PhotoCard)`
  width: 100%;
  height: 50rem;

  ${breakpoint.lg`
    width: 30rem;
    height: 43rem;
  `}

  .photo-card-photo {
    display: flex;
  }

  .photo-card-photo-image {
    width: 4.8rem;
    height: 4.8rem;
    margin: auto;
    position: static;
  }
`;

const MobileActionButtons = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2.6rem;
  background: white;
  box-shadow: 0 -0.1rem 0 0 #dadcde;

  button {
    width: 100%;
  }
`;

const DesktopActionButtons = styled(MobileActionButtons)`
  ${breakpoint.md`
    padding-left: 26.8rem;
  `}

  ${breakpoint.lg`
    position: static;
    display: flex;
    width: 30rem;
    padding: 0 1.6rem 0 0;
    box-shadow: none;

    button {
      width: auto;
    }
  `}
`;

interface ActionButtonsProps {
  isDesktop: boolean | undefined;
}

const ActionButtons: FC<ActionButtonsProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopActionButtons>{children}</DesktopActionButtons>
  ) : (
    <MobileActionButtons>{children}</MobileActionButtons>
  );

const SaveButton = styled(PrimaryButton)`
  margin-left: auto;

  &[disabled] {
    background: slategray;
  }
`;

const FieldsTable = ({ dataTest }: FieldsTableProps) => {
  const currentSpace = useCurrentSpaceObj();
  const spacesDispatch = useSpacesDispatch();
  const spacesAPI = useSpacesAPI();

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  const [cardConfig, setCardConfig] = useState<CardConfig.AsObject>(
    currentSpace?.config?.cardConfig ?? defaultCardConfig
  );

  const primaryField = cardConfig.fieldsList.find(({ role }) => role === 1);
  const secondaryField = cardConfig.fieldsList.find(({ role }) => role === 2);
  const tertiaryField = cardConfig.fieldsList.find(({ role }) => role === 3);

  const primaryFieldName =
    primaryField &&
    combineTableAndColumnName(primaryField.tableName, primaryField.columnName);

  const secondaryFieldName =
    secondaryField &&
    combineTableAndColumnName(
      secondaryField.tableName,
      secondaryField.columnName
    );

  const tertiaryFieldName =
    tertiaryField &&
    combineTableAndColumnName(
      tertiaryField.tableName,
      tertiaryField.columnName
    );

  const [isSaving, setIsSaving] = useState(false);
  const applySettings = (event: FormEvent) => {
    event.preventDefault();

    if (!currentSpace.config) return;
    setIsSaving(true);

    spacesAPI().then(({ saveSpaceConfig }) => {
      saveSpaceConfig(currentSpace.id, {
        ...currentSpace.config,
        cardConfig,
      }).then(config => {
        spacesDispatch.dispatch({
          type: spacesDispatch.actions.UpdateSpaceConfig,
          payload: { id: currentSpace.id, config },
        });

        setIsSaving(false);
      });
    });
  };

  return (
    <StyledForm
      onSubmit={applySettings}
      dataTest={`${dataTest}-form`}
      isDesktop={isDesktop}
    >
      <TableColumn>
        <StyledTableHeader>Fields</StyledTableHeader>

        <StyledTable style={{ margin: '0' }}>
          <VeryStyledTHead>
            <VeryStyledTRow>
              <StyledTH>Order</StyledTH>
              <StyledTH>Field Name</StyledTH>
            </VeryStyledTRow>
          </VeryStyledTHead>

          <TBody>
            {cardConfig.fieldsList.map(field => (
              <VeryStyledTRow key={field.role}>
                <VeryStyledTData>
                  {field.role === 1 && 'Primary'}
                  {field.role === 2 && 'Secondary'}
                  {field.role === 3 && 'Tertiary'}
                </VeryStyledTData>
                <VeryStyledTData>
                  <PropertiesDropdown
                    value={field}
                    onChange={({ tableName, columnName }) => {
                      setCardConfig({
                        ...cardConfig,
                        fieldsList: cardConfig.fieldsList.map(f =>
                          f === field ? { ...f, tableName, columnName } : f
                        ),
                      });
                    }}
                  />
                </VeryStyledTData>
              </VeryStyledTRow>
            ))}
          </TBody>
        </StyledTable>
      </TableColumn>

      <PhotoCardColumn>
        <TableHeader style={{ paddingLeft: 16 }}>Preview</TableHeader>

        <StyledPhotoCard
          id="example-card"
          imgSrcOverride={photosUrl}
          primaryInfo={primaryFieldName}
          secondaryInfo={secondaryFieldName}
          tertiaryInfo={tertiaryFieldName}
          loading={false}
          selectable={false}
        />

        <ActionButtons isDesktop={isDesktop}>
          <SaveButton
            disabled={isSaving}
            isLoading={isSaving}
            type="submit"
            data-test={`${dataTest}-save`}
          >
            {isSaving ? 'Saving...' : 'Save Layout'}
          </SaveButton>
        </ActionButtons>
      </PhotoCardColumn>
    </StyledForm>
  );
};

export { FieldsTable };
