import React from 'react';
import { FilestackImage } from './FilestackImage';
import { CdnImage, CdnImageProps } from './CdnImage';

export const ImagesHostRegex = 'images.*.gospotcheck.com';

export const CdnImageWrapper = ({ imageUrl, ...otherProps }: CdnImageProps) => {
  // Use FilestackImage unless url contains new GSC images domain
  const regex = new RegExp(ImagesHostRegex, 'i');
  if (!regex.exec(imageUrl || '')) {
    return <FilestackImage imageUrl={imageUrl} {...otherProps} />;
  }

  return <CdnImage imageUrl={imageUrl} {...otherProps} />;
};
