import styled from 'styled-components';
import { color, Input } from '@gsc/components';
const searchIconUrl = require('@gsc/icons/svg/search.svg').default as string;

const SearchInput = styled(Input)`
  max-width: 24rem;

  > input {
    background: ${color.ui01} url(${searchIconUrl}) no-repeat 0.8rem center;
    background-size: 1.4rem;
    padding-left: 3rem;
  }
`;

export { SearchInput };
