import { breakpoint as gscBreakpoint } from '@gsc/components';
import { css } from 'styled-components';

export const breakpoint = {
  ...gscBreakpoint,
  lg: (...args) => css`
    @media screen and (min-width: 65em) {
      ${css(...args)}
    }
  `,
};
