import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  useReducer,
  useState,
} from 'react';
import { State, AuthStatus } from './types';
import { Actions } from './actions';
import { reducer } from './reducer';
import { useAuthenticate, useAuthorize, useSpaceAdmin } from './effects';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  hasGscAccess: false,
  isApertureAdmin: false,
  isSpaceAdmin: false,
  status: AuthStatus.INVALID,
  token: '',
  tokenExpiration: 0,
  user: {
    id: '',
    email: '',
    name: '',
    nickname: '',
    picture: '',
    updatedAt: '',
  },
};

interface AuthStateContextValues extends State {
  auth0Client?: Auth0Client;
}

const AuthStateContext = createContext<AuthStateContextValues | undefined>(
  undefined
);
const AuthDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined
);

const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth0Client, setAuth0Client] = useState<Auth0Client | undefined>(
    undefined
  );

  useAuthenticate(dispatch, setAuth0Client);
  useAuthorize(dispatch, state);
  useSpaceAdmin(dispatch, state);

  return (
    <AuthStateContext.Provider value={{ ...state, auth0Client }}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export { AuthProvider, AuthStateContext, AuthDispatchContext };
