import styled from 'styled-components';
import { breakpoint, font } from '@gsc/components';

const NotificationTitle = styled.strong`
  font-weight: ${font.weight.medium};
`;

const NotificationWrapper = styled.div`
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-top: 0.4rem;
  margin-bottom: 2.4rem;

  ${breakpoint.lg`
    & {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  `}
`;

export { NotificationTitle, NotificationWrapper };
