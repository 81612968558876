import { Results } from '../Compliance/wapi_planogram_api';
import { Actions, ActionTypes } from './actions';
import { zoomIdentity, ZoomTransform } from 'd3-zoom';

export interface State {
  realogram: {
    width: number;
    height: number;
    transform: ZoomTransform;
    //this is the portion of the realogram that maps to the planogram coordinates.
    plannedRect?: {
      xMin: number;
      yMin: number;
      xMax: number;
      yMax: number;
      width: number;
      height: number;
    };
  };
  planogram: {
    width: number;
    height: number;
    transform?: ZoomTransform;
  };
}

const getPlannedRectDimensions = (
  realogramWidth: number,
  realogramHeight: number,
  kpiResult: Results
) => {
  let xMin = 0;
  let yMin = 0;
  let xMax = realogramWidth;
  let yMax = realogramHeight;
  let width = realogramWidth;
  let height = realogramHeight;

  if (kpiResult.planogram_rect) {
    xMin = kpiResult.planogram_rect.x_min;
    yMin = kpiResult.planogram_rect.y_min;
    xMax = kpiResult.planogram_rect.x_max;
    yMax = kpiResult.planogram_rect.y_max;
    width = (xMax - xMin) * realogramWidth;
    height = (yMax - yMin) * realogramHeight;
  } else if (kpiResult.segment_rects && kpiResult.segment_rects.length > 0) {
    xMax = Math.max(...kpiResult.segment_rects.map(el => el.x_max)) ?? 1;
    xMin = Math.min(...kpiResult.segment_rects.map(el => el.x_min)) ?? 1;
    const segmentWidthMultiplier: number = xMax - xMin;

    yMax = Math.max(...kpiResult.segment_rects.map(el => el.y_max)) ?? 1;
    yMin = Math.min(...kpiResult.segment_rects.map(el => el.y_min)) ?? 1;
    const segmentHeightMultiplier: number = yMax - yMin;

    width =
      segmentWidthMultiplier > 1
        ? realogramWidth * segmentWidthMultiplier
        : realogramWidth;
    height =
      segmentHeightMultiplier > 1
        ? realogramHeight * segmentHeightMultiplier
        : realogramHeight;
  }

  return {
    xMin,
    yMin,
    xMax,
    yMax,
    width,
    height,
  };
};

const getPlanogramTransform = (
  state: State,
  realogramTransform: ZoomTransform
) => {
  if (
    state.realogram.plannedRect &&
    state.realogram.plannedRect.width > 0 &&
    state.realogram.plannedRect.height > 0 &&
    state.planogram.width > 0 &&
    state.planogram.height > 0
  ) {
    const scale = Math.min(
      state.realogram.plannedRect.width / state.planogram.width,
      state.realogram.plannedRect.height / state.planogram.height
    );

    let transformX = state.realogram.plannedRect?.xMin ?? 0;
    if (transformX !== 0) {
      transformX = transformX * state.realogram.width * realogramTransform.k;
    }
    let transformY = state.realogram.plannedRect?.yMin ?? 0;
    if (transformY !== 0) {
      transformY = transformY * state.realogram.height * realogramTransform.k;
    }

    return zoomIdentity
      .translate(
        realogramTransform.x + transformX,
        realogramTransform.y + transformY
      )
      .scale(realogramTransform.k * scale);
  }

  return state.planogram.transform;
};

const getRealogramTransform = (
  state: State,
  planogramTransform: ZoomTransform
) => {
  if (
    state.realogram.plannedRect &&
    state.realogram.plannedRect.width > 0 &&
    state.realogram.plannedRect.height > 0 &&
    state.planogram.width > 0 &&
    state.planogram.height > 0 &&
    state.planogram.transform
  ) {
    const scale = Math.min(
      state.realogram.plannedRect.width / state.planogram.width,
      state.realogram.plannedRect.height / state.planogram.height
    );

    let transformX = state.realogram.plannedRect?.xMin ?? 0;
    if (transformX !== 0) {
      transformX =
        (transformX * state.realogram.width * planogramTransform.k) / scale;
    }
    let transformY = state.realogram.plannedRect?.yMin ?? 0;
    if (transformY !== 0) {
      transformY =
        (transformY * state.realogram.height * planogramTransform.k) / scale;
    }

    return zoomIdentity
      .translate(
        planogramTransform.x - transformX,
        planogramTransform.y - transformY
      )
      .scale(planogramTransform.k / scale);
  }

  return state.realogram.transform;
};

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SetRealogramTransform: {
      const planogramTransform = getPlanogramTransform(
        state,
        action.payload.transform
      );
      return {
        ...state,
        realogram: {
          ...state.realogram,
          transform: action.payload.transform,
        },
        planogram: {
          ...state.planogram,
          transform: planogramTransform,
        },
      };
    }
    case ActionTypes.SetPlanogramTransform: {
      return {
        ...state,
        planogram: {
          ...state.planogram,
          transform: action.payload.transform,
        },
        realogram: {
          ...state.realogram,
          transform: getRealogramTransform(state, action.payload.transform),
        },
      };
    }
    case ActionTypes.SetRealogramDimensions: {
      state.realogram = {
        ...state.realogram,
        width: action.payload.width,
        height: action.payload.height,
        plannedRect: getPlannedRectDimensions(
          action.payload.width,
          action.payload.height,
          action.payload.kpiResult
        ),
      };
      const planogramTransform = getPlanogramTransform(
        state,
        state.realogram.transform
      );
      return {
        ...state,
        planogram: {
          ...state.planogram,
          transform: planogramTransform,
        },
      };
    }
    case ActionTypes.SetPlanogramDimensions: {
      state = {
        ...state,
        planogram: {
          ...state.planogram,
          width: action.payload.width,
          height: action.payload.height,
        },
      };

      const planogramTransform = getPlanogramTransform(
        state,
        state.realogram.transform
      );
      return {
        ...state,
        planogram: {
          ...state.planogram,
          transform: planogramTransform,
        },
      };
    }
  }
};

export { reducer };
