import { SearchResponse } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_api_pb';
import { GetEnrichedAssetDetailResponse } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_api_pb';

export enum ActionTypes {
  ClearAssetDetailResponse = 'app/photo/ClearAssetDetailResponse',
  ClearPhotosResponse = 'app/photo/ClearPhotosResponse',
  GetAssetDetailResponse = 'app/photo/GetAssetDetailResponse',
  SetPageLoading = 'app/photo/SetPageLoading',
  GetPhotosResponse = 'app/photo/GetPhotosResponse',
  SetTotalHits = 'app/photo/SetTotalHits',
  SetDetailPlaceholder = 'app/photo/SetDetailPlaceholder',
  SetLoadingState = 'app/photo/SetLoadingState',
  SetMobileSelectMode = 'app/photo/SetMobileSelectMode',
  SetSelectedPhotos = 'app/photo/SetSelectedPhotos',
  TogglePhotoSelected = 'app/photo/TogglePhotoSelected',
  ToggleShowDetails = 'app/photo/ToggleShowDetails',
  ToggleShowPlanogram = 'app/photo/ToggleShowPlanogram',
}

interface ClearAssetDetailResponse {
  type: ActionTypes.ClearAssetDetailResponse;
}

interface ClearPhotosResponse {
  type: ActionTypes.ClearPhotosResponse;
}

interface GetAssetDetailResponse {
  type: ActionTypes.GetAssetDetailResponse;
  payload: {
    assetDetail: GetEnrichedAssetDetailResponse;
  };
}

interface SetDetailPlaceholder {
  type: ActionTypes.SetDetailPlaceholder;
  payload: {
    detailPlaceholder: {
      clickedCoordinates: {
        height: number;
        width: number;
        x: number;
        y: number;
      };
      placeholder: string;
    };
  };
}

interface SetPageLoading {
  type: ActionTypes.SetPageLoading;
  payload: {
    pageNumber: number;
  };
}

interface GetPhotosResponse {
  type: ActionTypes.GetPhotosResponse;
  payload: {
    photos: SearchResponse;
    pageNumber: number;
  };
}

interface SetTotalHits {
  type: ActionTypes.SetTotalHits;
  payload: {
    totalHits: number;
  };
}

interface SetLoadingState {
  type: ActionTypes.SetLoadingState;
  payload: {
    loading: boolean;
  };
}

interface SetMobileSelectMode {
  type: ActionTypes.SetMobileSelectMode;
  payload: {
    mobileSelectMode: boolean;
  };
}

interface SetSelectedPhotos {
  type: ActionTypes.SetSelectedPhotos;
  payload: {
    ids: string[];
  };
}

interface TogglePhotoSelected {
  type: ActionTypes.TogglePhotoSelected;
  payload: {
    id: string;
  };
}

interface ToggleShowDetails {
  type: ActionTypes.ToggleShowDetails;
}
interface ToggleShowPlanogram {
  type: ActionTypes.ToggleShowPlanogram;
}

export type Actions =
  | ClearAssetDetailResponse
  | ClearPhotosResponse
  | SetPageLoading
  | SetMobileSelectMode
  | GetAssetDetailResponse
  | GetPhotosResponse
  | SetTotalHits
  | SetDetailPlaceholder
  | SetLoadingState
  | SetSelectedPhotos
  | TogglePhotoSelected
  | ToggleShowDetails
  | ToggleShowPlanogram;
