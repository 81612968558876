import { useMemo } from 'react';
import pluralize from 'pluralize';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { useCurrentSpaceObj } from '../../../../state/Spaces';

let pageLoadTimer: number | undefined;

const queuePageForLoading = (
  pageNumber: number,
  loadFn: (v: number) => void
) => {
  if (typeof pageLoadTimer !== 'undefined') clearTimeout(pageLoadTimer);

  pageLoadTimer = setTimeout(function() {
    pageLoadTimer = undefined;

    loadFn(pageNumber);
  }, 200);
};

const getColumnCount = (windowWidth: number): number => {
  if (windowWidth >= 1920) return 5;
  if (windowWidth >= 1440) return 4;
  if (windowWidth >= 1024) return 3;
  if (windowWidth >= 768) return 2;
  return 1;
};

const useSortColumn = () => {
  const { sortField } = useQueryParams();
  const currentSpace = useCurrentSpaceObj();
  const assetDetailConfig = currentSpace.config?.assetDetailConfig;
  const tablesList = assetDetailConfig?.tablesList ?? [];

  return useMemo(() => {
    const [sortTableName, sortColumnName] = sortField.split('.');
    return tablesList
      .find(({ tableName }) => tableName === sortTableName)
      ?.columnConfigsList?.find(
        ({ columnName }) => columnName === sortColumnName
      );
  }, [sortField, tablesList]);
};

const pluralizePhotoCount = (count: number): string => {
  const countWithCommas = count
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${countWithCommas} ${pluralize('photo', count)}`;
};

function findLast<T>(array: T[], predicate: (x: T) => boolean): T | undefined {
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (predicate(x)) return x;
  }

  return;
}

function waitUntil(
  condition: () => boolean,
  checkInterval = 250,
  maxRetries = 20
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (condition()) {
      resolve();
      return;
    }

    let retryCount = 0;

    const interval = setInterval(() => {
      retryCount++;

      if (condition()) {
        clearInterval(interval);
        resolve();
        return;
      } else if (retryCount >= maxRetries) {
        clearInterval(interval);
        reject();
      }
    }, checkInterval);
  });
}

export {
  queuePageForLoading,
  getColumnCount,
  useSortColumn,
  pluralizePhotoCount,
  findLast,
  waitUntil,
};
