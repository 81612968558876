import { Dispatch, useEffect } from 'react';
import { Actions } from './actions';
import { useAlbumsAPI, useLoadPage } from './hooks';
import { State } from './types';
import { useAuth } from '../Auth';

const useGetFirstPageOfAlbums = (dispatch: Dispatch<Actions>, state: State) => {
  const api = useAlbumsAPI();
  const loadPage = useLoadPage(state, dispatch, api);
  const firstPage = state.albumsPages['page0'];
  const { status, types } = useAuth();
  const authorized = status === types.AuthStatus.AUTHORIZED;

  useEffect(() => {
    if (!firstPage && authorized) loadPage(0);
  }, [loadPage, firstPage, authorized]);
};

export { useGetFirstPageOfAlbums };
