import styled from 'styled-components';
import { color } from '@gsc/components';

export type SingleButtonMenuItemStatus =
  | 'default'
  | 'info'
  | 'positive'
  | 'warning'
  | 'negative';

export interface SingleButtonMenuItem {
  active?: boolean;
  children: any;
  dataTest?: string;
  disabled?: boolean;
  onSelect?(args?: any): any;
  status?: SingleButtonMenuItemStatus;
}

const colorMap = {
  default: color.ui50,
  info: color.info50,
  positive: color.positive70,
  warning: color.warning50,
  negative: color.negative70,
};

const ButtonMenuItem = styled.li`
  ${({ active, disabled, status }: SingleButtonMenuItem) => `
    background-color: ${active ? color.ui05 : color.ui01};
    color: ${status ? colorMap[status] : color.ui50};
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 1.2rem;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    ${
      disabled
        ? `
        color: ${color.ui30};
        cursor: not-allowed;
      `
        : ''
    }
  `}
`;

ButtonMenuItem.defaultProps = {
  active: false,
  disabled: false,
  status: 'default',
};

export { ButtonMenuItem };
