import styled from 'styled-components';

export const Container = styled.dialog`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  border: none;

  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  font-family: Helvetica Neue, sans-serif;

  &.open {
    animation-name: fadein;
  }

  &.close {
    animation-name: fadeout;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 820px;
    max-width: 100%;
    height: 524px;
    max-height: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgb(33 33 33 / 20%);
    z-index: var(--zIndices-modal);
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
