import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  width: fit-content;
  height: 28px;

  padding: 6px 8px;
  font-size: 12px;

  user-select: none;
  white-space: nowrap;

  font-weight: 500;
  text-align: center;
  vertical-align: middle;

  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: all 0.05s ease-in-out;

  & .BeforeIcon {
    display: flex;
    margin-right: 8px;
  }

  & .AfterIcon {
    display: flex;
    margin-left: 8px;
  }

  color: var(--colors-white);
  background: var(--colors-deepOcean);
  border: none;

  &:hover,
  &:focus {
    background: var(--colors-deepOcean20);
  }

  &:active {
    background: var(--colors-deepOcean50);
  }

  &:disabled {
    color: var(--colors-charlestonGrey30);
    background: var(--colors-charlestonGrey12);
    pointer-events: none;
  }

  &.sm {
    padding: 0.25rem 0.625rem;
    font-size: 0.8125rem;
  }

  &.lg {
    height: 40px;
    padding: 8px 12px;
    font-size: 14px;
  }

  &.outlined {
    color: var(--colors-deepOcean);
    background: transparent;
    border: 1px solid var(--colors-deepOcean);

    &:hover,
    &:focus {
      background: var(--colors-deepOcean8);
    }

    &:active {
      background: var(--colors-deepOcean40);
    }

    &:disabled {
      color: var(--colors-charlestonGrey30);
      border-color: var(--colors-charlestonGrey30);
    }
  }

  & .ripple {
    position: absolute;
    pointer-events: none;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background: rgba(255, 255, 255, 0.699);

    &.outlined,
    &.text {
      background: var(--colors-deepOcean8);
    }
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  &.text {
    color: var(--colors-deepOcean);
    background: transparent;
    border: none;

    &:hover,
    &:focus {
      background: var(--colors-deepOcean8);
    }

    &:active {
      background: var(--colors-deepOcean40);
    }

    &:disabled {
      color: var(--colors-charlestonGrey30);
      background: transparent;
    }
  }

  &.error {
    color: var(--colors-fail);
    background: transparent;
    border-color: var(--colors-fail);

    &:hover,
    &:focus {
      background: var(--colors-fail10);
    }

    &:active {
      background: var(--colors-fail25);
    }

    &:disabled {
      color: var(--colors-charlestonGrey30);
      border-color: var(--colors-charlestonGrey30);
    }
  }
`;
