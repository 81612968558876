import { FC } from 'react';
import {
  ColumnType,
  ColumnTypeMap,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { FilterComponentProps } from './types';
import { DateRangeFilter } from './DateRangeFilter';
import { NumericRangeFilter } from './NumericRangeFilter';
import { TypeaheadDropdownFilter } from './TypeaheadDropdownFilter';

const ID = 'ID';

const isIDColumn = (columnName: string): boolean => {
  return columnName.toUpperCase() === ID;
};

const filterComponentRegistry: {
  [columnType: number]: FC<FilterComponentProps>;
} = {
  [ColumnType.COLUMN_TYPE_DATETIME]: DateRangeFilter,
  [ColumnType.COLUMN_TYPE_NUMBER]: NumericRangeFilter,
};

const getFilterComponentForColumnType = (
  columnName: string,
  columnType?: ColumnTypeMap[keyof ColumnTypeMap]
): FC<FilterComponentProps> => {
  if (!columnType || isIDColumn(columnName)) return TypeaheadDropdownFilter;

  return filterComponentRegistry[columnType] ?? TypeaheadDropdownFilter;
};

export { filterComponentRegistry, getFilterComponentForColumnType };
