import { ZoomTransform } from 'd3-zoom';
import React, { useCallback, useMemo } from 'react';
import { FC, useContext } from 'react';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import styled from 'styled-components';
import { PlannedRectsEntity } from 'src/state/Compliance/wapi_planogram_api';
import { ProductTag } from './ComplianceSummary';
import { SegmentRectMask, SegmentRects } from './SegmentRects';

interface ProductResultRectsProps {
  transform: ZoomTransform;
  setTransform: (transform: ZoomTransform) => void;
  dimensions: {
    width: number;
    height: number;
  };
  selectSegment: (index: number) => void;
  disableHover: boolean;
}

interface ProductResultMaskProps {
  dimensions: {
    width: number;
    height: number;
  };
  displayRects: PlannedRectsEntity[];
}

const TagPolygon = styled.polygon`
  cursor: pointer;
  fill-opacity: 1;
  &:hover {
    fill-opacity: 0;
  }
`;

const ProductResultRectMask: FC<ProductResultMaskProps> = ({
  dimensions,
  displayRects,
}: ProductResultMaskProps) => {
  const context = useContext(ComplianceStateContext)?.state;
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;

  const selectedBrandbankUuid = useMemo(() => {
    if (selectedProduct?.brandbankUuid) {
      return selectedProduct?.brandbankUuid;
    }
    // else if (hoverDiv?.rect.found_brandbank_uuid) {
    //   return hoverDiv?.rect.found_brandbank_uuid;
    // }
    return undefined;
  }, [selectedProduct]);

  if (!context?.kpiResult || dimensions.height <= 0 || dimensions.width <= 0)
    return null;
  return (
    <mask xmlns="http://www.w3.org/2000/svg" id="imageMask">
      <rect
        width={dimensions.width}
        height={dimensions.height}
        x={0}
        y={0}
      ></rect>
      {displayRects
        ?.filter(
          rect =>
            rect.facing_status != 'correct_item' || !!selectedBrandbankUuid
        )
        .map((el, index) => {
          const height = (el.y_max - el.y_min) * dimensions.height;
          if (
            !!selectedBrandbankUuid &&
            el.brandbank_uuid !== selectedBrandbankUuid &&
            el.found_brandbank_uuid !== selectedBrandbankUuid &&
            height > 0
          ) {
            return null;
          }

          return (
            <rect
              key={index}
              x={el.x_min * dimensions.width}
              y={el.y_min * dimensions.height}
              width={(el.x_max - el.x_min) * dimensions.width}
              height={(el.y_max - el.y_min) * dimensions.height}
              fill="black"
            ></rect>
          );
        })}
    </mask>
  );
};

const ProductResultRects: FC<ProductResultRectsProps> = ({
  dimensions,
  disableHover,
  transform,
  setTransform,
  selectSegment,
}: ProductResultRectsProps) => {
  const context = useContext(ComplianceStateContext)?.state;
  const selectedProduct = useContext(ComplianceStateContext)?.selectedProduct;
  const setSelectedProduct = useContext(ComplianceStateContext)
    ?.setSelectedProduct;
  const currentFilters = useContext(ComplianceStateContext)?.currentFilters;

  const plannedRects = context?.kpiResult?.planned_rects;

  const getPointsString = (rect: {
    x_max: number;
    x_min: number;
    y_max: number;
    y_min: number;
  }) => {
    return `${rect.x_min * dimensions.width} ${rect.y_min *
      dimensions.height} ${rect.x_max * dimensions.width} ${rect.y_min *
      dimensions.height} ${rect.x_max * dimensions.width} ${rect.y_max *
      dimensions.height} ${rect.x_min * dimensions.width} ${rect.y_max *
      dimensions.height}`;
  };

  const selectedBrandbankUuid = useMemo(() => {
    if (selectedProduct?.brandbankUuid) {
      return selectedProduct?.brandbankUuid;
    }
    return undefined;
  }, [selectedProduct]);

  const notOnShelf = useCallback(
    (brandbankUuid: string) => {
      const product = context?.kpiResult?.products?.find(
        el => el.brandbank_uuid === brandbankUuid
      );
      return !product || product.is_not_on_shelf;
    },
    [context]
  );

  const facingStatusFilters: string[] = useMemo(() => {
    if (!currentFilters || currentFilters.length === 0) return [];
    const result: string[] = [];
    if (currentFilters.indexOf(ProductTag.IncorrectPosition) >= 0) {
      result.push('wrong_item');
    }
    if (currentFilters.indexOf(ProductTag.EmptyFacings) >= 0) {
      result.push('empty');
    }
    if (currentFilters.indexOf(ProductTag.UnplannedItem) >= 0) {
      result.push('unplanned_item');
    }
    if (currentFilters.indexOf(ProductTag.UnrecognizedProduct) >= 0) {
      result.push('unrecognized_item');
    }
    return result;
  }, [currentFilters]);

  const displayRects = useMemo(() => {
    let result: PlannedRectsEntity[] = [];

    if (!plannedRects) return result;

    if (currentFilters && currentFilters.length > 0) {
      if (currentFilters.indexOf(ProductTag.NotOnShelf) >= 0) {
        result = result.concat(
          plannedRects.filter(el => notOnShelf(el.brandbank_uuid))
        );
      }

      if (facingStatusFilters.length > 0) {
        result = result.concat(
          plannedRects.filter(
            el => facingStatusFilters.indexOf(el.facing_status) >= 0
          )
        );
      }
    } else {
      result = plannedRects;
    }

    if (selectedBrandbankUuid === undefined) {
      result = result.filter(el => !(el.facing_status === 'correct_item'));
    } else {
      const extra_facings =
        selectedProduct?.kpiProductResults?.extra_facings ?? 0;

      result = result.filter(el => {
        if (
          extra_facings === 0 &&
          !selectedProduct?.kpiProductResults?.is_compliant
        ) {
          return (
            (el.found_brandbank_uuid === selectedBrandbankUuid &&
              el.brandbank_uuid != selectedBrandbankUuid) ||
            (el.found_brandbank_uuid !== selectedBrandbankUuid &&
              el.brandbank_uuid === selectedBrandbankUuid)
          );
        }
        return (
          el.brandbank_uuid === selectedBrandbankUuid ||
          el.found_brandbank_uuid === selectedBrandbankUuid
        );
      });
    }

    return result;
  }, [
    currentFilters,
    facingStatusFilters,
    notOnShelf,
    plannedRects,
    selectedBrandbankUuid,
    selectedProduct,
  ]);

  const getPolygonColors = (rect: PlannedRectsEntity) => {
    let color = '#00d9eb';
    let fill = `${color}33`;

    if (selectedBrandbankUuid) {
      color = '#ffffff';
      fill = 'transparent';
      if (rect.brandbank_uuid === selectedBrandbankUuid) {
        if (rect.facing_status === 'unrecognized_item') {
          color = '#EED202';
        } else if (notOnShelf(rect.brandbank_uuid)) {
          color = '#D0021B';
        }
      } else if (rect.found_brandbank_uuid === selectedBrandbankUuid) {
        color = '#00d9eb';
      }
    } else {
      switch (rect.facing_status) {
        case 'empty':
          color = '#F45C3A'; //#FCD6CE
          fill = `${color}22`;
          break;
        case 'correct_item':
          color = '#ffffff';
          fill = 'transparent';
          break;
        case 'unrecognized_item':
          color = '#EED202';
          fill = `${color}22`;
          break;
      }
    }
    return { color, fill };
  };

  if (!context?.kpiResult || dimensions.width <= 0 || dimensions.height <= 0)
    return null;
  return (
    <>
      <defs>
        <ProductResultRectMask
          dimensions={dimensions}
          displayRects={displayRects}
        />
        <SegmentRectMask dimensions={dimensions} />
      </defs>
      <rect
        width={dimensions.width}
        height={dimensions.height}
        x={0}
        y={0}
        fill="#262B2F99"
        mask="url(#imageMask)"
      />

      {!disableHover && (
        <SegmentRects
          dimensions={dimensions}
          selectSegment={selectSegment}
          transform={transform}
          setTransform={setTransform}
        />
      )}
      <g>
        {displayRects.map((el, index) => {
          const { color, fill } = getPolygonColors(el);
          if (
            selectedBrandbankUuid &&
            (el.brandbank_uuid !== selectedBrandbankUuid ||
              el.facing_status === 'correct_item') &&
            el.found_brandbank_uuid !== selectedBrandbankUuid
          ) {
            return null;
          }
          return (
            <TagPolygon
              key={index}
              points={getPointsString(el)}
              stroke={color}
              strokeWidth={3 / (isNaN(transform.k) ? 1 : transform.k)}
              fill={fill}
              strokeLinejoin="round"
              rx={4 / (isNaN(transform.k) ? 1 : transform.k)}
              ry={4 / (isNaN(transform.k) ? 1 : transform.k)}
              onClick={(e: React.MouseEvent) => {
                if (setSelectedProduct) {
                  const selectExpected =
                    el.facing_status === 'unrecognized_item' ||
                    el.facing_status === 'empty';
                  if (selectExpected) {
                    if (
                      selectedProduct &&
                      selectedProduct.brandbankUuid === el.brandbank_uuid
                    ) {
                      setSelectedProduct(undefined);
                    } else {
                      setSelectedProduct(el.brandbank_uuid);
                    }
                  } else {
                    if (
                      selectedProduct &&
                      selectedProduct.brandbankUuid === el.found_brandbank_uuid
                    ) {
                      setSelectedProduct(undefined);
                    } else {
                      setSelectedProduct(el.found_brandbank_uuid);
                    }
                  }
                }
                e.stopPropagation();
              }}
            />
          );
        })}
      </g>
    </>
  );
};

export { ProductResultRects };
