import produce, { Draft } from 'immer';
import { Actions, ActionTypes } from './actions';
import { AlbumPage, AlbumPageLoadStatus, State } from './types';

const initialState: State = {
  albumsById: {},
  albumsPages: {},
  totalCount: undefined,
  pageSize: 50,
  pagination: {
    from: 0,
    size: 50,
    countPerAlbumType: undefined,
  },

  albumAssetsById: {},
  albumAssetsPages: {},
  albumAssetCounts: {},
  sharedAlbum: undefined,
};

const newPage = (pageNumber: number) => ({
  pageNumber,
  ids: new Array<string>(),
  status: AlbumPageLoadStatus.LOADING,
});

const initializePage = (
  { albumsPages }: State,
  pageNumber: number
): AlbumPage => {
  const pageKey = `page${pageNumber}`;
  albumsPages[pageKey] = newPage(pageNumber);
  return albumsPages[pageKey];
};

const findOrInitializePage = (
  { albumsPages }: State,
  pageNumber: number
): AlbumPage => {
  const pageKey = `page${pageNumber}`;
  if (!albumsPages[pageKey]) albumsPages[pageKey] = newPage(pageNumber);
  return albumsPages[pageKey];
};

const initializeAssetsPage = (
  { albumAssetsPages }: State,
  { albumId, pageNumber }: { albumId: string; pageNumber: number }
): AlbumPage => {
  const pageKey = `album:${albumId};page${pageNumber}`;
  albumAssetsPages[pageKey] = newPage(pageNumber);
  return albumAssetsPages[pageKey];
};

const findOrInitializeAssetsPage = (
  { albumAssetsPages }: State,
  { albumId, pageNumber }: { albumId: string; pageNumber: number }
): AlbumPage => {
  const pageKey = `album:${albumId};page${pageNumber}`;
  if (!albumAssetsPages[pageKey])
    albumAssetsPages[pageKey] = newPage(pageNumber);
  return albumAssetsPages[pageKey];
};

const reducer = produce(
  (draft: Draft<State>, action: Actions): Draft<State> => {
    switch (action.type) {
      case ActionTypes.SetPageLoading: {
        initializePage(draft, action.payload.pageNumber);
        return draft;
      }

      case ActionTypes.PageLoaded: {
        const page = findOrInitializePage(draft, action.payload.pageNumber);
        page.status = AlbumPageLoadStatus.LOADED;
        draft.totalCount = action.payload.response.getTotalHits();
        draft.pagination = action.payload.response.getPagination()?.toObject();

        action.payload.response.getAlbumsList().forEach(album => {
          const id = album.getId();
          draft.albumsById[id] = album.toObject();
          page.ids.push(id);
        });

        return draft;
      }

      case ActionTypes.PageLoadFailed: {
        const page = findOrInitializePage(draft, action.payload.pageNumber);
        page.status = AlbumPageLoadStatus.FAILED;

        return draft;
      }

      case ActionTypes.RecordLoaded: {
        const { id, record } = action.payload;
        id
          ? (draft.albumsById[id] = record.toObject())
          : (draft.sharedAlbum = record.toObject());

        return draft;
      }

      case ActionTypes.SetAssetsPageLoading: {
        initializeAssetsPage(draft, action.payload);
        return draft;
      }

      case ActionTypes.AssetsPageLoaded: {
        const { albumId, response } = action.payload;
        const page = findOrInitializeAssetsPage(draft, action.payload);
        page.status = AlbumPageLoadStatus.LOADED;
        draft.albumAssetCounts[albumId] = response.getTotalHits();

        action.payload.response.getAssetDetailsList().forEach(asset => {
          const id = asset.getId();
          draft.albumAssetsById[id] = asset.toObject();
          page.ids.push(id);
        });

        return draft;
      }

      case ActionTypes.AssetsPageLoadFailed: {
        const page = findOrInitializeAssetsPage(draft, action.payload);
        page.status = AlbumPageLoadStatus.FAILED;

        return draft;
      }

      case ActionTypes.SetSmartAssetsPageLoading: {
        initializeAssetsPage(draft, action.payload);
        return draft;
      }

      case ActionTypes.SmartAssetsPageLoaded: {
        const { albumId, response } = action.payload;
        const page = findOrInitializeAssetsPage(draft, action.payload);
        page.status = AlbumPageLoadStatus.LOADED;
        draft.albumAssetCounts[albumId] = response.getTotalHits();
        action.payload.response.getAssetDetailsList().forEach(asset => {
          const id = asset.getId();
          draft.albumAssetsById[id] = asset.toObject();
          page.ids.push(id);
        });

        return draft;
      }

      case ActionTypes.SmartAssetsPageLoadFailed: {
        const page = findOrInitializeAssetsPage(draft, action.payload);
        page.status = AlbumPageLoadStatus.FAILED;

        return draft;
      }

      case ActionTypes.AddAlbum: {
        const { album } = action.payload;
        const albumObj = album.toObject();
        draft.albumsById[albumObj.id] = albumObj;

        return draft;
      }

      case ActionTypes.ResetPagination: {
        draft.albumsById = {};
        draft.albumsPages = {};
        draft.pagination = undefined;

        return draft;
      }

      default:
        return draft;
    }
  }
);

export { reducer, initialState };
