import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { breakpoint, Notification } from '@gsc/components';
import { AlbumsLayout, AlbumsPageHeader } from '../../components';
import { AlbumsGrid } from './Grid';

const NotificationWrapper = styled.div`
  position: relative;
`;

const StyledNotification = styled(Notification)`
  left: 0;
  margin: 0.8rem 2.4rem;
  position: fixed;
  width: calc(100% - 4.8rem);
  z-index: 12;

  ${breakpoint.lg`
    left: 50%;
    margin-left: -36rem;
    width: 72rem;
  `};
`;

const GridScreen: FC = () => {
  const [errorNotification, setErrorNotification] = useState('');

  return (
    <AlbumsLayout>
      {errorNotification && (
        <NotificationWrapper>
          <StyledNotification
            className="album-toast"
            dataTest="create-album-notification"
            onClick={() => {
              setErrorNotification('');
            }}
            status="negative"
          >
            {errorNotification}
          </StyledNotification>
        </NotificationWrapper>
      )}
      <AlbumsPageHeader>Albums</AlbumsPageHeader>
      <AlbumsGrid setErrorNotification={setErrorNotification} />
    </AlbumsLayout>
  );
};

export { GridScreen };
