import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ScaleLinear } from 'd3-scale';
import pluralize from 'pluralize';
import { max } from 'd3-array';
import { HistogramRangePickerBucket } from './types';

const HistogramWrapper = styled.div`
  z-index: 0;
`;

const StyledSvg = styled.svg`
  background-color: white;
  display: block;
`;

interface HistogramBucketBarProps {
  bucket: HistogramRangePickerBucket;
  scale: ScaleLinear<number, number>;
  selection: number[];
  maxValue: number;
  height: number;

  barBorderRadius?: number;
  barPadding?: number;
  barStyle?: object;
  onChange?: (selection: number[]) => void;
  selectedColor?: string;
  unselectedColor?: string;
}

const HistogramBucketBar: FC<HistogramBucketBarProps> = props => {
  const {
    bucket,
    scale,
    selection,
    maxValue,
    height,
    barPadding = 0,
    barBorderRadius = 1,
    barStyle = {},
    selectedColor = '#1948B8',
    unselectedColor = '#B5B8BE',
    onChange,
  } = props;

  const [selectionLowerBound, selectionUpperBound] = selection || [];

  const opacity = useMemo(() => {
    if (selectionLowerBound > bucket.x || selectionUpperBound < bucket.x0) {
      return 0;
    } else if (
      selectionLowerBound <= bucket.x0 &&
      selectionUpperBound >= bucket.x
    ) {
      // Entire block is covered
      return 1;
    } else if (
      selectionLowerBound > bucket.x0 &&
      selectionUpperBound > bucket.x
    ) {
      // Some of left block is covered
      return 1 - (selectionLowerBound - bucket.x0) / (bucket.x - bucket.x0);
    } else if (
      selectionUpperBound < bucket.x &&
      selectionLowerBound < bucket.x0
    ) {
      // Some of right block is covered
      return (selectionUpperBound - bucket.x0) / (bucket.x - bucket.x0);
    } else {
      // Partial match
      return (
        (selectionUpperBound - selectionLowerBound) / (bucket.x - bucket.x0)
      );
    }
  }, [bucket.x, bucket.x0, selectionLowerBound, selectionUpperBound]);

  const onClick = useCallback(() => {
    onChange?.([bucket.x0, bucket.x]);
  }, [bucket.x, bucket.x0, onChange]);

  return (
    <g transform={`translate(${scale(bucket.x0) + barPadding / 2} 0)`}>
      <rect
        fill={unselectedColor}
        width={scale(bucket.x) - scale(bucket.x0) - barPadding}
        height={(bucket.y / maxValue) * height}
        rx={barBorderRadius}
        ry={barBorderRadius}
        x={0}
      />

      <rect
        fill={selectedColor}
        onClick={onClick}
        style={{ opacity, cursor: 'pointer', ...barStyle }}
        width={scale(bucket.x) - scale(bucket.x0) - barPadding}
        height={(bucket.y / maxValue) * height}
        rx={barBorderRadius}
        ry={barBorderRadius}
        x={0}
      />

      <title>
        {bucket.x0} to {bucket.x} - {bucket.y} {pluralize('photo', bucket.y)}
      </title>
    </g>
  );
};

interface HistogramProps {
  data: HistogramRangePickerBucket[];
  scale: ScaleLinear<number, number>;
  width: number;
  height: number;
  selection: number[];
  histogramStyle?: object;
  histogramPadding?: number;
  barPadding?: number;
  barBorderRadius?: number;
  barStyle?: object;
  selectedColor?: string;
  unselectedColor?: string;
  onChange?: (selection: number[]) => void;
}

const Histogram = (props: HistogramProps) => {
  const { data, width, height, histogramStyle } = props;
  const maxValue = useMemo(() => max(data, ({ y }) => +y), [data]) ?? 0;

  return (
    <HistogramWrapper>
      <StyledSvg width={width} height={height} style={histogramStyle}>
        <g transform={`translate(0, ${height})`}>
          <g transform="scale(1,-1)">
            {data.map((bucket, i) => (
              <HistogramBucketBar
                key={i}
                bucket={bucket}
                maxValue={maxValue}
                {...props}
              />
            ))}
          </g>
        </g>
      </StyledSvg>
    </HistogramWrapper>
  );
};

export { Histogram };
