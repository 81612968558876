import styled from 'styled-components';

const Loader = styled.div`
  animation: spinner 0.8s linear infinite;
  border-top: 0.25rem solid rgba(0, 0, 0, 0.08);
  border-right: 0.25rem solid rgba(0, 0, 0, 0.08);
  border-bottom: 0.25rem solid rgba(0, 0, 0, 0.08);
  border-left: 0.25rem solid rgba(0, 0, 0, 0.4);
  position: relative;
  text-indent: -9999em;

  &,
  &::after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { Loader };
