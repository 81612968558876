import { ZoomTransform } from 'd3-zoom';
import { Results } from '../Compliance/wapi_planogram_api';

export enum ActionTypes {
  SetRealogramTransform = 'app/compliance/complianceViewer/SetRealogramTransform',
  SetPlanogramTransform = 'app/compliance/complianceViewer/SetPlanogramTransform',
  SetRealogramDimensions = 'app/compliance/complianceViewer/SetRealogramDimensions',
  SetPlanogramDimensions = 'app/compliance/complianceViewer/SetPlanogramDimensions',
}

interface SetRealogramTransform {
  payload: { transform: ZoomTransform };
  type: ActionTypes.SetRealogramTransform;
}

interface SetPlanogramTransform {
  payload: { transform: ZoomTransform };
  type: ActionTypes.SetPlanogramTransform;
}

interface SetRealogramDimensions {
  payload: { width: number; height: number; kpiResult: Results };
  type: ActionTypes.SetRealogramDimensions;
}

interface SetPlanogramDimensions {
  payload: { width: number; height: number };
  type: ActionTypes.SetPlanogramDimensions;
}

export type Actions =
  | SetRealogramTransform
  | SetPlanogramTransform
  | SetRealogramDimensions
  | SetPlanogramDimensions;
