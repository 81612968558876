import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { H3, TertiaryButton } from '@gsc/components';
import { ChevronDownBold, ChevronUpBold } from '@gsc/icons/react';
import { isEmpty } from '../../../../../../shared/utils';
import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { Details, DetailsTitle } from './components';
import { useQueryParams } from '../../../../../../state/QueryParams/hooks';

const TagPropertyList = styled.ul`
  padding: 0.8rem 0 0;

  & > li {
    color: #6b717c;
    padding: 0.4rem 0;

    & > .property-value {
      float: right;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
`;

const TagList = styled.ul`
  margin: 0 -3.2rem 3.2rem;
  padding-top: 0.1rem;

  & > li {
    padding: 1.6rem 3.2rem;
    font-size: 1.4rem;
    color: #222b3b;
    cursor: pointer;
    border-bottom: 0.1rem solid #f3f3f4;

    &:hover {
      background: #f3f3f4;
    }

    &:first-child {
      border-top: 0.1rem solid #f3f3f4;
    }

    &.active {
      background: #e6e7e9;
    }

    & > .tag-details-toggle {
      color: #222b3b;
      float: right;
      padding: 1.2rem;
      margin-top: -1rem;
      margin-right: -2rem;

      &:hover,
      &:focus {
        background: transparent !important;
        border-color: transparent;
        box-shadow: none;
        color: #222b3b;
      }

      & > svg {
        margin-right: 0;
        width: 1.2rem;
      }
    }
  }
`;

interface Shelf {
  index: number;
  tags: Record.AsObject[];
}

interface ShelvedProductTagsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
}

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const repBrandAssignments = 'rep_brand_assignments';
const brand = 'brand';
const brandFamily = 'brand_family';
const supplier = 'supplier';
const tagEdit = 'Tag Edit';
const ignored = 'Ignored';

const determineName = (record: Record.AsObject): string => {
  const rba = JSON.parse(getProperty(record, repBrandAssignments) ?? '{}');

  if (isEmpty(rba)) {
    return getProperty(record, 'name') ?? 'Unknown Product';
  }

  if (rba.ignored == 'true') {
    return tagEdit + ': ' + ignored;
  }

  if (rba.ignored == 'false') {
    return tagEdit + ': ' + rba.brand;
  }

  return 'Unknown Product';
};

const determineValue = (record: Record.AsObject, name: string): string => {
  let value = '';
  const rba = JSON.parse(getProperty(record, repBrandAssignments) ?? '{}');

  if (isEmpty(rba)) {
    return getProperty(record, name);
  }

  switch (name) {
    case brand:
      value = rba.brand;
      break;

    case brandFamily:
      value = rba.brand_family;
      break;

    case supplier:
      value = rba.supplier;
      break;

    default:
      value = getProperty(record, name);
  }

  return value;
};

const ShelvedProductTagsPanel: FC<ShelvedProductTagsPanelProps> = ({
  assetDetail,
}) => {
  const currentSpace = useCurrentSpaceObj();

  const displayFields = useMemo(
    () =>
      currentSpace?.config?.assetDetailConfig?.tablesList
        ?.find(({ tableName }) => tableName === 'image_recognition_tags')
        ?.columnConfigsList?.map(({ columnName }) => columnName) ?? [],
    [currentSpace]
  );

  const tags = useMemo(
    () =>
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_tags')
        ?.recordsList?.filter(
          tag => !parseInt(getProperty(tag, 'deleted_at'))
        ) ?? [],
    [assetDetail]
  );

  const { selectedTagId, setSelectedTagId } = useQueryParams();

  const shelves = useMemo(
    () =>
      Object.values(
        tags.reduce<{ [key: string]: Shelf }>((result, tag) => {
          const positionProperty = getProperty(tag, 'position');

          if (positionProperty) {
            const [shelfIndex] = positionProperty.split(',').map(parseInt);

            if (!result[`${shelfIndex}`])
              result[`${shelfIndex}`] = { index: shelfIndex, tags: [] };

            result[`${shelfIndex}`].tags.push(tag);
          }

          return result;
        }, {})
      ).map(shelf => {
        return {
          ...shelf,
          tags: shelf.tags.sort((a, b) => {
            const positionPropertyA = getProperty(a, 'position');
            const positionPropertyB = getProperty(b, 'position');

            if (!positionPropertyA || !positionPropertyB) return 0;

            const columnIndexA = parseInt(positionPropertyA.split(',')[1]);
            const columnIndexB = parseInt(positionPropertyB.split(',')[1]);

            return columnIndexA - columnIndexB;
          }),
        };
      }),
    [tags]
  );

  return (
    <Details>
      <DetailsTitle>
        {pluralize('Product', tags.length, true)} Tagged
      </DetailsTitle>

      <ul>
        {shelves.map(shelf => (
          <li key={shelf.index}>
            {shelf.tags.map((tag, i) => {
              const sectionType = getProperty(tag, 'section_type');
              let count = 0;
              if (sectionType !== 'wine' || count > 0) {
                count++;
                return <H3 key={i}>Shelf {shelf.index}</H3>;
              }
              return <div key={i}></div>;
            })}
            <TagList>
              {shelf.tags.map((tag, i) => {
                const tagId = getProperty(tag, 'id');
                const active = tagId === selectedTagId;
                const ToggleIcon = active ? ChevronUpBold : ChevronDownBold;

                return (
                  <li
                    key={i}
                    className={classnames({ active, 'ir-tag-list-item': true })}
                    onClick={() => setSelectedTagId(active ? null : tagId)}
                    data-tag-id={tagId}
                  >
                    {displayFields.length > 0 && (
                      <TertiaryButton className="tag-details-toggle">
                        <ToggleIcon />
                      </TertiaryButton>
                    )}

                    {determineName(tag)}

                    {active && (
                      <TagPropertyList>
                        {displayFields.map(propertyName => {
                          if (propertyName === 'name') return null;

                          const name = propertyName
                            .split('_')
                            .map(w => w[0].toUpperCase() + w.substring(1))
                            .join(' ');

                          const value = determineValue(tag, propertyName);

                          if (!value) return null;
                          return (
                            <li key={propertyName}>
                              {name}:{' '}
                              <span className="property-value">{value}</span>
                            </li>
                          );
                        })}
                      </TagPropertyList>
                    )}
                  </li>
                );
              })}
            </TagList>
          </li>
        ))}
      </ul>
    </Details>
  );
};

export { ShelvedProductTagsPanel };
