import { Dispatch, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Actions, ActionTypes } from './actions';
import { api } from './api';
import { useAuth, useMetadata } from '../Auth';
import { SpacesStatus, State } from './types';

const useGetSpacesList = (dispatch: Dispatch<Actions>) => {
  const { status, types } = useAuth();
  const getMetadata = useMetadata();

  useEffect(
    function getSpaces() {
      if (status !== types.AuthStatus.AUTHORIZED) {
        return;
      }
      dispatch({
        type: ActionTypes.SetStatus,
        payload: {
          status: SpacesStatus.LOADING,
        },
      });

      getMetadata()
        .then(metadata => api(metadata))
        .then(({ listSpaces }) => listSpaces())
        .then(spaces => {
          dispatch({
            type: ActionTypes.InitializeSpaces,
            payload: {
              spaces,
            },
          });
        });
    },
    [dispatch, getMetadata, status, types]
  );
};

const useSetDefaultSpaceToRoute = (
  state: State,
  dispatch: Dispatch<Actions>
) => {
  const { status, types } = useAuth();
  const match = useRouteMatch<{ spaceId: string }>('/spaces/:spaceId');
  const id = match?.params.spaceId;

  useEffect(() => {
    if (status !== types.AuthStatus.AUTHORIZED) {
      return;
    } else if (id && state.spaces[id] && id !== state.defaultSpace) {
      dispatch({
        type: ActionTypes.SetDefaultSpace,
        payload: { id },
      });
    }
  }, [
    dispatch,
    id,
    state.spaces,
    state.defaultSpace,
    status,
    types.AuthStatus.AUTHORIZED,
  ]);
};

export { useGetSpacesList, useSetDefaultSpaceToRoute };
