import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import { color, zIndex } from '@gsc/components';
import classnames from 'classnames';
import download from 'downloadjs';
import { Swipeable } from 'react-swipeable';

import { PhotoViewer } from './shared/components';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { PhotoDetailsSidebar } from './shared/components/PhotoDetailsSidebar';
import { ImagesHostRegex } from '../../shared/components/CdnImageWrapper';
import { SetCdnOptions } from '../../shared/components/CdnImage';
import { usePhotosState } from '../../../../state/Photos';
import { breakpoint } from '../../style/breakpoint';

const DisableBodyScroll = createGlobalStyle`
  body.details-active {
    height: 100vh;
    overflow: hidden;
  }
`;
interface DetailsTakeoverProps extends HTMLAttributes<any> {
  coordinates: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  windowHeight: number;
  windowWidth: number;
}

const DetailsTakeover = styled.div(
  ({ coordinates, windowHeight, windowWidth }: DetailsTakeoverProps) => `
  background-color: ${color.ui05};
  height: ${windowHeight / 10}rem;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform-origin: center;
  width: ${windowWidth / 10}rem;
  z-index: ${zIndex.delta};

  &.animation-ended {
    overflow-y: auto;
  }

  &.animate {
    animation-duration: 0.25s;
    animation-name: takeover;
    animation-timing-function: ease-in;
  }

  @keyframes takeover {
    from {
      height: ${(coordinates.height - 130) / 10}rem;
      transform: translate3d(${coordinates.x / 10}rem, ${coordinates.y /
    10}rem, 0);
      width: ${coordinates.width / 10}rem;
    }

    to {
      height: ${windowHeight / 10}rem;
      transform: translate3d(0, 0, 0);
      width: ${windowWidth / 10}rem;
    }
  }
`
);

const DetailsLayout = styled.div`
  height: 100%;
  ${breakpoint.lg`
  & {
    display: flex;
  }
`}
`;

const StyledPhotoViewer = styled(PhotoViewer)`
  ${breakpoint.lg`
    & {
      flex: 1;
    }
  `}
`;

const StyledSwipeable = styled(Swipeable)<{ showDetails?: boolean }>`
  height: ${p => (p.showDetails ? '50%' : '100%')};
  ${breakpoint.lg`
  & {
    height: 100%;
    }
  `}
`;

interface DetailsProps {
  assetDetail: AssetDetail.AsObject | undefined;
  assetDetailLoading: boolean;
  clickedCoordinates?:
    | {
        height: number;
        width: number;
        x: number;
        y: number;
      }
    | undefined;
  goToNextPhoto: () => void;
  goToPreviousPhoto: () => void;
  hasPreviousPhoto: boolean;
}

const Details: FC<DetailsProps> = ({
  assetDetail,
  assetDetailLoading,
  clickedCoordinates,
  goToNextPhoto,
  goToPreviousPhoto,
  hasPreviousPhoto,
}) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [animationEnded, setAnimationEnded] = useState(false);
  const [downloadingAsset, setDownloadingAsset] = useState(false);
  const largerWindow = windowWidth >= 1020;
  const showDetails = usePhotosState().showDetails;
  const isDesktop = !isMobileDevice() && largerWindow;

  const handleAnimationEnd = () => setAnimationEnded(true);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') goToPreviousPhoto();
      if (e.key === 'ArrowRight') goToNextPhoto();
    },
    [goToNextPhoto, goToPreviousPhoto]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const photoDetail: AssetDetail.AsObject = assetDetail || {
    customerId: '',
    id: '',
    assetUrl: '',
    altAssetUrlsList: [],
    createdAtTime: 0,
    updatedAtTime: 0,
    versionId: 0,
    propertiesList: [],
    referencesList: [],
  };

  const downloadPic = () => {
    setDownloadingAsset(true);

    const imageUrl = photoDetail && photoDetail.assetUrl;
    let transformedUrl = SetCdnOptions(imageUrl, 'format=jpg');

    // transform using filepicker params if not a GSC images url
    const regex = new RegExp(ImagesHostRegex, 'i');
    if (!regex.exec(imageUrl || '')) {
      const [domain, path] = imageUrl.split('.com');
      transformedUrl = `${domain}.com/output=format:jpg${path}`;
    }

    fetch(transformedUrl)
      .then(responseUrl => responseUrl.blob())
      .then(blob => download(blob, `${assetDetail?.id}.jpeg`, 'image/jpeg'));
    setDownloadingAsset(false);
  };

  return (
    <DetailsTakeover
      className={classnames({
        animate: clickedCoordinates?.x !== 0,
        'animation-ended': animationEnded || clickedCoordinates?.x === 0,
      })}
      coordinates={clickedCoordinates || { height: 0, width: 0, x: 0, y: 0 }}
      onAnimationEnd={handleAnimationEnd}
      windowHeight={windowHeight}
      windowWidth={windowWidth}
    >
      <DisableBodyScroll />
      <Helmet>
        <title>PhotoWorks - Detail page</title>
        <meta name="description" content="Photo information here" />
      </Helmet>
      <DetailsLayout>
        <StyledSwipeable
          onSwipedLeft={goToNextPhoto}
          onSwipedRight={goToPreviousPhoto}
          showDetails={showDetails}
          style={{ flex: 1, display: 'flex' }}
        >
          <StyledPhotoViewer
            assetDetail={assetDetail}
            downloadPic={downloadPic}
            isDesktop={isDesktop}
            goToNextPhoto={goToNextPhoto}
            goToPreviousPhoto={goToPreviousPhoto}
            hasPreviousPhoto={hasPreviousPhoto}
          />
        </StyledSwipeable>
        <PhotoDetailsSidebar
          assetDetail={assetDetail}
          downloadingAsset={downloadingAsset}
          downloadPic={downloadPic}
          isDesktop={isDesktop}
          loading={assetDetailLoading}
        />
      </DetailsLayout>
    </DetailsTakeover>
  );
};

export { Details };
