export interface AccessToken {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
}

export interface Auth0User {
  email: string;
  ['https://gospotcheck.com/user/id']: number;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  nickname: string;
  picture: string;
  updatedAt: string;
}

export enum AuthStatus {
  INVALID = 'INVALID',
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  AUTHORIZED = 'AUTHORIZED',
  ERROR = 'ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export interface State {
  status: AuthStatus;
  token: string;
  tokenExpiration: number;
  user: User;
  hasGscAccess: boolean;
  isApertureAdmin: boolean;
  isSpaceAdmin: boolean;
}
