import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  DatePicker,
  elevation,
  font,
  Input,
  Label,
  OutsideClick,
  Radio,
  PrimaryButton,
  SecondaryButton,
  zIndex,
} from '@gsc/components';
const chevronDownBoldIconUrl = require('@gsc/icons/svg/chevron-down-bold.svg')
  .default as string;
import moment from 'moment';
import classnames from 'classnames';
import { isInclusivelyAfterDay } from 'react-dates';

import { FormContext } from '../../../../shared/components/FormContext';
import {
  SourceIdType,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { paths } from '../../../../paths';
import { TierTooltip } from './TierTooltip';

const Form = styled.form`
  max-width: 60rem;
`;

const RadioGroup = styled.div`
  margin-bottom: 2.4rem;
`;

const RadioGroupTitle = styled.div`
  color: ${color.ui50};
  font-weight: ${font.weight.medium};
  margin-bottom: 0.8rem;
`;

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLabel = styled(Label)`
  padding-bottom: 0;
`;

const DropdownInput = styled(Input)`
  input {
    background: ${color.ui01} url(${chevronDownBoldIconUrl}) no-repeat right
      1rem center / 1.2rem 1.2rem;
    margin: 1rem 0;
    text-transform: capitalize;
  }

  > div {
    display: none;
  }

  ${breakpoint.md`
    & {
      width: 28rem;
    }
  `}
`;

const DropdownContainer = styled.div`
  ${elevation['12']};
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  left: 0;
  overflow: hidden;
  position: absolute;
  right: auto;
  top: 90%;
  width: 100%;
  z-index: ${zIndex.hotel};

  ${breakpoint.lg`
    & {
      min-width: 28rem;
      width: auto;
    }
  `}
`;

const ListContainer = styled.ul`
  max-height: 20rem;
  overflow-y: auto;
`;

const ListItem = styled.li`
  cursor: pointer;
  display: block;
  padding: 0.8rem 1.5rem;
  width: 100%;

  &.selected,
  &:hover {
    background-color: ${color.ui10};
  }
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.md`
    & {
      flex-direction: row;
    }
  `}
`;

const InnerTierWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;

  ${breakpoint.md`
    align-items: unset;
    flex-direction: row;
  `}
`;

const StyledDatePicker = styled(DatePicker)`
  ${breakpoint.md`
    & {
      margin-left: 2rem;
      margin-top: 0.2rem;
      width: 28rem;
    }
  `}
`;

const StyledInput = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  ${breakpoint.md`
    & {
      width: 28rem;
    }
  `}
`;

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  a,
  button {
    margin-left: 1.6rem;
  }
`;

interface NewSpaceFormProps {
  isInvalid: boolean;
}

const NewSpaceForm: FC<NewSpaceFormProps> = ({ isInvalid }) => {
  const { errors, handleChange, handleSubmit, setValues, values } = useContext(
    FormContext
  );
  const [isTierOpen, setIsTierOpen] = useState(false);
  const [trialDate, setTrialDate] = useState(values.tier.trialEndDate);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Tab') {
      setIsTierOpen(false);
    }
  };

  const sourceIdType = parseInt(values.sourceIdType, 10);
  const tiers = SpaceTierType;

  const tierKeys = Object.keys(tiers).map(tier => {
    const statArray = tier.split('_');
    return statArray
      .slice(3, tier.length)
      .join(' ')
      .toLowerCase();
  });

  const setTierValues = ({ tier }: any) => {
    setValues({ tier });
    setIsTierOpen(false);
  };

  useEffect(() => {
    if (moment.isMoment(trialDate)) {
      setTierValues({
        tier: {
          trialEndDate: parseInt(moment(trialDate).format('x')),
          type: SpaceTierType.SPACE_TIER_TYPE_TRIAL,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialDate]);

  return (
    <Form onSubmit={handleSubmit}>
      <RadioGroup>
        <RadioGroupTitle>Authorizable type</RadioGroupTitle>
        <Radio
          checked={sourceIdType === SourceIdType.SOURCE_ID_TYPE_COMPANY_ID}
          id="authorizable-type-company"
          name="sourceIdType"
          onChange={handleChange}
          value={SourceIdType.SOURCE_ID_TYPE_COMPANY_ID}
        >
          Company
        </Radio>
        <Radio
          checked={
            sourceIdType === SourceIdType.SOURCE_ID_TYPE_COMPANY_GROUP_ID
          }
          id="authorizable-type-company-group"
          name="sourceIdType"
          onChange={handleChange}
          value={SourceIdType.SOURCE_ID_TYPE_COMPANY_GROUP_ID}
        >
          Company group
        </Radio>
      </RadioGroup>

      <StyledInput
        aria-label="company-id"
        errors={isInvalid ? 'Enter a valid ID' : errors.sourceId}
        id="sourceId"
        label={
          sourceIdType === SourceIdType.SOURCE_ID_TYPE_COMPANY_ID
            ? 'Company ID'
            : 'Company Group ID'
        }
        name="sourceId"
        onChange={handleChange}
        type="number"
        value={values.sourceId}
      />

      <TierContainer>
        <InnerTierWrapper>
          <DropdownWrapper>
            <OutsideClick onOutsideClick={() => setIsTierOpen(false)}>
              <StyledLabel id="tier">Tier</StyledLabel>
              <DropdownInput
                id="tier"
                onChange={() => {}}
                onClick={() => setIsTierOpen(true)}
                onFocus={() => setIsTierOpen(true)}
                onKeyDown={onKeyDown}
                placeholder="Tier"
                value={tierKeys[values.tier.type]}
              />

              {isTierOpen && (
                <DropdownContainer>
                  <ListContainer>
                    <ListItem
                      className={classnames({
                        selected:
                          values.tier.type ===
                          SpaceTierType.SPACE_TIER_TYPE_LITE,
                      })}
                      onClick={() => {
                        setTierValues({
                          tier: {
                            trialEndDate: 0,
                            type: SpaceTierType.SPACE_TIER_TYPE_LITE,
                          },
                        });
                      }}
                      value={SpaceTierType.SPACE_TIER_TYPE_LITE}
                    >
                      Lite
                    </ListItem>
                    <ListItem
                      className={classnames({
                        selected:
                          values.tier.type ===
                          SpaceTierType.SPACE_TIER_TYPE_TRIAL,
                      })}
                      onClick={() => {
                        setTierValues({
                          tier: {
                            trialEndDate: parseInt(
                              moment(trialDate).format('x')
                            ),
                            type: SpaceTierType.SPACE_TIER_TYPE_TRIAL,
                          },
                        });
                      }}
                      value={SpaceTierType.SPACE_TIER_TYPE_TRIAL}
                    >
                      Trial
                    </ListItem>
                    <ListItem
                      className={classnames({
                        selected:
                          values.tier.type ===
                          SpaceTierType.SPACE_TIER_TYPE_STANDARD,
                      })}
                      onClick={() => {
                        setTierValues({
                          tier: {
                            trialEndDate: 0,
                            type: SpaceTierType.SPACE_TIER_TYPE_STANDARD,
                          },
                        });
                      }}
                      value={SpaceTierType.SPACE_TIER_TYPE_STANDARD}
                    >
                      Standard
                    </ListItem>
                    <ListItem
                      className={classnames({
                        selected:
                          values.tier.type ===
                          SpaceTierType.SPACE_TIER_TYPE_UNLIMITED,
                      })}
                      onClick={() => {
                        setTierValues({
                          tier: {
                            trialEndDate: 0,
                            type: SpaceTierType.SPACE_TIER_TYPE_UNLIMITED,
                          },
                        });
                      }}
                      value={SpaceTierType.SPACE_TIER_TYPE_UNLIMITED}
                    >
                      Unlimited
                    </ListItem>
                  </ListContainer>
                </DropdownContainer>
              )}
            </OutsideClick>
          </DropdownWrapper>
          <TierTooltip />
        </InnerTierWrapper>
        <StyledDatePicker
          disabled={values.tier.type !== SpaceTierType.SPACE_TIER_TYPE_TRIAL}
          errors={errors.tier}
          id="trial-end-date"
          initialDate={trialDate && moment(trialDate)}
          isOutsideRange={(day: moment.Moment) =>
            !isInclusivelyAfterDay(day, moment())
          }
          label="Trial end date"
          onChange={(dateSelected: moment.Moment) =>
            setTrialDate(dateSelected.endOf('day'))
          }
          name="trial-end-date"
          placeholder={trialDate === null ? '' : moment(trialDate).format('L')}
        />
      </TierContainer>

      <FormButtons>
        <SecondaryButton status="neutral" to={paths.admin.root}>
          Cancel
        </SecondaryButton>

        <PrimaryButton type="submit">Create Space</PrimaryButton>
      </FormButtons>
    </Form>
  );
};

export { NewSpaceForm };
