import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  ButtonMenu,
  DropdownMenuLink,
} from '../../../../../../../shared/components';
import { AdvancedOptionsMenuIcon } from '../../../../../icons/Icons';

const StyledDropdownMenuLink = styled(DropdownMenuLink)`
  border-left: 0;
  padding: 0;
`;

interface MenuItemsProps {
  children: React.ReactNode;
  dataTest?: string;
  disabled?: boolean;
  onSelect?(): any;
}

interface SummaryAdvancedOptionsMenuProps {
  showJsonModal: () => void;
}

export const SummaryAdvancedOptionsMenu: FC<SummaryAdvancedOptionsMenuProps> = ({
  showJsonModal,
}) => {
  const menuItems = () => {
    const showJson: MenuItemsProps = {
      children: (
        <StyledDropdownMenuLink
          as="div"
          data-test="summary-advanced-options-menu-show-json"
          key="show-json"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            showJsonModal();
          }}
        >
          Get result as JSON
        </StyledDropdownMenuLink>
      ),
      onSelect: () => showJsonModal(),
    };
    return [showJson];
  };

  return (
    <ButtonMenu
      dataTest="summary-advanced-options-menu"
      alignRight={true}
      icon={<AdvancedOptionsMenuIcon />}
      items={menuItems()}
      status="neutral"
      variant="tertiary"
    />
  );
};
