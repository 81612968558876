import React, { CSSProperties, FC, memo } from 'react';
import pluralize from 'pluralize';
import { areEqual } from 'react-window';
import { queuePageForLoading } from '../../../../Photos/screens/PhotoGrid/utils';
import { AlbumGridCell as AlbumGridCellItem, ItemData } from '../../../types';
import { PreviewImages } from '../../../PreviewImages';
import { AlbumPageLoadStatus } from '../../../../../state/Albums';
import {
  AlbumGridCell,
  AlbumTitle,
  AlbumSubTitle,
  ShareIconWrapper,
  ShareFlag,
  TextLoading,
  AlbumHoverOverlay,
} from '../../../components';
import { AlbumType } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

interface AlbumDataProps {
  cell: AlbumGridCellItem;
  sharedAlbum: boolean;
  smartAlbum: boolean;
}

const AlbumData: FC<AlbumDataProps> = ({ cell, sharedAlbum, smartAlbum }) => {
  return (
    <>
      <AlbumTitle>{cell.album?.name ?? 'Untitled album'}</AlbumTitle>
      <AlbumSubTitle>
        {!smartAlbum && pluralize('photo', cell.album?.assetCount ?? 0, true)}
        {sharedAlbum && (
          <ShareIconWrapper>
            <ShareFlag />
          </ShareIconWrapper>
        )}
      </AlbumSubTitle>
    </>
  );
};

export interface AlbumRendererProps {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: ItemData;
}

const AlbumRenderer = memo(
  ({ columnIndex, rowIndex, style, data }: AlbumRendererProps) => {
    const { getPage, loadPage, pageSize, rows } = data;
    const cell = rows[rowIndex]?.[columnIndex];
    if (!cell) return null;
    const sharedAlbum = cell.album?.shareSettings?.shareToken !== undefined;
    const smartAlbum = cell.album?.albumType === AlbumType.ALBUM_TYPE_SMART;

    const width = style.width as number;
    const boxSize = (width ?? 300) - 32;
    const pageNumber = Math.floor((cell.index ?? 0) / pageSize);
    const page = getPage(pageNumber);

    if (!page) {
      queuePageForLoading(pageNumber, () => loadPage(pageNumber));
    } else if (page.status === AlbumPageLoadStatus.LOADED) {
      const previewUrls: string[] = cell.album?.previewUrlsList ?? [];

      return (
        <AlbumGridCell style={style} onClick={cell.onClick}>
          <PreviewImages
            boxSize={boxSize}
            previewUrls={previewUrls}
            smartAlbum={smartAlbum}
          />
          <AlbumHoverOverlay style={{ height: boxSize - 2 }} />
          <AlbumData
            cell={cell}
            sharedAlbum={sharedAlbum}
            smartAlbum={smartAlbum}
          />
        </AlbumGridCell>
      );
    }

    return (
      <AlbumGridCell style={style}>
        <PreviewImages boxSize={boxSize} />
        <TextLoading style={{ marginTop: '1.8rem' }} />
        <TextLoading
          style={{ width: '20%', height: '1.4rem', marginTop: '1.4rem' }}
        />
      </AlbumGridCell>
    );
  },
  areEqual
);

AlbumRenderer.displayName = 'AlbumRenderer';

export { AlbumRenderer };
