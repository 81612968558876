import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  breakpoint,
  H4,
  Input,
  PrimaryButton,
  TertiaryButton,
} from '@gsc/components';
import { Delete } from '@gsc/icons/react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSpacesAPI, useSpacesState } from '../../../../state/Spaces';
import { User } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

interface EditSpaceAdminsFormProps {
  setResponseError(args: any): any;
  setValues: (obj: { [key: string]: any }) => void;
}

const StyledSection = styled.section`
  margin: 3.2rem 0;

  ${breakpoint.md`
    & {
      display: flex;
      flex-direction: column;
    }
  `}
`;

const SectionHeader = styled(H4)`
  margin-bottom: 1.6rem;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;

  ${breakpoint.md`
    & {
      flex-direction: row;
    }
  `}
`;

const StyledInput = styled(Input)`
  width: 100%;

  ${breakpoint.md`
    & {
      margin-right: 1.6rem;
      width: 28rem;
    }
  `}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

const SubmitButton = styled(PrimaryButton)`
  margin-left: auto;

  ${breakpoint.md`
    & {
      margin-left: 0;
    }
  `}
`;

const StyledDataTable = styled(DataTable)`
  > header {
    display: none;
  }

  > div > .rdt_Table {
    width: calc(100% - 4.8rem);

    ${breakpoint.md`
      width: 100%;
    `}
  }

  > div
    > .rdt_Table
    > .rdt_TableBody
    > .rdt_TableRow
    > .rdt_TableCell:first-child,
  > div
    > .rdt_Table
    > .rdt_TableHead
    > .rdt_TableHeadRow
    > .rdt_TableCol:first-child {
    max-width: 12rem;
    padding-right: 0;
  }

  > div
    > .rdt_Table
    > .rdt_TableBody
    > .rdt_TableRow
    > .rdt_TableCell:last-child,
  > div
    > .rdt_Table
    > .rdt_TableHead
    > .rdt_TableHeadRow
    > .rdt_TableCol:last-child {
    margin-right: 1.6rem;
    max-width: 2rem;
    min-width: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
` as typeof DataTable;

const StyledTertiaryButton = styled(TertiaryButton)`
  border: none;
  padding: 0;

  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledDeleteIcon = styled(Delete)`
  width: 2rem;
`;

const EditSpaceAdminsForm: FC<EditSpaceAdminsFormProps> = ({
  setResponseError,
  setValues,
}) => {
  const match = useRouteMatch<{ spaceId: string }>('/admin/:spaceId');
  const spacesAPI = useSpacesAPI();
  const { spaces } = useSpacesState();
  const currentSpace = spaces[match?.params.spaceId || ''];
  const [spaceUsersList, setSpaceUsersList] = useState<User.AsObject[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [spaceAdminInput, setSpaceAdminInput] = useState('');
  const [errors, setErrors] = useState('');

  useEffect(() => {
    let mounted = true;
    spacesAPI()
      .then(({ getSpaceAdmins }) => getSpaceAdmins(currentSpace.id))
      .then(response => {
        if (mounted) {
          setSpaceUsersList(response.toObject().adminsList);
        }
      });
    return () => {
      mounted = false;
    };
  }, [currentSpace.id, spacesAPI]);

  const createSpaceIdsArr = (adminIdString: string) =>
    adminIdString.includes(',')
      ? adminIdString.replace(/ /g, '').split(',')
      : adminIdString.split(' ');

  const validateAdmin = (adminIdString: string) => {
    const idsArr = createSpaceIdsArr(adminIdString);
    const filteredIds = idsArr.filter(id => id.length > 0);
    const checkForNumbers = filteredIds.every((id: string) =>
      /^(\s*|\d+)$/.test(id)
    );

    if (!checkForNumbers) {
      setErrors('Enter GSC User ID');
    } else setErrors('');
  };

  const handleInputChange = (event: FormEvent) => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const { value } = target;
    validateAdmin(value);
    setSpaceAdminInput(value);
    setResponseError(undefined);
  };

  const getUserInfo = (inputId: string): Promise<User.AsObject | void> => {
    return spacesAPI()
      .then(({ getSpaceUser }) => {
        return getSpaceUser(currentSpace.id, inputId);
      })
      .then(response => {
        const responseUserObj = response?.getUser()?.toObject();
        if (!responseUserObj) {
          throw new Error('User not found');
        }
        return responseUserObj;
      })
      .catch(e => {
        const invalidId = e.message
          .split(' ')
          .slice(1, 2)
          .toString();
        setResponseError({ code: e.code, id: invalidId });
        setIsSaving(false);
      });
  };

  const getSpaceUsersInfo = async (
    inputIds: string[]
  ): Promise<User.AsObject[]> =>
    (await Promise.all(inputIds.map(getUserInfo))).filter(
      value => value != null
    ) as User.AsObject[];

  const updateUserLists = (adminUsersList: User.AsObject[]) => {
    const newAdminIdsList = adminUsersList.map(user => user.userId);
    setSpaceUsersList(adminUsersList);
    setValues({ adminIdsList: newAdminIdsList });
    setIsSaving(false);
    setSpaceAdminInput('');
    setResponseError(undefined);
  };

  const addAdmin = async (e: FormEvent | KeyboardEvent) => {
    e.preventDefault();
    setIsSaving(true);

    if (!spaceAdminInput) {
      setIsSaving(false);
      return setErrors('GSC User ID required');
    }

    const matchedUsers = spaceUsersList.find(user => {
      return createSpaceIdsArr(spaceAdminInput).find(
        adminId => user.userId === adminId
      );
    });

    if (matchedUsers) {
      setIsSaving(false);
      return setErrors('GSC User is already an admin');
    } else {
      setIsSaving(false);
      setErrors('');
    }

    if (errors) {
      return setIsSaving(false);
    } else {
      const inputIds = createSpaceIdsArr(spaceAdminInput);
      const filteredIds = inputIds.filter(id => id.length > 0);
      const newUserResponseArr = await getSpaceUsersInfo(filteredIds);
      return (
        newUserResponseArr.length &&
        updateUserLists([...newUserResponseArr, ...spaceUsersList])
      );
    }
  };

  const deleteAdmin = (id: string) => {
    const filteredList = spaceUsersList.filter(admin => admin.userId !== id);

    setValues({
      adminIdsList: filteredList.map(admin => admin.userId),
    });
    setSpaceUsersList(filteredList);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      addAdmin(e);
    }
  };

  const columns: IDataTableColumn<User.AsObject>[] = [
    {
      cell: row => row.userId,
      name: 'GSC User ID',
      selector: 'userId',
      sortable: true,
    },
    {
      cell: row => row.name,
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      cell: row => row.emailAddress,
      name: 'Email',
      selector: 'emailAddress',
      sortable: true,
    },
    {
      cell: row => {
        const DeleteButton = (
          <StyledTertiaryButton
            aria-label="delete-space-admin"
            id={`delete-admin-${row.userId}`}
            data-test={`delete-admin-${row.userId}`}
            key={`delete-${row.userId}`}
            onClick={() => deleteAdmin(row.userId)}
          >
            <StyledDeleteIcon />
          </StyledTertiaryButton>
        );
        return DeleteButton;
      },
      name: '',
      selector: '',
      sortable: false,
    },
  ];

  return (
    <StyledSection>
      <SectionHeader>Edit Space Admins</SectionHeader>
      <InputContainer>
        <StyledInput
          aria-label="space-admin-id"
          errors={errors}
          id="spaceAdminId"
          label="GSC User ID"
          name="spaceAdminId"
          onKeyDown={onKeyDown}
          onChange={handleInputChange}
          value={spaceAdminInput}
        />
        <SubmitButton
          isLoading={isSaving}
          onClick={(e: FormEvent) => addAdmin(e)}
        >
          {!isSaving && 'Add'}
        </SubmitButton>
      </InputContainer>

      <SectionHeader>Current Space Admins</SectionHeader>
      <StyledDataTable
        aria-label="current-space-admins"
        columns={columns}
        data={spaceUsersList}
        defaultSortAsc={false}
        defaultSortField="userId"
      />
    </StyledSection>
  );
};

export { EditSpaceAdminsForm };
