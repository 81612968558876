import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  margin-top: auto;
  border-top: 1px solid #e1e3de;

  & button {
    width: 100px;
  }
`;
