import React, { Component } from 'react';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

interface Props {
  children?: any;
  onError?: (error: Error, componentStack: string) => void;
}

interface ErrorInfo {
  componentStack: string;
}

interface State {
  error: Error | null;
  info: ErrorInfo | null;
}

const initialState: State = {
  error: null,
  info: null,
};

class ErrorBoundary extends Component<Props, State> {
  public state = initialState;

  public componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;

    if (onError) {
      onError.call(this, error, info.componentStack ? info.componentStack : '');
    }

    this.setState({ error, info });
  }

  public render() {
    const { children } = this.props;
    const { error, info } = this.state;

    const showFallback = error !== null;

    return showFallback ? (
      <ErrorBoundaryFallback
        componentStack={info ? info.componentStack : ''}
        error={error}
      />
    ) : (
      children
    );
  }
}

export { ErrorBoundary };
