import { Filter } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';

export interface SearchFilter extends Omit<Filter.AsObject, 'valuesList'> {
  value: string;
  label?: string;
}

export type SortDirection = '+' | '-';

export const DATE_REFERENCE_NAME = 'mission_responses';
export const DATE_PROPERTY_NAME = 'completed_at';
