import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  font,
  H5,
  SecondaryButton,
  Toggle,
} from '@gsc/components';
import { useAlbumsAPI, useAlbumsContext } from '../../../../state/Albums';
import classnames from 'classnames';
import {
  defaultCardConfig,
  useCurrentSpaceObj,
} from '../../../../state/Spaces';
import {
  Album,
  AlbumType,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import {
  AssetDetailConfig,
  CardConfig,
  CardField,
  ColumnConfig,
  TableConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const ToggleContainer = styled.div`
  padding: 0 2.4rem;

  ${breakpoint.md`
    padding: 0;
  `}
`;

const ToggleInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;

const ToggleText = styled(H5)`
  line-height: 2rem;
  margin-bottom: 0;
`;

const ToggleSubtext = styled(H5)`
  color: #6b717c;
  font-weight: ${font.weight.normal};
  margin-bottom: 2.4rem;
`;

const ShareLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ShareLink = styled.p`
  border: ${border.normal(color.ui30)};
  border-radius: 0.4rem 0 0 0.4rem;
  border-right: none;
  color: #6b717c;
  height: 4rem;
  line-height: 2rem;
  margin-bottom: 0;
  overflow-y: scroll;
  padding: 1rem 1.2rem;
  width: 33.9rem;
`;

const CopyButton = styled(SecondaryButton)`
  border-color: ${color.ui30};
  border-radius: 0 0.4rem 0.4rem 0;

  &.copy-link:not(.show-loader):hover {
    background-color: #267d4d;
    color: white;
  }
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface ShareModalBodyProps {
  album: AlbumType;
  setResponseNotification: (args: string) => any;
}

const ShareModalBody: FC<ShareModalBodyProps> = ({
  album,
  setResponseNotification,
}) => {
  const albumsAPI = useAlbumsAPI();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, { getAlbum, getSmartAlbum }] = useAlbumsContext();
  const [toggleShare, setToggleShare] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const smartAlbum =
    album && (album as SmartAlbum.AsObject).searchCriteria ? true : false;

  const albumShareToken = album?.shareSettings?.shareToken;
  const errorMessage =
    'We were unable to process your request. Please try again. If this error continues, please contact support.';

  useEffect(() => {
    if (albumShareToken) {
      setToggleShare(true);
    }
  }, [albumShareToken]);

  const cardConfig =
    useCurrentSpaceObj().config?.cardConfig ?? defaultCardConfig;

  const assetDetailConfig = useCurrentSpaceObj()?.config?.assetDetailConfig;

  const toCardField = (value: any): CardField => {
    const cardField = new CardField();
    cardField.setTableName(value.tableName);
    cardField.setColumnName(value.columnName);
    cardField.setRole(value.role);

    return cardField;
  };

  const toCardConfig = (value: any): CardConfig => {
    const fieldsList = value.fieldsList.map(toCardField);

    const cardConfig = new CardConfig();
    cardConfig.setFieldsList(fieldsList);

    return cardConfig;
  };

  const toColumnConfig = (value: any): ColumnConfig => {
    const columnConfig = new ColumnConfig();
    columnConfig.setColumnName(value.columnName);
    columnConfig.setType(value.type);
    columnConfig.setLabel(value.label);
    columnConfig.setCanFilter(value.canFilter);

    return columnConfig;
  };

  const toTableConfig = (value: any): TableConfig => {
    const tableConfig = new TableConfig();
    tableConfig.setTableName(value.tableName);
    const columnConfigList = value.columnConfigsList.map((valueItem: any) =>
      toColumnConfig(valueItem)
    );
    tableConfig.setColumnConfigsList(columnConfigList);

    return tableConfig;
  };

  const toAssetDetailConfig = (value: any): AssetDetailConfig => {
    const assetDetailConfig = new AssetDetailConfig();
    const tablesList = value.tablesList.map((listItem: any) =>
      toTableConfig(listItem)
    );
    assetDetailConfig.setTablesList(tablesList);

    return assetDetailConfig;
  };

  const createToken = () => {
    albumsAPI()
      .then(({ createShareToken }) => {
        if (!album) {
          return;
        } else {
          return createShareToken(
            album.id,
            toAssetDetailConfig(assetDetailConfig),
            toCardConfig(cardConfig)
          );
        }
      })
      .then(() => {
        if (smartAlbum) {
          album && getSmartAlbum(album.id);
        } else {
          album && getAlbum(album.id);
        }
      })
      .catch(e => {
        console.error('Create Token error', e);
        setResponseNotification(errorMessage);
      });
  };

  const deleteToken = () => {
    albumsAPI()
      .then(({ deleteShareToken }) => {
        if (!albumShareToken) {
          return;
        } else return deleteShareToken(albumShareToken);
      })
      .then(() => {
        if (smartAlbum) {
          album && getSmartAlbum(album.id);
        } else {
          album && getAlbum(album.id);
        }
      })
      .catch(() => {
        setResponseNotification(errorMessage);
      });
  };

  const handleToggleShare = () => {
    if (!toggleShare) {
      setToggleShare(true);
      createToken();
    } else {
      setToggleShare(false);
      deleteToken();
    }
  };

  const copyLinkText = () => {
    setCopyText(true);
    setTimeout(() => {
      const linkText = document.getElementById('share-link-text')?.innerText;
      linkText && navigator.clipboard.writeText(linkText);
      setCopyText(false);
    }, 200);
  };

  const urlLink = smartAlbum
    ? `https://photos.gospotcheck.com/share/${albumShareToken}?albumType=${AlbumType.ALBUM_TYPE_SMART}`
    : `https://photos.gospotcheck.com/share/${albumShareToken}?albumType=${AlbumType.ALBUM_TYPE_STANDARD}`;

  return (
    <ToggleContainer>
      <ToggleInnerWrapper>
        <ToggleText>Share link</ToggleText>
        <Toggle
          checked={toggleShare}
          hasLabel={false}
          name="toggleShareLink"
          onChange={handleToggleShare}
          value={albumShareToken ? albumShareToken : ''}
        />
      </ToggleInnerWrapper>
      <ToggleSubtext>
        Anyone with the link can view and download these photos
      </ToggleSubtext>
      {toggleShare && (
        <ShareLinkContainer>
          <ShareLink data-test="share-link-text" id="share-link-text">
            {urlLink}
          </ShareLink>
          <CopyButton
            className={classnames({ 'copy-link': copyText })}
            onClick={copyLinkText}
            status="neutral"
          >
            {copyText ? 'Copied' : 'Copy Link'}
          </CopyButton>
        </ShareLinkContainer>
      )}
    </ToggleContainer>
  );
};

export { ShareModalBody };
