import { ZoomTransform } from 'd3-zoom';
import React, { useMemo, FC, useContext } from 'react';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';
import styled from 'styled-components';

interface SegmentRectsProps {
  transform: ZoomTransform;
  setTransform: (transform: ZoomTransform) => void;
  dimensions: {
    width: number;
    height: number;
  };
  selectSegment: (index: number) => void;
}

interface SegmentRectMaskProps {
  dimensions: {
    width: number;
    height: number;
  };
}

const SegmentRect = styled.rect<{ index: number; isHovering?: boolean }>`
  cursor: pointer;
  transition: opacity 1s;

  opacity: 0;

  g:hover > & {
    opacity: 1;
  }
  opacity: ${p => (p.isHovering ? '1' : '0')};
`;

const SegmentRectMask: FC<SegmentRectMaskProps> = ({
  dimensions,
}: SegmentRectMaskProps) => {
  const context = useContext(ComplianceStateContext)?.state;
  const segmentRects = context?.kpiResult?.segment_rects ?? [];
  const segmentState = useContext(ComplianceStateContext)?.segmentState;

  if (!context?.kpiResult) return null;
  return (
    <mask xmlns="http://www.w3.org/2000/svg" id="segmentMask">
      <rect
        width={dimensions.width}
        height={dimensions.height}
        x={0}
        y={0}
      ></rect>
      {segmentRects.map((el, index) => {
        if (
          segmentState === undefined ||
          index !== segmentState.selectedSegmentIndex
        )
          return null;
        const x = el.x_min * dimensions.width;
        const width = (el.x_max - el.x_min) * dimensions.width;
        return (
          <rect
            key={index}
            width={width}
            height={dimensions.height - 8}
            x={x}
            y={4}
            fill="black"
          ></rect>
        );
      })}
    </mask>
  );
};

const SegmentRects: FC<SegmentRectsProps> = ({
  dimensions,
  selectSegment,
  transform,
}: SegmentRectsProps) => {
  const context = useContext(ComplianceStateContext)?.state;
  const segmentRects = context?.kpiResult?.segment_rects ?? [];
  const segmentState = useContext(ComplianceStateContext)?.segmentState;
  const setSegmentState = useContext(ComplianceStateContext)?.setSegmentState;

  const { selectedSegmentIndex } = useMemo(() => {
    if (!segmentState) return {};
    return segmentState;
  }, [segmentState]);

  if (!context?.kpiResult) return null;
  return (
    <>
      {selectedSegmentIndex !== undefined && (
        <rect
          width={dimensions.width}
          height={dimensions.height}
          x={0}
          y={0}
          fill="#262B2FCC"
          mask="url(#segmentMask)"
        />
      )}
      {segmentRects.map((el, index) => {
        const x = el.x_min * dimensions.width;
        const y = el.y_min * dimensions.height;
        const width = (el.x_max - el.x_min) * dimensions.width;
        const height = (el.y_max - el.y_min) * dimensions.height;

        const isHovering =
          segmentState !== undefined &&
          segmentState.hoveringInPlanogramSegmentIndex === index;

        return (
          <g key={index}>
            <SegmentRect
              index={index}
              isHovering={isHovering}
              onMouseEnter={() => {
                setSegmentState &&
                  setSegmentState({
                    ...segmentState,
                    hoveringSegmentIndex: index,
                  });
              }}
              onMouseLeave={() => {
                setSegmentState &&
                  setSegmentState({
                    ...segmentState,
                    hoveringSegmentIndex: undefined,
                  });
              }}
              onClick={(e: React.MouseEvent) => {
                selectSegment(index);
                e.stopPropagation();
              }}
              width={width}
              height={height - 8}
              x={x}
              y={y}
              strokeWidth={2 / (isNaN(transform.k) ? 1 : transform.k)}
              stroke={'#D4D5D5'}
              fill={isHovering ? '#D4D5D566' : '#262B2F01'}
              rx={4 / (isNaN(transform.k) ? 1 : transform.k)}
              ry={4 / (isNaN(transform.k) ? 1 : transform.k)}
            />
          </g>
        );
      })}
    </>
  );
};

export { SegmentRects, SegmentRectMask };
