import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { SelectItem, SelectItemValue, SelectProps } from '..';

import { getSelectedLabel, getSelectedValues, getSelection } from '../../utils';
import { SelectContext } from '../select-context';

import {
  Select,
  Wrapper,
  ClearIconWrapper,
  Content,
  DisplayValue,
} from './select.styles';

import { ClickAwayListener } from '../../../click-away-listener';
import { Portal } from '../../../portal';
import { anchorPositioningHelper } from '../../../../hooks';

const DefaultTriggerIcon = () => (
  <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
    <path
      d="M6.75781 9.24268L12.4147 14.8995L18.0715 9.24268"
      stroke="#BEBFC1"
      fill="none"
    ></path>
  </svg>
);
const ClearIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.334 2.14437L12.924 0.734375L7.33398 6.32438L1.74398 0.734375L0.333984 2.14437L5.92398 7.73438L0.333984 13.3244L1.74398 14.7344L7.33398 9.14437L12.924 14.7344L14.334 13.3244L8.74398 7.73438L14.334 2.14437Z"
      fill="#757874"
    />
  </svg>
);

export const BaseSelect = () =>
  forwardRef<HTMLDivElement, SelectProps>(function Component(
    {
      children,
      className,
      clearIcon = <ClearIcon />,
      contentClass,
      contentRef,
      name,
      dataTestid = `select-${name}`,
      defaultOpen = false,
      defaultValue,
      dir = 'ltr',
      error,
      iconBefore,
      multiple,
      open,
      options,
      placeholder = 'Select...',
      success,
      triggerIcon = <DefaultTriggerIcon />,
      value,
      warning,
      onBlur,
      onClear,
      onClick,
      onChange,
      ...props
    }: SelectProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    const selectRef = useRef<HTMLDivElement>(null);
    const contentRefControl = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(defaultOpen);

    const shouldOpen = open || isOpen;

    const classes = classNames({
      success,
      error,
      warning,
      withClear: onClear,
      withIconBefore: iconBefore,
    });

    const label = useMemo(() => {
      let valueLabel: string | string[] = '';

      if (value) {
        valueLabel = getSelectedLabel(value);

        if (Array.isArray(valueLabel)) return valueLabel[0];

        return valueLabel;
      }

      if (!defaultValue) return '';
      valueLabel = getSelectedLabel(defaultValue);

      if (Array.isArray(valueLabel)) return valueLabel[0];

      return valueLabel;
    }, [defaultValue, value]);

    const displayValue = label || placeholder;

    const ariaLabel = props?.['aria-label'] ?? name;

    useImperativeHandle(
      ref,
      () => ({ ...selectRef.current } as HTMLDivElement)
    );
    useImperativeHandle(
      contentRef,
      () =>
        ({
          ...contentRefControl.current,
        } as HTMLDivElement)
    );

    const handleOnScroll = () => {
      anchorPositioningHelper(selectRef.current, contentRefControl.current);
    };

    const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClear?.();
      e.stopPropagation();
    };
    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (onClick) return onClick(e);

      setIsOpen(prev => !prev);
    };
    const handleClickAway = () => {
      if (onClick) return onClick(null);

      if (isOpen) {
        setIsOpen(false);
      }
    };

    const handleSelectedValue = (selection: SelectItemValue) => {
      onChange?.(getSelection(value, options, selection, !!multiple));
    };

    useEffect(() => {
      document.addEventListener('scroll', handleOnScroll);
      document.addEventListener('click', handleOnScroll);

      return () => {
        document.removeEventListener('scroll', handleOnScroll);
        document.removeEventListener('click', handleOnScroll);
      };
    }, []);

    const selectContextValues = {
      onSelect: handleSelectedValue,
      selectedValues: getSelectedValues(value) as SelectItemValue | undefined,
    };

    return (
      <Wrapper
        className={className}
        ref={selectRef}
        onBlur={onBlur}
        data-testid={dataTestid}
      >
        <Select
          {...(props as Omit<typeof props, 'ref'>)}
          className={classes}
          onClick={handleClick}
          type="button"
          role="combobox"
          aria-expanded={shouldOpen}
          data-state={shouldOpen ? 'open' : 'closed'}
          aria-autocomplete="none"
          aria-label={ariaLabel}
          aria-controls={`select-${name}`}
          dir={dir}
        >
          <span
            className="iconBefore"
            data-testid={`select-${name}-iconBefore`}
          >
            {iconBefore}
          </span>
          <DisplayValue
            className={!label ? 'placeholder' : ''}
            data-testid={`select-${name}-displayValue`}
          >
            {displayValue}
          </DisplayValue>
          <span
            className="triggerIcon"
            data-testid={`select-${name}-triggerIcon`}
          >
            {triggerIcon}
          </span>
        </Select>
        {onClear && (
          <ClearIconWrapper
            data-testid={`select-${name}-clearIcon`}
            onClick={handleClear}
          >
            {clearIcon}
          </ClearIconWrapper>
        )}
        <SelectContext.Provider value={selectContextValues}>
          {shouldOpen && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Portal>
                <Content
                  className={contentClass}
                  role="listbox"
                  aria-labelledby={`select-${name}`}
                  ref={contentRefControl}
                  onClick={handleClickAway}
                >
                  {children}
                  {!children &&
                    options?.map((opt, index) => (
                      <SelectItem key={index} value={opt}>
                        {opt.label}
                      </SelectItem>
                    ))}
                </Content>
              </Portal>
            </ClickAwayListener>
          )}
        </SelectContext.Provider>
      </Wrapper>
    );
  });
