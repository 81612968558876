import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoint } from '@gsc/components';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../shared/components';
import { FieldsTable } from './FieldsTable';
import { DesktopArticle, MobileArticle } from '../../shared/components/Article';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';

const MobileCardLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(min(100vw, 108rem));
  padding-top: 13.2rem;
`;

const DesktopCardLayoutContainer = styled(MobileCardLayoutContainer)`
  padding-top: 8.8rem;
  padding-left: 24.2rem;
`;

const StyledMobileArticle = styled(MobileArticle)`
  padding: 0 3.2rem;
`;

const StyledDesktopArticle = styled(DesktopArticle)`
  padding: 0 1.6rem 0 6.4rem;

  ${breakpoint.lg`
    max-width: 94rem;
  `}
`;

interface StyledProps {
  isDesktop: boolean | undefined;
}

const CardLayoutContainer: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopCardLayoutContainer>{children}</DesktopCardLayoutContainer>
  ) : (
    <MobileCardLayoutContainer>{children}</MobileCardLayoutContainer>
  );

const VeryStyledArticle: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <StyledDesktopArticle>{children}</StyledDesktopArticle>
  ) : (
    <StyledMobileArticle>{children}</StyledMobileArticle>
  );

const CardLayout: FC = () => {
  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  return (
    <CardLayoutContainer isDesktop={isDesktop}>
      <VeryStyledArticle isDesktop={isDesktop}>
        <Helmet>
          <title>PhotoWorks - Card Layout</title>
          <meta name="description" content="Card layout" />
        </Helmet>

        <PageHeader
          title="Card Layout"
          bodyText="Cards can be configured to show up to three extra data fields."
        />
      </VeryStyledArticle>

      <FieldsTable />
    </CardLayoutContainer>
  );
};

export { CardLayout };
