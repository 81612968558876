import { createContext, useContext } from 'react';

export type RadioContextValues = {
  currentValue?: string;
  groupDataTestId?: string;
  name: string;
  onChange: (value: string) => void;
};

export const RadioContext = createContext<RadioContextValues | null>(null);

export const useRadioContext = () => {
  const context = useContext(RadioContext);

  if (!context) {
    throw new Error(
      'RadioContext is only available within RadioContextProvider'
    );
  }

  return context;
};
