import React, {
  ComponentProps,
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  color,
  mixin,
  PrimaryButton,
  transition,
  zIndex,
} from '@gsc/components';
import { Close } from '@gsc/icons/react';
import { isMobileDevice, useWindowDimensions } from '../utils';

interface MobileTakeOverProps {
  Button?: ReactNode;
  buttonText?: string | ReactNode;
  children: ReactNode;
  className?: string;
  dataTest: string;
  headerContent?: ReactNode;
  isOpen?: boolean;
  status?: string;
  style?: CSSProperties;
}

const xTranslation = ({ isOpen }: { isOpen: boolean }) => {
  if (isOpen) {
    return 'translate(0, 0)';
  }
  return 'translate(0, 100%)';
};

const TakeOverWrapper: any = styled.div(
  ({ isOpen }: ComponentProps<typeof TakeOverWrapper>) => `
  z-index: ${isOpen ? zIndex.alpha : 1};

  .takeover-active &:not(.current-takeover) {
    z-index: -1;
  }
`
);

const IsMobileTakeOver = styled.div`
  background-color: ${color.ui01};
  height: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: ${xTranslation};
  transition: ${transition.primary('transform')};
  width: 100%;
  z-index: ${zIndex.alpha};
`;

const DesktopTakeOver = styled(IsMobileTakeOver)`
  overflow: unset;
  position: static;
  transform: translate(0, 0);
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  padding: 0 2.4rem;
  position: relative;

  &:before,
  &:after {
    background-color: ${color.ui15};
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:before {
    background-color: ${color.ui20};
    top: 0;
  }

  &:after {
    bottom: 0;
  }
`;

const CloseTarget = styled.div`
  align-items: center;
  display: inline-flex;
  padding: 0.8rem;
  transform: translateX(-0.8rem);
`;

const StyledClose = styled(Close)`
  ${mixin.size('1.6rem')};
  color: ${color.ui50};
  cursor: pointer;
`;

const StyledButton = styled.button``;

interface TakeOverProps extends MobileTakeOverProps {
  isDesktop: boolean;
  isOpen: boolean;
  toggleTakeOver(): void;
}

const TakeOver: FC<TakeOverProps> = ({
  children,
  dataTest,
  headerContent,
  isDesktop,
  isOpen,
  toggleTakeOver,
}) =>
  isDesktop ? (
    <DesktopTakeOver
      data-test={dataTest && `${dataTest}-take-over`}
      isOpen={isOpen}
    >
      {children}
    </DesktopTakeOver>
  ) : (
    <IsMobileTakeOver
      data-test={dataTest && `${dataTest}-take-over`}
      isOpen={isOpen}
    >
      <Header>
        <CloseTarget onClick={toggleTakeOver}>
          <StyledClose data-test={dataTest && `${dataTest}-close`} />
        </CloseTarget>
        {headerContent}
      </Header>
      {children}
    </IsMobileTakeOver>
  );

const MobileTakeOver: FC<MobileTakeOverProps> = ({
  Button = PrimaryButton,
  buttonText,
  children,
  className,
  dataTest,
  headerContent,
  status,
  ...rest
}) => {
  const location = useLocation();
  const windowChanged = useWindowDimensions().width;
  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  const [isOpen, setIsOpen] = useState(false);
  const toggleTakeOver = () => {
    document.body.classList.toggle('takeover-active');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Close takeover if a route change happens
    document.body.classList.remove('takeover-active');
    setIsOpen(false);
  }, [location.pathname, windowChanged]);

  return (
    <TakeOverWrapper
      className={isOpen ? `current-takeover ${className}` : className}
      data-test={dataTest}
      isOpen={isOpen}
      {...rest}
    >
      {!isDesktop && (
        <StyledButton
          as={Button}
          data-test={dataTest && `${dataTest}-button`}
          onClick={toggleTakeOver}
          status={status}
        >
          {buttonText}
        </StyledButton>
      )}
      <TakeOver
        isDesktop={isDesktop}
        dataTest={dataTest}
        isOpen={isOpen}
        headerContent={headerContent}
        toggleTakeOver={toggleTakeOver}
      >
        {children}
      </TakeOver>
    </TakeOverWrapper>
  );
};

export { MobileTakeOver };
