import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  elevation,
  Input,
  OutsideClick,
  SecondaryButton,
  zIndex,
} from '@gsc/components';
import { isMobileDevice, useWindowDimensions } from '../../../shared/utils';
// For what ever reason, can't get TS to be happy with importing SVG's
// import chevronDownBoldIconUrl from '@gsc/icons/svg/chevron-down-bold.svg';
const chevronDownBoldIconUrl = require('@gsc/icons/svg/chevron-down-bold.svg')
  .default as string;

interface FilterDropdownProps {
  alignRight?: boolean;
  columnName?: string;
  dataTest?: string;
  fetchInitialFilterPropertyValues?(tableName: string, columnName: string): any;
  filterCount?: number;
  isActive?: boolean;
  tableName?: string;
  text: string;
  onOpen?: (...args: any[]) => any;
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const TriggerButton = styled(SecondaryButton).attrs({ status: 'neutral' })<
  FilterDropdownProps
>(
  ({ isActive, isOpen }) => `
    background-color: ${isActive || isOpen ? color.mxDeepOcean : color.ui01};
    border: ${
      isActive || isOpen
        ? border.normal(color.mxDeepOcean)
        : border.normal(color.ui15)
    };
    color: ${isActive || isOpen ? color.ui01 : color.ui50};

    ${
      isActive || isOpen
        ? `
      &:focus,
      &:focus:hover,
      &:not(.show-loader):hover {
        background-color: ${color.button.primary['default'].hover};
        border-color: ${color.button.primary['default'].hover};
        color: ${color.button.primary['default'].text};
      }
      `
        : ''
    } /* This style allows a chunk of CSS without generating a false: ''; attribute */
  `
);

const StyledInput = styled(Input)`
  input {
    background: ${color.ui01} url(${chevronDownBoldIconUrl}) no-repeat right
      1rem center / 1.2rem 1.2rem;
  }

  div {
    display: none;
  }
`;

const DropdownContainer = styled.div(
  ({ alignRight }: { alignRight: boolean }) => `
  ${elevation['12']}
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  margin-bottom: 1.6rem;
  margin-top: 0.4rem;
  position: absolute;
  top: 100%;
  left: ${alignRight ? 'auto' : 0};
  right: ${alignRight ? 0 : 'auto'};
  width: 100%;
  z-index: ${zIndex.hotel};
  overflow: hidden;

  ${breakpoint.md`
    & {
      min-width: 28rem;
      width: auto;
    }
  `}
`
);

// eslint-disable-next-line complexity
const FilterDropdown: FC<FilterDropdownProps> = ({
  children,
  columnName,
  dataTest,
  fetchInitialFilterPropertyValues,
  filterCount,
  isActive,
  tableName,
  text,
  onOpen,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sanitizedId = text.toLowerCase().replace(/\s/g, '');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) onOpen?.();
    fetchInitialFilterPropertyValues &&
      fetchInitialFilterPropertyValues(tableName || '', columnName || '');
  };

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;
  const formatId = text?.split(' ').join('');
  const buttonRight = document.getElementById(formatId)?.getBoundingClientRect()
    .right;
  const difference = window.innerWidth - (buttonRight ?? 1000);
  const alignRight = isDesktop && difference < 145;

  return (
    <DropdownWrapper data-test={dataTest} id={formatId} {...rest}>
      <OutsideClick onOutsideClick={() => setIsOpen(false)}>
        {isDesktop ? (
          <TriggerButton
            aria-expanded="false"
            aria-haspopup="true"
            data-test={dataTest && `${dataTest}-button`}
            isActive={isActive}
            isOpen={isOpen}
            onClick={toggleDropdown}
          >
            {text}
            {filterCount && filterCount > 0 ? ` (${filterCount})` : undefined}
          </TriggerButton>
        ) : (
          <StyledInput
            id={`${sanitizedId}-dropdownTrigger`}
            label={text}
            onClick={toggleDropdown}
            placeholder={
              filterCount && filterCount > 0
                ? `${filterCount} selected...`
                : 'Select...'
            }
            readOnly
            type="search"
            value={
              isOpen && filterCount && filterCount > 0
                ? `${filterCount} selected...`
                : ''
            }
            onChange={() => null}
          />
        )}
        {isOpen && (
          <DropdownContainer
            data-test={`${sanitizedId}-container`}
            alignRight={alignRight || false}
          >
            {children}
          </DropdownContainer>
        )}
      </OutsideClick>
    </DropdownWrapper>
  );
};

export { FilterDropdown };
