import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoint, H2 } from '@gsc/components';
import { usePhotosContext } from '../../../../state/Photos';
import { useSortColumn } from '../../../Photos/screens/PhotoGrid/utils';
import { MobileSavedTakeOver } from '../../../Photos/screens/PhotoGrid/components/MobileSavedTakeOver';
import { SortButton } from '../../../Photos/screens/PhotoGrid/components/SortButton';
import { BucketedGrid } from './grids/BucketedGrid';
import { RegularGrid } from './grids/RegularGrid';
import { ColumnType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';
import { NoResults } from '../../../Photos/shared/components/NoResults';

const PhotoGridInfo = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.6rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;

  ${breakpoint.lg`
    & {
      display: block;
      margin-bottom: 0;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  `}
`;

const PhotoCount = styled(H2)`
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0.4rem;

  ${breakpoint.lg`
    & {
      display: block;
      margin-bottom: 1.6rem;
    }
  `}
`;

const RightAlignedButtons = styled.div`
  display: inline;
  float: right;
  margin-top: -5rem;
  text-align: right;
`;

const PaddingForGrid = styled.div`
  padding-top: 1.6rem;
`;

interface PhotoGridProps {
  setResponseNotification: (args: string) => any;
}

const PhotoGrid: FC<PhotoGridProps> = ({ setResponseNotification }) => {
  const [{ loading, totalHits }] = usePhotosContext();
  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  let photoCountText: string = totalHits.toString();
  if (totalHits >= 10000) {
    photoCountText = '10,000+';
  }

  const sortColumn = useSortColumn();
  const isBucketedGrid = sortColumn?.type === ColumnType.COLUMN_TYPE_TEXT;

  return (
    <>
      {/* <div
        style={{
          height: isDesktop ? '6.4rem' : '11.3rem',
        }}
      ></div> */}
      <PhotoGridInfo data-test="photo-grid-info">
        <PhotoCount dataTest="photo-count">
          Realograms ({photoCountText})
        </PhotoCount>
        {!isDesktop && (
          <MobileSavedTakeOver
            setResponseNotification={setResponseNotification}
          />
        )}

        {isDesktop && (
          <RightAlignedButtons>
            <SortButton />
          </RightAlignedButtons>
        )}
      </PhotoGridInfo>

      {totalHits !== 0 || loading ? (
        isBucketedGrid ? (
          <BucketedGrid />
        ) : (
          <PaddingForGrid>
            <RegularGrid />
          </PaddingForGrid>
        )
      ) : (
        <NoResults />
      )}
    </>
  );
};

export { PhotoGrid };
