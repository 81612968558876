import styled from 'styled-components';
import { Table, TData, THead, TRow } from '../../../../shared/components';
import { breakpoint } from '@gsc/components';

const StyledTable = styled(Table)`
  ${breakpoint.md`
    & {
      margin-left: 2.4rem;
    }
  `}
`;

const StyledTHead = styled(THead)`
  display: none;
  visibility: hidden;

  ${breakpoint.md`
    & {
      display: table-header-group;
      visibility: visible;
    }
  `}
`;

const StyledTRow = styled(TRow)`
  display: block;

  ${breakpoint.md`
    & {
      display: table-row;

      td:first-child {
        width: 30%;
      }

      td:nth-child(2) {
        width: 30%;
      }

      td:nth-child(3),
      td:nth-child(4) {
        text-align: center;
        width: 20%;
      }

      th:nth-child(3),
      th:nth-child(4) {
        text-align: center;
      }
    }
  `}
`;

const StyledTData = styled(TData)`
  display: inline-flex;

  ${breakpoint.md`
    & {
      display: table-cell;
    }
  `}

  &[data-title]:before {
    content: attr(data-title);
    display: inline-block;
    min-width: 12rem;

    ${breakpoint.md`
      & {
        display: none;
      }
    `}
  }
`;

export { StyledTable, StyledTHead, StyledTRow, StyledTData };
