import { Context, createContext, useContext } from 'react';
import { SelectItemValue } from '../select-item';

export type SelectContextValues<T extends SelectItemValue> = {
  onSelect?: (value: T) => void;
  selectedValues?: T;
};

export const SelectContext: Context<SelectContextValues<any>> = createContext(
  {}
);

export const useSelectContext = () => {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error('Select context should be used within a Select component.');
  }

  return context;
};
