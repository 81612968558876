import { State } from './types';
import { Space } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const selectors = (state: State) => ({
  getSpacesAlphabetical: (): Space.AsObject[] => {
    return Object.values(state.spaces).sort((a, b) => {
      const aName = a.name.toUpperCase();
      const bName = b.name.toUpperCase();

      if (aName > bName) {
        return 1;
      }

      if (aName < bName) {
        return -1;
      }

      return 0;
    });
  },
});

export { selectors };
