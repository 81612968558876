const paths = {
  root: '/',
  notFound: '/404',
  admin: {
    root: '/admin',
    newSpace: '/admin/new-space',
    editSpace: (spaceId?: string) =>
      `/admin/${spaceId ?? ':spaceId'}/edit-space`,
  },
  exports: {
    root: '/exports',
  },
  spaces: {
    space: {
      root: (spaceId?: string) => `/spaces/${spaceId ?? ':spaceId'}`,
      photos: {
        root: (spaceId?: string) => `/spaces/${spaceId ?? ':spaceId'}/photos`,
        notFound: (spaceId?: string) =>
          `/spaces/${spaceId ?? ':spaceId'}/photo-not-found`,
      },
      albums: {
        root: (spaceId?: string) => `/spaces/${spaceId ?? ':spaceId'}/albums`,
      },
      compliance: {
        root: (spaceId?: string) =>
          `/spaces/${spaceId ?? ':spaceId'}/compliance`,
      },
      settings: {
        root: (spaceId?: string) => `/spaces/${spaceId ?? ':spaceId'}/settings`,
        dataConfiguration: (spaceId?: string) =>
          `/spaces/${spaceId ?? ':spaceId'}/settings/data-configuration`,
        cardLayout: (spaceId?: string) =>
          `/spaces/${spaceId ?? ':spaceId'}/settings/card-layout`,
      },
    },
  },
  share: {
    root: (shareToken?: string) => `/share/${shareToken ?? ':shareToken'}`,
  },
};

export { paths };
