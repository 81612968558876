import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { color, font, H1, H3, typography } from '@gsc/components';
import { Photos } from '@gsc/icons/react';
import { Format } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import classnames from 'classnames';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { useAlbumsContext } from '../../../../../../state/Albums';
import { usePhotosState } from '../../../../../../state/Photos';
import { MultiPhotoSlide } from './MultiPhotoSlide';
import { MetadataPreviewText } from './MetadataPreviewText';
import { formatTitle } from '../helpers/formatStrings';
import { Album } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

interface SlideProps {
  format?: number;
  useMetadata?: string;
}

const PreviewContainer = styled.div(
  ({ format }: SlideProps) => `
  background-color: ${color.ui05};
  flex: 1;
  padding: ${
    format === Format.FORMAT_PDF
      ? '6.8rem 4rem 6.8rem 13.6rem'
      : '6.8rem 8.8rem'
  };

  &.full-height {
    height: 100vh;
  }
`
);

const PreviewText = styled.p`
  ${typography.body};
  color: ${color.ui50};
  margin-bottom: 1.6rem;
`;

const TitleSlide = styled.section(
  ({ format }: SlideProps) => `
  align-items: center;
  background-color: ${color.ui01};
  display: flex;
  flex-direction: column;
  height: ${format === Format.FORMAT_PDF ? '65.6rem' : '41.8rem'};
  justify-content: center;
  margin-bottom: 4.8rem;
  width: ${format === Format.FORMAT_PDF ? '44.8rem' : '54.4rem'};
`
);

const TitleText = styled(H1)`
  font-weight: ${font.weight.medium};
  margin-bottom: 0.8rem;

  &.preview-text {
    color: ${color.ui30};
  }
`;

const SubtitleText = styled(H3)`
  font-weight: ${font.weight.normal};
  margin-bottom: 0;
`;

const NextSlide = styled.section(
  ({ format, useMetadata }: SlideProps) => `
  align-items: ${
    format === Format.FORMAT_PDF && useMetadata === 'true'
      ? 'flex-start'
      : 'center'
  };
  background-color: ${color.ui01};
  display: flex;
  height: ${format === Format.FORMAT_PDF ? '65.6rem' : '41.8rem'};
  justify-content: ${
    format === Format.FORMAT_PDF ? 'flex-start' : 'space-evenly'
  };
  padding: 2.4rem 3.2rem;
  width: ${format === Format.FORMAT_PDF ? '44.8rem' : '54.4rem'};

  &.three-per-page {
    > div:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
);

const SinglePhotoContainer = styled.div(
  ({ format }: SlideProps) => `
  display: flex;
  flex-direction: ${format === Format.FORMAT_PDF ? 'column' : 'row'};
  padding-left: ${format === Format.FORMAT_PDF ? '2rem' : '0'};

  &.include-text {
    align-items: ${format === Format.FORMAT_PDF ? 'flex-start' : 'center'};
    margin-right: auto;
  }
`
);

const Image = styled.div(
  ({ format }: SlideProps) => `
  align-items: center;
  background-color: ${color.ui10};
  color: ${color.ui30};
  display: flex;
  height: 30rem;
  justify-content: center;
  width: ${format === Format.FORMAT_PDF ? '34rem' : '50rem'};

  ${SinglePhotoContainer}.include-text & {
    margin-bottom: ${format === Format.FORMAT_PDF ? '1.2rem' : '0'};
    margin-right: 4.8rem;
    max-width: 38rem;
    min-height: 22rem;
    min-width: 22rem;
  }
`
);

const StyledPhotosIcon = styled(Photos)`
  height: 4rem;
  width: 4rem;
`;

const Preview: FC = () => {
  const { values } = useContext(FormContext);
  const { selectedPhotos } = usePhotosState();
  const [state] = useAlbumsContext();
  const album = state.albumsById[values.albumId];
  const exportFormat = parseInt(values.format, 10);

  const count = values.albumId
    ? album &&
      (album as Album.AsObject).assetCount / parseInt(values.photosPerPage)
    : selectedPhotos.length / parseInt(values.photosPerPage);
  const slideCount = Number.isInteger(count) ? count + 1 : Math.ceil(count) + 1;

  const allowPreview = exportFormat !== Format.FORMAT_JPEG;
  const type = exportFormat === Format.FORMAT_PPTX ? 'slide' : 'page';

  const SinglePhotoSlide = (
    <SinglePhotoContainer
      className={classnames({ 'include-text': values.useMetadata === 'true' })}
      format={exportFormat}
    >
      <Image format={exportFormat}>
        <StyledPhotosIcon />
      </Image>
      {values.useMetadata === 'true' && <MetadataPreviewText />}
    </SinglePhotoContainer>
  );

  return (
    <PreviewContainer
      className={classnames({ 'full-height': !allowPreview })}
      format={exportFormat}
    >
      {allowPreview ? (
        <>
          <PreviewText>
            Previewing {type} 1 of {slideCount}
          </PreviewText>
          <TitleSlide format={exportFormat}>
            <TitleText>{values.title}</TitleText>
            {values.subtitle && <SubtitleText>{values.subtitle}</SubtitleText>}
          </TitleSlide>
          <PreviewText>
            {formatTitle(type)} 2 of {slideCount}
          </PreviewText>
          <NextSlide
            className={classnames({
              'three-per-page': values.photosPerPage === '3',
            })}
            format={exportFormat}
            useMetadata={values.useMetadata}
          >
            {values.photosPerPage === '1' ? (
              SinglePhotoSlide
            ) : (
              <MultiPhotoSlide />
            )}
          </NextSlide>
        </>
      ) : (
        <>
          <TitleSlide>
            <TitleText className="preview-text">No preview available</TitleText>
          </TitleSlide>
        </>
      )}
    </PreviewContainer>
  );
};

export { Preview };
