import React, { CSSProperties, memo } from 'react';
import { areEqual } from 'react-window';
import { BucketRenderer } from './BucketRenderer';
import { PhotoRenderer } from './PhotoRenderer';
import { NotificationRenderer } from './NotificationRenderer';
import { ButtonRenderer } from './ButtonRenderer';
import { ItemData } from '../types';

const BucketedGridCellFactory = memo(
  (props: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const { columnIndex, rowIndex, data } = props;
    const cell = data.rows[rowIndex]?.[columnIndex];

    if (!cell) return null;
    if (cell.type === 'photo') {
      return <PhotoRenderer {...props} />;
    } else if (cell.type === 'bucket') {
      if (rowIndex === 0) return null;
      return <BucketRenderer {...props} />;
    } else if (cell.type === 'button') {
      return <ButtonRenderer {...props} />;
    } else if (cell.type === 'notification') {
      return <NotificationRenderer {...props} />;
    } else {
      return null;
    }
  },
  areEqual
);

BucketedGridCellFactory.displayName = 'BucketedGridCellFactory';

export { BucketedGridCellFactory };
