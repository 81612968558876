/* eslint-disable complexity */
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint, Notification, color } from '@gsc/components';
import { ArrowLeft } from '@gsc/icons/react';
import { AlbumsLayout } from '../../components';
import {
  ActionTypes,
  useAlbumsContext,
  useAlbumsDispatch,
} from '../../../../state/Albums';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { NoMatch } from '../../../NoMatch';
import { AlbumHeader } from './Header';
import { AlbumPhotosGrid } from './PhotosGrid';
import { AlbumQueryParamComponents } from './AlbumQueryParamComponents';
import { AddPhotos } from './AddPhotos';
import classnames from 'classnames';
import { AlbumType } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const BackLink = styled(Link)`
  color: ${color.mxDeepOcean};
  display: inline-block;
  margin-bottom: 1.6rem;
  margin-top: 3.2rem;

  ${breakpoint.md`
    margin-bottom: 0;
    margin-top: 1.6rem;
  `}

  & > svg {
    margin-right: 0.8rem;
    width: 2rem;
  }

  &.no-match {
    margin-bottom: 1.6rem;
  }
`;

const Spacer = styled.div`
  height: 6.8rem;
  width: 7.5rem;

  &.shared-album {
    height: 2rem;
  }

  ${breakpoint.md`
    height: 2rem;
  `}
`;

const NotificationWrapper = styled.div`
  position: relative;
`;

const StyledNotification = styled(Notification)`
  margin: 0.8rem 2.4rem;
  position: fixed;
  width: calc(100% - 4.8rem);
  z-index: 12;

  ${breakpoint.lg`
    left: 50%;
    margin-left: -36rem;
    width: 72rem;
  `};
`;

interface AlbumScreenParams {
  match: { params: { spaceId: string; albumId: string; shareToken: string } };
}

const AlbumScreen: FC<AlbumScreenParams> = ({
  match: {
    params: { spaceId, albumId, shareToken },
  },
}) => {
  const {
    showAlbumModal,
    showAlbumType,
    showEditAlbum: deleteMode,
  } = useQueryParams();
  const [responseNotification, setResponseNotification] = useState('');
  const [pageNotFound, setPageNotFound] = useState<{
    message: string;
  }>({ message: '' });
  const dispatch = useAlbumsDispatch();
  const [
    { albumAssetCounts, albumsById, sharedAlbum },
    { getAlbum, getSmartAlbum },
  ] = useAlbumsContext();
  const history = useHistory();

  const smartParam =
    showAlbumType === AlbumType.ALBUM_TYPE_SMART.valueOf().toString();
  const standardParam =
    showAlbumType === AlbumType.ALBUM_TYPE_STANDARD.valueOf().toString();
  const album = albumId ? albumsById[albumId] : sharedAlbum;

  const smartAlbum =
    (album &&
      'albumType' in album &&
      album.albumType === AlbumType.ALBUM_TYPE_SMART) ||
    (album && 'searchCriteria' in album) ||
    smartParam;

  const handleError = (e: Error) => {
    e.message.includes('token')
      ? setPageNotFound({
          message: `This shared link from PhotoWorks is no longer available.`,
        })
      : setPageNotFound({ message: `This album has been deleted.` });
  };

  useEffect(() => {
    if (shareToken && !history.location.search) {
      setPageNotFound({
        message: `This shared link from PhotoWorks is not available.`,
      });
    }
  }, [history.location.search, shareToken]);

  useEffect(() => {
    if (!album && shareToken && standardParam) {
      getAlbum(albumId, shareToken).catch(e => handleError(e));
    } else if (smartAlbum || smartParam) {
      getSmartAlbum(albumId, shareToken).catch(e => handleError(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    albumId,
    getAlbum,
    getSmartAlbum,
    shareToken,
    smartAlbum,
    smartParam,
    standardParam,
  ]);

  const resetAlbums = () => dispatch({ type: ActionTypes.ResetPagination });

  const showAddPhotos =
    album && albumAssetCounts[album.id] === 0 && !deleteMode;
  const toastStatus = responseNotification.includes('unable')
    ? 'negative'
    : 'positive';

  return (
    <>
      <AlbumsLayout>
        {spaceId ? (
          <BackLink
            className={classnames({ 'no-match': pageNotFound })}
            to={`/spaces/${spaceId}/albums`}
            onClick={resetAlbums}
          >
            <ArrowLeft />
            Albums
          </BackLink>
        ) : (
          <Spacer className={classnames({ 'shared-album': shareToken })} />
        )}

        {responseNotification && (
          <NotificationWrapper>
            <StyledNotification
              className="album-toast"
              dataTest="delete-album-notification"
              onClick={() => {
                setResponseNotification('');
              }}
              status={toastStatus}
            >
              {responseNotification}
            </StyledNotification>
          </NotificationWrapper>
        )}

        {pageNotFound.message ? (
          <NoMatch message={pageNotFound.message} />
        ) : (
          <>
            <AlbumHeader
              album={album}
              setResponseNotification={setResponseNotification}
              shareToken={shareToken}
              smartAlbum={smartAlbum}
            />
            {showAddPhotos ? (
              <AddPhotos albumId={album?.id} smartAlbum={smartAlbum} />
            ) : (
              <AlbumPhotosGrid
                album={album}
                setResponseNotification={setResponseNotification}
                smartAlbum={smartAlbum}
              />
            )}
          </>
        )}
      </AlbumsLayout>

      <AlbumQueryParamComponents
        albumId={albumId}
        setResponseNotification={setResponseNotification}
        showAlbumModal={showAlbumModal}
      />
    </>
  );
};

export { AlbumScreen };
