import moment from 'moment-timezone';
import { SearchFilter } from '../../../../state/QueryParams/types';
import {
  DateType,
  DateTypeMap,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const toString = (value: moment.Moment) =>
  moment(value)
    .valueOf()
    .toString();

const toMoment = (value: string) => moment(parseInt(value, 10));

const formatDateFull = (value: string) =>
  toMoment(value).format('MMM DD, YYYY');

const generateLabel = ([startFilter, endFilter]: [
  SearchFilter,
  SearchFilter
]): string => {
  const relativeDate = startFilter.label === endFilter.label;
  const startDate = formatDateFull(startFilter.value);
  const endDate = formatDateFull(endFilter.value);
  const yearsEqual =
    toMoment(startFilter.value).year() === toMoment(endFilter.value).year();
  const reg = /@date-/g;

  let label: string;
  if (startFilter.label && relativeDate) {
    label = startFilter.label.replace(reg, '');
  } else if (startDate === endDate) {
    label = startDate;
  } else if (yearsEqual) {
    label = `${toMoment(startFilter.value).format('MMM DD')} - ${endDate}`;
  } else {
    label = `${startDate} - ${endDate}`;
  }

  return label;
};

const generateDateTypes = (label: string) => {
  switch (label) {
    case '@date-Last 7 days':
      return DateType.DATE_TYPE_LAST_7_DAYS;

    case '@date-Last 30 days':
      return DateType.DATE_TYPE_LAST_30_DAYS;

    case '@date-This month':
      return DateType.DATE_TYPE_THIS_MONTH;

    case '@date-Last month':
      return DateType.DATE_TYPE_LAST_MONTH;

    case '@date-This year':
      return DateType.DATE_TYPE_THIS_YEAR;

    default:
      return DateType.DATE_TYPE_INVALID;
  }
};

const generateDateString = (dateType: DateTypeMap[keyof DateTypeMap]) => {
  switch (dateType) {
    case 0:
      return 'Invalid';
    case 1:
      return 'Last 7 days';

    case 2:
      return 'Last 30 days';

    case 3:
      return 'This month';

    case 4:
      return 'Last month';

    case 5:
      return 'This year';

    case 6:
      return 'All time';

    case 7:
      return 'custom';

    default:
      return 'All time';
  }
};

const currentDate = moment()
  .endOf('day')
  .format('x');

export {
  currentDate,
  generateDateString,
  generateDateTypes,
  generateLabel,
  toString,
  toMoment,
};
