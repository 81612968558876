import { Dispatch, useEffect, SetStateAction, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Actions, ActionTypes } from './actions';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { AuthStatus, Auth0User, State } from './types';
import { api } from './api';
import { paths } from '../../paths';
import { grpc } from '@improbable-eng/grpc-web';
import { useQueryParams } from '../QueryParams/hooks';

const options: Auth0ClientOptions = {
  domain: process.env.AUTH0_DOMAIN || '',
  client_id: process.env.AUTH0_CLIENT_ID || '',
  redirect_uri: window.location.origin, // TODO: Nate check this
};

const useAuthenticate = (
  dispatch: Dispatch<Actions>,
  setAuth0Client: Dispatch<SetStateAction<Auth0Client | undefined>>
) => {
  const history = useHistory();
  const { showSignIn, setSignIn } = useQueryParams();
  const match = useRouteMatch<{ shareToken: string }>(paths.share.root());

  useEffect(
    function getAllAuth() {
      let auth0Client: Auth0Client;

      const getAuth0Client = async () => {
        auth0Client = await createAuth0Client(options);

        setAuth0Client(auth0Client);

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0Client.handleRedirectCallback();
          history.push(
            appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname
          );
        }

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const [token, user]: [string, Auth0User] = await Promise.all([
            auth0Client.getTokenSilently(),
            auth0Client.getUser(),
          ]);

          dispatch({
            type: ActionTypes.SetToken,
            payload: {
              token,
            },
          });

          dispatch({
            type: ActionTypes.SetUser,
            payload: {
              user,
            },
          });

          dispatch({
            type: ActionTypes.SetStatus,
            payload: {
              status: AuthStatus.AUTHENTICATED,
            },
          });
          const signInParam = history.location.search.includes('signIn=true');
          signInParam && setSignIn(false);
        } else if (match?.params.shareToken && !showSignIn) {
          return dispatch({
            type: ActionTypes.SetStatus,
            payload: {
              status: AuthStatus.UNAUTHENTICATED,
            },
          });
        } else {
          return auth0Client.loginWithRedirect({
            appState: {
              targetUrl: history.location.pathname + history.location.search,
            },
          });
        }
      };

      dispatch({
        type: ActionTypes.SetStatus,
        payload: {
          status: AuthStatus.LOADING,
        },
      });

      getAuth0Client().catch(() => {
        dispatch({
          type: ActionTypes.SetStatus,
          payload: {
            status: AuthStatus.ERROR,
          },
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, setAuth0Client, history]
  );
};

const useAuthorize = (dispatch: Dispatch<Actions>, state: State) => {
  const sentRequestRef = useRef(false);
  const {
    status,
    token,
    user: { id },
  } = state;

  useEffect(
    function getAuthorization() {
      if (status === AuthStatus.AUTHENTICATED && !sentRequestRef.current) {
        const metadata = new grpc.Metadata();
        metadata.set('Authorization', `Bearer ${token}`);

        api(metadata)
          .isApertureAdmin(id)
          .then(isApertureAdmin => {
            if (isApertureAdmin === false) {
              api(metadata)
                .hasGscAccess(id)
                .then(hasGscAccess => {
                  dispatch({
                    type: ActionTypes.SetRoles,
                    payload: {
                      hasGscAccess,
                      isApertureAdmin,
                    },
                  });
                });
            } else {
              dispatch({
                type: ActionTypes.SetRoles,
                payload: {
                  hasGscAccess: true,
                  isApertureAdmin,
                },
              });
            }

            dispatch({
              type: ActionTypes.SetStatus,
              payload: {
                status: AuthStatus.AUTHORIZED,
              },
            });

            sentRequestRef.current = true;
          });
      }
    },
    [dispatch, token, id, status]
  );
};

const useSpaceAdmin = (dispatch: Dispatch<Actions>, state: State) => {
  const {
    token,
    user: { id },
  } = state;
  const match = useRouteMatch<{ spaceId: string }>(paths.spaces.space.root());
  const spaceId = match?.params.spaceId || '2-1';

  useEffect(
    function getSpaceAdmin() {
      if (token && id) {
        const metadata = new grpc.Metadata();
        metadata.set('Authorization', `Bearer ${token}`);

        api(metadata)
          .isSpaceAdmin(spaceId, id)
          .then((isSpaceAdmin: boolean) => {
            dispatch({
              type: ActionTypes.SetRoles,
              payload: {
                isSpaceAdmin,
              },
            });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, token, id, spaceId]
  );
};

export { useAuthenticate, useAuthorize, useSpaceAdmin };
