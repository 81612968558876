import { ThemeModel } from './base-theme';

const join = (path: string, key: string): string =>
  path ? `${path}-${key}` : key;

const generateVariables = (path: string, object: ThemeModel): string => {
  let styles = '';

  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object') {
      styles += generateVariables(`${join(path, key)}`, value);
    } else {
      styles += `--${join(path, key)}: ${value as string};`;
    }
  });

  return styles;
};

export const makeTheme = (variables: ThemeModel) => {
  const variablesObject: ThemeModel =
    typeof variables === 'string' ? JSON.parse(variables) : variables;

  return generateVariables('', variablesObject);
};
