import { zoomIdentity, ZoomTransform } from 'd3-zoom';

const zoomToFitImageWidth = ({
  imageWidth,
  imageHeight,
  viewportWidth,
  viewportHeight,
  widthPadding = 0,
  heightPadding = 0,
  offsetFromTop = 0,
}: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const paddedViewportWidth = viewportWidth - widthPadding * 2;
  const paddedViewportHeight = viewportHeight - heightPadding * 2;

  const k = imageWidth ? paddedViewportWidth / imageWidth : 1;
  const x = widthPadding;
  const y = imageHeight
    ? offsetFromTop + (paddedViewportHeight - imageHeight * k) / 2
    : 0;

  return zoomIdentity.translate(x, y).scale(k);
};

const zoomToFitImageHeight = ({
  imageWidth,
  imageHeight,
  viewportWidth,
  viewportHeight,
  widthPadding = 0,
  heightPadding = 0,
}: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const paddedViewportWidth = viewportWidth - widthPadding * 2;
  const paddedViewportHeight = viewportHeight - heightPadding * 2;

  const k = imageHeight ? paddedViewportHeight / imageHeight : 1;
  const x = imageWidth ? (paddedViewportWidth - imageWidth * k) / 2 : 0;
  const y = heightPadding;

  return zoomIdentity.translate(x, y).scale(k);
};

const zoomToFitImage = (props: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const zoomToWidth = zoomToFitImageWidth(props);
  const zoomToHeight = zoomToFitImageHeight(props);

  const heightFits =
    (props.imageWidth ?? 0) * zoomToHeight.k <= props.viewportWidth &&
    (props.imageHeight ?? 0) * zoomToHeight.k <= props.viewportHeight;

  if (heightFits) {
    return zoomToHeight;
  } else {
    return zoomToWidth;
  }
};

export { zoomToFitImage, zoomToFitImageWidth, zoomToFitImageHeight };
