import React, { FC } from 'react';
import styled from 'styled-components';
import { ConfirmationModal, PrimaryButton } from '@gsc/components';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { ShareModalBody } from './ShareModalBody';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const ShareModal = styled(ConfirmationModal)`
  min-width: 48rem;
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface ShareAlbumModalDesktopProps {
  album: AlbumType;
  setResponseNotification: (args: string) => any;
}

const ShareAlbumModalDesktop: FC<ShareAlbumModalDesktopProps> = ({
  album,
  setResponseNotification,
}) => {
  const { setShowShareAlbumModal } = useQueryParams();

  return (
    <ShareModal
      buttons={[
        <PrimaryButton
          key="share-album"
          name="share-album"
          onClick={() => setShowShareAlbumModal(false)}
        >
          Done
        </PrimaryButton>,
      ]}
      closeClick={() => setShowShareAlbumModal(false)}
      title="Share album"
    >
      <ShareModalBody
        album={album}
        setResponseNotification={setResponseNotification}
      />
    </ShareModal>
  );
};

export { ShareAlbumModalDesktop };
