import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { breakpoint, color, TertiaryButton } from '@gsc/components';
import { Pill } from '../../../shared/components/Pill';
import { generateLabel } from '../shared/filters/formatDateFilters';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import { SearchFilter } from '../../../state/QueryParams/types';
import { selectFilters } from '../../../state/QueryParams/selectors';
import { formatLabelOrColumnName } from '../shared/filters/formatFilterText';
import { useCurrentSpaceObj } from '../../../state/Spaces';

const PillsContainer = styled.div`
  padding: 0 1.6rem 1.2rem;

  ${breakpoint.lg`
    & {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  `}
`;

const StyledPill = styled(Pill)`
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
` as typeof Pill;

// this requires explicitly setting the color to
// color.button.tertiary.default.text because for some reason it's
// set to secondary.default.text in the component ...
const ClearButton = styled(TertiaryButton)`
  color: ${color.button.tertiary.default.text};

  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: ${color.ui01};
    border: 0;
    box-shadow: none;
    text-decoration: underline;
  }
`;

const PillsBar: FC = () => {
  const { searchFilters, setSearchFilters } = useQueryParams();
  const currentSpace = useCurrentSpaceObj();
  const assetDetailConfig = currentSpace.config?.assetDetailConfig;

  const removePills = (pills: SearchFilter[]) => {
    setSearchFilters(
      searchFilters.filter(
        filter =>
          !pills.find(
            pill =>
              filter.referenceName === pill.referenceName &&
              filter.propertyName === pill.propertyName &&
              filter.operator === pill.operator &&
              filter.value === pill.value
          )
      )
    );
  };

  const { dateFilters, numericRangeFilters, customFilters } = selectFilters(
    searchFilters
  );

  const renderDateFilters = () => {
    if (dateFilters.length < 2) return null;
    const label = generateLabel([dateFilters[0], dateFilters[1]]);
    return (
      <StyledPill
        dataTest="pills-bar-date-range"
        onClick={() => removePills(dateFilters)}
      >
        {label}
      </StyledPill>
    );
  };

  const groupedNumericRangeFilters = useMemo(
    () =>
      numericRangeFilters.reduce<{ [key: string]: SearchFilter[] }>(
        (result, filter) => {
          const key: string = filter.referenceName + filter.propertyName;
          const value: SearchFilter[] = [...(result[key] || []), filter];
          return { ...result, [key]: value };
        },
        {}
      ),
    [numericRangeFilters]
  );

  const renderNumericRangeFilters = () =>
    Object.entries(groupedNumericRangeFilters).map(
      ([key, [startFilter, endFilter]]) => {
        const table = assetDetailConfig?.tablesList?.find(
          t => t.tableName === startFilter.referenceName
        );
        const column = table?.columnConfigsList?.find(
          c => c.columnName === startFilter.propertyName
        );

        return (
          <StyledPill
            key={key}
            dataTest="pills-bar-numeric-range"
            onClick={() => removePills([startFilter, endFilter])}
          >
            {column
              ? formatLabelOrColumnName(column)
              : startFilter.propertyName}
            :&nbsp;
            {startFilter.value} to {endFilter.value}
          </StyledPill>
        );
      }
    );

  const renderCustomFilters = () =>
    customFilters.map(pill => {
      return (
        <StyledPill
          dataTest={`pills-bar-${pill.referenceName}-${pill.value}`}
          key={`${pill.referenceName}-${pill.value}`}
          onClick={() => removePills([pill])}
        >
          {pill.value || '(No value set)'}
        </StyledPill>
      );
    });

  return searchFilters.length > 0 ? (
    <PillsContainer data-test="pills-bar">
      {renderDateFilters()}
      {renderNumericRangeFilters()}
      {renderCustomFilters()}
      {searchFilters.length > 1 && (
        <ClearButton onClick={() => setSearchFilters([])}>
          Clear all
        </ClearButton>
      )}
    </PillsContainer>
  ) : null;
};

export { PillsBar };
