import React, { FC } from 'react';
import { AuthProvider } from './Auth';
import { SpacesProvider } from './Spaces';
import { PhotosProvider } from './Photos';

const Providers: FC = ({ children }) => (
  <AuthProvider>
    <SpacesProvider>
      <PhotosProvider>{children}</PhotosProvider>
    </SpacesProvider>
  </AuthProvider>
);

export { Providers };
