import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  breakpoint,
  color,
  H5,
  Notification,
  TertiaryButton,
  transition,
  zIndex,
} from '@gsc/components';
import { Close } from '@gsc/icons/react';
import classnames from 'classnames';
import {
  ActionTypes,
  usePhotosAPI,
  usePhotosDispatch,
  usePhotosState,
} from '../../../state/Photos';
import { isMobileDevice } from '../../../shared/utils';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { SelectedPhotosActionButtons } from './SelectedPhotosActionButtons';

const FooterWrapper = styled.div`
  align-items: center;
  background-color: ${color.ui01};
  bottom: -6.4rem;
  box-shadow: 0 -0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  display: flex;
  height: 6.4rem;
  padding: 2.2rem 2.4rem;
  position: fixed;
  transition: ${transition.primary('bottom')};
  visibility: hidden;
  width: 100%;
  z-index: ${zIndex.foxtrot};

  &.footer-open {
    bottom: 0;
    visibility: visible;
    z-index: ${zIndex.hotel};
  }

  .takeover-active & {
    visibility: hidden;
  }

  ${breakpoint.lg`
    & {
      padding: 2rem 3.2rem;
    }
  `}
`;

const ClearButton = styled(TertiaryButton)`
  border: none;
  color: ${color.ui90};
  height: 1.6rem;
  margin-right: 1.6rem;
  padding: 0;
  width: 1.6rem;

  &:not(.show-loader):hover {
    background-color: transparent;
    color: ${color.ui50};
  }
`;

const SelectedText = styled(H5)`
  line-height: 2rem;
  margin-bottom: 0;
`;

const StyledSelectAllButton = styled(TertiaryButton)`
  border: 0;
  color: ${color.mxDeepOcean};
  cursor: pointer;

  &:hover {
    color: ${color.mxDeepOceanHover};
  }

  > small {
    color: ${color.ui30};
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledNotification = styled(Notification)`
  border-radius: 0;
  bottom: 6.3rem;
  color: #8f6a17;
  font-size: 1.2rem;
  left: -0.1rem;
  line-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  position: absolute;
  width: 101%;

  .status-icon {
    position: relative;
    top: 0.5rem;
    margin-right: 1.4rem;
  }

  ${breakpoint.md`
    & {
      border-radius: 0.4rem;
      bottom: auto;
      left: calc(50vw + 15.8rem);
      width: auto;
    }
  `}
`;

const MAX_SELECTABLE_PHOTOS = 1000;

enum SelectAllState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

const SelectedPhotosFooter: FC = () => {
  const dispatch = usePhotosDispatch();
  const { selectedPhotos, mobileSelectMode } = usePhotosState();
  const {
    getFilters,
    selectAlbumId,
    sortField,
    sortDirection,
  } = useQueryParams();

  const showFooter =
    mobileSelectMode || (selectedPhotos.length > 0 && !selectAlbumId);

  const clearSelectedPhotos = useCallback(() => {
    dispatch({
      type: ActionTypes.SetMobileSelectMode,
      payload: { mobileSelectMode: false },
    });
  }, [dispatch]);

  useEffect(() => {
    const onEscPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') return clearSelectedPhotos();
    };

    document.addEventListener('keydown', onEscPress);

    return () => {
      document.removeEventListener('keydown', onEscPress);
    };
  }, [clearSelectedPhotos]);

  const api = usePhotosAPI();
  const [selectAllState, setSelectingAll] = useState<SelectAllState>(
    SelectAllState.COMPLETED
  );

  const selectablePhotoCount = MAX_SELECTABLE_PHOTOS - selectedPhotos.length;

  const onSelectAllClick = () => {
    if (selectAllState === SelectAllState.PENDING) return;
    setSelectingAll(SelectAllState.PENDING);

    api()
      .then(({ getPhotoSearch }) =>
        getPhotoSearch({
          filters: getFilters(),
          sortField,
          sortDirection,
          from: 0,
          pageSize: MAX_SELECTABLE_PHOTOS,
        })
      )
      .then(photos => {
        const ids = photos
          .getAssetDetailsList()
          .map((ad: AssetDetail) => ad.getId())
          .filter(id => selectedPhotos.indexOf(id) === -1)
          .slice(0, selectablePhotoCount);

        dispatch({
          type: ActionTypes.SetSelectedPhotos,
          payload: { ids: [...selectedPhotos, ...ids] },
        });

        setSelectingAll(SelectAllState.COMPLETED);
      })
      .catch(() => {
        setSelectingAll(SelectAllState.FAILED);
      });
  };

  return (
    <FooterWrapper className={classnames({ 'footer-open': showFooter })}>
      {!isMobileDevice() && (
        <ClearButton
          isIconOnly
          onClick={clearSelectedPhotos}
          status="neutral"
          title="Clear Photos"
          type="button"
        >
          <Close />
        </ClearButton>
      )}

      <SelectedText>{selectedPhotos.length} SELECTED</SelectedText>

      {selectablePhotoCount > 0 && (
        <StyledSelectAllButton
          onClick={onSelectAllClick}
          disabled={selectAllState === SelectAllState.PENDING}
        >
          {selectAllState === SelectAllState.PENDING ? (
            <>Please wait...</>
          ) : selectAllState === SelectAllState.FAILED ? (
            <>
              Retry select all
              <small> (up to {MAX_SELECTABLE_PHOTOS})</small>
            </>
          ) : (
            <>
              Select all
              <small> (up to {MAX_SELECTABLE_PHOTOS})</small>
            </>
          )}
        </StyledSelectAllButton>
      )}

      <SelectedPhotosActionButtons
        MAX_SELECTABLE_PHOTOS={MAX_SELECTABLE_PHOTOS}
        selectedPhotos={selectedPhotos}
      />

      {selectedPhotos.length > MAX_SELECTABLE_PHOTOS && (
        <StyledNotification status="warning">
          Exports are limited to {MAX_SELECTABLE_PHOTOS} photos at a time.
          <br />
          Please deselect photos to enable export options.
        </StyledNotification>
      )}
    </FooterWrapper>
  );
};

export { SelectedPhotosFooter };
