import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #e1e3de;
  opacity: 0;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  z-index: var(--zIndices-overlay);

  &.open {
    animation-name: overlayFadeIn;
  }

  &.close {
    animation-name: overlayFadeOut;
  }

  @keyframes overlayFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.25;
    }
  }

  @keyframes overlayFadeOut {
    0% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
    }
  }
`;
