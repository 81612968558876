import styled from 'styled-components';
import { border, breakpoint, color, typography } from '@gsc/components';

const TableHeader = styled.h3`
  ${typography.heading3};
  margin-bottom: 2.4rem;
`;

const Table = styled.table`
  display: table;
  margin-bottom: 3.2rem;
  width: 100%;
`;

const THead = styled.thead``;

const TBody = styled.tbody`
  display: table-row-group;
  margin-left: 2.4rem;
`;

const TH = styled.th`
  ${typography.heading5};
  color: ${color.ui50};
  display: table-cell;
  padding-bottom: 0.8rem;
  text-align: left;
`;

const TRow = styled.tr`
  border-bottom: ${border.normal(color.ui15)};
  display: table-row;

  & td:first-of-type {
    color: ${color.text900};
    padding: 1rem 0;
  }
`;

const TData = styled.td`
  align-items: center;
  color: ${color.ui50};
  display: table-cell;
  padding: 1rem 0 1rem 4.8rem;
  width: 100%;

  ${breakpoint.md`
    & {
      padding: 0.8rem 0;
      vertical-align: middle;
    }
  `}
`;

export { TableHeader, Table, THead, TBody, TH, TRow, TData };
