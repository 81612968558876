import { ListExportsResponse as ListExportsResponseProto } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_api_pb';

export enum ActionTypes {
  ListExportsResponse = 'app/exports/ListExportsResponse',
  SetLoadingState = 'app/photo/SetLoadingState',
}

interface ListExportsResponse {
  type: ActionTypes.ListExportsResponse;
  payload: {
    exports: ListExportsResponseProto;
  };
}

interface SetLoadingState {
  type: ActionTypes.SetLoadingState;
  payload: {
    loading: boolean;
  };
}

export type Actions = ListExportsResponse | SetLoadingState;
