import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { H3, H4, TertiaryButton } from '@gsc/components';
import { ChevronDownBold, ChevronUpBold } from '@gsc/icons/react';
import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { Details, DetailsTitle } from './components';
import { useQueryParams } from '../../../../../../state/QueryParams/hooks';

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const getIngredientName = (tag: Record.AsObject): string =>
  getProperty(tag, 'name') ??
  getProperty(tag, 'subclassification') ??
  getProperty(tag, 'classification') ??
  'Unknown product';

const MenuDetailsList = styled.ul`
  margin: 0 -3.2rem;
  padding-top: 0.1rem;

  & ${H3} {
    padding: 0.8rem 3.2rem;
    border-bottom: 0.1rem solid #f3f3f4;
    margin-bottom: 0;
  }

  & ${H4} {
    padding: 0.8rem 0;
    border-bottom: 0.1rem solid #f3f3f4;
    margin-bottom: 0;
  }

  & > li > ul > li {
    padding: 0.8rem 3.2rem;

    & > ul > li {
      margin: 0 -3.2rem;
      padding: 1.6rem 3.2rem;
      font-size: 1.4rem;
      border-bottom: 0.1rem solid #f3f3f4;
      cursor: pointer;

      & > ul {
        padding: 0.8rem 0 0;

        & > li {
          color: #6b717c;
          padding: 0.4rem 0;

          & > .property-name {
            margin-bottom: 0.4rem;
          }

          & > .property-value {
            float: right;
            font-weight: 500;
            margin-bottom: 0.4rem;
          }
        }
      }

      &:hover {
        background: #f3f3f4;
      }

      &:first-child {
        border-top: 0.1rem solid #f3f3f4;
      }

      &.active {
        background: #e6e7e9;
      }

      & > .tag-details-toggle {
        color: #222b3b;
        float: right;
        padding: 1.2rem;
        margin-top: -1rem;
        margin-right: -2rem;

        &:hover,
        &:focus {
          background: transparent !important;
          border-color: transparent;
          box-shadow: none;
          color: #222b3b;
        }

        & > svg {
          margin-right: 0;
          width: 1.2rem;
        }
      }
    }
  }
`;

interface Section {
  name: string;
  drinks: Drink[];
}
interface Drink {
  name: string;
  tag: Record.AsObject;
  ingredients: Ingredient[];
}
interface Ingredient {
  name: string;
  tag: Record.AsObject;
}

interface MenuTagsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
}

const MenuTagsPanel: FC<MenuTagsPanelProps> = ({ assetDetail }) => {
  const currentSpace = useCurrentSpaceObj();

  const displayFields = useMemo(
    () =>
      currentSpace?.config?.assetDetailConfig?.tablesList
        ?.find(({ tableName }) => tableName === 'image_recognition_tags')
        ?.columnConfigsList?.map(({ columnName }) => columnName) ?? [],
    [currentSpace]
  );

  const tags = useMemo(
    () =>
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_tags')
        ?.recordsList?.filter(
          tag => !parseInt(getProperty(tag, 'deleted_at'))
        ) ?? [],
    [assetDetail]
  );

  const { selectedTagId, setSelectedTagId } = useQueryParams();

  const drinks = useMemo(
    () => tags.filter(tag => getProperty(tag, 'type_name') === 'menu_item'),
    [tags]
  );

  const menu = useMemo<Section[]>(
    () =>
      Object.values(
        tags.reduce<{ [name: string]: Section }>((result, tag) => {
          const section = getProperty(tag, 'section') ?? '';
          const tagType = getProperty(tag, 'type_name');

          if (!result[section]) result[section] = { name: section, drinks: [] };

          if (tagType === 'menu_item') {
            const drinkId = getProperty(tag, 'external_id') ?? '';
            const drinkName = getProperty(tag, 'name') ?? 'N/A';

            result[section].drinks.push({
              name: drinkName,
              tag,
              ingredients: tags
                .filter(tag => getProperty(tag, 'menu_item_id') === drinkId)
                .map(tag => ({ name: getIngredientName(tag), tag })),
            });
          }

          return result;
        }, {})
      ),
    [tags]
  );

  return (
    <Details>
      <DetailsTitle>
        {pluralize('Drinks', drinks.length, true)} Tagged
      </DetailsTitle>

      <MenuDetailsList>
        {menu.map((section, i) => {
          return (
            <li key={i}>
              {!!section.name.length && <H3>{section.name}</H3>}
              <ul>
                {section.drinks.map((drink, i) => {
                  return (
                    <li key={i}>
                      <H4>{drink.name}</H4>
                      <ul>
                        {drink.ingredients.map((ingredient, i) => {
                          const tagId = getProperty(ingredient.tag, 'id');
                          const active = tagId === selectedTagId;
                          const ToggleIcon = active
                            ? ChevronUpBold
                            : ChevronDownBold;

                          return (
                            <li
                              key={i}
                              className={classnames({
                                active,
                                'ir-tag-list-item': true,
                              })}
                              onClick={() =>
                                setSelectedTagId(active ? null : tagId)
                              }
                              data-tag-id={tagId}
                            >
                              {displayFields.length > 0 && (
                                <TertiaryButton className="tag-details-toggle">
                                  <ToggleIcon />
                                </TertiaryButton>
                              )}

                              {ingredient.name}

                              {active && (
                                <ul>
                                  {displayFields.map(propertyName => {
                                    if (propertyName === 'name') return null;

                                    const name = propertyName
                                      .split('_')
                                      .map(
                                        w => w[0].toUpperCase() + w.substring(1)
                                      )
                                      .join(' ');

                                    const value = getProperty(
                                      ingredient.tag,
                                      propertyName
                                    );

                                    if (!value) return null;
                                    return (
                                      <li key={propertyName}>
                                        <span className="property-name">
                                          {name}:{' '}
                                        </span>
                                        <span className="property-value">
                                          {value}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </MenuDetailsList>
    </Details>
  );
};

export { MenuTagsPanel };
