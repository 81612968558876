import React, { FC, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint, Notification } from '@gsc/components';
import classnames from 'classnames';
import { paths } from '../../paths';
import { useAlbumsAPI } from '../../state/Albums';
import { usePhotosState } from '../../state/Photos';
import { useCurrentSpaceObj } from '../../state/Spaces';
import { useQueryParams } from '../../state/QueryParams/hooks';
import { NoMatch } from '../NoMatch';
import { SelectedPhotosFooter } from '../Photos/components/SelectedPhotosFooter';
import { CreateAlbumModal } from '../Photos/components/CreateAlbumModal';
import { SaveModal } from '../Photos/screens/PhotoGrid/components/SaveModal';
import { EditAlbumHeader } from '../Photos/components/EditAlbumHeader';
import { PhotoGrid } from './screens/PhotoGrid';
import { ExportsSettings } from '../Photos/screens/ExportsSettings';
import {
  SpaceStatus,
  SpaceTierType,
  Space,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { Album } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import { FilterBar } from '../Photos/components/FilterBar';
import { PhotoDetails } from '../Photos/screens/Details/PhotoDetails';
import { Operator } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';

const PhotosLayout = styled.div`
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 4rem;

  &.select-mode {
    padding-bottom: 6.4rem;
  }

  ${breakpoint.lg`
    & {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  `}

  ${breakpoint.xxxl`
    & {
      margin-right: auto;
      margin-left: auto;
      max-width: 192rem;
    }
  `}
`;

const NotificationWrapper = styled.div`
  position: relative;
`;

const StyledNotification = styled(Notification)`
  margin: 0.8rem 2.4rem;
  position: fixed;
  width: calc(100% - 4.8rem);
  z-index: 12;

  ${breakpoint.lg`
    left: 50%;
    margin-left: -36rem;
    width: 72rem;
  `};
`;

interface ScreenProps {
  currentSpace: Space.AsObject;
}

// eslint-disable-next-line complexity
const ComplianceScreen: FC<ScreenProps> = ({ currentSpace }) => {
  const albumsAPI = useAlbumsAPI();
  const [selectedAlbum, setSelectedAlbum] = useState<Album.AsObject>();
  const { selectedPhotos } = usePhotosState();
  const {
    currentPhotoId,
    selectAlbumId,
    setShowAlbumModal,
    setShowExport,
    showAlbumModal,
    showExport,
    showSavedViewModal,
    showSmartAlbumModal,
    searchFilters,
    setSearchFilters,
  } = useQueryParams();
  const [responseNotification, setResponseNotification] = useState('');
  const toastStatus = responseNotification.includes('unable')
    ? 'negative'
    : 'positive';

  const hideExports = () => setShowExport(false);
  const photoCount = currentPhotoId ? 1 : selectedPhotos.length;

  // Always add the planogram task type as a filter parameter
  useEffect(() => {
    const TASKS_REFERENCE = 'tasks';
    const TYPE_PROPERTY = 'type';
    const TASK_TYPE_PLANOGRAM_CAPTURE = 'Task::PlanogramCapture';

    const isInFilter = searchFilters.some(
      filter =>
        filter.referenceName === TASKS_REFERENCE &&
        filter.propertyName === TYPE_PROPERTY &&
        filter.value === TASK_TYPE_PLANOGRAM_CAPTURE
    );

    if (!isInFilter) {
      searchFilters.push({
        referenceName: TASKS_REFERENCE,
        propertyName: TYPE_PROPERTY,
        operator: Operator.OPERATOR_EQUALS,
        value: TASK_TYPE_PLANOGRAM_CAPTURE,
      });
      setSearchFilters(searchFilters);
    }
  }, [searchFilters, setSearchFilters]);

  useEffect(() => {
    if (showAlbumModal && photoCount === 0) {
      setShowAlbumModal(false, true);
    }

    if (selectAlbumId && selectedAlbum === undefined) {
      albumsAPI()
        .then(({ getAlbum }) => {
          return getAlbum({ albumId: selectAlbumId });
        })
        .then(response => {
          const album = response.toObject().album;
          setSelectedAlbum(album);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlbum, photoCount, showAlbumModal]);

  const showAlbumHeader = selectAlbumId && selectedAlbum;
  const showModal = showSavedViewModal || showSmartAlbumModal;

  return (
    <>
      {showAlbumHeader && (
        <EditAlbumHeader
          selectedAlbum={selectedAlbum}
          setResponseNotification={setResponseNotification}
          spaceId={currentSpace.id}
        />
      )}
      <FilterBar />

      {responseNotification && (
        <NotificationWrapper>
          <StyledNotification
            className="album-toast"
            dataTest="create-album-notification"
            onClick={() => {
              setResponseNotification('');
            }}
            status={toastStatus}
          >
            {responseNotification}
          </StyledNotification>
        </NotificationWrapper>
      )}

      <PhotosLayout
        className={classnames({
          'select-mode': photoCount > 0,
        })}
        data-test="photos-page"
      >
        {/* <PillsBar /> */}
        <PhotoGrid setResponseNotification={setResponseNotification} />
      </PhotosLayout>

      <SelectedPhotosFooter />

      {currentPhotoId && <PhotoDetails id={currentPhotoId} />}

      {showAlbumModal && (
        <CreateAlbumModal setResponseNotification={setResponseNotification} />
      )}

      {showModal && (
        <SaveModal setResponseNotification={setResponseNotification} />
      )}

      {showExport && <ExportsSettings handleCancel={() => hideExports()} />}
    </>
  );
};

const CompliancePhotos: FC = () => {
  const currentSpace = useCurrentSpaceObj();
  const expiredTrial =
    currentSpace?.status === SpaceStatus.SPACE_STATUS_INACTIVE &&
    currentSpace?.tier?.type === SpaceTierType.SPACE_TIER_TYPE_TRIAL;

  return (
    <Route path={paths.spaces.space.compliance.root()}>
      {expiredTrial ? (
        <NoMatch
          title="Trial Expired"
          message="Your trial of PhotoWorks has expired. Please contact your CSM to upgrade to a paid plan."
        />
      ) : (
        <ComplianceScreen currentSpace={currentSpace} />
      )}
    </Route>
  );
};

export { CompliancePhotos };
