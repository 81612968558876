import React from 'react';

import classNames from 'classnames';

import { Container, StyledInput } from './text-input.styles';

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;

  dataTestId?: string;

  elementAfter?: React.ReactNode;
  elementBefore?: React.ReactNode;

  info?: boolean;
  error?: boolean;
  warning?: boolean;
  success?: boolean;
  disabled?: boolean;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      name,
      type,
      className,
      elementAfter,
      elementBefore,
      success,
      warning,
      error,
      info,
      dataTestId,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const ariaLabel = props?.['aria-label'] ?? name;

    const isReadOnly = props.value !== undefined && !props.onChange;

    const stateful = {
      success,
      warning,
      error,
      info,
    };

    const state = Object.entries(stateful).find(([, value]) => value) ?? [''];

    const inputWrapperClass = classNames(state[0], className);

    const visibilityIcon = React.useMemo(
      () => <></>,
      // showPassword ? (
      //   <Icons24.VisibilityOffRounded className="visibility" />
      // ) : (
      //   <Icons24.VisibilityRounded className="visibility" />
      // ),
      []
    );

    const toggleVisibility = () => setShowPassword(prev => !prev);

    const handleInputType = () => {
      if (type === 'password') return showPassword ? 'text' : 'password';

      return type;
    };

    const getElementAfter = () => {
      if (type === 'password')
        return (
          <span
            className={classNames('elementWrapper', 'visibility')}
            onClick={toggleVisibility}
          >
            {visibilityIcon}
          </span>
        );

      if (elementAfter)
        return <span className="elementWrapper">{elementAfter}</span>;

      return <></>;
    };

    return (
      <Container
        aria-disabled={props.disabled}
        className={inputWrapperClass}
        data-testid={dataTestId ? `${dataTestId}-wrapper` : 'input-wrapper'}
        aria-label={name ? `${name}-wrapper` : 'input-wrapper'}
      >
        {elementBefore && (
          <span className="elementWrapper">{elementBefore}</span>
        )}
        <StyledInput
          {...props}
          ref={ref}
          id={id ?? name}
          name={name}
          type={handleInputType()}
          data-testid={dataTestId ?? `input-${type ?? 'text'}`}
          aria-label={ariaLabel}
          readOnly={isReadOnly}
        />
        {getElementAfter()}
      </Container>
    );
  }
);

TextInput.displayName = 'TextInput';
