import React, { CSSProperties, memo } from 'react';
import { ActionTypes, usePhotosDispatch } from '../../../../../state/Photos';
import { PhotoCard } from '../../../shared/components/PhotoCard';
import { areEqual } from 'react-window';
import { queuePageForLoading } from '../utils';
import { ItemData } from '../types';

const PhotoRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const dispatch = usePhotosDispatch();

    const cell = data.rows[rowIndex]?.[columnIndex];
    if (!cell || typeof cell.bucket !== 'string') return null;
    const pageNumber = Math.floor(
      ((cell.index ?? 0) - (cell.bucketOffset ?? 0)) / data.pageSize
    );
    const page = data.getPage(pageNumber, cell.bucket);

    if (!page) {
      queuePageForLoading(pageNumber, () =>
        data.loadMorePhotos(pageNumber, cell.bucket as string)
      );
    } else if (cell.photo) {
      return (
        <PhotoCard
          checked={data.selectedPhotos.includes(cell.photo.id)}
          onClick={() => {
            data.setShowPhoto(cell.photo?.id);
          }}
          handleChange={() => {
            dispatch({
              type: ActionTypes.TogglePhotoSelected,
              payload: { id: cell.photo?.id ?? '' },
            });
          }}
          id={cell.photo.id}
          loading={false}
          suspended={data.suspendGrid}
          photo={cell.photo}
          selectMode={
            data.mobileSelectMode ||
            data.selectedPhotos.length > 0 ||
            data.selectAlbumId
          }
          style={style}
          url={cell.photo.assetUrl}
          onMouseDown={() => {
            data.setDrag([cell.photo?.id ?? '']);
          }}
          onMouseEnter={() => {
            if (data.drag && cell.photo) {
              const newDrag = [...data.drag, cell.photo.id];

              if (newDrag.length === 2) {
                dispatch({
                  type: ActionTypes.TogglePhotoSelected,
                  payload: { id: data.drag[0] },
                });
              }

              dispatch({
                type: ActionTypes.TogglePhotoSelected,
                payload: { id: cell.photo.id },
              });

              data.setDrag(newDrag);
            }
          }}
        />
      );
    }

    return (
      <PhotoCard
        id={`loading_photo_${columnIndex}_${rowIndex}_index_${cell.index}`}
        style={style}
      />
    );
  },
  areEqual
);

PhotoRenderer.displayName = 'PhotoRenderer';

export { PhotoRenderer };
