import { SearchFilter, DATE_PROPERTY_NAME, DATE_REFERENCE_NAME } from './types';
import { Operator } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';

const selectFilters = (filters: SearchFilter[]) =>
  filters.reduce(
    (
      acc: {
        dateFilters: SearchFilter[];
        numericRangeFilters: SearchFilter[];
        customFilters: SearchFilter[];
      },
      filter
    ) => {
      const isDateFilter =
        filter.referenceName === DATE_REFERENCE_NAME &&
        filter.propertyName === DATE_PROPERTY_NAME;
      const isNumericRangeFilter =
        filter.operator === Operator.OPERATOR_GTE ||
        filter.operator === Operator.OPERATOR_LTE;
      if (isDateFilter) {
        acc.dateFilters.push(filter);
      } else if (isNumericRangeFilter) {
        acc.numericRangeFilters.push(filter);
      } else {
        acc.customFilters.push(filter);
      }

      return acc;
    },
    {
      dateFilters: [],
      numericRangeFilters: [],
      customFilters: [],
    }
  );

export { selectFilters };
