import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  elevation,
  Input,
  OutsideClick,
  zIndex,
} from '@gsc/components';
import { useCurrentSpaceObj } from '../../../../state/Spaces';
import { combineTableAndColumnName } from '../../../Photos/shared/filters/formatFilterText';
import { CardField } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
// For what ever reason, can't get TS to be happy with importing SVG's
// import chevronDownBoldIconUrl from '@gsc/icons/svg/chevron-down-bold.svg';
const chevronDownBoldIconUrl = require('@gsc/icons/svg/chevron-down-bold.svg')
  .default as string;

interface FilterDropdownProps {
  value: CardField.AsObject | null;
  onChange?: (...args: any[]) => any;
  dataTest?: string;
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  input {
    background: ${color.ui01} url(${chevronDownBoldIconUrl}) no-repeat right
      1rem center / 1.2rem 1.2rem;
    margin: 1rem 0;
  }

  div {
    display: none;
  }
`;

const DropdownContainer = styled.div`
  ${elevation['12']};
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  margin-bottom: 1.6rem;
  margin-top: 0.4rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  width: 100%;
  z-index: ${zIndex.hotel};
  overflow: hidden;

  ${breakpoint.lg`
    & {
      min-width: 28rem;
      width: auto;
    }
  `}
`;

const ListContainer = styled.ul`
  max-height: 20rem;
  overflow-y: auto;
`;

const ListItems = styled.li`
  display: block;
  width: 100%;
  padding: 0.8rem 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.ui10};
  }
`;

// eslint-disable-next-line complexity
const PropertiesDropdown: FC<FilterDropdownProps> = ({
  dataTest,
  value,
  onChange,
  ...rest
}) => {
  const currentSpace = useCurrentSpaceObj();

  const valueStr = value
    ? combineTableAndColumnName(value.tableName, value.columnName)
    : '';
  const [inputValue, setInputValue] = useState(valueStr);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setInputValue(valueStr);
  }, [valueStr]);

  const properties = useMemo(
    () =>
      currentSpace?.schema?.tablesList
        ?.flatMap(table =>
          table.columnsList
            .filter(({ name }) => name !== 'id')
            .map(column => ({
              propertyName: combineTableAndColumnName(table.name, column.name),
              tableName: table.name,
              columnName: column.name,
            }))
        )
        ?.sort((a, b) => {
          if (a.propertyName > b.propertyName) return 1;
          if (a.propertyName < b.propertyName) return -1;
          return 0;
        }) ?? [],
    [currentSpace]
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Tab') {
      setIsOpen(false);
    }
  };

  return (
    <DropdownWrapper data-test={dataTest} {...rest}>
      <OutsideClick onOutsideClick={() => setIsOpen(false)}>
        <StyledInput
          onChange={() => {}}
          placeholder="Search"
          value={inputValue ?? ''}
          onClick={() => setIsOpen(true)}
          onFocus={() => setIsOpen(true)}
          onKeyDown={onKeyDown}
        />

        {isOpen && (
          <DropdownContainer>
            <ListContainer>
              {properties.map(property => (
                <ListItems
                  key={property.propertyName}
                  onClick={() => {
                    onChange?.(property);
                    setIsOpen(false);
                  }}
                >
                  {property.propertyName}
                </ListItems>
              ))}
            </ListContainer>
          </DropdownContainer>
        )}
      </OutsideClick>
    </DropdownWrapper>
  );
};

export { PropertiesDropdown };
