import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { font, typography } from '@gsc/components';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { formatTitle, formatLabel } from '../helpers/formatStrings';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 18rem;
  overflow: visible;

  &.single-image {
    height: 30rem;
  }
`;

const TextRow = styled.span`
  display: flex;
  flex-flow: row wrap;
`;

const LabelText = styled.p`
  ${typography.body};
  display: inline-block;
  font-weight: ${font.weight.medium};
  margin-right: 0.4rem;
`;

const PhotoText = styled.p`
  ${typography.body};
  display: inline-block;
  margin-bottom: 0.4rem;
`;

const MetadataPreviewText: FC = () => {
  const { values } = useContext(FormContext);
  const currentSpace = useCurrentSpaceObj();
  const tablesList = currentSpace.config?.assetDetailConfig?.tablesList || [];

  const metadataItems = () => {
    return tablesList.map(table => {
      if (table.columnConfigsList.length > 0) {
        return table.columnConfigsList.map(columnConfig => {
          const combinedName = `metadata-${table.tableName}-${columnConfig.columnName}`;
          const fieldLabel = columnConfig.label
            ? `${columnConfig.label}:`
            : `${formatTitle(table.tableName)} ${formatLabel(
                columnConfig.columnName
              )}:`;
          const metaLabel = columnConfig.label
            ? `[${columnConfig.label}]`
            : `[${formatTitle(table.tableName)} ${formatLabel(
                columnConfig.columnName
              )}]`;

          return Object.entries(values).map(([key, value]) => {
            if (key === combinedName && value === 'true') {
              return (
                <TextRow key={key}>
                  {values.includeLabels === 'true' && (
                    <LabelText>{fieldLabel}</LabelText>
                  )}
                  <PhotoText>{metaLabel}</PhotoText>
                </TextRow>
              );
            }
            return null;
          });
        });
      }
      return null;
    });
  };

  return (
    <TextContainer
      className={classnames({ 'single-image': values.photosPerPage === '1' })}
    >
      <TextRow>
        {values.includeLabels === 'true' &&
          values['metadata-mission_responses-completed_at'] === 'true' && (
            <LabelText>Date submitted:</LabelText>
          )}
        <PhotoText>
          {values['metadata-mission_responses-completed_at'] === 'true' &&
            `[${moment().format('MMMM DD, YYYY h:mm A z')}]`}
        </PhotoText>
      </TextRow>
      {metadataItems()}
    </TextContainer>
  );
};

export { MetadataPreviewText };
