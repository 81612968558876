import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

export const getProperty = (
  record: Record.AsObject,
  propertyName: string
): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

export const getImageRecognitionTags = (assetDetail?: AssetDetail.AsObject) =>
  assetDetail?.referencesList
    ?.find(({ name }) => name === 'image_recognition_tags')
    ?.recordsList?.filter(tag => !parseInt(getProperty(tag, 'deleted_at'))) ??
  [];
