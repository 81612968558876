import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import {
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../state/Spaces';
import { paths } from '../../paths';
import { NoMatch } from '../NoMatch';
import { AlbumsProvider } from '../../state/Albums';
import { AlbumScreen, GridScreen } from './screens';

const AlbumsApp: FC = () => {
  const currentSpace = useCurrentSpaceObj();
  const canAccessAlbums =
    currentSpace?.status === SpaceStatus.SPACE_STATUS_ACTIVE &&
    currentSpace?.tier?.type !== SpaceTierType.SPACE_TIER_TYPE_LITE;

  return (
    <AlbumsProvider spaceId={currentSpace.id}>
      {canAccessAlbums ? (
        <>
          <Route
            exact
            path={paths.spaces.space.albums.root()}
            component={GridScreen}
          />

          <Route
            path={paths.spaces.space.albums.root() + '/:albumId'}
            component={AlbumScreen}
          />
        </>
      ) : (
        <NoMatch />
      )}
    </AlbumsProvider>
  );
};

export { AlbumsApp };
