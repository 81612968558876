import { Export } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import { Actions, ActionTypes } from './actions';

export interface State {
  exportsList: Export.AsObject[];
  loading: boolean;
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.ListExportsResponse: {
      const { exportsList } = action.payload.exports.toObject();

      return {
        ...state,
        exportsList,
      };
    }

    case ActionTypes.SetLoadingState: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer };
