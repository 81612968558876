import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { color } from '@gsc/components';
import { Photos } from '@gsc/icons/react';
import classnames from 'classnames';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { MetadataPreviewText } from './MetadataPreviewText';
import { Format } from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';

interface SlideProps {
  useMetadata?: string;
}

const PPTWrapper = styled.div(
  ({ useMetadata }: SlideProps) => `
  display: grid;
  height: ${useMetadata === 'false' ? '20rem' : '37rem'};
  width: 50rem;

  &.wrapper2 {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  &.wrapper3 {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
`
);

const PDFWrapper = styled.div`
  display: grid;
  width: 50rem;

  &.show-text {
    height: 60rem;

    &.wrapper4,
    &.wrapper6 {
      grid-auto-rows: 29rem;
    }
  }

  &.wrapper4 {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  &.wrapper6 {
    width: 40rem;
  }

  &.wrapper6,
  &.wrapper9 {
    grid-gap: 2.5rem;
    grid-auto-rows: 18rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const MultiPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(.show-text) {
    flex: 1;
  }

  &.export3 {
    width: 15rem;
  }

  &.export4 {
    width: 18rem;
  }
`;

const Image = styled.div`
  align-items: center;
  background-color: ${color.ui10};
  color: ${color.ui30};
  display: flex;
  height: 20rem;
  justify-content: center;
  margin-bottom: 1.2rem;

  ${MultiPhotoContainer}.show-text & {
    max-width: 22rem;
    min-width: 18rem;
  }

  ${MultiPhotoContainer}.export3 & {
    max-width: 18rem;
    min-width: 0;
    min-height: 18rem;
  }

  ${MultiPhotoContainer}.export4 & {
    max-width: 18rem;
    min-height: 18rem;
  }

  ${MultiPhotoContainer}.show-text.export6 & {
    min-width: 11rem;
  }
`;

const StyledPhotosIcon = styled(Photos)`
  height: 4rem;
  width: 4rem;
`;

const MultiPhotoSlide: FC = () => {
  const { values } = useContext(FormContext);
  const exportFormat = parseInt(values.format, 10);
  const photosPerPage = parseInt(values.photosPerPage, 10);

  const PowerPtPreview = (
    <PPTWrapper
      className={classnames({
        'show-text': values.useMetadata === 'true',
        wrapper2: values.photosPerPage === '2',
        wrapper3: values.photosPerPage === '3',
      })}
      useMetadata={values.useMetadata}
    >
      {[...new Array(photosPerPage)].map((e, i) => (
        <MultiPhotoContainer
          key={`${e}${i}`}
          className={classnames({
            'show-text': values.useMetadata === 'true',
            export2: values.photosPerPage === '2',
            export3: values.photosPerPage === '3',
          })}
        >
          <Image>
            <StyledPhotosIcon />
          </Image>
          {values.useMetadata === 'true' && <MetadataPreviewText />}
        </MultiPhotoContainer>
      ))}
    </PPTWrapper>
  );

  const PdfPreview = (
    <PDFWrapper
      className={classnames({
        'show-text': values.useMetadata === 'true',
        wrapper4: values.photosPerPage === '4',
        wrapper6: values.photosPerPage === '6',
        wrapper9: values.photosPerPage === '9',
      })}
    >
      {[...new Array(photosPerPage)].map((e, i) => (
        <MultiPhotoContainer
          key={`${e}${i}`}
          className={classnames({
            'show-text': values.useMetadata === 'true',
            export4: values.photosPerPage === '4',
            export6: values.photosPerPage === '6',
            export9: values.photosPerPage === '9',
          })}
        >
          <Image>
            <StyledPhotosIcon />
          </Image>
          {values.useMetadata === 'true' && <MetadataPreviewText />}
        </MultiPhotoContainer>
      ))}
    </PDFWrapper>
  );

  return exportFormat === Format.FORMAT_PPTX ? PowerPtPreview : PdfPreview;
};

export { MultiPhotoSlide };
