import React, { useState } from 'react';
import styled from 'styled-components';
import { AllGoodIcon } from '../../../../../../components/icons';
import { useComplianceStateContext } from '../../../../../../state/Compliance/context';
import { Button } from '../../../../../../components/button';
import { Input } from '../../../../../../components/input';
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from '../../../../../../components/modal';
import { RadioButton, RadioGroup } from '../../../../../../components/radio';
import { Select, SelectItemValue } from '../../../../../../components/select';
import { useMetadata } from '../../../../../../state/Auth';
import {
  generatePlanogramFromPhotogrid,
  GeneratePlanogramFromPhotogridResponse,
} from '../../../../../../state/Compliance/wapi_planogram_api';

const StyledModalContent = styled(ModalContent)`
  gap: 24px;
`;

const Section = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const SuccessSplashWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
`;

const SuccessMessage = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const PlanogramDetailsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 24px;
  width: 100%;
  border: 1px solid var(--colors-charlestonGrey12);
  border-radius: 8px;
`;

const PlanogramTitle = styled.span`
  color: var(--colors-charlestonGrey);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

const PlanogramPropertiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const PlanogramProperty = styled.div`
  display: flex;
  gap: 4px;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  background: var(--colors-keppel10);
  color: var(--colors-charlestonGrey);

  & > span {
    color: var(--colors-charlestonGrey65);
    font-weight: 400;
  }

  &:first-of-type {
    background: var(--colors-mediumOrchid10);
  }
  &:last-of-type {
    background: var(--colors-orangeSoda10);
  }
`;

const PlanogramLink = styled.a`
  position: absolute;
  right: 16px;
  top: 22px;
  text-decoration: none;
  color: var(--colors-deepOcean);
  font-size: 12px;
  font-weight: 600;
`;

const SuccessulfDialogContent = ({
  planogram,
}: GeneratePlanogramFromPhotogridResponse) => {
  const { companyId } = useComplianceStateContext();
  const planogramWebAppURL = `${process.env.WEB_APP_URL}/companies/${companyId}/planograms/${planogram.planogramUuid}`;
  return (
    <>
      <SuccessSplashWrapper>
        <AllGoodIcon />
        <SuccessMessage>
          Great! Your upload is successfully finalized.
        </SuccessMessage>
      </SuccessSplashWrapper>
      <PlanogramDetailsWrapper>
        <PlanogramTitle>{planogram.name}</PlanogramTitle>
        <PlanogramLink href={planogramWebAppURL} target="_blank" rel="none">
          Open planogram
        </PlanogramLink>
        <PlanogramPropertiesWrapper>
          <PlanogramProperty>
            {planogram.segmentCount}
            <span>Segments</span>
          </PlanogramProperty>
          <PlanogramProperty>
            {planogram.productCount}
            <span>Total products</span>
          </PlanogramProperty>
          <PlanogramProperty>
            {planogram.unmatchedProductCount}
            <span>Unmatched products</span>
          </PlanogramProperty>
        </PlanogramPropertiesWrapper>
      </PlanogramDetailsWrapper>
    </>
  );
};

const trafficFlowOptions: SelectItemValue[] = [
  {
    label: 'Left-right',
    value: 'LeftToRight',
  },
  {
    label: 'Right-left',
    value: 'RightToLeft',
  },
];

type CreatePlanogramFromPictureDialogProps = {
  companyId: number;
  photoGridId: number;
  onCloseCallback: () => void;
};

export const CreatePlanogramFromPictureDialog = ({
  companyId,
  onCloseCallback,
  photoGridId,
}: CreatePlanogramFromPictureDialogProps) => {
  const [planogramName, setPlanogramName] = useState('');
  const [trafficFlow, setTrafficFlow] = useState<SelectItemValue | undefined>(
    trafficFlowOptions[0]
  );
  const [unitOfMeasure, setUnitOfMeasure] = useState('imperial');

  const [loading, setLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState<
    GeneratePlanogramFromPhotogridResponse
  >();

  const getMetadata = useMetadata();

  const handlePlanogramName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlanogramName(e.target.value);
  };

  const handleTrafficFlow = (value?: SelectItemValue) => {
    setTrafficFlow(value);
  };

  const handleUnitOfMeasure = (value: string) => {
    setUnitOfMeasure(value);
  };

  const createPlanogramFromPicture = () => {
    setLoading(true);
    getMetadata()
      .then(metadata => {
        generatePlanogramFromPhotogrid(
          metadata,
          companyId,
          photoGridId,
          planogramName,
          unitOfMeasure === 'metric',
          trafficFlow?.value === 'RightToLeft'
        )
          .then(result => {
            const payload = result.data;
            if (payload.generatedSuccessfully) {
              setSuccessResponse(payload);
            } else {
              alert('Failed: ' + payload);
            }
          })
          .catch(reason => {
            alert('Failed: ' + reason);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open id="Conver to planogram" onClose={onCloseCallback}>
      <ModalHeader>Convert Image To Space Planning File</ModalHeader>
      <StyledModalContent>
        {successResponse && <SuccessulfDialogContent {...successResponse} />}
        {!successResponse && (
          <>
            <Section>
              <Wrapper>
                <Title>Planogram Title</Title>
                <Input
                  placeholder="Enter planogram title"
                  name="planogramName"
                  onChange={handlePlanogramName}
                  value={planogramName}
                />
              </Wrapper>
            </Section>
            <Section>
              <Wrapper>
                <Title>Traffic flow</Title>
                <Select
                  options={trafficFlowOptions}
                  placeholder="Specify traffic flow"
                  name="planogramName"
                  onChange={handleTrafficFlow}
                  value={trafficFlow}
                />
              </Wrapper>
            </Section>
            <Section>
              <Wrapper>
                <Title>Units of measure</Title>
                <RadioGroup
                  name="Units of measure"
                  onChange={handleUnitOfMeasure}
                  value={unitOfMeasure}
                >
                  <RadioButton value="metric" label="Metric system" />
                  <RadioButton value="imperial" label="Imperial system" />
                </RadioGroup>
              </Wrapper>
            </Section>
          </>
        )}
      </StyledModalContent>
      <ModalActions>
        {successResponse && (
          <Button name="Close" onClick={onCloseCallback}>
            Close
          </Button>
        )}
        {!successResponse && (
          <>
            <Button name="Cancel" variant="outlined" onClick={onCloseCallback}>
              Cancel
            </Button>
            <Button
              name="Convert Image"
              onClick={createPlanogramFromPicture}
              disabled={!planogramName || loading}
            >
              Convert Image
            </Button>
          </>
        )}
      </ModalActions>
    </Modal>
  );
};
