import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router } from 'react-router-dom';
import './config/i18n';
import { Providers } from './state';
import { Root, App } from './App';

Sentry.init({
  blacklistUrls: ['http://localhost:4000/'],
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: 'photoalbum@' + process.env.npm_package_version,
});

ReactDOM.render(
  <Router>
    <Providers>
      <Root>
        <App />
      </Root>
    </Providers>
  </Router>,
  document.getElementById('root')
);
