import React, { FC, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '@gsc/components';
import { Logout } from '@gsc/icons/react';
import { ButtonMenu, DropdownMenuLink } from '../../../shared/components';
import {
  BorderedDiv,
  Signout,
  UserAvatar,
  UserData,
  UserInfo,
  UserInfoWrapper,
} from './elements';
import { SpaceNavDrawer } from '../SpaceNavDrawer';
import { paths } from '../../../paths';

const DesktopMenuLink = styled(DropdownMenuLink)`
  ${breakpoint.md`
    border-left: 0;
    padding: 0;
  `}
`;

interface DropdownMenuLinkProps {
  as?: any;
  dataTest?: string;
  href?: string;
  isDesktop: boolean | undefined;
  onClick?(e: SyntheticEvent): void;
  rel?: string;
  target?: string;
  to?: string;
}

const StyledDropdownMenuLink: FC<DropdownMenuLinkProps> = ({
  as,
  children,
  dataTest,
  href,
  isDesktop,
  onClick,
  rel,
  target,
  to,
}) =>
  isDesktop ? (
    <DesktopMenuLink
      as={as}
      data-test={dataTest}
      href={href}
      onClick={onClick}
      rel={rel}
      target={target}
      to={to}
    >
      {children}
    </DesktopMenuLink>
  ) : (
    <DropdownMenuLink
      as={as}
      data-test={dataTest}
      href={href}
      onClick={onClick}
      rel={rel}
      target={target}
      to={to}
    >
      {children}
    </DropdownMenuLink>
  );

interface MenuItemsProps {
  children: React.ReactNode | number;
  disabled?: boolean;
  onSelect?(): any;
}

interface SecondaryNavProps {
  buttonText: string;
  email: string;
  hasGscAccess: boolean;
  initials: string;
  isApertureAdmin: boolean;
  isDesktop: boolean;
  isSpaceAdmin: boolean;
  logout: () => void;
  multiSpace: boolean;
  name: string;
  spaceId: string;
}

const SecondaryNav: FC<SecondaryNavProps> = ({
  buttonText,
  email,
  hasGscAccess,
  initials,
  isApertureAdmin,
  isDesktop,
  isSpaceAdmin,
  logout,
  multiSpace,
  name,
  spaceId,
}) => {
  const history = useHistory();

  const userInfo: MenuItemsProps = {
    children: (
      <UserInfoWrapper
        data-test="user-info-nav-menu-item"
        key="user-info-nav-menu-item"
      >
        <UserAvatar data-test="user-info-avatar" isLarge>
          {initials}
        </UserAvatar>
        <UserInfo>
          <UserData data-test="user-info-name" isBold>
            {name.toUpperCase()}
          </UserData>
          <UserData data-test="user-info-email">{email}</UserData>
        </UserInfo>
      </UserInfoWrapper>
    ),
    disabled: true,
  };

  const spaceSwitcher: MenuItemsProps = {
    children: (
      <SpaceNavDrawer
        data-test="space-nav-menu-item"
        key="space-nav-menu-item"
      />
    ),
    disabled: true,
  };

  const privacy: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        as="a"
        dataTest="privacy-nav-menu-item"
        key="privacy-nav-menu-item"
        href={'https://www.gospotcheck.com/privacy'}
        isDesktop={isDesktop}
        rel="noopener"
        target="_blank"
      >
        Privacy policy
      </StyledDropdownMenuLink>
    ),
    onSelect: () => window.open('https://www.gospotcheck.com/privacy'),
  };

  const useTerms: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        as="a"
        dataTest="useTerms-nav-menu-item"
        key="terms-nav-menu-item"
        href={'https://www.gospotcheck.com/terms'}
        isDesktop={isDesktop}
        rel="noopener"
        target="_blank"
      >
        Terms of use
      </StyledDropdownMenuLink>
    ),
    onSelect: () => window.open('https://www.gospotcheck.com/terms'),
  };

  const exports: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        dataTest="exports-nav-menu-item"
        isDesktop={isDesktop}
        key="exports-nav-menu-item"
        to={paths.exports.root}
      >
        Exports
      </StyledDropdownMenuLink>
    ),
    onSelect: () => history.push(paths.exports.root),
  };

  const settings: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        dataTest="settings-nav-menu-item"
        isDesktop={isDesktop}
        key="settings-nav-menu-item"
        to={paths.spaces.space.settings.dataConfiguration(spaceId)}
      >
        Settings
      </StyledDropdownMenuLink>
    ),
    onSelect: () =>
      history.push(paths.spaces.space.settings.dataConfiguration(spaceId)),
  };

  const signout: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        as="div"
        dataTest="signout-nav-menu-item"
        isDesktop={isDesktop}
        key="signout-nav-menu-item"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();

          logout();
        }}
      >
        <Signout>
          <span>Sign out</span> <Logout />
        </Signout>
      </StyledDropdownMenuLink>
    ),
    onSelect: () => logout(),
  };

  const admin: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        dataTest="admin-nav-menu-item"
        isDesktop={isDesktop}
        key="admin-nav-menu-item"
        to={paths.admin.root}
      >
        Admin
      </StyledDropdownMenuLink>
    ),
    onSelect: () => history.push(paths.admin.root),
  };

  const gospotcheck: MenuItemsProps = {
    children: (
      <StyledDropdownMenuLink
        as="a"
        dataTest="gospotcheck-nav-menu-item"
        href="https://admin.gospotcheck.com/"
        isDesktop={isDesktop}
        key="gospotcheck-nav-menu-item"
        rel="noopener"
        target="_blank"
      >
        GoSpotCheck
      </StyledDropdownMenuLink>
    ),
    onSelect: () => window.open('https://admin.gospotcheck.com/'),
  };

  const desktopActions = () => {
    if (isApertureAdmin || isSpaceAdmin) {
      return multiSpace
        ? [
            userInfo,
            spaceSwitcher,
            exports,
            settings,
            privacy,
            useTerms,
            signout,
          ]
        : [userInfo, exports, settings, privacy, useTerms, signout];
    } else {
      return multiSpace
        ? [userInfo, spaceSwitcher, exports, privacy, useTerms, signout]
        : [userInfo, exports, privacy, useTerms, signout];
    }
  };

  const mobileActions = () => {
    if (isApertureAdmin) {
      return multiSpace
        ? [
            spaceSwitcher,
            exports,
            settings,
            admin,
            privacy,
            useTerms,

            gospotcheck,
            signout,
          ]
        : [exports, settings, admin, privacy, useTerms, gospotcheck, signout];
    } else if (isSpaceAdmin) {
      return multiSpace
        ? [
            spaceSwitcher,
            exports,
            settings,
            privacy,
            useTerms,

            gospotcheck,
            signout,
          ]
        : [exports, settings, privacy, useTerms, gospotcheck, signout];
    } else if (!hasGscAccess) {
      return multiSpace
        ? [spaceSwitcher, exports, privacy, useTerms, signout]
        : [exports, privacy, useTerms, signout];
    } else {
      return multiSpace
        ? [spaceSwitcher, exports, privacy, useTerms, gospotcheck, signout]
        : [exports, gospotcheck, privacy, useTerms, signout];
    }
  };

  const menuActions = () => (isDesktop ? desktopActions() : mobileActions());

  return (
    <>
      {isDesktop ? (
        <ButtonMenu
          alignRight
          buttonText={buttonText}
          dataTest="secondary-nav"
          icon={<UserAvatar>{initials}</UserAvatar>}
          items={menuActions()}
          status="neutral"
          variant="secondary"
        />
      ) : (
        menuActions().map(({ children }) => children)
      )}
    </>
  );
};

export { SecondaryNav, BorderedDiv };
