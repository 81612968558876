import React, { FC, useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  DatePicker,
  elevation,
  Input,
  Label,
  OutsideClick,
  PrimaryButton,
  SecondaryButton,
  Toggle,
  zIndex,
} from '@gsc/components';
// For what ever reason, can't get TS to be happy with importing SVG's
// import chevronDownBoldIconUrl from '@gsc/icons/svg/chevron-down-bold.svg';
const chevronDownBoldIconUrl = require('@gsc/icons/svg/chevron-down-bold.svg')
  .default as string;
import moment from 'moment';
import classnames from 'classnames';
import { isInclusivelyAfterDay } from 'react-dates';

import { EditSpaceAdminsForm } from './EditSpaceAdminsForm';
import { FormContext } from '../../../../shared/components/FormContext';
import {
  SpaceFeatureFlag,
  SpaceFeatureFlagMap,
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { paths } from '../../../../paths';
import { TierTooltip } from './TierTooltip';

interface EditSpaceFormProps {
  setResponseError(args: any): any;
}

const Form = styled.form`
  ${breakpoint.md`
    & {
      max-width: 57.6rem;
    }
  `}
`;

const StyledSection = styled.section`
  margin-bottom: 1.2rem;

  ${breakpoint.md`
    & {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }
  `}
`;

const DropdownWrapper = styled.div`
  position: relative;

  &.tier-dropdown {
    width: 100%;
  }

  &.disabled-dropdown {
    cursor: not-allowed;
  }
`;

const StyledLabel = styled(Label)`
  padding-bottom: 0;
`;

const DropdownInput = styled(Input)`
  input {
    background: ${color.ui01} url(${chevronDownBoldIconUrl}) no-repeat right
      1rem center / 1.2rem 1.2rem;
    margin: 1rem 0;
    text-transform: capitalize;
  }

  > div {
    display: none;
  }

  ${DropdownWrapper}.disabled-dropdown & {
    input {
      pointer-events: none;
      background-color: ${color.form.disabled.background};
      border: ${border.normal(color.form.disabled.border)};
      color: ${color.ui30};
    }
  }

  ${breakpoint.md`
    & {
      width: 28rem;
    }
  `}
`;

const DropdownContainer = styled.div`
  ${elevation['12']};
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  left: 0;
  overflow: hidden;
  position: absolute;
  right: auto;
  top: 90%;
  width: 100%;
  z-index: ${zIndex.hotel};

  ${breakpoint.lg`
    & {
      min-width: 28rem;
      width: auto;
    }
  `}
`;

const ListContainer = styled.ul`
  max-height: 20rem;
  overflow-y: auto;
`;

const ListItem = styled.li`
  cursor: pointer;
  display: block;
  padding: 0.8rem 1.5rem;
  width: 100%;

  &.disabled {
    background-color: ${color.ui10};
    color: ${color.ui30};
    cursor: not-allowed;
  }

  &.selected,
  &:hover {
    background-color: ${color.ui10};
  }
`;

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  a,
  button {
    margin-left: 1.6rem;
  }
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.md`
    & {
      flex-direction: row;
    }
  `}
`;

const InnerTierWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;

  ${breakpoint.md`
    align-items: unset;
    flex-direction: row;
  `}
`;

const StyledDatePicker = styled(DatePicker)`
  ${breakpoint.md`
    & {
      margin-left: 2rem;
      margin-top: 0.2rem;
      width: 28rem;
    }
  `}
`;

const PlanogramsContainer = styled.div`
  margin-top: -1.4rem;
`;

const PlanogramsToggle = styled(Toggle)`
  margin-top: 1rem;
`;

const PictureToPlanogramContainer = styled.div`
  margin-top: 1rem;
`;

const PictureToPlanogramToggle = styled(Toggle)`
  margin-top: 1rem;
`;

const EditSpaceForm: FC<EditSpaceFormProps> = ({ setResponseError }) => {
  const { errors, handleSubmit, setValues, values } = useContext(FormContext);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isTierOpen, setIsTierOpen] = useState(false);
  const [trialDate, setTrialDate] = useState(
    values.tier.trialEndDate !== 0 ? values.tier.trialEndDate : null
  );
  const [
    isPlanogramComplianceEnabled,
    setIsPlanogramComplianceEnabled,
  ] = useState(
    (values.featureFlagsList as Array<
      SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]
    >).includes(SpaceFeatureFlag.SPACE_FEATURE_FLAG_PLANOGRAM_COMPLIANCE)
  );
  const [
    isPictureToPlanogramEnabled,
    setIsPictureToPlanogramEnabled,
  ] = useState(
    (values.featureFlagsList as Array<
      SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]
    >).includes(SpaceFeatureFlag.SPACE_FEATURE_FLAG_PICTURE_TO_PLANOGRAM)
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Tab') {
      setIsStatusOpen(false);
      setIsTierOpen(false);
    }
  };

  const status = SpaceStatus;
  const tiers = SpaceTierType;

  const statusKeys = Object.keys(status).map(stat => {
    const statArray = stat.split('_');
    return statArray
      .slice(2, stat.length)
      .join(' ')
      .toLowerCase();
  });

  const tierKeys = Object.keys(tiers).map(tier => {
    const statArray = tier.split('_');
    return statArray
      .slice(3, tier.length)
      .join(' ')
      .toLowerCase();
  });

  const setStatusValues = ({ status }: any) => {
    setValues({ status });
    setIsStatusOpen(false);
  };

  const setTierValues = useCallback(
    ({ tier }: any) => {
      setValues({ tier });
      setIsTierOpen(false);
    },
    [setValues]
  );

  useEffect(() => {
    if (trialDate) {
      setTierValues({
        tier: {
          trialEndDate: parseInt(moment(trialDate).format('x')),
          type: SpaceTierType.SPACE_TIER_TYPE_TRIAL,
        },
      });
    }
  }, [setTierValues, trialDate]);

  const setFeatureFlagValue = (
    featureFlag: SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap],
    enabled: boolean
  ) => {
    // Always remove existing entry and add it if enabled
    const newList = (values.featureFlagsList as Array<
      SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]
    >).filter(o => o !== featureFlag);
    if (enabled) {
      newList.push(featureFlag);
    }
    setValues({ featureFlagsList: newList as any });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <StyledSection>
        <DropdownWrapper>
          <OutsideClick onOutsideClick={() => setIsStatusOpen(false)}>
            <StyledLabel id="status">Status</StyledLabel>
            <DropdownInput
              id="status"
              onChange={() => {}}
              onClick={() => setIsStatusOpen(true)}
              onFocus={() => setIsStatusOpen(true)}
              onKeyDown={onKeyDown}
              placeholder="Status"
              value={statusKeys[values.status]}
            />

            {isStatusOpen && (
              <DropdownContainer>
                <ListContainer>
                  <ListItem
                    className={classnames({
                      selected:
                        values.status === SpaceStatus.SPACE_STATUS_ACTIVE,
                    })}
                    onClick={() => {
                      setStatusValues({
                        status: SpaceStatus.SPACE_STATUS_ACTIVE,
                      });
                    }}
                    value={SpaceStatus.SPACE_STATUS_ACTIVE}
                  >
                    Active
                  </ListItem>
                  <ListItem
                    className={classnames({
                      selected:
                        values.status === SpaceStatus.SPACE_STATUS_INACTIVE,
                    })}
                    onClick={() => {
                      setStatusValues({
                        status: SpaceStatus.SPACE_STATUS_INACTIVE,
                      });
                    }}
                    value={SpaceStatus.SPACE_STATUS_INACTIVE}
                  >
                    Inactive
                  </ListItem>
                </ListContainer>
              </DropdownContainer>
            )}
          </OutsideClick>
        </DropdownWrapper>

        <TierContainer>
          <InnerTierWrapper>
            <DropdownWrapper
              className={classnames(
                {
                  'disabled-dropdown':
                    values.tier.type === SpaceTierType.SPACE_TIER_TYPE_CUSTOM,
                },
                'tier-dropdown'
              )}
            >
              <OutsideClick onOutsideClick={() => setIsTierOpen(false)}>
                <StyledLabel id="tier">Tier</StyledLabel>
                <DropdownInput
                  id="tier"
                  onChange={() => {}}
                  onClick={() => setIsTierOpen(true)}
                  onFocus={() => setIsTierOpen(true)}
                  onKeyDown={onKeyDown}
                  placeholder="Tier"
                  value={tierKeys[values.tier.type]}
                />

                {isTierOpen && (
                  <DropdownContainer>
                    <ListContainer>
                      <ListItem
                        className={classnames({
                          selected:
                            values.tier.type ===
                            SpaceTierType.SPACE_TIER_TYPE_LITE,
                        })}
                        onClick={() => {
                          setTierValues({
                            tier: {
                              trialEndDate: 0,
                              type: SpaceTierType.SPACE_TIER_TYPE_LITE,
                            },
                          });
                        }}
                        value={SpaceTierType.SPACE_TIER_TYPE_LITE}
                      >
                        Lite
                      </ListItem>
                      <ListItem
                        className={classnames({
                          selected:
                            values.tier.type ===
                            SpaceTierType.SPACE_TIER_TYPE_TRIAL,
                        })}
                        onClick={() => {
                          setTierValues({
                            tier: {
                              trialEndDate: parseInt(
                                moment(trialDate).format('x')
                              ),
                              type: SpaceTierType.SPACE_TIER_TYPE_TRIAL,
                            },
                          });
                        }}
                        value={SpaceTierType.SPACE_TIER_TYPE_TRIAL}
                      >
                        Trial
                      </ListItem>
                      <ListItem
                        className={classnames({
                          selected:
                            values.tier.type ===
                            SpaceTierType.SPACE_TIER_TYPE_STANDARD,
                        })}
                        onClick={() => {
                          setTierValues({
                            tier: {
                              trialEndDate: 0,
                              type: SpaceTierType.SPACE_TIER_TYPE_STANDARD,
                            },
                          });
                        }}
                        value={SpaceTierType.SPACE_TIER_TYPE_STANDARD}
                      >
                        Standard
                      </ListItem>
                      <ListItem
                        className={classnames({
                          selected:
                            values.tier.type ===
                            SpaceTierType.SPACE_TIER_TYPE_UNLIMITED,
                        })}
                        onClick={() => {
                          setTierValues({
                            tier: {
                              trialEndDate: 0,
                              type: SpaceTierType.SPACE_TIER_TYPE_UNLIMITED,
                            },
                          });
                        }}
                        value={SpaceTierType.SPACE_TIER_TYPE_UNLIMITED}
                      >
                        Unlimited
                      </ListItem>
                      <ListItem
                        className="disabled"
                        value={SpaceTierType.SPACE_TIER_TYPE_CUSTOM}
                      >
                        Custom
                      </ListItem>
                    </ListContainer>
                  </DropdownContainer>
                )}
              </OutsideClick>
            </DropdownWrapper>
            <TierTooltip />
          </InnerTierWrapper>
          <StyledDatePicker
            disabled={values.tier.type !== SpaceTierType.SPACE_TIER_TYPE_TRIAL}
            errors={errors.tier}
            id="trial-end-date"
            initialDate={trialDate && moment(trialDate)}
            isOutsideRange={(day: moment.Moment) =>
              !isInclusivelyAfterDay(day, moment())
            }
            label="Trial end date"
            onChange={(dateSelected: moment.Moment) =>
              setTrialDate(dateSelected.endOf('day'))
            }
            name="trial-end-date"
            placeholder={
              trialDate === null ? '' : moment(trialDate).format('L')
            }
          />
        </TierContainer>

        <PlanogramsContainer>
          <StyledLabel id="planogram-compliance">
            Planogram Compliance
          </StyledLabel>
          <PlanogramsToggle
            onChange={() => {
              setIsPlanogramComplianceEnabled(!isPlanogramComplianceEnabled);
              setFeatureFlagValue(
                SpaceFeatureFlag.SPACE_FEATURE_FLAG_PLANOGRAM_COMPLIANCE,
                !isPlanogramComplianceEnabled
              );
            }}
            checked={isPlanogramComplianceEnabled}
            hasLabel={false}
            id={'planogram-compliance-toggle'}
            key={'planogram-compliance-toggle'}
            name={'planogram-compliance-toggle'}
            value="true"
          ></PlanogramsToggle>
        </PlanogramsContainer>

        <PictureToPlanogramContainer>
          <StyledLabel id="picture-to-planogram">
            Picture to Planogram
          </StyledLabel>
          <PictureToPlanogramToggle
            onChange={() => {
              setIsPictureToPlanogramEnabled(!isPictureToPlanogramEnabled);
              setFeatureFlagValue(
                SpaceFeatureFlag.SPACE_FEATURE_FLAG_PICTURE_TO_PLANOGRAM,
                !isPictureToPlanogramEnabled
              );
            }}
            checked={isPictureToPlanogramEnabled}
            hasLabel={false}
            id={'picture-to-planogram-toggle'}
            key={'picture-to-planogram-toggle'}
            name={'picture-to-planogram-toggle'}
            value="true"
          ></PictureToPlanogramToggle>
        </PictureToPlanogramContainer>
      </StyledSection>

      <EditSpaceAdminsForm
        setResponseError={setResponseError}
        setValues={setValues}
      />

      <FormButtons>
        <SecondaryButton status="neutral" to={paths.admin.root}>
          Cancel
        </SecondaryButton>

        <PrimaryButton type="submit">Save Space</PrimaryButton>
      </FormButtons>
    </Form>
  );
};

export { EditSpaceForm };
