import { useContext, useCallback } from 'react';
import { AuthStateContext, AuthDispatchContext } from './context';
import * as AuthTypes from './types';
import { selectors } from './selectors';
import { grpc } from '@improbable-eng/grpc-web';
import { ActionTypes } from './actions';

// Purposefully controlling what is exposed here
const useAuth = () => {
  const context = useContext(AuthStateContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { auth0Client } = context;

  const logout = (options?: LogoutOptions) => auth0Client?.logout(options);

  return {
    hasGscAccess: context.hasGscAccess,
    isApertureAdmin: context.isApertureAdmin,
    isSpaceAdmin: context.isSpaceAdmin,
    status: context.status,
    user: context.user,
    types: AuthTypes,
    logout,
    ...selectors(context),
  };
};

const useMetadata = () => {
  const context = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);
  const auth0Client = context?.auth0Client;

  if (!context || !dispatch) {
    throw new Error('useMetadata must be used within an AuthProvider');
  }

  const { token, tokenExpiration } = context;

  return useCallback(() => {
    const metadata = new grpc.Metadata();
    const now = Date.now();

    if (!auth0Client) {
      return Promise.resolve(metadata);
    }

    if (now < tokenExpiration) {
      metadata.set('Authorization', `Bearer ${token}`);
      return Promise.resolve(metadata);
    }

    return auth0Client.getTokenSilently().then(token => {
      dispatch({
        type: ActionTypes.SetToken,
        payload: {
          token,
        },
      });

      metadata.set('Authorization', `Bearer ${token}`);

      return metadata;
    });
  }, [token, tokenExpiration, auth0Client, dispatch]);
};

export { useAuth, useMetadata };
