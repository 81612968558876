import styled from 'styled-components';
import { breakpoint, color } from '@gsc/components';

const PhotoGridLayout = styled.div`
  background-color: ${color.ui01};
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  ${breakpoint.md`
    & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  `}

  ${breakpoint.lg`
    & {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `}

  ${breakpoint.xxl`
    & {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  `}

  ${breakpoint.xxxl`
    & {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  `}
`;

export { PhotoGridLayout };
