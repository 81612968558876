import {
  Space,
  CardConfig,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

export enum SpacesStatus {
  INVALID = 'INVALID',
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export enum AdminStatus {
  INVALID_USER_ID = 5,
}

export interface State {
  spaces: {
    [key: string]: Space.AsObject;
  };
  defaultSpace: string;
  status: SpacesStatus;
}

export const defaultCardConfig = {
  fieldsList: [
    {
      role: 1,
      tableName: 'places',
      columnName: 'name',
    },
    {
      role: 2,
      tableName: 'places',
      columnName: 'address',
    },
    {
      role: 3,
      tableName: 'users',
      columnName: 'full_name',
    },
  ],
} as CardConfig.AsObject;
