import React, { FC } from 'react';
import { useAlbumsContext } from '../../../../state/Albums';
import { AlbumPhotoDetail } from './AlbumPhotoDetail';
import { DeleteAlbumModal } from './DeleteAlbumModal';
import { ShareAlbumModalDesktop } from './ShareAlbumModalDesktop';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { isMobileDevice, useWindowDimensions } from '../../../../shared/utils';
import { ExportsSettings } from '../../../Photos/screens/ExportsSettings';
import { CreateAlbumModal } from '../../../Photos/components/CreateAlbumModal';
import {
  Album,
  AlbumType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

interface AlbumQueryParamComponentsProps {
  albumId: string;
  setResponseNotification: (args: string) => any;
  showAlbumModal: boolean;
}

// eslint-disable-next-line complexity
const AlbumQueryParamComponents: FC<AlbumQueryParamComponentsProps> = ({
  albumId,
  setResponseNotification,
  showAlbumModal,
}) => {
  const {
    currentPhotoId,
    showDeleteAlbumModal,
    showExport,
    showShareAlbumModal,
    setShowExport,
  } = useQueryParams();
  const [state] = useAlbumsContext();
  const album = state.albumsById[albumId];
  const assetCount = state.albumAssetCounts[albumId];
  const albumType =
    album && (album as Album.AsObject).albumType
      ? AlbumType.ALBUM_TYPE_STANDARD
      : AlbumType.ALBUM_TYPE_SMART;

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  const hideExports = () => setShowExport(false);

  return (
    <>
      {currentPhotoId && (
        <AlbumPhotoDetail albumId={album && album.id} id={currentPhotoId} />
      )}

      {showExport && (
        <ExportsSettings
          album={album}
          albumType={albumType}
          assetCount={assetCount}
          handleCancel={() => hideExports()}
        />
      )}

      {showDeleteAlbumModal && (
        <DeleteAlbumModal
          albumId={albumId}
          setResponseNotification={setResponseNotification}
        />
      )}

      {showAlbumModal && (
        <CreateAlbumModal setResponseNotification={setResponseNotification} />
      )}

      {showShareAlbumModal && isDesktop && (
        <ShareAlbumModalDesktop
          album={album}
          setResponseNotification={setResponseNotification}
        />
      )}
    </>
  );
};

export { AlbumQueryParamComponents };
