export interface ThemeModel {
  colors: {
    black: string;
    white: string;
    charlestonGrey: string;
    charlestonGrey75: string;
    charlestonGrey65: string;
    charlestonGrey50: string;
    charlestonGrey30: string;
    charlestonGrey20: string;
    charlestonGrey12: string;
    charlestonGrey6: string;
    charlestonGrey4: string;
    deepOcean: string;
    deepOcean50: string;
    deepOcean20: string;
    deepOcean40: string;
    deepOcean16: string;
    deepOcean8: string;
    deepOcean6: string;
    pompadour: string;
    pompadour75: string;
    pompadour50: string;
    pompadour25: string;
    pompadour10: string;
    gingerMonkey: string;
    gingerMonkey75: string;
    gingerMonkey50: string;
    gingerMonkey25: string;
    gingerMonkey10: string;
    yellow: string;
    yellow75: string;
    yellow50: string;
    yellow25: string;
    yellow10: string;
    chalky: string;
    chalky75: string;
    chalky50: string;
    chalky25: string;
    chalky10: string;
    orangeSoda: string;
    orangeSoda75: string;
    orangeSoda50: string;
    orangeSoda25: string;
    orangeSoda10: string;
    brightTurquoise: string;
    brightTurquoise75: string;
    brightTurquoise50: string;
    brightTurquoise25: string;
    brightTurquoise10: string;
    blueCola: string;
    blueCola75: string;
    blueCola50: string;
    blueCola25: string;
    blueCola10: string;
    keppel: string;
    keppel75: string;
    keppel50: string;
    keppel25: string;
    keppel10: string;
    viridianGreen: string;
    viridianGreen75: string;
    viridianGreen50: string;
    viridianGreen25: string;
    viridianGreen10: string;
    chinaPink: string;
    chinaPink75: string;
    chinaPink50: string;
    chinaPink25: string;
    chinaPink10: string;
    mediumOrchid: string;
    mediumOrchid75: string;
    mediumOrchid50: string;
    mediumOrchid25: string;
    mediumOrchid10: string;
    success: string;
    success10: string;
    success25: string;
    fail: string;
    fail10: string;
    fail25: string;
    warning: string;
    warning10: string;
    warning25: string;
    [x: string]: string | number;
  };
  space: Record<string | number, string | number>;
  fontSizes: {
    displayLarge: string;
    displayMedium: string;
    displaySmall: string;
    headlineLarge: string;
    headlineMedium: string;
    headlineSmall: string;
    titleLarge: string;
    titleMedium: string;
    titleSmall: string;
    bodyLarge: string;
    bodyMedium: string;
    bodySmall: string;
    labelLarge: string;
    labelMedium: string;
    labelSmall: string;
    [x: string]: string | number;
  };
  fonts: {
    main: string;
    [x: string]: string | number;
  };
  fontWeights: Record<string | number, string | number>;
  lineHeights: Record<string | number, string | number>;
  letterSpacings: Record<string | number, string | number>;
  sizes: Record<string | number, string | number>;
  borderWidths: Record<string | number, string | number>;
  borderStyles: Record<string | number, string | number>;
  radii: Record<string | number, string | number>;
  shadows: Record<string | number, string>;
  zIndices: Record<string | number, number>;
  transitions: Record<string | number, string | number | boolean>;
  extras: {
    breadcrumbSeparator: string;
    [x: string]: string | number;
  };
}

export const baseTheme: ThemeModel = {
  colors: {
    black: '#010021',
    white: '#FFFFFF',
    charlestonGrey: '#262B2F',
    charlestonGrey75: '#5C6063',
    charlestonGrey65: '#727578',
    charlestonGrey50: '#929597',
    charlestonGrey30: '#BEBFC1',
    charlestonGrey20: '#D4D5D5',
    charlestonGrey12: '#E5E6E6',
    charlestonGrey6: '#F2F2F3',
    charlestonGrey4: '#F6F7F7',
    deepOcean: '#1B7899',
    deepOcean50: '#0E3C4D',
    deepOcean20: '#16607A',
    deepOcean40: '#A4C9D6',
    deepOcean16: '#DBE9EF',
    deepOcean8: '#EDF4F7',
    deepOcean6: '#F1F7F9',
    pompadour: '#781D42',
    pompadour75: '#9A5571',
    pompadour50: '#BB8EA0',
    pompadour25: '#DDC6D0',
    pompadour10: '#F2E8EC',
    gingerMonkey: '#CA6702',
    gingerMonkey75: '#D78D41',
    gingerMonkey50: '#E4B380',
    gingerMonkey25: '#F2D9C0',
    gingerMonkey10: '#FAF0E6',
    yellow: '#F1BA24',
    yellow75: '#F4CB5B',
    yellow50: '#F8DC91',
    yellow25: '#FBEEC8',
    yellow10: '#FEF8E9',
    chalky: '#E9D8A6',
    chalky75: '#EEE2BC',
    chalky50: '#F4EBD2',
    chalky25: '#F9F5E9',
    chalky10: '#FDFBF6',
    orangeSoda: '#F45C3A',
    orangeSoda75: '#F7856B',
    orangeSoda50: '#F9AD9C',
    orangeSoda25: '#FCD6CE',
    orangeSoda10: '#FEEFEB',
    brightTurquoise: '#00D9EB',
    brightTurquoise75: '#40E2F0',
    brightTurquoise50: '#80ECF5',
    brightTurquoise25: '#BFF5FA',
    brightTurquoise10: '#E6FBFD',
    blueCola: '#0B98E5',
    blueCola75: '#48B2EB',
    blueCola50: '#85CBF2',
    blueCola25: '#CEEAFA',
    blueCola10: '#E7F5FC',
    keppel: '#37B496',
    keppel75: '#69C7B0',
    keppel50: '#9BD9CA',
    keppel25: '#CDECE5',
    keppel10: '#EBF7F5',
    viridianGreen: '#0A9396',
    viridianGreen75: '#47AEB0',
    viridianGreen50: '#84C9CA',
    viridianGreen25: '#C2E4E5',
    viridianGreen10: '#E7F4F5',
    chinaPink: '#E861B2',
    chinaPink75: '#EE88C5',
    chinaPink50: '#F3B0D8',
    chinaPink25: '#F9D7EC',
    chinaPink10: '#FDEFF7',
    mediumOrchid: '#C661E8',
    mediumOrchid75: '#D488EE',
    mediumOrchid50: '#E2B0F3',
    mediumOrchid25: '#F4DFFA',
    mediumOrchid10: '#F9EFFD',
    success: '#046C41',
    success10: '#DCF5EA',
    success25: '#C0DACF',
    fail: '#D0021B',
    fail10: '#FAE6E8',
    fail25: '#F3C0C6',
    warning: '#EE9B00',
    warning10: '#FDF3E0',
    warning25: '#FBE6BF',
  },
  space: { placeholder: '' },
  fontSizes: {
    displayLarge: '3.5625rem',
    displayMedium: '2.8125rem',
    displaySmall: '2.375rem',
    headlineLarge: '2rem',
    headlineMedium: '1.75rem',
    headlineSmall: '1.5rem',
    titleLarge: '1.375rem',
    titleMedium: '1rem',
    titleSmall: '0.875rem',
    bodyLarge: '1rem',
    bodyMedium: '0.875rem',
    bodySmall: '0.75rem',
    labelLarge: '0.875rem',
    labelMedium: '0.75rem',
    labelSmall: '0.6875rem',
  },
  fonts: {
    main: 'Helvetica Neue',
  },
  fontWeights: { placeholder: '' },
  lineHeights: { placeholder: '' },
  letterSpacings: { placeholder: '' },
  sizes: {
    breakpointSm: '600px',
    breakpointMd: '900px',
    breakpointLg: '1200px',
    breakpointXl: '1536px',
  },
  borderWidths: { placeholder: '' },
  borderStyles: { placeholder: '' },
  radii: { placeholder: '' },
  shadows: { placeholder: '' },
  zIndices: {
    initial: 0,
    overlay: 3,
    drawer: 8,
    modal: 9,
  },
  transitions: {
    defaultDelay: '400ms',
    closeSnackAlertDelay: 1000,
  },
  extras: {
    breadcrumbSeparator: "'/'",
  },
};
