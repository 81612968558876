import React, {
  CSSProperties,
  FC,
  HTMLAttributes,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {
  border,
  color,
  font,
  SecondaryButton,
  TertiaryButton,
  zIndex,
} from '@gsc/components';
import { Preferences, Sort, SuccessCheckmark } from '@gsc/icons/react';
import useComponentSize from '@rehooks/component-size';

import { ActionTypes, usePhotosContext } from '../../../state/Photos';
import { ColumnType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../state/Spaces';
import { isMobileDevice, useWindowDimensions } from '../../../shared/utils';
import { MobileTakeOver } from '../../../shared/components';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import {
  DATE_PROPERTY_NAME,
  DATE_REFERENCE_NAME,
} from '../../../state/QueryParams/types';
import { formatFilterText } from '../shared/filters/formatFilterText';
import { FilterComponentProps, FilterButton } from './filters';

interface SpaceRetainerProps extends HTMLAttributes<any> {
  height: number;
  isDesktop: boolean | undefined;
}

const SpaceRetainer = styled.div(
  ({ height, isDesktop }: SpaceRetainerProps) => `
  height: ${isDesktop ? `calc(${height / 10}rem + 6.4rem)` : '11.3rem'};
  `
);

const SelectModeButton = styled(SecondaryButton)`
  margin-top: 0.8rem;
  position: fixed;
  right: 2.4rem;
  top: 5.6rem;
  visibility: visible;
  z-index: ${zIndex.golf + 1};

  .takeover-active &,
  &.exports-active {
    visibility: hidden;
  }
`;

const StyledTertiaryButton = styled(TertiaryButton)`
  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: ${color.ui01};
    border: 0;
    box-shadow: none;
    color: ${color.ui30};
  }
`;

const StyledMobileTakeOver = styled(MobileTakeOver)`
  background-color: ${color.ui01};
  border-bottom: ${border.normal(color.ui15)};
  left: 0;
  padding: 0.8rem 2.4rem;
  position: fixed;
  top: 5.6rem;
  visibility: visible;
  width: 100%;

  &.exports-active {
    visibility: hidden;
  }
`;

const DesktopMobileTakeOver = styled(StyledMobileTakeOver)`
  padding: 0.4rem 3.2rem;
  z-index: 3;
`;

const MobileForm = styled.form`
  padding: 0 2.4rem;
`;

const DesktopForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  & > div {
    align-items: center;
    display: flex;
  }
`;

const FilterHeader = styled.div`
  font-size: 1.6rem;
  font-weight: ${font.weight.medium};
`;

interface MobileTakeOverComponentProps {
  Button: any;
  buttonText: any;
  className: string;
  dataTest: string;
  headerContent: any;
  isDesktop: boolean | undefined;
  status: string;
  style?: CSSProperties;
}

const MobileTakeOverComponent: FC<MobileTakeOverComponentProps> = ({
  Button,
  buttonText,
  children,
  className,
  dataTest,
  headerContent,
  isDesktop,
  status,
  style,
}) =>
  isDesktop ? (
    <DesktopMobileTakeOver
      Button={Button}
      buttonText={buttonText}
      className={className}
      dataTest={dataTest}
      headerContent={headerContent}
      status={status}
      style={style}
    >
      {children}
    </DesktopMobileTakeOver>
  ) : (
    <StyledMobileTakeOver
      Button={Button}
      buttonText={buttonText}
      className={className}
      dataTest={dataTest}
      headerContent={headerContent}
      status={status}
      style={style}
    >
      {children}
    </StyledMobileTakeOver>
  );

interface StyledFormProps {
  filterBarRef: RefObject<HTMLFormElement>;
  isDesktop: boolean;
}

const StyledForm: FC<StyledFormProps> = ({
  children,
  filterBarRef,
  isDesktop,
}) =>
  isDesktop ? (
    <DesktopForm ref={filterBarRef}>{children}</DesktopForm>
  ) : (
    <MobileForm>{children}</MobileForm>
  );

const SortSelectionIndicator = styled(SuccessCheckmark)`
  width: 1.2rem;
  margin-left: auto;
`;

const SortOptionsHeader = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
`;

const SortOption = styled.button`
  width: 100%;
  height: 4.8rem;
  display: flex;
  background: white;
  padding: 0 2.4rem;
  align-items: center;
  color: #6b717c;
  font-size: 1.4rem;
  line-height: 2rem;
  border-bottom: 0.1rem solid #dadcde;
`;

const FilterBar: FC = () => {
  const [{ mobileSelectMode }, dispatch] = usePhotosContext();
  const {
    searchFilters,
    setSearchFilters,
    showExport,
    sortField,
    sortDirection,
    setSortFieldAndDirection,
    setSortDirection,
  } = useQueryParams();
  const currentSpace = useCurrentSpaceObj();
  const assetDetailConfig = currentSpace.config?.assetDetailConfig;

  const largerWindow = useWindowDimensions().width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  const filterBarRef = useRef<HTMLFormElement>(null);
  const filterBarSize = useComponentSize(filterBarRef);

  const setSelectMode = (select: boolean) => {
    dispatch({
      type: ActionTypes.SetMobileSelectMode,
      payload: {
        mobileSelectMode: select,
      },
    });
  };

  const tablesList = assetDetailConfig?.tablesList ?? [];
  const filtersToRender: FilterComponentProps[] = useMemo(
    () => [
      {
        filterText: 'Date',
        tableName: DATE_REFERENCE_NAME,
        columnName: DATE_PROPERTY_NAME,
        columnType: ColumnType.COLUMN_TYPE_DATETIME,
        canFilter: true,
      },
      ...tablesList
        ?.flatMap(table =>
          table.columnConfigsList.map(column => ({
            tableName: table.tableName,
            columnName: column.columnName,
            columnType: column.type,
            canFilter: column.canFilter,
            filterText: formatFilterText(table, column),
            filterCount: searchFilters.filter(
              filter =>
                filter.propertyName === column.columnName &&
                filter.referenceName === table.tableName
            ).length,
          }))
        )
        .sort((a, b) => {
          const aFilterText = a.filterText.toLowerCase();
          const bFilterText = b.filterText.toLowerCase();
          if (aFilterText > bFilterText) return 1;
          if (aFilterText < bFilterText) return -1;
          return 0;
        }),
    ],
    [tablesList, searchFilters]
  );

  const sortOptionsToRender = useMemo(
    () => [
      {
        children: <SortOptionsHeader>Order</SortOptionsHeader>,
        style: { height: '2rem', alignItems: 'flex-end', paddingBottom: 8 },
      },

      {
        onClick: () => setSortDirection('+'),
        children: (
          <>
            Ascending
            {sortDirection === '+' && <SortSelectionIndicator />}
          </>
        ),
      },
      {
        onClick: () => setSortDirection('-'),
        children: (
          <>
            Descending
            {sortDirection === '-' && <SortSelectionIndicator />}
          </>
        ),
      },

      {
        children: <SortOptionsHeader>Sort By</SortOptionsHeader>,
        style: { alignItems: 'flex-end', paddingBottom: 8 },
      },

      ...filtersToRender
        .filter(f => f.canFilter)
        .map(({ filterText, tableName, columnName }) => ({
          onClick: () => {
            setSortFieldAndDirection(
              `${tableName}.${columnName}`,
              `${tableName}.${columnName}` === 'mission_responses.completed_at'
                ? '-'
                : '+'
            );
          },
          children: (
            <React.Fragment key={filterText}>
              {filterText}
              {sortField === `${tableName}.${columnName}` && (
                <SortSelectionIndicator />
              )}
            </React.Fragment>
          ),
        })),
    ],
    [
      filtersToRender,
      setSortDirection,
      setSortFieldAndDirection,
      sortDirection,
      sortField,
    ]
  );

  return (
    <>
      <SpaceRetainer
        id="header-space-retainer"
        height={filterBarSize.height}
        isDesktop={isDesktop}
      />

      {isMobileDevice() && (
        <SelectModeButton
          className={classnames({ 'exports-active': showExport })}
          onClick={() => setSelectMode(!mobileSelectMode)}
          status="neutral"
        >
          {mobileSelectMode ? 'Cancel' : 'Select'}
        </SelectModeButton>
      )}

      <MobileTakeOverComponent
        Button={SecondaryButton}
        buttonText={
          <span>
            <Preferences />
            Filters
          </span>
        }
        className={classnames('filter-bar', { 'exports-active': showExport })}
        dataTest="filter-bar"
        headerContent={
          <>
            <span />
            <FilterHeader>Filters</FilterHeader>
            <StyledTertiaryButton
              status="neutral"
              onClick={() => setSearchFilters([])}
            >
              Clear all
            </StyledTertiaryButton>
          </>
        }
        isDesktop={isDesktop}
        status="neutral"
      >
        <StyledForm isDesktop={isDesktop} filterBarRef={filterBarRef}>
          {filtersToRender.map(props => (
            <FilterButton key={props.tableName + props.columnName} {...props} />
          ))}
        </StyledForm>
      </MobileTakeOverComponent>

      {!isDesktop && (
        <MobileTakeOverComponent
          Button={SecondaryButton}
          buttonText={
            <span>
              <Sort
                style={{
                  transform: sortDirection === '+' ? 'scaleY(-1)' : '',
                  marginRight: '1rem',
                }}
              />
              Sort
            </span>
          }
          className={classnames({ 'exports-active': showExport })}
          dataTest="filter-bar"
          headerContent={
            <>
              <FilterHeader>Sort</FilterHeader>
              <span style={{ width: '3rem' }} />
            </>
          }
          isDesktop={isDesktop}
          status="neutral"
          style={{ left: '12.5rem', paddingLeft: '0.8rem' }}
        >
          {sortOptionsToRender.map((props, i) => (
            <SortOption key={i} {...props} />
          ))}
        </MobileTakeOverComponent>
      )}
    </>
  );
};

export { FilterBar };
