import React, { FC } from 'react';
import styled from 'styled-components';
import { SearchForm } from '@gsc/components';
import {
  ActionTypes,
  useAlbumsAPI,
  useAlbumsDispatch,
} from '../../state/Albums';
import { Album } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

interface AlbumsSearchInputProps {
  setRenderedAlbumsList?: (args: Album.AsObject[]) => any;
}

const StyledSearchForm = styled(SearchForm)`
  width: 24rem;
`;

const AlbumsSearchInput: FC<AlbumsSearchInputProps> = ({
  setRenderedAlbumsList,
}) => {
  const albumsAPI = useAlbumsAPI();
  const dispatch = useAlbumsDispatch();

  const handleAlbumSearch = (value: string) => {
    albumsAPI()
      .then(({ listAlbums }) => {
        return listAlbums({ nameMatchValue: value });
      })
      .then(list => {
        dispatch({
          type: ActionTypes.PageLoaded,
          payload: { pageNumber: 0, response: list },
        });
        return setRenderedAlbumsList?.(list.toObject().albumsList);
      });
  };

  const handleInputChange = (value: string) => {
    handleAlbumSearch(value);
  };

  return (
    <StyledSearchForm
      onSubmit={handleInputChange}
      name="album-search-input"
      placeholderText="Search albums..."
    />
  );
};

export { AlbumsSearchInput };
