import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useMemo } from 'react';
import {
  getImageRecognitionTags,
  getProperty,
} from '../../../screens/Photos/shared/utils/asset';

export const useAssetDetailTags = (
  assetDetail?: AssetDetail.AsObject
): { companyId?: number; photoGridId?: number } => {
  return useMemo(() => {
    const tags = getImageRecognitionTags(assetDetail);

    let companyId: number | undefined;
    let photoGridId: number | undefined;

    if (tags.length > 0) {
      const tag = tags[0];
      companyId = parseInt(getProperty(tag, 'company_id'));
      if (isNaN(companyId)) {
        companyId = undefined;
      }

      photoGridId = parseInt(getProperty(tag, 'photo_grid_id'));
      if (isNaN(photoGridId)) {
        photoGridId = undefined;
      }
    }

    return { companyId, photoGridId };
  }, [assetDetail]);
};
