import { User } from '../../state/Auth/types';
import { Space } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

interface PendoParams {
  user: User;
  space: Space.AsObject;
  isApertureAdmin: boolean;
}

const initPendo = ({ user, space, isApertureAdmin }: PendoParams) => {
  window.pendo.initialize({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: user.name,
      gospotcheckAdmin: isApertureAdmin,
    },

    account: {
      id: space.id,
      name: space.name,
      customerIdsList: space.customerIdsList,
      status: space.status,
      createdAt: space.createdAtTime,
    },
  });
};

const updatePendo = ({ user, space, isApertureAdmin }: PendoParams) => {
  window.pendo.updateOptions({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: user.name,
      gospotcheckAdmin: isApertureAdmin,
    },

    account: {
      id: space.id,
      name: space.name,
      customerIdsList: space.customerIdsList,
      status: space.status,
      createdAt: space.createdAtTime,
    },
  });
};

export { initPendo, updatePendo };
