import { MutableRefObject, useRef, useEffect } from 'react';

const useAutofocus = () => {
  const ref: MutableRefObject<any> = useRef();

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return ref;
};

export { useAutofocus };
