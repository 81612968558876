import React, { FC, useCallback, useEffect, useState } from 'react';
import { AutoSizer, Grid, WindowScroller } from 'react-virtualized';
import { Notification } from '@gsc/components';
import {
  ActionTypes,
  usePhotosAPI,
  usePhotosContext,
} from '../../../../../state/Photos';
import {
  PhotoDetailsPanels,
  useQueryParams,
} from '../../../../../state/QueryParams/hooks';
import { useWindowDimensions } from '../../../../../shared/utils';
import {
  getColumnCount,
  queuePageForLoading,
} from '../../../../Photos/screens/PhotoGrid/utils';
import { PhotoCard } from '../../../../Photos/shared/components/PhotoCard';
import {
  NotificationTitle,
  NotificationWrapper,
} from '../../../../Photos/screens/PhotoGrid/components/Notifications';
import { PhotoGridLayout } from '../../../../Photos/screens/PhotoGrid/PhotoGridLayout';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const RegularGrid: FC = () => {
  const [
    {
      assetDetailsById,
      assetDetailsPages,
      loading,
      pagination,
      totalHits,
      selectedPhotos,
      mobileSelectMode,
    },
    dispatch,
  ] = usePhotosContext();
  const {
    getFilters,
    sortField,
    sortDirection,
    setShowPhoto,
    currentPhotoId,
    selectAlbumId,
  } = useQueryParams();
  const api = usePhotosAPI();
  const { width: windowWidth } = useWindowDimensions();
  const isDesktop = windowWidth >= 1024;

  const maxResults = 9999;
  const pageSize = pagination?.size ?? 30;
  const columnCount = getColumnCount(windowWidth);
  const photoCount = Math.min(totalHits, maxResults - pageSize);
  const rowCount = Math.ceil(photoCount / columnCount);

  const loadingArray = new Array(isDesktop ? 15 : 6).fill(' ');
  const loadingCards = loadingArray.map((photo, index) => (
    <PhotoCard id={`loading_photo_${index}`} key={`loading_photo_${index}`} />
  ));

  const loadMoreRows = useCallback(
    (pageNumber: number) => {
      if (assetDetailsPages[`page${pageNumber}`]) return;
      dispatch({
        type: ActionTypes.SetPageLoading,
        payload: { pageNumber },
      });

      api()
        .then(({ getPhotoSearch }) =>
          getPhotoSearch({
            filters: getFilters(),
            from: pageNumber * pageSize,
            sortField,
            sortDirection,
          })
        )
        .then(photos => {
          dispatch({
            type: ActionTypes.GetPhotosResponse,
            payload: { photos, pageNumber },
          });
        });
    },
    [
      assetDetailsPages,
      pageSize,
      dispatch,
      api,
      getFilters,
      sortField,
      sortDirection,
    ]
  );

  const renderHasMoreIndicator = () => {
    if (totalHits === null || totalHits <= maxResults - pageSize) return null;

    return (
      <NotificationWrapper>
        <Notification status="info">
          <NotificationTitle>
            Whoa, you just scrolled through 10,000 photos!
          </NotificationTitle>
          <br />
          That’s the maximum this page can show. If you need to go back even
          further, try adding more filters or adjusting the date range for your
          search.
        </Notification>
      </NotificationWrapper>
    );
  };

  const handleSelectedPhoto = (id: string) => {
    dispatch({
      type: ActionTypes.TogglePhotoSelected,
      payload: { id },
    });
  };

  const [drag, setDrag] = useState<string[] | null>(null);
  useEffect(() => {
    const onMouseUp = () => {
      if (drag) setDrag(null);
    };

    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [drag]);

  const cellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    key?: string;
    rowIndex: number;
    style: any;
  }) => {
    const photoIndex = rowIndex * columnCount + columnIndex;
    const pageNumber = Math.floor(photoIndex / pageSize);
    const page = assetDetailsPages[`page${pageNumber}`];

    if (photoIndex + 1 > totalHits) {
      return null;
    } else if (!page) {
      queuePageForLoading(pageNumber, loadMoreRows);
    } else {
      const photo: AssetDetail.AsObject | undefined =
        assetDetailsById[page?.ids?.[photoIndex % pageSize]];

      if (photo) {
        return (
          <PhotoCard
            checked={selectedPhotos.includes(photo.id)}
            handleChange={() => handleSelectedPhoto(photo.id)}
            onClick={() => {
              setShowPhoto(photo.id, PhotoDetailsPanels.COMPLIANCE_SUMMARY);
            }}
            id={photo.id}
            key={key}
            loading={false}
            photo={photo}
            selectMode={
              mobileSelectMode || selectedPhotos.length > 0 || selectAlbumId
            }
            suspended={!!currentPhotoId}
            style={style}
            url={photo.assetUrl}
            onMouseDown={() => {
              setDrag([photo.id]);
            }}
            onMouseEnter={() => {
              if (drag) {
                const newDrag = [...drag, photo.id];

                if (newDrag.length === 2) {
                  dispatch({
                    type: ActionTypes.TogglePhotoSelected,
                    payload: { id: drag[0] },
                  });
                }

                dispatch({
                  type: ActionTypes.TogglePhotoSelected,
                  payload: { id: photo.id },
                });

                setDrag(newDrag);
              }
            }}
          />
        );
      }
    }

    return (
      <PhotoCard
        id={`loading_photo_${columnIndex}_${rowIndex}_index_${photoIndex}`}
        key={key}
        style={style}
      />
    );
  };

  const renderGrid = ({
    height,
    scrollTop,
  }: {
    height: number;
    scrollTop: number;
  }) => (
    <>
      {loading ? (
        <PhotoGridLayout>{loadingCards}</PhotoGridLayout>
      ) : (
        <AutoSizer disableHeight>
          {({ width }) => {
            const tileAspectRatio = 1.3525;
            const columnWidth = Math.floor(width / columnCount);
            const rowHeight = columnWidth * tileAspectRatio;

            return (
              <Grid
                autoHeight
                className="photoalbum-photo-grid"
                columnCount={columnCount}
                columnWidth={columnWidth}
                height={height}
                rowCount={rowCount}
                rowHeight={rowHeight}
                scrollTop={scrollTop}
                width={width}
                cellRenderer={cellRenderer}
                overscanRowCount={3}
              />
            );
          }}
        </AutoSizer>
      )}

      {renderHasMoreIndicator()}
    </>
  );

  return <WindowScroller>{renderGrid}</WindowScroller>;
};

export { RegularGrid };
