import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoint, color, SecondaryButton } from '@gsc/components';
import { Add, Export } from '@gsc/icons/react';
import classnames from 'classnames';
import { isMobileDevice } from '../../../shared/utils';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import { useCurrentSpaceObj } from '../../../state/Spaces';
import { SpaceTierType } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

interface SelectedPhotosActionButtonsProps {
  MAX_SELECTABLE_PHOTOS: number;
  selectedPhotos: string[];
}

const PhotoActionsWrapper = styled.div`
  position: fixed;
  right: 2.4rem;

  & button:first-child {
    margin-right: 1.6rem;
  }

  ${breakpoint.md`
    & {
      display: flex;
      justify-content: center;
      left: calc(50% - 18.8rem);
      position: fixed;
      right: calc(50% - 18.8rem);
    }
  `}
`;

const ActionsButton = styled(SecondaryButton)`
  line-height: 1.6rem;
  margin-bottom: 0;
`;

const StyledAddIcon = styled(Add)`
  &.mobile-icon {
    ${ActionsButton}:disabled & {
      color: ${color.ui30};
    }
  }
`;

const StyledExportIcon = styled(Export)`
  &.mobile-icon {
    ${ActionsButton}:disabled & {
      color: ${color.ui30};
    }
  }
`;

const SelectedPhotosActionButtons: FC<SelectedPhotosActionButtonsProps> = ({
  selectedPhotos,
  MAX_SELECTABLE_PHOTOS,
}) => {
  const { setShowAlbumModal, setShowExport } = useQueryParams();
  const currentSpaceObj = useCurrentSpaceObj();

  const onLiteTier =
    currentSpaceObj?.tier?.type === SpaceTierType.SPACE_TIER_TYPE_LITE;

  const openAlbumModal = () => setShowAlbumModal(true);
  const openExportsScreen = () => setShowExport(true);

  return (
    <PhotoActionsWrapper>
      {!onLiteTier && (
        <ActionsButton
          disabled={
            selectedPhotos.length === 0 ||
            selectedPhotos.length > MAX_SELECTABLE_PHOTOS
          }
          isIconOnly={isMobileDevice()}
          onClick={() => openAlbumModal()}
          status="neutral"
          title="Add to Album"
        >
          <StyledAddIcon
            className={classnames({ 'mobile-icon': isMobileDevice() })}
          />
          {!isMobileDevice() && 'Add to Album'}
        </ActionsButton>
      )}
      <ActionsButton
        disabled={
          selectedPhotos.length === 0 ||
          selectedPhotos.length > MAX_SELECTABLE_PHOTOS
        }
        isIconOnly={isMobileDevice()}
        onClick={() => openExportsScreen()}
        status="neutral"
        title="Show Exports"
      >
        <StyledExportIcon
          className={classnames({ 'mobile-icon': isMobileDevice() })}
        />
        {!isMobileDevice() && 'Export'}
      </ActionsButton>
    </PhotoActionsWrapper>
  );
};

export { SelectedPhotosActionButtons };
