import { Dispatch, useContext, useCallback } from 'react';
import { Actions } from './actions';
import { ExportsDispatchContext, ExportsStateContext } from './context';
import { State } from './reducer';
import { api } from './api';
import { useMetadata } from '../Auth';

const useExportsState = () => {
  const context = useContext(ExportsStateContext);

  if (!context) {
    throw new Error('useExportsState must be used within an ExportsProvider');
  }
  return context;
};

const useExportsDispatch = () => {
  const context = useContext(ExportsDispatchContext);

  if (!context) {
    throw new Error(
      'useExportsDispatch must be used within an ExportsProvider'
    );
  }

  return context;
};

const useExportsAPI = () => {
  const getMetadata = useMetadata();

  return useCallback(() => {
    return getMetadata().then(metadata => {
      return api(metadata);
    });
  }, [getMetadata]);
};

const useExportsContext = (): [State, Dispatch<Actions>] => [
  useExportsState(),
  useExportsDispatch(),
];

export {
  useExportsAPI,
  useExportsState,
  useExportsDispatch,
  useExportsContext,
};
