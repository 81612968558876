import React, { forwardRef } from 'react';

import { getSelectedValues } from '../../utils';
import { useSelectContext } from '../select-context';

import { Container, Indicator } from './select-item.styles';

export type SelectItemValue = { label: string; value: string | number };

export type SelectItemProps = {
  children?: React.ReactText;
  className?: string;
  dataTestId?: string;
  indicator?: React.ReactNode;
  indicatorClass?: string;
  value: SelectItemValue;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: SelectItemValue
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
};

const DefaultIndicator = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 6.99997L19.5902 5.58997L9.00016 16.17Z"
      fill="currentColor"
    />
  </svg>
);

const SelectItemInner = (
  {
    className,
    children,
    indicator = <DefaultIndicator />,
    indicatorClass,
    onClick,
    value,
    ...props
  }: SelectItemProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { onSelect, selectedValues } = useSelectContext();

  const valueSelected = getSelectedValues(selectedValues);

  const itemValue = getSelectedValues(value) as string | number;

  const isSelected = Array.isArray(valueSelected)
    ? valueSelected.includes(itemValue)
    : valueSelected === itemValue;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick?.(e, value);
    onSelect?.(value);
  };
  return (
    <Container className={className} {...props} ref={ref} onClick={handleClick}>
      <span>{children}</span>
      {isSelected && (
        <Indicator className={indicatorClass}>{indicator}</Indicator>
      )}
    </Container>
  );
};

export const SelectItem = forwardRef(SelectItemInner);
