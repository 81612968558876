import React, { FC } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { breakpoint } from '@gsc/components';

import { MenuLink, Sidebar } from '../../shared/components';
import { CardLayout } from './screens/CardLayout';
import { DataConfig } from './screens/DataConfig';
import { useCurrentSpace } from '../../state/Spaces';
import { paths } from '../../paths';

const SettingsLayout = styled.div`
  ${breakpoint.lg`
    & {
      display: flex;
    }
  `}
`;

const Settings: FC = () => {
  const { id } = useCurrentSpace();

  return (
    <SettingsLayout>
      <Helmet>
        <title>PhotoWorks - Settings page</title>
        <meta name="description" content="Config info" />
      </Helmet>

      <Sidebar
        dataTest="settings-sidebar"
        title="Settings"
        links={[
          <MenuLink
            activeClassName="active"
            as={NavLink}
            exact
            key="data-config-link"
            to={paths.spaces.space.settings.dataConfiguration(id)}
          >
            Data configuration
          </MenuLink>,
          <MenuLink
            activeClassName="active"
            as={NavLink}
            exact
            key="card-layout-link"
            to={paths.spaces.space.settings.cardLayout(id)}
          >
            Card layout
          </MenuLink>,
        ]}
      />

      <Switch>
        <Route exact path="/spaces/:spaceId/settings/data-configuration">
          <DataConfig />
        </Route>
        <Route exact path="/spaces/:spaceId/settings/card-layout">
          <CardLayout />
        </Route>
        <Redirect to="/spaces/:spaceId/settings/data-configuration" />
      </Switch>
    </SettingsLayout>
  );
};

export { Settings };
