import { Dispatch, useContext, useCallback } from 'react';
import { Actions } from './actions';
import { PhotosStateContext, PhotosDispatchContext } from './context';
import { State } from './reducer';
import { api } from './api';
import { useMetadata } from '../Auth';
import { useCurrentSpace } from '../Spaces';

const usePhotosState = () => {
  const context = useContext(PhotosStateContext);

  if (!context) {
    throw new Error('usePhotosState must be used within a PhotosProvider');
  }
  return context;
};

const usePhotosDispatch = () => {
  const context = useContext(PhotosDispatchContext);

  if (!context) {
    throw new Error('usePhotosDispatch must be used within a PhotosProvider');
  }

  return context;
};

const usePhotosAPI = () => {
  const getMetadata = useMetadata();
  const { id } = useCurrentSpace();

  return useCallback(() => {
    return getMetadata().then(metadata => {
      return api(metadata, id);
    });
  }, [id, getMetadata]);
};

const usePhotosContext = (): [State, Dispatch<Actions>] => [
  usePhotosState(),
  usePhotosDispatch(),
];

export { usePhotosAPI, usePhotosState, usePhotosDispatch, usePhotosContext };
