import { Filter } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';

const serialize = (value: Filter.AsObject): Filter => {
  const filter = new Filter();
  filter.setReferenceName(value.referenceName);
  filter.setPropertyName(value.propertyName);
  filter.setOperator(value.operator);
  filter.setValuesList(value.valuesList);

  return filter;
};

export { serialize };
