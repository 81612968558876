import { grpc } from '@improbable-eng/grpc-web';
import {
  ListSpacesRequest,
  SaveSpaceConfigRequest,
  SaveSpaceRequest,
  SetSpaceStatusRequest,
  SetSpaceStatusResponse,
  SetSpaceAdminsRequest,
  SetSpaceAdminsResponse,
  GetSpaceAdminsResponse,
  GetSpaceAdminsRequest,
  SetSpaceTierResponse,
  SetSpaceTierRequest,
  SetSpaceFeatureFlagsResponse,
  SetSpaceFeatureFlagsRequest,
  GetSpaceUserResponse,
  GetSpaceUserRequest,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_api_pb';
import { AssetDetailClient } from '../../services';
import {
  Space,
  SpaceConfig,
  SpaceFeatureFlagMap,
  SpaceTier,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { serialize } from './serializer';

const api = (metadata: grpc.Metadata) => ({
  listSpaces: (): Promise<Space[]> => {
    const request = new ListSpacesRequest();

    return new Promise((resolve, reject) => {
      AssetDetailClient.listSpaces(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:listSpaces')
          );
        }

        return resolve(response.getSpacesList());
      });
    });
  },

  saveSpace: (props: SaveSpaceRequest.AsObject): Promise<Space> => {
    const { type, trialEndDate } = props.tier as SpaceTier.AsObject;
    const newTier = new SpaceTier();
    newTier.setType(type);
    newTier.setTrialEndDate(trialEndDate);

    const request = new SaveSpaceRequest();
    request.setSourceId(props.sourceId);
    request.setSourceIdType(props.sourceIdType);
    request.setTier(newTier);

    return new Promise((resolve, reject) => {
      AssetDetailClient.saveSpace(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:saveSpace')
          );
        }

        const space = response.getSpace();

        if (!space) {
          return reject('No space returned from API');
        }

        return resolve(space);
      });
    });
  },

  saveSpaceConfig: (
    spaceId: string,
    props: SpaceConfig.AsObject
  ): Promise<SpaceConfig> => {
    const request = new SaveSpaceConfigRequest();
    const spaceConfig = new SpaceConfig();

    if (props.assetDetailConfig) {
      spaceConfig.setAssetDetailConfig(
        serialize(props.assetDetailConfig).toAssetDetailConfig()
      );
    }

    if (props.cardConfig) {
      spaceConfig.setCardConfig(serialize(props.cardConfig).toCardConfig());
    }

    request.setConfig(spaceConfig);
    request.setSpaceId(spaceId);

    return new Promise((resolve, reject) => {
      AssetDetailClient.saveSpaceConfig(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:saveSpace')
          );
        }

        const spaceConfig = response.getConfig();

        if (!spaceConfig) {
          return reject('No space config returned from API');
        }

        return resolve(spaceConfig);
      });
    });
  },

  setSpaceStatus: (
    id: string,
    status: any
  ): Promise<SetSpaceStatusResponse> => {
    const request = new SetSpaceStatusRequest();
    request.setSpaceId(id);
    request.setStatus(status);

    return new Promise((resolve, reject) => {
      AssetDetailClient.setSpaceStatus(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:setSpaceStatus')
          );
        }

        return resolve(response);
      });
    });
  },

  setSpaceTier: (
    id: string,
    tier: SpaceTier.AsObject
  ): Promise<SetSpaceTierResponse> => {
    const newTier = new SpaceTier();
    const request = new SetSpaceTierRequest();
    newTier.setType(tier.type);
    newTier.setTrialEndDate(tier.trialEndDate);

    request.setSpaceId(id);
    request.setTier(newTier);

    return new Promise((resolve, reject) => {
      AssetDetailClient.setSpaceTier(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:setSpaceTier')
          );
        }

        return resolve(response);
      });
    });
  },

  setSpaceAdmins: (
    spaceId: string,
    adminIds: any
  ): Promise<SetSpaceAdminsResponse> => {
    const request = new SetSpaceAdminsRequest();

    request.setSpaceId(spaceId);
    request.setAdminUserIdsList(adminIds);

    return new Promise((resolve, reject) => {
      AssetDetailClient.setSpaceAdmins(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:setSpaceAdmins')
          );
        }

        return resolve(response);
      });
    });
  },

  getSpaceAdmins: (spaceId: string): Promise<GetSpaceAdminsResponse> => {
    const request = new GetSpaceAdminsRequest();
    request.setSpaceId(spaceId);

    return new Promise((resolve, reject) => {
      AssetDetailClient.getSpaceAdmins(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:getSpaceAdmins')
          );
        }

        return resolve(response);
      });
    });
  },

  getSpaceUser: (
    spaceId: string,
    userId: string
  ): Promise<GetSpaceUserResponse> => {
    const request = new GetSpaceUserRequest();
    request.setSpaceId(spaceId);
    request.setUserId(userId);

    return new Promise((resolve, reject) => {
      AssetDetailClient.getSpaceUser(request, metadata, (err, response) => {
        if (err) return reject(err);
        if (!response) {
          return reject(
            new Error('No response from AssetDetailClient:getSpaceUser')
          );
        }

        return resolve(response);
      });
    });
  },

  setSpaceFeatureFlags: (
    id: string,
    featureFlags: Array<SpaceFeatureFlagMap[keyof SpaceFeatureFlagMap]>
  ): Promise<SetSpaceFeatureFlagsResponse> => {
    const request = new SetSpaceFeatureFlagsRequest();
    request.setSpaceId(id);
    request.setFeatureFlagsList(featureFlags);

    return new Promise((resolve, reject) => {
      AssetDetailClient.setSpaceFeatureFlags(
        request,
        metadata,
        (err, response) => {
          if (err) return reject(err);
          if (!response) {
            return reject(
              new Error(
                'No response from AssetDetailClient:setSpaceFeatureFlags'
              )
            );
          }

          return resolve(response);
        }
      );
    });
  },
});

export { api };
