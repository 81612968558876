import { useCallback, useEffect, useState } from 'react';
import { GetPropertyHistogramResponse } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_api_pb';
import { useQueryParams } from '../../../../../state/QueryParams/hooks';
import { HistogramRangePickerBucket } from '../types';
import { usePhotosAPI } from '../../../../../state/Photos';

const useHistogram = ({
  tableName,
  columnName,
  initialSelection,
  onChange,
}: {
  tableName: string;
  columnName: string;
  initialSelection?: number[];
  onChange?: (minValue: string, maxValue: string) => any;
}) => {
  const { getFilters } = useQueryParams();

  const [histogramSelection, setHistogramSelection] = useState<
    number[] | undefined
  >(initialSelection);

  const [
    histogramResponse,
    setHistogramResponse,
  ] = useState<GetPropertyHistogramResponse | null>(null);

  const [histogramData, setHistogramData] = useState<
    HistogramRangePickerBucket[] | null
  >(null);

  useEffect(() => {
    if (histogramResponse) {
      const buckets = histogramResponse.getHistogramBucketsList().map(b => ({
        x0: b.getRangeStart(),
        x: b.getRangeEnd(),
        y: b.getCount(),
      }));

      const maxValueIsWholeNumber =
        histogramResponse.getMaxPropertyValue() % 1 === 0;

      if (maxValueIsWholeNumber) {
        // if the max value is a whole number, we will collapse the final two
        // buckets such that there is no trailing bucket and our slider range
        // ends at that max value.
        const [lastBucket, secondToLastBucket] = buckets.slice(-2).reverse();

        const lastTwoBucketsAreAdjacent =
          lastBucket &&
          secondToLastBucket &&
          secondToLastBucket.x === lastBucket.x0;

        if (lastTwoBucketsAreAdjacent) {
          // if the last two buckets are adjacent, we should combine them.
          buckets.pop();
          buckets.pop();
          buckets.push({
            x0: secondToLastBucket.x0,
            x: secondToLastBucket.x,
            y: secondToLastBucket.y + lastBucket.y,
          });
        } else if (lastBucket) {
          // if the last two buckets are not adjacent,
          // we should shift the final bucket back one interval.
          const bucketWidth = lastBucket.x - lastBucket.x0;
          buckets.pop();
          buckets.push({
            x0: lastBucket.x0 - bucketWidth,
            x: lastBucket.x - bucketWidth,
            y: lastBucket.y,
          });
        }
      }

      setHistogramData(buckets);
    }
  }, [histogramResponse, setHistogramData]);

  useEffect(() => {
    if (!histogramSelection) return;
    const [minValue, maxValue] = histogramSelection.map(f => f.toString());
    onChange?.(minValue, maxValue);
  }, [histogramSelection, onChange]);

  const api = usePhotosAPI();
  const fetchHistogram = useCallback(() => {
    // we will specifically ask the api to filter everything EXCEPT the
    // current filter, so that the histogram continues to show values that are
    // not selected yet.
    const filteredFilters = getFilters().filter(
      filter =>
        filter.getReferenceName() !== tableName ||
        filter.getPropertyName() !== columnName
    );

    api()
      .then(({ getPropertyHistogram }) =>
        getPropertyHistogram({
          referenceName: tableName,
          propertyName: columnName,
          filters: filteredFilters,
        })
      )
      .then(response => {
        setHistogramResponse(response);
      });
  }, [api, columnName, getFilters, tableName]);

  return {
    histogramSelection,
    setHistogramSelection,
    histogramResponse,
    setHistogramResponse,
    histogramData,
    setHistogramData,
    fetchHistogram,
  };
};

export { useHistogram };
