import React, { CSSProperties, memo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { areEqual } from 'react-window';
import { Add } from '@gsc/icons/react';
import { color } from '@gsc/components';
import { ItemData } from '../../../types';
import {
  AlbumGridCell,
  AlbumHoverOverlay,
  AlbumTitle,
} from '../../../components';
import {
  ActionTypes,
  useAlbumsAPI,
  useAlbumsDispatch,
} from '../../../../../state/Albums';
import { useCurrentSpace } from '../../../../../state/Spaces';

const StyledAddIcon = styled(Add)`
  color: ${color.mxDeepOcean};
  margin: 0 auto;
  width: 3.2rem;
`;

const CreateAlbumBox = styled.div`
  border: 0.1rem solid #dadcde;
  border-radius: 1.2rem;
  display: flex;
`;

const CreateAlbumRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const albumsAPI = useAlbumsAPI();
    const dispatch = useAlbumsDispatch();
    const { id: spaceId } = useCurrentSpace();
    const history = useHistory();

    const cell = data.rows[rowIndex]?.[columnIndex];
    if (!cell) return null;

    const width = style.width as number;
    const boxSize = (width ?? 300) - 32;

    const createEmptyAlbum = () => {
      albumsAPI()
        .then(({ createAlbum }) => {
          return createAlbum('Untitled album', [], spaceId);
        })
        .then(response => {
          const album = response.getAlbum();
          if (album) {
            dispatch({
              type: ActionTypes.AddAlbum,
              payload: {
                album,
              },
            });
            const albumId = album.toObject().id;
            history.push(`/spaces/${spaceId}/albums/${albumId}`);
          }
        })
        .catch(() => {
          cell.setErrorNotification &&
            cell.setErrorNotification(
              'We were unable to process your request. Please try again. If this error continues, please contact support.'
            );
        });
    };

    return (
      <AlbumGridCell style={style} onClick={createEmptyAlbum}>
        <CreateAlbumBox style={{ width: boxSize, height: boxSize }}>
          <StyledAddIcon />
        </CreateAlbumBox>

        <AlbumHoverOverlay
          style={{
            top: '1.6rem',
            left: '1.6rem',
            width: boxSize,
            height: boxSize,
            background: 'rgba(0, 0, 0, 0.05)',
          }}
        />

        <AlbumTitle>Create album</AlbumTitle>
      </AlbumGridCell>
    );
  },
  areEqual
);

CreateAlbumRenderer.displayName = 'CreateAlbumRenderer';

export { CreateAlbumRenderer };
