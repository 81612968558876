import 'react-dates/initialize';

import React, { FC, useEffect, useState } from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
const calendarIconUrl = require('@gsc/icons/svg/calendar-standard.svg')
  .default as string;

import {
  breakpoint,
  color,
  elevation,
  Input,
  SecondaryButton,
} from '@gsc/components';
import { ThemeProvider } from '../../../../shared/utils/theme';
import {
  DateRangePickerStyleOverrides,
  NextArrow,
  PrevArrow,
} from './components';

import { useWindowDimensions } from '../../../../shared/utils';
import {
  isLast7Days,
  isLast30Days,
  isThisMonth,
  isThisYear,
  isEndOfLastMonth,
  isStartOfLastMonth,
} from '../../../../shared/utils/helpers';

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

interface DateRangePickerProps {
  dataTest: string;
  enableOutsideDays?: boolean;
  initialEndDate?: moment.Moment;
  initialStartDate?: moment.Moment;
  isOutsideRange?(day: moment.Moment): boolean;
  onChange(dateRange: DateRange, labelName?: string): any;
}

interface SecondaryButtonProps {
  className?: string;
  children: string;
  dataTest: string;
  id?: string;
  onClick(...args: any[]): any;
  status?: string;
}

interface StyledInputProps {
  className?: string;
  dataTest: string;
  label: string;
  name: string;
  onChange?(dateRange: DateRange, labelName?: string): any;
  placeholder?: string;
  value: string;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const RangePickerContainer = styled.div`
  ${elevation['12']};
  background-color: ${color.ui01};
  display: flex;
  flex-direction: column-reverse;
  padding: 1.6rem 1.2rem;
  width: 32.7rem;

  ${breakpoint.lg`
    flex-direction: row;
    padding: 3.2rem 2.4rem 2.8rem;
    width: 46rem;
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 8.4rem;
  justify-content: space-between;

  button:nth-child(-n + 4) {
    margin-right: 0.4rem;
  }

  ${breakpoint.lg`
    flex-flow: column nowrap;
    height: auto;

    button:nth-child(-n+4) {
      margin-right: 0;
    }

    button:last-child {
      margin-bottom: 0;
    }
  `};
`;

const StyledSecondaryButton = styled(SecondaryButton)<SecondaryButtonProps>`
  padding: 0 1.4rem;

  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover,
  &.selected {
    background-color: ${color.ui01};
    border-color: ${color.mxDeepOcean};
    box-shadow: none;
    color: ${color.mxDeepOcean};
  }

  ${breakpoint.lg`
    margin-bottom: 1.6rem;
    padding: 0 1.6rem;
  `}
`;

const CalendarWrapper = styled.div`
  ${breakpoint.lg`
    margin-left: 2.4rem;
  `};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .start-input {
    margin-right: 0.7rem;

    ${breakpoint.lg`
      margin-right: 1.5rem;
    `};
  }
`;

const StyledInput = styled(Input)<StyledInputProps>`
  > input {
    background: transparent url(${calendarIconUrl}) no-repeat 0.8rem center;
    background-size: 1.2rem;
    padding-left: 2.8rem;
    padding-right: 1.2rem;
    width: 14.8rem;

    ${breakpoint.lg`
      width: 13rem;
    `};
  }

  > div {
    min-height: 1.4rem;
  }

  &.active > input {
    border-color: ${color.button.primary['default'].hover};
  }
`;

const DateRangePicker: FC<DateRangePickerProps> = ({
  dataTest,
  enableOutsideDays,
  initialEndDate = null,
  initialStartDate = null,
  isOutsideRange,
  onChange,
}) => {
  const isMobile = useWindowDimensions().width < 1020;

  const [startDate, setStartDate] = useState(initialStartDate);
  const [startDateString, setStartDateString] = useState(
    initialStartDate?.format('L') ?? ''
  );
  const [endDate, setEndDate] = useState(initialEndDate);
  const [endDateString, setEndDateString] = useState(
    initialEndDate?.format('L') ?? ''
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    'startDate'
  );
  const [selectedShortcut, setSelectedShortcut] = useState<string>('');

  const setActiveShortcut = (id: string) => setSelectedShortcut(id);
  const resetActiveShortcut = () => setSelectedShortcut('');

  const handleDatesChange = (
    { startDate, endDate }: DateRange,
    e?: HTMLInputEvent,
    labelName?: string
  ) => {
    if (e !== undefined) {
      setActiveShortcut(e.target.id);
    } else {
      resetActiveShortcut();
    }

    setStartDateString(startDate?.format('L') ?? '');
    setStartDate(startDate);
    setEndDateString(endDate?.format('L') ?? '');
    setEndDate(endDate);
    onChange({ startDate, endDate }, labelName);
  };

  // The idea here is that when forceCalendarUpdate is called, the calendar will
  // be removed from the layout for 1 frame. This forces it to re-evaluate its
  // initialVisibleMonth which moves the calendar with the user's selection.
  const [forcingCalendarUpdate, setForcingCalendarUpdate] = useState(false);
  const forceCalendarUpdate = () => setForcingCalendarUpdate(true);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        setForcingCalendarUpdate(false);
      }
    }, 0);
    return () => {
      unmounted = true;
    };
  }, [forcingCalendarUpdate]);

  const handleStartDate = (e: HTMLInputEvent) => {
    const { value } = e.target;
    setStartDateString(value);
    if (value.length === 10) {
      const newStartDate = moment(value);
      setStartDate(newStartDate);
      onChange({ startDate: newStartDate, endDate });

      setFocusedInput('endDate');
      forceCalendarUpdate();
    }
  };

  const handleEndDate = (e: HTMLInputEvent) => {
    const { value } = e.target;
    setEndDateString(value);
    if (value.length === 10) {
      const newEndDate = moment(value);
      setEndDate(newEndDate);
      onChange({ startDate, endDate: newEndDate });
      forceCalendarUpdate();
    }
  };

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput ?? 'startDate');
  };

  const initialVisibleMonth = () =>
    moment(
      (focusedInput && (focusedInput === 'endDate' ? endDate : startDate)) ??
        endDate ??
        startDate ??
        undefined
    );

  const onStartInputFocus = () => {
    setFocusedInput('startDate');
    forceCalendarUpdate();
  };

  const onEndInputFocus = () => {
    setFocusedInput('endDate');
    forceCalendarUpdate();
  };

  return (
    <RangePickerContainer data-test={dataTest}>
      <ButtonContainer>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-last-7',
          })}
          dataTest={'last-7-button'}
          id="shortcut-last-7"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: isLast7Days(),
                endDate: moment(),
              },
              e,
              '@date-Last 7 days'
            );
          }}
          status="neutral"
        >
          Last 7 days
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-last-30',
          })}
          dataTest={'last-30-button'}
          id="shortcut-last-30"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: isLast30Days(),
                endDate: moment(),
              },
              e,
              '@date-Last 30 days'
            );
          }}
          status="neutral"
        >
          Last 30 days
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-this-month',
          })}
          dataTest={'this-month-button'}
          id="shortcut-this-month"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: isThisMonth(),
                endDate: moment(),
              },
              e,
              '@date-This month'
            );
          }}
          status="neutral"
        >
          This month
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-last-month',
          })}
          dataTest={'last-month-button'}
          id="shortcut-last-month"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: isStartOfLastMonth(),
                endDate: isEndOfLastMonth(),
              },
              e,
              '@date-Last month'
            );
          }}
          status="neutral"
        >
          Last month
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-this-year',
          })}
          dataTest={'this-year-button'}
          id="shortcut-this-year"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: isThisYear(),
                endDate: moment(),
              },
              e,
              '@date-This year'
            );
          }}
          status="neutral"
        >
          This year
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({
            selected: selectedShortcut === 'shortcut-all-time',
          })}
          dataTest={'all-time-button'}
          id="shortcut-all-time"
          onClick={(e: HTMLInputEvent) => {
            handleDatesChange(
              {
                startDate: null,
                endDate: null,
              },
              e
            );
          }}
          status="neutral"
        >
          All time
        </StyledSecondaryButton>
      </ButtonContainer>
      <CalendarWrapper>
        <InputWrapper>
          <StyledInput
            autoFocus={focusedInput === 'startDate'}
            className={classnames({
              'start-input': true,
              active: focusedInput === 'startDate',
            })}
            dataTest={`${dataTest}-start`}
            label="From"
            name="start date"
            onChange={handleStartDate}
            onFocus={onStartInputFocus}
            placeholder="MM/DD/YYYY"
            value={startDateString}
          />
          <StyledInput
            autoFocus={focusedInput === 'endDate'}
            className={classnames({
              'end-input': true,
              active: focusedInput === 'endDate',
            })}
            dataTest={`${dataTest}-end`}
            label="To"
            name="end date"
            onChange={handleEndDate}
            onFocus={onEndInputFocus}
            placeholder="MM/DD/YYYY"
            value={endDateString}
          />
        </InputWrapper>

        <ThemeProvider>
          <DateRangePickerStyleOverrides data-test={`${dataTest}-calendar`}>
            {!forcingCalendarUpdate && (
              <DayPickerRangeController
                daySize={isMobile ? 36.5 : 35}
                enableOutsideDays={enableOutsideDays}
                endDate={endDate}
                focusedInput={focusedInput}
                hideKeyboardShortcutsPanel
                initialVisibleMonth={initialVisibleMonth}
                isOutsideRange={isOutsideRange}
                keepOpenOnDateSelect
                minimumNights={0}
                monthFormat="MMM YYYY"
                navNext={<NextArrow />}
                navPrev={<PrevArrow />}
                noBorder
                numberOfMonths={1}
                onDatesChange={handleDatesChange}
                onFocusChange={handleFocusChange}
                startDate={startDate}
              />
            )}
          </DateRangePickerStyleOverrides>
        </ThemeProvider>
      </CalendarWrapper>
    </RangePickerContainer>
  );
};

export { DateRangePicker };
