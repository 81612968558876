import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  breakpoint,
  color,
  PrimaryButton,
  TertiaryButton,
  zIndex,
} from '@gsc/components';
import { Close } from '@gsc/icons/react';
import pluralize from 'pluralize';
import { useAlbumsAPI } from '../../../state/Albums';
import {
  ActionTypes,
  usePhotosDispatch,
  usePhotosState,
  usePhotosAPI,
} from '../../../state/Photos';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import { Album } from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const AlbumHeader = styled.div`
  align-items: center;
  background-color: ${color.ui01};
  border-bottom: ${color.ui15} 0.1rem solid;
  display: flex;
  height: 5.6rem;
  padding: 0.8rem 2.4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndex.golf};

  ${breakpoint.lg`
    padding: 0.8rem 3.2rem;
  `}
`;

const ClearButton = styled(TertiaryButton)`
  border: none;
  color: ${color.mxDeepOcean};
  height: 1.6rem;
  margin-right: 1.6rem;
  padding: 0;
  width: 1.6rem;

  &:not(.show-loader):hover {
    background-color: transparent;
    color: ${color.ui50};
  }
`;

const AlbumNameWrapper = styled.span`
  display: none;

  ${breakpoint.md`
  display: flex;
  padding-left: 0.3rem;
  `}
`;

const AlbumName = styled.p`
  font-weight: 500;
  padding-left: 0.3rem;
`;

const StyledSelectAllButton = styled(TertiaryButton)`
  border: 0;
  color: ${color.mxDeepOcean};
  cursor: pointer;

  &:hover {
    color: ${color.mxDeepOceanHover};
  }

  > small {
    color: ${color.ui30};
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const AddButton = styled(PrimaryButton)`
  margin-left: auto;
`;

interface EditAlbumHeaderProps {
  selectedAlbum: Album.AsObject | undefined;
  setResponseNotification: (args: string) => any;
  spaceId: string;
}

const MAX_SELECTABLE_PHOTOS = 1000;

enum SelectAllState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

const EditAlbumHeader: FC<EditAlbumHeaderProps> = ({
  selectedAlbum,
  setResponseNotification,
  spaceId,
}) => {
  const history = useHistory();
  const albumsAPI = useAlbumsAPI();
  const api = usePhotosAPI();
  const { selectedPhotos } = usePhotosState();
  const dispatch = usePhotosDispatch();
  const {
    getFilters,
    selectAlbumId,
    setShowSelectedAlbum,
    sortField,
    sortDirection,
  } = useQueryParams();
  const [selectAllState, setSelectingAll] = useState<SelectAllState>(
    SelectAllState.COMPLETED
  );

  const selectablePhotoCount = MAX_SELECTABLE_PHOTOS - selectedPhotos.length;

  const onSelectAllClick = () => {
    if (selectAllState === SelectAllState.PENDING) return;
    setSelectingAll(SelectAllState.PENDING);

    api()
      .then(({ getPhotoSearch }) =>
        getPhotoSearch({
          filters: getFilters(),
          sortField,
          sortDirection,
          from: 0,
          pageSize: MAX_SELECTABLE_PHOTOS,
        })
      )
      .then(photos => {
        const ids = photos
          .getAssetDetailsList()
          .map((ad: AssetDetail) => ad.getId())
          .filter(id => selectedPhotos.indexOf(id) === -1)
          .slice(0, selectablePhotoCount);

        dispatch({
          type: ActionTypes.SetSelectedPhotos,
          payload: { ids: [...selectedPhotos, ...ids] },
        });

        setSelectingAll(SelectAllState.COMPLETED);
      })
      .catch(() => {
        setSelectingAll(SelectAllState.FAILED);
      });
  };

  const addToAlbum = () => {
    const errorText =
      selectedPhotos.length > 0
        ? 'We were unable to process your request. Please try again. If this error continues, please contact support.'
        : 'We were unable to process your request. Please select some photos before proceeding.';

    albumsAPI()
      .then(({ addAssetsToAlbum }) => {
        return (
          selectedAlbum && addAssetsToAlbum(selectedAlbum.id, selectedPhotos)
        );
      })
      .then(() => {
        history.push(`/spaces/${spaceId}/albums/${selectAlbumId}`);
        dispatch({
          type: ActionTypes.SetSelectedPhotos,
          payload: { ids: [] },
        });
      })
      .catch(() => {
        setResponseNotification(errorText);
      });
  };

  const cancelAddToAlbum = () => {
    setShowSelectedAlbum();
    dispatch({
      type: ActionTypes.SetSelectedPhotos,
      payload: { ids: [] },
    });
    history.push(`/spaces/${spaceId}/albums/${selectAlbumId}`);
  };

  return (
    <AlbumHeader>
      <ClearButton
        isIconOnly
        onClick={cancelAddToAlbum}
        status="neutral"
        title="Clear Album Photos"
        type="button"
      >
        <Close />
      </ClearButton>
      {`Add ${selectedPhotos.length} ${pluralize(
        'photos',
        selectedPhotos.length
      )}`}
      <AlbumNameWrapper>
        to <AlbumName>{selectedAlbum?.name}</AlbumName>
      </AlbumNameWrapper>

      {selectablePhotoCount > 0 && (
        <StyledSelectAllButton
          onClick={onSelectAllClick}
          disabled={selectAllState === SelectAllState.PENDING}
        >
          {selectAllState === SelectAllState.PENDING ? (
            <>Please wait...</>
          ) : selectAllState === SelectAllState.FAILED ? (
            <>
              Retry select all
              <small> (up to {MAX_SELECTABLE_PHOTOS})</small>
            </>
          ) : (
            <>
              Select all
              <small> (up to {MAX_SELECTABLE_PHOTOS})</small>
            </>
          )}
        </StyledSelectAllButton>
      )}

      <AddButton data-test="add-button" onClick={addToAlbum}>
        Add to Album
      </AddButton>
    </AlbumHeader>
  );
};

export { EditAlbumHeader };
