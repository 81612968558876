import React, { FC, useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Checkbox, color, Input, Loader, mixin } from '@gsc/components';
import { Close } from '@gsc/icons/react';
// For what ever reason, can't get TS to be happy with importing SVG's
// import searchIconUrl from '@gsc/icons/svg/search.svg';
const searchIconUrl = require('@gsc/icons/svg/search.svg').default as string;
import { useAutofocus, useDebounce } from '../../../shared/utils';
import { usePhotosAPI } from '../../../state/Photos';
import {
  PropertyValueCount,
  Operator,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { useQueryParams } from '../../../state/QueryParams/hooks';

const StyledInput = styled(Input)`
  padding: 1rem 1rem 0.8rem;

  input {
    background: ${color.ui01} url(${searchIconUrl}) no-repeat left 1rem center /
      1.2rem 1.2rem;
    padding-left: 2.8rem;
  }

  div {
    display: none;
  }
`;

const StyledButton = styled.button`
  background-color: ${color.ui01};
  position: absolute;
  right: 1.5rem;
  top: 2.25rem;
`;

const StyledClose = styled(Close)`
  ${mixin.size('1.2rem')};
  color: ${color.ui50};
  cursor: pointer;
`;

const ListContainer = styled.ul`
  max-height: 20rem;
  overflow-y: auto;
`;

const ListItems = styled.li`
  display: block;
  width: 100%;

  &:hover {
    background-color: ${color.ui10};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  label {
    padding: 1rem 0 1rem 2.2rem;

    &:before,
    &:after {
      left: 1rem;
      top: 1rem;
    }

    > div {
      left: -1.1rem;
      margin-right: 0;
    }
  }
`;

const SmallLoader = styled(Loader)`
  color: ${color.ui50};
  height: 1.6rem;
  position: absolute;
  right: 1.5rem;
  top: 2.25rem;
  width: 1.6rem;
`;

const ResultsText = styled.p`
  color: ${color.ui50};
  padding: 1rem;
`;

interface FilterTypeaheadProps {
  referenceName: string;
  propertyName: string;
  dataTest: string;
}

const FilterTypeahead: FC<FilterTypeaheadProps> = ({
  propertyName,
  dataTest,
  referenceName,
}) => {
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filterProperties, setFilterProperties] = useState<
    PropertyValueCount.AsObject[]
  >([]);
  const api = usePhotosAPI();
  const debouncedInputValue = useDebounce(inputValue, 250);
  const { searchFilters, setSearchFilters, getFilters } = useQueryParams();

  useEffect(() => {
    let unmounted = false;
    setLoadingFilters(true);

    api()
      .then(({ getFacetedFilterValues }) => {
        // we will specifically ask the api to filter everything EXCEPT the
        // current filter, so that the user is not prevented from selecting
        // more options from this typeahead to build up OR logic.
        const filteredFilters = getFilters().filter(
          filter =>
            filter.getReferenceName() !== referenceName ||
            filter.getPropertyName() !== propertyName
        );

        return getFacetedFilterValues({
          referenceName,
          propertyName,
          filters: filteredFilters,
          inputValue: debouncedInputValue,
        });
      })
      .then(data => {
        if (!unmounted) {
          setFilterProperties(data.propertyValueCountsList);
          setLoadingFilters(false);
        }
      });

    return () => {
      unmounted = true;
    };
  }, [propertyName, debouncedInputValue, referenceName, api, getFilters]);

  const handleInputChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    e.persist();

    if (value) {
      setInputValue(value);
    } else setInputValue('');
  };

  const handleSelectProperty = (value: string) => {
    const selectedIndex = searchFilters.findIndex(
      filter =>
        filter.referenceName === referenceName &&
        filter.propertyName === propertyName &&
        filter.value === value
    );

    if (selectedIndex > -1) {
      searchFilters.splice(selectedIndex, 1);
    } else {
      searchFilters.push({
        referenceName,
        propertyName,
        operator: Operator.OPERATOR_EQUALS,
        value,
      });
    }

    setSearchFilters(searchFilters);
  };

  return (
    <div data-test={`${dataTest}-typeahead`}>
      <StyledInput
        id={`${referenceName}-${propertyName}-typeahead`}
        onChange={handleInputChange}
        placeholder="Search"
        ref={useAutofocus()}
        value={inputValue}
      />
      {loadingFilters ? (
        <SmallLoader />
      ) : (
        <StyledButton
          hidden={inputValue === ''}
          onClick={handleInputChange}
          type="button"
        >
          <StyledClose />
        </StyledButton>
      )}
      {!loadingFilters && (
        <ListContainer data-test={`${referenceName}-${propertyName}-list`}>
          {!loadingFilters && filterProperties.length > 0 ? (
            filterProperties.map(item => {
              const sanitizedId = encodeURIComponent(item.value);
              const id = `${referenceName}~${propertyName}~${sanitizedId}`;
              const checked = !!searchFilters.find(
                filter =>
                  filter.referenceName === referenceName &&
                  filter.propertyName === propertyName &&
                  filter.value === item.value
              );
              return (
                <ListItems
                  data-test={`${referenceName}-${propertyName}-${sanitizedId}-list-item`}
                  key={item.value}
                >
                  <StyledCheckbox
                    id={id}
                    name={id}
                    className={checked ? 'checked' : ''}
                    onChange={() => handleSelectProperty(item.value)}
                    value={item.value}
                    checked={checked}
                    dataTest={`${referenceName}-${propertyName}-${sanitizedId}-checkbox`}
                  >
                    {item.value === '' ? '(No value set)' : item.value}
                  </StyledCheckbox>
                </ListItems>
              );
            })
          ) : (
            <ResultsText>No values found.</ResultsText>
          )}
        </ListContainer>
      )}
    </div>
  );
};

export { FilterTypeahead };
