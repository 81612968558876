import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  breakpoint,
  Checkbox,
  color,
  font,
  TertiaryButton,
  Toggle,
  Tooltip,
  typography,
} from '@gsc/components';
import { FormContext } from '../../../../../../shared/components/FormContext';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { SubWrapper, SubHeader } from './ExportSettingsForm';
import { formatTitle, formatLabel } from '../helpers/formatStrings';

const MetadataTitleWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-right: 4.8rem;
  }

  ${breakpoint.md`
    flex-direction: row;
  `}
`;

const ToggleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.6rem;

  > h5 {
    margin-bottom: 0;
  }
`;

const StyledToggle = styled(Toggle)`
  display: inline-block;
  margin-left: 1.6rem;
`;

const TooltipWrapper = styled.div`
  display: inline-block;
  line-height: 1.6rem;
  margin-left: 0.8rem;
  > div > svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const SelectAllContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.2rem;
`;

const FieldsText = styled.p`
  ${typography.body}
  color: ${color.ui50};
  font-weight: ${font.weight.medium};
`;

const SelectAllButton = styled(TertiaryButton)`
  border: none;
  height: auto;

  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: ${color.ui01};
    box-shadow: none;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding-bottom: 0.8rem;
`;

const MetadataLayout: FC = () => {
  const { handleChange, setValues, values } = useContext(FormContext);
  const [selectAll, setSelectAll] = useState(false);
  const currentSpace = useCurrentSpaceObj();
  const tablesList = currentSpace.config?.assetDetailConfig?.tablesList || [];
  const sortedTablesList = [
    ...tablesList
      ?.flatMap(table =>
        table.columnConfigsList.map(column => ({
          tableName: table.tableName,
          columnName: column.columnName,
          columnType: column.type,
          canFilter: column.canFilter,
          columnLabel: column.label,
          checkboxLabel: column.label
            ? column.label
            : `${formatTitle(table.tableName)} ${formatLabel(
                column.columnName
              )}`,
        }))
      )
      .sort((a, b) => {
        const aLabelText = a.checkboxLabel.toLowerCase();
        const bLabelText = b.checkboxLabel.toLowerCase();
        if (aLabelText > bLabelText) return 1;
        if (aLabelText < bLabelText) return -1;
        return 0;
      }),
  ];

  const findMetadata = Object.keys(values).filter(key =>
    key.startsWith('metadata-')
  );

  const toggleSelectAll = () => {
    const metadataObj: { [key: string]: string } = {};
    findMetadata.forEach(key => {
      const metaValue = selectAll ? 'false' : 'true';
      metadataObj[key] = metaValue;
    });
    setSelectAll(!selectAll);
    setValues(metadataObj);
  };

  const metadataList = (
    <ul>
      {sortedTablesList.map(item => {
        const combinedName = `metadata-${item.tableName}-${item.columnName}`;
        return (
          <li key={`${item.tableName}-${item.columnName}`}>
            <StyledCheckbox
              checked={values[combinedName] === 'true'}
              id={combinedName}
              name={combinedName}
              onChange={handleChange}
              value={values[combinedName] === 'true' ? 'false' : 'true'}
            >
              {item.checkboxLabel}
            </StyledCheckbox>
          </li>
        );
      })}
    </ul>
  );

  return (
    <SubWrapper>
      <MetadataTitleWrapper>
        <ToggleContainer>
          <SubHeader>Show photo details</SubHeader>
          <StyledToggle
            checked={values.useMetadata === 'true'}
            disabled={values.photosPerPage === '9'}
            hasLabel={false}
            name="useMetadata"
            onChange={handleChange}
            value={values.useMetadata === 'true' ? 'false' : 'true'}
          />
          {values.photosPerPage === '9' && (
            <TooltipWrapper>
              <Tooltip dataTest="photosPerPage-tooltip">
                To enable photo details, use a layout with less images per page.
              </Tooltip>
            </TooltipWrapper>
          )}
        </ToggleContainer>
        {values.useMetadata === 'true' && (
          <ToggleContainer>
            <SubHeader>Include field labels</SubHeader>
            <StyledToggle
              checked={values.includeLabels === 'true'}
              hasLabel={false}
              name="includeLabels"
              onChange={handleChange}
              value={values.includeLabels === 'true' ? 'false' : 'true'}
            />
          </ToggleContainer>
        )}
      </MetadataTitleWrapper>
      {values.useMetadata === 'true' && (
        <div className="metadata-checkbox-wrapper">
          <SelectAllContainer>
            <FieldsText>{findMetadata.length} available fields</FieldsText>
            <SelectAllButton onClick={toggleSelectAll}>
              {selectAll ? 'Deselect all' : 'Select all'}
            </SelectAllButton>
          </SelectAllContainer>
          <StyledCheckbox
            checked={
              values['metadata-mission_responses-completed_at'] === 'true'
            }
            id="metadata-mission_responses-completed_at"
            name="metadata-mission_responses-completed_at"
            onChange={handleChange}
            value={
              values['metadata-mission_responses-completed_at'] === 'true'
                ? 'false'
                : 'true'
            }
          >
            Date submitted
          </StyledCheckbox>
          {tablesList && metadataList}
        </div>
      )}
    </SubWrapper>
  );
};
export { MetadataLayout };
