import { State } from './types';

const selectors = (state: State) => ({
  getUserInitials: () => {
    const { name } = state.user;

    return name
      .split(/\s/)
      .slice(0, 2)
      .reduce((response, word) => (response += word.slice(0, 1)), '')
      .toUpperCase();
  },
});

export { selectors };
