import styled from 'styled-components';
import {
  border,
  color,
  font,
  Link,
  SecondaryButton,
  TertiaryButton,
} from '@gsc/components';
import { HelpCircleBold } from '@gsc/icons/react';
import { MobileTakeOver } from '../../shared/components/MobileTakeOver';
import { menuLinkStyles } from '../../shared/components/MenuLink';
import { LogoIcon } from './Logo';

const MobileHeaderWrapper = styled.header`
  align-items: center;
  height: 5.6rem;
  background-color: ${color.ui01};
  border-bottom: ${border.normal(color.ui15)};
  display: flex;
  left: 0;
  padding: 0 2.4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const DesktopHeaderWrapper = styled(MobileHeaderWrapper)`
  padding: 0 3.2rem;
`;

const MobileNavigationWrapper = styled(MobileTakeOver)`
  button[data-test='main-navigation-button'] {
    border: none;
    color: ${color.ui50};
    height: auto;
    padding: 0;

    &:not(.show-loader) {
      &:hover {
        background-color: transparent;
        border: none;
        color: ${color.ui50};
      }
    }

    svg {
      margin-right: 0;
      width: 2.4rem;
    }
  }
`;

const DesktopNavigationWrapper = styled(MobileNavigationWrapper)`
  flex: 1;

  & > div {
    align-items: center;
    display: flex;
  }
`;

const MobileLogo = styled.div`
  align-items: center;
  display: inline-flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const DesktopLogo = styled(MobileLogo)`
  margin-right: 1.6rem;
  padding-right: 0.8rem;
  position: static;
  transform: translate(0);
`;

const MobileCameraIcon = styled(LogoIcon)`
  color: ${color.mxDeepOcean};
  height: 4rem;
`;

const DesktopCameraIcon = styled(MobileCameraIcon)`
  height: 4rem;
`;

const MobileHeaderText = styled.div`
  ${menuLinkStyles}

  &.active,
  &.active:hover {
    cursor: pointer;
  }
`;

const DesktopHeaderText = styled(MobileHeaderText)`
  border: 0;
  display: inline-block;
  margin: 0 0.8rem;
  padding: 0 0.8rem;
  width: auto;

  &:hover {
    background-color: transparent;
    font-weight: ${font.weight.medium};
  }

  &.active {
    border: 0;
    font-weight: ${font.weight.medium};
  }
`;

const MobileNavWrapper = styled.div``;

const DesktopNavWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const FreeLink = styled(Link)`
  font-weight: 500;
  margin-right: 0.6rem;
`;

const PendoAnchor = styled(TertiaryButton)`
  color: #6b717c;
  margin-right: 1.6rem;
`;

const PendoIcon = styled(HelpCircleBold)`
  width: 2rem;
`;

const SignInButton = styled(SecondaryButton)`
  margin-left: auto;
`;

export {
  MobileHeaderWrapper,
  DesktopCameraIcon,
  DesktopHeaderText,
  DesktopHeaderWrapper,
  DesktopLogo,
  DesktopNavigationWrapper,
  DesktopNavWrapper,
  FreeLink,
  MobileCameraIcon,
  MobileHeaderText,
  MobileLogo,
  MobileNavigationWrapper,
  MobileNavWrapper,
  PendoAnchor,
  PendoIcon,
  SignInButton,
};
