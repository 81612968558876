import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useReducer,
} from 'react';
import { initialState, reducer } from './reducer';
import { Actions } from './actions';
import { useGetFirstPageOfAlbums } from './effects';
import { State } from './types';

const AlbumsStateContext = createContext<State | undefined>(undefined);
const AlbumsDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined
);

interface AlbumsProviderProps {
  children: ReactNode;
  spaceId?: string;
}

const AlbumsProvider: FC<AlbumsProviderProps> = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useGetFirstPageOfAlbums(dispatch, state);

  return (
    <AlbumsStateContext.Provider value={state}>
      <AlbumsDispatchContext.Provider value={dispatch}>
        {props.children}
      </AlbumsDispatchContext.Provider>
    </AlbumsStateContext.Provider>
  );
};

export { AlbumsStateContext, AlbumsDispatchContext, AlbumsProvider };
