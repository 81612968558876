export const createRipple = (
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
  rippleClass: string,
  clientX: number,
  clientY: number
) => {
  const button = buttonRef.current;
  if (!button) return;

  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${clientY - button.offsetTop - radius}px`;

  rippleClass.split(' ').forEach(className => circle.classList.add(className));

  const ripples = Array.from(button.getElementsByClassName(rippleClass));

  ripples.forEach(ripple => ripple.remove());

  button.appendChild(circle);
};
