import React, { CSSProperties, memo } from 'react';
import styled from 'styled-components';
import { areEqual } from 'react-window';
import { Notification } from '@gsc/components';
import {
  NotificationTitle,
  NotificationWrapper,
} from '../components/Notifications';
import { ItemData } from '../types';

const notificationRowHeight = 90;

const NotificationRow = styled.div`
  background: white;
  width: min(100vw - 3.2rem, 188.8rem);
  height: ${notificationRowHeight / 10}rem;
  padding: 0;
  position: relative;
  z-index: 2;
`;

const NotificationRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const cell = data.rows[rowIndex]?.[columnIndex];

    return (
      <div style={style}>
        <NotificationRow>
          <NotificationWrapper>
            <Notification status={cell.status}>
              {cell.title && (
                <>
                  <NotificationTitle>{cell.title}</NotificationTitle>
                  <br />
                </>
              )}
              {cell.message}
            </Notification>
          </NotificationWrapper>
        </NotificationRow>
      </div>
    );
  },
  areEqual
);

NotificationRenderer.displayName = 'NotificationRenderer';

export { NotificationRenderer, notificationRowHeight };
