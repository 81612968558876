import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  breakpoint,
  color,
  H1,
  Link,
  Loader,
  Notification,
  TertiaryButton,
} from '@gsc/components';
import { Delete } from '@gsc/icons/react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import moment from 'moment';
import download from 'downloadjs';

import {
  Export,
  ExportStatus,
  Format,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import {
  ActionTypes,
  useExportsAPI,
  useExportsDispatch,
  useExportsState,
} from '../../state/Exports';

const StyledNotification = styled(Notification)`
  margin-bottom: 1.6rem;
`;

const ExportsLayout = styled.div`
  padding: 8.8rem 2.4rem 3.2rem;

  ${breakpoint.lg`
    & {
      padding-left: 6.4rem;
      padding-right: 6.4rem;
    }
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
`;

const HeaderTitle = styled(H1)`
  margin-bottom: 0;
`;

const Subtitle = styled.p`
  color: #6b717c;
  font-size: 1.4rem;
`;

const StyledDataTable = styled(DataTable)`
  > header {
    display: none;
  }

  > div > .rdt_Table {
    width: calc(100% - 4.8rem);

    ${breakpoint.md`
      width: 100%;
    `}
  }

  > div > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol,
  > div > .rdt_Table > .rdt_TableBody > .rdt_TableRow > .rdt_TableCell {
    padding-left: 0;

    ${breakpoint.md`
      padding-left: 1.6rem;
    `}
  }

  > div
    > .rdt_Table
    > .rdt_TableBody
    > .rdt_TableRow
    > .rdt_TableCell:nth-child(3) {
    max-width: 5.5rem;
    min-width: 2rem;

    ${breakpoint.md`
      max-width: 100%;
      min-width: 10rem;
    `}
  }
` as typeof DataTable;

const LoaderWrapper = styled.div`
  padding: 8rem 2.4rem 2.4rem;
`;

const StyledLoader = styled(Loader)`
  color: ${color.ui50};
  height: 10rem;
`;

const SmallLoader = styled(Loader)`
  height: 1.6rem;
  margin-left: 1rem;
  width: 1.6rem;
`;

const StyledTertiaryButton = styled(TertiaryButton)`
  border: none;
  padding: 0;

  &:active,
  &:focus,
  &:focus:hover,
  &:not(.show-loader):hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledDeleteIcon = styled(Delete)`
  width: 2rem;
`;

const Exports: FC = () => {
  const [responseError, setResponseError] = useState<{ message: string }>({
    message: '',
  });
  const [downloadingExport, setDownloadingExport] = useState('');
  const [deletingExport, setDeletingExport] = useState('');
  const { exportsList, loading } = useExportsState();
  const api = useExportsAPI();
  const dispatch = useExportsDispatch();

  const format = Format;
  const formatKeys = Object.keys(format).map(stat => {
    const statArray = stat.split('_');
    return statArray
      .slice(1, stat.length)
      .join(' ')
      .toLowerCase();
  });

  const filename = (row: Export.AsObject) => {
    const date = row.createdAtTime;
    const formattedDate = moment(date).format('MM-DD-YYYY_h:mm_A_z');
    const joinedTitle =
      row.titlePageSettings?.title?.split(' ')?.join('_') ?? 'export';

    return `${joinedTitle}_${formattedDate}`;
  };

  const interceptDownload = (e: SyntheticEvent, row: Export.AsObject) => {
    e.preventDefault();
    setDownloadingExport(row.id);

    const fileExtension =
      row.format === Format.FORMAT_JPEG ? 'zip' : formatKeys[row.format];

    api()
      .then(({ getDownloadURL }) => getDownloadURL(row.id))
      .then(response => fetch(response.getUrl()))
      .then(urlResponse => urlResponse.blob())
      .then(blob => {
        download(blob, `${filename(row)}.${fileExtension}`);
        setDownloadingExport('');
      })
      .catch(() => {
        setResponseError({
          message:
            'We were unable to process your request. Please try again. If this error continues, please contact support.',
        });
      });
  };

  useEffect(() => {
    const exportsByCreatedAt = exportsList.sort(
      (a, b) => b.createdAtTime - a.createdAtTime
    );

    if (exportsByCreatedAt[0]?.status === ExportStatus.EXPORT_STATUS_FAILED) {
      setResponseError({
        message:
          'Oops! Your last export failed to generate. Please try again or contact support.',
      });
    }
  }, [exportsList]);

  const deleteSelectedExport = (id: string) => {
    setDeletingExport(id);
    api()
      .then(({ deleteExport, listExports }) =>
        deleteExport(id).then(() => listExports())
      )
      .then(exports => {
        dispatch({
          type: ActionTypes.ListExportsResponse,
          payload: { exports },
        });
        setDeletingExport('');
      })
      .catch(() => {
        setResponseError({
          message:
            'We were unable to process your request. Please try again. If this error continues, please contact support.',
        });
      });
  };

  const columns: IDataTableColumn<Export.AsObject>[] = [
    {
      cell: row => row.titlePageSettings?.title ?? 'untitled',
      name: 'Title',
      sortable: true,
    },
    {
      cell: row => moment(row.createdAtTime).format('MMMM DD, YYYY h:mm A z'),
      name: 'Created at',
      selector: 'createdAtTime',
      sortable: true,
    },
    {
      cell: row => formatKeys[row.format].toUpperCase(),
      name: 'Type',
      selector: 'format',
      sortable: true,
    },
    {
      cell: row => {
        const DownloadLink = () =>
          downloadingExport === row.id ? (
            <p>Downloading...</p>
          ) : (
            <Link
              as="a"
              dataTest={`download-link-${row.id}`}
              download
              key={`download-link-${row.id}`}
              onClick={(e: SyntheticEvent) => interceptDownload(e, row)}
            >
              Download
            </Link>
          );

        if (row.id && row.status === ExportStatus.EXPORT_STATUS_COMPLETED) {
          return DownloadLink();
        } else if (row.status === ExportStatus.EXPORT_STATUS_IN_PROGRESS) {
          return <SmallLoader />;
        } else if (row.status === ExportStatus.EXPORT_STATUS_FAILED) {
          return <span>Failed</span>;
        } else if (row.status === ExportStatus.EXPORT_STATUS_SCHEDULED) {
          return <span>Scheduled</span>;
        } else {
          return <span>???</span>;
        }
      },
      name: '',
      selector: 'status',
      sortable: true,
    },
    {
      cell: row => {
        const DeleteButton = () =>
          deletingExport === row.id ? (
            <SmallLoader />
          ) : (
            <StyledTertiaryButton
              aria-label="delete-export"
              data-test={`delete-export-${row.titlePageSettings?.title}`}
              id={`delete-export-${row.id}`}
              key={`delete-${row.id}`}
              onClick={() => deleteSelectedExport(row.id)}
            >
              <StyledDeleteIcon />
            </StyledTertiaryButton>
          );

        return row.id && row.status === ExportStatus.EXPORT_STATUS_COMPLETED
          ? DeleteButton()
          : null;
      },
      name: '',
      selector: 'id',
      sortable: true,
    },
  ];

  const CustomLoader = () => (
    <LoaderWrapper>
      <StyledLoader />
    </LoaderWrapper>
  );

  return (
    <ExportsLayout>
      <Helmet>
        <title>PhotoWorks - Exports</title>
        <meta name="description" content="PhotoWorks Exports" />
      </Helmet>

      <Header>
        <HeaderTitle>Exports</HeaderTitle>
        <Subtitle>Showing your exports from the last 60 days.</Subtitle>
      </Header>

      {responseError.message && (
        <StyledNotification
          dataTest="create-export-notification"
          onClick={() => setResponseError({ message: '' })}
          status="negative"
        >
          {responseError.message}
        </StyledNotification>
      )}

      <StyledDataTable
        columns={columns}
        data={exportsList}
        defaultSortAsc={false}
        defaultSortField="createdAtTime"
        pagination
        progressPending={loading}
        progressComponent={<CustomLoader />}
      />
    </ExportsLayout>
  );
};

export { Exports };
