import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CdnImageProps } from './CdnImage';

const setFilestackOptions = (url: string, filestackOptions: string): string => {
  const [domain, path] = url.split('.com');
  return `${domain}.com/${filestackOptions}${path}`;
};

const imagesLoaded: string[] = [];

export const FilestackImage = ({
  alt,
  imageUrl,
  imgSrcOverride,
  fullUrlOptions = 'compress=metadata:true',
  placeholderOptions = 'compress=metadata:true',
  onLoad,
  ...otherProps
}: CdnImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [fullUrl, setFullUrl] = useState<string | undefined>(undefined);

  const placeholderUrl = useMemo(() => {
    if (imgSrcOverride) return imgSrcOverride;
    if (!imageUrl) return;
    if (imagesLoaded.indexOf(fullUrl ?? '') > -1) return fullUrl;
    return setFilestackOptions(imageUrl, placeholderOptions);
  }, [imageUrl, fullUrl, imgSrcOverride, placeholderOptions]);

  const [image, setImage] = useState(placeholderUrl);
  const [retries, setRetries] = useState(5);

  useEffect(() => {
    let url;
    if (imgSrcOverride) url = imgSrcOverride;
    else if (imageUrl) {
      url = setFilestackOptions(imageUrl, fullUrlOptions);
    }

    if (url) {
      setRetries(5);
      setFullUrl(url);
    }
  }, [imageUrl, imgSrcOverride, fullUrlOptions]);

  useEffect(() => {
    let unmounted = false;
    if (!fullUrl) return;

    const imageObj = new Image();
    imageObj.referrerPolicy = 'no-referrer';

    imageObj.onload = args => {
      imagesLoaded.push(imageObj.src);
      if (unmounted) return;
      setImage(imageObj.src);
      onLoad?.(args);
    };

    imageObj.src = fullUrl;

    return () => {
      imageObj.src = '';
      unmounted = true;
    };
  }, [fullUrl, onLoad, retries]);

  useEffect(
    () => () => {
      if (imgRef?.current?.src) {
        imgRef.current.src = '';
      }
    },
    []
  );

  return (
    <img
      ref={imgRef}
      alt={alt}
      src={image}
      referrerPolicy="no-referrer"
      {...otherProps}
    />
  );
};
