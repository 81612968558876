import React, { CSSProperties, FC, memo, useState } from 'react';
import styled from 'styled-components';
import { areEqual } from 'react-window';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@gsc/components';
import { ItemData } from '../types';

const buttonRowHeight = 70;

const ButtonRow = styled.div`
  background: white;
  width: min(100vw - 3.2rem, 188.8rem);
  height: ${buttonRowHeight / 10}rem;
  padding: 0 1.5rem;
  position: relative;
  text-align: center;
  z-index: 2;
`;

type IButtonComponent = FC<{ disabled: boolean; onClick: () => void }>;
const buttonComponents: { [key: string]: IButtonComponent } = {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
};

const ButtonRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const cell = data.rows[rowIndex]?.[columnIndex];
    const {
      text,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      type,
      buttonComponent = 'SecondaryButton',
      disableAfterClick,
      disabledText,
      onClick,
      ...buttonProps
    } = cell;

    const [disabled, setDisabled] = useState<boolean>(false);
    const realOnClick = () => {
      if (disableAfterClick) setDisabled(true);
      onClick?.();
    };

    const ButtonComponent = buttonComponents[buttonComponent];

    return (
      <div style={style}>
        <ButtonRow>
          <ButtonComponent
            disabled={disabled}
            onClick={realOnClick}
            {...buttonProps}
          >
            {disabled ? disabledText : text}
          </ButtonComponent>
        </ButtonRow>
      </div>
    );
  },
  areEqual
);

ButtonRenderer.displayName = 'ButtonRenderer';

export { ButtonRenderer, buttonRowHeight };
