import React, { FC, useState, FormEvent, useEffect } from 'react';
import styled from 'styled-components';
import {
  border,
  breakpoint,
  color,
  ConfirmationModal,
  H3,
  H5,
  Input,
  Notification,
  PopupModal,
  PrimaryButton,
  SecondaryButton,
  typography,
} from '@gsc/components';
import { Add } from '@gsc/icons/react';
import {
  ActionTypes,
  useAlbumsAPI,
  useAlbumsDispatch,
  AlbumsProvider,
  useAlbumsContext,
} from '../../../state/Albums';
import {
  ActionTypes as PhotoActionTypes,
  usePhotosDispatch,
  usePhotosState,
} from '../../../state/Photos';
import { useQueryParams } from '../../../state/QueryParams/hooks';
import { useCurrentSpace } from '../../../state/Spaces';
import { AlbumsSearchInput } from '../../Albums/SearchInput';
import { CdnImageWrapper } from '../shared/components/CdnImageWrapper';
import pluralize from 'pluralize';
import moment from 'moment';
import classnames from 'classnames';
import {
  Album,
  AlbumType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const StyledPopupModal = styled(PopupModal)`
  height: 100%;
  min-width: auto;
  padding-top: 0;
  width: 100%;

  & > svg {
    left: 2.4rem;
    top: 2rem;

    ${breakpoint.md`
      left: auto;
      right: 2.4rem;
      top: 2.4rem;
      z-index: 2;
    `}
  }

  &.album-error {
    padding-top: 3.2rem;

    & > svg {
      left: 2.4rem;
      top: 17rem;

      ${breakpoint.md`
        left: auto;
        right: 2.4rem;
        top: 13rem;
        z-index: 1;
      `}
    }
  }

  ${breakpoint.md`
    height: 44rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 3.2rem;
    width: 48rem;
  `}
`;

const ModalHeader = styled.div`
  align-items: center;
  border-bottom: ${border.normal(color.ui15)};
  display: flex;
  height: 5.6rem;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;

  ${breakpoint.md`
    border: 0;
    height: auto;
    justify-content: start;
    position: relative;
    height: 3rem;
    background-color: white;
    z-index: 1;
  `};
`;

const ModalHeaderText = styled(H3)`
  font-size: 1.6rem;
  margin-bottom: 0;

  ${breakpoint.md`
    font-size: 2rem;
    margin-bottom: 1.6rem;
    padding-left: 2.4rem;
  `}
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  top: 5.6rem;
  position: absolute;
  width: calc(100% - 4.8rem);

  &.album-error {
    top: 20.5rem;
  }

  ${breakpoint.md`
    height: 33.5rem;
    padding: 0.8rem 2.4rem 0;
    width: 100%;

    &.album-error {
      height: 26rem;
      top: 16rem;
    }
  `}
`;

const AddWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  left: -2.4rem;
  padding: 2.4rem;
  position: relative;
  width: calc(100% + 4.8rem);

  & :hover {
    background-color: #f3f3f4;
  }

  ${breakpoint.md`
    padding: 1.2rem 2.4rem;
  `}
`;

const AddButtonText = styled.p`
  padding-left: 1.6rem;
`;

const StyledConfirmationModal = styled(ConfirmationModal)`
  height: 100%;
  padding: 0 2.4rem;

  ${breakpoint.md`
    height: auto;
    padding: 0;
  `}
`;

const SubHeader = styled.p`
  ${typography.body};
  color: #6b717c;
  padding-bottom: 2.4rem;
`;

const StyledInput = styled(Input)`
  & > div {
    display: none;
  }
`;

const SearchHeader = styled(H5)`
  color: #6b717c;
  padding-top: 2.4rem;
`;

const AlbumsListContainer = styled.ul`
  margin-top: 0.8rem;
`;

const AlbumListItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 6.8rem;
  left: -2.4rem;
  padding: 1.2rem 2.4rem;
  position: relative;
  width: calc(100% + 4.8rem);
  & :hover {
    background-color: #f3f3f4;
  }
`;

const AlbumThumbnail = styled.span`
  background-color: #dadcde;
  border-radius: 0.4rem;
  height: 4.4rem;
  margin-right: 1.6rem;
  width: 4.4rem;
`;

const ItemTextWrapper = styled.span``;

const AlbumTitle = styled.p``;

const AlbumDetails = styled.p`
  color: #6b717c;
`;

const ErrorNotification = styled(Notification)`
  margin-bottom: 2.4rem;

  & > svg:not(.status-icon) {
    height: 1.2rem;
    width: 1.2rem;
  }

  ${breakpoint.md`
    margin: 0 2.4rem 2.4rem;
  `}
`;

interface InitialModalProps {
  handleCreateAlbumClick: () => void;
  selectedPhotos: string[];
  setResponseNotification: (args: string) => any;
  setShowAlbumModal: (show: boolean, replace?: boolean | undefined) => void;
}

const InitialModal: FC<InitialModalProps> = ({
  handleCreateAlbumClick,
  selectedPhotos,
  setResponseNotification,
  setShowAlbumModal,
}) => {
  const [{ getAlbumsList }] = useAlbumsContext();
  const albumsAPI = useAlbumsAPI();
  const dispatchPhotos = usePhotosDispatch();
  const albums = getAlbumsList() as Album.AsObject[];
  const [renderedAlbumsList, setRenderedAlbumsList] = useState<
    Album.AsObject[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const standardAlbums = albums.filter(
    album => album.albumType !== AlbumType.ALBUM_TYPE_SMART
  );

  useEffect(() => {
    if (standardAlbums.length > 0 && renderedAlbumsList.length < 1) {
      standardAlbums && setRenderedAlbumsList(standardAlbums);
    }
  }, [standardAlbums, renderedAlbumsList]);

  const selectExistingAlbum = (
    albumId: string,
    assetCount: number,
    name: string
  ) => {
    const photoCount = selectedPhotos.length;
    if (assetCount + photoCount > 1000)
      return setErrorMessage(
        'Albums are limited to 1000 photos. Please deselect photos or choose a different album.'
      );

    albumsAPI()
      .then(({ addAssetsToAlbum }) => {
        return addAssetsToAlbum(albumId, selectedPhotos);
      })
      .then(() => {
        setShowAlbumModal(false);
        setResponseNotification(
          `${photoCount} ${pluralize('photo', photoCount)} ${pluralize(
            'was',
            photoCount
          )} added to ${name}`
        );
        dispatchPhotos({
          type: PhotoActionTypes.SetSelectedPhotos,
          payload: { ids: [] },
        });
      })
      .catch(() => {
        setShowAlbumModal(false);
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  const albumsListItems = renderedAlbumsList?.map(album => {
    const {
      assetCount,
      createdAtTime,
      id: albumId,
      name,
      previewUrlsList,
    } = album;
    const photoCount = `${assetCount} ${pluralize('photo', assetCount)}`;
    const formattedDate = moment(createdAtTime).format('MMM D, YYYY');
    const hasPreview = previewUrlsList.length > 0;

    return (
      <AlbumListItem
        key={albumId}
        onClick={() => selectExistingAlbum(albumId, assetCount, name)}
      >
        <AlbumThumbnail>
          {hasPreview && (
            <CdnImageWrapper
              alt={`${name} - Preview 1`}
              imageUrl={previewUrlsList[0]}
              style={{
                width: 44,
                height: 44,
                objectFit: 'cover',
                borderRadius: '0.4rem',
              }}
            />
          )}
        </AlbumThumbnail>
        <ItemTextWrapper>
          <AlbumTitle>{name}</AlbumTitle>
          <AlbumDetails>
            {`${photoCount} - Created ${formattedDate}`}
          </AlbumDetails>
        </ItemTextWrapper>
      </AlbumListItem>
    );
  });

  return (
    <StyledPopupModal
      className={classnames({ 'album-error': errorMessage })}
      closeClick={() => setShowAlbumModal(false)}
    >
      {errorMessage && (
        <ErrorNotification
          className="album-error"
          onClick={() => setErrorMessage('')}
          status="negative"
        >
          {errorMessage}
        </ErrorNotification>
      )}
      <ModalHeader>
        <ModalHeaderText>Add photos to...</ModalHeaderText>
      </ModalHeader>
      <ScrollContainer className={classnames({ 'album-error': errorMessage })}>
        <AddWrapper onClick={handleCreateAlbumClick}>
          <PrimaryButton isIconOnly status="neutral">
            <Add />
          </PrimaryButton>
          <AddButtonText>Create new album</AddButtonText>
        </AddWrapper>

        {standardAlbums.length > 0 && (
          <>
            <SearchHeader>ALL ALBUMS</SearchHeader>
            <AlbumsSearchInput setRenderedAlbumsList={setRenderedAlbumsList} />
            <AlbumsListContainer>{albumsListItems}</AlbumsListContainer>
          </>
        )}
      </ScrollContainer>
    </StyledPopupModal>
  );
};

interface CreateNewModalProps {
  setResponseNotification: (args: string) => any;
  selectedPhotos: string[];
  setShowAlbumModal: (show: boolean, replace?: boolean | undefined) => void;
  spaceId: string;
}

const CreateNewModal: FC<CreateNewModalProps> = ({
  setResponseNotification,
  selectedPhotos,
  setShowAlbumModal,
  spaceId,
}) => {
  const albumsAPI = useAlbumsAPI();
  const dispatch = useAlbumsDispatch();
  const dispatchPhotos = usePhotosDispatch();
  const [albumTitle, setAlbumTitle] = useState('Untitled album');

  const handleInputChange = (e: FormEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setAlbumTitle(value);
  };

  const photoCount = selectedPhotos.length;
  const finalAlbumTitle = albumTitle === '' ? 'Untitled album' : albumTitle;

  const createNewAlbum = () => {
    albumsAPI()
      .then(({ createAlbum }) => {
        return createAlbum(finalAlbumTitle, selectedPhotos, spaceId);
      })
      .then(response => {
        const album = response.getAlbum();
        if (album) {
          dispatch({
            type: ActionTypes.AddAlbum,
            payload: {
              album,
            },
          });
        }
        setShowAlbumModal(false);
        setResponseNotification(
          `${photoCount} ${pluralize('photo', photoCount)} ${pluralize(
            'was',
            photoCount
          )} added to ${album?.toObject().name}`
        );
        dispatchPhotos({
          type: PhotoActionTypes.SetSelectedPhotos,
          payload: { ids: [] },
        });
      })
      .catch(() => {
        setShowAlbumModal(false);
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  return (
    <StyledConfirmationModal
      buttons={[
        <SecondaryButton
          key="cancel-album"
          onClick={() => setShowAlbumModal(false)}
          status="neutral"
        >
          Cancel
        </SecondaryButton>,
        <PrimaryButton
          key="save-album"
          onClick={createNewAlbum}
          status="default"
        >
          Save
        </PrimaryButton>,
      ]}
      closeClick={() => setShowAlbumModal(false)}
      title="Create a new album"
    >
      <SubHeader>
        {selectedPhotos.length} selected photos will be added to the album
      </SubHeader>
      <StyledInput
        aria-label="Album name"
        label="Album name"
        onChange={handleInputChange}
        placeholder="Untitled album"
        value={albumTitle}
      />
    </StyledConfirmationModal>
  );
};

interface CreateAlbumModalProps {
  setResponseNotification: (args: string) => any;
}

const CreateAlbumModal: FC<CreateAlbumModalProps> = ({
  setResponseNotification,
}) => {
  const [renderNewModal, setRenderNewModal] = useState(false);
  const { selectedPhotos } = usePhotosState();
  const { currentPhotoId, setShowAlbumModal } = useQueryParams();
  const { id: spaceId } = useCurrentSpace();

  const photosToAlbum = currentPhotoId ? [currentPhotoId] : selectedPhotos;
  const handleCreateAlbumClick = () => setRenderNewModal(true);

  return (
    <>
      <AlbumsProvider spaceId={spaceId}>
        {renderNewModal ? (
          <CreateNewModal
            setResponseNotification={setResponseNotification}
            spaceId={spaceId}
            selectedPhotos={photosToAlbum}
            setShowAlbumModal={setShowAlbumModal}
          />
        ) : (
          <InitialModal
            handleCreateAlbumClick={handleCreateAlbumClick}
            selectedPhotos={photosToAlbum}
            setResponseNotification={setResponseNotification}
            setShowAlbumModal={setShowAlbumModal}
          />
        )}
      </AlbumsProvider>
    </>
  );
};

export { CreateAlbumModal };
