import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { H1, PrimaryButton } from '@gsc/components';
import { Edit } from '@gsc/icons/react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import moment from 'moment';
import Fuse from 'fuse.js';
import {
  Space,
  SpaceBackfillStatus,
  SpaceStatus,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { SearchInput, Link } from '../../../shared/components';
import {
  useSpacesAPI,
  useSpacesDispatch,
  useSpacesState,
} from '../../../state/Spaces';
import { paths } from '../../../paths';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDataTable = styled(DataTable)`
  > header {
    display: none;
  }

  > div
    > .rdt_Table
    > .rdt_TableBody
    > .rdt_TableRow
    > .rdt_TableCell:last-child,
  > div
    > .rdt_Table
    > .rdt_TableHead
    > .rdt_TableHeadRow
    > .rdt_TableCol:last-child {
    margin-right: 1.6rem;
    max-width: 2rem;
    min-width: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
` as typeof DataTable;

const Status = styled.span`
  text-transform: capitalize;
`;

const IdWrapper = styled.span`
  display: inline-block;
  line-height: 2rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
`;

const StyledEditIcon = styled(Edit)`
  width: 2rem;
`;

const SpacesTable: FC = () => {
  const spacesState = useSpacesState();
  const { dispatch, actions } = useSpacesDispatch();
  const spacesAPI = useSpacesAPI();
  const [filterText, setfilterText] = useState('');

  const spaces = Object.values(spacesState.spaces);

  useEffect(() => {
    spacesAPI()
      .then(({ listSpaces }) => listSpaces())
      .then(spaces => {
        dispatch({
          type: actions.InitializeSpaces,
          payload: {
            spaces,
          },
        });
      });
  }, [actions.InitializeSpaces, dispatch, spacesAPI]);

  const handleFilterChange = (e: InputEvent) => {
    setfilterText((e.target as HTMLTextAreaElement).value);
  };

  const activeStatus = SpaceStatus;
  const backfillStatus = SpaceBackfillStatus;
  const tiers = SpaceTierType;

  const activeStatusKeys = Object.keys(activeStatus).map(stat => {
    const statArray = stat.split('_');
    return statArray
      .slice(2, stat.length)
      .join(' ')
      .toLowerCase();
  });

  const backfillStatusKeys = Object.keys(backfillStatus).map(stat => {
    const statArray = stat.split('_');
    return statArray
      .slice(3, stat.length)
      .join(' ')
      .toLowerCase();
  });

  const tierKeys = Object.keys(tiers).map(tier => {
    const statArray = tier.split('_');
    return statArray
      .slice(3, tier.length)
      .join(' ')
      .toLowerCase();
  });

  const columns: IDataTableColumn<Space.AsObject>[] = [
    {
      cell: row => {
        const SpaceLink = (
          <Link
            key={`link-${row.id}`}
            to={paths.spaces.space.photos.root(row.id)}
          >
            {row.id}
          </Link>
        );
        return SpaceLink;
      },
      name: 'ID',
      grow: 0,
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      cell: row => {
        const tierType = row.tier?.type;
        const RowTier = <Status>{tierType && tierKeys[tierType]}</Status>;
        return RowTier;
      },
      name: 'Tier',
      selector: 'tier',
      sortable: true,
    },
    {
      cell: row => {
        const RowStatus = <Status>{activeStatusKeys[row.status]}</Status>;
        return RowStatus;
      },
      name: 'Status',
      selector: 'status',
      sortable: true,
    },
    {
      cell: row => {
        const RowStatus = (
          <Status>{backfillStatusKeys[row.backfillStatus]}</Status>
        );
        return RowStatus;
      },
      name: 'Backfill Progress',
      selector: 'backfillStatus',
      sortable: true,
    },
    {
      cell: row => moment(row.createdAtTime).format('MMMM DD, YYYY h:mm A z'),
      name: 'Created at',
      selector: 'createdAtTime',
      sortable: true,
    },
    {
      cell: row => {
        const list = row.customerIdsList;
        const CompanyIds = (
          <IdWrapper>
            {list.map((id, i) => (list.length === i + 1 ? id : `${id}, `))}
          </IdWrapper>
        );
        return CompanyIds;
      },
      name: 'Company IDs',
      selector: 'customerIdsList',
      sortable: true,
    },
    {
      cell: row => {
        const EditLink = (
          <Link key={`edit-${row.id}`} to={paths.admin.editSpace(row.id)}>
            <StyledEditIcon />
          </Link>
        );
        return EditLink;
      },
      name: '',
      selector: 'status',
      sortable: false,
    },
  ];

  const options = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    ignoreLocation: true,
    minMatchCharLength: 1,
    keys: ['id', 'name', 'customerIdsList'],
  };
  const fuse = new Fuse(spaces, options);
  const filteredItems =
    filterText.trim() !== '' ? fuse.search(filterText) : spaces;

  return (
    <>
      <Helmet>
        <title>PhotoWorks - PhotoWorks Spaces</title>
        <meta name="description" content="PhotoWorks Spaces" />
      </Helmet>

      <Header>
        <H1>PhotoWorks Spaces</H1>

        <PrimaryButton to={paths.admin.newSpace}>New Space</PrimaryButton>
      </Header>

      <SearchInput
        id="search-spaces"
        name="search-spaces"
        value={filterText}
        placeholder="Search spaces..."
        onChange={handleFilterChange}
      />

      <StyledDataTable
        columns={columns}
        data={filteredItems}
        pagination
        defaultSortField="createdAtTime"
        defaultSortAsc={false}
      />
    </>
  );
};

export { SpacesTable };
