import { zoomIdentity, ZoomTransform } from 'd3-zoom';

const zoomToFitImageWidth = ({
  imageWidth,
  imageHeight,
  viewportWidth,
  viewportHeight,
  widthPadding = 0,
  heightPadding = 0,
  offsetFromTop = 0,
}: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const paddedViewportWidth = viewportWidth - widthPadding * 2;
  const paddedViewportHeight = viewportHeight - heightPadding * 2;

  const k = imageWidth ? paddedViewportWidth / imageWidth : 1;
  const x = widthPadding;
  const y = imageHeight
    ? offsetFromTop + (paddedViewportHeight - imageHeight * k) / 2
    : 0;

  return zoomIdentity.translate(x, y).scale(k);
};

const zoomToFitImageHeight = ({
  imageWidth,
  imageHeight,
  viewportWidth,
  viewportHeight,
  widthPadding = 0,
  heightPadding = 0,
}: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const paddedViewportWidth = viewportWidth - widthPadding * 2;
  const paddedViewportHeight = viewportHeight - heightPadding * 2;

  const k = imageHeight ? paddedViewportHeight / imageHeight : 1;
  const x = imageWidth ? (paddedViewportWidth - imageWidth * k) / 2 : 0;
  const y = heightPadding;

  return zoomIdentity.translate(x, y).scale(k);
};

const zoomToFitImage = (props: {
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  viewportWidth: number;
  viewportHeight: number;
  widthPadding?: number;
  heightPadding?: number;
  offsetFromTop?: number;
}): ZoomTransform => {
  const zoomToWidth = zoomToFitImageWidth(props);
  const zoomToHeight = zoomToFitImageHeight(props);

  const heightFits =
    (props.imageWidth ?? 0) * zoomToHeight.k <= props.viewportWidth &&
    (props.imageHeight ?? 0) * zoomToHeight.k <= props.viewportHeight;

  if (heightFits) {
    return zoomToHeight;
  } else {
    return zoomToWidth;
  }
};

const zoomToPoints = ({
  points,
  viewportWidth,
  viewportHeight,
  offsetFromTop = 0,
  paddingForLabel = 100,
  minScale = 0.1,
  maxScale = 2.0,
}: {
  points: string;
  viewportWidth: number;
  viewportHeight: number;
  offsetFromTop?: number;
  paddingForLabel?: number;
  minScale?: number;
  maxScale?: number;
}): ZoomTransform => {
  const coords = points.split(' ').map(n => parseInt(n));

  const xs = coords.filter((_c: any, i: number) => i % 2 === 0);
  const ys = coords.filter((_c: any, i: number) => i % 2 !== 0);

  const minX = Math.min(...xs);
  const maxX = Math.max(...xs);
  const minY = Math.min(...ys);
  const maxY = Math.max(...ys);

  const labelWidth = maxX - minX;
  const labelHeight = maxY - minY;

  const availableWidth = viewportWidth - paddingForLabel * 2;
  const availableHeight = viewportHeight - offsetFromTop - paddingForLabel * 2;

  const scaleX = Math.max(
    Math.min(availableWidth / labelWidth, maxScale),
    minScale
  );
  const scaleY = Math.max(
    Math.min(availableHeight / labelHeight, maxScale),
    minScale
  );

  const scaleXWorks =
    labelWidth * scaleX <= availableWidth &&
    labelHeight * scaleX <= availableHeight;

  const scale = scaleXWorks ? scaleX : scaleY;

  const [cX, cY] = xs
    .reduce(([rX, rY], x, i) => [rX + x, rY + ys[i]], [0, 0])
    .map(n => n / xs.length);

  const x = cX * scale - viewportWidth / 2;
  const y = cY * scale - (viewportHeight + offsetFromTop) / 2;

  return zoomIdentity.translate(-x, -y).scale(scale);
};

export {
  zoomToFitImage,
  zoomToFitImageWidth,
  zoomToFitImageHeight,
  zoomToPoints,
};
