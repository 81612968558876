import {
  GetPropertyValuesRequest,
  GetPropertyValuesResponse,
  SearchRequest,
  SearchResponse,
  GetPropertyHistogramRequest,
  GetPropertyHistogramResponse,
  CountDistinctPropertyValuesRequest,
  CountDistinctPropertyValuesResponse,
  GetFacetedFilterValuesRequest,
  GetFacetedFilterValuesResponse,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_api_pb';
import {
  GetEnrichedAssetDetailRequest,
  GetEnrichedAssetDetailResponse,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_api_pb';
import { grpc } from '@improbable-eng/grpc-web';
import { SearchClient, AssetDetailClient } from '.';

const PhotoClient = (metadata: grpc.Metadata) => ({
  getSearch: (request: SearchRequest): Promise<SearchResponse> =>
    new Promise((resolve, reject) => {
      SearchClient.search(request, metadata, (error, response) => {
        if (error) return reject(error);

        if (!response) {
          return reject(new Error('No response from PhotoClient:getSearch'));
        }
        return resolve(response);
      });
    }),

  getFacetedFilterValues: (
    request: GetFacetedFilterValuesRequest
  ): Promise<GetFacetedFilterValuesResponse> =>
    new Promise((resolve, reject) => {
      SearchClient.getFacetedFilterValues(
        request,
        metadata,
        (error, response) => {
          if (error) return reject(error);

          if (!response) {
            return reject(
              new Error('No response from PhotoClient:getFacetedFilterValues')
            );
          }

          return resolve(response);
        }
      );
    }),

  getFilterPropertyValues: (
    request: GetPropertyValuesRequest
  ): Promise<GetPropertyValuesResponse> =>
    new Promise((resolve, reject) => {
      SearchClient.getPropertyValues(request, metadata, (error, response) => {
        if (error) return reject(error);

        if (!response) {
          return reject(
            new Error('No response from PhotoClient:getPropertyValues')
          );
        }

        return resolve(response);
      });
    }),

  getAssetDetail: (
    request: GetEnrichedAssetDetailRequest
  ): Promise<GetEnrichedAssetDetailResponse> =>
    new Promise((resolve, reject) => {
      AssetDetailClient.getEnrichedAssetDetail(
        request,
        metadata,
        (error, response) => {
          if (error) return reject(error);

          if (!response) {
            return reject(
              new Error('No response from PhotoClient:getAssetDetail')
            );
          }

          return resolve(response);
        }
      );
    }),

  getPropertyHistogram: (
    request: GetPropertyHistogramRequest
  ): Promise<GetPropertyHistogramResponse> =>
    new Promise((resolve, reject) => {
      SearchClient.getPropertyHistogram(
        request,
        metadata,
        (error, response) => {
          if (error) return reject(error);

          if (!response) {
            return reject(
              new Error('No response from PhotoClient:getPropertyHistogram')
            );
          }

          return resolve(response);
        }
      );
    }),

  countDistinctPropertyValues: (
    request: CountDistinctPropertyValuesRequest
  ): Promise<CountDistinctPropertyValuesResponse> =>
    new Promise((resolve, reject) => {
      SearchClient.countDistinctPropertyValues(
        request,
        metadata,
        (error, response) => {
          if (error) return reject(error);

          if (!response) {
            return reject(
              new Error(
                'No response from PhotoClient:countDistinctPropertyValues'
              )
            );
          }

          return resolve(response);
        }
      );
    }),
});

export { PhotoClient };
