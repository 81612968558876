import { Dispatch, useEffect } from 'react';
import { Actions, ActionTypes } from './actions';
import { useMetadata } from '../Auth';
import { api } from './api';
import {
  Export,
  ExportStatus,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';

const useGetExportsList = (dispatch: Dispatch<Actions>) => {
  const getMetadata = useMetadata();

  useEffect(() => {
    let timeoutId: number;

    const exportIsInProgress = (exportItem: Export) =>
      exportItem.getStatus() === ExportStatus.EXPORT_STATUS_SCHEDULED ||
      exportItem.getStatus() === ExportStatus.EXPORT_STATUS_IN_PROGRESS;

    const fetchExports = () => {
      getMetadata()
        .then(metadata => {
          return api(metadata).listExports();
        })
        .then(exports => {
          dispatch({
            type: ActionTypes.ListExportsResponse,
            payload: { exports },
          });

          dispatch({
            type: ActionTypes.SetLoadingState,
            payload: { loading: false },
          });

          if (exports.getExportsList().some(exportIsInProgress)) {
            timeoutId = setTimeout(fetchExports, 3000);
          }
        });
    };

    fetchExports();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [dispatch, getMetadata]);
};

export { useGetExportsList };
