import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { VariableSizeGrid } from 'react-window';
import styled from 'styled-components';
import {
  ActionTypes,
  usePhotosAPI,
  usePhotosContext,
} from '../../../../../state/Photos';
import { useQueryParams } from '../../../../../state/QueryParams/hooks';
import { findLast, getColumnCount, pluralizePhotoCount } from '../utils';
import { PhotoCard } from '../../../shared/components/PhotoCard';
import { chunkArray, useWindowDimensions } from '../../../../../shared/utils';
import { WindowScroller } from 'react-virtualized';
import { BucketedGridCellFactory } from '../cells/BucketedGridCellFactory';
import {
  Filter,
  Operator,
  PropertyValueCount,
} from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { PhotoGridLayout } from '../PhotoGridLayout';
import { BucketRenderer, bucketRowHeight } from '../cells/BucketRenderer';
import { notificationRowHeight } from '../cells/NotificationRenderer';
import { buttonRowHeight } from '../cells/ButtonRenderer';
import classNames from 'classnames';
import {
  ItemData,
  BucketPages,
  BucketPage,
  BucketedGridCell,
  Bucket,
  BucketedGridCellType,
} from '../types';
import { NoResults } from '../../../shared/components/NoResults';

const StyledVariableSizeGrid = styled(VariableSizeGrid)`
  height: 100% !important;
  user-select: none;
  overflow: hidden !important;
`;

const StickyRowDiv = styled.div`
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  padding-top: 0.8rem;
  position: sticky !important;
  z-index: 1;

  &.scrolling {
    border-bottom: 0.1rem solid #eee;
  }
`;

const StickyRow = ({
  scrolling,
  ...props
}: {
  scrolling?: boolean;
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: ItemData;
}) => {
  const spaceRetainer = document.getElementById('header-space-retainer');
  const stickyPoint = spaceRetainer?.getBoundingClientRect()?.height ?? 113;

  return (
    <StickyRowDiv
      className={classNames({ scrolling })}
      style={{ top: `${stickyPoint / 10}rem` }}
      data-test="sticky-bucket"
    >
      <BucketRenderer {...props} />
    </StickyRowDiv>
  );
};

const MAX_SELECTABLE_PHOTOS = 1000;

const bucketReducerInitialState: PropertyValueCount.AsObject[] = [];
const initializeBucketReducer = () => bucketReducerInitialState;
const bucketReducer = (
  state: PropertyValueCount.AsObject[],
  newBuckets: PropertyValueCount.AsObject[] | null
) => (newBuckets ? [...state, ...newBuckets] : []);

const bucketPagesReducerInitialState: BucketPages = {};
const initializeBucketPagesReducer = () => bucketPagesReducerInitialState;
const bucketPagesReducer = (
  state: BucketPages,
  newBucketPages: BucketPages | null
) => (newBucketPages ? { ...state, ...newBucketPages } : {});

const BucketedGrid = () => {
  const [
    {
      assetDetailsById,
      loading,
      pagination,
      selectedPhotos,
      mobileSelectMode,
      totalHits,
    },
    dispatch,
  ] = usePhotosContext();
  const {
    getFilters,
    selectAlbumId,
    setShowPhoto,
    sortField,
    sortDirection,
    currentPhotoId,
  } = useQueryParams();

  const api = usePhotosAPI();
  const gridRef = useRef<VariableSizeGrid>(null);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isDesktop = windowWidth >= 1024;
  const columnCount = getColumnCount(windowWidth);
  const pageSize = pagination?.size ?? 30;

  const loadingCards = useMemo(
    () =>
      new Array(isDesktop ? 15 : 6)
        .fill(' ')
        .map((photo, index) => (
          <PhotoCard
            id={`loading_photo_${index}`}
            key={`loading_photo_${index}`}
          />
        )),
    [isDesktop]
  );

  const [bucketPages, dispatchBucketPages] = useReducer(
    bucketPagesReducer,
    bucketPagesReducerInitialState,
    initializeBucketPagesReducer
  );

  const loadMorePhotos = useCallback(
    (pageNumber: number, bucket?: string) => {
      const pageKey = `bucket: ${bucket}; page: ${pageNumber}`;
      if (bucketPages[pageKey]) return;

      dispatch({
        type: ActionTypes.SetPageLoading,
        payload: { pageNumber },
      });

      const startIndex = pageNumber * pageSize;
      const bucketFilter = new Filter();
      const [referenceName, propertyName] = sortField.split('.');
      bucketFilter.setReferenceName(referenceName);
      bucketFilter.setPropertyName(propertyName);
      bucketFilter.setOperator(Operator.OPERATOR_EQUALS);
      bucketFilter.setValuesList([bucket ?? '']);

      const filters = [...getFilters()];
      if (bucket) filters.push(bucketFilter);

      dispatchBucketPages({
        [pageKey]: {
          pageNumber,
          ids: [],
          loading: true,
        },
      });

      api()
        .then(({ getPhotoSearch }) =>
          getPhotoSearch({
            filters,
            from: startIndex,
            sortField: 'mission_responses.completed_at',
            sortDirection: '-',
          })
        )
        .then(photos => {
          dispatch({
            type: ActionTypes.GetPhotosResponse,
            payload: { photos, pageNumber },
          });

          dispatchBucketPages({
            [pageKey]: {
              pageNumber,
              ids: photos.getAssetDetailsList().map(p => p.getId()),
              loading: false,
            },
          });
        });
    },
    [bucketPages, dispatch, pageSize, sortField, api, getFilters]
  );

  useEffect(() => {
    dispatchBucketPages(null);
  }, [getFilters]);

  const [buckets, dispatchBuckets] = useReducer(
    bucketReducer,
    bucketReducerInitialState,
    initializeBucketReducer
  );

  const [bucketsHaveLoaded, setBucketsHaveLoaded] = useState(false);
  const loadBuckets = useCallback(
    (afterKey?: string) => {
      const [referenceName, propertyName] = sortField.split('.');

      return api().then(({ getFilterPropertyValues }) =>
        getFilterPropertyValues({
          referenceName,
          propertyName,
          sortDirection,
          filters: getFilters(),
          applyAllFilters: true,
          afterKey,
          pageSize: 1000,
        })
      );
    },
    [api, getFilters, sortDirection, sortField]
  );

  useEffect(() => {
    let unmounted = false;

    setBucketsHaveLoaded(false);
    dispatchBuckets(null);

    loadBuckets().then(({ propertyValueCountsList }) => {
      if (unmounted) return;
      dispatchBuckets(propertyValueCountsList);
      setBucketsHaveLoaded(true);
    });

    return () => {
      unmounted = true;
    };
  }, [loadBuckets]);

  const bucketFaultLines = useMemo(
    () =>
      buckets.reduce<Bucket[]>((result, bucket) => {
        const lastBucketEndsAt = result.slice(-1)[0]?.endsAt ?? -1;
        const startsAt = lastBucketEndsAt + 1;
        const endsAt = startsAt + bucket.count - 1;

        result.push({ ...bucket, startsAt, endsAt });

        return result;
      }, []),
    [buckets]
  );

  const bucketFaultLinesWithNull = useMemo(() => {
    if (bucketFaultLines.length > 0) {
      return bucketFaultLines;
    } else if (totalHits > 0) {
      return [
        { value: '', count: totalHits, startsAt: 0, endsAt: totalHits - 1 },
      ];
    } else {
      return [];
    }
  }, [bucketFaultLines, totalHits]);

  const width = Math.min(windowWidth, 1920) - 32;
  const height = windowHeight - 250;
  const tileAspectRatio = 1.3525;
  const columnWidth = Math.floor(width / columnCount);
  const photoRowHeight = columnWidth * tileAspectRatio;

  const getPage = useCallback(
    (pageNumber: number, bucket: string): BucketPage | undefined =>
      bucketPages[`bucket: ${bucket}; page: ${pageNumber}`],
    [bucketPages]
  );

  const [drag, setDrag] = useState<string[] | null>(null);
  useEffect(() => {
    if (!drag) return;
    const onMouseUp = () => setDrag(null);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [drag]);

  const getPhotoByIndex = useCallback(
    (index: number, bucket: string): AssetDetail.AsObject | undefined => {
      const pageNumber = Math.floor(index / pageSize);
      const page = getPage(pageNumber, bucket);
      if (!page) return;
      return assetDetailsById[page?.ids?.[index % pageSize]];
    },
    [assetDetailsById, getPage, pageSize]
  );

  const [expandedBucket, setExpandedBucket] = useState<Bucket | null>(null);
  const [currentBucketRowIndex, setCurrentBucketRowIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0, true);

    if (expandedBucket) {
      window.scroll({ top: expandedBucket.y ?? 0 });
    }
  }, [expandedBucket]);

  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0, true);
    gridRef.current?.resetAfterColumnIndex(0, true);
  }, [windowWidth]);

  const rowHeights = useMemo<{ [rowType: string]: number }>(
    () => ({
      bucket: bucketRowHeight,
      button: buttonRowHeight,
      notification: notificationRowHeight,
      photo: photoRowHeight,
    }),
    [photoRowHeight]
  );

  const rows: BucketedGridCell[][] = useMemo(() => {
    const theMostRowsWeCanRender = 90000;
    let expandedRowHeight = 0;

    // eslint-disable-next-line complexity
    const rowsSoFar = bucketFaultLinesWithNull.reduce((result, bucket) => {
      if (result.length >= theMostRowsWeCanRender) return result;

      const isExpanded =
        expandedBucket && expandedBucket.value === bucket.value;
      const previousCell = result.slice(-1)[0]?.[0];
      const previousY = previousCell?.y ?? 0;
      const previousHeight = previousCell ? rowHeights[previousCell.type] : 0;
      const bucketY = previousY + previousHeight;
      const bucketRow = [
        {
          type: 'bucket' as BucketedGridCellType,
          bucket,
          y: bucketY,
          rowIndex: result.length,
          columnIndex: 0,
        },
      ];
      result.push(bucketRow);

      const photos: BucketedGridCell[] = [];
      const firstRow = columnCount;
      const maxPhotosForBucket = isExpanded ? 10000 : firstRow;
      const theMostElasticCanGive = bucket.startsAt + maxPhotosForBucket - 1;
      const theActualEndOfThisBucket = bucket.endsAt;
      const upTo10kPhotos = Math.min(
        theMostElasticCanGive,
        theActualEndOfThisBucket
      );
      for (let i = bucket.startsAt; i <= upTo10kPhotos; i++) {
        photos.push({
          type: 'photo',
          photo: getPhotoByIndex(i - bucket.startsAt, bucket.value),
          index: i,
          bucket: bucket.value,
          bucketOffset: bucket.startsAt,
        });
      }
      const photoRows = chunkArray(photos, columnCount);
      for (
        let i = 0, len = photoRows.length, photoY = bucketY + rowHeights.bucket;
        i < len;
        i++, photoY += rowHeights.photo
      ) {
        result.push(photoRows[i].map(pr => ({ ...pr, y: photoY })));
        if (i > 0) expandedRowHeight += rowHeights.photo;
      }
      if (bucket.count > firstRow && !isExpanded) {
        const previousCell = result.slice(-1)[0][0];
        const photosCounted = pluralizePhotoCount(
          Math.min(bucket.count, 10000)
        );

        result.push([
          {
            type: 'button' as BucketedGridCellType,
            text: `Show all ${photosCounted}`,
            y: (previousCell.y ?? 0) + rowHeights[previousCell.type],
            onClick: () => {
              setExpandedBucket({
                ...bucket,
                y:
                  bucketY +
                  rowHeights.bucket * 2 +
                  -22 +
                  (expandedBucket && bucketY < (expandedBucket.y ?? 0)
                    ? 0
                    : rowHeights.button - expandedRowHeight),
              });

              setCurrentBucketRowIndex(bucketRow[0].rowIndex);
            },
          },
        ]);
      }

      if (isExpanded && bucket.count > maxPhotosForBucket) {
        const previousCell = result.slice(-1)[0][0];
        result.push([
          {
            type: 'notification',
            status: 'info',
            message: `Whoa! You’ve scrolled through 10,000 photos and reached the limit for the amount of photos that can be rendered in a section. Please use the filters above to help you narrow down your results.`,
            y: (previousCell.y ?? 0) + rowHeights[previousCell.type],
          },
        ]);
      }

      return result;
    }, new Array<BucketedGridCell[]>());

    if (rowsSoFar.length >= theMostRowsWeCanRender) {
      rowsSoFar.push([
        {
          type: 'notification',
          status: 'warning',
          message:
            'You’ve reached the browser limit for how many sections and photos can displayed at once. To view more photos, please collapse sections or use the filters above to narrow down your results.',
        },
      ]);
    } else if (bucketFaultLinesWithNull.length % 1000 === 0) {
      rowsSoFar.push([
        {
          type: 'button' as BucketedGridCellType,
          text: `Load 1,000 more sections`,
          buttonComponent: 'PrimaryButton',
          disableAfterClick: true,
          disabledText: 'Loading...',
          onClick: () => {
            const lastBucket = bucketFaultLinesWithNull.slice(-1)[0];
            loadBuckets(lastBucket.value).then(
              ({ propertyValueCountsList }) => {
                dispatchBuckets(propertyValueCountsList);
                gridRef.current?.resetAfterRowIndex(0, true);
              }
            );
          },
        },
      ]);
    }

    return rowsSoFar;
  }, [
    bucketFaultLinesWithNull,
    columnCount,
    expandedBucket,
    getPhotoByIndex,
    loadBuckets,
    rowHeights,
  ]);

  const getRowHeight = useCallback(
    i =>
      i === 0
        ? 0
        : (rowHeights[rows[i]?.[0]?.type] ?? 0) +
          // include a little extra space for the fixed-position notification.
          (i === rows.length - 1 ? 44 : 0),
    [rows, rowHeights]
  );

  const [bucketsAllSelected, setBucketsAllSelected] = useState<string[]>([]);
  const toggleBucketAllSelected = useCallback(
    (bucketId: string) => {
      const addingBucket = !bucketsAllSelected.includes(bucketId);
      if (addingBucket) {
        setBucketsAllSelected([...bucketsAllSelected, bucketId]);
      } else {
        setBucketsAllSelected(bucketsAllSelected.filter(id => id !== bucketId));
      }

      const bucketFilter = new Filter();
      const [referenceName, propertyName] = sortField.split('.');
      bucketFilter.setReferenceName(referenceName);
      bucketFilter.setPropertyName(propertyName);
      bucketFilter.setOperator(Operator.OPERATOR_EQUALS);
      bucketFilter.setValuesList([bucketId]);

      api()
        .then(({ getPhotoSearch }) =>
          getPhotoSearch({
            filters: [...getFilters(), bucketFilter],
            sortField,
            sortDirection,
            from: 0,
            pageSize: 1000,
          })
        )
        .then(photos => {
          if (addingBucket) {
            const ids = photos
              .getAssetDetailsList()
              .map((ad: AssetDetail) => ad.getId())
              .filter(id => !selectedPhotos.includes(id))
              .slice(0, MAX_SELECTABLE_PHOTOS);

            dispatch({
              type: ActionTypes.SetSelectedPhotos,
              payload: { ids: [...selectedPhotos, ...ids] },
            });
          } else {
            const ids = photos
              .getAssetDetailsList()
              .map((ad: AssetDetail) => ad.getId());

            dispatch({
              type: ActionTypes.SetSelectedPhotos,
              payload: { ids: selectedPhotos.filter(id => !ids.includes(id)) },
            });
          }
        });
    },
    [
      api,
      bucketsAllSelected,
      dispatch,
      getFilters,
      selectedPhotos,
      sortDirection,
      sortField,
    ]
  );

  // this clears the selected buckets when selected photos are cleared.
  useEffect(() => {
    if (selectedPhotos.length === 0) {
      setBucketsAllSelected([]);
    }
  }, [selectedPhotos.length]);

  const itemData: ItemData = useMemo(
    () => ({
      loadMorePhotos,
      suspendGrid: !!currentPhotoId,
      getPage,
      pageSize,
      selectAlbumId,
      selectedPhotos,
      setShowPhoto,
      mobileSelectMode,
      drag,
      setDrag,
      rows,
      bucketsAllSelected,
      toggleBucketAllSelected,
    }),
    [
      loadMorePhotos,
      currentPhotoId,
      getPage,
      pageSize,
      selectAlbumId,
      selectedPhotos,
      setShowPhoto,
      mobileSelectMode,
      drag,
      setDrag,
      rows,
      bucketsAllSelected,
      toggleBucketAllSelected,
    ]
  );

  const estimatedRowHeight = useMemo(
    () =>
      rows.reduce(
        (result, row) => result + rowHeights[row[0]?.type ?? 'photo'],
        0
      ) / rows.length,
    [rowHeights, rows]
  );

  const [isScrollingTheGrid, setIsScrollingTheGrid] = useState(false);

  const filterBarHeight = useMemo(
    () =>
      document.querySelector('.filter-bar')?.getBoundingClientRect()?.height ??
      57,
    // disabling lint check here because we want to remeasure the filter bar
    // height whenever windowWidth changed, but windowWidth does not actually
    // need to be used in this situation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowWidth]
  );

  const onScroll = useCallback(
    ({ scrollTop }) => {
      gridRef.current?.scrollTo({ scrollTop, scrollLeft: 0 });

      setIsScrollingTheGrid(window.scrollY >= 96);

      const scrollY = Math.min(scrollTop, window.scrollY - 172);
      const headerHeight = 71 + filterBarHeight;
      const isBucketAboveScroll = (r: BucketedGridCell[]): boolean =>
        r[0]?.type === 'bucket' && (r[0].y ?? 0) < scrollY + headerHeight;
      const nearestBucket = findLast(rows, isBucketAboveScroll);
      setCurrentBucketRowIndex(nearestBucket?.[0]?.rowIndex ?? null);
    },
    [rows, filterBarHeight]
  );

  return (
    <div data-test="bucketed-grid">
      <WindowScroller onScroll={onScroll}>{() => <div />}</WindowScroller>

      {bucketsHaveLoaded && !loading ? (
        bucketFaultLinesWithNull.length > 0 ? (
          <>
            <StickyRow
              data={itemData}
              rowIndex={currentBucketRowIndex ?? 0}
              columnIndex={0}
              scrolling={isScrollingTheGrid}
              style={{}}
            />

            <StyledVariableSizeGrid
              ref={gridRef}
              className="photoalbum-photo-grid"
              columnCount={columnCount}
              columnWidth={_index => columnWidth}
              estimatedColumnWidth={columnWidth}
              estimatedRowHeight={estimatedRowHeight}
              height={height}
              rowCount={rows.length}
              rowHeight={getRowHeight}
              width={width}
              overscanRowCount={3}
              itemData={itemData}
            >
              {BucketedGridCellFactory}
            </StyledVariableSizeGrid>
          </>
        ) : (
          <NoResults
            title="Oops! Looks like there aren’t any photos matching your selection."
            message="Try changing the sort to find more photos."
          />
        )
      ) : (
        <>
          <StickyRow data={itemData} rowIndex={0} columnIndex={0} style={{}} />
          <PhotoGridLayout>{loadingCards}</PhotoGridLayout>
        </>
      )}
    </div>
  );
};

export { BucketedGrid };
