import styled from 'styled-components';
import { breakpoint, H2 } from '@gsc/components';

const Details = styled.article`
  font-size: 1.6rem;
  padding: 3.2rem 2.4rem;

  ${breakpoint.lg`
    & {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
  `}
`;

const DetailsTitle = styled(H2)`
  margin-bottom: 3.2rem;
`;

export { Details, DetailsTitle };
