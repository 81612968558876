import { Dispatch } from 'react';
import {
  Album,
  Pagination,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import { AssetDetail } from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { Actions } from './actions';

export enum AlbumPageLoadStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export interface AlbumPage {
  pageNumber: number;
  ids: string[];
  status: AlbumPageLoadStatus;
}

export interface State {
  albumsById: { [id: string]: Album.AsObject | SmartAlbum.AsObject };
  albumsPages: { [pageId: string]: AlbumPage };
  totalCount: number | undefined;
  pageSize: number;
  pagination: Pagination.AsObject | undefined;

  albumAssetsById: { [id: string]: AssetDetail.AsObject };
  albumAssetsPages: { [pageId: string]: AlbumPage };
  albumAssetCounts: { [albumId: string]: number };

  sharedAlbum: Album.AsObject | SmartAlbum.AsObject | undefined;
}

export interface StateWithSelectors extends State {
  getAlbumsList: () => (Album.AsObject | SmartAlbum.AsObject)[];
  getPage: (pageNumber: number) => AlbumPage | undefined;
  getAsset: (id: string) => AssetDetail.AsObject | undefined;
  getPageOfAssets: (
    albumId: string,
    pageNumber: number
  ) => AlbumPage | undefined;
}

export interface AlbumsActions {
  dispatch: Dispatch<Actions>;
  loadPage: (pageNumber: number) => Promise<void>;
  getAlbum: (id?: string, shareToken?: string) => Promise<void>;
  getSmartAlbum: (id?: string, shareToken?: string) => Promise<void>;
  loadPageOfAssets: (
    albumId: string,
    pageNumber: number,
    shareToken?: string
  ) => Promise<void>;
  loadPageOfSmartAssets: (
    albumId: string,
    pageNumber: number,
    shareToken?: string
  ) => Promise<void>;
}
