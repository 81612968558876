import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  breakpoint,
  ConfirmationModal,
  PrimaryButton,
  SecondaryButton,
} from '@gsc/components';
import {
  ActionTypes,
  useAlbumsAPI,
  useAlbumsDispatch,
} from '../../../../state/Albums';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { paths } from '../../../../paths';
import { useCurrentSpace } from '../../../../state/Spaces';

const DeleteModal = styled(ConfirmationModal)`
  max-width: 32.7rem;
  min-width: 32.7rem;

  ${breakpoint.md`
    max-width: 45rem;
    min-width: 40.8rem;
  `}
`;

interface DeleteAlbumModalProps {
  albumId: string;
  setResponseNotification: (args: string) => any;
}

const DeleteAlbumModal: FC<DeleteAlbumModalProps> = ({
  albumId,
  setResponseNotification,
}) => {
  const history = useHistory();
  const { id: spaceId } = useCurrentSpace();
  const albumsAPI = useAlbumsAPI();
  const { setShowDeleteAlbumModal } = useQueryParams();
  const dispatch = useAlbumsDispatch();

  const deleteCurrentAlbum = () => {
    albumsAPI()
      .then(({ deleteAlbum }) => {
        return deleteAlbum(albumId);
      })
      .then(() => {
        setShowDeleteAlbumModal(false);
        dispatch({ type: ActionTypes.ResetPagination });
        history.push(paths.spaces.space.albums.root(spaceId));
      })
      .catch(() => {
        setShowDeleteAlbumModal(false);
        setResponseNotification(
          'We were unable to process your request. Please try again. If this error continues, please contact support.'
        );
      });
  };

  return (
    <DeleteModal
      buttons={[
        <SecondaryButton
          key="cancel-delete-album"
          name="cancel-delete-album"
          onClick={() => setShowDeleteAlbumModal(false)}
          status="neutral"
        >
          Cancel
        </SecondaryButton>,
        <PrimaryButton
          key="delete-album"
          name="delete-album"
          onClick={deleteCurrentAlbum}
          status="negative"
        >
          Delete It
        </PrimaryButton>,
      ]}
      closeClick={() => setShowDeleteAlbumModal(false)}
      title="Delete this album?"
    >
      Deleting this album will permanently remove it from the system. Once you
      delete it, there’s no getting it back.
    </DeleteModal>
  );
};

export { DeleteAlbumModal };
