import styled from 'styled-components';
import { breakpoint, color, font, typography } from '@gsc/components';
import { ChevronLeftBold, ChevronRightBold } from '@gsc/icons/react';

const DateRangePickerStyleOverrides = styled.div`
  .CalendarMonth_caption {
    ${typography.body};
    color: ${color.text900};
    font-weight: 500;
    padding-bottom: 3.1rem;
    padding-top: 1.6rem;
  }

  .CalendarMonth {
    padding: 0;

    ${breakpoint.md`
      padding-left: 0 !important;
      padding-right: 1.3rem;
    `};
  }

  .DayPicker_weekHeader {
    padding: 0;
    top: 4.8rem;

    ${breakpoint.md`
      padding-left: 0 !important;
      padding-right: 1.3rem;
    `};
  }

  .DayPicker_weekHeader_li {
    font-weight: ${font.weight.medium};
  }

  .DayPicker_transitionContainer {
    height: 26rem;
    width: 26rem;
  }

  .CalendarDay {
    border: none;
    vertical-align: middle;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:hover {
    background: ${color.mxDeepOcean};
    border: none;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover {
    background: ${color.info10};
    border: none;
    color: ${color.text900};
  }

  .CalendarDay__selected_start {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }

  .CalendarDay__selected_end {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${color.info10};
    border: none;
    color: ${color.text900};
  }
`;

const PrevArrow = styled(ChevronLeftBold)`
  color: ${color.ui50};
  height: 1.2rem;
  left: 3rem;
  position: absolute;
  top: 2rem;

  ${breakpoint.md`
    left: 1.5rem;
  `};
`;

const NextArrow = styled(ChevronRightBold)`
  color: ${color.ui50};
  height: 1.2rem;
  position: absolute;
  right: 2.8rem;
  top: 2rem;

  ${breakpoint.md`
    right: 4.2rem;  
  `};
`;

export { DateRangePickerStyleOverrides, PrevArrow, NextArrow };
