import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMetadata } from '../Auth';
import {
  getKpis,
  getPlanogram,
  BrandbankProduct,
  CompletePlanogram,
  matchPlanogram,
  Results,
  ProductDetails,
  ProductsEntity,
  KpiResultEntity,
  findProducts,
  KPI_RESULT_TYPE_PLANOGRAM,
} from './wapi_planogram_api';
import { useCurrentSpaceObj } from '../Spaces';

type SelectedProductIdentifier = {
  brandbankUuid: string;
  product?: ProductDetails;
  brandBankProduct?: BrandbankProduct;
  kpiProductResults?: ProductsEntity;
};

export type SegmentState = {
  selectedSegmentIndex?: number;
  hoveringSegmentIndex?: number;
  hoveringInPlanogramSegmentIndex?: number;
};

export const ComplianceStateContext = createContext<
  | {
      isPlanogramImage: boolean | undefined;
      state: ComplianceState | undefined;
      segmentState: SegmentState;
      setSegmentState: (state: SegmentState) => void;
      selectedProduct?: SelectedProductIdentifier;
      setSelectedProduct: (brandbank_uuid?: string) => void;
      currentFilters: string[];
      toggleCurrentFilter: (filter: string) => void;
      setCurrentFilters: React.Dispatch<React.SetStateAction<string[]>>;
      photoGridId?: number;
      companyId?: number;
      configuredColumns?: string[];
    }
  | undefined
>(undefined);

interface ComplianceProviderProps {
  children: ReactNode;
  photoGridId?: number;
  companyId?: number;
}

export type ComplianceState = {
  planogram?: CompletePlanogram;
  mostRecentPlanogramResult?: KpiResultEntity;
  kpiResult?: Results;
  brandbankMap: Map<string, BrandbankProduct>;
  planogramProductMap: Map<string, ProductDetails>;
};

export const ComplianceProvider: FC<ComplianceProviderProps> = props => {
  const { photoGridId, companyId } = props;

  const [state, setState] = useState<ComplianceState>({
    brandbankMap: new Map<string, BrandbankProduct>(),
    planogramProductMap: new Map<string, ProductDetails>(),
  });

  const [isPlanogramImage, setIsPlanogramImage] = useState<boolean>();
  const [selectedProductId, setSelectedProduct] = useState<string>();
  const [segmentState, setSegmentState] = useState<SegmentState>({
    selectedSegmentIndex: undefined,
    hoveringSegmentIndex: undefined,
  });
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);

  const toggleCurrentFilter = (filter: string) => {
    setCurrentFilters(filters => {
      const updated = [...filters];

      const index = updated.indexOf(filter);
      if (index < 0) {
        updated.push(filter);
      } else {
        updated.splice(index, 1);
      }

      return updated;
    });
  };

  const getMetadata = useMetadata();

  const sortResults = (a: KpiResultEntity, b: KpiResultEntity) => {
    const aDate = new Date(a.createdAtTime);
    const bDate = new Date(b.createdAtTime);
    return bDate.getTime() - aDate.getTime();
  };

  useEffect(() => {
    if (!photoGridId || !companyId) {
      if (companyId) {
        setIsPlanogramImage(false);
      }
      return;
    }

    getMetadata().then(metadata => {
      getKpis(metadata, companyId, photoGridId)
        .then(result => {
          const kpiResult = result.data;
          if (!!kpiResult.kpiResult && kpiResult.kpiResult.length > 0) {
            setIsPlanogramImage(true);
            const mostRecentPlanogramResult = kpiResult.kpiResult
              .filter(r => r.kpiResultType == KPI_RESULT_TYPE_PLANOGRAM)
              .sort(sortResults)?.[0];
            const results = mostRecentPlanogramResult.results;
            // POG-111 Dump debugging information to the console
            console.log(
              `id: ${mostRecentPlanogramResult.id}
              photo_grid_id: ${photoGridId} 
              planogram_uuid: ${results?.planogram_uuid}
              execution_id: ${results?.execution_id}
              payload_file_name: ${results?.payload_file_name}`
            );
            setState(state => {
              const update = { ...state };
              update.mostRecentPlanogramResult = mostRecentPlanogramResult;
              update.kpiResult = results;
              return update;
            });
          } else {
            setIsPlanogramImage(false);
          }
        })
        .catch(() => {
          setIsPlanogramImage(false);
        });
    });
  }, [getMetadata, photoGridId, companyId]);

  const planogramUuid = state.kpiResult?.planogram_uuid;
  useEffect(() => {
    if (!planogramUuid || !companyId) return;
    getMetadata().then(metadata => {
      getPlanogram(metadata, companyId, planogramUuid).then(result => {
        const planogram = result.data.planograms[0];
        setState(state => ({ ...state, planogram }));
      });
    });
  }, [companyId, getMetadata, planogramUuid]);

  useEffect(() => {
    if (!state.kpiResult || !planogramUuid || !companyId) return;

    const kpiResult = state.kpiResult;
    getMetadata().then(metadata => {
      matchPlanogram(metadata, companyId, planogramUuid).then(result => {
        const brandbankMap = new Map(
          result.data.results.matchedProducts.map(match => [
            match.searchProduct.brandbankGuid,
            match.searchProduct,
          ])
        );
        const planogramProductMap = new Map(
          result.data.results.matchedProducts.map(match => [
            match.searchProduct.brandbankGuid,
            match.planogramProduct,
          ])
        );
        setState(state => {
          const update = { ...state };
          update.brandbankMap = new Map([
            ...update.brandbankMap,
            ...brandbankMap,
          ]);
          update.planogramProductMap = new Map([
            ...update.planogramProductMap,
            ...planogramProductMap,
          ]);
          return update;
        });
      });

      let guids: string[] = [];

      if (kpiResult.rectangles) {
        guids = guids.concat(
          kpiResult.rectangles
            .filter(
              el =>
                el.rect_status === 'unplanned_item' && el.brandbank_uuid !== ''
            )
            .map(el => el.brandbank_uuid ?? '')
        );
      }
      guids.length > 0 &&
        findProducts(metadata, companyId, planogramUuid, guids).then(result => {
          const unplannedItems = new Map(
            result.data.products.map(match => [match.brandbankGuid, match])
          );

          setState(state => {
            const update = { ...state };
            update.brandbankMap = new Map([
              ...update.brandbankMap,
              ...unplannedItems,
            ]);
            return update;
          });
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, getMetadata, planogramUuid, state.kpiResult]);

  const selectedProduct = useMemo(() => {
    if (!selectedProductId) return undefined;

    const product = state.planogram?.details.products.find(
      el => el.brandbankGuid === selectedProductId
    );
    const brandBankProduct = state.brandbankMap.get(selectedProductId);
    const kpiProductResults = state.kpiResult?.products?.find(
      el => el.brandbank_uuid === selectedProductId
    );

    return {
      brandbankUuid: selectedProductId,
      product,
      brandBankProduct,
      kpiProductResults,
    };
  }, [selectedProductId, state.brandbankMap, state.kpiResult, state.planogram]);

  const currentSpace = useCurrentSpaceObj();
  const configuredColumns = useMemo(
    () =>
      currentSpace?.config?.assetDetailConfig?.tablesList
        ?.find(({ tableName }) => tableName === 'image_recognition_tags')
        ?.columnConfigsList?.map(({ columnName }) => columnName) ?? [],
    [currentSpace]
  );

  return (
    <ComplianceStateContext.Provider
      value={{
        state,
        isPlanogramImage,
        selectedProduct,
        setSelectedProduct: (brandbank_uuid?: string) => {
          setSegmentState({});
          setSelectedProduct(brandbank_uuid);
        },
        segmentState,
        setSegmentState,
        currentFilters,
        toggleCurrentFilter,
        setCurrentFilters,
        companyId,
        photoGridId,
        configuredColumns,
      }}
    >
      {props.children}
    </ComplianceStateContext.Provider>
  );
};

export const useComplianceStateContext = () => {
  const context = useContext(ComplianceStateContext);

  if (!context) {
    throw new Error(
      'useComplianceStateContext is only available within ComplianceStateContext Provider'
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => context, []);
};
