import React, { useContext } from 'react';
import { FC } from 'react';
import { breakpoint } from '../../../../../style/breakpoint';
import styled from 'styled-components';
import { FlexSpacer } from './ComplianceViewer';
import { ComplianceStateContext } from '../../../../../../../state/Compliance/context';

interface ViewControlsProps {
  zoomIn: () => void;
  zoomOut: () => void;
  fitToScreen: () => void;
  fitToWidth: () => void;
  isZoomed: boolean;
  zoomToSelection: boolean;
  toggleZoomToSelection: () => void;
  previousSegmentDisabled: boolean;
  nextSegmentDisabled: boolean;
  selectSegment: (index: number) => void;
  planogramVisible: boolean;
}

const Controls = styled.div<{ planogramVisible?: boolean }>`
  position: absolute;
  bottom: ${p => (p.planogramVisible ? '-3rem' : 0)};
  width: 100%;
  padding: 1.4rem 2.4rem;
  z-index: 2;
  display: none;
  ${breakpoint.lg`
    display: flex;
  `}
`;

const ControlButtons = styled.div`
  border-radius: 0.4rem;
  border: 0.1rem solid #5c6063;
  background: #262b2f;
  display: flex;
  height: 3.2rem;
  padding: 0.2rem 0.8rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  flex-shrink: 0;
`;

const StyledIconButton = styled.button<{
  disabled?: boolean;
  selected?: boolean;
}>`
  background-color: #262b2faa;
  border-radius: 0.4rem;
  display: flex;
  color: white;
  padding: 0;
  z-index: 3;
  height: 2.4rem;
  width: 2.4rem;
  align-items: center;
  justify-content: center;
  svg > path {
    stroke: ${p => (p.disabled ? '#929597' : p.selected ? '#00D9EB' : 'white')};
    fill: ${p => (p.selected ? '#00D9EB' : undefined)};
  }
  &:hover {
    background-color: ${p => (!p.disabled ? '#5c6063' : '#262b2faa')};
  }
`;

const ViewControls: FC<ViewControlsProps> = ({
  zoomIn,
  zoomOut,
  fitToScreen,
  fitToWidth,
  zoomToSelection,
  toggleZoomToSelection,
  isZoomed,
  selectSegment,
  previousSegmentDisabled,
  nextSegmentDisabled,
  planogramVisible,
}) => {
  const selectedSegment = useContext(ComplianceStateContext)?.segmentState;
  return (
    <Controls planogramVisible={planogramVisible}>
      <FlexSpacer />
      <ControlButtons>
        <StyledIconButton onClick={zoomOut} data-test="view-controls-zoom-out">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <title>Zoom Out</title>
            <path
              d="M4 12.0002H20"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledIconButton>
        <StyledIconButton
          onClick={isZoomed ? fitToScreen : fitToWidth}
          data-test="view-controls-fit"
        >
          {isZoomed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <title>Fit to Screen</title>
              <g clipPath="url(#clip0_2091_88409)">
                <path
                  d="M0.375 7.87402C0.375 8.85894 0.568993 9.83421 0.945903 10.7442C1.32281 11.6541 1.87526 12.4809 2.5717 13.1773C3.26814 13.8738 4.09493 14.4262 5.00487 14.8031C5.91482 15.18 6.89009 15.374 7.875 15.374C8.85991 15.374 9.83518 15.18 10.7451 14.8031C11.6551 14.4262 12.4819 13.8738 13.1783 13.1773C13.8747 12.4809 14.4272 11.6541 14.8041 10.7442C15.181 9.83421 15.375 8.85894 15.375 7.87402C15.375 6.88911 15.181 5.91384 14.8041 5.0039C14.4272 4.09396 13.8747 3.26716 13.1783 2.57072C12.4819 1.87428 11.6551 1.32184 10.7451 0.944927C9.83518 0.568017 8.85991 0.374023 7.875 0.374023C6.89009 0.374023 5.91482 0.568017 5.00487 0.944927C4.09493 1.32184 3.26814 1.87428 2.5717 2.57072C1.87526 3.26716 1.32281 4.09396 0.945903 5.0039C0.568993 5.91384 0.375 6.88911 0.375 7.87402Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                />
                <path
                  d="M17.625 17.6245L13.1782 13.1777"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                />
                <path
                  d="M4.125 7.87402H11.625"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                />
              </g>
              <defs>
                <clipPath id="clip0_2091_88409">
                  <rect width="18" height="18" fill="none" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <title>Fit to Width</title>
              <path
                d="M0.375 7.87427C0.375 8.85918 0.568993 9.83445 0.945903 10.7444C1.32281 11.6543 1.87526 12.4811 2.5717 13.1776C3.26814 13.874 4.09493 14.4265 5.00487 14.8034C5.91482 15.1803 6.89009 15.3743 7.875 15.3743C8.85991 15.3743 9.83518 15.1803 10.7451 14.8034C11.6551 14.4265 12.4819 13.874 13.1783 13.1776C13.8747 12.4811 14.4272 11.6543 14.8041 10.7444C15.181 9.83445 15.375 8.85918 15.375 7.87427C15.375 6.88935 15.181 5.91408 14.8041 5.00414C14.4272 4.0942 13.8747 3.26741 13.1783 2.57097C12.4819 1.87453 11.6551 1.32208 10.7451 0.945171C9.83518 0.568261 8.85991 0.374268 7.875 0.374268C6.89009 0.374268 5.91482 0.568261 5.00487 0.945171C4.09493 1.32208 3.26814 1.87453 2.5717 2.57097C1.87526 3.26741 1.32281 4.0942 0.945903 5.00414C0.568993 5.91408 0.375 6.88935 0.375 7.87427Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                d="M17.625 17.6242L13.1782 13.1775"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                d="M4.125 7.87427H11.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                d="M7.875 4.12427V11.6243"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
            </svg>
          )}
        </StyledIconButton>
        <StyledIconButton onClick={zoomIn} data-test="view-controls-zoom-in">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <title>Zoom In</title>
            <path
              d="M4 12H20"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 4V20"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledIconButton>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1"
          height="32"
          viewBox="0 0 1 32"
          fill="none"
        >
          <rect width="1" height="32" fill="#5C6063" />
        </svg>
        <StyledIconButton
          onClick={toggleZoomToSelection}
          selected={zoomToSelection}
          data-test="view-controls-zoom-selection"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <title>Toggle Zoom to Selection</title>
            <path
              d="M3.5 5.76667C3.5 5.16551 3.73881 4.58898 4.16389 4.16389C4.58898 3.73881 5.16551 3.5 5.76667 3.5"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.76667 18.2335C5.16551 18.2335 4.58898 17.9947 4.16389 17.5696C3.73881 17.1445 3.5 16.568 3.5 15.9668"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9668 3.5C16.568 3.5 17.1445 3.73881 17.5696 4.16389C17.9947 4.58898 18.2335 5.16551 18.2335 5.76667"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2334 12.5668V9.16675"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 12.5668V9.16675"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.16675 3.5H12.5668"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.16675 18.2334H12.5668"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.0881 14.0891L16.4923 20.5L17.6944 17.6954L20.4998 16.4933L14.0881 14.0891Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledIconButton>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1"
          height="32"
          viewBox="0 0 1 32"
          fill="none"
        >
          <rect width="1" height="32" fill="#5C6063" />
        </svg>
        <StyledIconButton
          disabled={previousSegmentDisabled}
          data-test="view-controls-prev-segment"
          onClick={() => {
            selectedSegment?.selectedSegmentIndex !== undefined &&
              selectSegment(selectedSegment.selectedSegmentIndex - 1);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="red"
          >
            <title>Previous Segment</title>
            <path
              d="M16 20L8.1607 12.3769C8.10976 12.3274 8.06934 12.2687 8.04177 12.204C8.01419 12.1393 8 12.07 8 12C8 11.93 8.01419 11.8607 8.04177 11.796C8.06934 11.7313 8.10976 11.6726 8.1607 11.6231L16 4"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
        </StyledIconButton>
        <StyledIconButton
          disabled={nextSegmentDisabled}
          data-test="view-controls-next-segment"
          onClick={() => {
            selectedSegment?.selectedSegmentIndex !== undefined &&
              selectSegment(selectedSegment.selectedSegmentIndex + 1);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <title>Next Segment</title>
            <path
              d="M8 4L15.8393 11.6231C15.8902 11.6726 15.9307 11.7313 15.9582 11.796C15.9858 11.8607 16 11.93 16 12C16 12.07 15.9858 12.1393 15.9582 12.204C15.9307 12.2687 15.8902 12.3274 15.8393 12.3769L8 20"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
        </StyledIconButton>
      </ControlButtons>
      <FlexSpacer />
    </Controls>
  );
};
export { ViewControls };
