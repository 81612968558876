import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';
import {
  generateDateString,
  generateLabel,
} from '../../../Photos/shared/filters/formatDateFilters';
import { uppercaseFirstLetter } from '../../../../shared/utils';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';
import { Filter } from '@gsc/proto-gen-v2/dist/idl/aperture/search/v1/search_pb';

const Details = styled.p`
  color: #6b717c;
  font-size: 1.4rem;

  & > span {
    font-weight: 500;
  }
`;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface HeaderSmartFiltersProps {
  album: AlbumType;
  photoCount: number | undefined;
}

const HeaderSmartFilters: FC<HeaderSmartFiltersProps> = ({
  album,
  photoCount,
}) => {
  const filtersList =
    album && 'searchCriteria' in album
      ? album?.searchCriteria?.filtersList
      : [];

  const dateFilters = filtersList?.filter((f: Filter.AsObject) =>
    f.propertyName?.includes('completed_at')
  );

  const otherFilters = filtersList?.filter(
    (f: Filter.AsObject) => !f.propertyName?.includes('completed_at')
  );

  const dateLabel = () => {
    if (dateFilters) {
      const startFilter: any = Object.assign({}, dateFilters[0]);
      startFilter.value = startFilter?.valuesList?.toString();
      delete startFilter.valuesList;
      const endFilter: any = Object.assign({}, dateFilters[1]);
      endFilter.value = endFilter?.valuesList?.toString();
      delete endFilter.valuesList;

      return generateLabel([startFilter, endFilter]);
    } else return null;
  };

  const findDateString = () => {
    const smartDateType = (album as SmartAlbum.AsObject).dateType;
    const dateString = generateDateString(smartDateType);
    if (dateString === 'custom') {
      return dateLabel();
    } else {
      return dateString;
    }
  };

  const dateString =
    dateFilters &&
    dateFilters?.length > 0 &&
    (album as SmartAlbum.AsObject).dateType === 0
      ? dateLabel()
      : findDateString();

  const generateFiltersString = () => {
    const filters: any[] = [];
    otherFilters &&
      otherFilters.map((filter: Filter.AsObject, index: number) => {
        const refName = uppercaseFirstLetter(filter.referenceName);
        const propName = filter.propertyName;
        filters.push(
          <Fragment key={refName + index}>
            {index > 0 && index !== otherFilters.length - 1 && ', '}
            {index > 0 && index === otherFilters.length - 1 && ' and '}
            <span>
              {refName} {propName}
            </span>{' '}
            is one of <span>&apos;{filter.valuesList.join(', ')}&apos;</span>
          </Fragment>
        );
      });

    return filters;
  };

  const imageCount =
    photoCount && photoCount > 1000
      ? 'the 1000 most recent photos'
      : pluralize('photo', photoCount, true);

  const andString = otherFilters && otherFilters.length > 0 ? 'and' : null;

  return (
    <Details data-test="photo-count">
      This album contains <span>{imageCount}</span> where{' '}
      {generateFiltersString()} {andString} <span>Date</span> is{' '}
      <span>&apos;{dateString}&apos;</span>.
    </Details>
  );
};

export { HeaderSmartFilters };
