import { Dispatch, useContext } from 'react';
import { Actions } from './actions';
import { State } from './reducer';
import {
  ComplianceViewerStateContext,
  ComplianceViewerDispatchContext,
} from './context';

const useComplianceViewerState = () => {
  const context = useContext(ComplianceViewerStateContext);

  if (!context) {
    throw new Error('usePhotosState must be used within a PhotosProvider');
  }
  return context;
};

const useComplianceViewerDispatch = () => {
  const context = useContext(ComplianceViewerDispatchContext);

  if (!context) {
    throw new Error('usePhotosDispatch must be used within a PhotosProvider');
  }

  return context;
};

const useComplianceViewerContext = (): [State, Dispatch<Actions>] => [
  useComplianceViewerState(),
  useComplianceViewerDispatch(),
];

export {
  useComplianceViewerState,
  useComplianceViewerDispatch,
  useComplianceViewerContext,
};
