import React, { FC, FormEvent, useContext } from 'react';
import styled from 'styled-components';
import { SelectTabRadio, SelectTabRadioGroup, Tooltip } from '@gsc/components';
import classnames from 'classnames';

import {
  isMobileDevice,
  useWindowDimensions,
} from '../../../../../../shared/utils';
import { FormContext } from '../../../../../../shared/components/FormContext';
import {
  Format,
  ImageQuality,
} from '@gsc/proto-gen-v2/dist/idl/aperture/export/v1/export_pb';
import {
  SectionWrapper,
  SectionHeader,
  SubWrapper,
  SubHeader,
} from './ExportSettingsForm';

const TooltipWrapper = styled.div`
  display: inline-block;
  line-height: 1.6rem;
  margin-left: 0.8rem;
  > div > svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const FormSettings: FC = () => {
  const { handleChange, setValues, values } = useContext(FormContext);
  const largerWindow = useWindowDimensions().width >= 1019;
  const isDesktop = !isMobileDevice() && largerWindow;
  const format = parseInt(values.format, 10);
  const imageQuality = parseInt(values.imageQuality, 10);

  const setPPTValues = (e: FormEvent) => {
    handleChange(e);
    setValues({
      photosPerPage: '2',
    });
  };

  const setPDFValues = (e: FormEvent) => {
    handleChange(e);
    setValues({
      photosPerPage: '4',
    });
  };

  return (
    <SectionWrapper>
      <SectionHeader>Settings</SectionHeader>
      <SubWrapper>
        <SubHeader>Export format</SubHeader>
        <SelectTabRadioGroup
          className={classnames({
            'small-radio-group': isDesktop,
          })}
        >
          <SelectTabRadio
            checked={format === Format.FORMAT_PPTX}
            id="format-pptx"
            name="format"
            onChange={(e: FormEvent) => setPPTValues(e)}
            value={Format.FORMAT_PPTX.toString()}
          >
            PowerPoint
          </SelectTabRadio>
          <SelectTabRadio
            checked={format === Format.FORMAT_PDF}
            id="format-pdf"
            name="format"
            onChange={(e: FormEvent) => setPDFValues(e)}
            value={Format.FORMAT_PDF.toString()}
          >
            PDF
          </SelectTabRadio>
          <SelectTabRadio
            checked={format === Format.FORMAT_JPEG}
            id="format-jpeg"
            name="format"
            onChange={handleChange}
            value={Format.FORMAT_JPEG.toString()}
          >
            JPEG
          </SelectTabRadio>
        </SelectTabRadioGroup>
      </SubWrapper>
      <SubWrapper>
        <SubHeader>Image quality</SubHeader>
        <TooltipWrapper>
          <Tooltip dataTest="image-quality-tooltip">
            The higher the quality setting, the more detail is preserved in the
            image, but the larger the file size.
          </Tooltip>
        </TooltipWrapper>
        <SelectTabRadioGroup
          className={classnames({ 'large-radio-group': isDesktop })}
        >
          <SelectTabRadio
            checked={imageQuality === ImageQuality.IMAGE_QUALITY_MAXIMUM}
            id="imageQuality-max"
            name="imageQuality"
            onChange={handleChange}
            value={ImageQuality.IMAGE_QUALITY_MAXIMUM.toString()}
          >
            {isMobileDevice() ? 'Max' : 'Maximum'}
          </SelectTabRadio>
          <SelectTabRadio
            checked={imageQuality === ImageQuality.IMAGE_QUALITY_HIGH}
            id="imageQuality-high"
            name="imageQuality"
            onChange={handleChange}
            value={ImageQuality.IMAGE_QUALITY_HIGH.toString()}
          >
            High
          </SelectTabRadio>
          <SelectTabRadio
            checked={imageQuality === ImageQuality.IMAGE_QUALITY_MEDIUM}
            id="imageQuality-med"
            name="imageQuality"
            onChange={handleChange}
            value={ImageQuality.IMAGE_QUALITY_MEDIUM.toString()}
          >
            Medium
          </SelectTabRadio>
          <SelectTabRadio
            checked={imageQuality === ImageQuality.IMAGE_QUALITY_LOW}
            id="imageQuality-low"
            name="imageQuality"
            onChange={handleChange}
            value={ImageQuality.IMAGE_QUALITY_LOW.toString()}
          >
            Low
          </SelectTabRadio>
        </SelectTabRadioGroup>
      </SubWrapper>
    </SectionWrapper>
  );
};

export { FormSettings };
