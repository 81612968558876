/* eslint-disable complexity */
import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { H3, H4, TertiaryButton } from '@gsc/components';
import { ChevronDownBold, ChevronUpBold } from '@gsc/icons/react';
import {
  AssetDetail,
  Record,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';
import { useCurrentSpaceObj } from '../../../../../../state/Spaces';
import { Details, DetailsTitle } from './components';
import { useQueryParams } from '../../../../../../state/QueryParams/hooks';

const getProperty = (record: Record.AsObject, propertyName: string): any =>
  record.propertiesList.find(({ name }) => name === propertyName)?.value;

const getItemName = (tag: Record.AsObject): string => {
  const name = getProperty(tag, 'item_text') ?? '';
  const type = getProperty(tag, 'type_name') ?? '';

  switch (type) {
    case 'menu_generic_product':
      return `Generic: "${name}"`;
    case 'menu_unknown_product':
      return `Unknown: "${name}"`;
    default:
      return name;
  }
};

const MenuDetailsListV2 = styled.ul`
  & ${H3} {
    margin: 0 -3.2rem;
    padding: 1.6rem 3.2rem;
    border-bottom: 0.1rem solid #f3f3f4;

    &.first-section {
      border-top: 0.1rem solid #f3f3f4;
    }
  }

  & ${H4} {
    margin: 0 -3.2rem;
    padding: 1.6rem 3.2rem;
    border-bottom: 0.1rem solid #f3f3f4;
  }
`;

const TagPropertyList = styled.ul`
  padding: 0.8rem 0 0;

  & > li {
    color: #6b717c;
    padding: 0.4rem 0;

    & > .property-value {
      float: right;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
`;

const TagList = styled.ul`
  margin: 0 -3.2rem;
  padding-top: 0.1rem;

  & > li {
    padding: 1.6rem 3.2rem;
    font-size: 1.4rem;
    color: #222b3b;
    cursor: pointer;
    border-bottom: 0.1rem solid #f3f3f4;

    &:hover {
      background: #f3f3f4;
    }

    &.active {
      background: #e6e7e9;
    }

    & > .tag-details-toggle {
      color: #222b3b;
      float: right;
      padding: 1.2rem;
      margin-top: -1rem;
      margin-right: -2rem;

      &:hover,
      &:focus {
        background: transparent !important;
        border-color: transparent;
        box-shadow: none;
        color: #222b3b;
      }

      & > svg {
        margin-right: 0;
        width: 1.2rem;
      }
    }
  }
`;

interface Section {
  name: string;
  drinks: Drink[];
  x: number;
  y: number;
}

interface Drink {
  name: string;
  tag: Record.AsObject;
  ingredients?: Ingredient[];
  y: number;
}

interface Ingredient {
  name: string;
  tag: Record.AsObject;
  position: number;
}

interface MenuTagsPanelProps {
  assetDetail: AssetDetail.AsObject | undefined;
}

const MenuTagsPanelV2: FC<MenuTagsPanelProps> = ({ assetDetail }) => {
  const currentSpace = useCurrentSpaceObj();

  const displayFields = useMemo(
    () =>
      currentSpace?.config?.assetDetailConfig?.tablesList
        ?.find(({ tableName }) => tableName === 'image_recognition_tags')
        ?.columnConfigsList?.map(({ columnName }) => columnName)
        ?.sort((a, b) => (a == 'name' ? -1 : b == 'name' ? 1 : 0)) ?? [],
    [currentSpace]
  );

  const tags = useMemo(
    () =>
      assetDetail?.referencesList
        ?.find(({ name }) => name === 'image_recognition_tags')
        ?.recordsList?.filter(
          tag => !parseInt(getProperty(tag, 'deleted_at'))
        ) ?? [],
    [assetDetail]
  );

  const { selectedTagId, setSelectedTagId } = useQueryParams();

  const drinks = useMemo(
    () => tags.filter(tag => getProperty(tag, 'type_name') !== 'menu_unit'),
    [tags]
  );

  const sections = useMemo<Section[]>(
    () =>
      Object.values(
        tags.reduce<{ [name: string]: Section }>((result, tag) => {
          const section = getProperty(tag, 'section_text') ?? '';
          // sectionType possible values = ["wine", "cocktail", "beer", "spirits"]
          const sectionType = getProperty(tag, 'section_type') ?? '';
          const tagType = getProperty(tag, 'type_name');
          const itemName = getProperty(tag, 'item_name') ?? '';
          const position = getProperty(tag, 'position') ?? '0,0';
          const [x, y] = position.split(',').map(parseFloat);

          if (!result[section])
            result[section] = { name: section, drinks: [], x, y };

          // use menu unit to group unique cocktails
          if (tagType == 'menu_unit') {
            // TODO: "index" for ingredients is coming, use that instead of x/y
            const ingredients = tags
              .filter(
                tag =>
                  getProperty(tag, 'item_name') === itemName &&
                  getProperty(tag, 'type_name') !== 'menu_unit'
              )
              .map(tag => {
                const order = getProperty(tag, 'product_order') ?? '0';

                return {
                  name: getItemName(tag),
                  tag,
                  position: parseInt(order, 10),
                };
              })
              .sort((a, b) => a.position - b.position);

            if (ingredients?.length) {
              result[section].drinks.push({
                name: itemName,
                tag,
                ingredients,
                y: y,
              });
            }
          }

          // add drinks with no ingredients
          if (['wine', 'beer', 'spirits'].includes(sectionType)) {
            result[section].drinks.push({
              name: getItemName(tag),
              tag,
              y: y,
            });
          }

          return result;
        }, {})
      )
        // sort section by x then y
        // sort drinks by y
        .sort((a, b) => a.x - b.x || a.y - b.y)
        .map(section => ({
          ...section,
          drinks: section.drinks.sort((a, b) => a.y - b.y),
        })),
    [tags]
  );

  const displayTag = (tag: Record.AsObject, name: string, i: number): any => {
    const tagId = getProperty(tag, 'id');
    const active = tagId === selectedTagId;
    const ToggleIcon = active ? ChevronUpBold : ChevronDownBold;

    return (
      <li
        key={i}
        className={classnames({ active, 'ir-tag-list-item': true })}
        onClick={() => setSelectedTagId(active ? null : tagId)}
        data-tag-id={tagId}
      >
        {displayFields.length > 0 && (
          <TertiaryButton className="tag-details-toggle">
            <ToggleIcon />
          </TertiaryButton>
        )}

        {name}

        {active && (
          <TagPropertyList>
            {displayFields.map(propertyName => {
              const name = propertyName
                .split('_')
                .map(w => w[0].toUpperCase() + w.substring(1))
                .join(' ');

              const value = getProperty(tag, propertyName);
              if (!value) return null;

              return (
                <li key={propertyName}>
                  {name}: <span className="property-value">{value}</span>
                </li>
              );
            })}
          </TagPropertyList>
        )}
      </li>
    );
  };

  return (
    <Details>
      <DetailsTitle>
        {pluralize('Drinks', drinks.length, true)} Tagged
      </DetailsTitle>

      <MenuDetailsListV2>
        {sections.map((section, i) => {
          let firstSection = '';
          if (i === 0) firstSection = 'first-section';

          return (
            <li key={i}>
              {!!section.name.length && (
                <H3 className={firstSection}>{section.name}</H3>
              )}
              {section.drinks.map((drink, i) =>
                drink.ingredients?.length ? (
                  <ul key={i}>
                    <li key={i}>
                      <H4>{drink.name}</H4>
                      <TagList className="unit-tag">
                        {drink.ingredients.map((ingredient, i) =>
                          displayTag(ingredient.tag, ingredient.name, i)
                        )}
                      </TagList>
                    </li>
                  </ul>
                ) : (
                  <TagList key={i}>
                    {displayTag(drink.tag, drink.name, i)}
                  </TagList>
                )
              )}
            </li>
          );
        })}
      </MenuDetailsListV2>
    </Details>
  );
};

export { MenuTagsPanelV2 };
