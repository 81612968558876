import React, { FC, useCallback, useMemo, useRef } from 'react';
import { WindowScroller } from 'react-virtualized';
import { FixedSizeGrid } from 'react-window';
import { useWindowDimensions } from '../../../../shared/utils';
import { useAlbumsContext } from '../../../../state/Albums';
import { useQueryParams } from '../../../../state/QueryParams/hooks';
import { getColumnCount } from '../../../Photos/screens/PhotoGrid/utils';
import { AlbumPhotoCell } from './PhotoCell';
import { ItemData } from './types';
import { AlbumPhotosFixedSizeGrid } from '../../components';
import {
  Album,
  SmartAlbum,
} from '@gsc/proto-gen-v2/dist/idl/aperture/album/v1/album_pb';

const tileAspectRatio = 1.3525;

type AlbumType = Album.AsObject | SmartAlbum.AsObject | undefined;

interface AlbumPhotosGridProps {
  album: AlbumType;
  setResponseNotification: (args: string) => any;
  smartAlbum: boolean;
}

const AlbumPhotosGrid: FC<AlbumPhotosGridProps> = ({
  album,
  setResponseNotification,
  smartAlbum,
}) => {
  const [
    { pageSize, albumAssetCounts, getAsset, getPageOfAssets },
    { loadPageOfAssets, loadPageOfSmartAssets, getAlbum },
  ] = useAlbumsContext();
  const { showEditAlbum: deleteMode, setShowPhoto } = useQueryParams();

  const albumId = album?.id;
  const shareToken = album?.shareSettings?.shareToken;
  const cardConfig = album?.shareSettings?.cardConfig;

  const gridRef = useRef<FixedSizeGrid>(null);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const columnCount = getColumnCount(windowWidth);
  const width = Math.min(windowWidth, 1920) - 32;
  const height = windowHeight - 186;
  const columnWidth = Math.floor(width / columnCount);
  const rowHeight = Math.round(columnWidth * tileAspectRatio);
  const rowCount =
    albumId && albumAssetCounts[albumId]
      ? Math.ceil(albumAssetCounts[albumId] / columnCount)
      : 30;

  const onScroll = useCallback(({ scrollTop }) => {
    gridRef.current?.scrollTo({ scrollTop, scrollLeft: 0 });
  }, []);

  const itemData: ItemData = useMemo(
    () => ({
      albumId,
      columnCount,
      cardConfig,
      deleteMode,
      pageSize,
      shareToken,
      smartAlbum,
      getAlbum,
      getAsset,
      getPageOfAssets,
      loadPageOfAssets,
      setResponseNotification,
      setShowPhoto,
      loadPageOfSmartAssets,
    }),
    [
      albumId,
      columnCount,
      cardConfig,
      deleteMode,
      pageSize,
      shareToken,
      smartAlbum,
      getAlbum,
      getAsset,
      getPageOfAssets,
      loadPageOfAssets,
      setResponseNotification,
      setShowPhoto,
      loadPageOfSmartAssets,
    ]
  );

  if (!album) return null;

  return (
    <>
      <WindowScroller onScroll={onScroll}>{() => <div />}</WindowScroller>

      <AlbumPhotosFixedSizeGrid
        ref={gridRef}
        columnCount={columnCount}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        rowCount={rowCount}
        width={width}
        height={height}
        itemData={itemData}
        overscanRowCount={3}
      >
        {AlbumPhotoCell}
      </AlbumPhotosFixedSizeGrid>
    </>
  );
};

export { AlbumPhotosGrid };
