import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { Close } from '@gsc/icons/react';

import { border, color, mixin, typography } from '@gsc/components';

interface PillProps {
  as?: any;
  children: ReactNode;
  dataTest?: string;
  onClick?: (event: MouseEvent) => void;
}

const StyledPill = styled.span(
  ({ as }: PillProps) => `
  ${typography.bodySmall}
  background-color: ${color.ui10};
  border-color: ${color.ui10};
  border-radius: 999rem;
  border: ${border.normal(color.ui10)};
  color: ${color.text900};
  display: inline-block;
  line-height: 2.2rem;
  padding: 0 2.4rem 0 1.2rem;
  position: relative;

  &:hover {
    background-color: ${
      as === 'a' || as === 'button' ? color.ui15 : color.ui10
    };
    cursor: ${as === 'a' || as === 'button' ? 'pointer' : 'default'};
  }
`
);

const CloseWrapper = styled.div`
  ${mixin.size('1.2rem')}
  cursor: pointer;
  display: inline-block;
  margin: 0.6rem;
  position: absolute;
  right: 0;
  top: -0.1rem;

  &:hover {
    color: ${color.ui50};
  }
`;

const Pill: FC<PillProps &
  AnchorHTMLAttributes<any> &
  ButtonHTMLAttributes<any>> = ({
  as,
  children,
  dataTest,
  onClick,
  ...rest
}) => (
  <StyledPill as={as} data-test={dataTest} {...rest}>
    <span>{children}</span>
    {onClick && (
      <CloseWrapper
        data-test={dataTest && `${dataTest}-close`}
        onClick={onClick}
      >
        <Close />
      </CloseWrapper>
    )}
  </StyledPill>
);

export { Pill };
