import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { breakpoint, color, font, typography } from '@gsc/components';

const BorderedDiv = styled.div`
  &:before,
  &:after {
    background-color: ${color.ui15};
    content: '';
    display: block;
    height: 0.1rem;
    left: -3.2rem;
    position: absolute;
    width: calc(100% + 6rem);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  > span {
    flex: 1;
  }

  svg {
    width: 1.4rem;
  }

  ${breakpoint.xl`
    &:after {
      display: none;
    }
  `}
`;

const Signout = styled(BorderedDiv)`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const UserInfoWrapper = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  padding: 0 1.2rem;
  transform: translateX(-1.2rem);
  width: calc(100% + 2.4rem);
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.2rem;
`;

interface UserDataProps extends HTMLAttributes<any> {
  isBold?: boolean;
}

const UserData = styled.p(
  ({ isBold }: UserDataProps) => `
  ${typography.body}
  color: ${color.ui50};

  ${
    isBold
      ? `
    font-weight: ${font.weight.medium};
  `
      : ''
  }
`
);

interface UserAvatarProps extends HTMLAttributes<any> {
  isLarge?: boolean;
}

const UserAvatar = styled.div(
  ({ isLarge }: UserAvatarProps) => `
  ${typography.heading6}
  align-items: center;
  background-color: ${color.mxDeepOcean};
  border-radius: 100%;
  color: ${color.ui01};
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin-left: 1.2rem;
  width: 2.4rem;

  ${
    isLarge
      ? `
    ${typography.heading4}
    height: 4rem;
    margin: 1.2rem 0 1.2rem;
    width: 4rem;
  `
      : ''
  }
`
);

export {
  BorderedDiv,
  Signout,
  UserInfoWrapper,
  UserInfo,
  UserData,
  UserAvatar,
};
