import { produce } from 'immer';
import decode from 'jwt-decode';
import { State, AccessToken } from './types';
import { Actions, ActionTypes } from './actions';

const reducer = (state: State, action: Actions): State =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SetStatus:
        draft.status = action.payload.status;
        return draft;

      case ActionTypes.SetToken:
        const { token } = action.payload;

        draft.token = token;
        const decoded: AccessToken = decode(token);
        draft.tokenExpiration = decoded.exp * 1000;

        return draft;

      case ActionTypes.SetUser:
        const { user } = action.payload;
        draft.user.email = user.email;
        draft.user.id = user['https://gospotcheck.com/user/id'].toString();
        draft.user.name = user.name;
        draft.user.nickname = user.nickname;
        draft.user.picture = user.picture;
        draft.user.updatedAt = user.updated_at;

        return draft;

      case ActionTypes.SetRoles:
        const { hasGscAccess, isApertureAdmin, isSpaceAdmin } = action.payload;
        if (typeof hasGscAccess === 'boolean')
          draft.hasGscAccess = hasGscAccess;
        if (typeof isApertureAdmin === 'boolean')
          draft.isApertureAdmin = isApertureAdmin;
        if (typeof isSpaceAdmin === 'boolean')
          draft.isSpaceAdmin = isSpaceAdmin;

        return draft;

      default:
        return draft;
    }
  });

export { reducer };
