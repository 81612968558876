import React, { FC, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { breakpoint } from '@gsc/components';
import useComponentSize from '@rehooks/component-size';

import { isMobileDevice } from '../../../../shared/utils';
import { useCurrentSpaceObj } from '../../../../state/Spaces';
import { PageHeader } from '../../shared/components';
import { ConfigTables } from './ConfigTables';
import { DesktopArticle, MobileArticle } from '../../shared/components/Article';

const MobileConfigLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(min(100vw, 1020px));
  padding-top: 13.2rem;
`;

const DesktopConfigLayoutContainer = styled(MobileConfigLayoutContainer)`
  padding-top: 8.8rem;
`;

const StyledMobileArticle = styled(MobileArticle)`
  padding: 0 3.2rem 7.2rem;
`;

const StyledDesktopArticle = styled(DesktopArticle)`
  padding: 0 1.6rem 7.2rem 30.6rem;

  ${breakpoint.lg`
    max-width: 94rem;
  `}
`;

interface StyledProps {
  isDesktop: boolean | undefined;
}

const ConfigLayoutContainer: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <DesktopConfigLayoutContainer>{children}</DesktopConfigLayoutContainer>
  ) : (
    <MobileConfigLayoutContainer>{children}</MobileConfigLayoutContainer>
  );

const VeryStyledArticle: FC<StyledProps> = ({ isDesktop, children }) =>
  isDesktop ? (
    <StyledDesktopArticle>{children}</StyledDesktopArticle>
  ) : (
    <StyledMobileArticle>{children}</StyledMobileArticle>
  );

const DataConfig: FC = () => {
  const currentSpace = useCurrentSpaceObj();
  const tablesList = currentSpace.schema?.tablesList;
  const assetDetailConfig = currentSpace.config?.assetDetailConfig;

  const tableRef = useRef(null);
  const tableSize = useComponentSize(tableRef);
  const largerWindow = tableSize.width >= 1020;
  const isDesktop = !isMobileDevice() && largerWindow;

  return (
    <div ref={tableRef}>
      <ConfigLayoutContainer isDesktop={isDesktop}>
        <VeryStyledArticle isDesktop={isDesktop}>
          <Helmet>
            <title>PhotoWorks - Data Configuration</title>
            <meta name="description" content="Data configuration info" />
          </Helmet>

          <PageHeader
            title="Data Configuration"
            bodyText="Synced data is associated with each photo and available to filter on or display on a photo."
          />

          {tablesList &&
            tablesList.length > 0 &&
            assetDetailConfig?.hasOwnProperty('tablesList') && (
              <ConfigTables
                assetDetailConfig={assetDetailConfig}
                dataTest="settings-config"
                tablesList={tablesList}
                tableSizeWidth={tableSize.width}
              />
            )}
        </VeryStyledArticle>
      </ConfigLayoutContainer>
    </div>
  );
};

export { DataConfig };
