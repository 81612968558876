import React, { FC } from 'react';
import { FilterComponentProps } from './types';
import { FilterTypeahead } from '../FilterTypeahead';
import { StyledFilterDropdown } from './components';

const TypeaheadDropdownFilter: FC<FilterComponentProps> = ({
  tableName,
  columnName,
  filterCount,
  filterText,
}) => {
  return (
    <StyledFilterDropdown
      columnName={columnName}
      dataTest="filter-trigger"
      filterCount={filterCount}
      isActive={!!filterCount && filterCount > 0}
      tableName={tableName}
      text={filterText ?? ''}
    >
      <FilterTypeahead
        referenceName={tableName}
        propertyName={columnName}
        dataTest="filter"
      />
    </StyledFilterDropdown>
  );
};

export { TypeaheadDropdownFilter };
