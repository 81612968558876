import { SelectItemValue } from '../components';

export const getSelectedValues = (value?: SelectItemValue) => {
  // if (Array.isArray(value)) {
  //   return value.map(value =>
  //     typeof value === 'object' ? value.value : value
  //   );
  // }

  return value?.value;
};

export const getSelectedLabel = (value: SelectItemValue) => {
  // if (Array.isArray(value)) {
  //   return value.map(value =>
  //     typeof value === 'object' ? value.label : value.toString()
  //   );
  // }

  return value.label;
};

// const notIncluded = (
//   value: SelectItemValue | undefined,
//   selection: SelectItemValue
// ) => {
//   if (typeof value === 'object') {
//     return typeof selection === 'object'
//       ? value.value !== selection.value
//       : value.value !== selection;
//   }
//   return typeof selection === 'object'
//     ? value !== selection.value
//     : value !== selection;
// };

export const getSelection = (
  value: SelectItemValue | undefined,
  options: SelectItemValue[] | undefined,
  selection: SelectItemValue,
  _multiple: boolean
) => {
  // if (multiple) {
  //   if (Array.isArray(value)) {
  //     // if (
  //     //   (getSelectedValues(value) as (string | number)[]).includes(
  //     //     getSelectedValues(selection) as string | number
  //     //   )
  //     // ) {
  //     //   return value.filter(el => notIncluded(el, selection));
  //     // }
  //     return [...value, selection];
  //   }

  //   if (notIncluded(value, selection)) {
  //     return [selection];
  //   }

  //   return [];
  // }

  return options?.find(opt => selection.value === opt.value);
};
