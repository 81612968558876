import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { color, H1, typography } from '@gsc/components';
import { WarningCircle } from '@gsc/icons/react';

interface NoMatchProps {
  title?: string;
  message?: string;
}

const Wrapper = styled.div`
  background-color: ${color.ui05};
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  width: 36rem;
`;

const StyledWarningCircle = styled(WarningCircle)`
  height: 6.4rem;
  padding-bottom: 1.5rem;
  width: 6.4rem;
`;

const Text = styled.p`
  ${typography.bodyLarge};
  color: ${color.ui50};
`;

const NoMatch: FC<NoMatchProps> = props => {
  const {
    title = 'Page Not Found',
    message = 'Alas, the page you seek does not exist or has been permanently moved.',
  } = props;

  useEffect(() => {
    document.body.classList.add('page-not-found');

    return () => {
      document.body.classList.remove('page-not-found');
    };
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={message} />
      </Helmet>

      <InnerWrapper>
        <StyledWarningCircle />
        <H1>{title}</H1>
        <Text>{message}</Text>
      </InnerWrapper>
    </Wrapper>
  );
};

export { NoMatch };
