import axios, { AxiosPromise } from 'axios';
import { grpc } from '@improbable-eng/grpc-web';

const wapiMissionJobApi = (token: string) => {
  return axios.create({
    baseURL: process.env.PLANOGRAM_API_URL,
    headers: {
      common: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    },
  });
};

export const KPI_RESULT_TYPE_PLANOGRAM = 'KPI_RESULT_TYPE_GRID_PLANOGRAM';

export const getPlanogram = (
  metadata: grpc.Metadata | null,
  companyId: number,
  planogramId: string
): AxiosPromise<GetManyPlanogramsResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/v1/company/${companyId}/planogram/getMany?planogram_uuids=${planogramId}`
  );
};

export const matchPlanogram = (
  metadata: grpc.Metadata | null,
  companyId: number,
  planogramId: string
): AxiosPromise<MatchPlanogramResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/v1/company/${companyId}/planogram/match?planogram_uuid=${planogramId}`
  );
};

export const getKpis = (
  metadata: grpc.Metadata | null,
  companyId: number,
  photoGridId: number
): AxiosPromise<KpiResult> => {
  if (!metadata || !photoGridId) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/v1/company/${companyId}/photo_grid/${photoGridId}/kpis`
  );
};

export const findProducts = (
  metadata: grpc.Metadata | null,
  companyId: number,
  planogramId: string,
  guids: string[]
): AxiosPromise<FindProductsResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  const brandbankGuidParams = guids.map(guid => `guids=${guid}`).join('&');

  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/v1/company/${companyId}/planogram/products?planogram_uuid=${planogramId}&${brandbankGuidParams}`
  );
};

export const getPictureToPlanogramAccess = (
  metadata: grpc.Metadata | null,
  companyId: number
): AxiosPromise<GetPictureToPlanogramAccessResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/v1/company/${companyId}/planogram/getPictureToPlanogramAccess`
  );
};

export const generatePlanogramFromPhotogrid = (
  metadata: grpc.Metadata | null,
  companyId: number,
  photoGridId: number,
  planogramName: string,
  isMetric: boolean,
  trafficFlowRightToLeft: boolean
): AxiosPromise<GeneratePlanogramFromPhotogridResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).post(
    `/v1/company/${companyId}/photo_grid/${photoGridId}/generatePlanogram`,
    {
      newPlanogramName: planogramName,
      isMetric,
      trafficFlowRightToLeft,
    }
  );
};

export const getExportableResultJson = (
  metadata: grpc.Metadata | null,
  companyId: number,
  photoGridId: number
): AxiosPromise<GetExportableResultJsonResponse> => {
  if (!metadata) throw new Error('Auth Metadata missing');
  return wapiMissionJobApi(metadata.headersMap.authorization[0]).get(
    `/external/v1/company/${companyId}/photo_grid/${photoGridId}/compliance_result`
  );
};

export interface UpdatePlanogramStatusResponse {
  planogram: PlanogramSummary;
}

export interface DeletePlanogramsResponse {
  planograms: PlanogramSummary[];
}

export interface RematchPlanogramsResponse {
  planograms: PlanogramSummary[];
}

export interface GetPlanogramsResponse {
  planograms: PlanogramSummary[];
  pagination: Pagination;
}

export interface FindProductsResponse {
  products: BrandbankProduct[];
}

export interface GetPictureToPlanogramAccessResponse {
  hasAccess: boolean;
}

export interface GeneratePlanogramFromPhotogridResponse {
  generatedSuccessfully: boolean;
  resultDescription: string;
  planogram: PlanogramSummary;
}

export interface GetExportableResultJsonResponse {
  result: any;
}

export interface Pagination {
  limit: number;
  offset: number;
  rowcount: number;
  sortColumn: string;
  sortDirection: string;
}

export interface PlanogramSummary {
  id: string;
  planogramUuid: string;
  name: string;
  status: string;
  positionCount: number;
  productCount: number;
  unmatchedProductCount: number;
  responseCount: number;
  segmentCount: number;
  trafficFlow: string;
  previewUrl: string;
  files: AttachedFile[];
  createdTime: string;
  updatedTime: string;
  deletedTime?: string;
  category?: string;
  photoTypeId?: string;
  placeGroups: any[];
  labels: any[];
}

export interface AttachedFile {
  url: string;
  mimeType: string;
  lastModified: string;
}

export interface GetManyPlanogramsResponse {
  planograms: CompletePlanogram[];
}

export interface CompletePlanogram {
  summary: PlanogramSummary;
  details: PlanogramFile;
}

export interface PlanogramFile {
  filename: string;
  version: string;
  project: ProjectDetails;
  planogram: PlanogramDetails;
  positions: PositionDetails[];
  products: ProductDetails[];
  fixtures: FixtureDetails[];
  segments: SegmentDetails[];
}

export interface ProjectDetails {
  name: string;
  metric: boolean;
}

export interface PlanogramDetails {
  name: string;
  category: string;
  subCategory: string;
  trafficFlow: string;
  width: number;
  height: number;
  depth: number;
}

export interface ProductDetails {
  upc: string;
  brandbankGuid: string;
  category: string;
  subCategory: string;
  key: string;
  name: string;
  price: string;
  brand: string;
}

export interface FixtureDetails {
  type: string;
  name: string;
  key: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  depth: number;
}

export interface PositionDetails {
  upc: string;
  locationId: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  depth: number;
  hFacings: number;
  vFacings: number;
  dFacings: number;
  segmentIndex: number;
  shelfIndexX: number;
  shelfIndexY: number;
  excluded: boolean;
}

export interface SegmentDetails {
  name: string;
  key: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  depth: number;
  offsetX: number;
  offsetY: number;
}

export interface MatchPlanogramResponse {
  results: MatchResults;
}
export interface MatchResults {
  matchedProducts: MatchedProduct[];
  unmatchedProducts: ProductDetails[];
}

export interface MatchedProduct {
  planogramProduct: ProductDetails;
  searchProduct: BrandbankProduct;
}

export interface BrandbankProduct {
  brandbankGuid: string;
  name: string;
  fullName: string;
  upc: string;
  ean: string;
  packSize: string;
  packType: string;
  size: string;
  imageUrl: string;
  thumbnailUrl: string;
  brand: string;
  brandFamily: string;
  supplier: string;
  classification: string;
  subclassification: string;
  externalId: string;
  catalogId: string;
  score: number;
  dimensions: BrandbankProductDimensions;
}

export interface BrandbankProductDimensions {
  widthMm: string;
  heightMm: string;
  depthMm: string;
  widthInch: string;
  heightInch: string;
  depthInch: string;
}

export interface KpiResult {
  kpiResult?: KpiResultEntity[] | null;
  pagination: Pagination;
}
export interface KpiResultEntity {
  companyId: string;
  id: string;
  externalId: string;
  photoGridId: string;
  kpiResultType: string;
  results: Results;
  createdAtTime: string;
}

export interface Results {
  empty_facings: number;
  execution_id: string;
  extra_facings: number;
  generated_image_url: string;
  incorrect_position: number;
  incorrect_pricing: number;
  missing_price: number;
  not_on_shelf: number;
  ok: number;
  payload_file_name: string;
  pct_compliant: number;
  photo_grid_id: number;
  planned_facings: number;
  planned_rects?: PlannedRectsEntity[] | null;
  planogram_rect: SimpleRectEntity | null;
  planogram_uuid: string;
  products?: ProductsEntity[] | null;
  rectangles?: RectanglesEntity[] | null;
  unplanned_product: number;
  unrecognized_items: number;
  segment_rects: SimpleRectEntity[] | null;
}
export interface PlannedRectsEntity {
  brandbank_uuid: string;
  facing_status: string;
  found_brandbank_uuid: string;
  x_max: number;
  x_min: number;
  y_max: number;
  y_min: number;
  segment_index: number;
  shelf_index_x: number;
  shelf_index_y: number;
}
export interface ProductsEntity {
  brandbank_uuid: string;
  empty_facings: number;
  extra_facings: number;
  incorrect_position: number;
  incorrect_pricing: number;
  is_compliant: boolean;
  missing_price: number;
  name: string;
  is_not_on_shelf: boolean;
  ok: number;
  planned_facings: number;
}
export interface RectanglesEntity {
  rect_status: string;
  x_max: number;
  x_min: number;
  y_max: number;
  y_min: number;
  brandbank_uuid?: string | null;
}

export interface SimpleRectEntity {
  x_max: number;
  x_min: number;
  y_max: number;
  y_min: number;
}
