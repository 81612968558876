import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoint, font, Tooltip, zIndex } from '@gsc/components';

const TooltipWrapper = styled.div`
  display: inline-block;
  line-height: 1.6rem;
  margin-right: 1rem;
  margin-top: 1.5rem;

  ${breakpoint.md`
    & {
      align-items: center;
      display: flex;
      margin: 0 0 0 0.8rem;
    }
  `}

  > div > svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  > div > div {
    max-width: 30rem;
    z-index: ${zIndex.golf + 1};

    ${breakpoint.md`
      max-width: 35rem;
    `}
  }
`;

const BoldText = styled.p`
  display: inline-block;
  font-weight: ${font.weight.medium};
`;

const TierTooltip: FC = () => (
  <TooltipWrapper>
    <Tooltip dataTest="tier-tooltip">
      <BoldText>Lite</BoldText> - This tier is meant for customers who are not
      paying for Photo Album / PhotoWorks in their contract. Lite tier customers
      have limited access to new features.
      <br />
      <br />
      <BoldText>Trial</BoldText> - Use this for customers on a free trial. The
      Space will automatically deactivate on the end date you provide.
      <br />
      <br />
      <BoldText>Standard</BoldText> - Use this for all paying Photo Album
      customers who have not upgraded to pay for unlimited data retention.
      <br />
      <br />
      <BoldText>Unlimited</BoldText> - Choosing this will sync all time Photo
      history for customer. You should only use this for customers who pay the
      up-charge for storing all-time data.
    </Tooltip>
  </TooltipWrapper>
);

export { TierTooltip };
