import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Select = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  background-color: var(--colors-charlestonGrey4);

  padding: 6px 48px 6px 8px;
  width: 100%;
  border-radius: 4px;

  color: var(--colors-charlestonGrey);

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.024px;

  &.withIconBefore {
    padding-left: 44px;
  }
  &.withClear {
    padding-right: 72px;
  }

  &:hover,
  &:focus-within {
    border-color: var(--colors-deepOcean);
    outline: 1px solid var(--colors-deepOcean);
  }

  &.success {
    border-color: var(--colors-success);

    &:hover,
    &:focus-within {
      border-color: var(--colors-success);
      outline: 1px solid var(--colors-success);
    }
  }

  &.error {
    border-color: var(--colors-fail);

    &:hover,
    &:focus-within {
      border-color: var(--colors-fail);
      outline: 1px solid var(--colors-fail);
    }
  }

  &.warning {
    border-color: var(--colors-warning);

    &:hover,
    &:focus-within {
      border-color: var(--colors-warning);
      outline: 1px solid var(--colors-warning);
    }
  }

  .iconBefore {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colors-charlestonGrey30);
    width: 24px;
    left: 16px;
  }

  .triggerIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colors-charlestonGrey30);
    margin-left: auto;
    width: 24px;
    right: 16px;
    font-size: 24px;
  }
`;

export const ClearIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 24px;
  color: var(--colors-neutral50);
  cursor: pointer;
  top: 0;
  right: 48px;
`;

export const DisplayValue = styled.span`
  color: var(--colors-charlestonGrey);

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.024px;

  &.placeholder {
    color: var(--colors-charlestonGrey50);
  }
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px 0;
  background: var(--colors-white);
  border: 1px solid var(--colors-deepOcean);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.24));
  z-index: 20;
`;
