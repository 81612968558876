import styled from 'styled-components';

export const Container = styled.label`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
  color: var(--colors-charlestonGrey);
  font-size: 12px;
  font-weight: 400;

  &[aria-disabled='true'] {
    color: var(--colors-charlestonGrey30);
    cursor: auto;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    & * {
      outline-color: var(--colors-deepOcean);
    }
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  display: none;
  width: 0;
  height: 0;
  z-index: -1;
`;

export const Radio = styled.div<{ isChecked: boolean }>`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  padding: 3px;

  border: none;

  outline: 1px solid var(--colors-charlestonGrey50);

  ${({ isChecked }) =>
    isChecked ? 'outline-color: var(--colors-deepOcean);' : ''}

  &[aria-disabled='true'] {
    outline-color: var(--colors-charlestonGrey12);
    pointer-events: none;
    cursor: auto;

    & > div {
      background: var(--colors-charlestonGrey12);
    }
  }
`;

export const Indicator = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--colors-deepOcean);
`;
