import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Sort, SuccessCheckmark } from '@gsc/icons/react';
import { useQueryParams } from '../../../../../state/QueryParams/hooks';
import { useCurrentSpaceObj } from '../../../../../state/Spaces';
import { FilterComponentProps } from '../../../components/filters';
import { formatFilterText } from '../../../shared/filters/formatFilterText';
import { ButtonMenu } from '../../../../../shared/components';
import {
  DATE_PROPERTY_NAME,
  DATE_REFERENCE_NAME,
} from '../../../../../state/QueryParams/types';
import {
  ColumnType,
  SpaceTierType,
} from '@gsc/proto-gen-v2/dist/idl/aperture/assetdetail/v1/asset_detail_pb';

const SelectionIndicator = styled(SuccessCheckmark)`
  float: right;
  width: 1.2rem;
  margin-top: 1.3rem;
`;

const StyledButtonMenu = styled(ButtonMenu)`
  button {
    position: static;
  }

  ul[role='listbox'] {
    overflow-x: hidden;
    max-height: 42.4rem;
    z-index: 2;
  }
`;

const SortButton = () => {
  const {
    searchFilters,
    sortField,
    sortDirection,
    setSortDirection,
    setSortFieldAndDirection,
  } = useQueryParams();

  const currentSpace = useCurrentSpaceObj();
  const assetDetailConfig = currentSpace.config?.assetDetailConfig;
  const tablesList = assetDetailConfig?.tablesList ?? [];
  const filtersToRender: FilterComponentProps[] = useMemo(
    () => [
      {
        filterText: 'Date',
        tableName: DATE_REFERENCE_NAME,
        columnName: DATE_PROPERTY_NAME,
        columnType: ColumnType.COLUMN_TYPE_DATETIME,
        canFilter: true,
      },
      ...tablesList
        ?.flatMap(table =>
          table.columnConfigsList.map(column => ({
            tableName: table.tableName,
            columnName: column.columnName,
            columnType: column.type,
            canFilter: column.canFilter,
            filterText: formatFilterText(table, column),
            filterCount: searchFilters.filter(
              filter =>
                filter.propertyName === column.columnName &&
                filter.referenceName === table.tableName
            ).length,
          }))
        )
        .sort((a, b) => {
          const aFilterText = a.filterText.toLowerCase();
          const bFilterText = b.filterText.toLowerCase();
          if (aFilterText > bFilterText) return 1;
          if (aFilterText < bFilterText) return -1;
          return 0;
        }),
    ],
    [tablesList, searchFilters]
  );

  const selectedFilter = useMemo(
    () =>
      filtersToRender.find(
        filter => `${filter.tableName}.${filter.columnName}` === sortField
      ),
    [filtersToRender, sortField]
  );

  const items = useMemo(
    () => [
      {
        onSelect: () => setSortDirection('+'),
        children: (
          <span>
            Ascending
            {sortDirection === '+' && <SelectionIndicator />}
          </span>
        ),
      },
      {
        onSelect: () => setSortDirection('-'),
        children: (
          <>
            <span>
              Descending
              {sortDirection === '-' && <SelectionIndicator />}
            </span>
            <div
              style={{ borderTop: '.1rem solid #DADCDE', margin: '0 -1.5rem' }}
            />
          </>
        ),
      },

      ...filtersToRender
        .filter(f => f.canFilter)
        .map(({ filterText, tableName, columnName }) => ({
          onSelect: () => {
            setSortFieldAndDirection(
              `${tableName}.${columnName}`,
              `${tableName}.${columnName}` === 'mission_responses.completed_at'
                ? '-'
                : '+'
            );
          },
          children: (
            <span key={filterText}>
              {filterText}
              {sortField === `${tableName}.${columnName}` && (
                <SelectionIndicator />
              )}
            </span>
          ),
        })),
    ],
    [
      filtersToRender,
      setSortDirection,
      setSortFieldAndDirection,
      sortDirection,
      sortField,
    ]
  );

  if (currentSpace.tier?.type === SpaceTierType.SPACE_TIER_TYPE_LITE)
    return null;

  return (
    <StyledButtonMenu
      buttonText={`Sort by ${selectedFilter?.filterText ?? '???'}`}
      alignRight
      variant="secondary"
      status="neutral"
      items={items}
      icon={
        <Sort
          style={{
            transform: sortDirection === '+' ? 'scaleY(-1)' : '',
          }}
        />
      }
    />
  );
};

export { SortButton };
